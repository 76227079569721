<form class="unions" [formGroup]="unionForm">
  <div class="union" *ngFor="let union of unions">
    <input
      type="radio"
      name="union"
      [id]="'union' + union.id"
      [value]="union.id"
      formControlName="union"
    />
    <label [for]="'union' + union.id" class="union__label">
      <img [src]="union.image" [alt]="union.name" class="union__image" />
    </label>
  </div>
</form>
