import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { fromEvent, Subscription } from 'rxjs'
import { OrbitControls } from 'src/app/@shared/three-dxf/OrbitControls'
import { DXF } from 'src/app/presentation/shared/models/DXF.model'
import { DxfDrawerService } from 'src/app/presentation/shared/services/dxf-drawer.service'
import { environment } from 'src/environments/environment'

import * as Three from 'three'
import { Color } from 'three'

import * as uuid from 'uuid'

import {
  DxfConfectionService,
  ProfileDisAssembler,
} from '../services/dxf-confection.service'

@Component({
  selector: 'app-disassembly-dxf-editor',
  templateUrl: './disassembly-dxf-editor.component.html',
  styleUrls: ['./disassembly-dxf-editor.component.scss'],
})
export class DisassemblyDxfEditorComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('content', { static: true })
  content!: ElementRef<HTMLCanvasElement>
  @ViewChild('container', { static: false })
  container!: ElementRef<HTMLDivElement>
  @ViewChild('modal', { static: true }) modal!: ElementRef<HTMLDivElement>

  private zoomQuantity = 5

  private scene!: Three.Scene
  private camera!: Three.Camera
  private renderer!: Three.WebGLRenderer
  private controls;

  private dxfs: { dxfAssembler?: ProfileDisAssembler; dxf: DXF }[] = []
  public dxfSelected: DXF | null = null

  @Output() 
  $response: EventEmitter<any> = new EventEmitter<any>()

  private addProfileSubscription: Subscription
  private removeProfileSubscription: Subscription

  constructor(
    private dxfConfectionService: DxfConfectionService,
    private dxfDrawer: DxfDrawerService
  ) {}

  ngOnInit(): void {
    this.dxfConfectionSubscriber()
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.createScene()
      this.createCamera()
      this.createRenderer()
      this.createViewControls()

      this.drawAxis()

      this.listenMouse()
      this.listenResize()

      this.animate()
      this.render()
    }, 0)
  }

  ngOnDestroy(): void {
    this.addProfileSubscription?.unsubscribe()
    this.removeProfileSubscription?.unsubscribe()
  }

  private dxfConfectionSubscriber(): void {
    this.addProfileSubscription = this.dxfConfectionService.onAddProfile$.subscribe(
      {
        next: (params: ProfileDisAssembler) => {
          this.buildDXF(params)
          this.emitList()
          this.render()
        },
      }
    )

    this.removeProfileSubscription = this.dxfConfectionService.onRemoveProfile$.subscribe(
      {
        next: (dxf) => {
          this.selectDxf(dxf)
          this.removeDxf()
        },
      }
    )
  }

  private emitList(): void {
    this.dxfConfectionService.emitListDxfs(this.dxfs.map((dxf) => dxf.dxf))
  }

  private buildDXF(params: ProfileDisAssembler): DXF | null {
    const { dxfParsed } = params

    const parsed = dxfParsed

    const entities = parsed.entities
    if (!this.scene) return null

    const {
      object: dxfGroup,
      entities: newEntities,
    } = this.dxfDrawer.drawDxfFromEntities(entities)
    dxfGroup.position.set(0, 0, 0)

    parsed.entities = newEntities

    const dxf = new DXF({
      uuid: params.uuid,
      profile: params.profile,
      parsed,
      offset: [0, 0, 0],
    })
    this.dxfs.push({
      dxfAssembler: params,
      dxf,
    })

    dxf.setGroup(dxfGroup)

    this.dxfDrawer.addDragProperty(dxf, this.scene, this.camera, this.renderer)

    this.scene.add(dxfGroup)

    this.render()

    if(params.offset) {
      dxf.offset = params.offset.match(/\-?\d+(\.?\d+)?/g).map(a => parseInt(a));
      const vector = new Three.Vector3(...dxf.offset);
      this.dxfDrawer.moveGroup(dxf, [dxfGroup], vector, this.renderer, this.scene, this.camera)
    }

    return dxf
  }

  private intersectDxf(event: any): DXF | null {
    let result = null

    const mousePointer = this.getMousePosition(event)

    const dxfs = this.dxfs.map((dxf) => dxf.dxf)

    dxfs.forEach((dxf) => {
      const group = dxf.getGroup()
      if (!group) return
      const bbox = new Three.Box3().setFromObject(group)

      // transparent
      const box3Helper = new Three.Box3Helper(bbox, new Color(0xff0000))
      this.scene.add(box3Helper)

      // raycaster helper
      const raycaster = new Three.Raycaster()
      raycaster.setFromCamera(
        new Three.Vector2(mousePointer.x, mousePointer.y),
        this.camera
      )
      const ray = raycaster.ray
      const vectorIntersectBox = ray.intersectBox(bbox, mousePointer)

      const hasBox = vectorIntersectBox ? true : false

      if (hasBox) {
        result = dxf
      }

      this.scene.remove(box3Helper)
    })

    return result
  }

  private selectDxf(dxf: DXF): void {
    this.dxfSelected = dxf

    const dxfs = this.dxfs.map((dxf) => dxf.dxf)

    dxfs.forEach((d) => {
      d.changeColor(d === dxf ? 0x00ff00 : 0x000000)
    })
  }

  public removeDxf(): void {
    const dxf = this.dxfSelected
    if (!dxf) return

    const group = dxf.getGroup()
    if (!group) return

    this.scene.remove(group)
    this.dxfs = this.dxfs.filter((item) => item.dxf !== dxf)
    this.dxfSelected = null

    this.modal.nativeElement.style.display = 'none'

    this.emitList()
  }

  public rotate(): void {
    if (!this.dxfSelected) return
    this.dxfSelected.rotate(Math.PI / 2)

    this.emitList()
  }

  public reflect(): void {
    if (!this.dxfSelected) return
    this.dxfSelected.reflect()

    this.emitList()
  }

  public duplicate(): void {
    if (!this.dxfSelected) return
    const duplicateDxf = this.dxfSelected.duplicate()
    this.buildDXF({
      uuid: uuid.v4(),
      profile: duplicateDxf.profile,
      dxfParsed: {
        entities: duplicateDxf.getEntities(),
      },
    })

    const group = duplicateDxf.getGroup()
    if (!group) return
    const offset = new Three.Vector3(10, 10, 0)
    group.position.add(offset)

    this.dxfDrawer.moveGroup(
      duplicateDxf,
      group.children,
      offset,
      this.renderer,
      this.scene,
      this.camera
    )

    this.emitList()
  }

  public onSubmit(): void {
    const dxf = DXF.combineArray(this.dxfs.map((d) => d.dxf))

    this.scene.clear()

    const { object: dxfGroup, entities } = this.dxfDrawer.drawDxfFromEntities(
      dxf.json().entities
    )
    dxfGroup.position.set(0, 0, 0)

    const newDxf = new DXF({
      uuid: uuid.v4(),
      profile: dxf.profile,
      parsed: {
        entities,
      },
    })
    newDxf.setGroup(dxfGroup)
    this.dxfs = [
      {
        dxf,
      },
    ]

    this.dxfDrawer.addDragProperty(
      newDxf,
      this.scene,
      this.camera,
      this.renderer
    )

    this.scene.add(dxfGroup)

    this.$response.emit(newDxf)

    this.render()
  }

  private getDimensions(): { width: number; height: number } {
    return {
      width: this.container.nativeElement.clientWidth,
      height: this.container.nativeElement.clientHeight,
    }
  }

  private createScene(): void {
    const scene = new Three.Scene()
    scene.background = new Three.Color(0xffffff)
    this.scene = scene
  }

  private createCamera(): void {
    const camera = new Three.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.01,
      10000
    )
    camera.position.z = 50
    this.camera = camera

    // from wheel event on content
    const sub = fromEvent(this.content.nativeElement, 'wheel').subscribe({
      next: (event: any) => {
        event.preventDefault()

        const isZoomIn = event.deltaY > 0

        if (event.deltaY > 0) {
          this.cameraZoomIn()
        }

        if (event.deltaY < 0) {
          this.cameraZoomOut()
        }

        const mousePointer = this.getMousePosition(event)
        const isXPositive = mousePointer.x > 0
        const isYPositive = mousePointer.y > 0

        const xOffset = isXPositive ? -this.zoomQuantity : this.zoomQuantity
        const yOffset = isYPositive ? -this.zoomQuantity : this.zoomQuantity

        if (!isZoomIn) {
          this.camera.position.x += xOffset
          this.camera.position.y += yOffset
        }

        if (isZoomIn) {
          this.camera.position.x -= xOffset
          this.camera.position.y -= yOffset
        }

        this.render()
      },
    })
  }

  private createRenderer(): void {
    const renderer = new Three.WebGLRenderer({
      antialias: true,
      canvas: this.content.nativeElement,
    })

    const { width, height } = this.getDimensions()

    renderer.setSize(width, height)
    renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer = renderer
  }

  private createViewControls(): void {
    this.controls = new OrbitControls( this.camera, this.renderer.domElement );
    this.controls.enableRotate = false;
    this.controls.enabled = true;
    this.controls.update();
    console.log(this.controls)
  }

  private listenMouse(): void {
    const sub = fromEvent(this.content.nativeElement, 'click').subscribe({
      next: (event: any) => {
        const dxfs = this.dxfs.map(({ dxf }) => dxf)

        this.modal.nativeElement.style.display = 'none'

        const intersectedDxf = this.intersectDxf(event)
        this.dxfSelected = intersectedDxf

        dxfs.forEach((dxf) => {
          dxf.changeColor(dxf === intersectedDxf ? 0x00ff00 : 0x000000)
        })
      },
    })

    const sub2 = fromEvent(this.content.nativeElement, 'contextmenu').subscribe(
      {
        next: (event: any) => {
          event.preventDefault()

          const intersectedDxf = this.intersectDxf(event)
          if (!intersectedDxf) return
          this.selectDxf(intersectedDxf)

          this.modal.nativeElement.style.display = 'block'
          this.modal.nativeElement.style.left = event.layerX + 'px'
          this.modal.nativeElement.style.top = event.layerY + 'px'
        },
      }
    )
  }

  private listenResize(): void {
    const sub = fromEvent(window, 'resize').subscribe({
      next: () => {
        const { width, height } = this.getDimensions()

        this.renderer.setSize(width, height)

        this.render()
      },
    })
  }

  private cameraZoomIn(): void {
    this.camera.position.z -= this.zoomQuantity
    this.camera.updateMatrix()
  }

  private cameraZoomOut(): void {
    this.camera.position.z += this.zoomQuantity
    this.camera.updateMatrix()
  }

  private getMousePosition(event: any): Three.Vector3 {
    let mouse = new Three.Vector3()

    const canvasWidth = this.content.nativeElement.clientWidth
    const canvasHeight = this.content.nativeElement.clientHeight

    mouse.set(
      (event.offsetX / canvasWidth) * 2 - 1,
      -(event.offsetY / canvasHeight) * 2 + 1,
      0
    )

    return mouse
  }

  private drawAxis(): void {
    const isProd = environment.production
    if (isProd) return

    const axis = new Three.AxesHelper(5)
    this.scene.add(axis)
  }

  private animate() {
    requestAnimationFrame(() => this.animate())

    this.render()
  }

  private render(): void {
    this.renderer.render(this.scene, this.camera)
  }
}
