<div class="modal-content">
  <div class="header">
    <h3>Nueva combinación de accesorios</h3>
    <fa-icon
      [icon]="faTimes"
      (click)="decline()"
      class="header__icon icon"
    ></fa-icon>
  </div>

  <hr class="separator" />

  <div class="body">
    <table class="table table-hover table-fixed">
      <thead>
        <tr>
          <th scope="col">Familia</th>
          <th scope="col">Referencia</th>
          <th scope="col">Descripción</th>
          <th scope="col">
            <form class="table-head-icons" [formGroup]="form">
              <input
                type="text"
                class="table-head-icons__input-search"
                [ngClass]="{
                  'animated-grow': form.get('show').value,
                  hide: !form.get('show').value
                }"
                formControlName="search"
              />
              <input
                type="checkbox"
                formControlName="show"
                id="show"
                style="display: none"
              />
              <label for="show">
                <fa-icon
                  [icon]="faSearch"
                  class="table-head-icons__icon icon"
                ></fa-icon>
              </label>
            </form>
          </th>
        </tr>
      </thead>

      <tbody class="table-tbody">
        <ngx-spinner
          bdColor="rgba(0, 0, 0, 0.8)"
          size="medium"
          color="#fff"
          type="square-jelly-box"
          [fullScreen]="false"
          ><p style="color: white">Cargando...</p></ngx-spinner
        >
        <tr
          class="table-row fade-in"
          *ngFor="let accesory of filteredAccesories; index as i"
          (click)="selectAccesory(accesory)"
        >
          <td>{{ accesory.family }}</td>
          <td>{{ accesory.codRef }}</td>
          <td>{{ accesory.denomination }}</td>

          <td>
            <div class="table-head-icons"></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <hr class="separator body-separator" />
</div>
