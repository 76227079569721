import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class OpeningService {
  private apiUrl = environment.apiUrl

  public openingTypesAbisagradas: Array<{ name: string; id: number }> = []

  constructor(private http: HttpClient) {
    this.getAbisagradas()
  }

  private getAbisagradas(): void {
    this.getOpeningTypeLeafts('Abisagrada').then(
      (openings) => (this.openingTypesAbisagradas = openings)
    )
  }

  public async getOpeningTypeLeafts(
    type: 'Abisagrada'
  ): Promise<Array<{ name: string; id: number }>> {
    if (this.openingTypesAbisagradas.length > 0) return

    const params = new HttpParams().set('typeAperture', type)

    return this.http
      .get<any>(`${this.apiUrl}move-leafs`, { params })
      .toPromise()
  }
}
