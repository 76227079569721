import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { DisassemblyDxfEditorComponent } from './disassembly-dxf-editor/disassembly-dxf-editor.component'
import { DxfDisassemblyConfectionComponent } from './dxf-disassembly-confection/dxf-disassembly-confection.component'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'

@NgModule({
  declarations: [
    DisassemblyDxfEditorComponent,
    DxfDisassemblyConfectionComponent,
  ],
  exports: [DisassemblyDxfEditorComponent, DxfDisassemblyConfectionComponent],
  imports: [CommonModule, FontAwesomeModule],
})
export class ThreeDxfModule {}
