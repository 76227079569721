import { Injectable } from '@angular/core'
import Konva from 'konva'
import { degreeToRadians } from 'src/app/@helper/degreeToRadians'
import { ProfileCreator } from 'src/app/@shared/@interfaces/profileCreator.model'
import { ProfileElement } from 'src/app/@shared/@models/modelsElements/Profile-model'
import { SideOptions } from 'src/app/@shared/@types/side.types'
import {
  CutVariationService,
  CutVariationTypes,
} from '../cut-variation.service'
import { ProfileDrawerCreatorService } from './profile-drawer-creator.service'

@Injectable({
  providedIn: 'root',
})
export class CutVariationsDrawerService {
  private cutVariationtypes: CutVariationTypes
  private profilesTypes: profileTypes[] = ['Frame', 'Covergap', 'Crossbar']
  private scaleFactor = 1

  constructor(
    private cutVariationService: CutVariationService,
    private profileDrawer: ProfileDrawerCreatorService
  ) {
    this.cutVariationtypes = this.cutVariationService.cutVariationTypes
  }

  public applyCutVariations(
    cutVariations: {
      [k in SideOptions]?: { id: number; value: number; value2?: number }
    },
    profiles: {
      [k in SideOptions]?: {
        profile: ProfileCreator
        profileDraw: Konva.Line
      }
    },
    thickness: { [k in SideOptions]?: number },
    profilesType: profileTypes,
    scaleFactor: number
  ) {
    if (!this.profilesTypes.includes(profilesType)) return
    this.scaleFactor = scaleFactor

    const scaledThickness = {
      top: thickness?.top * scaleFactor,
      bottom: thickness?.bottom * scaleFactor,
      left: thickness?.left * scaleFactor,
      right: thickness?.right * scaleFactor,
    }

    const profilesCreator = {
      top: profiles?.top?.profile,
      bottom: profiles?.bottom?.profile,
      left: profiles?.left?.profile,
      right: profiles?.right?.profile,
    }

    if (profiles?.top?.profile)
      this.top(
        cutVariations.top,
        profilesCreator,
        scaledThickness.top,
        profiles.top.profileDraw
      )

    if (profiles?.bottom?.profile)
      this.bottom(
        cutVariations.bottom,
        profilesCreator,
        scaledThickness.bottom,
        profiles.bottom.profileDraw
      )

    if (profiles?.left?.profile)
      this.left(
        cutVariations.left,
        profilesCreator,
        scaledThickness.left,
        profiles.left.profileDraw
      )

    if (profiles?.right?.profile)
      this.right(
        cutVariations.right,
        profilesCreator,
        scaledThickness.right,
        profiles.right.profileDraw
      )
  }

  private top(
    cutVariation: { id: number; value: number; value2?: number },
    profiles: { [k in SideOptions]: ProfileCreator },
    scaledThickness: number,
    profile: Konva.Line
  ) {
    const { value, value2, id } = cutVariation
    switch (cutVariation.id) {
      case this.cutVariationtypes.cutDifferenteTo90degrees:
        {
          this.createAngleDifferentTo90(
            cutVariation.value,
            profile,
            scaledThickness,
            'Horizontal'
          )
        }
        break

      case this.cutVariationtypes.retested:
        {
          if (value === 0) return

          this.setValue1and2(
            value,
            value,
            this.scaleFactor,
            profile,
            'Horizontal',
            false
          )
        }
        break

      case this.cutVariationtypes.value:
        {
          this.setValue1(
            cutVariation.value,
            this.scaleFactor,
            profile,
            'Horizontal',
            true
          )
        }
        break
      case this.cutVariationtypes.value2:
        {
          this.setValue2(
            cutVariation.value,
            this.scaleFactor,
            profile,
            'Horizontal',
            true
          )
        }
        break
      case this.cutVariationtypes.value1_2:
        {
          this.setValue1and2(
            cutVariation.value,
            cutVariation.value2 ?? 0,
            this.scaleFactor,
            profile,
            'Horizontal',
            true
          )
        }
        break
      case this.cutVariationtypes.stopFinReduction:
        {
        }
        break
      case this.cutVariationtypes.centerSuperpositionReduction:
        {
          this.setValue1and2(
            -cutVariation.value,
            -cutVariation.value,
            this.scaleFactor,
            profile,
            'Horizontal'
          )
        }
        break

      default:
        break
    }
  }

  private bottom(
    cutVariation: { id: number; value: number; value2?: number },
    profiles: { [k in SideOptions]: ProfileCreator },
    scaledThickness: number,
    profile: Konva.Line
  ) {
    const { value, value2, id } = cutVariation
    switch (id) {
      case this.cutVariationtypes.cutDifferenteTo90degrees:
        {
          this.createAngleDifferentTo90(
            cutVariation.value,
            profile,
            scaledThickness,
            'Horizontal'
          )
        }
        break

      case this.cutVariationtypes.value:
        {
          this.setValue1(
            cutVariation.value,
            this.scaleFactor,
            profile,
            'Horizontal',
            false
          )
        }
        break
      case this.cutVariationtypes.value2:
        {
          this.setValue2(
            cutVariation.value,
            this.scaleFactor,
            profile,
            'Horizontal',
            false
          )
        }
        break
      case this.cutVariationtypes.value1_2:
        {
          this.setValue1and2(
            cutVariation.value,
            cutVariation.value2 ?? 0,
            this.scaleFactor,
            profile,
            'Horizontal',
            false
          )
        }
        break
      case this.cutVariationtypes.stopFinReduction:
        {
        }
        break
      case this.cutVariationtypes.retested:
        {
          if (value === 0) return

          this.setValue1and2(
            value,
            value,
            this.scaleFactor,
            profile,
            'Horizontal',
            false
          )
        }
        break
      case this.cutVariationtypes.centerSuperpositionReduction:
        {
          this.setValue1and2(
            -cutVariation.value,
            -cutVariation.value,
            this.scaleFactor,
            profile,
            'Horizontal'
          )
        }
        break

      default:
        break
    }
  }

  private left(
    cutVariation: { id: number; value: number; value2?: number },
    profiles: { [k in SideOptions]: ProfileCreator },
    scaledThickness: number,
    profile: Konva.Line
  ) {
    const { value, value2, id } = cutVariation
    switch (cutVariation.id) {
      case this.cutVariationtypes.cutDifferenteTo90degrees:
        {
          this.createAngleDifferentTo90(
            cutVariation.value,
            profile,
            scaledThickness,
            'Vertical'
          )
        }
        break

      case this.cutVariationtypes.value:
        {
          this.setValue1(
            cutVariation.value,
            this.scaleFactor,
            profile,
            'Vertical',
            true
          )
        }
        break
      case this.cutVariationtypes.value2:
        {
          this.setValue2(
            cutVariation.value,
            this.scaleFactor,
            profile,
            'Vertical',
            true
          )
        }
        break
      case this.cutVariationtypes.value1_2:
        {
          this.setValue1and2(
            cutVariation.value,
            cutVariation.value2 ?? 0,
            this.scaleFactor,
            profile,
            'Vertical',
            true
          )
        }
        break
      case this.cutVariationtypes.stopFinReduction:
        {
        }
        break
      case this.cutVariationtypes.retested:
        {
          if (value === 0) return

          this.setValue1and2(
            value,
            value,
            this.scaleFactor,
            profile,
            'Vertical',
            false
          )
        }
        break
      case this.cutVariationtypes.centerSuperpositionReduction:
        {
          this.setValue1and2(
            -cutVariation.value,
            -cutVariation.value,
            this.scaleFactor,
            profile,
            'Vertical'
          )
        }
        break

      default:
        break
    }
  }

  private right(
    cutVariation: { id: number; value: number; value2?: number },
    profiles: { [k in SideOptions]: ProfileCreator },
    scaledThickness: number,
    profile: Konva.Line
  ) {
    const { value, value2, id } = cutVariation
    switch (cutVariation.id) {
      case this.cutVariationtypes.cutDifferenteTo90degrees:
        {
          this.createAngleDifferentTo90(
            cutVariation.value,
            profile,
            scaledThickness,
            'Vertical'
          )
        }
        break
      case this.cutVariationtypes.retested:
        {
          if (value === 0) return

          this.setValue1and2(
            value,
            value,
            this.scaleFactor,
            profile,
            'Vertical',
            false
          )
        }
        break

      case this.cutVariationtypes.value:
        {
          this.setValue1(
            cutVariation.value,
            this.scaleFactor,
            profile,
            'Vertical',
            false
          )
        }
        break
      case this.cutVariationtypes.value2:
        {
          this.setValue2(
            cutVariation.value,
            this.scaleFactor,
            profile,
            'Vertical',
            false
          )
        }
        break
      case this.cutVariationtypes.value1_2:
        {
          this.setValue1and2(
            cutVariation.value,
            cutVariation.value2 ?? 0,
            this.scaleFactor,
            profile,
            'Vertical',
            false
          )
        }
        break
      case this.cutVariationtypes.stopFinReduction:
        {
        }
        break
      case this.cutVariationtypes.centerSuperpositionReduction:
        {
          this.setValue1and2(
            -cutVariation.value,
            -cutVariation.value,
            this.scaleFactor,
            profile,
            'Vertical'
          )
        }
        break

      default:
        break
    }
  }

  private setCutOn90degrees(
    profile: Konva.Line,
    axis: 'Horizontal' | 'Vertical'
  ): Konva.Line {
    if (!profile) return
    const {
      startExt,
      startInt,
      endExt,
      endInt,
    } = this.profileDrawer.getPointsAsVectors(profile.points())

    // Verify if already cut is 90
    const isStart90 = startExt.x === startInt.x || startExt.y === startInt.y
    const isEnd90 = endExt.x === endInt.x || endExt.y === endInt.y

    if (!isStart90) {
      if (axis === 'Horizontal') startInt.setX(startExt.x)
      if (axis === 'Vertical') startInt.setY(startExt.y)
    }

    if (!isEnd90) {
      if (axis === 'Horizontal') endInt.setX(endExt.x)
      if (axis === 'Vertical') endInt.setY(endExt.y)
    }

    const newPoints = [
      startExt.toArray(),
      startInt.toArray(),
      endInt.toArray(),
      endExt.toArray(),
      startExt.toArray(),
    ].flat()

    profile.points(newPoints)

    return profile
  }

  private setCutAngle(
    degrees: number,
    profile: Konva.Line,
    scaledThickness: number,
    axis: 'Horizontal' | 'Vertical'
  ) {
    const {
      startExt,
      startInt,
      endExt,
      endInt,
    } = this.profileDrawer.getPointsAsVectors(profile.points())

    const radians = degreeToRadians(degrees)
    const lengthdiffBetweenExtInt = -scaledThickness * (1 / Math.tan(radians))

    if (axis === 'Horizontal') {
      startInt.setX(startInt.x - lengthdiffBetweenExtInt)
      endInt.setX(endInt.x + lengthdiffBetweenExtInt)
    }

    if (axis === 'Vertical') {
      startInt.setY(startInt.y - lengthdiffBetweenExtInt)
      endInt.setY(endInt.y + lengthdiffBetweenExtInt)
    }

    const newPoints = [
      startExt.toArray(),
      startInt.toArray(),
      endInt.toArray(),
      endExt.toArray(),
      startExt.toArray(),
    ].flat()

    profile.points(newPoints)

    return profile
  }

  private createAngleDifferentTo90(
    cutVariationValue: number,
    profileLine: Konva.Line,
    scaledThickness: number,
    axis: 'Horizontal' | 'Vertical'
  ) {
    if (cutVariationValue > 90 || cutVariationValue < 0) return
    this.setCutOn90degrees(profileLine, axis)
    this.setCutAngle(cutVariationValue, profileLine, scaledThickness, axis)
  }

  private setValue1and2(
    value: number,
    value2: number,
    scaleFactor: number,
    profile: Konva.Line,
    axis: 'Horizontal' | 'Vertical',
    isMain = true
  ) {
    this.setValue1(value, scaleFactor, profile, axis, isMain)
    this.setValue2(value2, scaleFactor, profile, axis, isMain)
  }

  private setValue1(
    value: number,
    scaleFactor: number,
    profile: Konva.Line,
    axis: 'Horizontal' | 'Vertical',
    isMain = true
  ) {
    const scaledValue = value * scaleFactor
    const {
      startExt,
      startInt,
      endExt,
      endInt,
    } = this.profileDrawer.getPointsAsVectors(profile.points())

    if (axis === 'Horizontal') {
      if (isMain) {
        startExt.setX(startExt.x - scaledValue)
        startInt.setX(startInt.x - scaledValue)
      }

      if (!isMain) {
        endExt.setX(endExt.x + scaledValue)
        endInt.setX(endInt.x + scaledValue)
      }
    }

    if (axis === 'Vertical') {
      if (isMain) {
        startExt.setY(startExt.y - scaledValue)
        startInt.setY(startInt.y - scaledValue)
      }

      if (!isMain) {
        endExt.setY(endExt.y + scaledValue)
        endInt.setY(endInt.y + scaledValue)
      }
    }

    this.profileDrawer.setPointsFromVectors(
      startExt,
      startInt,
      endExt,
      endInt,
      profile
    )
  }

  private setValue2(
    value: number,
    scaleFactor: number,
    profile: Konva.Line,
    axis: 'Horizontal' | 'Vertical',
    isMain = true
  ) {
    const scaledValue = value * scaleFactor
    const {
      startExt,
      startInt,
      endExt,
      endInt,
    } = this.profileDrawer.getPointsAsVectors(profile.points())

    if (axis === 'Horizontal') {
      if (!isMain) {
        startExt.setX(startExt.x - scaledValue)
        startInt.setX(startInt.x - scaledValue)
      }

      if (isMain) {
        endExt.setX(endExt.x + scaledValue)
        endInt.setX(endInt.x + scaledValue)
      }
    }

    if (axis === 'Vertical') {
      if (!isMain) {
        startExt.setY(startExt.y - scaledValue)
        startInt.setY(startInt.y - scaledValue)
      }

      if (isMain) {
        endExt.setY(endExt.y + scaledValue)
        endInt.setY(endInt.y + scaledValue)
      }
    }

    this.profileDrawer.setPointsFromVectors(
      startExt,
      startInt,
      endExt,
      endInt,
      profile
    )
  }
}

export type profileTypes = 'Frame' | 'Crossbar' | 'Covergap'
