<button class="button" #button (click)="togglePopover()">
  <!-- add icon fontawesome -->
  <fa-icon [icon]="faEllipsisV"></fa-icon>
</button>

<div class="popover" #popover>
  <button class="popover-action" (click)="onAddAccessory()">
    Añadir accesorio
  </button>
  <button class="popover-action" (click)="onAddAccessory(true)">
    Añadir grupo de accesorios
  </button>
</div>
