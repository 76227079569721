<div class="content">
  <div class="content-header">
    <h2>Creación de áreas</h2>
    <fa-icon
      [icon]="faTimes"
      class="content-header__icon"
      (click)="decline()"
    ></fa-icon>
  </div>

  <!-- Set Axis bar -->
  <div class="set-axis-bar">
    <div class="axis">
      <span class="set-axis-bar-label">Selección de eje: </span>

      <div class="set-axis-bar-items">
        <button class="set-axis-bar-item" (click)="selectAxis('X')">
          <img
            src="../../../assets/icons/horizontal.svg"
            alt="Horizontal"
            tooltip="Horizontal"
            class="set-axis-bar-item__image"
            [ngClass]="{ selected: axisSelected === 'X' }"
          />
        </button>
        <button class="set-axis-bar-item" (click)="selectAxis('Y')">
          <img
            src="../../../assets/icons/vertical.svg"
            alt="Vertical"
            tooltip="Vertical"
            class="set-axis-bar-item__image"
            [ngClass]="{ selected: axisSelected === 'Y' }"
          />
        </button>
      </div>
    </div>

    <div class="trash-icon" *ngIf="selectedCrossbar">
      <fa-icon
        [icon]="faTrashAlt"
        class="icon"
        (click)="removeCrossbar()"
      ></fa-icon>
    </div>
  </div>

  <!-- Set profile bar -->
  <div class="set-profile-bar">
    <button class="btn btn-secondary" (click)="associateUnionProfile()">
      Asociar perfil de unión
      <fa-icon [icon]="faExternalLinkAlt" class="btn-icon"></fa-icon>
    </button>

    <div class="profile-selected-info">
      <h5 style="text-align: center">Perfil seleccionado</h5>
      <div class="profile-info" *ngIf="selectedProfile">
        <app-input
          title="Código"
          label="Código"
          width="100%"
          justifyContent="flex-start"
        >
          <span>{{ selectedProfile.code }}</span>
        </app-input>

        <app-input
          title="Denominación"
          label="Denominación"
          width="100%"
          justifyContent="flex-start"
        >
          <span>{{ selectedProfile.description }}</span>
        </app-input>
      </div>
      <div class="overlap">
        <app-overlaps
          (overlapsEmitter)="setOverlaps($event)"
          [cutVariation]="overlaps.cutVariation"
        ></app-overlaps>
      </div>
    </div>
  </div>

  <!-- Form -->
  <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="areas-container">
      <app-input
        label="Número de elementos"
        justifyContent="flex-start"
        width="max-content"
        class="form-item"
      >
        <div class="area-quantity">
          <input
            type="number"
            class="area-quantity__input"
            formControlName="amount"
            (change)="createFormArray()"
          />
        </div>
      </app-input>

      <!-- Table -->
      <table
        class="table table-striped fade-in"
        *ngIf="areasFormArray()?.length > 0"
      >
        <thead>
          <tr>
            <th scope="col">Número</th>
            <th scope="col">{{ axisFrom }}</th>
          </tr>
        </thead>
        <tbody formArrayName="array">
          <tr
            *ngFor="let item of areasFormArray().controls; index as i"
            class="fade-in"
          >
            <th scope="row">{{ i + 1 }}</th>
            <th>
              <input
                type="number"
                class="axis-amount__input"
                [formControlName]="i"
              />
            </th>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- From container -->
    <div class="from-container">
      <!-- Horizontal -->
      <div
        class="from-container-horizontal fade-in"
        *ngIf="axisSelected === 'X'"
      >
        <!-- Top -->
        <div class="from-container-item">
          <input
            type="radio"
            value="top"
            id="top"
            name="from"
            formControlName="from"
          />
          <label for="top" class="from-container-item__label">
            <img
              src="../../../assets/icons/fromTop.svg"
              alt="Selecciona pisición de 'y' desde la parte superior"
              class="from-container-item__icon"
            />
            <span class="form-container-item__label-text"
              >Seleccionar desde arriba</span
            >
          </label>
        </div>

        <!-- Bottom -->
        <div class="from-container-item">
          <input
            type="radio"
            value="bottom"
            id="bottom"
            name="from"
            formControlName="from"
          />
          <label for="bottom" class="from-container-item__label">
            <img
              src="../../../assets/icons/fromBottom.svg"
              alt="Selecciona pisición de 'y' desde la parte inferior"
              class="from-container-item__icon"
            />
            <span class="form-container-item__label-text"
              >Seleccionar desde abajo</span
            >
          </label>
        </div>
      </div>

      <!-- Vertical -->
      <div class="from-container-vertical fade-in" *ngIf="axisSelected === 'Y'">
        <!-- Left -->
        <div class="from-container-item">
          <input
            type="radio"
            value="left"
            id="left"
            name="from"
            formControlName="from"
          />
          <label for="left" class="from-container-item__label">
            <img
              src="../../../assets/icons/fromLeft.svg"
              alt="Selecciona pisición de 'x' desde la izquierda"
              class="from-container-item__icon"
            />
            <span class="form-container-item__label-text"
              >Seleccionar desde izquierda</span
            >
          </label>
        </div>

        <!-- Right -->
        <div class="from-container-item">
          <input
            type="radio"
            value="right"
            id="right"
            name="from"
            formControlName="from"
          />
          <label for="right" class="from-container-item__label">
            <img
              src="../../../assets/icons/fromRight.svg"
              alt="Selecciona pisición de 'x' desde la derecha"
              class="from-container-item__icon"
            />
            <span class="form-container-item__label-text"
              >Seleccionar desde derecha</span
            >
          </label>
        </div>
      </div>
    </div>
  </form>

  <div class="button">
    <button class="btn btn-primary" (click)="onSubmit()">Aceptar</button>
  </div>
</div>
