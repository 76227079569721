<div class="accesories-groups">
  <div class="serie-tree">
    <app-series-tree
      [treeNode]="treeNode"
      [selectedSerie]="selectedSerie"
      (selectSerieEmitter)="onSelectSerie($event)"
      (searchEmitter)="onSearch($event)"
    ></app-series-tree>
  </div>

  <div class="content" *ngIf="selectedSerie">
    <!-- Content header -->
    <div class="content-header">
      <h1 class="title">{{ selectedSerie.name }}</h1>
      <div class="content-header-actions">
        <app-search-inline
          (searchEmitter)="onSearchGroup($event)"
        ></app-search-inline>
        <fa-icon
          [icon]="faPlus"
          class="icon add"
          (click)="addGroupToList()"
        ></fa-icon>
        <fa-icon
          [icon]="faTimes"
          class="icon close"
          (click)="onSelectSerie(null)"
        ></fa-icon>
      </div>
    </div>

    <!-- Content body -- Table -->
    <div class="content-body">
      <app-accessorie-group-list
        [selectedSerie]="selectedSerie"
      ></app-accessorie-group-list>
    </div>
  </div>
</div>
