import { Component, Input, OnInit } from '@angular/core'
import { OptimizationBarService } from 'src/app/@services/bar-optimization.service'
import {
  DataBar,
  OptimizationBarsCalculation,
} from 'src/app/@shared/@interfaces/optimizationbarCalculation'

// FontAwesome
import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { roundUp } from 'src/app/@helper/roundNumber'
import { ProfileCreator } from 'src/app/@shared/@interfaces/profileCreator.model'

@Component({
  selector: 'app-barlength-resume-table',
  templateUrl: './barlength-resume-table.component.html',
  styleUrls: ['./barlength-resume-table.component.scss'],
})
export class BarlengthResumeTableComponent implements OnInit {
  @Input() private modelId: number
  @Input() private quantity = 1

  // Icons
  public faInfo = faInfo

  public optimizations: OptimizationBarsCalculation[] = []
  public dataBars: Array<{
    profile: ProfileCreator
    bars: any
    cutOptimization: any
  }> = []
  public selectedBar: number

  public data: any[] = []

  constructor(private optimizationService: OptimizationBarService) {}

  ngOnInit(): void {
    if (this.hasModel()) this.calculate()
  }

  public hasModel(): boolean {
    return this.modelId ? true : false
  }

  private calculate(): void {
    const request = {
      model: this.modelId,
    }

    this.optimizationService.calculate(request).subscribe((optimizations) => {
      this.optimizations = optimizations
      this.sort()
    })
  }

  private sort(): void {
    this.optimizations.forEach((o, i) =>
      this.dataBars.push({
        profile: o.profile,
        bars: this.list(o.dataBar, i),
        cutOptimization: o.cutOptimization,
      })
    )

    let dataBars = []

    this.dataBars.forEach((d) => {
      d.bars.forEach((b) => {
        const data = this.countSameArrays(b.bars)

        const value = data.map((d) => {
          const arrayNumbers = this.countSameNumbers(d.array.bars)
          return arrayNumbers
        })

        dataBars.push({
          profile: d.profile,
          cutOptimization: d.cutOptimization,
          long: b.long,
          restTotal: b.restTotal,
          quantity: b.quantity,
          bars: { data, value },
          value,
        })
      })
    })

    const data = this.unifyByProfiles(dataBars)

    this.data = data

    let test = []
  }

  private countSameArrays(
    array: Array<{ rest: number; array: number[] }>
  ): Array<{ count: number; array: { rest: number; bars: number[] } }> {
    let arraycopy = [...array]
    let result = []

    while (arraycopy.length > 0) {
      const firstArray = arraycopy[0]
      const firstStringify = JSON.stringify(firstArray)

      let count = 1
      arraycopy = arraycopy
        .map((a, i) => {
          if (i === 0) return null

          const aStringify = JSON.stringify(a)
          const isSame = firstStringify === aStringify

          if (!isSame) return a

          count++
        })
        .filter((a) => a !== null && a !== undefined)

      result.push({
        count,
        array: firstArray,
      })

      this.countSameArrays(arraycopy)
    }

    return result
  }

  private countSameNumbers(
    array: number[]
  ): { count: number; value: number }[] {
    let arraycopy = [...array]

    let result = []

    while (arraycopy.length > 0) {
      const firstValue = arraycopy[0]
      let count = 1
      arraycopy = arraycopy
        .map((v, i) => {
          if (i === 0) return null
          const isSame = firstValue === v
          if (!isSame) return v

          count++
        })
        .filter((v) => v !== null && v !== undefined)

      result.push({
        count,
        value: firstValue,
      })
      this.countSameNumbers(arraycopy)
    }

    return result
  }

  private unifyByProfiles(array: any[]) {
    let arraycopy = [...array]

    const data = []

    while (arraycopy.length > 0) {
      const firstItem = arraycopy[0]

      const profileData = {
        profile: firstItem.profile,
        barDetails: [firstItem],
        restTotal: firstItem.restTotal,
        cutOptimization: firstItem.cutOptimization,
      }

      arraycopy = arraycopy
        .map((a, i) => {
          if (i === 0) return null
          const isSameProfile = firstItem.profile.id === a.profile.id
          if (!isSameProfile) return a

          profileData.barDetails.push(a)
        })
        .filter((a) => a !== null && a !== undefined)

      data.push(profileData)

      this.unifyByProfiles(arraycopy)
    }

    return data
  }

  private list(dataBar: DataBar[], i: number): Bar[] {
    const longs = [...new Set(dataBar.map((x) => x.long))]
    const result = []
    for (const long of longs) {
      const data = this.optimizations[i].dataBar.filter((x) => x.long === long)
      const rest = data.reduce(
        (sum, value) =>
          typeof value.rest == 'number' ? sum + value.rest : sum,
        0
      )
      result.push({
        long: roundUp(long, 2),
        restTotal: roundUp(rest, 2),
        quantity: data.length,
        bars: data.map((d) => ({ rest: d.rest, bars: d.bars })),
      })
    }
    return result
  }

  public selectBar(index: number) {
    if (index === this.selectedBar) return (this.selectedBar = null)
    this.selectedBar = index
  }

  public isSelectedBar(index: number): boolean {
    return this.selectedBar === index
  }
}

interface Bar {
  long: number
  restTotal: number
  quantity: number
  bars: Array<number[]>
}

interface BarDetails {
  long?: number
  quantity?: number
  rest?: number
  bar?: Partial<Bar>[]
}
