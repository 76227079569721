<div class="model-page-container d-flex flex-column" #modelpagewrapper>
  <!-- HORIZONTAL TOOLBAR -->
  <nav class="nav nav-horizontal">
    <a class="nav-link">
      <fa-icon [icon]="faSaveRegular"></fa-icon>
    </a>
    <a class="nav-link">
      <fa-icon [icon]="faUndo"></fa-icon>
    </a>
    <a class="nav-link">
      <fa-icon [icon]="faRedo"></fa-icon>
    </a>
    <a class="nav-link">
      <fa-icon [icon]="faTrashAlt"></fa-icon>
    </a>
    <a class="nav-link">
      <fa-icon [icon]="faFileExport"></fa-icon>
    </a>
    <a class="nav-link">
      <fa-icon [icon]="faFolderOpen"></fa-icon>
    </a>
    <a class="nav-link">
      <fa-icon [icon]="faHandPaper"></fa-icon>
    </a>
    <a class="nav-link" (click)="toggleRuleActivation()">
      <fa-icon
        [icon]="faRulerCombined"
        [ngStyle]="{
          color:
            (measureRuleService.isMeasuringRuleActive$ | async)
              ? 'red'
              : 'white'
        }"
      ></fa-icon>
    </a>
    <a class="nav-link">
      <fa-icon [icon]="faSearchPlus"></fa-icon>
    </a>
    <a class="nav-link">
      <fa-icon [icon]="faSearchMinus"></fa-icon>
    </a>
    <a class="nav-link img-container">
      <img
        src="../../../assets/icons/long-section.svg"
        alt="longitudinal section"
      />
    </a>
    <a class="nav-link">
      <img
        src="../../../assets/icons/cross-section.svg"
        class="custom-icon"
        alt="cross section"
      />
    </a>
  </nav>
  <div class="no-gutters d-flex flex-grow-1 overflow-hidden">
    <!-- VERTICAL TOOLBAR -->
    <div class="nav-vertical-container">
      <nav class="icons-sidebar">
        <app-add-crossbar-icon-item
          [gap]="gap"
          [modelObject]="modelObject"
          Tooltip
          text="Añadir travesaño/Partir area"
        ></app-add-crossbar-icon-item>
        <app-add-frame-icon-item
          [gap]="gap"
          [modelObject]="modelObject"
          (deleteFrameEmitter)="deleteFrame($event)"
          Tooltip
          text="Añadir Marco"
        >
        </app-add-frame-icon-item>
        <app-add-tipup-opening-icon-item
          [gap]="gap"
          [modelObject]="modelObject"
          Tooltip
          text="Añadir apertura abatible"
        ></app-add-tipup-opening-icon-item>
        <app-add-jonquillo-icon-item
          [gap]="gap"
          [modelObject]="modelObject"
          Tooltip
          text="Añadir junquillo"
        >
        </app-add-jonquillo-icon-item>
      </nav>
    </div>
    <!-- KONVA -->
    <div #konvaContainer class="flex-grow-1 konva-container">
      <!-- add icon from fontawesome -->
      <div class="actions">
        <app-model-option-button></app-model-option-button>
      </div>

      <app-konva
        #konvaComponent
        [konvaModel]="konvaModelObject"
        [konvaAreas]="konvaAreasArray"
      ></app-konva>
    </div>
    <!-- FIX DIALOG -->
    <div *ngIf="showSideNavRight" class="side-nav-right">
      <div class="fix-dialog">
        <ul class="nav nav-tabs nav-OptionHeader" id="myTab" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="dimensions-tab"
              data-toggle="tab"
              href="#dimensions"
              role="tab"
              aria-controls="dimensions"
              aria-selected="true"
            >
              Medidas
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="typology-tab"
              href="#typology"
              data-toggle="tab"
              role="tab"
              aria-controls="typology"
              aria-selected="true"
            >
              Datos tipología
            </a>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="dimensions"
            role="tabpanel"
            aria-labelledby="dimensions-tab"
          >
            <div class="dimensions-container">
              <div class="dimensions-title">Tamaño del hueco</div>
              <form [formGroup]="dimensionsForm" class="dimensions-form">
                <div class="form-row">
                  <div class="form-group col-6">
                    <label for="width">Ancho</label>
                    <div class="input-group">
                      <input
                        id="width"
                        type="number"
                        class="form-control"
                        formControlName="width"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">mm</span>
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-6">
                    <label for="height">Alto</label>
                    <div class="input-group">
                      <input
                        id="height"
                        type="number"
                        class="form-control"
                        formControlName="height"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">mm</span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div class="row button-container">
                <div class="col-12 text-center">
                  <button class="btn btn-primary" (click)="addArea()">
                    Añadir área
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            class="tab-pane typology fade"
            id="typology"
            role="tabpanel"
            aria-labelledby="typology-tab"
          >
            <img
              (click)="openBarLengthResumeModal()"
              class="typology__icon"
              src="../../../assets/icons/barlength.svg"
              alt="Longitud barras"
            />
            <img
              (click)="openSummaryAccessoriesModal()"
              class="typology__icon"
              src="../../../assets/icons/summary-accessories-model.svg"
              alt="Accesorios de modelo"
            />
          </div>
        </div>
      </div>

      <div class="areas-dimensions-container">
        <div class="dimensions-title areas">Áreas</div>
        <accordion>
          <accordion-group
            *ngFor="let area of areasArray; index as i"
            [panelClass]="customClass"
            [isOpen]="i === 0 ? true : false"
          >
            <div accordion-heading>
              <span class="dimensions-area-label"> Área {{ i + 1 }} </span>
              <span class="dimensions-area-buttons">
                <!-- TODO: Función cargar modelo existente -->
                <a
                  *ngIf="false"
                  class="area-link-button"
                  tooltip="cargar modelo"
                  containerClass="areaTooltip"
                  (click)="addExistingModel(area, $event)"
                >
                  <fa-icon [icon]="faPlus"></fa-icon>
                </a>
                <a
                  class="area-link-button"
                  tooltip="guardar área"
                  containerClass="areaTooltip"
                  (click)="saveChanges(area, $event)"
                >
                  <fa-icon [icon]="faSaveSolid"></fa-icon>
                </a>
                <a
                  class="area-link-button"
                  tooltip="eliminar área"
                  containerClass="areaTooltip"
                  (click)="openDeleteAreaDialog(area, $event)"
                >
                  <fa-icon [icon]="faTrash"></fa-icon>
                </a>
              </span>
            </div>
            <app-area-form
              [$area]="area"
              [areaWidth]="area.width"
              [areaHeight]="area.height"
              (areaDimensionsChangeEmitter)="areaDimensionsChange($event, area)"
            >
            </app-area-form>
          </accordion-group>
        </accordion>
      </div>
    </div>
  </div>
</div>
