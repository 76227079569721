import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'

import {
  faCheckCircle,
  faEdit,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'

import { ToastrService } from 'ngx-toastr'

import { invalidInputMessages } from 'src/app/@helper/invalidInputShowMessage'

import { ProfileCuttingOptimizationService } from 'src/app/@services/profile-cutting-optimization.service'
import {
  CutOptimization,
  CutOptimizationCreator,
} from 'src/app/@shared/@interfaces/cutOptimization'
import { IdAndNameModel } from 'src/app/@shared/@models/idAndName.model'

import { Profile } from '../../profile-management-editor.component'

@Component({
  selector: 'app-profile-cutting-optimization',
  templateUrl: './profile-cutting-optimization.component.html',
  styleUrls: ['./profile-cutting-optimization.component.scss'],
})
export class ProfileCuttingOptimizationComponent implements OnInit, OnChanges {
  @Input() private profile: Profile
  @Output() public submitEmitter: EventEmitter<any> = new EventEmitter<any>()

  public loading = false

  // Icons
  public faPlus = faPlus
  public faEdit = faEdit
  public faCheckCircle = faCheckCircle
  public faTrash = faTrash

  // Forms
  public form: FormGroup
  private readonly formModel: Object

  // Dependencies
  public cutTypes: IdAndNameModel[] = []
  public machinePositions: IdAndNameModel[] = []
  public machines: IdAndNameModel[] = []
  public optimizationParams: IdAndNameModel[] = []

  // selectedCut
  public selectedCut: CutOptimization

  // Cuts
  public cuts: CutOptimization[] = []

  constructor(
    private cuttingService: ProfileCuttingOptimizationService,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) {
    this.formModel = {
      id: [null],
      profile: [null],
      typeCut: [1, [Validators.required]],
      cutOptimization: [1, [Validators.required]],
      positionMachine: [1, [Validators.required]],
      rounding: [0, [Validators.required, Validators.min(0)]],
      groupedBars: [0, [Validators.required, Validators.min(0)]],
      openPackage: [true, [Validators.required]],
      discardBetweenPieces: [true, [Validators.required]],
      discardStartEndBar: [true, [Validators.required]],
      discardBetweenPiecesValue: [0, [Validators.required, Validators.min(0)]],
      discardStartEndBarValue: [0, [Validators.required, Validators.min(0)]],
      min: [0, [Validators.required, Validators.min(0)]],
      max: [0, [Validators.required, Validators.min(0)]],
      length2: [0, [Validators.required, Validators.min(0)]],
      length3: [0, [Validators.required, Validators.min(0)]],
      passed: [0, [Validators.required, Validators.min(0)]],
      maximumNumberMeasures: [0, [Validators.required, Validators.min(0)]],
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const hasProfileChanged = this.hasProfileChanged(changes)
    if (hasProfileChanged && this.profile) {
      this.buildForm()
      this.getCutByProfile()
    }

    if (!this.profile) {
      this.buildForm()
    }
  }

  ngOnInit(): void {
    this.getDependencies()
  }

  private hasProfileChanged(changes: SimpleChanges): boolean {
    const currentProfile = changes.profile?.currentValue
    const previusProfile = changes.profile?.previousValue

    return !(currentProfile?.id === previusProfile?.id)
  }

  // Form
  private buildForm(): void {
    this.form = this.fb.group(this.formModel)
  }
  private updateForm(profile: any): void {
    this.form.patchValue(profile)
  }

  public selecCutType(type: IdAndNameModel): void {
    this.form.get('typeCut').setValue(type.id)
  }

  public inputErrorMessages(
    control: string,
    requiredMessage?: string
  ): string[] {
    return invalidInputMessages(this.form, control, requiredMessage)
  }

  // SUBMIT
  public onSubmit(): any {
    if (!this.canSubmit()) return

    const isNewProfile = this.profile ? false : true

    // new Perfile
    if (isNewProfile) {
      return this.submitEmitter.emit()
    }

    this.updateCut()
  }
  private canSubmit(): boolean {
    this.form.markAllAsTouched()

    return this.form.valid
  }
  public buildCutOptimizationForSubmit(): CutOptimizationCreator | null {
    const cutOptimization: CutOptimization = this.form?.value

    if (!cutOptimization) return null

    return {
      profile: {
        id: this.profile?.id,
      },
      cutOptimization: {
        id: cutOptimization.cutOptimization,
      },
      typeCut: {
        id: cutOptimization.typeCut,
      },
      positionMachine: {
        id: cutOptimization.positionMachine,
      },
      rounding: cutOptimization.rounding,
      groupedBars: cutOptimization.groupedBars,
      openPackage: cutOptimization.openPackage,
      discardBetweenPieces: cutOptimization.discardBetweenPieces,
      discardStartEndBar: cutOptimization.discardStartEndBar,
      discardBetweenPiecesValue: cutOptimization.discardBetweenPiecesValue,
      discardStartEndBarValue: cutOptimization.discardStartEndBarValue,
      min: cutOptimization.min,
      max: cutOptimization.max,
      length2: cutOptimization.length2,
      length3: cutOptimization.length3,
      passed: cutOptimization.passed,
      maximumNumberMeasures: cutOptimization.maximumNumberMeasures,
    }
  }
  public createCut(): void {
    const cut = this.buildCutOptimizationForSubmit()

    this.cuttingService.createCut(cut).subscribe(
      (response) => {
        this.toastr.success('Se ha creado el corte')
      },
      (err) => {
        this.toastr.error('No ha sido posible crear el corte')
      }
    )
  }
  public updateCut(): void {
    const cut = this.buildCutOptimizationForSubmit()
    const id = this.form.get('id').value

    this.cuttingService.updateCut(id, cut).subscribe(
      (response) => {
        this.toastr.success('Se ha actualizado el corte')
      },
      (err) => {
        this.toastr.error('No ha sido posible actualizar el corte')
      }
    )
  }

  // Getters
  public getCutType(): string {
    const id = this.form ? this.form.get('typeCut').value : 0
    const type = this.cutTypes.find((value) => value.id === id)?.name ?? 'Tipo'
    return type
  }
  public getPosition(): string {
    const id = this.form.get('positionMachine').value

    const position =
      this.machinePositions.find((value) => value.id === id)?.name ?? 'Posición'

    return position
  }
  public isOptimusBar(): boolean {
    return this.form.get('cutOptimization').value === 2
  }
  public isMultiple(): boolean {
    return this.form.get('cutOptimization').value === 3
  }

  public selecPosition(position: IdAndNameModel): void {
    this.form.get('positionMachine').setValue(position.id)
  }

  // CRUD when profile exist
  private getCutByProfile(): void {
    this.cuttingService.getCutByProfile(this.profile?.id).subscribe(
      (cuts: any[]) => {
        cuts.forEach((cut, index) => {
          const profileCut: CutOptimization = {
            ...cut,
            typeCut: cut.typeCut.id,
            cutOptimization: cut.cutOptimization.id,
            positionMachine: cut.positionMachine.id,
          }
          this.updateForm(profileCut)
        })
      },
      (err) => {}
    )
  }

  // Dependencies
  private getDependencies(): void {
    this.getCutTypes()
    this.getMachinePositions()
    this.getOptimizationParams()
  }

  private getOptimizationParams(): void {
    this.cuttingService.getOptimizationParams().subscribe(
      (params) => {
        this.optimizationParams = params
      },
      (err) => {}
    )
  }

  private getCutTypes(): void {
    this.cuttingService.getCutTypes().subscribe(
      (types) => {
        this.cutTypes = types
      },
      (err) => {}
    )
  }

  private getMachinePositions(): void {
    this.cuttingService.getMachinePositions().subscribe(
      (positions) => {
        this.machinePositions = positions
      },
      (err) => {}
    )
  }
}
