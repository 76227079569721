import { Component, OnInit } from '@angular/core'
import { TipupLeaftOpening } from 'src/app/@shared/@models/modelsElements/door-window-leaft-model'
import { DoorWindowTipupOpeningElement } from 'src/app/@shared/@models/modelsElements/door-window-tipupOpening-model'

import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { ProfileElement } from 'src/app/@shared/@models/modelsElements/Profile-model'

@Component({
  selector: 'app-dooe-window-info',
  templateUrl: './dooe-window-info.component.html',
  styleUrls: ['./dooe-window-info.component.scss'],
})
export class DooeWindowInfoComponent implements OnInit {
  // Icons
  public faChevronLeft = faChevronLeft
  public faChevronRight = faChevronRight

  public initialState: { opening: DoorWindowTipupOpeningElement }
  public opening: DoorWindowTipupOpeningElement

  public leafts: TipupLeaftOpening[] = []

  public leaftBuilt: Leaft[] = []
  public leaftSelected: Leaft = null

  public profileSelected: {
    type: string
    profile: ProfileElement
  } = null

  private leaftSelectedIndex = 0
  private profileSelectedIndex = 0

  constructor() {}

  ngOnInit(): void {
    this.opening = this.initialState?.opening
    this.leafts = this.opening.leafts
    this.buildObject()
  }

  public buildObject(): void {
    const leafts: Leaft[] = this.leafts.map((l, i) => {
      const sideProfiles = [
        {
          type: 'Superior',
          profile: l.profilesElements.top,
        },
        {
          type: 'Inferior',
          profile: l.profilesElements.bottom,
        },
        {
          type: 'Izquierdo',
          profile: l.profilesElements.left,
        },
        {
          type: 'Derecho',
          profile: l.profilesElements.right,
        },
        ...(this.opening.doorStepElement
          ? [{ type: 'Umbral', profile: this.opening.doorStepElement }]
          : []),
      ].flat()

      const optionalProfiles = [
        ...(l.inversor ? [{ type: 'Inversor', profile: l.inversor }] : []),
        ...(l.dripTray ? [{ type: 'Canaleta', profile: l.dripTray }] : []),
      ]

      return {
        index: i + 1,
        isMain: l.hasHandleDoor,
        profiles: [...sideProfiles, ...optionalProfiles],
      }
    })

    this.leaftBuilt = leafts
    this.leaftSelected = this.leaftBuilt[this.leaftSelectedIndex]

    this.profileSelected = this.leaftSelected.profiles[
      this.profileSelectedIndex
    ]
  }

  public previusLeaft(): void {
    const isCero = this.leaftSelectedIndex <= 0

    if (isCero) this.leaftSelectedIndex = this.leaftBuilt.length - 1
    if (!isCero) this.leaftSelectedIndex--

    this.selectLeaft()
  }
  public nextLeaft(): void {
    const isBiggerThanArray =
      this.leaftSelectedIndex >= this.leaftBuilt.length - 1

    if (isBiggerThanArray) this.leaftSelectedIndex = 0
    if (!isBiggerThanArray) this.leaftSelectedIndex++

    this.selectLeaft()
  }

  public previustProfile(): void {
    const isCero = this.profileSelectedIndex <= 0

    if (!isCero) this.profileSelectedIndex--
    if (isCero)
      this.profileSelectedIndex = this.leaftSelected.profiles.length - 1

    this.profileSelected = this.leaftSelected.profiles[
      this.profileSelectedIndex
    ]
  }
  public nextProfile(): void {
    const isBiggerThanArray =
      this.profileSelectedIndex >= this.leaftSelected.profiles.length - 1

    if (isBiggerThanArray) this.profileSelectedIndex = 0
    if (!isBiggerThanArray) this.profileSelectedIndex++

    this.profileSelected = this.leaftSelected.profiles[
      this.profileSelectedIndex
    ]
  }

  private selectLeaft(): void {
    this.leaftSelected = this.leaftBuilt[this.leaftSelectedIndex]

    this.profileSelectedIndex = 0
    this.profileSelected = this.leaftSelected.profiles[
      this.profileSelectedIndex
    ]
  }
}

interface Leaft {
  index: number
  isMain: boolean
  profiles: Array<{
    type: string
    profile: ProfileElement
  }>
}
