<div class="header-container">
  <div>
    <h3 class="title">
      <button class="btn" (click)="back()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <strong>Fórmulas</strong>
    </h3>
  </div>
  <app-search-inline (searchEmitter)="onSearch($event)"></app-search-inline>
</div>

<mat-tree [dataSource]="treeNode" [treeControl]="treeControl" *ngIf="treeNode.length > 0">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding class="mat-tree-node">
    <span class="tree-item__description">
      {{ node.formula.template }}
    </span>
    <button class="btn" (click)="onSelectFormula(node.formula)">
      <mat-icon class="icon" [ngClass]="{
          selected: selectedFormula?.id === node?.formula?.id
        }">
        visibility
      </mat-icon>
    </button>
  </mat-tree-node>
</mat-tree>