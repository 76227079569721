import { Component, OnInit } from '@angular/core'
import { NgxSpinnerService } from 'ngx-spinner'
import { FormulaNode } from 'src/app/@components/formulas-tree/formulas-tree.component'
import { ProfileAccesoriesCombinationService } from 'src/app/@services/profile-accesories-combination.service'
import { Formula } from 'src/app/@shared/@interfaces/formula'

@Component({
  templateUrl: './formulas.component.html',
  styleUrls: ['./formulas.component.scss'],
})
export class FormulasComponent implements OnInit {
  public formulas: Formula[] = []
  public treeNode: FormulaNode[] = []

  public selectedFormula: Formula

  constructor(
    private profileAccesoriesCombinationService: ProfileAccesoriesCombinationService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.getFormulas()
  }

  public onSelectFormula(formula: Formula): void {
    this.selectedFormula = formula
  }

  private getFormulas(): void {
    this.spinner.show()
    this.profileAccesoriesCombinationService.getFormulas().subscribe(
      (formulas) => {
        this.formulas = formulas
        this.buildTree()
        this.spinner.hide()
      },
      (err) => {}
    )
  }

  private buildTree() {
    this.treeNode =
      this.formulas?.map((a) => ({
        formula: a,
      })) ?? []
  }

  public onSearch(criteria: string): void {
    if (!criteria) return this.buildTree()
    this.spinner.show()

    criteria = criteria?.toLowerCase() ?? ''

    let auxTreeNode: FormulaNode[] = []

    this.formulas.forEach((a) => {
      const original = a.original.toLowerCase()

      if (original.includes(criteria)) auxTreeNode.push({ formula: a })
    })

    this.treeNode = null
    this.treeNode = auxTreeNode

    this.spinner.hide()
  }
}
