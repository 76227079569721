import { Vector } from '../@shared/@interfaces/vector'
import { degreeToRadians } from './degreeToRadians'

export const rotateVector = (vector: Vector, degree: number): Vector => {
  const radians = degreeToRadians(degree)
  const { cos, sin } = Math

  return {
    x: vector.x * cos(radians) - vector.y * sin(radians),
    y: vector.x * sin(radians) + vector.y * cos(radians),
  }
}
