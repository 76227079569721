// ANGULAR
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms'
// TOOLS
import { Subject, Subscription } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { ToastrService } from 'ngx-toastr'
// Fontawesome
import { faSortDown } from '@fortawesome/free-solid-svg-icons'
// Helper
import { invalidInputMessages } from 'src/app/@helper/invalidInputShowMessage'
import {
  inputDecimalNumberRegex,
  decimalNumberValidationMessage,
} from 'src/app/@helper/constants'
import { roundUp } from 'src/app/@helper/roundNumber'
import { customTextToNumberInput } from 'src/app/@helper/customTextToNumberInput'
// MODELS
import { Assembly } from 'src/app/@shared/@interfaces/assemby.model'
import { Material } from 'src/app/@shared/@interfaces/material.model'
import { ProfileCreator } from 'src/app/@shared/@interfaces/profileCreator.model'
import { Vertex } from 'src/app/@shared/@interfaces/vertex'
import { DxfContent } from 'src/app/@shared/@interfaces/dxf-content'
// SERVICES
import { ProfileService } from 'src/app/@services/profile.service'
import {
  CotasDimmesion,
  CotasServiceService,
  DxfDimensions,
} from 'src/app/@services/cotas-service.service'
import { SeriesService } from 'src/app/@services/series.service'
import { DxfEditorService } from 'src/app/@services/dxf-editor.service'
// COMPONENTS
import { DxfEditorComponent } from '../../dxf-editor/dxf-editor.component'
import { Profile } from '../../profile-management-editor.component'
import { faWizardsOfTheCoast } from '@fortawesome/free-brands-svg-icons'

@Component({
  selector: 'app-technical-data',
  templateUrl: './technical-data.component.html',
  styleUrls: ['./technical-data.component.scss'],
})
export class TechnicalDataComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('dxfEditor')
  private dxfEditor: DxfEditorComponent

  @Input()
  set $isNewProfile(isNew: boolean) {
    this.isNewProfile = isNew
  }
  private isNewProfile: boolean

  @Input()
  set $profile(profileCode: Profile) {
    this.profile = profileCode
  }
  private profile: Profile

  @Input()
  set $serieID(serieID: number) {
    this.serieID = serieID
  }
  private serieID: number

  @Input()
  set $profileObject(profile: ProfileCreator | null) {
    this.profileObject = profile
  }
  private profileObject: ProfileCreator | null

  @Input()
  set $profileParsedDxf($profileParsedDxf: DxfContent) {
    this.profileParsedDxf = $profileParsedDxf
  }

  get $profileParsedDxf(): DxfContent {
    return this.profileParsedDxf
  }
  public profileParsedDxf: DxfContent

  // Form
  public form: FormGroup
  private canUpdateForm = false
  private isFirstEntry = true
  private isFormTouched = false

  // Dropdowns
  public selectedMaterial: Material = {
    id: -1,
    name: 'Materiales',
  }
  public selectedAssembly: Assembly = {
    id: -1,
    name: 'Ensamblado',
  }

  public assemblies: Assembly[] = []
  public materials: Material[] = []

  // Icons
  public faSortDown = faSortDown

  // DXF
  public dxfProfileObject: File
  private closestVertice = new Subject()
  public closestVertice$ = this.closestVertice.asObservable()

  private dxfDimensions: DxfDimensions
  private dxfDimensionsSubscription: Subscription

  private cotasDimensionsSubscription: Subscription

  private isDxfFromDBSubscription: Subscription
  private isDxfFromDB = false

  private dxfAbsolteDimensionsSubscription: Subscription

  @Output()
  public profileCreatorEmitter: EventEmitter<any> = new EventEmitter<any>()

  @Output()
  public isDisassembledEmitter = new EventEmitter<boolean>()

  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService,
    private serieService: SeriesService,
    private dxfService: DxfEditorService,
    private cotasService: CotasServiceService,
    private toastr: ToastrService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    // control if "changes.$profile" has previusValue property
    const hasProfilePreviusChange = changes.$profile.previousValue
      ? true
      : false

    // Control if profile has been changed
    const isProfileChanged = hasProfilePreviusChange
      ? changes.$profile.currentValue.id !== changes.$profile.previousValue.id
      : false

    // Control if exist profileObject and if has been changed
    const hadChangedProfileObject =
      (changes.$profileObject ? true : false) && this.profileObject

    // isFirstEntry control first set form
    const canUpdate =
      (hadChangedProfileObject && this.canUpdateForm) ||
      (this.isFirstEntry && this.canUpdateForm)

    if (canUpdate) this.setForm()
    if (hasProfilePreviusChange && isProfileChanged && this.canUpdateForm) {
      this.isFormTouched = false
      this.cleanForm()
    }
  }

  ngOnInit(): void {
    this.buildForm()
    this.getDataFromDB()
    this.dxfSubscribe()

    if (!this.profile.id) this.getSerie()

    // Cotas
    this.cotasDimensionsSubscription = this.cotasService.cotasDimensions$.subscribe(
      (cotasDimensions) => {
        this.updateFormFromCotas(cotasDimensions)
      }
    )

    this.dxfDimensionsSubscription = this.cotasService.dxfDimensions$.subscribe(
      (dimensions) => {
        if (!this.profile.id) {
          const width = Math.abs(
            roundUp(dimensions.end.x - dimensions.start.x, 2)
          )
          const height = Math.abs(
            roundUp(dimensions.end.y - dimensions.start.y, 2)
          )

          this.form
            .get('thicknessTotal')
            .setValue(width.toString().replace('.', ','))
          this.form
            .get('heightTotal')
            .setValue(height.toString().replace('.', ','))

          this.createCotaE(Number(width))
        }
      }
    )

    this.isDxfFromDBSubscription = this.dxfService.isDxfFromDB$.subscribe(
      (is) => {
        this.isDxfFromDB = is
      }
    )
  }

  ngOnDestroy(): void {
    this.cotasDimensionsSubscription?.unsubscribe()
    this.dxfDimensionsSubscription?.unsubscribe()
    this.dxfAbsolteDimensionsSubscription?.unsubscribe()
    this.isDxfFromDBSubscription?.unsubscribe()
    this.dxfService?.onDestroy()
    this.cotasService?.onDestroy()
  }

  private getSerie(): void {
    this.serieService.getSerieById(this.serieID).subscribe((serie) => {
      this.setBarLengthFromSerie(serie.barLength)
    })
  }

  private setBarLengthFromSerie(barLength: number): void {
    this.form.get('longitude').setValue(barLength)
  }

  public onSubmit(): void {
    this.form.markAllAsTouched()
    this.isFormTouched = true

    const isCanSubmit = this.canSubmit()
    if (isCanSubmit) {
      this.profileCreatorEmitter.emit({
        profile: this.buildObjectForSubmit(),
        dxf: this.loadProfileDxf(),
      })
    }
  }

  public dxfFileEmitter(dxfFileEmitter: any): void {
    this.dxfProfileObject = dxfFileEmitter
  }

  public loadProfileDxf(): FormData {
    const formData = new FormData()
    formData.append('file', this.dxfProfileObject)

    return formData
  }

  public onClosestVerticeChange(vertice: Vertex): void {
    this.closestVertice.next(vertice)
  }

  public buildObjectForSubmit(): ProfileCreator {
    const formValue = this.form.value
    const internalCamera = this.form.get('internalCamera').value
    return {
      assembly: {
        id: this.selectedAssembly.id,
      },

      material: {
        id: this.selectedMaterial.id,
      },
      serie: {
        id: this.serieID,
      },
      code: this.profile.name,
      description: this.profile.description,
      thicknessTotal: Number(formValue.thicknessTotal.replace(',', '.')),
      heightTotal: Number(formValue.heightTotal.replace(',', '.')),
      externalFin: Number(formValue.externalFin.replace(',', '.')),
      externalTooth: Number(formValue.externalTooth.replace(',', '.')),
      internalFin: Number(formValue.internalFin.replace(',', '.')),
      internalTooth: Number(formValue.internalTooth.replace(',', '.')),
      internalCamera: internalCamera,
      correction: formValue.correction,
      thicknessCrystal: formValue.thicknessCrystal,
      perimeterViews: formValue.perimeterViews,
      perimeterTotal: formValue.perimeterTotal,
      longitude: formValue.longitude,
      kg: formValue.kg,
      reversible: formValue.reversible,
      clipped: formValue.clipped,
      thermalCut: formValue.thermalCut,
      wx: formValue.wx,
      wy: formValue.wy,
      jx: formValue.jx,
      jy: formValue.jy,
    }
  }

  private buildForm(): void {
    this.form = this.fb.group({
      thicknessTotal: [
        '0',
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern(inputDecimalNumberRegex),
        ],
      ],
      heightTotal: [
        '0',
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern(inputDecimalNumberRegex),
        ],
      ],
      externalFin: [
        '0',
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern(inputDecimalNumberRegex),
        ],
      ],
      externalTooth: [
        '0',
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern(inputDecimalNumberRegex),
        ],
      ],
      internalFin: [
        '0',
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern(inputDecimalNumberRegex),
        ],
      ],
      internalTooth: [
        '0',
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern(inputDecimalNumberRegex),
        ],
      ],
      correction: [0, [Validators.required, Validators.min(0)]],
      thicknessCrystal: [0, [Validators.required, Validators.min(0)]],
      longitude: [0, [Validators.required, Validators.min(0)]],
      kg: [0, [Validators.required, Validators.min(0)]],
      assembly: ['', [Validators.required]],
      material: ['', [Validators.required]],
      reversible: [false, [Validators.required]],
      clipped: [false, [Validators.required]],
      thermalCut: [false, [Validators.required]],
      internalCamera: [
        {
          value: 0,
          disabled: true,
        },
        [Validators.required, Validators.min(1)],
      ],
    })
  }

  getFormControl(controlName: string): AbstractControl {
    return this.form.get(controlName)
  }

  private setForm(): void {
    if (!this.profileObject) return
    this.isFirstEntry = false

    const material = this.materials.find(
      (value) => value.id === this.profileObject.material.id
    )

    const assembly = this.assemblies.find(
      (value) => value.id === this.profileObject.assembly.id
    )

    this.checkIsDisAssembly(assembly)

    this.onSelectAssembly(assembly)
    this.onSelectMaterial(material)

    this.profileObject.thicknessTotal = this.profileObject.thicknessTotal
      .toString()
      .replace('.', ',')
    this.profileObject.heightTotal = this.profileObject.heightTotal
      .toString()
      .replace('.', ',')
    this.profileObject.externalFin = this.profileObject.externalFin
      .toString()
      .replace('.', ',')
    this.profileObject.externalTooth = this.profileObject.externalTooth
      .toString()
      .replace('.', ',')
    this.profileObject.internalFin = this.profileObject.internalFin
      .toString()
      .replace('.', ',')
    this.profileObject.internalTooth = this.profileObject.internalTooth
      .toString()
      .replace('.', ',')

    this.form.reset({
      ...this.profileObject,
      internalCamera: this.profileObject.internalCamera,
      assembly: assembly.name,
      material: material.name,
    })
  }

  private dxfSubscribe(): void {
    this.dxfAbsolteDimensionsSubscription = this.cotasService.dxfAbsoluteDimensions$
      .pipe(debounceTime(200))
      .subscribe((dxfdimension) => {
        if (!dxfdimension) return
        if (!this.profileObject) return

        const cotas: CotasDimmesion = {
          A: Number(this.profileObject.externalFin.replace(',', '.')),
          B: Number(this.profileObject.externalTooth.replace(',', '.')),
          C: Number(this.profileObject.internalFin.replace(',', '.')),
          D: Number(this.profileObject.internalTooth.replace(',', '.')),
          E: Number(this.profileObject.thicknessTotal.replace(',', '.')),
        }

        this.createCotaE(cotas.E)
        this.createCotaA(cotas.A)
        this.createCotaC(cotas.C)
        this.createCotaB(cotas.B)
        this.createCotaD(cotas.D)

        if (this.isDxfFromDB) {
          this.cotasService.hideShowVerticalCotas(false)
          this.cotasService.hideShowHorizontalCotas(false)
        } else {
          this.cotasService.cleanCotas()
          this.cotasService.createCotaE()
        }
      })
  }

  private cleanForm(): void {
    this.onSelectAssembly(null)

    this.onSelectMaterial(null)
    this.buildForm()
    this.dxfEditor.resetDxf()
  }

  public invalidInputMessages(
    control: string,
    requiredMessage?: string
  ): string[] {
    return invalidInputMessages(
      this.form,
      control,
      requiredMessage,
      decimalNumberValidationMessage
    )
  }

  public isInvalidAssembly(assembly: Assembly): boolean {
    const index = this.assemblies.findIndex((value) => value.id === assembly.id)
    return index === -1 && this.isFormTouched ? true : false
  }
  public isInvalidMaterial(material: Material): boolean {
    const index = this.materials.findIndex((value) => value.id === material.id)
    return index === -1 && this.isFormTouched ? true : false
  }

  private canSubmit(): boolean {
    const isValidAssembly = !this.isInvalidAssembly(this.selectedAssembly)
    const isValidMaterial = !this.isInvalidMaterial(this.selectedMaterial)

    return this.form.valid && isValidAssembly && isValidMaterial
  }

  private async getDataFromDB(): Promise<void> {
    await this.getAssemblies()
    await this.getMaterials()
    this.canUpdateForm = true
  }

  private getAssemblies(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.profileService.getAssemblies().subscribe(
        (assemblies) => {
          this.assemblies = assemblies

          resolve()
        },
        (err) => {
          this.toastr.error(err)
        }
      )
    })
  }

  private checkIsDisAssembly(assembly: Assembly): void {
    const disAssemblyId = 3
    const isDisAssembly = assembly?.id === disAssemblyId

    this.isDisassembledEmitter.emit(isDisAssembly)
  }

  private getMaterials(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.profileService.getMaterials().subscribe(
        (materials) => {
          this.materials = materials

          resolve()
        },
        (err) => {
          this.toastr.error(err)
        }
      )
    })
  }

  // On selects

  public onSelectAssembly(assembly: Assembly | null): void {
    this.selectedAssembly = assembly ?? { id: -1, name: 'Ensamblado' }
    this.form.get('assembly').setValue(assembly)
    this.checkIsDisAssembly(assembly)
  }
  public onSelectMaterial(material: Material | null): void {
    this.selectedMaterial = material ?? { id: -1, name: 'Materiales' }
    this.form.get('material').setValue(material)
  }

  // Create cotas
  public createCotaE(width?: any): void {
    this.cotasService.createCotaE(Number(width.toString().replace(',', '.')))
  }
  public createCotaA(width?: any): void {
    width = this.checkWidth(width)
    this.cotasService.createCotaA(width)
  }
  public createCotaB(width?: any): void {
    width = this.checkWidth(width)
    this.cotasService.createCotaB(width)
  }
  public createCotaC(width?: any): void {
    width = this.checkWidth(width)
    this.cotasService.createCotaC(width)
  }
  public createCotaD(width?: any): void {
    width = this.checkWidth(width)
    this.cotasService.createCotaD(width)
  }

  public checkWidth(width: any) {
    if (width && typeof width === 'string') {
      width = Number(width.replace(',', '.'))
    }
    return width
  }

  private updateFormFromCotas(cotas: CotasDimmesion): void {
    this.form.get('internalCamera').setValue(cotas.E)
    this.form.get('externalFin').setValue(cotas.A.toString().replace('.', ','))
    this.form
      .get('externalTooth')
      .setValue(cotas.B.toString().replace('.', ','))
    this.form.get('internalFin').setValue(cotas.C.toString().replace('.', ','))
    this.form
      .get('internalTooth')
      .setValue(cotas.D.toString().replace('.', ','))
  }

  decimalNumberValidation(event: any): void {
    customTextToNumberInput(event)
  }
}
