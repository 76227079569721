import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router';

import { CrystalService } from 'src/app/@services/crystal.service';

import { Crystal } from 'src/app/@shared/@interfaces/crystal';
import { FlatNode } from 'src/app/@shared/MatTreeConfig/treeDataStructure';

export interface CrystalNode {
  crystal: Crystal
  children?: CrystalNode[]
}

@Component({
  selector: 'app-crystals-tree',
  templateUrl: './crystals-tree.component.html',
  styleUrls: ['./crystals-tree.component.scss']
})

export class CrystalsTreeComponent implements OnInit {
  @Input() public treeNode: CrystalNode[] = []
  @Input() public selectedCrystal: Crystal

  @Output() public searchEmitter = new EventEmitter<string>()
  @Output() public addNewEmitter = new EventEmitter<void>()
  @Output() public selectCrystalEmitter = new EventEmitter<Crystal>()

  public treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable
  )

  constructor(private router: Router,
    private crystalService: CrystalService
  ) { }

  ngOnInit(): void { }

  public editNode(crystal: Crystal): void {
    this.selectCrystalEmitter.emit(crystal)
  }

  public deleteNode(crystal: Crystal) {
    this.crystalService.deleteCrystal(crystal.id).subscribe((crystals) => {
      this.treeNode = this.treeNode.filter((x) => x.crystal.id != crystal.id);
    })
  }

  public addNew(): void {
    this.addNewEmitter.emit()
  }

  public onSearch(criteria: string): void {
    this.searchEmitter.emit(criteria)
  }

  public back(): void {
    this.router.navigateByUrl('/files')
  }
}
