import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { OptimizationBarCalculateRequest } from '../@shared/@interfaces/optimizationBarCalculateRequest'
import { OptimizationBarsCalculation } from '../@shared/@interfaces/optimizationbarCalculation'
import { ProfileCreator } from '../@shared/@interfaces/profileCreator.model'

@Injectable({
  providedIn: 'root',
})
export class OptimizationBarService {
  private apiUrl = environment.apiUrl

  constructor(private http: HttpClient) {}

  public calculate(
    request: OptimizationBarCalculateRequest
  ): Observable<OptimizationBarsCalculation[]> {
    return this.http.post<OptimizationBarsCalculation[]>(
      `${this.apiUrl}optimizations-bars/calculate`,
      request
    )
  }
}
