import { Component, EventEmitter, Input, OnInit } from '@angular/core'

import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import { BsModalRef } from 'ngx-bootstrap/modal'
import { Overlaps } from 'src/app/@components/overlaps/overlaps.component'
import { IDialog } from 'src/app/@shared/@interfaces/dialog'
import { ProfileCreator } from 'src/app/@shared/@interfaces/profileCreator.model'
import { JonquilloElement } from 'src/app/@shared/@models/modelsElements/jonquillo-model'
import { Side } from 'src/app/@shared/@types/side.types'

@Component({
  selector: 'app-jonquillo-dialog',
  templateUrl: './jonquillo-dialog.component.html',
  styleUrls: ['./jonquillo-dialog.component.scss'],
})
export class JonquilloDialogComponent implements OnInit, IDialog {
  $response: EventEmitter<{
    profile: ProfileCreator
    superposition: Side<number>
    cutVariation: Side<{ id: number; value: number; value2?: number }>
  }> = new EventEmitter()

  // Inputs
  public jonquillo: JonquilloElement
  public serieId: number
  public profile: ProfileCreator = null

  public superposition = 0
  public cutVariation = { id: 1, value: 0 }

  public faTrash = faTrashAlt

  constructor(public modalRef: BsModalRef) {}

  ngOnInit(): void {
    if (this.jonquillo) {
      this.setProfile(this.jonquillo.sidesElement.top.profile)
    }
  }

  confirm(): void {
    this.$response.emit()
    this.modalRef.hide()
  }
  decline(): void {
    this.modalRef.hide()
  }

  public setProfile(profile: ProfileCreator): void {
    this.profile = profile
  }

  public setSuperpositionAndCutVariation(data: Overlaps) {
    this.superposition = data.overlap
    this.cutVariation = data.cutVariation
  }

  public remove() {
    this.$response.emit(null)
    this.modalRef.hide()
  }

  save(): void {
    const response = {
      profile: this.profile,
      superposition: {
        top: this.superposition,
        bottom: this.superposition,
        left: this.superposition,
        right: this.superposition,
      },
      cutVariation: {
        top: this.cutVariation,
        bottom: this.cutVariation,
        left: this.cutVariation,
        right: this.cutVariation,
      },
    }

    this.$response.emit(response)
    this.modalRef.hide()
  }
}
