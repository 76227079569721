export class KonvaAreaModel {
  x: number
  y: number
  width: number
  height: number
  stroke: string
  profile: konvaProfileModel
  id: number | string

  constructor(
    x: number,
    y: number,
    width: number,
    height: number,
    stroke: string,
    id?: number | string
  ) {
    this.x = x
    this.y = y
    this.width = width
    this.height = height
    this.stroke = stroke
    this.profile = new konvaProfileModel()
  }
}
export class konvaProfileModel {
  top: boolean
  bottom: boolean
  left: boolean
  right: boolean

  constructor() { }
}
