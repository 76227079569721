export class KonvaModelModel {
  width: number
  height: number
  container: string

  constructor(width: number, height: number, container: string) {
    this.width = width
    this.height = height
    this.container = container
  }
}
