import { Component, EventEmitter, OnInit } from '@angular/core'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { IDialog } from 'src/app/@shared/@interfaces/dialog'

import { faTimes } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-guillotine-dialog',
  templateUrl: './guillotine-dialog.component.html',
  styleUrls: ['./guillotine-dialog.component.scss'],
})
export class GuillotineDialogComponent implements OnInit, IDialog {
  $response: EventEmitter<any> = new EventEmitter<any>()

  // Icons
  public faTimes = faTimes

  constructor(private modalRef: BsModalRef) {}

  confirm(): void {
    this.modalRef.hide()
  }

  decline(): void {
    this.modalRef.hide()
  }

  ngOnInit(): void {}
}
