import { Component, Input, OnInit, SimpleChanges } from '@angular/core'
import Konva from 'konva'
import { ToastrService } from 'ngx-toastr'
import { fromEvent, Subscription } from 'rxjs'
import { debounceTime, map } from 'rxjs/operators'
import { JonquilloDialogComponent } from 'src/app/@dialogs/jonquillo-dialog/jonquillo-dialog.component'
import { radianToDegree } from 'src/app/@helper/radianToDegree'
import { roundUp } from 'src/app/@helper/roundNumber'
import { JonquilloService } from 'src/app/@services/jonquillo.service'
import { ModalService } from 'src/app/@services/modal.service'
import {
  PseudoFrameDrawerService,
  SidePseudoFrameCreatorStartInt,
} from 'src/app/@services/modelDrawServices/pseudo-frame-drawer.service'
import { SuperpositionDrawerService } from 'src/app/@services/modelDrawServices/superposition-drawer.service'
import { UNIONS_TYPES } from 'src/app/@services/union.service'
import {
  JonquilloCreator,
  JunquilloProfileSide,
} from 'src/app/@shared/@interfaces/JonquilloCreator'
import { ProfileCreator } from 'src/app/@shared/@interfaces/profileCreator.model'
import { DialogInstanceModel } from 'src/app/@shared/@models/dialog-instance-model'
import {
  Area,
  AreaModel,
} from 'src/app/@shared/@models/modelsElements/area-model'
import { Gap } from 'src/app/@shared/@models/modelsElements/gap-model'
import { JonquilloElement } from 'src/app/@shared/@models/modelsElements/jonquillo-model'
import { ProfileElement } from 'src/app/@shared/@models/modelsElements/Profile-model'
import { NewModelModel } from 'src/app/@shared/@models/new-model-model'
import { Side, SideOptions } from 'src/app/@shared/@types/side.types'
import { UnionPositions } from 'src/app/@shared/@types/unionPositions.type'
import { EntityInformationService } from 'src/app/@shared/entity-information/entity-information.service'
import { Vector2 } from 'three'
import * as uuidV4 from 'uuid'
import { JonquilloInfoComponent } from '../../entity-info/jonquillo-info/jonquillo-info.component'
import { IconSidebarItem } from '../icon-sidebar-item/icon-sidebar.interface'

@Component({
  selector: 'app-add-jonquillo-icon-item',
  templateUrl: './add-jonquillo-icon-item.component.html',
  styleUrls: ['./add-jonquillo-icon-item.component.scss'],
})
export class AddJonquilloIconItemComponent implements OnInit, IconSidebarItem {
  @Input() gap: Gap
  @Input() modelObject: NewModelModel

  public gapSubscription: Subscription

  public canUpdate = false

  constructor(
    private toastr: ToastrService,
    private modalService: ModalService,
    private pseudoFrameDrawer: PseudoFrameDrawerService,
    private jonquilloService: JonquilloService,
    private entityInfoService: EntityInformationService,
    private superpositionService: SuperpositionDrawerService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.gap) this.gapSubscribe()
  }

  ngOnDestroy(): void {
    this.gapSubscription?.unsubscribe()
  }

  public gapSubscribe(): void {
    if (this.gapSubscription) return

    this.gapSubscription = this.gap.gapUpdated$.subscribe(() => {
      this.canUpdate = this.canActivateBotton()
    })
  }

  public canActivateBotton(): boolean {
    const selectedArea = this.gap?.selectedArea
    const hasLeaft = selectedArea?.leaft ? true : false
    const hasFrame = selectedArea?.frames.length > 0 ? true : false
    const hasJonquillo = this.gap?.getSelectedElement<JonquilloElement>(
      JonquilloElement
    )
      ? true
      : false

    return hasLeaft || hasFrame || hasJonquillo
  }

  public addJonquillo(): any {
    const area = this.gap?.selectedArea

    const selectedJonquillo = this.gap?.getSelectedElement<JonquilloElement>(
      JonquilloElement
    )

    if (!area && !selectedJonquillo) {
      this.toastr.warning('Seleccione un area con apertura')
      return
    }

    const newAreaDialog = new DialogInstanceModel(JonquilloDialogComponent, {
      class: 'modal-dialog-centered',
      initialState: {
        serieId: this.modelObject.serie.id,
        jonquillo: selectedJonquillo,
      },
    })

    this.modalService.openModal(newAreaDialog).subscribe(
      (data: {
        profile: ProfileCreator
        superposition: Side<number>
        cutVariation: Side<{
          id: number
          value: number
          value2?: number
        }>
      }) => {
        const remove = data ? false : true

        if (remove) {
          this.removeJonquillo(selectedJonquillo)
          return this.gap.layer.draw()
        }

        const { profile, superposition, cutVariation } = data

        const update = profile && selectedJonquillo ? true : false
        const create = profile && !selectedJonquillo ? true : false

        if (update)
          this.updateJonquillo(selectedJonquillo, profile, superposition)

        if (create) {
          const jonquilloElement = this.createJonquillo(
            area,
            profile,
            superposition
          )

          const subs = fromEvent(jonquilloElement.group, 'contextmenu')
            .pipe(
              map((e) => {
                e.preventDefault()
                e.stopPropagation()
                return e
              }),
              debounceTime(100)
            )
            .subscribe((e: MouseEvent) => {
              this.entityInfoService.hide()
              this.entityInfoService.showCard(JonquilloInfoComponent, e, {
                jonquillo: jonquilloElement,
              })
            })

          jonquilloElement.addSubscriptions([subs])
        }

        this.gap.layer.draw()
      }
    )
  }

  private updateJonquillo(
    currentJonquillo: JonquilloElement,
    newProfile: ProfileCreator,
    superposition: Side<number>
  ): void {
    this.removeJonquillo(currentJonquillo)
    this.createJonquillo(
      currentJonquillo.associatedArea,
      newProfile,
      superposition
    )
  }

  private createJonquillo(
    area: AreaModel,
    jonquilloProfile: ProfileCreator,
    superposition: Side<number>
  ): JonquilloElement {
    const parentAreaContainer = area.container

    const jonquilloElement = this.drawJonquillo(
      area,
      jonquilloProfile,
      superposition
    )
    jonquilloElement.setAssociatedArea(area)
    jonquilloElement.container = parentAreaContainer

    area.addJonquillo(jonquilloElement)
    area.leaft?.addJonquillo(jonquilloElement)

    this.modifyArea(area, jonquilloElement)
    const newAreaContainer = new Area({
      parent: parentAreaContainer,
      width: area.width,
      height: area.height,
      x: area.x,
      y: area.y,
      modelId: parentAreaContainer.modelId,
    })

    parentAreaContainer.addElements([...(jonquilloElement.sideElements ?? [])])
    parentAreaContainer.setVerticalContain([newAreaContainer])
    area.container = newAreaContainer
    area.container.setVinculatedArea(area)
    parentAreaContainer.setVinculatedArea(null)

    const elements = jonquilloElement.sidesElement
    this.jonquilloService
      .createJonquillo(
        this.createFrame(jonquilloElement, area.id, jonquilloProfile, elements)
      )
      .subscribe((jonquillo) => {
        jonquilloElement.id = jonquillo.id
      })

    this.gap.subscribeJonquillo()
    jonquilloElement.select()

    return jonquilloElement
  }

  private removeJonquillo(jonquillo: JonquilloElement): void {
    const area = jonquillo.associatedArea
    const { profile } = jonquillo.sidesElement.left
    const scaleFactor = area.scaleWidth

    const isClipped = profile.clipped

    if (!isClipped) {
      const { left, right, top, bottom } = jonquillo.sidesElement

      const { startExt: leftSide } = left.pointsAsVector()
      const { startExt: topSide } = top.pointsAsVector()
      const { startExt: rightSide } = right.pointsAsVector()
      const { startExt: bottomSide } = bottom.pointsAsVector()

      const areaWidth = Math.abs(leftSide.x - rightSide.x) / scaleFactor
      const areaHeight = Math.abs(topSide.y - bottomSide.y) / scaleFactor

      const start = leftSide.clone()

      area.resize(areaWidth, areaHeight)
      area.moveScaled(start.x, start.y)
      area.removeJonquillo(jonquillo.uuid)

      const parentContainer = area.container.parent

      parentContainer.setHorizontalContain([])
      parentContainer.setVerticalContain([])
      parentContainer.removeElements()

      area.container = parentContainer
      area.container.setVinculatedArea(area)

      this.jonquilloService.deleteJonquillo(jonquillo.id).subscribe(() => {})
      jonquillo.destroy()

      return
    }

    const positionOffset = profile.internalCamera - profile.externalTooth

    const positionOffsetByClipped = profile.clipped
      ? positionOffset + 2 * profile.externalTooth
      : positionOffset

    const widthOffsetByClipped =
      2 * positionOffsetByClipped - 2 * profile.externalTooth

    const widthOffset = profile.clipped
      ? widthOffsetByClipped
      : 2 * positionOffsetByClipped + 2 * profile.externalTooth

    const heightOffset =
      2 * positionOffset + (profile.clipped ? 0 : 2 * profile.externalTooth)

    const areaWidth = area.width + widthOffset
    const areaheight = area.height + heightOffset

    const startOffsetX = profile.clipped
      ? profile.externalTooth
      : -profile.externalTooth

    const startOffsetY = profile.clipped ? 0 : -profile.externalTooth

    const areaStart = area
      .getNonScaledStartPosition()
      .add(
        new Vector2(
          -positionOffsetByClipped + startOffsetX,
          -positionOffset + startOffsetY
        ).multiplyScalar(scaleFactor)
      )

    area.resize(areaWidth, areaheight)
    area.moveScaled(areaStart.x, areaStart.y)
    area.removeJonquillo(jonquillo.uuid)

    const parentContainer = area.container.parent

    parentContainer.setHorizontalContain([])
    parentContainer.setVerticalContain([])
    parentContainer.removeElements()

    area.container = parentContainer
    area.container.setVinculatedArea(area)

    this.jonquilloService.deleteJonquillo(jonquillo.id).subscribe(() => {})
    jonquillo.destroy()
  }

  private modifyArea(
    area: AreaModel,
    jonquilloElement: JonquilloElement
  ): void {
    const { scaleWidth: scaleFactor } = area
    const { left, top, right, bottom } = jonquilloElement.sidesElement

    const leftPoints = left.pointsAsVector(left.internalFinDraw.points())
    const topPoints = top.pointsAsVector(top.internalFinDraw.points())
    const rightPoints = right.pointsAsVector(right.internalFinDraw.points())
    const bottomPoints = bottom.pointsAsVector(bottom.internalFinDraw.points())

    const newAreaStartPoint = new Vector2(
      leftPoints.startInt.x,
      topPoints.startInt.y
    )

    area.moveScaled(newAreaStartPoint.x, newAreaStartPoint.y)

    const newAreaWidth =
      Math.abs(rightPoints.startInt.x - leftPoints.endInt.x) / scaleFactor

    const newAreaHeight =
      Math.abs(bottomPoints.startInt.y - topPoints.endInt.y) / scaleFactor

    area.resize(newAreaWidth, newAreaHeight)
  }

  public createFrame(
    junquillo: JonquilloElement,
    areaId: number,
    profile: ProfileCreator,
    elements: Side<ProfileElement>
  ): JonquilloCreator {
    const { left, right, bottom, top } = elements

    const leftLong = left.calculateLength()
    const rightLong = right.calculateLength()
    const topLong = top.calculateLength()
    const bottomLong = bottom.calculateLength()

    const leftAngles = left.getProfileAngles()
    const rightAngles = right.getProfileAngles()
    const topAngles = top.getProfileAngles()
    const bottomAngles = bottom.getProfileAngles()

    return {
      model: this.modelObject.id,
      area: {
        id: areaId,
      },
      topProfile: {
        uuid: junquillo.sidesElement?.top?.uuid,
        profile: {
          id: profile.id,
        },
        extLong: topLong.ext,
        intLong: topLong.int,
        superimposition: 0,
        typeVariation: { id: 1 },
        cutVariation: 0,
        ang: roundUp(radianToDegree(topAngles?.mainAngle ?? 0), 2),
        ang2: roundUp(radianToDegree(topAngles?.secondAngle ?? 0), 2),
      },
      bottomProfile: {
        uuid: junquillo.sidesElement?.bottom?.uuid,
        profile: {
          id: profile.id,
        },
        extLong: bottomLong.ext,
        intLong: bottomLong.int,
        superimposition: 0,
        typeVariation: { id: 1 },
        cutVariation: 0,
        ang: roundUp(radianToDegree(bottomAngles?.mainAngle ?? 0), 2),
        ang2: roundUp(radianToDegree(bottomAngles?.secondAngle ?? 0), 2),
      },
      leftProfile: {
        uuid: junquillo.sidesElement?.left?.uuid,
        profile: {
          id: profile.id,
        },
        extLong: leftLong.ext,
        intLong: leftLong.int,
        superimposition: 0,
        typeVariation: { id: 1 },
        cutVariation: 0,
        ang: roundUp(radianToDegree(leftAngles?.mainAngle ?? 0), 2),
        ang2: roundUp(radianToDegree(leftAngles?.secondAngle ?? 0), 2),
      },
      rightProfile: {
        uuid: junquillo.sidesElement?.right?.uuid,
        profile: {
          id: profile.id,
        },
        extLong: rightLong.ext,
        intLong: rightLong.int,
        superimposition: 0,
        typeVariation: { id: 1 },
        cutVariation: 0,
        ang: roundUp(radianToDegree(rightAngles?.mainAngle ?? 0), 2),
        ang2: roundUp(radianToDegree(rightAngles?.secondAngle ?? 0), 2),
      },
    }
  }

  public drawJonquillo(
    area: AreaModel,
    profile: ProfileCreator,
    superposition: Side<number>
  ): JonquilloElement {
    const union = profile.clipped
      ? UNIONS_TYPES.cut90Horizontal
      : UNIONS_TYPES.cut45

    const unions = {
      'left-bottom': union,
      'left-top': union,
      'right-bottom': union,
      'right-top': union,
    }

    const sides = this.profilePoints(area, profile)
    const scaleFactor = this.gap.scaleWidth

    const profilesDraw = this.pseudoFrameDrawer.createPseudoFrameFromStartInt({
      cutVariations: {},
      has: {
        bottom: true,
        left: true,
        right: true,
        top: true,
      },
      scaleFactor: this.gap.scaleWidth,
      sides,
      superpositions: {},
      unions,
    })

    const profiles: Side<ProfileCreator> = {
      top: profile,
      bottom: profile,
      right: profile,
      left: profile,
    }

    const positions: Side<Vector2> = {
      top: sides.top.startPointInt,
      bottom: sides.bottom.startPointInt,
      left: sides.left.startPointInt,
      right: sides.right.startPointInt,
    }

    this.superpositionService.applySuperposition(
      unions,
      profilesDraw,
      superposition,
      scaleFactor
    )

    const profileElements = this.createProfileElements(
      profiles,
      profilesDraw,
      unions,
      positions
    )

    const jonquilloElement = new JonquilloElement(uuidV4.v4(), profileElements)
    jonquilloElement.isClipped = profile.clipped

    this.gap.layer.add(jonquilloElement.group)

    return jonquilloElement
  }

  private createProfileElements(
    profiles: { [k in SideOptions]: ProfileCreator },
    lines: { [k in SideOptions]: Konva.Line },
    unions: { [k in UnionPositions]: number },
    positions: { [k in SideOptions]: Vector2 }
  ): { [k in SideOptions]: ProfileElement } {
    let profileElements: { [k in SideOptions]?: ProfileElement } = {}

    Object.keys(profiles).forEach((side: SideOptions) => {
      const profileElement = new ProfileElement({
        gap: this.gap,
        profile: profiles[side],
        profileDraw: lines[side],
        scaleFactor: this.gap.scaleWidth,
        side,
        thickness: profiles[side].internalCamera,
        unions: unions,
        uuid: uuidV4.v4(),
        x: positions[side].x,
        y: positions[side].y,
        axis: side === 'top' || side === 'bottom' ? 'Horizontal' : 'Vertical',
      })

      profileElements[side] = profileElement
    })

    return profileElements as Side<ProfileElement>
  }

  private profilePoints(
    area: AreaModel,
    profile: ProfileCreator
  ): Side<SidePseudoFrameCreatorStartInt> {
    const scaleFactor = area.scaleWidth

    const startArea = area.getNonScaledStartPosition()

    const positionOffset = profile.internalCamera - profile.externalTooth

    const offsetByClipped = profile.clipped
      ? profile.externalTooth
      : profile.externalTooth

    const startPointTop = startArea
      .clone()
      .add(
        new Vector2(
          positionOffset + offsetByClipped,
          positionOffset + (profile.clipped ? 0 : offsetByClipped)
        ).multiplyScalar(scaleFactor)
      )

    const startPointBottom = startArea
      .clone()
      .add(new Vector2(0, area.height).multiplyScalar(scaleFactor))
      .add(
        new Vector2(
          positionOffset + offsetByClipped,
          -(positionOffset + (profile.clipped ? 0 : offsetByClipped))
        ).multiplyScalar(scaleFactor)
      )

    const startPointLeft = startArea
      .clone()
      .add(
        new Vector2(
          positionOffset + offsetByClipped,
          positionOffset + (profile.clipped ? 0 : profile.externalTooth)
        ).multiplyScalar(scaleFactor)
      )

    const startPointRight = startArea
      .clone()
      .add(new Vector2(area.width, 0).multiplyScalar(scaleFactor))
      .add(
        new Vector2(
          -(positionOffset + offsetByClipped),
          positionOffset + (profile.clipped ? 0 : profile.externalTooth)
        ).multiplyScalar(scaleFactor)
      )

    const length: Side<number> = {
      top: area.width - 2 * (positionOffset + offsetByClipped),
      bottom: area.width - 2 * (positionOffset + offsetByClipped),
      left:
        area.height -
        2 * (positionOffset + (profile.clipped ? 0 : offsetByClipped)),
      right:
        area.height -
        2 * (positionOffset + (profile.clipped ? 0 : offsetByClipped)),
    }

    return {
      bottom: {
        profile: profile,
        length: length.bottom,
        startPointInt: startPointBottom,
        width: profile.internalCamera,
      },
      top: {
        profile: profile,
        length: length.top,
        startPointInt: startPointTop,
        width: profile.internalCamera,
      },
      right: {
        profile: profile,
        length: length.right,
        startPointInt: startPointRight,
        width: profile.internalCamera,
      },
      left: {
        profile: profile,
        length: length.left,
        startPointInt: startPointLeft,
        width: profile.internalCamera,
      },
    }
  }
}
