<div class="panels">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white">Cargando...</p>
  </ngx-spinner>

  <div class="tree">
    <app-panels-tree [treeNode]="treeNode" [selectedAccesory]="selectedPanel"
      (selectPanelEmitter)="onSelectPanel($event)" (searchEmitter)="onSearch($event)" (addNewEmitter)="addNew()">
    </app-panels-tree>
  </div>

  <div class="content" *ngIf="new || selectedPanel">
    <form [formGroup]="form" autocomplete="off">
      <h1 class="title">Datos Técnicos</h1>

      <div class="panel-information">
        <div class="panel-information__item">
          <app-input label="Nombre" id="name">
            <input type="text" class="form-item__input name" id="name" name="name" placeholder="Nombre"
              formControlName="name" />
          </app-input>
          <app-input-alert *ngFor="
                              let errorMessage of inputErrorMessages(
                                'name',
                                'Debe indicar un nombre'
                              )
                            " [title]="errorMessage"></app-input-alert>
        </div>

        <div class="panel-information__item">
          <app-input label="Espesor" id="thickness">
            <input type="number" class="form-item__input thickness" id="thickness" name="thickness"
              placeholder="Espesor" formControlName="thickness" />
          </app-input>
          <app-input-alert *ngFor="
                                        let errorMessage of inputErrorMessages(
                                          'thickness',
                                          'Debe indicar un espesor'
                                        )
                                      " [title]="errorMessage"></app-input-alert>
        </div>

      </div>
      <div class="d-flex justify-content-center submit-button">
        <button class="btn btn-primary" (click)="onSubmit()">Aceptar</button>
      </div>
    </form>
  </div>
</div>