import { Entity } from 'src/app/@shared/@interfaces/dxf-content'
import { DXFAction } from './DXFAction'

export class DXFReflexAction extends DXFAction {
  public apply(entities: Entity[]): Entity[] {
    const newEntities: any[] = []
    const entityType: { [k: string]: (entity: Entity) => void } = {
      LINE: (entity) => {
        newEntities.push(this.reflectVertex(entity))
      },
      LWPOLYLINE: (entity) => {
        newEntities.push(this.reflectVertex(entity))
      },
      POLYLINE: (entity) => {
        newEntities.push(this.reflectVertex(entity))
      },
      ARC: (entity) => {
        const newEntity = this.reflectCenter(entity)
        newEntities.push(this.reflectArc(newEntity))
      },
      CIRCLE: (entity) => {
        newEntities.push(this.reflectCenter(entity))
      },
    }

    entities.forEach((entity) => {
      const type: any = entity.type
      if (!type) return

      const action: (entity: Entity) => void = entityType[type]
      action(entity)
    })

    return newEntities
  }

  public reflectVertex(entity: Entity): Entity {
    const newEntity = { ...entity }
    newEntity?.vertices?.forEach((vertices, index) => {
      if (vertices.bulge) {
        vertices.bulge = -vertices.bulge
      }
      vertices.x = -vertices.x
    })
    return newEntity
  }

  public reflectArc(entity: Entity): Entity {
    const newEntity = { ...entity }
    newEntity.startAngle = newEntity.startAngle ?? 0
    newEntity.endAngle = newEntity.endAngle ?? 0

    const angleBetween = newEntity.angleLength ?? 0
    const startAngleWithRespectYAxis = newEntity.startAngle - Math.PI / 2
    const endAngleWithRespectYAxis = newEntity.endAngle - Math.PI / 2

    const start =
      newEntity.startAngle - 2 * startAngleWithRespectYAxis - angleBetween
    const end = newEntity.endAngle - 2 * endAngleWithRespectYAxis + angleBetween

    newEntity.startAngle = start
    newEntity.endAngle = end

    return newEntity
  }

  public reflectCenter(entity: Entity): Entity {
    const newEntity = { ...entity }
    newEntity.center = newEntity.center ?? { x: 0, y: 0 }
    newEntity.center.x = -newEntity.center.x
    return newEntity
  }
}
