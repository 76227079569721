import { Injectable } from '@angular/core'
import Konva from 'konva'
import { CreateCrossbar } from 'src/app/@dialogs/new-area-dialog/new-area-dialog.component'
import { ProfileCreator } from 'src/app/@shared/@interfaces/profileCreator.model'
import { AreaModel } from 'src/app/@shared/@models/modelsElements/area-model'
import { CrossbarElement } from 'src/app/@shared/@models/modelsElements/crossbar-model'
import { Gap } from 'src/app/@shared/@models/modelsElements/gap-model'
import { SideOptions } from 'src/app/@shared/@types/side.types'
import { Vector2 } from 'three/src/math/Vector2'
import * as uuidGenerator from 'uuid'
import { CrossbarService } from '../crossbar.service'
import { AreaDrawService } from './area-draw.service'
import { CutVariationsDrawerService } from './cut-variations-drawer.service'
import { ProfileDrawerCreatorService } from './profile-drawer-creator.service'

@Injectable({
  providedIn: 'root',
})
export class CrossbarDrawerService {
  constructor(
    private areaDrawService: AreaDrawService,
    private profileDrawer: ProfileDrawerCreatorService,
    private cutVariationDrawer: CutVariationsDrawerService,
    private crossbarService: CrossbarService
  ) {}

  public addCrossbarsFromselected(
    response: CreateCrossbar,
    profileAxis: 'Horizontal' | 'Vertical',
    gap: Gap
  ): CrossbarElement {
    let area = gap?.selectedArea

    if (!area) return

    const inverseFromArray = ['bottom', 'right']
    const isInverse = inverseFromArray.includes(response.from)
    const isHorizontal = profileAxis === 'Horizontal'

    const { selectedProfile, crossbarsPositionsMM } = response
    const { splitAreaVertically, splitAreaHorizontally } = this.areaDrawService

    const splitFunction = isHorizontal
      ? splitAreaVertically
      : splitAreaHorizontally

    const internalCamera = selectedProfile?.internalCamera ?? 0

    const parentContainer = area.container

    // Corte
    crossbarsPositionsMM.forEach((mesure, i) => {
      const areaSize = isHorizontal ? area.height : area.width
      const fixedMeasure = isInverse ? areaSize - mesure : mesure

      const areaMeasure =
        i === 0
          ? fixedMeasure
          : fixedMeasure - crossbarsPositionsMM[i - 1] - internalCamera / 2

      let crossbarElement: CrossbarElement

      if (selectedProfile) {
        const areaDimensions = isHorizontal
          ? { width: area.width, height: areaMeasure }
          : { width: areaMeasure, height: area.height }

        const areaPosition = new Vector2(
          gap.xScaled + area._x,
          gap.yScaled + area._y
        )
        const profileDraw = this.createProfile(
          selectedProfile,
          areaDimensions,
          areaPosition,
          profileAxis,
          gap,
          response.overlaps.cutVariation
        )

        crossbarElement = new CrossbarElement({
          gap: gap,
          profile: selectedProfile,
          profileDraw,
          scaleFactor: gap.scaleWidth,
          side: isHorizontal ? 'top' : 'left',
          thickness: selectedProfile.internalCamera,
          unions: {},
          axis: isHorizontal ? 'Horizontal' : 'Vertical',
          uuid: uuidGenerator.v4(),
          x: areaPosition.x,
          y: areaPosition.y,
        })

        this.addToArea(
          area,
          crossbarElement,
          isHorizontal ? 'top' : 'left',
          isHorizontal ? 'bottom' : 'right'
        )
      }

      parentContainer.addElements([crossbarElement])

      const lastArea = area

      const newArea = splitFunction(
        areaMeasure,
        area,
        selectedProfile?.internalCamera ?? 0,
        parentContainer
      )

      area = newArea

      lastArea.frames.forEach((f) => area.addFrame(f))

      if (crossbarElement) {
        this.addToArea(
          area,
          crossbarElement,
          isHorizontal ? 'bottom' : 'right',
          isHorizontal ? 'top' : 'left'
        )
        gap.layer.add(crossbarElement.group).draw()
      }
    })
  }

  private createProfile(
    profile: ProfileCreator,
    areaDimension: { width: number; height: number },
    areaPosition: Vector2,
    profileAxis: 'Horizontal' | 'Vertical',
    gap: Gap,
    cutVariation?: { id: number; value: number; value2?: number }
  ): Konva.Line {
    const scaleFactor = gap.scaleWidth
    const isHorizontal = profileAxis === 'Horizontal'

    const profileLength = isHorizontal
      ? areaDimension.width
      : areaDimension.height

    const add = new Vector2(
      isHorizontal ? 0 : areaDimension.width,
      isHorizontal ? areaDimension.height : 0
    )
      .add(
        new Vector2(
          isHorizontal ? 0 : -profile.internalCamera / 2,
          isHorizontal ? -profile.internalCamera / 2 : 0
        )
      )
      .multiplyScalar(scaleFactor)

    const startPosition = areaPosition.add(add)

    const profileDrawCreator = {
      profile,
      profileLength,
      profileWidth: profile.internalCamera,
      scaleFactor,
      startPointExt: startPosition,
    }

    const line = isHorizontal
      ? this.profileDrawer.createHorizontalProfileFromStartExt(
          profileDrawCreator
        )
      : this.profileDrawer.createVerticalProfileFromStartExt(profileDrawCreator)

    const cutVariations = {
      ...(isHorizontal ? { top: cutVariation } : { left: cutVariation }),
    }

    const profiles = {
      ...(isHorizontal
        ? {
            top: {
              profile,
              profileDraw: line,
            },
          }
        : {
            left: {
              profile,
              profileDraw: line,
            },
          }),
    }

    const thickness = {
      ...(isHorizontal
        ? {
            top: profile.thicknessTotal,
          }
        : {
            left: profile.thicknessTotal,
          }),
    }

    this.cutVariationDrawer.applyCutVariations(
      cutVariations,
      profiles,
      thickness,
      'Crossbar',
      gap.scaleWidth
    )

    return line
  }

  private addToArea(
    area: AreaModel,
    crossbar: CrossbarElement,
    areaside: SideOptions,
    crossbarSide: SideOptions
  ): void {
    crossbar.addArea(area, areaside)
    area.addCrossBar(crossbar, crossbarSide)
  }
}
