<div class="modal-content">
  <div class="header">
    <div class="header-upbar">
      <h3>Criterio de combinación</h3>
      <fa-icon
        [icon]="faTimes"
        class="header-upbar__icon"
        (click)="decline()"
      ></fa-icon>
    </div>

    <div class="header-accesory-info">
      <app-input
        label="Código"
        width="100%"
        [disabled]="true"
        class="header-accesory-info__item"
      >
        <p style="margin: 0" class="header-accesory-info__text">
          {{ accesory.codRef }}
        </p>
      </app-input>

      <app-input
        label="Descripción"
        width="100%"
        [disabled]="true"
        class="header-accesory-info__item"
      >
        <p style="margin: 0" class="header-accesory-info__text">
          {{ accesory.denomination }}
        </p>
      </app-input>
    </div>

    <div class="header-variables">
      <form
        class="header-variables__item fade-in"
        *ngFor="let variable of form.controls"
        [formGroup]="variable"
      >
        <app-input
          [label]="variable.value.name"
          width="calc(min-content + 100px)"
        >
          <input
            [type]="variable.value.type"
            class="header-variables__item__input"
            formControlName="value"
            (input)="buildFormula()"
          />
        </app-input>
      </form>
    </div>
  </div>

  <div class="content">
    <table class="table table-hover table-fixed">
      <thead>
        <tr>
          <th scope="col">Descripción</th>
          <th scope="col">Función introducción</th>
          <th scope="col">
            <div class="table-head-icons"></div>
          </th>
        </tr>
      </thead>

      <tbody class="table-tbody table-body">
        <tr
          class="table-row fade-in"
          *ngFor="let formula of formulas; index as i"
          [ngClass]="{ selected: formula?.id === selectedFormula?.id }"
          (click)="createForm(formula)"
          tooltip="{{ formula.original }}"
          placement="top"
        >
          <td>{{ formula.description }}</td>
          <td>
            {{ prettierFormulaTemplate(formula) }}
          </td>

          <td>
            <div class="table-head-icons"></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="formula-preview">
    <app-input
      label="Fórmula introducción"
      width="100%"
      [disabled]="false"
      justifyContent="flex-start"
      class="header-accesory-info__item"
    >
      <p style="margin: 0" class="header-accesory-info__text">
        {{ prettierOnlyInternal() }}
      </p>
    </app-input>
  </div>

  <div class="button">
    <button class="btn btn-primary" (click)="confirm()">Aceptar</button>
  </div>
</div>
