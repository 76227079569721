// ANGULAR
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core'

// TOOLS
import {
  faFolder,
  faUndo,
  faRedo,
  faExchangeAlt,
  faHashtag,
  faArrowsAltH,
  faArrowsAltV,
} from '@fortawesome/free-solid-svg-icons'
import { Subscription } from 'rxjs'

// MODELS
import { Vertex } from 'src/app/@shared/@interfaces/vertex'

// SERVICES
import { DxfEditorService } from 'src/app/@services/dxf-editor.service'
import { DialogInstanceModel } from 'src/app/@shared/@models/dialog-instance-model'
import { DeleteItemDialogComponent } from 'src/app/@dialogs/delete-item-dialog/delete-item-dialog.component'
import { ModalService } from 'src/app/@services/modal.service'
import { CotasServiceService } from 'src/app/@services/cotas-service.service'

@Component({
  selector: 'app-dxf-editor',
  templateUrl: './dxf-editor.component.html',
  styleUrls: ['./dxf-editor.component.scss'],
})
export class DxfEditorComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  set $profileParsedDxf($profileParsedDxf: Blob) {
    this.profileParsedDxf = $profileParsedDxf
  }
  get $profileParsedDxf(): Blob {
    return this.profileParsedDxf
  }
  public profileParsedDxf: Blob

  @ViewChild('dxfSelector') dxfSelector: ElementRef<HTMLDivElement>
  @ViewChild('dxfViewer') dxfViewerElement: ElementRef<HTMLDivElement>

  // TOOLBAR
  public toolButtons: Array<any>
  public faFolder = faFolder
  public noDxf: boolean

  // DXF Viewer
  private closestVerticeSubscription: Subscription

  @Output()
  dxfFileEmitter: EventEmitter<any> = new EventEmitter<any>()
  @Output()
  closestVerticeChange: EventEmitter<Vertex> = new EventEmitter<Vertex>()

  // Hide/Show cotas
  public isShowVerticalCotas = true
  public isShowHorizontalCotas = true
  get isShowAllCotas(): boolean {
    return this.isShowHorizontalCotas && this.isShowVerticalCotas
  }

  constructor(
    private dxfService: DxfEditorService,
    private renderer: Renderer2,
    private modalService: ModalService,
    private cotasService: CotasServiceService
  ) {
    this.noDxf = true
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.$profileParsedDxf.firstChange) {
      this.renderDxf(this.profileParsedDxf)
    }
  }

  ngOnInit(): void {
    this.closestVerticeSubscriptionFn()
    this.setToolbar()

    this.dxfService.isDxfFromDB$.subscribe((is) => {
      if (is) {
        this.isShowHorizontalCotas = !this.isShowHorizontalCotas
        this.isShowVerticalCotas = !this.isShowVerticalCotas
      }
    })
  }

  ngOnDestroy(): void {
    this.closestVerticeSubscription?.unsubscribe()
    this.dxfService?.onDestroy()
    this.cotasService?.onDestroy()
  }

  public closestVerticeSubscriptionFn(): void {
    this.closestVerticeSubscription = this.dxfService.closestVertice$.subscribe(
      (closestVertice) => {
        this.closestVerticeChange.emit(closestVertice)
      }
    )
  }

  public setToolbar() {
    this.toolButtons = [
      {
        label: 'rotar izq.',
        icon: faUndo,
        toolClick: () => {
          this.confirmDialogInstance('left')
        },
      },
      {
        label: 'rotar der.',
        icon: faRedo,
        toolClick: () => {
          this.confirmDialogInstance('right')
        },
      },
      {
        label: 'reflexión',
        icon: faExchangeAlt,
        toolClick: () => {
          this.confirmDialogInstance('reflexion')
        },
      },
      {
        label: 'mostrar cotas',
        icon: faHashtag,
        toolClick: () => {
          this.dimensions()
        },
      },
      {
        label: 'cotas hor.',
        icon: faArrowsAltH,
        toolClick: () => {
          this.horizontalDimensions()
        },
      },
      {
        label: 'cotas ver.',
        icon: faArrowsAltV,
        toolClick: () => {
          this.verticalDimensions()
        },
      },
    ]
  }

  public selectDxf(event: any): void {
    this.dxfService.isDxfFromDBEmitter(false)
    this.resetDxf()
    const file = (event.target as HTMLInputElement).files[0]
    this.dxfFileEmitter.emit(file)
    this.renderDxf(file)
  }

  public renderDxf(file: any): void {
    this.noDxf = false
    this.dxfService.renderDxf(file, this.dxfViewerElement.nativeElement)
  }

  public resetDxf(): void {
    this.cleanCotas()
    if (this.dxfViewerElement) {
      Array.from(this.dxfViewerElement.nativeElement.children).forEach(
        (child, index) => {
          if (index === 1) {
            this.renderer.removeChild(
              this.dxfViewerElement.nativeElement,
              child
            )
          }
        }
      )
    }
  }

  public confirmDialogInstance(mode: string): void {
    const action = {
      left: () => {
        this.rotate(mode)
      },
      right: () => {
        this.rotate(mode)
      },
      reflexion: () => {
        this.reflexion()
      },
    }
    return action[mode]()
  }

  public rotate(mode: string): void {
    this.resetDxf()
    this.dxfService.rotateDxf(mode)
  }

  public reflexion(): void {
    this.resetDxf()
    this.dxfService.reflexionDxf()
  }

  public dimensions(): void {
    this.hideShowAll()
  }

  public verticalDimensions(): void {
    this.hideShowVertical()
  }

  public horizontalDimensions(): void {
    this.hideShowHorizontal()
  }

  private cleanCotas(): void {
    this.cotasService.cleanCotas()
  }

  // Hide/Show cotas
  public hideShowAll(): void {
    const isAnyCotaShowing =
      this.isShowHorizontalCotas || this.isShowVerticalCotas

    if (isAnyCotaShowing) {
      this.isShowVerticalCotas = true
      this.isShowHorizontalCotas = true
    }

    this.hideShowHorizontal()
    this.hideShowVertical()
  }
  public hideShowHorizontal(): void {
    this.isShowHorizontalCotas = !this.isShowHorizontalCotas
    this.cotasService.hideShowHorizontalCotas(this.isShowHorizontalCotas)
  }
  public hideShowVertical(): void {
    this.isShowVerticalCotas = !this.isShowVerticalCotas
    this.cotasService.hideShowVerticalCotas(this.isShowVerticalCotas)
  }
}
