import { pointsToVectors2d } from './pointsTo2dVector'

export const reflexRespectHorizontalLine = (
  points: number[],
  yLine: number
): number[] => {
  const vectors = pointsToVectors2d(points)

  const translatedVectors = vectors.map((v) => {
    return {
      x: v.x,
      y: v.y - yLine,
      z: 0,
    }
  })

  const reflexVectors = translatedVectors.map((v) =>
    rotateRecpectXAxis(Math.PI, v)
  )

  const newPoints = reflexVectors.flatMap((v) => [v.x, v.y + yLine])

  return newPoints
}

const rotateRecpectXAxis = (
  radians: number,
  vector: { x: number; y: number; z: number }
): { x: number; y: number; z: number } => {
  return {
    x: vector.x,
    y: vector.y * Math.cos(radians) - vector.z * Math.sin(radians),
    z: vector.y * Math.sin(radians) + vector.z * Math.cos(radians),
  }
}
