import { NestedTreeControl } from '@angular/cdk/tree'
import { Component, OnInit } from '@angular/core'
import { MatTreeNestedDataSource } from '@angular/material/tree'

// Toastr
import { ToastrService } from 'ngx-toastr'

import { NgxSpinnerService } from 'ngx-spinner'

import { SeriesService } from 'src/app/@services/series.service'
import { ProfileService } from 'src/app/@services/profile.service'
import { ModalService } from 'src/app/@services/modal.service'

import { Profile } from '../profile-management-editor/profile-management-editor.component'

import { IdAndNameModel } from 'src/app/@shared/@models/idAndName.model'

import { DialogInstanceModel } from 'src/app/@shared/@models/dialog-instance-model'
import { DeleteItemDialogComponent } from 'src/app/@dialogs/delete-item-dialog/delete-item-dialog.component'
import { AuthStatusService } from 'src/app/@services/auth-status.service'
import { Observable } from 'rxjs'
import { User } from 'src/app/core/models/User.model'

const enum NodeTypes {
  title,
  node,
}
interface TreeNode {
  name: string
  id: number
  canEdit: boolean
  description?: string
  children?: TreeNode[]
  isParent?: boolean
  type?: NodeTypes
}

@Component({
  selector: 'app-profile-management',
  templateUrl: './profile-management.component.html',
  styleUrls: ['./profile-management.component.scss'],
})
export class ProfileManagementComponent implements OnInit {
  public showProfileEditorFlag = false
  public showSerieEditorFlag = false

  public filteredProfiles: any
  private _listFilter = ''
  private nodeParentId

  public selectedSerie: IdAndNameModel
  public selectedSerieForEdit: IdAndNameModel
  public selectedProfile: Profile

  //#region  treeControls
  treeControl = new NestedTreeControl<TreeNode>((node) => node.children)
  dataSource = new MatTreeNestedDataSource<TreeNode>()
  hasChild = (_: number, node: TreeNode) => {
    return (
      (!!node.children && node.children.length > 0) ||
      node.type === NodeTypes.title
    )
  }

  //#endregion

  get filterBy(): string {
    return this._listFilter
  }

  set filterBy(value: string) {
    this._listFilter = value
    this.dataSource.data
      .find((item) => item.id === this.nodeParentId)
      .children.find(
        (item) => item.name == 'Perfiles'
      ).children = this.filterProfiles(this._listFilter)
    let listRawData = this.dataSource.data
    this.fillDataSource(listRawData)
  }

  public user$: Observable<User>

  constructor(
    private _seriesServices: SeriesService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: ModalService,
    private profileService: ProfileService,
    private authStatus: AuthStatusService
  ) {
    this.user$ = authStatus.user$
  }

  ngOnInit(): void {
    this.getSeriesNodes()
  }

  public showProfileEditor(): void {
    this.showProfileEditorFlag = false
    this.showSerieEditorFlag = false
    setTimeout(() => {
      this.showProfileEditorFlag = true
    }, 0)
  }

  public hideProfileEditor(): void {
    // to put it to the end of js excution stack
    setTimeout(() => {
      this.showProfileEditorFlag = false
    }, 0)
  }

  public showSerieEditor(): void {
    this.showProfileEditorFlag = false
    this.showSerieEditorFlag = true
  }
  public hideSerieEditor(): void {
    this.showSerieEditorFlag = false
  }

  public selectSerie(serie: any): void {
    const isNewProfile =
      this.selectedSerie && this.selectedSerie.id !== serie.id
    if (isNewProfile) this.selectedProfile = null

    this.selectedSerie = new IdAndNameModel(serie.id, serie.name)
  }

  public selectSerieForEdit(serie: any): void {
    this.selectedSerieForEdit = new IdAndNameModel(serie.id, serie.name)
  }

  public selectProfile(profile: any): void {
    this.selectedProfile = {
      name: profile.name,
      description: profile.description,
      id: profile.id,
    }

    this.showProfileEditor()
  }

  public editNode(node: any): any {
    if (!node.isParent) {
      return this.selectProfile(node)
    }

    this.selectSerieForEdit(node)
    this.showSerieEditor()
  }

  private updateTree(): void {
    const data = this.dataSource.data
    this.dataSource.data = null
    this.dataSource.data = data
  }

  public addNew(node?: any): any {
    node = node ?? { isParent: true }

    if (node.name === 'Accesorios') return

    const isProfile = !node.isParent

    if (isProfile) {
      this.selectedProfile = null
      return this.showProfileEditor()
    }

    this.selectedSerieForEdit = null
    this.selectedSerie = null
    this.showSerieEditor()
  }
  public addSerie(serie: any): void {
    this.dataSource.data.push({
      canEdit: true,
      id: serie.id,
      isParent: true,
      name: serie.name,
      children: [
        {
          name: 'Perfiles',
          canEdit: false,
          id: null,
          children: [],
          description: null,
          type: NodeTypes.title,
        },
        {
          name: 'Accesorios',
          canEdit: false,
          id: null,
          children: [],
          description: null,
          type: NodeTypes.title,
        },
      ],
      description: serie.description,
    })

    this.updateTree()
  }
  public updateSerie(serie: any): void {
    const serieTree = this.dataSource.data.find(
      (value) => value.id === serie.id
    )
    serieTree.name = serie.name
    this.updateTree()
  }

  public addProfile(profile: any): void {
    if (!profile) return

    const serie = this.dataSource.data.find(
      (value) => value.id === this.selectedSerie.id
    )
    if (!serie) return

    const newNode: TreeNode = {
      canEdit: true,
      id: profile.id,
      name: profile.code,
      children: null,
      description: profile.description,
      isParent: false,
    }

    serie.children[0].children = [newNode, ...serie.children[0].children]

    this.updateTree()
  }
  public updateProfile(profile: any): void {
    if (!profile) return

    const serie = this.dataSource.data.find(
      (value) => value.id === this.selectedSerie.id
    )

    if (!serie) return

    const prof = serie.children[0].children.find(
      (value) => value.id === profile.id
    )

    if (!prof) return
    prof.description = profile.description

    this.updateTree()
  }

  public deleteNode(node: any): void {
    const isProfile = !node.isParent

    if (isProfile) this.deleteProfile(node)
    if (!isProfile) this.deleteSerie(node)
  }

  private deleteProfile(profile: Profile): void {
    const dialogModel = new DialogInstanceModel(DeleteItemDialogComponent, {
      initialState: {
        itemName: 'el perfil',
      },
      class: 'modal-dialog-centered',
    })

    this.modalService.openModal(dialogModel).subscribe(
      (response: boolean) => {
        if (response)
          this.profileService.deleteProfile(profile.id).subscribe(
            (data) => {
              this.hideProfileEditor()
              this.hideSerieEditor()
              this.toastr.success('El perfil ha sido eliminado')
              this.deleteProfileFromTree(profile)
            },
            (err) => {
              console.dir(err)
              this.toastr.error(err)
              this.toastr.error('No se ha podido eliminar el perfil')
            }
          )
      },
      (err) => {
        console.dir(err)
        this.toastr.error(err)
      }
    )
  }
  private deleteProfileFromTree(profile: Profile): void {
    const serie = this.dataSource.data.find(
      (value) => value.id === this.selectedSerie.id
    )

    const profiles = serie.children[0].children
    const profilesAux: TreeNode[] = profiles.filter(
      (value) => value.id !== profile.id
    )

    serie.children[0].children = profilesAux

    // Form some reason, it´s needed set to null to update tree
    this.updateTree()
  }

  private deleteSerie(serie: any): void {
    const dialogModel = new DialogInstanceModel(DeleteItemDialogComponent, {
      initialState: {
        itemName: 'la serie',
      },
      class: 'modal-dialog-centered',
    })

    this.modalService.openModal(dialogModel).subscribe(
      (response: boolean) => {
        if (response)
          this._seriesServices.deleteSerie(serie.id).subscribe(
            (data) => {
              this.hideProfileEditor()
              this.hideSerieEditor()
              this.toastr.success('La serie ha sido eliminada')
              this.deleteSerieFromTree(serie)
            },
            (err) => {
              this.toastr.error('No se ha podido eliminar la serie')
            }
          )
      },
      (err) => {
        console.dir(err)
        this.toastr.error(err)
      }
    )
  }
  private deleteSerieFromTree(serie: any): void {
    this.dataSource.data = this.dataSource.data.filter(
      (value) => value.id !== serie.id
    )

    this.updateTree()
  }

  getSeriesNodes() {
    this.spinner.show()
    this._seriesServices.getSeries().subscribe(
      async (result: any) => {
        this.dataSource.data = result.map((serie) => ({
          name: serie.name,
          id: serie.id,
          isParent: true,
          canEdit: true,
          children: [
            {
              name: 'Perfiles',
              children: [],
              canEdit: false,
              parentId: serie.id,
              type: NodeTypes.title,
            },
            // Avoid show Accesorios tree
            // {
            //   name: 'Accesorios',
            //   children: [],
            //   canEdit: false,
            //   parentId: serie.id,
            //   type: NodeTypes.title,
            // },
          ],
        }))
        this.spinner.hide()
      },
      (error) => {
        console.dir(error)
        this.toastr.error(error)
      }
    )
  }

  async getProfilesBySerie(serieId: number): Promise<TreeNode[]> {
    this.filteredProfiles = await this._seriesServices
      .getSeriesFilteredByProfile(serieId)
      .toPromise()
      .then((result: any) =>
        result.map((element: any) => ({
          name: element.code,
          description: element.description,
          canEdit: true,
          id: element.id,
        }))
      )

    return this.filteredProfiles
  }

  async getProfiles() {
    return this._seriesServices
      .getSeriesFilteredByProfile(null)
      .toPromise()
      .then((result: any) =>
        result.map((element: any) => ({
          name: element.code,
          description: element.description,
          canEdit: true,
          serie: element.serie,
        }))
      )
  }

  async getDataIfExist(nodeSerie: any) {
    if (nodeSerie.isParent) {
      this.dataSource.data
        .find((item) => item.id === nodeSerie.id)
        .children.find(
          (item) => item.name == 'Perfiles'
        ).children = await this.getProfilesBySerie(nodeSerie.id)
      let nodeWithProfileList = this.dataSource.data
      this.fillDataSource(nodeWithProfileList)
      this.selectSerie(nodeSerie)
    }
  }

  fillDataSource(list: any[]) {
    this.dataSource.data = null
    this.dataSource.data = list
  }

  filterProfiles(filteredBy: string) {
    filteredBy = filteredBy.toLowerCase()
    let listFiltered

    if (filteredBy != '') {
      listFiltered = this.dataSource.data
        .find((item) => item.id === this.nodeParentId)
        .children.find((item) => item.name == 'Perfiles')
        .children.filter(
          (r) =>
            r.name.toLowerCase().includes(filteredBy) ||
            r.description.toLowerCase().includes(filteredBy)
        )
    } else {
      listFiltered = this.filteredProfiles
    }
    return listFiltered
  }

  saveFilterList(nodeParent: any) {
    this.nodeParentId = nodeParent
  }
}
