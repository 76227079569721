<div class="content">
  <div class="content-header modal-header">
    <h2>{{ title }}</h2>

    <fa-icon
      [icon]="faTimes"
      class="content-header__icon"
      (click)="decline()"
    ></fa-icon>
  </div>

  <div class="modal-body body">
    <app-input label="Nombre" justifyContent="flex-start">
      <input
        type="text"
        style="width: 100%"
        class="body__input"
        [formControl]="formControl"
      />
    </app-input>
    <app-input-alert
      title="Indique un nombre"
      *ngIf="isInvalidName()"
    ></app-input-alert>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="confirm()">Aceptar</button>
  </div>
</div>
