<div class="wrapper">
  <h2>Abertura</h2>
  <div class="content opening">
    <div class="actions">
      <button class="btn btn-block btn-primary" (click)="onAddOverOpening()">
        Añadir sobre abertura
      </button>

      <button
        class="btn btn-block btn-primary"
        (click)="onAddOverOpeningContainer()"
      >
        Añadir sobre area contenedora
      </button>
    </div>

    <div class="profiles" *ngIf="openingProfiles.length > 0">
      <div
        class="profile"
        *ngFor="let profile of openingProfiles"
        (click)="onAddOverOpeningProfile(profile)"
      >
        <div class="profile-info">
          <span>{{ profile.code }}</span>
          <span>{{ profile.description }}</span>
          <span>{{ profile.ext }}mm</span>
        </div>
        <div class="profile-side">
          <span>{{ profile.side }}</span>
        </div>
      </div>
    </div>
  </div>

  <tui-tabs [(activeItemIndex)]="activeTabIndex">
    <button
      tuiTab
      *ngFor="let leaf of leafs; index as i"
      (click)="onSelectLeaf(leaf)"
    >
      Hoja {{ i + 1 }}
    </button>
  </tui-tabs>

  <div class="content">
    <div class="actions">
      <button class="btn btn-block btn-primary" (click)="onAddOverLeaf()">
        Añadir sobre hoja
      </button>

      <button
        class="btn btn-block btn-primary"
        (click)="onAddOverLeafContainer()"
      >
        Añadir sobre area de hoja
      </button>
    </div>

    <div class="profiles" *ngIf="leafProfiles.length > 0">
      <div
        class="profile"
        *ngFor="let profile of leafProfiles"
        (click)="onAddOverLeafProfile(profile)"
      >
        <div class="profile-info">
          <span>{{ profile.code }}</span>
          <span>{{ profile.description }}</span>
          <span>{{ profile.ext }}mm</span>
        </div>
        <div class="profile-side">
          <span>{{ profile.side }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
