import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import { CrystalNode } from 'src/app/@components/crystals-tree/crystals-tree.component';

import { invalidInputMessages } from 'src/app/@helper/invalidInputShowMessage';

import { CrystalService } from 'src/app/@services/crystal.service';

import { Crystal, CrystalCreator } from 'src/app/@shared/@interfaces/crystal';
import { CrystalTypes } from 'src/app/@shared/@interfaces/crystal-types';

@Component({
  templateUrl: './crystals.component.html',
  styleUrls: ['./crystals.component.scss']
})
export class CrystalsComponent implements OnInit {
  public treeNode: CrystalNode[] = []
  public selectedCrystal: Crystal
  public crystals: Crystal[] = []
  public crystalTypes: CrystalTypes[] = []

  public form: FormGroup

  public new = false

  constructor(
    private crystalService: CrystalService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.getCrystals()
    this.getCrystalsTypes()
  }

  private buildForm(): void {
    this.form = this.fb.group({
      name: ['1', [Validators.required]],
      thickness: ['1', [Validators.required]],
      weight: ['1', [Validators.required, Validators.min(1)]],
      crystalType: [1, [Validators.required]],
      camera: [''],
      camera2: [''],
    })
  }
  private setForm() {
    this.form.get('name').setValue(this.selectedCrystal.name);
    this.form.get('thickness').setValue(this.selectedCrystal.thickness);
    this.form.get('weight').setValue(this.selectedCrystal.weight);
    this.form.get('crystalType').setValue(this.selectedCrystal.crystalType.id);
    this.form.get('camera').setValue(this.selectedCrystal.camera);
    this.form.get('camera2').setValue(this.selectedCrystal.camera2);
  }

  private getCrystals(): void {
    this.spinner.show()
    this.crystalService.getAllCrystals().subscribe((crystals) => {
      this.crystals = crystals
      this.buildTree()
      this.spinner.hide()
    })
  }

  private getCrystalsTypes(): void {
    this.crystalService.getCrystalsTypes().subscribe((crystals) => {
      this.crystalTypes = crystals
    })
  }

  public getCrystalsType(): string {
    const crystalType = this.form.get('crystalType').value

    const crystal =
      this.crystalTypes.find((value) => value.id === crystalType)?.name ?? 'Tipo'

    return crystal
  }

  public onSelectCrystal(crystal: Crystal): void {
    this.selectedCrystal = crystal
    this.setForm();
  }

  public selecPosition(crystalType: CrystalTypes): void {
    this.form.get('crystalType').setValue(crystalType.id)
  }

  private buildTree() {
    this.treeNode =
      this.crystals?.map((c) => ({
        crystal: c,
      })) ?? []
  }
  public onSearch(criteria: string): void {
    if (!criteria) return this.buildTree()
    this.spinner.show()

    criteria = criteria?.toLowerCase() ?? ''

    let auxTreeNode: CrystalNode[] = []

    this.crystals.forEach((c) => {
      const name = c.name

      if (name.includes(criteria)) auxTreeNode.push({ crystal: c })
    })

    this.treeNode = null
    this.treeNode = auxTreeNode

    this.spinner.hide()
  }

  public addNew() {
    this.form.get('name').setValue('');
    this.form.get('thickness').setValue(1);
    this.form.get('weight').setValue(1);
    this.form.get('crystalType').setValue(1);
    this.form.get('camera').setValue('');
    this.form.get('camera2').setValue('');
    this.new = true;
    this.selectedCrystal = null;
  }

  public inputErrorMessages(
    control: string,
    requiredMessage: string
  ): string[] {
    return invalidInputMessages(this.form, control, requiredMessage);
  }

  public onSubmit() {
    const crystal = {
      name: this.form.get('name').value,
      thickness: this.form.get('thickness').value,
      weight: this.form.get('weight').value,
      crystalType: {
        id: this.form.get('crystalType').value
      },
    };
    if (this.form.get('camera').value) {
      crystal['camera'] = this.form.get('camera').value;
    }
    if (this.form.get('camera').value) {
      crystal['camera2'] = this.form.get('camera2').value;
    }
    if (this.selectedCrystal) {
      this.editCrystal(crystal);
    } else {
      this.newCrystal(crystal);
    }
  }

  public editCrystal(crystal: CrystalCreator) {
    this.crystalService.updateCrystal(crystal, this.selectedCrystal.id)
      .subscribe(
        (response) => {
          this.toastr.success('Se ha actualizado el cristal')
          this.getCrystals();
          this.selectedCrystal = null;
        },
        (err) => {
          this.toastr.error('No ha sido posible actualizar el cristal')
        }
      )
  }

  public newCrystal(crystal) {
    this.crystalService.createCrystal(crystal)
      .subscribe(
        (response) => {
          this.toastr.success('Se ha creado el cristal')
          this.getCrystals();
          this.new = false;
        },
        (err) => {
          this.toastr.error('No ha sido posible crear el cristal')
        }
      )
  }
}
