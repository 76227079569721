import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { ToastrService } from 'ngx-toastr'
import { accesoryTypeToDescription } from 'src/app/@helper/accesoryTipeToDescription'

import { invalidInputMessages } from 'src/app/@helper/invalidInputShowMessage'

import { AccessoriesModelService } from 'src/app/@services/accessories-model.service'
import { ModalService } from 'src/app/@services/modal.service'

import { Accesory } from 'src/app/@shared/@interfaces/accesory'
import { AccessoryModel } from 'src/app/@shared/@interfaces/accessory-model.model'
import { Color } from 'src/app/@shared/@interfaces/color.model'
import { IDialog } from 'src/app/@shared/@interfaces/dialog'
import { DialogInstanceModel } from 'src/app/@shared/@models/dialog-instance-model'

import { NewAccesoriesCombinationDialogComponent } from '../new-accesories-combination/new-accesories-combination.component'

@Component({
  selector: 'app-add-accessories-dialog',
  templateUrl: './add-accessories-dialog.component.html',
  styleUrls: ['./add-accessories-dialog.component.scss'],
})
export class AddAccessoriesDialogComponent implements OnInit, IDialog {
  @Input() private acc: AccessoryModel = null
  @Input() private modelId: number
  @Input() public quantity = false
  @Output()
  $response = new EventEmitter<any>()

  public faEdit = faEdit
  public faTimes = faTimes

  public form: FormGroup

  public colorTypes: Color[] = []
  public accessory: Accesory

  constructor(
    private accessoriesModelService: AccessoriesModelService,
    private fb: FormBuilder,
    private modalRef: BsModalRef,
    private modalService: ModalService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.buildForm()
    this.getColors(null, null)
    if (this.acc) {
      this.getColors(this.acc.family, this.acc.codRef)
      this.setForm()
    } else {
      this.openAccesoryModalDialog()
    }
  }

  private buildForm(): void {
    this.form = this.fb.group({
      color: [1, [Validators.required]],
      name: ['', [Validators.required]],
      quantity: ['1', [Validators.required, Validators.min(1)]],
      um: ['P', [Validators.required]],
    })
  }

  private setForm() {
    this.form.get('color').setValue(this.acc.color.id)
    this.form.get('name').setValue(`${this.acc.family} - ${this.acc.codRef}`)
    this.form.get('quantity').setValue(this.acc.quantity)
    this.form.get('um').setValue(accesoryTypeToDescription(this.acc.um))
    this.accessory = this.acc.accessory
  }

  public openAccesoryModalDialog() {
    const newDialog = new DialogInstanceModel(
      NewAccesoriesCombinationDialogComponent,
      {
        initialState: {
          accesory: '',
        },
      }
    )

    this.modalService.openModal(newDialog).subscribe(
      async (accessory) => {
        this.accessory = accessory
        // this.form
        //   .get('name')
        //   .setValue(`${accessory.family} - ${accessory.codRef}`)
        this.form.patchValue({
          name: `${accessory.family} - ${accessory.codRef}`,
          um: accesoryTypeToDescription(accessory.type ?? 'P'),
        })

        this.getColors(this.accessory.family, this.accessory.codRef)
      },
      (err) => {}
    )
  }

  public getColors(family: string = 'AS', codRef: string = 'AE90') {
    this.accessoriesModelService.getColorsAccessory(family, codRef).subscribe(
      async (color) => {
        this.colorTypes = color
      },
      (err) => {}
    )
  }

  public getColor(): string {
    const id = this.form.get('color').value

    const color =
      this.colorTypes.find((value) => value.id === id)?.description ?? 'Color'

    return color
  }

  public selecPosition(color: Color): void {
    this.form.get('color').setValue(color.id)
  }

  public onSubmit() {
    if (this.acc) {
      this.updateAccessoriesModel()
    } else {
      this.createAccessoriesModel()
    }
  }

  private createAccessoriesModel() {
    const model = {
      model: {
        id: this.modelId,
      },
      accessoriesModel: [
        {
          family: this.accessory.family,
          codRef: this.accessory.codRef,
          quantity: this.form.get('quantity').value,
          um: this.form.get('um').value,
          color: {
            id: this.form.get('color').value,
          },
        },
      ],
    }
    this.accessoriesModelService.createAccessoriesModel(model).subscribe(
      (response) => {
        this.toastr.success('Se ha creado el accesorio')
        this.confirm()
      },
      (err) => {
        this.toastr.error('No ha sido posible crear el accesorio')
      }
    )
  }

  private updateAccessoriesModel() {
    this.accessoriesModelService
      .updateAccessoriesModel(
        {
          quantity: this.form.get('quantity').value,
          um: this.form.get('um').value,
          color: {
            id: this.form.get('color').value,
          },
        },
        this.acc.id
      )
      .subscribe(
        (response) => {
          this.toastr.success('Se ha actualizado el accesorio')
          this.confirm()
        },
        (err) => {
          this.toastr.error('No ha sido posible actualizar el accesorio')
        }
      )
  }

  public inputErrorMessages(
    control: string,
    requiredMessage: string
  ): string[] {
    return invalidInputMessages(this.form, control, requiredMessage)
  }

  public confirm(): void {
    this.$response.emit()
    this.modalRef.hide()
  }

  public decline(): void {
    this.modalRef.hide()
  }
}
