import { Vector2 } from 'three'
import { pointsToVectors2d } from './pointsTo2dVector'
import { rotateRespectPoint } from './rotateRespectToPoint'

export const rotatePoints = (
  points: number[],
  point: Vector2,
  degree: number
): number[] => {
  const vectors = pointsToVectors2d(points)
  const rotateVectors = vectors.map((v) => rotateRespectPoint(v, point, degree))

  const newPoints = rotateVectors.flatMap((v) => {
    return [v.x, v.y]
  })

  return newPoints
}
