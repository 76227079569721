<div class="overlaps" (click)="openOverlapDialog()">
  <div class="superposition">
    <span>{{ overlap }}</span>
    <img
      src="../../../assets/icons/superposition.svg"
      alt="superposición"
      class="icon"
    />
  </div>
  <div class="cutvariation">
    <span>{{ cutVariation.value }}</span>
    <img
      src="../../../assets/icons/cut-variation.svg"
      alt="variación de corte"
      class="icon"
    />
  </div>
</div>
