import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from 'src/environments/environment'
import { User, UserDTO } from '../core/models/User.model'
import { AuthStatusService } from './auth-status.service'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _API_URL = environment.apiUrl

  constructor(
    private http: HttpClient,
    private authStatus: AuthStatusService
  ) {}

  public async tokenLogin(): Promise<void> {
    const response = await this.http
      .post<UserDTO>(`${this._API_URL}login/token`, {})
      .toPromise()
    const user = new User(response)
    this.authStatus.setUser(user)
  }

  public logout(): void {
    this.authStatus.setUser(null)
    localStorage.removeItem('token')
    this.tokenLogin()
  }
}
