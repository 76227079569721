import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { ToastrService } from 'ngx-toastr'
import { Subscription } from 'rxjs'
import { ProfileDialogComponent } from 'src/app/@dialogs/profile-dialog/profile-dialog.component'
import { DxfEditorService } from 'src/app/@services/dxf-editor.service'
import { ModalService } from 'src/app/@services/modal.service'
import { ProfileService } from 'src/app/@services/profile.service'
import { IDialog } from 'src/app/@shared/@interfaces/dialog'
import { DxfContent } from 'src/app/@shared/@interfaces/dxf-content'
import { ProfileCreator } from 'src/app/@shared/@interfaces/profileCreator.model'
import { DialogInstanceModel } from 'src/app/@shared/@models/dialog-instance-model'
import { DXF } from 'src/app/presentation/shared/models/DXF.model'
import { DxfConfectionService } from '../services/dxf-confection.service'

@Component({
  selector: 'app-dxf-disassembly-confection',
  templateUrl: './dxf-disassembly-confection.component.html',
  styleUrls: ['./dxf-disassembly-confection.component.scss'],
})
export class DxfDisassemblyConfectionComponent
  implements OnInit, OnDestroy, IDialog {
  public dxfsList: DXF[] = []
  public faTrash = faTrash;

  @Input()
  private preloadProfile: boolean;
  @Input()
  private profile;

  $response: EventEmitter<DXF[]> = new EventEmitter<DXF[]>()

  private removeProfileSubscription: Subscription

  constructor(
    private modalService: ModalService,
    private profileService: ProfileService,
    private dxfConfectionService: DxfConfectionService,
    private dxfEditorService: DxfEditorService,
    private toastr: ToastrService
  ) {}

  confirm(): void {
    this.$response.emit(this.dxfsList)
    const dxf = DXF.combineArray(this.dxfsList)
    const serialized = dxf.json();

    console.log(this.dxfsList);

    this.profileService.createProfileParsedDxf(this.profile.id.toString(), serialized).subscribe(
      (response: any) => {},
      (error) => {
        console.dir(error)
        this.toastr.error('No se ha podido guardar el dxf')
      }
    );
    
    this.profileService.persistRelationsFor(this.profile.id.toString(), this.dxfsList).subscribe(
      (response: any) => {},
      (error) => {
        console.dir(error)
        this.toastr.error('Hubo un problema guardando la relaccion')
      }
    );

    this.dxfEditorService.isDxfFromDBEmitter(true)
  }

  decline(): void {
    throw new Error('Method not implemented.')
  }

  async ngOnInit() {
    if (this.preloadProfile) {
      this.profileService.getDisasembledProfilesForProfile(this.profile.id.toString()).subscribe(
        (response: any[]) => response.forEach(async (disasembled) => this.dxfConfectionService.addProfile({
            profile: disasembled.disasembledProfile,
            dxfParsed: await this.profileService
            .getProfileParsedDxf(disasembled.disasembledProfile.id)
            .toPromise(),
            uuid: disasembled.disasembledProfile.id,
            offset: disasembled.offset
          })),
      )
    }
    this.dxfConfectionService.listDxfs$.subscribe({
      next: (dxfs) => {
        this.dxfsList = dxfs
      },
    })
  }

  ngOnDestroy(): void {
    this.removeProfileSubscription?.unsubscribe()
  }

  public onAddProfile(): void {
    const dialogModel = new DialogInstanceModel(ProfileDialogComponent, {
      initialState: {
        serieId: 1,
        onlyOne: true,
      },
      class: 'modal-dialog-centered',
    })

    this.modalService.openModal(dialogModel).subscribe({
      next: async (result: { profile: ProfileCreator }) => {
        const { profile } = result

        const dxfParsed = await this.profileService
          .getProfileParsedDxf(profile.id)
          .toPromise()

        this.dxfConfectionService.addProfile({
          profile,
          dxfParsed,
          uuid: profile.id.toString(),
        })
      },
    })
  }

  public onRemoveProfile(dxf: DXF): void {
    this.dxfConfectionService.removeProfile(dxf)
  }
}
