import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core'

// NGX Bootstrap
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown'

// FontAwesome
import { faSearch, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Color } from 'src/app/@shared/@interfaces/color.model'

@Component({
  selector: 'app-color-table-selector',
  templateUrl: './color-table-selector.component.html',
  styleUrls: ['./color-table-selector.component.scss'],
  providers: [
    {
      provide: BsDropdownConfig,
      useValue: { isAnimated: true, autoClose: true },
    },
  ],
})
export class ColorTableSelectorComponent implements OnInit {
  @ViewChild('dropdownContent')
  private dropDownContentElement: ElementRef<HTMLDivElement>

  @Input() public aviableColors: Color[] = []
  @Input() public serieColors: Color[] = []

  @Output() public serieColorsEmitter = new EventEmitter<Color[]>()

  // Icons
  public faSearch = faSearch
  public faPlus = faPlus
  public faTrash = faTrash

  public isColorsShown = false

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}

  public addColor(color: Color): void {
    const isAlreadyAdded = this.isAlreadyAdded(color)
    if (isAlreadyAdded) return this.hideShowColors()

    this.serieColors.push(color)
    this.hideShowColors()

    this.emit()
  }

  public removeColor(color: Color): void {
    this.serieColors = this.serieColors.filter((c) => c.id !== color.id)
    this.emit()
  }

  public hideShowColors(): void {
    let display: 'flex' | 'none' = 'flex'
    if (this.isColorsShown) display = 'none'

    this.renderer.setStyle(
      this.dropDownContentElement.nativeElement,
      'display',
      display
    )

    this.isColorsShown = !this.isColorsShown
  }

  private isAlreadyAdded(color: Color): boolean {
    return this.serieColors.find((c) => c.id === color.id) ? true : false
  }

  public emit(): void {
    this.serieColorsEmitter.emit(this.serieColors)
  }
}
