import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'

import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Subscription } from 'rxjs'
import { Serie } from 'src/app/@components/profile-management-editor/profile-management-editor.component'
import { invalidInputMessages } from 'src/app/@helper/invalidInputShowMessage'
import { ProfileCreator } from 'src/app/@shared/@interfaces/profileCreator.model'
import { Nullable } from 'src/app/core/types/Nullable.type'

interface QueryParams {
  editMode: boolean
  id?: number
  serieId?: number
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  public isNewProfile = true

  private paramsSubscription!: Subscription
  private params: QueryParams = {
    editMode: false,
  }

  // Icons
  public faTimes = faTimes
  public profileDataForm: FormGroup

  public profile: Nullable<ProfileCreator>
  private profileSubscription!: Subscription

  public serie: Nullable<Serie>
  private serieSubscription!: Subscription

  constructor(private route: ActivatedRoute, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.buildForm()
    this.getQueryParams()
  }

  ngOnDestroy(): void {
    this.paramsSubscription?.unsubscribe()
    this.profileSubscription?.unsubscribe()
  }

  private getQueryParams(): void {
    this.paramsSubscription = this.route.queryParams.subscribe({
      next: (params: any) => {
        const { editMode: editModeParams, serieId } = params

        const id = params.id ? +params.id : null
        let isEditMode = editModeParams ? editModeParams === 'true' : false

        this.params = {
          editMode: id ? isEditMode : false,
          id: params.id ? parseInt(params.id, 10) : undefined,
          serieId: serieId ? parseInt(serieId, 10) : undefined,
        }

        const { editMode } = this.params
      },
    })
  }

  private buildForm(): void {
    this.profileDataForm = this.fb.group({
      serie: [{ value: '', disabled: true }, [Validators.required]],
      name: ['', [Validators.required]],
      description: ['', [Validators.required, Validators.maxLength(140)]],
    })

    this.profileDataForm.valueChanges.subscribe({
      next: (value) => {
        const { name, description } = value
      },
    })
  }

  public isInvalidControl(control: string, requiredMessage?: string): string[] {
    return invalidInputMessages(this.profileDataForm, control, requiredMessage)
  }
}
