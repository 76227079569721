import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class MeasuringRuleService {
  public isMeasuringRuleActive = false

  private toggleMeasuringRuleSubject: BehaviorSubject<boolean>
  public isMeasuringRuleActive$: Observable<boolean>

  constructor() {
    this.toggleMeasuringRuleSubject = new BehaviorSubject(
      this.isMeasuringRuleActive
    )
    this.isMeasuringRuleActive$ = this.toggleMeasuringRuleSubject.asObservable()
  }

  public toggleRule(): void {
    this.isMeasuringRuleActive = !this.isMeasuringRuleActive
    this.toggleMeasuringRuleSubject.next(this.isMeasuringRuleActive)
  }
}
