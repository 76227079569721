import { Injectable } from '@angular/core'
import { Observable, of, Subject, Subscription } from 'rxjs'
import { Vector2 } from 'three'
import { Vertex } from '../@shared/@interfaces/vertex'
import { DxfEditorService } from './dxf-editor.service'

@Injectable({
  providedIn: 'root',
})
export class CotasServiceService {
  private dxfVertex: Vertex[] = []
  private dxfVextexSubscription: Subscription

  // DXf dimensions
  private dxfAbsoluteDimensions: DxfDimensions
  private dxfAbsoluteDimensionsSubject: Subject<DxfDimensions>
  public dxfAbsoluteDimensions$: Observable<DxfDimensions>

  private dxfDimensions: DxfDimensions
  private dxfDimensionsSubject: Subject<DxfDimensions>
  public dxfDimensions$: Observable<DxfDimensions>

  // Emit dimensions from drawer
  private cotasDimensionsSubject: Subject<CotasDimmesion>
  public cotasDimensions$: Observable<CotasDimmesion>

  // Hide/show cotas
  private hideShowHorizontalSubject: Subject<boolean | null>
  public hideShowHorizontal$: Observable<boolean | null>
  private hideShowVerticalSubject: Subject<boolean | null>
  public hideShowVertical$: Observable<boolean | null>

  // Create cota observable
  private createCotaESubject: Subject<number | null>
  public createCotaE$: Observable<number | null>

  private createCotaASubject: Subject<number | null>
  public createCotaA$: Observable<number | null>

  private createCotaBSubject: Subject<number | null>
  public createCotaB$: Observable<number | null>

  private createCotaCSubject: Subject<number | null>
  public createCotaC$: Observable<number | null>

  private createCotaDSubject: Subject<number | null>
  public createCotaD$: Observable<number | null>

  private cleanCotasSubject: Subject<void>
  public cleanCotas$: Observable<void>

  private dxfServiceSubscription: Subscription

  constructor(private dxfService: DxfEditorService) {
    this.dxfServiceSubscription = this.dxfService.dxfVertex$.subscribe(
      (vertex: Vertex[]) => {
        this.dxfVertex = vertex

        this.getDxfAbsoluteDimensions()
        this.emitDxfAbsoluteDimensions()

        this.getDxfDimensions()
        this.emitDxfDimensions()
      }
    )

    this.buildObservables()
  }

  public onDestroy(): void {
    this.dxfService?.onDestroy()
    this.dxfVextexSubscription?.unsubscribe()
  }

  private buildObservables(): void {
    // Dxf dimensions observables
    this.dxfAbsoluteDimensionsSubject = new Subject<DxfDimensions>()
    this.dxfAbsoluteDimensions$ = this.dxfAbsoluteDimensionsSubject.asObservable()

    this.dxfDimensionsSubject = new Subject<DxfDimensions>()
    this.dxfDimensions$ = this.dxfDimensionsSubject.asObservable()

    // Cota Observables
    this.createCotaASubject = new Subject<number | null>()
    this.createCotaA$ = this.createCotaASubject.asObservable()

    this.createCotaBSubject = new Subject<number | null>()
    this.createCotaB$ = this.createCotaBSubject.asObservable()

    this.createCotaCSubject = new Subject<number | null>()
    this.createCotaC$ = this.createCotaCSubject.asObservable()

    this.createCotaDSubject = new Subject<number | null>()
    this.createCotaD$ = this.createCotaDSubject.asObservable()

    this.createCotaESubject = new Subject<number | null>()
    this.createCotaE$ = this.createCotaESubject.asObservable()

    this.cotasDimensionsSubject = new Subject<CotasDimmesion>()
    this.cotasDimensions$ = this.cotasDimensionsSubject.asObservable()

    this.hideShowHorizontalSubject = new Subject<boolean | null>()
    this.hideShowVerticalSubject = new Subject<boolean | null>()
    this.hideShowHorizontal$ = this.hideShowHorizontalSubject.asObservable()
    this.hideShowVertical$ = this.hideShowVerticalSubject.asObservable()

    this.cleanCotasSubject = new Subject<void>()
    this.cleanCotas$ = this.cleanCotasSubject.asObservable()
  }

  private getDxfAbsoluteDimensions(): void {
    let dimensions: DxfDimensions = {
      start: new Vector2(Infinity, Infinity),
      end: new Vector2(0, 0),
    }

    this.dxfVertex.forEach((v) => {
      if (v.absolute.x < dimensions.start.x) dimensions.start.setX(v.absolute.x)
      if (v.absolute.x > dimensions.end.x) dimensions.end.setX(v.absolute.x)
      if (v.absolute.y < dimensions.start.y) dimensions.start.setY(v.absolute.y)
      if (v.absolute.y > dimensions.end.y) dimensions.end.setY(v.absolute.y)
    })

    this.dxfAbsoluteDimensions = dimensions
  }

  private getDxfDimensions(): void {
    let dimensions: DxfDimensions = {
      start: new Vector2(Infinity, Infinity),
      end: new Vector2(-Infinity, -Infinity),
    }

    this.dxfVertex.forEach((v) => {
      if (v.dxf.x < dimensions.start.x) dimensions.start.setX(v.dxf.x)
      if (v.dxf.x > dimensions.end.x) dimensions.end.setX(v.dxf.x)
      if (v.dxf.y < dimensions.start.y) dimensions.start.setY(v.dxf.y)
      if (v.dxf.y > dimensions.end.y) dimensions.end.setY(v.dxf.y)
    })

    this.dxfDimensions = dimensions
  }

  private emitDxfAbsoluteDimensions(): void {
    this.dxfService.dxfAbsoluteDimensions = this.dxfAbsoluteDimensions
    this.dxfAbsoluteDimensionsSubject.next(this.dxfAbsoluteDimensions)
  }
  private emitDxfDimensions(): void {
    this.dxfDimensionsSubject.next(this.dxfDimensions)
  }

  public createCotaE(widthMM?: number) {
    this.createCotaESubject.next(widthMM)
  }

  public createCotaA(widthMM?: number) {
    this.createCotaASubject.next(widthMM)
  }

  public createCotaB(widthMM?: number) {
    this.createCotaBSubject.next(widthMM)
  }

  public createCotaC(widthMM?: number) {
    this.createCotaCSubject.next(widthMM)
  }

  public createCotaD(widthMM?: number) {
    this.createCotaDSubject.next(widthMM)
  }

  public emitCotasDimension(cotasDimension: CotasDimmesion) {
    this.cotasDimensionsSubject.next(cotasDimension)
  }

  public hideShowHorizontalCotas(show: boolean): void {
    this.hideShowHorizontalSubject.next(show)
  }
  public hideShowVerticalCotas(show: boolean): void {
    this.hideShowVerticalSubject.next(show)
  }
  public hideShowAllCotas(show: boolean): void {
    this.hideShowHorizontalCotas(show)
    this.hideShowAllCotas(show)
  }

  public cleanCotas(): void {
    this.cleanCotasSubject.next()
  }
}

export interface DxfDimensions {
  start: Vector2
  end: Vector2
}

export interface CotasDimmesion {
  A: number
  B: number
  C: number
  D: number
  E: number
}
