import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  AfterViewInit,
} from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'

import { CoverGapForm } from 'src/app/@shared/@interfaces/coverGapForm'
import { CoverGaps } from 'src/app/@shared/@interfaces/coverGaps'
import { SideOptions } from 'src/app/@shared/@types/side.types'
import { UnionPositions } from 'src/app/@shared/@types/unionPositions.type'
import { UnionSelect } from '../unions-column/unions-column.component'

@Component({
  selector: 'app-cover-gap',
  templateUrl: './cover-gap.component.html',
  styleUrls: ['./cover-gap.component.scss'],
})
export class CoverGapComponent implements OnInit {
  @ViewChild('unionSelector')
  private unionSelectorElement: ElementRef<HTMLDivElement>

  @Input() serieId = 1
  @Input() coverGaps: CoverGaps
  @Input() auxCoverGaps: any

  @Output() coverGapsEmitter = new EventEmitter<CoverGaps>()

  public showUnionSelector = false

  // Form
  public form: FormGroup
  public selectedSide: UnionPositions = 'left-top'

  public overlaps = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  }

  public cutVariations = {
    top: {
      id: 1,
      value: 0,
    },
    bottom: {
      id: 1,
      value: 0,
    },
    left: {
      id: 1,
      value: 0,
    },
    right: {
      id: 1,
      value: 0,
    },
  }

  constructor(private fb: FormBuilder, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.buildForm()
    this.setOverlaps()
    this.setCutVariations()
  }

  private setOverlaps() {
    if (this.coverGaps) {
      this.overlaps = {
        top: this.coverGaps?.top?.overlap ?? 0,
        bottom: this.coverGaps?.bottom?.overlap ?? 0,
        left: this.coverGaps?.left?.overlap ?? 0,
        right: this.coverGaps?.right?.overlap ?? 0,
      }
    }
  }

  private setCutVariations() {
    if (this.coverGaps) {
      this.cutVariations = {
        top: this.coverGaps?.top?.cutVariation ?? this.cutVariations.top,
        bottom:
          this.coverGaps?.bottom?.cutVariation ?? this.cutVariations.bottom,
        left: this.coverGaps?.left?.cutVariation ?? this.cutVariations.left,
        right: this.coverGaps?.right?.cutVariation ?? this.cutVariations.right,
      }
    }
  }

  private buildForm(): void {
    this.form = this.fb.group({
      clips: this.fb.group({
        top: [this.coverGaps?.top?.clip ?? 0],
        bottom: [this.coverGaps?.bottom?.clip ?? 0],
        left: [this.coverGaps?.left?.clip ?? 0],
        right: [this.coverGaps?.right?.clip ?? 0],
      }),
      unions: this.fb.group({
        'left-top': [this.coverGaps?.unions?.['left-top'] ?? 1],
        'right-top': [this.coverGaps?.unions?.['right-top'] ?? 1],
        'left-bottom': [this.coverGaps?.unions?.['left-bottom'] ?? 1],
        'right-bottom': [this.coverGaps?.unions?.['right-bottom'] ?? 1],
      }),
    })
  }

  public unionsForm(): FormGroup {
    return this.form.get('unions') as FormGroup
  }

  private emit(): void {
    this.buildEmit()
    this.coverGapsEmitter.emit(this.coverGaps)
  }

  private buildEmit(): void {
    this.coverGaps.unions = this.unionsForm().value
    if (this.coverGaps?.bottom)
      this.coverGaps.bottom.clip = this.coverGaps?.bottom?.clip ?? 0
    if (this.coverGaps?.top)
      this.coverGaps.top.clip = this.coverGaps?.top?.clip ?? 0
    if (this.coverGaps?.left)
      this.coverGaps.left.clip = this.coverGaps?.left?.clip ?? 0
    if (this.coverGaps?.right)
      this.coverGaps.right.clip = this.coverGaps?.right?.clip ?? 0
  }

  public isSidesSelected(
    externalInternal: 'internal' | 'external'
  ): { [k in SideOptions]: boolean } {
    const has = externalInternal === 'external' ? 'hasExternal' : 'hasInternal'

    return {
      top: this.coverGaps?.top?.[externalInternal]
        ? this.coverGaps?.top?.[has]
        : false,
      bottom: this.coverGaps?.bottom?.[externalInternal]
        ? this.coverGaps?.bottom?.[has]
        : false,
      left: this.coverGaps?.left?.[externalInternal]
        ? this.coverGaps?.left?.[has]
        : false,
      right: this.coverGaps?.right?.[externalInternal]
        ? this.coverGaps?.right?.[has]
        : false,
    }
  }

  public unionImage(side: UnionPositions): string {
    const id = this.unionsForm().get(side).value
    return `../../../assets/icons/unions/${id}.svg`
  }

  public setCoverGap(coverGapForm: CoverGapForm, side: SideOptions): void {
    if (!this.coverGaps) {
      this.coverGaps = {
        bottom: null,
        left: null,
        right: null,
        top: null,
        unions: null,
      }
    }

    this.coverGaps[side] = coverGapForm

    if (coverGapForm?.external?.all) {
      this.setAll(coverGapForm, 'external')
    }

    if (coverGapForm?.internal?.all) {
      this.setAll(coverGapForm, 'internal')
    }

    this.emit()
  }

  public setAll(
    coverGapForm: CoverGapForm,
    externalInternal: 'external' | 'internal'
  ): void {
    coverGapForm[externalInternal].all = false

    const has = externalInternal === 'external' ? 'hasExternal' : 'hasInternal'

    this.coverGaps.top = {
      ...this.coverGaps.top,
      [has]: coverGapForm[has],
      [externalInternal]: coverGapForm[externalInternal],
    }
    this.coverGaps.bottom = {
      ...this.coverGaps.bottom,
      [externalInternal]: coverGapForm[externalInternal],
    }
    this.coverGaps.left = {
      ...this.coverGaps.left,
      [externalInternal]: coverGapForm[externalInternal],
    }
    this.coverGaps.right = {
      ...this.coverGaps.right,
      [externalInternal]: coverGapForm[externalInternal],
    }

    this.setSides()
  }

  private setSides(): void {
    this.coverGaps.bottom.side = 'bottom'
    this.coverGaps.top.side = 'top'
    this.coverGaps.left.side = 'left'
    this.coverGaps.right.side = 'right'
  }

  public openUnionSelector(event: MouseEvent, side: UnionPositions) {
    if (this.showUnionSelector) {
      this.hideUnionSelector()
      return (this.showUnionSelector = false)
    }

    this.selectedSide = side

    const position = {
      x: event.x + 24 + 'px',
      y: side.includes('bottom') ? event.y - 280 + 'px' : event.y + 16 + 'px',
    }

    this.renderer.setStyle(
      this.unionSelectorElement.nativeElement,
      'display',
      'flex'
    )

    this.renderer.setStyle(
      this.unionSelectorElement.nativeElement,
      'left',
      position.x
    )
    this.renderer.setStyle(
      this.unionSelectorElement.nativeElement,
      'top',
      position.y
    )

    this.showUnionSelector = true
  }

  public hideUnionSelector(): void {
    this.showUnionSelector = false

    this.renderer.setStyle(
      this.unionSelectorElement.nativeElement,
      'display',
      'none'
    )
  }

  public chooseUnion(union: UnionSelect) {
    this.unionsForm().get(union.side).setValue(union.union)
    this.hideUnionSelector()
    this.emit()
  }
}
