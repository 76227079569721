<div class="content">
  <div class="content-header">
    <h2>Agregar Accesorio</h2>
    <fa-icon [icon]="faTimes" class="content-header__icon" (click)="decline()"></fa-icon>
  </div>
  <hr />
  <form [formGroup]="form" autocomplete="off">
    <div class="form-serie-data">
      <div class="input-group mb-4">
        <app-input label="Accesorio" id="description">
          <input type="text" class="form-item__input description" id="name" name="name" placeholder="Accesorio"
            formControlName="name" disabled="true" />
          <fa-icon [icon]="faEdit" class="icon edit" (click)="openAccesoryModalDialog()" *ngIf="!quantity"></fa-icon>
        </app-input>
      </div>

      <div class="input-group mb-4">
        <app-input label="Medida" id="um">
          <input type="text" class="form-item__input um" id="um" name="um" placeholder="Medida" formControlName="um"
            disabled="true" />
        </app-input>
      </div>

      <div class="input-group mb-4">
        <app-input label="Cantidad" id="quantity">
          <input type="number" class="form-item__input quantity" id="quantity" name="quantity" placeholder="Cantidad"
            formControlName="quantity" [readonly]="quantity" />
        </app-input>
        <app-input-alert *ngFor="
                    let errorMessage of inputErrorMessages(
                      'quantity',
                      'Debe indicar una cantidad'
                    )
                  " [title]="errorMessage"></app-input-alert>
      </div>

      <div class="input-group mb-4">
        <app-input label="Color" width="max-content">
          <app-dropdown-input [items]="colorTypes" [title]="getColor()" (selectItemEmitter)="selecPosition($event)">
          </app-dropdown-input>
        </app-input>
      </div>
    </div>

    <div class="d-flex justify-content-center submit-button">
      <button class="btn btn-primary" (click)="onSubmit()">Aceptar</button>
    </div>
  </form>
</div>