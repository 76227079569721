import { Injectable } from '@angular/core'

export const CUT_VARIATIONS = {
  nothing: 1,
  cutDifferenteTo90degrees: 2,
  retested: 3,
  stopFinReduction: 4,
  centerSuperpositionReduction: 5,
  value: 6,
  value2: 7,
  value1_2: 8,
}

@Injectable({
  providedIn: 'root',
})
export class CutVariationService {
  public cutVariationTypes: CutVariationTypes = CUT_VARIATIONS

  constructor() {}
}

export interface CutVariationTypes {
  nothing: number
  cutDifferenteTo90degrees: number
  retested: number
  stopFinReduction: number
  centerSuperpositionReduction: number
  value: number
  value2: number
  value1_2: number
}
