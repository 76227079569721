import { ProfileElement, ProfileElementCreator } from './Profile-model'

export class InversorElement extends ProfileElement {
  constructor(inversor: InversorElementCreator) {
    super(inversor)

    this.hide()
    this.group.name('inversor')
  }
}

export interface InversorElementCreator extends ProfileElementCreator {}
