<div class="container fade-in">
  <div class="header">
    <h1 class="header__title">Gestión de perfiles</h1>
    <a routerLink="../">
      <fa-icon [icon]="faTimes" class="header__icon"></fa-icon>
    </a>
  </div>

  <form class="form-inline" [formGroup]="profileDataForm">
    <div class="input-item">
      <div class="input-group form-inline-item" [ngClass]="{ disabled: true }">
        <label for="serie" class="form-inline-item__label">Serie</label>
        <div class="form-inline-item__separator"></div>
        <input
          type="text"
          autocomplete="off"
          class="form-inline-item__input"
          id="serie"
          placeholder="Serie"
          formControlName="serie"
        />
      </div>
      <app-input-alert
        *ngFor="
          let errorMessage of isInvalidControl(
            'serie',
            'Debe indicar una serie'
          )
        "
        [title]="errorMessage"
      ></app-input-alert>
    </div>

    <div class="input-item">
      <div
        class="input-group form-inline-item name"
        [ngClass]="{ disabled: false }"
      >
        <label for="name" class="form-inline-item__label">Código perfil</label>
        <div class="form-inline-item__separator"></div>
        <input
          type="text"
          autocomplete="off"
          class="form-inline-item__input"
          id="name"
          placeholder="Código"
          formControlName="name"
        />
      </div>
      <app-input-alert
        *ngFor="
          let errorMessage of isInvalidControl('name', 'Debe indicar el nombre')
        "
        [title]="errorMessage"
      ></app-input-alert>
    </div>

    <div class="input-item">
      <div class="input-group form-inline-item description">
        <label for="description" class="form-inline-item__label"
          >Denominación</label
        >
        <div class="form-inline-item__separator"></div>
        <input
          type="text"
          autocomplete="off"
          class="form-inline-item__input description"
          id="description"
          placeholder="Descripción"
          formControlName="description"
        />
      </div>
      <app-input-alert
        *ngFor="
          let errorMessage of isInvalidControl(
            'description',
            'Debe indicar la descripción'
          )
        "
        [title]="errorMessage"
      ></app-input-alert>
    </div>
  </form>

  <div class="content">
    <div class="content-navbar">
      <a
        class="content-navbar-link"
        [routerLink]="['./technical']"
        routerLinkActive="active"
        >Datos técnicos</a
      >
      <a
        class="content-navbar-link"
        [routerLink]="['./uses']"
        routerLinkActive="active"
        >Usos</a
      >
      <a
        class="content-navbar-link"
        [routerLink]="['./accessories']"
        routerLinkActive="active"
        >Combinación de accesorios</a
      >
      <a
        class="content-navbar-link"
        [routerLink]="['./optimization']"
        routerLinkActive="active"
        >Corte y optimización</a
      >
    </div>

    <div class="content-container">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
