<div class="content">
  <div class="content-header">
    <h2>Lista de accesorios</h2>
    <fa-icon
      [icon]="faTimes"
      class="content-header__icon"
      (click)="decline()"
    ></fa-icon>
  </div>
  <hr />

  <br />
  <div class="fade-in">
    <table class="table">
      <thead class="cerulean">
        <tr>
          <th>Código</th>
          <th>Familia</th>
          <th>Descripción</th>
          <th>Cantidad</th>
          <th>Unidad</th>
          <th>Color</th>
          <th>Opcional</th>
          <th>Grupo</th>
          <th scope="col">
            <div class="icons">
              <fa-icon class="icon add"></fa-icon>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let acc of accessoriesModel">
          <td>
            {{ acc.codRef }}
          </td>
          <td>{{ acc.accessory.family }}</td>
          <td>{{ acc.accessory.denomination }}</td>
          <td>{{ acc.quantity }}</td>
          <td>{{ getUnit(acc.um) }}</td>
          <td>
            <div
              class="table-color"
              [ngStyle]="{ backgroundColor: acc.color?.code }"
              [title]="acc.color?.description"
            ></div>
          </td>
          <td>{{ acc.type ? 'no' : 'sí' }}</td>
          <td>
            {{
              !acc.accessorySerieGroup ? 'no' : acc.accessorySerieGroup?.name
            }}
          </td>
          <td>
            <div class="table-head-icons">
              <fa-icon
                [icon]="faEdit"
                class="table-head-icons__icon edit"
                (click)="editAccessory(acc)"
              >
              </fa-icon>
              <fa-icon
                [icon]="faTrash"
                class="table-head-icons__icon delete"
                (click)="deleteAccessory(acc)"
                *ngIf="!acc.type"
              >
              </fa-icon>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
