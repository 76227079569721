import Konva from 'konva'
import { Area, AreaModel } from './area-model'

import * as uuidGenerator from 'uuid'
import { Vector2 } from 'three'

import { Observable, Subject, Subscription } from 'rxjs'
import { Frame } from './frame-model'
import { DoorWindowTipupOpeningElement } from './door-window-tipupOpening-model'
import { CrossbarElement } from './crossbar-model'
import { JonquilloElement } from './jonquillo-model'
import { ElementSelectable } from './elementSelectable'
import { ModelElement } from '../../@interfaces/modelElement'

export class Gap implements ModelElement {
  private _uuid: string

  private _x: number
  private _y: number
  private _width: number
  private _height: number
  private _areas: AreaModel[] = []
  private _modelId: number

  public gap: Konva.Rect
  public readonly layer: Konva.Layer

  private gapUpdateSubject: Subject<Gap>
  public gapUpdated$: Observable<Gap>

  private areasUpdateSubject: Subject<AreaModel[]>
  public areasUpdate$: Observable<AreaModel[]>

  private removeAreaSubject: Subject<AreaModel>
  public removeArea$: Observable<AreaModel>

  public scaleWidth = 1
  public scaleHeight = 1

  // Selecteds
  public selectedElement: ElementSelectable
  public selectedArea: AreaModel

  private areaSubscriptions: Subscription[] = []
  private framesSubscriptions: Subscription[] = []
  private doorwindowOpeningSubscriptions: Subscription[] = []
  private crossbarSubcritions: Subscription[] = []
  private jonquilloSubscriptions: Subscription[] = []

  public stage: Konva.Stage

  public container: Area

  public isResized = false

  constructor(
    uuid: string,
    x: number,
    y: number,
    width: number,
    height: number,
    layer: Konva.Layer,
    modelId: number,
    scaleWidth: number,
    scaleHeight: number,
    stage?: Konva.Stage
  ) {
    this._uuid = uuid
    this._modelId = modelId

    this._x = x
    this._y = y
    this._width = width
    this._height = height

    this.container = new Area({
      verticalContain: [],
      horizontalContain: [],
      width: this.width,
      height: this.height,
      x: 0,
      y: 0,
      modelId: modelId,
    })

    this.scaleWidth = scaleWidth
    this.scaleHeight = scaleHeight

    this.stage = stage
    this.layer = layer

    this.gapUpdateSubject = new Subject<Gap>()
    this.gapUpdated$ = this.gapUpdateSubject.asObservable()

    this.areasUpdateSubject = new Subject<AreaModel[]>()
    this.areasUpdate$ = this.areasUpdateSubject.asObservable()

    this.removeAreaSubject = new Subject()
    this.removeArea$ = this.removeAreaSubject.asObservable()

    this.createGap()
  }

  // Getters
  get uuid(): string {
    return this._uuid
  }
  get xScaled(): number {
    return this._x
  }
  get x(): number {
    return this._x / this.scaleWidth
  }

  get yScaled(): number {
    return this._y
  }
  get y(): number {
    return this._y / this.scaleHeight
  }

  get width(): number {
    return this._width
  }

  get height(): number {
    return this._height
  }

  get modelId(): number {
    return this._modelId
  }

  get areas(): AreaModel[] {
    return this._areas
  }

  // Setters
  set uuid(uuid: string) {
    this._uuid = uuid
  }
  set width(width: number) {
    this._width = width
  }
  set height(height: number) {
    this._height = height
  }

  public onDestroy(): void {
    this.removeFramesSubcriptions()
    this.removeAreaSubscriptions()
    this.areas.forEach((a) => {
      a.destroy()
    })

    this.layer.destroyChildren()
    this.layer.destroy()
  }

  public getSelectedElement<T>(instanceOf: any): T | null {
    if (this.selectedElement instanceof instanceOf)
      return (this.selectedElement as unknown) as T

    return null
  }

  public screenShot(): Gap {
    const layer = new Konva.Layer()

    const shotGap = new Gap(
      uuidGenerator.v4(),
      this.xScaled,
      this.yScaled,
      this.width,
      this.height,
      layer,
      this.modelId,
      this.scaleWidth,
      this.scaleHeight,
      this.stage
    )

    return shotGap
  }

  private createGap(): void {
    this.gap = new Konva.Rect({
      x: this.xScaled,
      y: this.yScaled,
      width: this.width * this.scaleWidth,
      height: this.height * this.scaleHeight,
      stroke: 'red',
      strokeWidth: 1,
    })
      .addName('GAP')
      .setZIndex(0)

    this.fill()
    this.layer.add(this.gap)
  }

  public addArea(area: AreaModel): void {
    this._areas.push(area)

    this.layer.add(area.group)
    this.layer.draw()

    this.notifyUpdate()
    this.notifyAreasUpdate()
  }
  public removeArea(uuid: string): void {
    const areasAux: AreaModel[] = []
    const area = this.areas.find((a) => a.uuid === uuid)

    this.areas.forEach((a) => {
      if (uuid === a.uuid) {
        a.destroy()
        return
      }

      areasAux.push(a)
    })

    this._areas = areasAux
    this.notifyAreasUpdate()
    this.removeAreaSubject.next(area)
  }

  public getArea(uuid: string): AreaModel | null {
    const area = this._areas.find((a) => a.uuid === uuid)
    return area
  }

  public fill() {
    if (this._areas.length === 0) {
      const firstArea = new AreaModel(
        uuidGenerator.v4(),
        0,
        0,
        this.width,
        this.height,
        'A1',
        this.layer,
        this.modelId,
        new Vector2(this.xScaled, this.yScaled),
        this.scaleWidth,
        this.scaleHeight,
        this
      )

      firstArea.container = this.container
      firstArea.container.setVinculatedArea(firstArea)

      this._areas.push(firstArea)
    }

    this._areas.forEach((a) => this.layer.add(a.group))

    this.notifyUpdate()
    this.notifyAreasUpdate()
  }

  public setGapDownZIndex(): void {
    this.gap.setZIndex(0)
    this.removeAreaSubscriptions()
    this.areas.forEach((a, index) => {
      a.area.removeEventListener('click')

      const subs = a.setClickListener().subscribe((area) => {
        if (area) {
          this.selectedElement?.unSelect({ emit: false })
          this.selectedElement = null
        }

        if (area?.uuid !== this.selectedArea?.uuid)
          this.selectedArea?.unSelectArea()

        this.selectedArea = area
        this.gapUpdateSubject.next(this)
        this.layer.draw()
      })
      this.areaSubscriptions.push(subs)
    })
  }

  public subscribeToFrames(): void {
    this.removeFramesSubcriptions()

    this._areas.forEach((a) =>
      a.frames.forEach((f) => {
        const subs = f.addListener<Frame>().subscribe((frame) => {
          this.unSelectAll(frame)
        })
        this.framesSubscriptions.push(subs)
      })
    )
  }

  public subscribeToDoorWindowOpenings(): void {
    this.removeOpeningSubscriptions()

    const subscribersUuid = []

    this._areas.forEach((a) => {
      if (!a.doorWindowOpenings) return
      if (subscribersUuid.includes(a.doorWindowOpenings?.uuid)) return
      subscribersUuid.push(a.doorWindowOpenings?.uuid)

      const subs = a.doorWindowOpenings
        ?.addListener<DoorWindowTipupOpeningElement>()
        .subscribe((opening) => {
          this.unSelectAll(opening)
        })
      this.doorwindowOpeningSubscriptions.push(subs)
    })
  }

  public subscribeCrossbar(): void {
    this.removeCrossbarSubscriptions()

    let crossbarIn = []
    this._areas.forEach((a) => {
      a.crossbars.forEach((c) => {
        if (crossbarIn.includes(c.uuid)) return
        crossbarIn.push(c.uuid)

        const subs = c.addListener<CrossbarElement>().subscribe((crossbar) => {
          this.unSelectAll(crossbar)
        })
        this.crossbarSubcritions.push(subs)
      })
    })
  }

  public subscribeJonquillo(): void {
    this.removeJonquilloSubscriptions()

    this._areas.forEach((a) => {
      const subs = a.jonquillo
        ?.addListener<JonquilloElement>()
        .subscribe((j) => {
          this.unSelectAll(j)
        })

      this.jonquilloSubscriptions.push(subs)
    })
  }

  public selectArea(uuid: string): AreaModel {
    this.unselectAllAreas()
    this.selectedArea = this._areas.find((a) => {
      if (a.uuid === uuid) {
        a.selectArea()
        return a
      }
    })

    return this.selectedArea
  }

  public unSelectAll(element?: ElementSelectable): void {
    this.unselectAllAreas()
    this.selectedElement?.unSelect({ emit: false })
    this.selectedElement = element

    this.notifyUpdate()
  }

  public unselectAllAreas(): void {
    this._areas.forEach((a) => a.unSelectArea())
    this.selectedArea = null
  }

  public resize(width: number, height: number): void {
    this._width = width
    this._height = height

    this.container.resize(width, height, { x: 0, y: 0 })

    this.gap.width(this._width * this.scaleWidth)
    this.gap.height(this._height * this.scaleHeight)

    this.isResized = true
  }

  private removeAreaSubscriptions(): void {
    this.areaSubscriptions?.forEach((s) => s.unsubscribe())
    this.areaSubscriptions = []
  }

  private removeFramesSubcriptions(): void {
    this.framesSubscriptions.forEach((f) => f.unsubscribe())
    this.framesSubscriptions = []
  }

  private removeOpeningSubscriptions(): void {
    this.doorwindowOpeningSubscriptions?.forEach((s) => s?.unsubscribe())
    this.doorwindowOpeningSubscriptions = []
  }

  private removeCrossbarSubscriptions(): void {
    this.crossbarSubcritions.forEach((c) => c.unsubscribe())
    this.crossbarSubcritions = []
  }

  private removeJonquilloSubscriptions(): void {
    this.jonquilloSubscriptions.forEach((j) => j?.unsubscribe())
    this.jonquilloSubscriptions = []
  }

  public countLeafs(): number {
    const openingsCounted = []
    let count = 0

    this._areas.forEach((a) => {
      if (!a.doorWindowOpenings) return
      if (openingsCounted.includes(a.doorWindowOpenings.uuid)) return

      openingsCounted.push(a.doorWindowOpenings.uuid)
      count += a.doorWindowOpenings.countLeafs()
    })

    return count
  }

  public hasFixedElements(): boolean {
    const hasSomeFixed = this._areas.some((a) => a.hasFixedElements())
    return hasSomeFixed
  }

  public notifyUpdate(): void {
    this.gapUpdateSubject.next(this)
  }

  public notifyAreasUpdate(): void {
    this.areasUpdateSubject.next(this.areas)
  }
}
