import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-opening-door',
  templateUrl: './opening-door.component.html',
  styleUrls: ['./opening-door.component.scss'],
})
export class OpeningDoorComponent implements OnInit {
  public openingDoorImages = [
    'Internaderecha.svg',
    'Internaizquierda.svg',
    'Externadercha.svg',
    'Externazquierda.svg',
  ]

  constructor() {}

  ngOnInit(): void {}
}
