<div id="filesMenuContainer">
  <div id="menusContainers" class="row col">
    <div class="col-3">
      <div class="shadow-sm">
        <app-files-menu
          [menuListItems]="listItemsMenuProfilesMachines"
        ></app-files-menu>
      </div>
    </div>
    <div class="col-3">
      <div class="shadow-sm">
        <app-files-menu
          [menuListItems]="listItemsMenuAccessoriesProdcuts"
        ></app-files-menu>
      </div>
    </div>
  </div>
</div>
