import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ProfileComponent } from './profile.component'

const routes: Routes = [
  {
    path: '',
    component: ProfileComponent,
    children: [
      {
        path: 'technical',
        loadChildren: () =>
          import(
            './model_view/technical-data-model-view/technical-data-model-view.module'
          ).then((m) => m.TechnicalDataModelViewModule),
      },
      {
        path: 'uses',
        loadChildren: () =>
          import('./model_view/uses-model-view/uses-model-view.module').then(
            (m) => m.UsesModelViewModule
          ),
      },
      {
        path: 'accessories',
        loadChildren: () =>
          import(
            './model_view/accessories-model-view/accessories-model-view.module'
          ).then((m) => m.AccessoriesModelViewModule),
      },
      {
        path: 'optimization',
        loadChildren: () =>
          import(
            './model_view/cutting-optimization-model-view/cutting-optimization-model-view.module'
          ).then((m) => m.CuttingOptimizationModelViewModule),
      },

      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'technical-data',
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProfileRoutingModule {}
