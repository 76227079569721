import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { Area } from '../@shared/@models/modelsElements/area-model'

@Injectable({
  providedIn: 'root',
})
export class AreaContainerService {
  private readonly apiUrl = environment.apiUrl + 'area-container'

  constructor(private http: HttpClient) {}

  public createAreaContainer(area: Area): Promise<any> {
    const requests = this.getAreasRequests(area)

    return this.http.post(this.apiUrl, requests).toPromise()
  }

  private getAreasRequests(area: Area): AreaContainerRequest[] {
    const requests: AreaContainerRequest[] = []
    const areaRequest = this.getRequest(area)
    requests.push(areaRequest)

    area.verticalContain.forEach((a) => {
      requests.push(...this.getAreasRequests(a))
    })

    area.horizontalContain.forEach((a) => {
      requests.push(...this.getAreasRequests(a))
    })

    return requests
  }

  private getRequest(area: Area): AreaContainerRequest {
    return {
      uuid: area.uuid,
      parent: area.parent?.uuid ?? null,
      verticalElements: area.verticalElements().map((e) => e.uuid),
      horizontalElements: area.horizontalElements().map((e) => e.uuid),
      verticalAreas: area.verticalContain.map((a) => a.uuid),
      horizontalAreas: area.horizontalContain.map((a) => a.uuid),
      width: area.width,
      height: area.height,
      x: area.x,
      y: area.y,
      model: area.modelId,
    }
  }
}

interface AreaContainerRequest {
  uuid: string
  parent: string | null
  verticalElements: string[]
  horizontalElements: string[]
  verticalAreas: string[]
  horizontalAreas: string[]
  width: number
  height: number
  x: number
  y: number
  model: number
}
