import Konva from 'konva'
import { Vector2 } from 'three'
import { ModelElement } from '../../@interfaces/modelElement'

import { ProfileCreator } from '../../@interfaces/profileCreator.model'
import { SideOptions } from '../../@types/side.types'
import { UnionPositions } from '../../@types/unionPositions.type'
import { Area } from './area-model'
import { BarLength, Frame } from './frame-model'
import { Gap } from './gap-model'
import { ProfileElement } from './Profile-model'

export class FrameSide extends ProfileElement implements ModelElement {
  public frame: Frame

  public readonly areaUuid: string

  public profileID: number
  public profile: ProfileCreator

  public areaId: number

  public profileDraw: Konva.Line
  private layer: Konva.Layer

  public unions: { [k in UnionPositions]?: number }
  public unionAngles: { [k in UnionPositions]?: 45 | 90 } = {}
  public side: SideOptions

  private _originalLength = 0

  private _barLength: BarLength = {
    ext: 0,
    int: 0,
  }

  public container: Area

  constructor(
    uuid: string,
    profile: ProfileCreator,
    x: number,
    y: number,
    originalLength: number,
    profileID: number,
    areaID: number,
    areaUuid: string,
    profileDraw: Konva.Line,
    layer: Konva.Layer,
    scaleFactor: number,
    gap: Gap,
    frame: Frame,
    unions: { [k in UnionPositions]?: number },
    side: SideOptions,
    axis: 'Horizontal' | 'Vertical',
    id?: number
  ) {
    super({
      gap: gap,
      profile,
      profileDraw,
      scaleFactor,
      side,
      thickness: profile.internalCamera,
      unions,
      uuid,
      x,
      y,
      axis,
    })

    this._originalLength = originalLength

    this.frame = frame

    this.profileID = profileID
    this.areaId = areaID

    this.layer = layer

    this.areaUuid = areaUuid
  }

  get thickness(): number {
    return this._thickness / this.scaleFactor
  }

  get x(): number {
    return this._x / this.scaleFactor
  }

  get y(): number {
    return this._y / this.scaleFactor
  }

  get width(): number {
    return this._width / this.scaleFactor
  }

  get height(): number {
    return this._height / this.scaleFactor
  }

  get barLength() {
    return this._barLength
  }

  get originalLength() {
    return this._originalLength
  }

  set barLength(barLength: BarLength) {
    this._barLength = {
      ext: barLength.ext,
      int: barLength.int,
    }
  }

  public externalScaledBarLength(): number {
    const vectors = this.pointsAsVector()
    let length = 0

    if (this.axis === 'Horizontal') {
      return Math.abs(vectors.endExt.x - vectors.startExt.x)
    }

    if (this.axis === 'Vertical') {
      return Math.abs(vectors.endExt.y - vectors.startExt.y)
    }
  }
}
