import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ProfileCreator } from 'src/app/@shared/@interfaces/profileCreator.model'
import { CutVariation } from 'src/app/@shared/@models/cut-variation.model'
import { SideOptions } from 'src/app/@shared/@types/side.types'

@Component({
  selector: 'app-associated-profiles-selector',
  templateUrl: './associated-profiles-selector.component.html',
  styleUrls: ['./associated-profiles-selector.component.scss'],
})
export class AssociatedProfilesSelectorComponent implements OnInit {
  @Input() public associatedProfiles: AssociatedProfiles = {
    top: [],
    bottom: [],
    left: [],
    right: [],
  }

  @Output()
  public associatedProfilesEmitter = new EventEmitter<AssociatedProfiles>()

  constructor() {}

  ngOnInit(): void {
    this.emit()
  }

  public hasProfiles(side: SideOptions): boolean {
    return this.associatedProfiles?.[side]?.length > 0
  }

  public addProfile(
    side: SideOptions,
    associatedProfile: AssociatedProfile[]
  ): void {
    this.associatedProfiles[side] = [...associatedProfile]
    this.emit()
  }

  private emit(): void {
    this.associatedProfilesEmitter.emit(this.associatedProfiles)
  }
}

export interface AssociatedProfiles {
  top: AssociatedProfile[]
  bottom: AssociatedProfile[]
  left: AssociatedProfile[]
  right: AssociatedProfile[]
}

export interface AssociatedProfile {
  profile: ProfileCreator
  overlap: number
  cutVariation: { id: number; value: number; value2?: number }
  union: number
}
