import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { Crystal, CrystalCreator } from '../@shared/@interfaces/crystal'
import { CrystalTypes } from '../@shared/@interfaces/crystal-types'

@Injectable({
  providedIn: 'root',
})
export class CrystalService {
  private apiUrl = environment.apiUrl

  constructor(private http: HttpClient) { }

  public createCrystal(
    crystal: CrystalCreator
  ): Observable<number> {
    return this.http
      .post<number>(`${this.apiUrl}crystals`, crystal)
  }

  public getAllCrystals(): Observable<Crystal[]> {
    return this.http.get<Crystal[]>(`${this.apiUrl}crystals`)
  }

  public getCrystalsTypes(): Observable<CrystalTypes[]> {
    return this.http.get<CrystalTypes[]>(`${this.apiUrl}crystal-types`)
  }

  public updateCrystal(
    crystal: CrystalCreator,
    id: number
  ): Observable<any> {
    return this.http.put(`${this.apiUrl}crystals/${id}`, crystal)
  }

  public deleteCrystal(crystal: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}crystals/${crystal}`)
  }
}
