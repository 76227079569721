import { Component, Input, OnInit, SimpleChanges } from '@angular/core'
import { ToastrService } from 'ngx-toastr'
import { Subscription } from 'rxjs'
import {
  CreateCrossbar,
  NewAreaDialogComponent,
} from 'src/app/@dialogs/new-area-dialog/new-area-dialog.component'
import { ModalService } from 'src/app/@services/modal.service'
import { CrossbarDrawerService } from 'src/app/@services/modelDrawServices/crossbar-drawer.service'
import { DialogInstanceModel } from 'src/app/@shared/@models/dialog-instance-model'
import { CrossbarElement } from 'src/app/@shared/@models/modelsElements/crossbar-model'
import { Gap } from 'src/app/@shared/@models/modelsElements/gap-model'
import { IconSidebarItem } from '../icon-sidebar-item/icon-sidebar.interface'

@Component({
  selector: 'app-add-crossbar-icon-item',
  templateUrl: './add-crossbar-icon-item.component.html',
  styleUrls: ['./add-crossbar-icon-item.component.scss'],
})
export class AddCrossbarIconItemComponent implements OnInit, IconSidebarItem {
  @Input() gap: Gap
  @Input() modelObject

  public gapSubscription: Subscription
  public canUpdate: boolean

  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private crossbarDrawer: CrossbarDrawerService
  ) {}

  ngOnDestroy(): void {
    this.gapSubscription?.unsubscribe()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.gap) this.gapSubscribe()
  }

  public gapSubscribe(): void {
    if (this.gapSubscription) return

    this.gapSubscription = this.gap.gapUpdated$.subscribe(() => {
      this.canUpdate = this.canActivateBotton()
    })
  }

  public canActivateBotton(): boolean {
    const hasSelectedArea = this.gap?.selectedArea ? true : false

    return hasSelectedArea
  }

  ngOnInit(): void {}

  // Create new areas (Crossbar)
  public addArea(): any {
    let area = this.gap?.selectedArea
    let selectedCrossbar = this.gap?.getSelectedElement<CrossbarElement>(
      CrossbarElement
    )

    if (!area && !selectedCrossbar)
      return this.toastr.warning('Seleccione un área')

    const newAreaDialog = new DialogInstanceModel(NewAreaDialogComponent, {
      class: 'modal-dialog-centered',
      initialState: {
        selectedArea: area,
        serieId: this.modelObject.serie.id,
        ...(selectedCrossbar ? { selectedCrossbar } : {}),
      },
    })

    this.modalService
      .openModal(newAreaDialog)
      .subscribe((response: CreateCrossbar) => {
        if (!response) return this.removeCrossbar(selectedCrossbar)

        this.createCrossbar(response)
        this.gap.subscribeCrossbar()
      })
  }

  private createCrossbar(response: CreateCrossbar): void {
    const orientation =
      response.axisSelected === 'X' ? 'Horizontal' : 'Vertical'

    this.crossbarDrawer.addCrossbarsFromselected(
      response,
      orientation,
      this.gap
    )
  }

  private removeCrossbar(selectedCrossbar: CrossbarElement): void {}
}
