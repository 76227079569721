import { FlatTreeControl } from '@angular/cdk/tree'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { SerieCreator } from 'src/app/@shared/@interfaces/serieCreator'
import { FlatNode } from 'src/app/@shared/MatTreeConfig/treeDataStructure'

export interface SerieNode {
  serie: SerieCreator
  children?: SerieNode[]
}

@Component({
  selector: 'app-series-tree',
  templateUrl: './series-tree.component.html',
  styleUrls: ['./series-tree.component.scss'],
})
export class SeriesTreeComponent implements OnInit {
  @Input() public treeNode: any = []
  @Input() public selectedSerie: SerieCreator = null

  @Output() private selectSerieEmitter = new EventEmitter<SerieCreator>()
  @Output() private searchEmitter = new EventEmitter<string>()

  public treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable
  )

  constructor(private router: Router) {}

  ngOnInit(): void {}

  public back(): void {
    this.router.navigateByUrl('/files')
  }

  public onSearch(criteria: string): void {
    this.searchEmitter.emit(criteria)
  }

  public onSelectSerie(serie: SerieCreator): void {
    this.selectSerieEmitter.emit(serie)
  }
}
