import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Subscription } from 'rxjs'
import { UnionService } from 'src/app/@services/union.service'
import { UnionModel } from 'src/app/@shared/@models/union-model'
import { UnionPositions } from 'src/app/@shared/@types/unionPositions.type'

@Component({
  selector: 'app-unions-column',
  templateUrl: './unions-column.component.html',
  styleUrls: ['./unions-column.component.scss'],
})
export class UnionsColumnComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public formGroup: FormGroup
  @Input() public withCustom = true
  @Input() private side: UnionPositions
  @Input() private actualUnion = 1

  @Output()
  private unionSelectEmitter: EventEmitter<UnionSelect> = new EventEmitter<UnionSelect>()

  public unionForm: FormGroup
  public unions: UnionModel[] = []

  private unionControlSubscription: Subscription

  constructor(private fb: FormBuilder, private unionService: UnionService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.unionControlSubscription?.unsubscribe()
    this.unionForm?.get('union').setValue(this.actualUnion ?? 1)
    this.onUnionSelectionChange()
  }

  ngOnInit(): void {
    this.buildForm()
    this.onUnionSelectionChange()
    this.getUnions()
  }

  ngOnDestroy(): void {
    this.unionControlSubscription.unsubscribe()
  }

  private buildForm(): void {
    if (this.formGroup) {
      this.unionForm = this.formGroup
      return
    }

    this.unionForm = this.fb.group({
      union: [this.actualUnion ?? 1, Validators.required],
    })
  }

  private onUnionSelectionChange(): void {
    this.unionControlSubscription = this.unionForm
      ?.get('union')
      .valueChanges.subscribe((value) => {
        this.emit(value)
      })
  }

  public emit(value: number): void {
    this.unionSelectEmitter.emit({
      side: this.side,
      union: value,
    })
  }

  private getUnions(): void {
    this.unionService.getUnions().subscribe((unions) => {
      if (!this.withCustom)
        return (this.unions = unions.filter((u) => u.id !== 7))

      this.unions = unions
    })
  }
}

export interface UnionSelect {
  side: string
  union: number
}
