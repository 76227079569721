import Konva from 'konva'
import { Observable, Subject, Subscription } from 'rxjs'
import { Vector2 } from 'three/src/math/Vector2'
import { ModelElement } from '../../@interfaces/modelElement'
import { Area } from './area-model'
import { TipupLeaftOpening } from './door-window-leaft-model'
import { ElementSelectable } from './elementSelectable'
import { ProfileElement } from './Profile-model'
import { PROFILE_KONVA_NAMES } from './types-elements-names'

export class DoorWindowTipupOpeningElement
  extends ElementSelectable
  implements ModelElement {
  private _id: number

  public readonly leafts: TipupLeaftOpening[] = []
  public doorStepElement: ProfileElement

  private subscriptions: Subscription[] = []

  private _mainOverlap = 0

  public container: Area

  constructor(public uuid: string) {
    super()
    this.group = new Konva.Group().addName('door-window tipup opening')
  }

  get id(): number {
    return this._id
  }

  get mainOverlap(): number {
    return this._mainOverlap
  }

  public setMainOverlap(overlap: number): void {
    this._mainOverlap = overlap
  }

  public setId(id: number): void {
    this._id = id
  }

  public destroy(): void {
    this.group.destroy()

    this.leafts.forEach((l, index) => {
      if (index !== 0) l.destroy()
      l?.jonquillo?.destroy()
      l.associatedArea.removeDoorOpening(this.uuid)
      l.associatedArea.removeJonquillo(l?.jonquillo?.uuid)
    })

    this.subscriptions.forEach((s) => s.unsubscribe())
  }

  public addLeaft(leaftElement: TipupLeaftOpening): void {
    this.leafts.push(leaftElement)
    this.group.add(leaftElement.group)

    const hasInversor = leaftElement.inversor ? true : false
    if (hasInversor) leaftElement.group.zIndex(0)

    if (leaftElement.isOpenToOut()) leaftElement.group.setZIndex(0)
  }

  public addDoorStep(doorStep: ProfileElement): void {
    this.doorStepElement = doorStep
    this.group.add(doorStep.group)
  }

  public addSubscriptions(subs: Subscription[]): void {
    this.subscriptions = [...this.subscriptions, ...subs]
  }

  public getTotalWidth(): number {
    const { left, top: topFirstLeaft } = this.leafts[0].profilesElements
    const scaleFactor = left.scaleFactor

    const { startInt: start } = topFirstLeaft.pointsAsVector(
      topFirstLeaft.externalFinDraw.points()
    )

    const { top: topLastLeaft } = this.leafts[
      this.leafts.length - 1
    ].profilesElements

    const { endInt: end } = topLastLeaft.pointsAsVector(
      topLastLeaft.externalFinDraw.points()
    )

    return Math.abs(end.x - start.x) / scaleFactor
  }

  public getTotalHeight(): number {
    const { left, top } = this.leafts[0].profilesElements
    const scaleFactor = left.scaleFactor

    const { startInt: start, endInt: end } = left.pointsAsVector(
      left.externalFinDraw.points()
    )

    return Math.abs(end.y - start.y) / scaleFactor
  }

  public getPosition(): Vector2 {
    const { top } = this.leafts[0].profilesElements

    const { startInt: start } = top.pointsAsVector(top.externalFinDraw.points())

    return start
  }

  /**
   * countLeafs
   */
  public countLeafs(): number {
    return this.leafts.length
  }
}
