<div class="associated-profiles-content">
  <app-associated-profiles-table
    class="associated-table top"
    [associatedProfilesSide]="associatedProfiles.top"
    (associatedProfilesSideEmitter)="addProfile('top', $event)"
  ></app-associated-profiles-table>

  <app-associated-profiles-table
    class="associated-table left"
    [associatedProfilesSide]="associatedProfiles.left"
    (associatedProfilesSideEmitter)="addProfile('left', $event)"
  ></app-associated-profiles-table>

  <div class="central-rect">
    <app-scheme-side-rect
      [sides]="{
        top: hasProfiles('top'),
        bottom: hasProfiles('bottom'),
        left: hasProfiles('left'),
        right: hasProfiles('right')
      }"
    ></app-scheme-side-rect>
  </div>

  <app-associated-profiles-table
    class="associated-table right"
    [associatedProfilesSide]="associatedProfiles.right"
    (associatedProfilesSideEmitter)="addProfile('right', $event)"
  ></app-associated-profiles-table>

  <app-associated-profiles-table
    class="associated-table bottom"
    [associatedProfilesSide]="associatedProfiles.bottom"
    (associatedProfilesSideEmitter)="addProfile('bottom', $event)"
  ></app-associated-profiles-table>
</div>
