<div class="content-container" #container>
  <canvas class="content" #content></canvas>
</div>

<div class="modal-context-menu p-0" #modal>
  <div class="modal-context-menu__title text-center" *ngIf="dxfSelected">
    <span>{{ dxfSelected.profile.description }}</span>
  </div>

  <div class="actions d-flex" *ngIf="dxfSelected">
    <button (click)="removeDxf()">Remove dxf</button>
    <button (click)="rotate()">Rotar</button>
    <button (click)="reflect()">Reflejar</button>
    <button (click)="duplicate()">Duplicar</button>
  </div>
</div>
