import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { DxfContent } from 'src/app/@shared/@interfaces/dxf-content'
import { ProfileCreator } from 'src/app/@shared/@interfaces/profileCreator.model'
import { DXF } from 'src/app/presentation/shared/models/DXF.model'

@Injectable({
  providedIn: 'root',
})
export class DxfConfectionService {
  private listDxfSubject: BehaviorSubject<DXF[]>
  private addProfileEmitter: Subject<ProfileDisAssembler>
  private removeProfileEmitter: Subject<DXF>

  public listDxfs$: Observable<DXF[]>
  public onAddProfile$: Observable<ProfileDisAssembler>
  public onRemoveProfile$: Observable<DXF>

  constructor() {
    this.addProfileEmitter = new Subject()
    this.removeProfileEmitter = new Subject()
    this.listDxfSubject = new BehaviorSubject<DXF[]>([])

    this.onAddProfile$ = this.addProfileEmitter.asObservable()
    this.onRemoveProfile$ = this.removeProfileEmitter.asObservable()
    this.listDxfs$ = this.listDxfSubject.asObservable()
  }

  public addProfile(profile: ProfileDisAssembler) {
    this.addProfileEmitter.next(profile)
  }

  public removeProfile(dxf: DXF) {
    this.removeProfileEmitter.next(dxf)
  }

  public emitListDxfs(listDxfs: DXF[]) {
    this.listDxfSubject.next(listDxfs)
  }
}

export interface ProfileDisAssembler {
  uuid: string
  profile: ProfileCreator
  dxfParsed: DxfContent
  offset?: string
}
