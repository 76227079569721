export const PROFILE_KONVA_NAMES = {
  stroke: 'stroke',
  profile: 'profile',
  leaftTipup: 'leaftTipup',
  externalFin: 'externalFin',
  internalFin: 'internalFin',
}

export const FRAME_COLOR = '#b2adad'
export const FRAME_STROKE_COLOR = 'black'
