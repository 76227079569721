<div class="header-container">
  <div>
    <h3 class="title">
      <button class="btn" (click)="back()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <strong>Paneles</strong>
    </h3>
  </div>
</div>

<div class="header-items">
  <app-search-inline (searchEmitter)="onSearch($event)"></app-search-inline>
  <button class="newPanelButton btn btn-primary" (click)="addNew()">
    <mat-icon>add</mat-icon>
    Nuevo panel
  </button>
</div>

<mat-tree [dataSource]="treeNode" [treeControl]="treeControl" *ngIf="treeNode.length > 0">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding class="mat-tree-node">
    <span class="tree-item__code">{{ node.panel.name }}</span>
    <div>
      <button (click)="editNode(node.panel)" class="btn">
        <mat-icon>edit</mat-icon>
      </button>
      <button (click)="deleteNode(node.panel)" class="btn">
        <mat-icon>delete_outline</mat-icon>
      </button>
    </div>
  </mat-tree-node>
</mat-tree>