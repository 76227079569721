import { Component, OnInit, ViewChild } from '@angular/core'

import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { AccessorieGroupListComponent } from 'src/app/@components/accessorie-group-list/accessorie-group-list.component'
import { SerieNode } from 'src/app/@components/series-tree/series-tree.component'
import { AddNameDialogComponent } from 'src/app/@dialogs/add-name-dialog/add-name-dialog.component'
import { ModalService } from 'src/app/@services/modal.service'
import { SeriesService } from 'src/app/@services/series.service'
import { SerieCreator } from 'src/app/@shared/@interfaces/serieCreator'
import { DialogInstanceModel } from 'src/app/@shared/@models/dialog-instance-model'

@Component({
  templateUrl: './accesories-groups.component.html',
  styleUrls: ['./accesories-groups.component.scss'],
})
export class AccesoriesGroupsComponent implements OnInit {
  @ViewChild(AccessorieGroupListComponent)
  private accesorieListComponent: AccessorieGroupListComponent

  private series: SerieCreator[] = []
  public treeNode: SerieNode[] = []

  public selectedSerie: SerieCreator

  // Icons
  public faPlus = faPlus
  public faTimes = faTimes

  constructor(
    private serieService: SeriesService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.getSeries()
  }

  public addGroupToList() {
    const addNameModal = new DialogInstanceModel(AddNameDialogComponent, {
      initialState: {},
      class: 'modal-dialog-centered',
    })

    this.modalService.openModal(addNameModal).subscribe((name) => {
      this.accesorieListComponent.addGroup(name)
    })
  }

  private getSeries(): void {
    this.serieService.getSeries().subscribe((series) => {
      this.series = series

      this.buildTreeNode()
    })
  }

  private buildTreeNode(): void {
    this.treeNode = this.series.map((s) => ({ serie: s }))
  }

  public onSelectSerie(serie: SerieCreator | null): void {
    this.selectedSerie = serie
  }

  public onSearch(criteria: string): void {
    if (!criteria) return this.buildTreeNode()

    criteria = criteria.toLowerCase()

    let auxTreeNode: SerieNode[] = []

    this.series.forEach((s) => {
      const name = s.name.toLowerCase()
      if (name.includes(criteria)) auxTreeNode.push({ serie: s })
    })

    this.treeNode = null
    this.treeNode = auxTreeNode
  }

  public onSearchGroup(criteria: string): void {
    this.accesorieListComponent.search(criteria)
  }
}
