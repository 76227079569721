import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms'

// TOOLS
import { invalidInputMessages } from 'src/app/@helper/invalidInputShowMessage'

// toastr
import { ToastrService } from 'ngx-toastr'

// Icons
import { faTimes, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

// Services
import { SeriesService } from 'src/app/@services/series.service'
import { ModalService } from 'src/app/@services/modal.service'

// Models
import { MillType } from 'src/app/@shared/@models/millType'
import { Opening } from 'src/app/@shared/@models/opening'
import { CrystalOverlap } from 'src/app/@shared/@models/crystalOverlap.model'
import { SerieOverlap } from 'src/app/@shared/@models/serie-overlap'
import { HeightReference } from 'src/app/@shared/@models/heightReference.model'
import { IdAndNameModel } from 'src/app/@shared/@models/idAndName.model'
import { SerieCreator } from 'src/app/@shared/@interfaces/serieCreator'
import { UnionModel } from 'src/app/@shared/@models/union-model'
import { DialogInstanceModel } from 'src/app/@shared/@models/dialog-instance-model'

// Components
import { DeleteItemDialogComponent } from 'src/app/@dialogs/delete-item-dialog/delete-item-dialog.component'
import { Color, SerieColor } from 'src/app/@shared/@interfaces/color.model'
import { SerieColorService } from 'src/app/@services/serie-color.service'

@Component({
  selector: 'app-serie-form',
  templateUrl: './serie-form.component.html',
  styleUrls: ['./serie-form.component.scss'],
})
export class SerieFormComponent implements OnInit, OnChanges {
  @Output()
  public hideSerieEditorEmitter: EventEmitter<void> = new EventEmitter<void>()
  @Output()
  public addSerieEmitter: EventEmitter<any> = new EventEmitter<any>()
  @Output()
  public updateSerieEmitter: EventEmitter<any> = new EventEmitter<any>()

  @Input() private serie: IdAndNameModel

  public form: FormGroup
  private hasTriedSubmit = false

  // Icons
  public faTimes = faTimes
  public faExclamationCircle = faExclamationCircle

  // Dropdowns selecteds
  public millTypeSelected: MillType
  public openingsSelected: Opening
  public showSerieRadios: boolean
  public showCristalRadios: boolean
  public unionJonquilloSelected: UnionModel
  public heightMinus1800Selected: HeightReference
  public heightMore1800Selected: HeightReference

  // Dependencies
  public millTypes: MillType[] = []
  public unionJonquillos: UnionModel[] = []
  public crystalOverlaps: CrystalOverlap[] = []
  public openings: Opening[] = []
  public serieOverlaps: SerieOverlap[] = []
  public heightReferences: HeightReference[] = []

  public aviableColors: Color[] = []
  public serieColors: Color[] = []

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private seriesService: SeriesService,
    private serieColorService: SerieColorService,
    private modalService: ModalService
  ) {
    // Init selecteds
    this.millTypeSelected = new MillType(-1, 'Tipo')
    this.openingsSelected = new Opening(-1, 'Apertura')
    this.heightMinus1800Selected = new HeightReference(-1, 'Altura')
    this.heightMore1800Selected = new HeightReference(-1, 'Altura')

    // Init component
    this.initComponent()
    this.showSerieRadios = true
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isNeededClearForm(changes)) this.clearForm()
    if (this.isNeededUpdateForm(changes)) this.updateComponent()
  }

  ngOnInit(): void {}

  private async initComponent(): Promise<void> {
    this.buildForm()
    this.getAviableColors()
    await this.getDependencies()
  }

  private updateComponent(): void {
    this.seriesService.getSerieById(this.serie.id).subscribe(
      (serie) => {
        this.updateForm(serie)
        this.getSerieColors()
      },
      (err) => {
        console.dir(err)
        this.toastr.error(err)
      }
    )
  }

  private clearForm(): void {
    // this.form.reset()
    this.buildForm()
  }
  private updateForm(newSerie: any): void {
    this.onSelectHeightMinus1800(newSerie.heightReferenceLow)
    this.onSelectHeightMore1800(newSerie.heightReferenceHigh)
    this.onSelectMillType(newSerie.millType)
    this.onSelectOpening(newSerie.opening)

    newSerie.unionJonquillo = newSerie.unionJonquillo?.id
    if (newSerie.serieOverlap) {
      newSerie.serieOverlap = newSerie.serieOverlap?.id
      this.selectSerieRadios(newSerie)
    }
    if (newSerie.crystalOverlap) {
      newSerie.crystalOverlap = newSerie.crystalOverlap?.id
      this.selectCristalRadios(newSerie.crystalOverlap)
    }

    newSerie.overlapAmount = Number(newSerie.overlapAmount)

    this.form.patchValue(newSerie)
  }

  private buildForm(): void {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required, Validators.maxLength(140)]],
      barLength: [6500, [Validators.required, Validators.min(0)]],
      openingOffset: [0, [Validators.min(0)]],
      openingOffsetDoor: [0, [Validators.min(0)]],
      floorPaneDistance: [0, [Validators.min(0)]],
      openingOverlap: [0, [Validators.min(0)]],
      millType: [''],
      millVariation: [0, [Validators.min(0)]],
      unionJonquillo: [1],
      opening: [''],
      serieOverlap: [null],
      overlapAmount: [0, [Validators.min(0)]],
      heightReferenceLow: [''],
      heightLow: [0, [Validators.min(0)]],
      heightReferenceHigh: [''],
      heightHigh: [0, [Validators.min(0)]],
      hasRoture: [false, [Validators.required]],
    })
  }

  getFormControl(controlName: string): AbstractControl {
    return this.form.get(controlName)
  }

  private async getDependencies(): Promise<void> {
    // TODO: Revisar para optimizar
    await this.seriesService
      .getMillTypes()
      .toPromise()
      .then((milltypes) => (this.millTypes = milltypes))
      .catch((err) => {})
    await this.seriesService
      .getBeadJoins()
      .toPromise()
      .then((beadjoins) => {
        this.unionJonquillos = beadjoins
        this.unionJonquillos.map((beadjoin) => {
          beadjoin.image = `../../../assets/icons/unions/${beadjoin.id}.svg`
          return beadjoin
        })
      })
      .catch((err) => {})
    await this.seriesService
      .getCrystalOverLaps()
      .toPromise()
      .then((crystalOverlaps) => (this.crystalOverlaps = crystalOverlaps))
      .catch((err) => {})
    await this.seriesService
      .getOpenings()
      .toPromise()
      .then((openings) => (this.openings = openings))
      .catch((err) => {})
    await this.seriesService
      .getSerieOverLaps()
      .toPromise()
      .then((overlaps) => (this.serieOverlaps = overlaps))
      .catch((err) => {})
    await this.seriesService
      .getHeightReferences()
      .toPromise()
      .then((heightReferences) => {
        this.heightReferences = heightReferences
      })
      .catch((err) => {
        console.log(err)
      })
  }

  public selectSerieRadios(serie?: any): void {
    this.form.addControl(
      'serieOverlap',
      new FormControl(serie ? serie.serieOverlap : null)
    )
    this.form.addControl(
      'overlapAmount',
      new FormControl(serie ? serie.overlapamount : 0)
    )
    this.showSerieRadios = true
    this.showCristalRadios = false
    this.form.removeControl('crystalOverlap')
  }

  public selectCristalRadios(value?: number): void {
    this.form.addControl('crystalOverlap', new FormControl(value || null))
    this.showCristalRadios = true
    this.showSerieRadios = false
    this.form.removeControl('serieOverlap')
    this.form.removeControl('overlapAmount')
  }

  //On Submit
  public onSubmit(): any {
    this.hasTriedSubmit = true
    if (this.canSubmit()) {
      const isNewSerie = this.serie ? false : true
      const newSerie = this.buildSerieForSubmit()
      this.confirmSubmit(isNewSerie, newSerie)
    }
    this.form.markAllAsTouched()
  }

  private confirmSubmit(isNewSerie: boolean, newSerie: SerieCreator): void {
    const dialogModel = new DialogInstanceModel(DeleteItemDialogComponent, {
      initialState: {
        headerContent: 'Algunos campos son requeridos para ciertas series',
        bodyContent:
          '¿Estás seguro de que quieres guardar la serie con estos datos?',
      },
      class: 'modal-dialog-centered',
    })

    this.modalService.openModal(dialogModel).subscribe(
      async (response: boolean) => {
        if (response) {
          let serieId: number
          if (isNewSerie) serieId = await this.createSerie(newSerie)
          if (!isNewSerie) serieId = await this.updateSerie(newSerie)
          this.createSerieColors(serieId)
        }
      },
      (err) => {
        console.dir(err)
        this.toastr.error(err)
      }
    )
  }

  private buildSerieForSubmit(): SerieCreator {
    const newSerie: SerieCreator = this.form.value
    newSerie.millType =
      this.millTypeSelected?.id >= 0 ? this.millTypeSelected : null
    newSerie.opening =
      this.openingsSelected?.id >= 0 ? this.openingsSelected : null
    newSerie.heightReferenceHigh =
      this.heightMore1800Selected?.id >= 0 ? this.heightMore1800Selected : null
    newSerie.heightReferenceLow =
      this.heightMinus1800Selected?.id >= 0
        ? this.heightMinus1800Selected
        : null
    newSerie.unionJonquillo = { id: Number(newSerie.unionJonquillo) }

    if (this.showSerieRadios) {
      newSerie.serieOverlap = newSerie.serieOverlap
        ? { id: Number(newSerie.serieOverlap) }
        : null
      newSerie.crystalOverlap = null
    }

    if (this.showCristalRadios) {
      newSerie.crystalOverlap = newSerie.crystalOverlap
        ? { id: Number(newSerie.crystalOverlap) }
        : null
      newSerie.serieOverlap = null
      newSerie.overlapAmount = null
    }

    return newSerie
  }
  private createSerie(serie: SerieCreator): Promise<number> {
    return new Promise((resolve, reject) => {
      this.seriesService.createNewSerie(serie).subscribe(
        (response) => {
          this.toastr.success('La serie se ha creado')
          this.addSerieEmitter.emit({
            id: response.id,
            name: response.name,
            description: response.description,
          })
          this.selectSerieRadios()
          this.clearForm()
          resolve(response.id)
        },
        (error) => {
          this.toastr.error('No ha sido posible crear la serie')
          resolve(null)
        }
      )
    })
  }
  private updateSerie(serie: SerieCreator): Promise<number> {
    return new Promise((resolve, reject) => {
      this.seriesService.updateSerie(this.serie.id, serie).subscribe(
        (response) => {
          this.toastr.success('La serie se ha actualizado')
          this.updateSerieEmitter.emit({
            name: serie.name,
            id: this.serie.id,
          })
          resolve(this.serie.id)
        },
        (err) => {
          this.toastr.error('No ha sido posible actualizar la serie')
          resolve(null)
        }
      )
    })
  }

  // Check valid
  public canSubmit(): boolean {
    const canSubmit = this.form.valid
    return canSubmit
  }

  public isInvalidControl(control: string): boolean {
    const controlForm = this.form.get(control)
    return controlForm.invalid && controlForm.touched
  }
  public inputErrorMessages(
    control: string,
    requiredMessage: string
  ): string[] {
    return invalidInputMessages(this.form, control, requiredMessage)
  }

  // On Selects
  public onSelectMillType(milltype: MillType): void {
    this.millTypeSelected = milltype
  }
  public onSelectOpening(opening: Opening): void {
    this.openingsSelected = opening
  }
  public onSelectHeightMore1800(height: HeightReference): void {
    const isCenter = this.isHeightReferenceeCenter(height)

    if (isCenter) this.form.get('heightHigh').setValue(0)

    this.heightMore1800Selected = height
  }
  public onSelectHeightMinus1800(height: HeightReference): void {
    const isCenter = this.isHeightReferenceeCenter(height)
    if (isCenter) this.form.get('heightLow').setValue(0)

    this.heightMinus1800Selected = height
  }

  public hideSerieEditor(): void {
    this.hideSerieEditorEmitter.emit()
  }

  // Verifications
  private isNeededUpdateForm(changes: SimpleChanges): boolean {
    const hasSerie = changes.serie.currentValue ? true : false
    if (!hasSerie) return false

    const hasSerieChanged =
      changes.serie.currentValue.id !== changes.serie.previousValue?.id

    return hasSerieChanged
  }

  private isNeededClearForm(changes: SimpleChanges): boolean {
    const hasSerie = this.serie ? true : false
    const hasForm = this.form ? true : false

    if (hasSerie && hasForm) return false

    return true
  }

  // check is height referencee is center
  public isHeightReferenceeCenter(heightReference: HeightReference): boolean {
    const isCenter = heightReference?.name === 'Centrar'

    return isCenter
  }

  // Serie Colors
  public getAviableColors(): void {
    this.serieColorService.serieAviableColors().subscribe((colors) => {
      this.aviableColors = colors
    })
  }

  public getSerieColors(): void {
    if (!this.serie) return

    this.serieColorService.getSerieColor(this.serie.id).subscribe((colors) => {
      colors

      this.serieColors = colors.map((c) => c.color)
    })
  }

  public setSerieColors(colors: Color[]): void {
    this.serieColors = colors
  }

  private async createSerieColors(serieId: number): Promise<void> {
    if (!serieId) return
    await this.serieColorService.deleteAllSerieColors(serieId).toPromise()
    await this.serieColorService
      .createSerieColors(serieId, this.serieColors)
      .toPromise()
  }
}
