// ANGULAR
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
// TOOLS
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { JonquilloCreator } from '../@shared/@interfaces/JonquilloCreator'
import { Jonquillo } from '../@shared/@interfaces/jonquillo'

@Injectable({
  providedIn: 'root',
})
export class JonquilloService {
  private apiUrl = environment.apiUrl

  constructor(private http: HttpClient) {}

  public createJonquillo(jonquillo: JonquilloCreator): Observable<Jonquillo> {
    return this.http.post<Jonquillo>(`${this.apiUrl}jonquillos`, jonquillo)
  }

  public deleteJonquillo(jonquilloId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}jonquillos/${jonquilloId}`)
  }
}
