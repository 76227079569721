import { HttpClient, HttpClientModule } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import {
  CovergapRequest,
  CovergapSideRequest,
} from '../@shared/@interfaces/covergapRequest'

@Injectable({
  providedIn: 'root',
})
export class CovergapsService {
  private apiUrl = environment.apiUrl

  constructor(private http: HttpClient) {}

  public createCovergap(
    external: CovergapRequest,
    internal: CovergapRequest,
    model: number
  ): Observable<any> {
    const isInvalidRequest = !external && !internal

    if (isInvalidRequest) throw new Error('Invalid covergap request')

    const request = this.buildRequest(model, external, internal)
    return this.http.post(`${this.apiUrl}flashings`, request)
  }

  public deleteCovergap(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}flashings/${id}`)
  }

  private buildRequest(
    model: number,
    external?: CovergapRequest,
    internal?: CovergapRequest
  ): CovergapCreateRequest {
    const frameId = external ? external.frame.id : internal.frame.id
    const clips = external ? external.clip : internal.clip

    return {
      model,
      frame: { id: frameId },
      topExtSide: external?.top?.profile?.id ? external?.top : null,
      bottomExtSide: external?.bottom?.profile?.id ? external?.bottom : null,
      leftExtSide: external?.left?.profile?.id ? external?.left : null,
      rigthExtSide: external?.right?.profile?.id ? external?.right : null,

      topIntSide: internal?.top?.profile?.id ? internal?.top : null,
      bottomIntSide: internal?.bottom?.profile?.id ? internal?.bottom : null,
      leftIntSide: internal?.left?.profile?.id ? internal?.left : null,
      rigthIntSide: internal?.right?.profile?.id ? internal?.right : null,

      bottomCutout: clips.bottom ?? 0,
      topCutout: clips.top ?? 0,
      leftCutout: clips.left ?? 0,
      rigthCutout: clips.right ?? 0,
    }
  }
}

interface CovergapCreateRequest {
  model: number
  frame: {
    id: number
  }
  leftExtSide: CovergapSideRequest
  leftIntSide: CovergapSideRequest
  rigthExtSide: CovergapSideRequest
  rigthIntSide: CovergapSideRequest
  topExtSide: CovergapSideRequest
  topIntSide: CovergapSideRequest
  bottomExtSide: CovergapSideRequest
  bottomIntSide: CovergapSideRequest
  topCutout: number
  leftCutout: number
  rigthCutout: number
  bottomCutout: number
}
