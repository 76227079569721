export const pointsToVectors2d = (
  points: number[]
): Array<{ x: number; y: number }> => {
  let vectors: { x: number; y: number }[] = []

  for (let i = 0; i < points.length; i += 2) {
    vectors.push({
      x: points[i],
      y: points[i + 1],
    })
  }

  return vectors
}
