import Konva from 'konva'
import { Observable, Subject, Subscription } from 'rxjs'
import { AssociatedProfiles } from 'src/app/@components/associated-profiles-selector/associated-profiles-selector.component'
import { radianToDegree } from 'src/app/@helper/radianToDegree'
import { roundUp } from 'src/app/@helper/roundNumber'
import { FrameCreator } from '../../@interfaces/frameCreator'
import { ModelElement } from '../../@interfaces/modelElement'
import { SideOptions } from '../../@types/side.types'
import { UnionPositions } from '../../@types/unionPositions.type'
import { AreaModel } from './area-model'
import { AssociatedProfileFrame } from './associatedProfileFrame-model'
import { CoverGapElement } from './covergap-model'
import { FrameSide } from './frameSide-model'
import { PseudoFrame } from './pseudoframe-model'

export class Frame extends PseudoFrame implements ModelElement {
  public readonly uuid: string
  private _id: number

  public frameSideTop: FrameSide
  public frameSideBottom: FrameSide
  public frameSideLeft: FrameSide
  public frameSideRight: FrameSide

  public associatedProfiles: {
    [k in SideOptions]: AssociatedProfileFrame[]
  } = {
    top: [],
    bottom: [],
    left: [],
    right: [],
  }

  public associatedAreas: AreaModel[] = []

  private _unionId: number
  private _unions: { [k in UnionPositions]: number }
  private _overlaps: { [k in SideOptions]: number }
  private _cutVariations: { [k in SideOptions]: { id: number; value: number } }
  private _barlength: FrameBarLength

  public covergaps: {
    external?: CoverGapElement
    internal?: CoverGapElement
  } = {}

  // Observables
  private frameSelectorSubject: Subject<Frame>
  public frameSelectorObservable: Observable<Frame>

  // subscriptions
  private subscriptions: Subscription[] = []

  constructor(
    uuid: string,
    unionId: number,
    unions: { [k in UnionPositions]: number },
    overlaps: { [k in SideOptions]: number },
    cutVariations: { [k in SideOptions]: { id: number; value: number } }
  ) {
    super(uuid)
    this.uuid = uuid
    this._unionId = unionId
    this._unions = { ...unions }
    this._overlaps = overlaps
    this._cutVariations = cutVariations

    this.group = new Konva.Group().addName('frame')

    // Set observables
    this.frameSelectorSubject = new Subject<Frame>()
    this.frameSelectorObservable = this.frameSelectorSubject.asObservable()
  }

  set unionId(id: number) {
    this._unionId = id
  }

  set unions(unions: { [k in UnionPositions]: number }) {
    this._unions = unions
  }

  set overlaps(overlaps: { [k in SideOptions]: number }) {
    this._overlaps = overlaps
  }

  get unions() {
    return this._unions
  }

  get overlaps() {
    return this._overlaps
  }

  get cutVariations() {
    return this._cutVariations
  }

  get frameBarLenght() {
    return this._barlength
  }

  get unionId() {
    return this._unionId
  }
  get id() {
    return this._id
  }

  get frames(): FrameSide[] {
    return [
      this.frameSideLeft,
      this.frameSideRight,
      this.frameSideBottom,
      this.frameSideTop,
    ]
  }

  public setId(id: number) {
    this._id = id
  }

  // Associated Profiles
  public addAssociatedProfile(
    side: SideOptions,
    profile: AssociatedProfileFrame
  ) {
    this.associatedProfiles[side].push(profile)
    this.group.add(profile.group)
    profile.group.moveToBottom()
  }

  public associatedProfilesAsObject(): AssociatedProfiles {
    return {
      top: this.associatedProfiles.top.map((ap) => ap.toObject()),
      bottom: this.associatedProfiles.bottom.map((ap) => ap.toObject()),
      left: this.associatedProfiles.left.map((ap) => ap.toObject()),
      right: this.associatedProfiles.right.map((ap) => ap.toObject()),
    }
  }

  // Covergaps
  public addExternalCovergap(covergap: CoverGapElement) {
    this.covergaps.external = covergap
    this.group.add(covergap.group)
    covergap.group.zIndex(0)
  }

  public addInternalCovergap(covergap: CoverGapElement) {
    this.covergaps.internal = covergap
    this.group.add(covergap.group)
    covergap.group.zIndex(this.group.children.length - 1)
  }

  // Add frames
  public addTop(frameSide: FrameSide): void {
    this.frameSideTop = frameSide
    this.profilesElements.top = frameSide

    this.group.add(frameSide.group)
  }
  public addBottom(frameSide: FrameSide): void {
    this.frameSideBottom = frameSide
    this.profilesElements.bottom = frameSide

    this.group.add(frameSide.group)
  }
  public addLeft(frameSide: FrameSide): void {
    this.frameSideLeft = frameSide
    this.profilesElements.left = frameSide

    this.group.add(frameSide.group)
  }
  public addRight(frameSide: FrameSide): void {
    this.frameSideRight = frameSide
    this.profilesElements.right = frameSide

    this.group.add(frameSide.group)
  }

  public addSubscriptions(subs: Subscription[]): void {
    this.subscriptions = [...this.subscriptions, ...subs]
  }

  // Remove frames
  public removeTop(): void {
    this.frameSideTop = null
  }
  public removeBottom(): void {
    this.frameSideBottom = null
  }
  public removeLeft(): void {
    this.frameSideLeft = null
  }
  public removeRight(): void {
    this.frameSideRight = null
  }

  public remove(): void {
    // this.destroy()
  }

  public associateArea(area: AreaModel): void {
    const isAlreadyIn =
      this.associatedAreas.findIndex((a) => a.uuid === area.uuid) === -1
        ? false
        : true
    if (isAlreadyIn) return
    this.associatedAreas.push(area)
  }

  public removeArea(uuid: string): void {
    this.associatedAreas = this.associatedAreas.filter((a) => a.uuid !== uuid)
  }

  public destroy(): void {
    this.removeTop()
    this.removeBottom()
    this.removeLeft()
    this.removeRight()

    this.destroyListeners()
    this.group.destroy()

    this.subscriptions.forEach((s) => s.unsubscribe())
  }
  private destroyListeners(): void {
    this.frames.forEach((f) => {
      f?.destroy()
    })
  }

  public generateFrameRequest(model?: number): FrameCreator {
    const barsLength = this.calculateBarLength()
    this.setFrameSideLengths()

    const bottomAngles = this.frameSideBottom?.getProfileAngles()
    const topAngles = this.frameSideTop?.getProfileAngles()
    const leftAngles = this.frameSideLeft?.getProfileAngles()
    const rightAngles = this.frameSideRight?.getProfileAngles()

    const frameCreator: FrameCreator = {
      model,
      union: {
        id: this.unionId,
      },
      areas: [...this.associatedAreas.map((a) => ({ id: a.id }))],
      ...(this.frameSideBottom
        ? {
            bottomSide: {
              uuid: this.frameSideBottom?.uuid,
              profile: {
                id: this.frameSideBottom?.profileID,
              },
              superimposition: this.overlaps?.bottom,
              cutVariation: this.cutVariations?.bottom.value,
              typeVariation: {
                id: this.cutVariations?.bottom.id,
              },
              extLong: barsLength?.bottom?.ext,
              intLong: barsLength?.bottom?.int,
              ang: roundUp(radianToDegree(bottomAngles?.mainAngle ?? 0), 2),
              ang2: roundUp(radianToDegree(bottomAngles?.secondAngle ?? 0), 2),
            },
          }
        : {}),

      ...(this.frameSideTop
        ? {
            topSide: {
              uuid: this.frameSideTop?.uuid,
              profile: {
                id: this.frameSideTop?.profileID,
              },
              superimposition: this.overlaps?.top,
              cutVariation: this.cutVariations?.top?.value,
              typeVariation: {
                id: this.cutVariations?.top?.id,
              },
              extLong: barsLength?.top?.ext,
              intLong: barsLength?.top?.int,
              ang: roundUp(radianToDegree(topAngles?.mainAngle ?? 0), 2),
              ang2: roundUp(radianToDegree(topAngles?.secondAngle ?? 0), 2),
            },
          }
        : {}),

      ...(this.frameSideLeft
        ? {
            leftSide: {
              uuid: this.frameSideLeft?.uuid,
              profile: {
                id: this.frameSideLeft?.profileID,
              },
              superimposition: this.overlaps?.left,
              cutVariation: this.cutVariations?.left?.value,
              typeVariation: {
                id: this.cutVariations?.left?.id,
              },
              extLong: barsLength?.left?.ext,
              intLong: barsLength?.left?.int,
              ang: roundUp(radianToDegree(leftAngles?.mainAngle ?? 0), 2),
              ang2: roundUp(radianToDegree(leftAngles?.secondAngle ?? 0), 2),
            },
          }
        : {}),

      ...(this.frameSideRight
        ? {
            rigthSide: {
              uuid: this.frameSideRight?.uuid,
              profile: {
                id: this.frameSideRight?.profileID,
              },
              superimposition: this.overlaps?.right,
              cutVariation: this.cutVariations?.right?.value,
              typeVariation: {
                id: this.cutVariations?.right?.id,
              },
              extLong: barsLength?.right?.ext,
              intLong: barsLength?.right?.int,
              ang: roundUp(radianToDegree(rightAngles?.mainAngle ?? 0), 2),
              ang2: roundUp(radianToDegree(rightAngles?.secondAngle ?? 0), 2),
            },
          }
        : {}),

      leftBottom: {
        id: this.unions['left-bottom'],
      },
      rightBottom: {
        id: this.unions['right-bottom'],
      },
      leftTop: {
        id: this.unions['left-top'],
      },
      rightTop: {
        id: this.unions['right-top'],
      },
    }

    return frameCreator
  }

  public calculateBarLength(): FrameBarLength {
    const horizontalLengths = (
      frameSide: FrameSide
    ): { ext: number; int: number } => {
      if (!frameSide)
        return {
          ext: 0,
          int: 0,
        }

      const { startExt, startInt, endExt, endInt } = frameSide.pointsAsVector()

      const leftCutAngle = Math.atan(
        (frameSide.profile.internalCamera * frameSide.scaleFactor) /
          Math.abs(startExt.x - startInt.x)
      )

      const rightCutAngle = Math.atan(
        (frameSide.profile.internalCamera * frameSide.scaleFactor) /
          Math.abs(endExt.x - endInt.x)
      )

      let extDiffLeft =
        frameSide.profile.externalFin *
        frameSide.scaleFactor *
        Math.tan(leftCutAngle)

      let extDiffRight =
        frameSide.profile.externalFin *
        frameSide.scaleFactor *
        Math.tan(rightCutAngle)

      extDiffLeft = leftCutAngle === Math.PI / 2 ? 0 : extDiffLeft
      extDiffRight = rightCutAngle === Math.PI / 2 ? 0 : extDiffRight

      const extLength =
        (Math.abs(endExt.x - startExt.x) + extDiffLeft + extDiffRight) /
        frameSide.scaleFactor
      const intLength = Math.abs(endInt.x - startInt.x) / frameSide.scaleFactor

      return {
        ext: extLength,
        int: intLength,
      }
    }

    const verticalLengths = (
      frameSide: FrameSide
    ): { ext: number; int: number } => {
      if (!frameSide)
        return {
          ext: 0,
          int: 0,
        }

      const { startExt, startInt, endExt, endInt } = frameSide.pointsAsVector()

      const topCutAngle = Math.atan(
        (frameSide.profile.internalCamera * frameSide.scaleFactor) /
          Math.abs(startExt.y - startInt.y)
      )

      const bottomCutAngle = Math.atan(
        (frameSide.profile.internalCamera * frameSide.scaleFactor) /
          Math.abs(endExt.y - endInt.y)
      )

      let extDiffTop =
        frameSide.profile.externalFin *
        frameSide.scaleFactor *
        Math.tan(topCutAngle)
      let extDiffBottom =
        frameSide.profile.externalFin *
        frameSide.scaleFactor *
        Math.tan(bottomCutAngle)

      extDiffTop = topCutAngle === Math.PI / 2 ? 0 : extDiffTop
      extDiffBottom = bottomCutAngle === Math.PI / 2 ? 0 : extDiffBottom

      const extLength =
        (Math.abs(endExt.y - startExt.y) + extDiffTop + extDiffBottom) /
        frameSide.scaleFactor
      const intLength = Math.abs(endInt.y - startInt.y) / frameSide.scaleFactor

      return {
        ext: extLength,
        int: intLength,
      }
    }

    const barlength = {
      bottom: horizontalLengths(this.frameSideBottom),
      top: horizontalLengths(this.frameSideTop),
      right: verticalLengths(this.frameSideRight),
      left: verticalLengths(this.frameSideLeft),
    }

    Object.keys(barlength).forEach((k) => {
      barlength[k] = {
        ext: roundUp(barlength[k].ext, 2),
        int: roundUp(barlength[k].int, 2),
      }
    })

    return barlength
  }

  public setFrameSideLengths(): void {
    const barsLength = this.calculateBarLength()
    this.frameSideBottom?.barLength
      ? (this.frameSideBottom.barLength = barsLength.bottom)
      : null
    this.frameSideTop?.barLength
      ? (this.frameSideTop.barLength = barsLength.top)
      : null
    this.frameSideLeft?.barLength
      ? (this.frameSideLeft.barLength = barsLength.left)
      : null
    this.frameSideRight?.barLength
      ? (this.frameSideRight.barLength = barsLength.right)
      : null
  }

  public setZindex() {
    this.sides?.forEach((f) => f.profileDraw.zIndex(3))
    this.covergaps?.external?.sides?.forEach((c) => c.profileDraw.zIndex(2))
    this.covergaps?.internal?.sides?.forEach((c) => c.profileDraw.zIndex(1))

    Object.keys(this.associatedProfiles).forEach((key) => {
      const profiles = this.associatedProfiles[key] as AssociatedProfileFrame[]
      profiles.forEach((p) => p.profileDraw.zIndex(0))
    })
  }
}

export interface BarLength {
  ext: number
  int: number
}

interface FrameBarLength {
  top: BarLength
  bottom: BarLength
  left: BarLength
  right: BarLength
}
