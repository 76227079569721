<div class="flex-column col menuColumn">
  <div class="menuItemContainer">
    <h6 class="menuTitle">{{ menuListItems.menuTitle }}</h6>
  </div>
  <div
    class="menuItemContainer"
    *ngFor="let itemMenu of menuListItems.menuItems"
  >
    <div
      class="menuOptions"
      data-animation="center"
      [routerLink]="[itemMenu.menuItemAction]"
      (click)="onClickItem(itemMenu)"
    >
      <div class="circle"></div>
      <span class="optionTitle bodyS">{{ itemMenu.menuItemName }}</span>
    </div>
  </div>
</div>
