import { Injectable } from '@angular/core'
import Konva from 'konva'

import { UnionTypes } from 'src/app/@shared/@interfaces/unionTypes'
import { SideOptions } from 'src/app/@shared/@types/side.types'
import { UnionPositions } from 'src/app/@shared/@types/unionPositions.type'
import { Vector2 } from 'three'
import { UnionService } from '../union.service'
import { ProfileDrawerCreatorService } from './profile-drawer-creator.service'

@Injectable({
  providedIn: 'root',
})
export class SuperpositionDrawerService {
  private unionTypes: UnionTypes

  constructor(
    private profileDrawer: ProfileDrawerCreatorService,
    private unionService: UnionService
  ) {
    this.unionTypes = this.unionService.unionsIds
  }

  /**
   *
   * @param unions
   * @param profiles profile object with all values
   * @param superpositions
   * @param scaleFactor
   * @return offsets of superpositions
   */
  public applySuperposition(
    unions: { [k in UnionPositions]: number },
    profiles: { [k in SideOptions]: Konva.Line },
    superpositions: { [k in SideOptions]: number },
    scaleFactor: number
  ): { [k in SideOptions]: { x: number; y: number } } {
    const offsets = this.offsets(unions, superpositions, scaleFactor)

    const lengthdiffs = this.setLengthsDiff(unions, superpositions, scaleFactor)

    if (profiles.top) this.top(offsets.top, lengthdiffs.top, profiles.top)

    if (profiles.bottom)
      this.bottom(offsets.bottom, lengthdiffs.bottom, profiles.bottom)

    if (profiles.left) this.left(offsets.left, lengthdiffs.left, profiles.left)

    if (profiles.right)
      this.right(offsets.right, lengthdiffs.right, profiles.right)

    return offsets
  }

  // TOP
  private top(
    topOffset: { x: number; y: number },
    topLengthDiff: number,
    profile: Konva.Line
  ) {
    const {
      startExt,
      startInt,
      endExt,
      endInt,
    } = this.profileDrawer.getPointsAsVectors(profile.points())

    startExt.add(new Vector2(-topOffset.x, -topOffset.y))
    startInt.add(new Vector2(-topOffset.x, -topOffset.y))

    endExt.add(new Vector2(topLengthDiff, -topOffset.y))
    endInt.add(new Vector2(topLengthDiff, -topOffset.y))

    const newPoints = [
      startExt.toArray(),
      startInt.toArray(),
      endInt.toArray(),
      endExt.toArray(),
      startExt.toArray(),
    ].flat()

    profile.points(newPoints)
  }

  private bottom(
    bottomOffset: { x: number; y: number },
    bottomLengthDiff: number,
    profile: Konva.Line
  ) {
    const {
      startExt,
      startInt,
      endExt,
      endInt,
    } = this.profileDrawer.getPointsAsVectors(profile.points())

    startExt.add(new Vector2(-bottomOffset.x, bottomOffset.y))
    startInt.add(new Vector2(-bottomOffset.x, bottomOffset.y))

    endExt.add(new Vector2(bottomLengthDiff, bottomOffset.y))
    endInt.add(new Vector2(bottomLengthDiff, bottomOffset.y))

    const newPoints = [
      startExt.toArray(),
      startInt.toArray(),
      endInt.toArray(),
      endExt.toArray(),
      startExt.toArray(),
    ].flat()

    profile.points(newPoints)
  }

  private left(
    leftOffsets: { x: number; y: number },
    leftLengthDiff: number,
    profile: Konva.Line
  ) {
    const {
      startExt,
      startInt,
      endExt,
      endInt,
    } = this.profileDrawer.getPointsAsVectors(profile.points())

    startExt.add(new Vector2(-leftOffsets.x, -leftOffsets.y))
    startInt.add(new Vector2(-leftOffsets.x, -leftOffsets.y))

    endExt.add(new Vector2(-leftOffsets.x, leftLengthDiff))
    endInt.add(new Vector2(-leftOffsets.x, leftLengthDiff))

    const newPoints = [
      startExt.toArray(),
      startInt.toArray(),
      endInt.toArray(),
      endExt.toArray(),
      startExt.toArray(),
    ].flat()

    profile.points(newPoints)

    // profile.offsetY(leftOffsets.y)
    // profile.offsetX(leftOffsets.x)
  }

  private right(
    rightOffsets: { x: number; y: number },
    rightLengthDiff: number,
    profile: Konva.Line
  ) {
    const {
      startExt,
      startInt,
      endExt,
      endInt,
    } = this.profileDrawer.getPointsAsVectors(profile.points())

    startExt.add(new Vector2(rightOffsets.x, -rightOffsets.y))
    startInt.add(new Vector2(rightOffsets.x, -rightOffsets.y))

    endExt.add(new Vector2(rightOffsets.x, rightLengthDiff))
    endInt.add(new Vector2(rightOffsets.x, rightLengthDiff))

    const newPoints = [
      startExt.toArray(),
      startInt.toArray(),
      endInt.toArray(),
      endExt.toArray(),
      startExt.toArray(),
    ].flat()

    profile.points(newPoints)
  }

  // To Verticals

  // Set Offsets
  private offsets(
    unions: { [k in UnionPositions]: number },
    superpositions: { [k in SideOptions]: number },
    scaleFactor: number
  ): { [k in SideOptions]: { x: number; y: number } } {
    const scaledSuperpositions = {
      top: superpositions.top * scaleFactor,
      bottom: superpositions.bottom * scaleFactor,
      left: superpositions.left * scaleFactor,
      right: superpositions.right * scaleFactor,
    }

    let offsets = {
      top: {
        x: 0,
        y: 0,
      },
      bottom: {
        x: 0,
        y: 0,
      },
      left: {
        x: 0,
        y: 0,
      },
      right: {
        x: 0,
        y: 0,
      },
    }

    if (!unions) return offsets

    // left
    if (
      unions['left-top'] === this.unionTypes.cut45 ||
      unions['left-top'] === this.unionTypes.cut90VerticalwithHorizontal
    ) {
      offsets.left.y += scaledSuperpositions.top
    }
    if (
      unions['left-top'] === this.unionTypes.cut45 ||
      unions['left-bottom'] === this.unionTypes.cut45 ||
      unions['left-top'] === this.unionTypes.cut90Horizontal ||
      unions['left-bottom'] === this.unionTypes.cut90Horizontal ||
      unions['left-top'] === this.unionTypes.cut90HorizontalwithVertical ||
      unions['left-bottom'] === this.unionTypes.cut90HorizontalwithVertical
    ) {
      offsets.left.x += scaledSuperpositions.left
    }

    //right
    if (
      unions['right-top'] === this.unionTypes.cut45 ||
      unions['right-top'] === this.unionTypes.cut90VerticalwithHorizontal
    ) {
      offsets.right.y += scaledSuperpositions.top
    }
    if (
      unions['right-top'] === this.unionTypes.cut45 ||
      unions['right-bottom'] === this.unionTypes.cut45 ||
      unions['right-top'] === this.unionTypes.cut90Horizontal ||
      unions['right-bottom'] === this.unionTypes.cut90Horizontal ||
      unions['right-top'] === this.unionTypes.cut90HorizontalwithVertical ||
      unions['right-bottom'] === this.unionTypes.cut90HorizontalwithVertical
    ) {
      offsets.right.x += scaledSuperpositions.right
    }

    //TOP
    if (
      unions['left-top'] === this.unionTypes.cut45 ||
      unions['right-top'] === this.unionTypes.cut45 ||
      unions['left-top'] === this.unionTypes.cut90VerticalwithHorizontal ||
      unions['right-top'] === this.unionTypes.cut90VerticalwithHorizontal ||
      unions['left-top'] === this.unionTypes.cut90Vertical ||
      unions['right-top'] === this.unionTypes.cut90Vertical
    ) {
      offsets.top.y += scaledSuperpositions.top
    }
    if (
      unions['left-top'] === this.unionTypes.cut90HorizontalwithVertical ||
      unions['left-top'] === this.unionTypes.cut45
    ) {
      offsets.top.x += scaledSuperpositions.left
    }

    //BOTTOM
    if (
      unions['left-bottom'] === this.unionTypes.cut45 ||
      unions['right-bottom'] === this.unionTypes.cut45 ||
      unions['left-bottom'] === this.unionTypes.cut90VerticalwithHorizontal ||
      unions['right-bottom'] === this.unionTypes.cut90VerticalwithHorizontal ||
      unions['left-bottom'] === this.unionTypes.cut90Vertical ||
      unions['right-bottom'] === this.unionTypes.cut90Vertical
    ) {
      offsets.bottom.y += scaledSuperpositions.bottom
    }
    if (
      unions['left-bottom'] === this.unionTypes.cut90HorizontalwithVertical ||
      unions['left-bottom'] === this.unionTypes.cut45
    ) {
      offsets.bottom.x += scaledSuperpositions.left
    }

    return offsets
  }

  // Set lengthDiff
  private setLengthsDiff(
    unions: { [k in UnionPositions]: number },
    superpositions: { [k in SideOptions]: number },
    scaleFactor: number
  ): { [k in SideOptions]: number } {
    const scaledSuperpositions = {
      top: superpositions.top * scaleFactor,
      bottom: superpositions.bottom * scaleFactor,
      left: superpositions.left * scaleFactor,
      right: superpositions.right * scaleFactor,
    }

    let lengthDiff = {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    }

    // Top
    if (
      unions['right-top'] === this.unionTypes.cut90HorizontalwithVertical ||
      unions['right-top'] === this.unionTypes.cut45
    ) {
      lengthDiff.top += scaledSuperpositions.right
    }

    //Bottom
    if (
      unions['right-bottom'] === this.unionTypes.cut90HorizontalwithVertical ||
      unions['right-bottom'] === this.unionTypes.cut45
    ) {
      lengthDiff.bottom += scaledSuperpositions.right
    }

    // Left
    if (
      unions['left-bottom'] === this.unionTypes.cut45 ||
      unions['left-bottom'] === this.unionTypes.cut90VerticalwithHorizontal
    ) {
      lengthDiff.left += scaledSuperpositions.bottom
    }

    // right
    if (
      unions['right-bottom'] === this.unionTypes.cut45 ||
      unions['right-bottom'] === this.unionTypes.cut90VerticalwithHorizontal
    ) {
      lengthDiff.right += scaledSuperpositions.bottom
    }

    return lengthDiff
  }
}
