import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { BsModalRef } from 'ngx-bootstrap/modal'

@Component({
  selector: 'app-delete-item-dialog',
  templateUrl: './delete-item-dialog.component.html',
  styleUrls: ['./delete-item-dialog.component.scss'],
})
export class DeleteItemDialogComponent implements OnInit {
  // Inputs
  public itemName: string
  public headerContent: string
  public bodyContent: string

  // Outputs
  @Output()
  $response: EventEmitter<boolean> = new EventEmitter<boolean>()

  constructor(public modalRef: BsModalRef) {}

  ngOnInit(): void {}

  confirm(): void {
    this.$response.emit(true)
    this.modalRef.hide()
  }

  decline(): void {
    this.modalRef.hide()
  }
}
