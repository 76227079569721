import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import {
  faFilePdf,
  faSearch,
  faTrashAlt,
  faInfoCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { NgxSpinnerService } from 'ngx-spinner'
import { Subscription } from 'rxjs'
import { asyncMap } from 'src/app/@helper/asyncMap'
import { AuthStatusService } from 'src/app/@services/auth-status.service'
import {
  ElementResponse,
  GroupedElement,
  ModelService,
} from 'src/app/@services/model.service'
import { ListModel } from 'src/app/@shared/@models/list-model.model'
import { ModelQuery } from 'src/app/core/interfaces/ModelQuery.interface'
import { User } from 'src/app/core/models/User.model'
import { Nullable } from 'src/app/core/types/Nullable.type'
import { environment } from 'src/environments/environment'

const rightFadeOutAnimationClass = 'animate__fadeOutRight'

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit, OnDestroy {
  @ViewChild('lateralWindow', { static: true })
  lateralWindow: ElementRef<HTMLElement>

  // Icons
  public faSearch = faSearch
  public faFilePdf = faFilePdf
  public faTrashAlt = faTrashAlt
  public faInfoCircle = faInfoCircle
  public faTimes = faTimes

  public filteredModel: ListModel[] = []
  public models: ListModelWithImage[]
  public p = 0
  public url = `${environment.apiUrl}models/`

  private _listFilter = ''

  public modelBars: GroupedElement[] = []
  public selectedModel: ListModel | null
  public isLoadingModelBars = false

  private isLateralWindowVisible = false

  private user: Nullable<User>
  private userSubscription: Subscription

  public totalPages = 0
  public page = 1

  get filterBy(): string {
    return this._listFilter
  }
  set filterBy(value: string) {
    this._listFilter = value
    this.getModels()
  }

  constructor(
    private spinner: NgxSpinnerService,
    private modelService: ModelService,
    private authStatus: AuthStatusService,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.userSubscription = this.authStatus.user$.subscribe({
      next: (u) => {
        if (!u) return
        this.user = u
        this.getModels()
      },
    })
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe()
  }

  private getModels(): void {
    this.spinner.show()

    const query: ModelQuery = {
      name_contains: this._listFilter,
      ...(this.user?.isAdmin() ? {} : { user_equals: this.user.id }),
    }

    this.modelService
      .getListModels(query, this.page)
      .subscribe(async ({ models, totalPages }) => {
        this.totalPages = totalPages

        this.models = await asyncMap<any, ListModelWithImage>(
          models,
          async (model: any) => {
            const image = await this.getImage(model.id)
            return {
              ...model,
              ...(image ? { image } : { image: '' }),
            }
          }
        )

        this.filteredModel = this.models

        this.spinner.hide()
      })
  }

  public performFilter(filteredBy?: string): void {
    this.getModels()
  }

  public deleteModel(item: ListModel): void {
    const id = item.id

    this.modelService.deleteModel(id).subscribe(() => {
      this.models = this.models.filter((m) => {
        return m.id !== id
      })
      this.filteredModel = this.models
    })
  }

  private async getImage(modelId: number): Promise<SafeUrl> {
    const dataUrl = await this.modelService
      .getImageDataUrl(modelId)
      .catch(() => null)

    if (!dataUrl) return null
    return this.sanitizer.bypassSecurityTrustUrl(dataUrl)
  }

  public async seeModelBars(model: ListModel): Promise<void> {
    this.toogleLateralWindow()

    if (this.isLateralWindowVisible) {
      this.isLoadingModelBars = true
      this.selectedModel = model
      const response = await this.modelService
        .getModelBars(model.id)
        .catch(() => [])
      this.modelBars = response

      this.isLoadingModelBars = false
    }
  }

  private toogleLateralWindow(): void {
    if (this.isLateralWindowVisible) this.hideLateralWindow()
    else this.openLateralWindow()
  }

  private openLateralWindow(): void {
    this.renderer.setStyle(this.lateralWindow.nativeElement, 'display', 'flex')
    this.isLateralWindowVisible = true
  }

  public hideLateralWindow(): void {
    this.renderer.addClass(
      this.lateralWindow.nativeElement,
      rightFadeOutAnimationClass
    )
    const listener = this.renderer.listen(
      this.lateralWindow.nativeElement,
      'animationend',
      () => this.onHideAnimationEnd(listener)
    )

    this.isLateralWindowVisible = false
  }

  private onHideAnimationEnd(listener: () => void): void {
    this.renderer.setStyle(this.lateralWindow.nativeElement, 'display', 'none')

    this.renderer.removeClass(
      this.lateralWindow.nativeElement,
      rightFadeOutAnimationClass
    )

    listener()
  }

  public onPageChange(page: number): void {
    this.page = page + 1
    this.getModels()
  }
}

interface ListModelWithImage extends ListModel {
  image: SafeUrl
}
