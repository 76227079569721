import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core'
import { FormArray, FormBuilder, FormGroup } from '@angular/forms'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { ToastrService } from 'ngx-toastr'

import * as uuidV4 from 'uuid'

import { Serie } from 'src/app/@components/profile-management-editor/profile-management-editor.component'

import { SeriesService } from 'src/app/@services/series.service'
import { AccesoriesGruopsService } from 'src/app/@services/accesories-gruops.service'
import { AccessoriesModelService } from 'src/app/@services/accessories-model.service'

import { AccesorieGroupItem } from 'src/app/@shared/@interfaces/accesoryGroup'
import { Color } from 'src/app/@shared/@interfaces/color.model'
import { IDialog } from 'src/app/@shared/@interfaces/dialog'
import { ProfileService } from 'src/app/@services/profile.service'
import { accesoryTypeToDescription } from 'src/app/@helper/accesoryTipeToDescription'

@Component({
  selector: 'app-add-accessories-group-dialog',
  templateUrl: './add-accessories-group-dialog.component.html',
  styleUrls: ['./add-accessories-group-dialog.component.scss'],
})
export class AddAccessoriesGroupDialogComponent implements OnInit, IDialog {
  @Input() public accList: AccesorieGroupItem[] = []
  @Input() private modelId: number
  @Output()
  $response = new EventEmitter<AccesoryGroupResponse>()

  public faTimes = faTimes

  public accessoryGroupForm: FormGroup
  public formArra: FormArray

  public series: Serie[] = []
  public colors: Color[] = []
  public groups: any[] = []
  public filteredgroups: any[] = []
  public accesories: AccesorieGroupItem[] = []
  public filteredAccesories: AccesorieGroupItem[] = []

  constructor(
    private accessoriesModelService: AccessoriesModelService,
    private accesoriesGroupsService: AccesoriesGruopsService,
    private fb: FormBuilder,
    private modalRef: BsModalRef,
    private seriesService: SeriesService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.buildForm()
    this.getSeries()
    if (this.accList.length == 0) {
      this.getColors(null, null)
    }
  }

  private buildForm(): void {
    this.formArra = this.fb.array([])

    this.accessoryGroupForm = this.fb.group({
      serie: [1],
      group: [1],
    })
  }

  private addAccesoryGroupToForm(accessory: AccesorieGroupItem): void {
    let color = 0
    if (this.accList.length > 0) {
      color = accessory.color.id
    }

    this.formArra.push(
      this.fb.group({
        uuid: [uuidV4.v4()],
        serie: [accessory.accessorySerieGroup?.serie?.id ?? 1],
        group: [accessory.accessorySerieGroup?.id ?? 1],
        um: [accesoryTypeToDescription((accessory as any).type ?? 'P')],
        color: [color],
        colorName: [accessory?.color?.description ?? 'Color'],
        formula: accessory.formula,
        denomination: [accessory.accessory.denomination],
        codRef: [accessory.accessory.codRef],
        family: [accessory.accessory.family],
      })
    )
  }

  public getControlValue(group: FormGroup, control: string): any {
    return group.get(control).value
  }

  private getAccessoriesGroups(serie: number = 1) {
    this.accesoriesGroupsService
      .getAccesorieGroupsBySerie(serie)
      .subscribe((acc) => {
        this.groups = acc
        this.filteredgroups = this.groups
        if (this.accList.length > 0) {
          this.accessoryGroupForm
            .get('group')
            .setValue(this.accList[0].accessorySerieGroup.id)
          // this.getAccesories(this.accList[0].accessorySerieGroup.id)
          this.accList.forEach((a) => {
            this.addAccesoryGroupToForm(a)
          })
        } else {
          this.accessoryGroupForm.get('group').setValue(acc[0].id)
          this.getAccesories(acc[0].id)
        }
      })
  }

  public getColors(family: string = 'AS', codRef: string = 'AE90') {
    this.accessoriesModelService.getColorsAccessory(family, codRef).subscribe(
      async (color) => {
        this.colors = color
      },
      (err) => {}
    )
  }

  public getColor(group: FormGroup): string {
    if (!group) return 'Colors'

    const colorId = group.get('color').value

    const color =
      this.colors.find((value) => value.id === colorId)?.description ?? 'Color'

    return color
  }

  private getSeries(): void {
    this.seriesService.getSeries().subscribe((series: Serie[]) => {
      this.series = series
    })

    if (this.accList.length > 0) {
      this.accessoryGroupForm
        .get('serie')
        .setValue(this.accList[0].accessorySerieGroup.serie.id)
      this.getAccessoriesGroups(this.accList[0].accessorySerieGroup.serie.id)
    } else {
      this.getAccessoriesGroups()
    }
  }

  public getSerie(): string {
    const id = this.accessoryGroupForm.get('serie').value

    const serie = this.series.find((value) => value.id === id)?.name ?? 'serie'

    return serie
  }

  public getGroup(): string {
    const id = this.accessoryGroupForm.get('group').value

    const group = this.groups.find((value) => value.id === id)?.name ?? 'Grupos'

    return group
  }

  private getAccesories(group: number = 1): void {
    this.accesoriesGroupsService
      .getAccesoriesByGroup(group)
      .subscribe((accessories) => {
        this.formArra = this.fb.array([])
        accessories.forEach((a) => {
          this.addAccesoryGroupToForm(a)
        })
        this.accesories = accessories
        this.search()
      })
  }

  public selecPositionSeries(serie: Serie): void {
    this.accessoryGroupForm.get('serie').setValue(serie.id)
    this.getAccessoriesGroups(serie.id)
  }

  public selecPositionGroups(group: any): void {
    this.accessoryGroupForm.get('group').setValue(group.id)
    this.getAccesories(group.id)
  }

  public selecPositionColor(group: FormGroup, color: Color): void {
    group.get('color').setValue(color.id)
    group.get('colorName').setValue(color.description)
  }

  public search(criteria?: string): any {
    if (!criteria) return (this.filteredAccesories = this.accesories)
  }

  public onSubmit() {
    this.accessoriesModelService
      .deleteAccessoriesModelGroup(this.accessoryGroupForm.get('group').value)
      .subscribe(() => {
        this.emitResponse()
      })
  }

  private emitResponse() {
    const accessories: AccessoryResponse[] = this.formArra.value.map(
      (group: AccessoryResponse) => {
        const { codRef, family, formula, group: accessoryGroup } = group

        return {
          codRef,
          family,
          formula,
          group: accessoryGroup,
        }
      }
    )

    this.$response.emit({ accessories })
    this.decline()
  }

  public confirm(): void {
    this.$response.emit()
    this.modalRef.hide()
  }
  public decline(): void {
    this.modalRef.hide()
  }
}

interface AccesoryGroupResponse {
  accessories: AccessoryResponse[]
}

interface AccessoryResponse {
  family: string
  codRef: string
  formula: string
  group: number
}
