import { FlatTreeControl } from '@angular/cdk/tree'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { Accesory } from 'src/app/@shared/@interfaces/accesory'

export interface AccesoryNode {
  accesory: Accesory
  children?: AccesoryNode[]
}

interface FlatNode {
  expandable: boolean
  name: string
  level: number
}

@Component({
  selector: 'app-accesories-tree',
  templateUrl: './accesories-tree.component.html',
  styleUrls: ['./accesories-tree.component.scss'],
})
export class AccesoriesTreeComponent implements OnInit {
  @Input() public treeNode: AccesoryNode[] = []
  @Input() public selectedAccesory: Accesory

  @Output() public selectAccesoryEmitter = new EventEmitter<Accesory>()
  @Output() public searchEmitter = new EventEmitter<string>()

  public treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable
  )

  constructor(private router: Router) {}

  ngOnInit(): void {}

  public onSelectAccesory(accesory: Accesory): void {
    this.selectAccesoryEmitter.emit(accesory)
  }

  public back(): void {
    this.router.navigateByUrl('/files')
  }

  public onSearch(criteria: string): void {
    this.searchEmitter.emit(criteria)
  }
}
