import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'

import { environment } from 'src/environments/environment'
import { AssociatedFrameProfileCreator } from '../@shared/@interfaces/associatedFrameProfile'

@Injectable({
  providedIn: 'root',
})
export class AssociatedFrameProfilesService {
  private apiUrl = environment.apiUrl + 'profiles-frames'

  constructor(private http: HttpClient) {}

  public createAssociatedFrameProfile(
    profile: AssociatedFrameProfileCreator
  ): Observable<any> {
    return this.http.post(`${this.apiUrl}`, profile)
  }

  public deleteAssociatedFrameProfile(id: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/${id}`)
  }
}
