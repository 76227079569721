import * as three from 'three'

import * as uuid from 'uuid'

import { DXFAction } from './DXFAction'
import { DXFReflexAction } from './DXFReflexAction'
import { DXFRotateAction } from './DXFRotateAction'
import { DXFTranslateAction } from './DXFTranslateAction'
import { Vector3 } from 'three'
import { DxfContent, Entity } from 'src/app/@shared/@interfaces/dxf-content'
import { ProfileCreator } from 'src/app/@shared/@interfaces/profileCreator.model'

export class DXF {
  public readonly uuid: string
  public readonly profile: ProfileCreator
  private parsed: DxfContent

  private group: THREE.Object3D | null = null
  private actions: DXFAction[] = []
  public offset?: number[];

  constructor(params: DXFParams) {
    this.uuid = params.uuid
    this.profile = params.profile
    this.parsed = params.parsed
    this.offset = params.offset
  }

  public static combineArray(dxfArray: DXF[]): DXF {
    const combined: DxfContent = {
      blocks: [],
      header: {},
      tables: [],
      entities: [],
    }
    dxfArray.forEach((dxf) => {
      dxf.applyActions()

      combined.entities = [...combined.entities, ...dxf.parsed.entities]
    })
    return new DXF({
      uuid: uuid.v4(),
      parsed: combined,
    })
  }

  public addAction(action: DXFAction): void {
    this.actions.push(action)
  }

  public setGroup(group: THREE.Object3D): void {
    this.group = group
  }

  public getGroup(): THREE.Object3D | null {
    return this.group
  }

  public duplicate(): DXF {
    if (!this.group)
      return new DXF({
        uuid: uuid.v4(),
        profile: this.profile,
        parsed: this.parsed,
      })

    const newDxf = new DXF({
      uuid: uuid.v4(),
      profile: this.profile,
      parsed: this.parsed,
    })
    newDxf.setGroup(this.group.clone())
    return newDxf
  }

  public json(): DxfContent {
    return this.parsed
  }

  public getEntities(): Entity[] {
    return this.parsed.entities
  }

  public getVertexs(): Vector3[] {
    const vertexs: Vector3[] = []

    this.parsed.entities.forEach((entity) => {
      entity.vertices?.forEach((vertex) => {
        vertexs.push(new Vector3(vertex.x, vertex.y, 0))
      })

      entity.center &&
        vertexs.push(new Vector3(entity.center.x, entity.center.y, 0))
    })

    return vertexs
  }

  public combineDxf(dxf: DXF): DXF {
    const combined: DxfContent = {
      entities: [...this.parsed.entities, ...dxf.parsed.entities],
    }
    return new DXF({
      uuid: uuid.v4(),
      profile: this.profile,
      parsed: combined,
    })
  }

  private applyActions(): void {
    // apply actions over entities
    let entities = this.parsed.entities

    entities.forEach((entity) => {
      const vertexs = entity.vertices
      const center = entity.center

      this.actions.forEach((action) => {
        if (action instanceof DXFTranslateAction) {
          const dx = action.dx
          const dy = action.dy

          vertexs?.forEach((vertex) => {
            vertex.x += dx
            vertex.y += dy
          })

          if (!!center) {
            center.x += dx
            center.y += dy
          }
        }
      })

      entity.vertices = vertexs
      entity.center = center
    })

    this.actions.forEach((action) => {
      if (action instanceof DXFRotateAction) {
        const newEntities = action.apply(entities)
        entities = newEntities
      }

      if (action instanceof DXFReflexAction) {
        const newEntities = action.apply(entities)
        entities = newEntities
      }
    })

    this.parsed.entities = entities

    this.actions = []
  }

  public changeColor(color: any): void {
    this.group?.traverse((child: any) => {
      if (child.material) {
        child.material.color.setHex(color)
      }
    })
  }

  public rotate(angle: number): void {
    if (!this.group) return
    const group = this.group

    const bbox = new three.Box3().setFromObject(group)
    const Xmax = bbox.max.x
    const Xmin = bbox.min.x
    const Ymax = bbox.max.y
    const Ymin = bbox.min.y

    const center = new three.Vector3(
      Xmin + (Xmax - Xmin) / 2,
      Ymin + (Ymax - Ymin) / 2,
      0
    )

    this.group.rotateZ(angle)

    this.addAction(new DXFRotateAction(angle))
  }

  /**
   * Reflects the DXF around the Y axis
   */
  public reflect(): three.Vector3 {
    // get width and height of the group
    if (!this.group) return new Vector3(0, 0, 0)
    const boundingBox = new three.Box3().setFromObject(this.group)
    const width = boundingBox.max.x - boundingBox.min.x
    const height = boundingBox.max.y - boundingBox.min.y

    // get left position from children
    const left = boundingBox.min.x
    const right = boundingBox.max.x
    const top = boundingBox.max.y
    const bottom = boundingBox.min.y

    const leftBottomCorner = new Vector3(left, bottom, 0)
    const rightTopCorner = new Vector3(right, top, 0)

    const center = new Vector3(
      rightTopCorner.x - width / 2,
      rightTopCorner.y - height / 2
    )

    this.group.rotateOnWorldAxis(new Vector3(0, 1, 0), Math.PI)

    this.addAction(new DXFReflexAction())

    return leftBottomCorner
  }
}

export interface DXFParams {
  parsed: DxfContent
  uuid: string
  profile?: ProfileCreator
  offset?: number[]
}
