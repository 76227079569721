import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-opening-projecting',
  templateUrl: './opening-projecting.component.html',
  styleUrls: ['./opening-projecting.component.scss'],
})
export class OpeningProjectingComponent implements OnInit {
  @Input() public images = []

  constructor() {}

  ngOnInit(): void {}
}
