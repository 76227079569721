<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="square-jelly-box"
  [fullScreen]="true"
>
  <p style="color: white">Cargando...</p>
</ngx-spinner>

<div class="tree">
  <app-formulas-tree
    [treeNode]="treeNode"
    [selectedFormula]="selectedFormula"
    (selectFormulaEmitter)="onSelectFormula($event)"
    (searchEmitter)="onSearch($event)"
  ></app-formulas-tree>
</div>

<div class="content" *ngIf="selectedFormula">
  <h1 class="title">Datos Técnicos</h1>

  <div class="formula-information">
    <div class="formula-information__item">
      <app-input label="Función">
        <p class="item__value">{{ selectedFormula.template }}</p>
      </app-input>
    </div>

    <div class="formula-information">
      <div class="formula-information__item">
        <app-input label="Original">
          <p class="item__value">{{ selectedFormula.original }}</p>
        </app-input>
      </div>

      <div class="formula-information__item">
        <app-input label="Descripción">
          <p class="item__value">{{ selectedFormula.description }}</p>
        </app-input>
      </div>
    </div>
  </div>
</div>
