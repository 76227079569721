<div class="modal-header">
  <h5 class="modal-title pull-left">Corredera</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <img src="../../../assets/img/icons/close.svg" alt="Close modal icon" />
  </button>
</div>
<div class="modal-body">

<!-- menu  -->

  <tabset>
    <tab>
      <ng-template tabHeading>Datos Hojas</ng-template>
      <div class="content">
      <div [ngTemplateOutlet]="content1"></div>
      </div>
    </tab>

    <tab>
      <ng-template tabHeading>Construcción avanzada</ng-template>
      <div class="content">
        <div [ngTemplateOutlet]="content2"></div>
      </div>
    </tab>


    <tab>
      <ng-template tabHeading>Datos tirador</ng-template>
      <div class="content">
        <div [ngTemplateOutlet]="content3"></div>
      </div>
    </tab>


  </tabset>

<!-- menu  -->

<!-- primera pantalla -->

  <ng-template #content1>

    <div class="first-column">
    <div class="box">
      <input  class="checkbox-size" type="checkbox" id="cbox1" value="first_checkbox">
      <label for="huey">Lado superior</label>
      <app-profile-select></app-profile-select>
      <input class="checkbox-size" type="checkbox" id="cbox1" value="first_checkbox">
      <label for="huey">Perfiles asociados hojas</label>
      <input class="box_space" name="alturamanilla" type="text" />
      <input class="checkbox-size" type="checkbox" id="cbox1" value="first_checkbox">
      <label for="huey">Hojas con tirador</label>
      <input class="box_space" name="alturamanilla" type="text" />
      <input class="checkbox-size" type="checkbox" id="cbox1" value="first_checkbox">
      <label for="huey">Hojas con enganche</label>
      <input class="box_space" name="alturamanilla" type="text" />
      <input class="checkbox-size" type="checkbox" id="cbox1" value="first_checkbox">
      <label for="huey">Perfil inferior sup.</label>
      <input class="box_space" name="alturamanilla" type="text" />
   </div>

    <div class="box-2">
      <input  class="checkbox-size" type="checkbox" id="cbox1" value="first_checkbox">
      <label for="huey">Lado superior</label>
      <app-profile-select></app-profile-select>
      <input class="checkbox-size" type="checkbox" id="cbox1" value="first_checkbox">
      <label for="huey">Perfiles asociados hojas</label>
      <input class="box_space" name="alturamanilla" type="text" />
      <input class="checkbox-size" type="checkbox" id="cbox1" value="first_checkbox">
      <label for="huey">Hojas con tirador</label>
      <input class="box_space" name="alturamanilla" type="text" />
    </div>
    <app-input label="Hoja cerradura" id="long">
      <input
        type="number"
        step="any"
        min="0"
        class="form-item__input"
        id="long"
      />
    </app-input> 
    </div>


     <div class="second-column">
      <div class="box-3">
        <div>
        <label for="huey">N.guías</label>
        <input class="box_space1" name="alturamanilla" type="text" /></div>
        <div>
        <label for="huey">N.hojas</label>
        <input class="box_space1" name="alturamanilla" type="text" /></div>
       </div>
       <div class="box-4">
        <img src="../../../assets/img/barras.svg" alt="Close modal icon" />
       </div>
       <div class="box-5">
        <input  class="checkbox-size" type="checkbox" id="cbox1" value="first_checkbox">
        <label class="size" for="huey">Puntos de interrupción (50SS)</label>
       </div>
       <div class="box-6">
        <label class="size1" for="huey">Var. superposición nodo central</label>
        <input class="box_space2" name="alturamanilla" type="text" />
        <label class="size1" for="huey">Offc: Abertura Hojas adyacentes</label>
        <input class="box_space2" name="alturamanilla" type="text" />
       </div>
       <div class="box-7">
        <input type="radio" id="huey" name="drone" value="huey" unchecked />
        <label for="huey">Diferentes</label>
        <input  type="radio" id="huey" name="drone" value="huey" unchecked />
        <label for="huey">Iguales</label>
        <input  type="radio" id="huey" name="drone" value="huey" unchecked />
        <label for="huey">Sup/Int Iguales</label>
       </div>

       <div class="box-8">

        <div class="box-8_content">
         
          <div class="grid-item"><label class="size" for="huey">1</label></div>
          <div class="grid-item"><label class="size" for="huey">996,15</label></div>
            <div class="grid-item"><input  class="checkbox-size" type="checkbox" id="cbox1" value="first_checkbox"></div>
              <div class="grid-item"><label class="size" for="huey">0</label></div>
       </div>

      </div>

       </div> 

       <div class="third-column">
         <div class="uniones">
          <h5>Uniones</h5>
         <div class="box-9">
          <input type="radio" id="huey" name="drone" value="huey" unchecked />
          <img src="../../../assets/img/union1.svg" alt="Close modal icon" />
          <input type="radio" id="huey" name="drone" value="huey" unchecked />
          <img src="../../../assets/img/union2.svg" alt="Close modal icon" />
          <input type="radio" id="huey" name="drone" value="huey" unchecked />
          <img src="../../../assets/img/union3.svg" alt="Close modal icon" />
          <input type="radio" id="huey" name="drone" value="huey" unchecked />
          <img src="../../../assets/img/union4.svg" alt="Close modal icon" />
          <input type="radio" id="huey" name="drone" value="huey" unchecked />
          <img src="../../../assets/img/union1.svg" alt="Close modal icon" />
          <input type="radio" id="huey" name="drone" value="huey" unchecked />
          <img src="../../../assets/img/union2.svg" alt="Close modal icon" />
          <input type="radio" id="huey" name="drone" value="huey" unchecked />
          <img src="../../../assets/img/union3.svg" alt="Close modal icon" />
          <input type="radio" id="huey" name="drone" value="huey" unchecked />
          <img src="../../../assets/img/union4.svg" alt="Close modal icon" />

         </div>
        </div>
       </div>



  </ng-template>

<!-- primera pantalla -->

<!-- segunda pantalla -->

<ng-template #content2>
  <div class="flex">
<div class="first">

  <div class="lado-superior">
  <div>
    <label for="huey">Lado superior</label>
    <br />
    <app-overlaps></app-overlaps>
  </div>
  <div class="space">
  <app-profile-select></app-profile-select></div>
</div>

<div class="lado-izquierdo">
  <div>
    <label for="huey">Lado izquierdo</label>
    <br />
    <app-overlaps></app-overlaps>
  </div>
  <div class="space">
  <app-profile-select></app-profile-select></div>
</div>

<div class="square"></div>

<div class="lado-derecho">
  <div>
    <label for="huey">Lado derecho</label>
    <br />
    <app-overlaps></app-overlaps>
  </div>
  <div class="space">
  <app-profile-select></app-profile-select></div>
</div>

<div class="lado-inferior">
  <div>
    <label for="huey">Lado inferior</label>
    <br />
    <app-overlaps></app-overlaps>
  </div>
  <div class="space">
  <app-profile-select></app-profile-select></div>
</div>
</div>
<div class="second">
  <div class="box-10">
    <input type="radio" id="huey" name="drone" value="huey" unchecked />
    <label for="huey">Estándar</label>
    <input  type="radio" id="huey" name="drone" value="huey" unchecked />
    <label for="huey">Hoja de elevación</label>
    <input  type="radio" id="huey" name="drone" value="huey" unchecked />
    <label for="huey">Hoja fija</label>
   </div>

   <div class="box-11">
    <img src="../../../assets/img/barras.svg" alt="Close modal icon" />
    <input class="box_space3" name="alturamanilla" type="text" />
   </div>

</div>

</div>


</ng-template>

<!-- segunda pantalla -->

<!-- tercera pantalla -->

<ng-template #content3>

<div class="tirador">
  <h5>Tirador</h5>
  
  <div class="container">

  <div class="box-12">

    <div class="box-12_first">
      <input  class="checkbox-size" type="checkbox" id="cbox1" value="first_checkbox">
      <label class="size" for="huey">Cerradura</label>
    </div>

     <div class="box-12_second">
    <img src="../../../assets/img/group164.svg" alt="Close modal icon" />
    <input class="boxspace4" name="alturamanilla" type="text" />  
     </div>
     
   </div>


     <div class="referencia">
       <h5>Referencia</h5>
     <div class="referencia_box">
    <input type="radio" id="huey" name="drone" value="huey" unchecked />
    <label for="huey">Del piso</label>
    <input  type="radio" id="huey" name="drone" value="huey" unchecked />
    <label for="huey">De área</label>
    <input  type="radio" id="huey" name="drone" value="huey" unchecked />
    <label for="huey">De hoja - Aleta externa</label>
    <input  type="radio" id="huey" name="drone" value="huey" unchecked />
    <label for="huey">De hoja - Cámara</label>

   </div>
   </div>
  
</div>
  
</div>





</ng-template>

<!-- tercera pantalla -->




  <!-- Button -->
  <div class="modal-footer justify-content-center">
    <button class="btn btn-primary" (click)="save()">Aceptar</button>
  </div>