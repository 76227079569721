import { Component, Input, OnInit } from '@angular/core'

// Icons
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-input-alert',
  templateUrl: './input-alert.component.html',
  styleUrls: ['./input-alert.component.scss'],
})
export class InputAlertComponent implements OnInit {
  @Input() public title: string

  public faExclamationCircle = faExclamationCircle

  constructor() {}

  ngOnInit(): void {}
}
