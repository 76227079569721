import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { ToastrService } from 'ngx-toastr'
import { fromEvent, Subscription } from 'rxjs'
import { debounceTime, map } from 'rxjs/operators'
import { FrameProfilesDialogComponent } from 'src/app/@dialogs/frame-profiles-dialog/frame-profiles-dialog.component'
import { ModalService } from 'src/app/@services/modal.service'
import { AssociatedProfileFrameDrawerService } from 'src/app/@services/modelDrawServices/associated-profile-frame-drawer.service'
import { CovergapDrawService } from 'src/app/@services/modelDrawServices/covergap-draw.service'
import { FrameDrawService } from 'src/app/@services/modelDrawServices/frame-draw.service'
import { CoverGaps } from 'src/app/@shared/@interfaces/coverGaps'
import { FrameCreatorDraw } from 'src/app/@shared/@interfaces/frameCreatorDraw'
import { DialogInstanceModel } from 'src/app/@shared/@models/dialog-instance-model'
import {
  Area,
  AreaModel,
} from 'src/app/@shared/@models/modelsElements/area-model'
import { Frame } from 'src/app/@shared/@models/modelsElements/frame-model'
import { Gap } from 'src/app/@shared/@models/modelsElements/gap-model'
import { EntityInformationService } from 'src/app/@shared/entity-information/entity-information.service'
import { FrameInfoComponent } from '../../entity-info/frame-info/frame-info.component'
import { IconSidebarItem } from '../icon-sidebar-item/icon-sidebar.interface'

@Component({
  selector: 'app-add-frame-icon-item',
  templateUrl: './add-frame-icon-item.component.html',
  styleUrls: ['./add-frame-icon-item.component.scss'],
})
export class AddFrameIconItemComponent implements OnInit, IconSidebarItem {
  @Input() public gap: Gap
  @Input() public modelObject

  @Output() public deleteFrameEmitter = new EventEmitter<Frame>()
  @Output() public createFrameEmitter = new EventEmitter<Frame>()

  public canUpdate = false

  public gapSubscription: Subscription

  constructor(
    private toastr: ToastrService,
    private modalService: ModalService,
    private frameDrawService: FrameDrawService,
    private covergapsDrawerService: CovergapDrawService,
    private associatedProfileFrameDrawer: AssociatedProfileFrameDrawerService,
    private entityInfoService: EntityInformationService
  ) {}

  ngOnDestroy(): void {
    this.gapSubscription?.unsubscribe()
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.gap) this.gapSubscribe()
  }

  ngOnInit(): void {}

  public gapSubscribe(): void {
    if (this.gapSubscription) return

    this.gapSubscription = this.gap.gapUpdated$.subscribe(() => {
      this.canUpdate = this.canActivateBotton()
    })
  }

  public canActivateBotton(): boolean {
    const hasSelected = this.gap.getSelectedElement<Frame>(Frame) ? true : false
    const hasSelectedArea = this.gap.selectedArea ? true : false
    const hasSelectedAreaOpening = this.gap.selectedArea?.doorWindowOpenings
      ? true
      : false

    return hasSelected || (hasSelectedArea && !hasSelectedAreaOpening)
  }

  // Set Frames
  public setFrameToSelectedArea(): void {
    let area1 = this.gap?.selectedArea
    const gap = this.gap
    const frame = gap?.getSelectedElement<Frame>(Frame)

    if (!area1 && !frame) {
      this.toastr.warning('Seleccione un área o un marco')
      return
    }

    let frameProfiles = null
    let covergaps: CoverGaps = undefined

    if (frame) {
      const covergapsClip = {
        top: frame.covergaps?.external?.clips?.top ?? 0,
        bottom: frame.covergaps?.external?.clips?.bottom ?? 0,
        left: frame.covergaps?.external?.clips?.left ?? 0,
        right: frame.covergaps?.external?.clips?.right ?? 0,
      }

      const covergapsCutVariations = {
        top: frame.covergaps?.external?.cutVariations?.top,
        bottom: frame.covergaps?.external?.cutVariations?.bottom,
        left: frame.covergaps?.external?.cutVariations?.left,
        right: frame.covergaps?.external?.cutVariations?.right,
      }

      const covergapsOverlap = {
        top: frame.covergaps?.external?.overlaps?.top,
        bottom: frame.covergaps?.external?.overlaps?.bottom,
        left: frame.covergaps?.external?.overlaps?.left,
        right: frame.covergaps?.external?.overlaps?.right,
      }

      const profileCreator = {
        external: {
          top: frame.covergaps?.external?.sidesCovergaps?.top?.profile,
          bottom: frame.covergaps?.external?.sidesCovergaps?.bottom?.profile,
          left: frame.covergaps?.external?.sidesCovergaps?.left?.profile,
          right: frame.covergaps?.external?.sidesCovergaps?.right?.profile,
        },
        internal: {
          top: frame.covergaps?.internal?.sidesCovergaps?.top?.profile,
          bottom: frame.covergaps?.internal?.sidesCovergaps?.bottom?.profile,
          left: frame.covergaps?.internal?.sidesCovergaps?.left?.profile,
          right: frame.covergaps?.internal?.sidesCovergaps?.right?.profile,
        },
      }

      frameProfiles = {
        left: {
          id: frame.frameSideLeft?.profileID,
        },
        right: {
          id: frame.frameSideRight?.profileID,
        },
        top: {
          id: frame.frameSideTop?.profileID,
        },
        bottom: {
          id: frame.frameSideBottom?.profileID,
        },
      }

      covergaps = {
        top: {
          clip: covergapsClip.top,
          cutVariation: covergapsCutVariations.top,
          hasExternal:
            frame.covergaps?.external?.covergapsSideArray?.length > 0
              ? true
              : false,
          hasInternal:
            frame.covergaps?.internal?.covergapsSideArray?.length > 0
              ? true
              : false,
          overlap: covergapsOverlap.top,
          side: 'top',
          external: profileCreator.external.top,
          internal: profileCreator.internal.top,
        },
        bottom: {
          clip: covergapsClip.bottom,
          cutVariation: covergapsCutVariations.bottom,
          hasExternal:
            frame.covergaps?.external?.covergapsSideArray.length > 0
              ? true
              : false,
          hasInternal:
            frame.covergaps?.internal?.covergapsSideArray.length > 0
              ? true
              : false,
          overlap: covergapsOverlap.bottom,
          side: 'bottom',
          external: profileCreator.external.bottom,
          internal: profileCreator.internal.bottom,
        },
        left: {
          clip: covergapsClip.left,
          cutVariation: covergapsCutVariations.left,
          hasExternal:
            frame.covergaps?.external?.covergapsSideArray.length > 0
              ? true
              : false,
          hasInternal:
            frame.covergaps?.internal?.covergapsSideArray.length > 0
              ? true
              : false,
          overlap: covergapsOverlap.left,
          side: 'left',
          external: profileCreator.external.left,
          internal: profileCreator.internal.left,
        },
        right: {
          clip: covergapsClip.right,
          cutVariation: covergapsCutVariations.right,
          hasExternal:
            frame.covergaps?.external?.covergapsSideArray.length > 0
              ? true
              : false,
          hasInternal:
            frame.covergaps?.internal?.covergapsSideArray.length > 0
              ? true
              : false,
          overlap: covergapsOverlap.right,
          side: 'right',
          external: profileCreator.external.right,
          internal: profileCreator.internal.right,
        },
        unions: frame.covergaps?.external?.unions,
      }
    }

    const frameDialog = new DialogInstanceModel(FrameProfilesDialogComponent, {
      initialState: {
        serieId: this.modelObject.serie.id,
        serieOpeningId: this.modelObject.serie?.opening?.id,
        framesProfiles: frameProfiles,
        union: frame ? frame.unionId : null,
        frameUnions: frame ? frame.unions : null,
        overlaps: frame?.overlaps,
        coverGaps: covergaps,
        associatedProfiles: frame?.associatedProfilesAsObject() ?? {
          top: [],
          bottom: [],
          left: [],
          right: [],
        },
        ...(frame?.cutVariations
          ? { cutVariations: frame?.cutVariations }
          : {}),
      },
      class: 'modal-dialog-centered modal-lg modal-frames',
    })

    this.modalService
      .openModal(frameDialog)
      .subscribe((framesData: FrameCreatorDraw) => {
        const area = area1 ?? frame?.associatedAreas[0]

        if (!framesData) return this.removeFrame(frame, area, gap.scaleWidth)
        this.newFrame(frame, area, gap, framesData)
      })
  }

  private newFrame(
    frame: Frame,
    area: AreaModel,
    gap: Gap,
    framesData: FrameCreatorDraw
  ) {
    const scaleFactor = gap.scaleWidth

    if (frame) {
      this.removeFrame(frame, area, scaleFactor)
    }

    const {
      frame: frameCreated,
      superpositionOffsets,
    } = this.frameDrawService.createFrame(framesData, area, {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    })

    const parentContainer = area.container
    frameCreated.container = parentContainer
    parentContainer.addElements([
      frameCreated.frameSideTop,
      frameCreated.frameSideBottom,
      frameCreated.frameSideLeft,
      frameCreated.frameSideRight,
    ])

    const newArea = new Area({
      parent: area.container,
      width: area.width,
      height: area.height,
      x: area.x,
      y: area.y,
      modelId: parentContainer.modelId,
    })

    area.addFrame(frameCreated)
    area.container.setVerticalContain([newArea])
    area.container = newArea
    newArea.setVinculatedArea(area)
    parentContainer.setVinculatedArea(null)

    let newAreaWidth =
      area.width -
      (frameCreated?.frameSideLeft?.thickness ?? 0) -
      (frameCreated?.frameSideRight?.thickness ?? 0)
    let newAreaHeight =
      area.height -
      (frameCreated?.frameSideTop?.thickness ?? 0) -
      (frameCreated?.frameSideBottom?.thickness ?? 0)

    newAreaHeight +=
      (superpositionOffsets.top.y + superpositionOffsets.bottom.y) / scaleFactor

    newAreaWidth +=
      (superpositionOffsets.left.x + superpositionOffsets.right.x) / scaleFactor

    area.resize(newAreaWidth, newAreaHeight)
    newArea.updateWidth(newAreaWidth)
    newArea.updateHeight(newAreaHeight)

    area.move(
      area.x +
        (frameCreated?.frameSideLeft?.thickness ?? 0) -
        superpositionOffsets.left.x / scaleFactor,
      area.y +
        (frameCreated.frameSideTop?.thickness ?? 0) -
        superpositionOffsets.top.y / scaleFactor
    )

    area.container.updatePosition(area.x, area.y)
    this.gap.notifyAreasUpdate()

    if (framesData.coverGaps?.unions) {
      const auxFrameData = { ...framesData }

      const covergaps = this.covergapsDrawerService.createCovergaps(
        frameCreated,
        auxFrameData.coverGaps
      )

      frameCreated.addExternalCovergap(covergaps.external)
      frameCreated.addInternalCovergap(covergaps.internal)
    }

    this.associatedProfileFrameDrawer.createAssociatedProfiles(
      framesData.associatedProfiles,
      frameCreated
    )

    parentContainer.addElements([
      ...frameCreated.associatedProfiles?.top,
      ...frameCreated.associatedProfiles?.bottom,
      ...frameCreated.associatedProfiles?.left,
      ...frameCreated.associatedProfiles?.right,
    ])

    parentContainer.addElements([
      ...(frameCreated.covergaps?.external?.covergapsSideArray ?? []),
      ...(frameCreated.covergaps?.internal?.covergapsSideArray ?? []),
    ])

    frameCreated?.setZindex()

    area.setZIndexTop()
    area.layer.draw()

    this.gap.subscribeToFrames()
    frameCreated.select()

    this.createFrameEmitter.emit(frameCreated)

    const subs = fromEvent(frameCreated.group, 'contextmenu')
      .pipe(
        map((e) => {
          e.preventDefault()
          e.stopPropagation()
          return e
        }),
        debounceTime(200)
      )
      .subscribe((e: MouseEvent) => {
        this.entityInfoService.hide()
        this.entityInfoService.showCard(FrameInfoComponent, e, {
          frame: frameCreated,
        })
      })

    frameCreated.addSubscriptions([subs])
  }

  private removeFrame(
    frame: Frame,
    area: AreaModel,
    scaleFactor: number
  ): void {
    const topFrame = frame?.frameSideTop
    const bottomFrame = frame?.frameSideBottom
    const leftFrame = frame?.frameSideLeft
    const rightFrame = frame?.frameSideRight

    const topFrameThickness = topFrame?.thickness ?? 0
    const bottomFrameThickness = bottomFrame?.thickness ?? 0
    const leftFrameThickness = leftFrame?.thickness ?? 0
    const rightFrameThickness = rightFrame?.thickness ?? 0

    const oldOverlaps = frame.overlapsApplied

    // TODO: check unions to resize and move
    area.resize(
      area.width +
        leftFrameThickness +
        rightFrameThickness -
        (oldOverlaps?.left + oldOverlaps?.right) / scaleFactor,
      area.height +
        topFrameThickness +
        bottomFrameThickness -
        (oldOverlaps?.top + oldOverlaps?.bottom) / scaleFactor
    )
    area.move(
      area.x - leftFrameThickness + oldOverlaps?.left / scaleFactor,
      area.y - topFrameThickness + oldOverlaps?.top / scaleFactor
    )

    const container = area.container
    container.parent?.removeElements()

    area.container = container.parent
    area.container.setVinculatedArea(area)
    container.destroy()

    area.removeFrame(frame.uuid)
    this.deleteFrameEmitter.emit(frame)
    this.gap.layer.draw()
  }
}
