// ANGULAR
import { Injectable } from '@angular/core'
// TOOLS
import DxfParser from 'dxf-parser'
import { Viewer } from 'src/app/@shared/three-dxf'

import { fromEvent, Observable, Subject, Subscription } from 'rxjs'
import { distinctUntilChanged, throttleTime } from 'rxjs/operators'
import { Vector2, Vector3 } from 'three'
import { ToastrService } from 'ngx-toastr'
import { dataUrlToBlob } from 'src/app/@helper/dataUrlToBlob'
// INTERFACES
import { Size } from 'src/app/@shared/@interfaces/size'
import { Vertex } from 'src/app/@shared/@interfaces/vertex'
import { DxfContent, Entity } from 'src/app/@shared/@interfaces/dxf-content'
import { Viewport } from 'src/app/@shared/@interfaces/viewport'
import { Coordinates } from '../@shared/@interfaces/coordinates'
// SERVICES
import { DxfTransformationService } from './dxf-transformation.service'
import { DxfDimensions } from './cotas-service.service'
import { degreeToRadians } from '../@helper/degreeToRadians'

const THROTTLE_MS = 100

@Injectable({
  providedIn: 'root',
})
export class DxfEditorService {
  private fileReader: FileReader
  private dxfParser: any
  // Size of the dxf canvas
  public canvasSize: Size
  // Outout of the dxf parser
  private dxfParserOutput: DxfContent
  private dxfPngBlob: Blob
  // Array of dxf vertex
  private dxfVertex: Vertex[]
  private dxfVertexSubject: Subject<Vertex[]>
  public dxfVertex$: Observable<Vertex[]>

  private dxfViewerInstance: any
  private viewport: Viewport
  private moveEventSubscription: Subscription
  private closestVertice = new Subject<Vertex>()

  public dxfAbsoluteDimensions: DxfDimensions
  public dxfViewerElement: HTMLDivElement

  public dxfCenter: Vector2

  private isDxfFromDBSubject: Subject<boolean>
  public isDxfFromDB$: Observable<boolean>
  public isDxfFromDB: boolean

  // Filters only the vertex that has changed
  public readonly closestVertice$ = this.closestVertice
    .asObservable()
    .pipe(
      distinctUntilChanged(
        (a, b) => a?.dxf?.x === b?.dxf?.x && a?.dxf?.y === b?.dxf?.y
      )
    )

  constructor(
    private dxfTransformationService: DxfTransformationService,
    private toastr: ToastrService
  ) {
    this.dxfVertexSubject = new Subject<Vertex[]>()
    this.dxfVertex$ = this.dxfVertexSubject.asObservable()

    this.isDxfFromDBSubject = new Subject<boolean>()
    this.isDxfFromDB$ = this.isDxfFromDBSubject.asObservable()

    this.fileReader = new FileReader()
    this.dxfParser = new DxfParser()
    this.canvasSize = {
      width: 350,
      height: 350,
    }
  }

  public onDestroy(): void {
    this.moveEventSubscription?.unsubscribe()
  }

  public get getCanvasSize(): Size {
    return this.canvasSize
  }

  public get getDxfParserOutput() {
    return this.dxfParserOutput
  }

  public get getDxfPng() {
    return this.dxfPngBlob
  }

  public renderDxf(file: any, dxfViewerElement: HTMLDivElement): void {
    this.dxfViewerElement = dxfViewerElement
    if (this.isDxfFromDB) {
      this.dxfParserOutput = file
      this.setViewer(this.dxfViewerElement)
    } else {
      if (file) {
        this.fileReader.onload = ($event) => {
          this.parseDxf($event)
          this.setViewer(this.dxfViewerElement)
        }
        this.fileReader.readAsBinaryString(file)
      }
    }
  }

  public parseDxf(event: any) {
    // Parse dxf to json object
    this.dxfParserOutput = this.dxfParser.parseSync(
      event.target.result.toString()
    )
  }

  public setViewer(dxfViewerElement) {
    // this.dxfParserOutput.blocks['14.8T'].entities[2].color = 2170838
    // this.dxfParserOutput.entities.splice(-2, 1)
    // Get the dxf vertex coordinates in the dxf measure units
    // The use of the typescript flatMap method is possible
    // when you change, in the tsconfig.json, the value of the property
    // lib from es2018 to es2019
    // "lib": ["es2019", "dom"]]

    this.dxfVertex = []
    this.dxfVertex = this.dxfParserOutput.entities
      .flatMap((e) => {
        if (!e) return

        const isArc = e.type === 'ARC'
        const isLineType = e?.type === 'LINE' || e?.type === 'LWPOLYLINE'
        const hasVertices = e?.vertices ? true : false

        if (isLineType && !hasVertices) {
          this.toastr.error('DXF no válido: vértices no generados')
          return
        }

        return e?.vertices
      })
      .filter((c) => !!c)
      .map((c) => ({ dxf: c }))

    if (this.dxfVertex.length === 0) {
      return this.toastr.error('DXF no válido: vértices no generados')
    }
    this.addVertexs(this.dxfParserOutput.entities)

    // Instance
    this.dxfViewerInstance = new Viewer(
      this.dxfParserOutput,
      dxfViewerElement,
      this.canvasSize.width,
      this.canvasSize.height,
      null
    )
    this.setViewport()
    this.addRelativeAndAbsoluteCoordinates()

    this.addListener(dxfViewerElement)
    this.emitDxfVertexs()
    this.parseDxfToPng()
  }

  private addVertexs(entities: Entity[]) {
    const newVertices: Coordinates[] = []

    entities.forEach((e) => {
      const isArc = e.type === 'ARC'
      const isLineType = e?.type === 'LINE' || e?.type === 'LWPOLYLINE'

      if (isLineType) {
        newVertices.push(...this.vertexForLineWithBulge(e))
      }

      if (isArc) {
        newVertices.push(...this.vertexForArc(e))
      }
    })

    const vertex = newVertices.filter((v) => !!v).map((v) => ({ dxf: v }))

    this.dxfVertex = [...this.dxfVertex, ...vertex]
  }

  private vertexForArc(entity: Entity): Coordinates[] {
    const radius = entity.radius

    // get the center of the arc
    const center = new Vector2(entity.center.x, entity.center.y)

    // transform center on (0,0)
    const centerTransformed = new Vector2(0, 0)

    const angleToRotate = 2 * Math.PI - entity.startAngle

    const endRotate = new Vector2(
      radius * Math.cos(entity.endAngle),
      radius * Math.sin(entity.endAngle)
    ).rotateAround(centerTransformed, angleToRotate)

    const angleBetween = endRotate.angle()

    const newVertexs: Coordinates[] = []

    let angleBetweenAux = angleBetween
    const angleToSubstract = degreeToRadians(5)
    while (angleBetweenAux > 0) {
      // get vertex and applying inverse transformationss
      const newVertex = new Vector2(
        radius * Math.cos(angleBetweenAux),
        radius * Math.sin(angleBetweenAux)
      )
        .rotateAround(centerTransformed, -angleToRotate)
        .add(center)

      newVertexs.push(newVertex)
      angleBetweenAux -= angleToSubstract
    }

    return [...newVertexs]
  }

  private vertexForLineWithBulge(e: Entity): Coordinates[] {
    const newVertices: Coordinates[] = []

    e.vertices.forEach((v, i) => {
      const isLast = i === e.vertices.length - 1

      if (!v.bulge || isLast) return

      // function that get ortogonal unitary vector given a vector
      const ortogonal = (v: Vector2) => {
        const ortogonalVector = new Vector2(-v.y, v.x)
        return ortogonalVector.normalize()
      }

      // funtion to calculate angle respect x axis
      const angleRespectX = (v: Vector2) => {
        const angle = Math.atan2(v.y, v.x)
        return angle
      }

      const normalizeAngleInterval = (angle: number) => {
        while (angle < 0) {
          angle += 2 * Math.PI
        }

        while (angle > 2 * Math.PI) {
          angle -= 2 * Math.PI
        }

        return angle
      }

      // funtion that calculate rest between 2 vectors
      const rest = (v1: Vector2, v2: Vector2): Vector2 => {
        return new Vector2(v1.x - v2.x, v1.y - v2.y)
      }

      const bulge = v.bulge
      const alfa = 4 * Math.atan(bulge)

      const v1 = new Vector2(v.x, v.y)

      const v2 = new Vector2(e.vertices[i + 1].x, e.vertices[i + 1].y)
      const vrest = rest(v2, v1)

      // funtion calculate distance between two vectors:Vector2
      const distance = (v1: Vector2, v2: Vector2) =>
        Math.abs(Math.sqrt(Math.pow(v1.x - v2.x, 2) + Math.pow(v1.y - v2.y, 2)))

      const d = distance(v1, v2)
      const radius = (d / 2) * Math.sin(alfa / 2)

      const a = radius * Math.cos(alfa / 2)

      const vort = ortogonal(vrest).multiplyScalar(a)
      const center = v1.clone().add(vrest.multiplyScalar(0.5)).add(vort)

      const centerTransformed = center.clone().sub(center)
      const v1Transformed = v1.clone().sub(center)
      const v2Transformed = v2.clone().sub(center)

      let angles = {
        start: normalizeAngleInterval(angleRespectX(v1Transformed)),
        end: normalizeAngleInterval(angleRespectX(v2Transformed)),
      }

      if (bulge < 0) {
        const startAux = angles.start
        angles.start = angles.end
        angles.end = startAux
      }

      const entity = {
        center: { x: center.x, y: center.y },
        startAngle: angles.start,
        endAngle: angles.end,
        radius: Math.abs(radius),
      }

      const arcs = this.vertexForArc(entity)

      newVertices.push(v1, v2, center, ...arcs)
    })

    return newVertices
  }

  public rotateDxf(mode: string) {
    const degrees = mode === 'left' ? 90 : -90
    const newEntitiesVertices: any = []

    const entityType = {
      LINE: (entity) => {
        newEntitiesVertices.push(
          this.dxfTransformationService.rotateVertex(degrees, entity)
        )
      },
      LWPOLYLINE: (entity) => {
        newEntitiesVertices.push(
          this.dxfTransformationService.rotateVertex(degrees, entity)
        )
      },
      POLYLINE: (entity) => {
        newEntitiesVertices.push(
          this.dxfTransformationService.rotateVertex(degrees, entity)
        )
      },
      ARC: (entity) => {
        newEntitiesVertices.push(
          this.dxfTransformationService.rotateArc(degrees, entity)
        )
      },
      CIRCLE: (entity) => {
        newEntitiesVertices.push(
          this.dxfTransformationService.rotateCenter(degrees, entity)
        )
      },
      INSERT: (entity) => {
        // newEntitiesVertices.push(
        //   this.dxfTransformationService.rotatePosition(degrees, entity)
        // )
      },
    }

    this.dxfParserOutput.entities.forEach((entity) => {
      if (entityType[entity.type]) entityType[entity.type](entity)
    })
    this.dxfParserOutput = {
      ...this.dxfParserOutput,
      entities: newEntitiesVertices,
    }

    this.setViewer(this.dxfViewerElement)
  }

  public reflexionDxf(): void {
    const newEntities: any[] = []
    const entityType = {
      LINE: (entity) => {
        newEntities.push(this.dxfTransformationService.reflectVertex(entity))
      },
      LWPOLYLINE: (entity) => {
        newEntities.push(this.dxfTransformationService.reflectVertex(entity))
      },
      POLYLINE: (entity) => {
        newEntities.push(this.dxfTransformationService.reflectVertex(entity))
      },
      ARC: (entity) => {
        const newEntity = this.dxfTransformationService.reflectCenter(entity)
        newEntities.push(this.dxfTransformationService.reflectArc(newEntity))
      },
      CIRCLE: (entity) => {
        newEntities.push(this.dxfTransformationService.reflectCenter(entity))
      },
      INSERT: (entity) => {
        // this.reflectPosition(entity)
      },
    }

    this.dxfParserOutput.entities.forEach((entity) => {
      if (entityType[entity.type]) entityType[entity.type](entity)
    })

    this.dxfParserOutput = {
      ...this.dxfParserOutput,
      entities: newEntities,
    }

    this.setViewer(this.dxfViewerElement)
  }

  private emitDxfVertexs(): void {
    this.dxfVertexSubject.next(this.dxfVertex)
  }

  private setViewport() {
    // Get the Three-dxf viewport property.
    const viewport = this.dxfViewerInstance.viewPort
    // this.dxfViewer.viewPort calculates the coordinates from the centre of the viewport.
    // This is not usefull for our porpose.
    // We need calculate the absolute coordinates of the Three-dxf container
    this.viewport = {
      left: viewport.center.x + viewport.left,
      right: viewport.center.x + viewport.right,
      top: viewport.center.y + viewport.top,
      bottom: viewport.center.y + viewport.bottom,
    }
  }
  // To calculate coordinates in relative percentage to three-dxf container
  // e.g: realtive.x: 0.767032... means that is the 76.7032% of the width of the three-dxf container
  private relativeCoords(viewport: Viewport, coords: Coordinates): Coordinates {
    return {
      x: (coords.x - viewport.left) / (viewport.right - viewport.left),
      y: (coords.y - viewport.top) / (viewport.bottom - viewport.top),
    }
  }

  // To calculate the absolute coordinates of the three-dxf container
  private realCoords(size: Size, relativeCoords: Coordinates): Coordinates {
    return {
      x: relativeCoords.x * size.width,
      y: relativeCoords.y * size.height,
    }
  }

  // Add to the vertices array the relative and absolute coordinates
  private addRelativeAndAbsoluteCoordinates(): void {
    this.dxfVertex.forEach((v) => {
      v.relative = this.relativeCoords(this.viewport, v.dxf)
      v.absolute = this.realCoords(this.canvasSize, v.relative)
    })
  }

  // To calculate the distance between two coordinates
  private distanceTo(
    coordsOrigin: Coordinates,
    coordsDestination: Coordinates
  ): number {
    return Math.abs(
      Math.sqrt((coordsOrigin.x - coordsDestination.x) ** 2) +
        Math.sqrt((coordsOrigin.y - coordsDestination.y) ** 2)
    )
  }

  // To add the mousemove event listener to the three-dxf viewer.
  // It is needed to get the vertex closest to the mouse pointer
  // and send it by closestVertice$ observable
  private addListener(dxfViewerElement: HTMLDivElement): void {
    // To remove the previous subscription
    this.moveEventSubscription?.unsubscribe()

    // Create new subscription to the mousemove event
    this.moveEventSubscription = fromEvent(dxfViewerElement, 'mousemove')
      .pipe(throttleTime(THROTTLE_MS))
      .subscribe((evt: MouseEvent) => {
        const relativeCoords = this.relativeCoords(
          {
            top: 0,
            left: 0,
            right: this.canvasSize.width,
            bottom: this.canvasSize.height,
          },
          {
            x: evt.offsetX,
            y: evt.offsetY,
          }
        )
        const verticesWithDistance = this.dxfVertex.map((v) => ({
          ...v,
          distance: this.distanceTo(v.relative, relativeCoords),
        }))

        const closest = verticesWithDistance.sort(
          (a, b) => a.distance - b.distance
        )[0]
        this.closestVertice.next(closest)
      })
  }

  public clearCanvas() {
    if (this.dxfViewerInstance) {
      this.dxfViewerInstance.renderer.clear()
    }
  }

  public isDxfFromDBEmitter(is: boolean): void {
    this.isDxfFromDB = is
    this.isDxfFromDBSubject.next(is)
  }

  public parseDxfToPng() {
    const dataURL = this.dxfViewerInstance.renderer.domElement.toDataURL()
    this.dxfPngBlob = dataUrlToBlob(dataURL)
  }
}
