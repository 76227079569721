import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'

import { User } from '../core/models/User.model'
import { Nullable } from '../core/types/Nullable.type'

@Injectable({
  providedIn: 'root',
})
export class AuthStatusService {
  private _user: Nullable<User>
  private _isAuthenticated: boolean = false

  private userSubject: BehaviorSubject<Nullable<User>>
  public user$: Observable<Nullable<User>>

  private isAuthenticatedSubject: BehaviorSubject<boolean>
  public isAuthenticated$: Observable<boolean>

  constructor() {
    this.userSubject = new BehaviorSubject<Nullable<User>>(null)
    this.user$ = this.userSubject.asObservable()

    this.isAuthenticatedSubject = new BehaviorSubject<boolean>(false)
    this.isAuthenticated$ = this.isAuthenticatedSubject.asObservable()
  }

  public getUser(): Nullable<User> {
    return this._user
  }

  public isAuthenticated(): boolean {
    return this._isAuthenticated
  }

  public setUser(user: Nullable<User>): void {
    this._user = user
    this._isAuthenticated = !!user
    this.emit()
  }

  private emit(): void {
    this.userSubject.next(this._user)
    this.isAuthenticatedSubject.next(this._isAuthenticated)
  }
}
