<div class="wrapper-opening-window-door">
  <app-opening-images-grid
    [$images]="openingWindowsDoorImages"
    [openingSelectedId]="openingSelectedId"
    (openingSelectedEmitter)="selectOpeningId($event)"
  ></app-opening-images-grid>

  <div class="form">
    <!-- primer parrafo altura manilla y desplegable   -->
    <form class="form_first-pair" [formGroup]="handleDoorForm">
      <div class="handle-door-container">
        <div class="form_height">
          <label>Altura manilla</label>
          <input
            class="form_space"
            name="alturamanilla"
            type="number"
            formControlName="height"
            style="width: 100px"
          />
        </div>
        <div class="form_reference">
          <label for="reference">Referencia</label>
          <select
            class="form_space"
            id="reference"
            name="reference"
            formControlName="reference"
          >
            <option value="1">Del piso</option>
            <option value="2">Del suelo</option>
          </select>
        </div>
      </div>

      <div
        class="delete-opening"
        *ngIf="openingSelectedElement"
        (click)="removeOpening()"
      >
        <fa-icon [icon]="faTrashAlt" class="icon"></fa-icon>
      </div>
    </form>

    <!-- añadir hojas -->

    <div class="add-sheet">
      <app-opening-table-leaft
        [areaWidth]="areaWidth"
        [openingFromSeleted]="openingLeaftsFromSelected"
        [openingsLeaft]="openingLeafts"
        [selectedOpeningId]="openingSelectedId"
        (selectedOpeningIdEmitter)="selectOpeningId($event)"
        (openingLeaftsEmitter)="setOpeningLeafts($event)"
      ></app-opening-table-leaft>
    </div>
    <!-- añadir hojas -->

    <!-- pefiles -->
    <form class="columns" [formGroup]="hasProfileForm">
      <!-- primera columna  -->

      <div class="columns_profiles">
        <div></div>
        <div>
          <label>Perfil principal</label>
          <br />
          <div style="width: 100px">
            <app-overlaps
              (overlapsEmitter)="
                setMainProfileSuperpositionAndCutVariation($event)
              "
              [overlap]="mainProfiles?.top?.superposition"
              [cutVariation]="mainProfiles?.top?.cutVariation"
            ></app-overlaps>
          </div>
        </div>
        <app-profile-select
          (selectedProfileEmitter)="setMainProfile($event)"
          [selectedProfile]="mainProfiles.top.profile"
          [serieId]="serieId"
          [onlyOneProfile]="true"
          typeProfile="Hoja"
        ></app-profile-select>

        <div class="center">
          <input type="checkbox" formControlName="hasInversor" />
        </div>
        <div>
          <label>Inversor</label>
          <app-overlaps
            (overlapsEmitter)="setSuperpositionCutVariation('inversor', $event)"
            [cutVariation]="
              optionalProfiles?.inversor?.superpositionCutVariation
                ?.cutVariation
            "
            [overlap]="
              optionalProfiles?.inversor?.superpositionCutVariation?.overlap
            "
          ></app-overlaps>
        </div>
        <app-profile-select
          (selectedProfileEmitter)="
            setOptinalProfile('inversor', $event, 'hasInversor')
          "
          [selectedProfile]="optionalProfiles?.inversor?.profile"
          [serieId]="serieId"
          [onlyOneProfile]="true"
          typeProfile="Inversor"
        ></app-profile-select>

        <div class="center">
          <input type="checkbox" formControlName="hasBottomProfile" />
        </div>
        <div>
          <label>Perfil inferior</label>
          <br />
          <!-- <app-overlaps
            (overlapsEmitter)="setBottomSuperposition($event)"
          ></app-overlaps> -->
        </div>
        <app-profile-select
          (selectedProfileEmitter)="
            setOptinalProfile('bottom', $event, 'hasBottomProfile')
          "
          [selectedProfile]="optionalProfiles.bottom?.profile"
          [serieId]="serieId"
          [onlyOneProfile]="true"
          typeProfile="Hoja"
        ></app-profile-select>

        <div class="center">
          <input type="checkbox" formControlName="hasHandleDoorProfile" />
        </div>
        <span class="columns_profiles-handle">
          Perfil lateral de la manilla
        </span>
        <app-profile-select
          (selectedProfileEmitter)="
            setOptinalProfile('lateral', $event, 'hasHandleDoorProfile')
          "
          [selectedProfile]="optionalProfiles.lateral?.profile"
          [serieId]="serieId"
          [onlyOneProfile]="true"
          typeProfile="Hoja"
        ></app-profile-select>
      </div>

      <!-- segunda columna  -->

      <div class="columns_profiles2">
        <div class="center">
          <input type="checkbox" formControlName="hasDripTray" />
        </div>
        <div>
          <span class="columns_profiles-handle"> Canaleta </span>
          <app-overlaps
            (overlapsEmitter)="setSuperpositionCutVariation('dripTray', $event)"
            [cutVariation]="
              optionalProfiles?.dripTray?.superpositionCutVariation
                ?.cutVariation
            "
            [overlap]="
              optionalProfiles?.dripTray?.superpositionCutVariation?.overlap
            "
          ></app-overlaps>
        </div>
        <app-profile-select
          (selectedProfileEmitter)="
            setOptinalProfile('dripTray', $event, 'hasDripTray')
          "
          [selectedProfile]="optionalProfiles.dripTray?.profile"
          [serieId]="serieId"
          [onlyOneProfile]="true"
          typeProfile="Canal"
        ></app-profile-select>

        <div class="center">
          <input type="checkbox" formControlName="hasDoorstep" />
        </div>
        <div>
          <span class="columns_profiles-handle"> Umbral </span>
          <app-overlaps
            (overlapsEmitter)="setSuperpositionCutVariation('doorStep', $event)"
            [cutVariation]="
              optionalProfiles?.doorStep?.superpositionCutVariation
                ?.cutVariation
            "
            [overlap]="
              optionalProfiles?.doorStep?.superpositionCutVariation?.overlap
            "
          ></app-overlaps>
        </div>
        <app-profile-select
          (selectedProfileEmitter)="
            setOptinalProfile('doorStep', $event, 'hasDoorstep')
          "
          [selectedProfile]="optionalProfiles.doorStep?.profile"
          [serieId]="serieId"
          typeProfile="Hoja"
          [onlyOneProfile]="true"
        ></app-profile-select>
      </div>
    </form>
    <!-- pefiles -->
  </div>
</div>
