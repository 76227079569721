import { Component, EventEmitter, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

import { BsModalRef } from 'ngx-bootstrap/modal'
import {
  CutVariationService,
  CutVariationTypes,
} from 'src/app/@services/cut-variation.service'
import { ProfileUsesService } from 'src/app/@services/profile-uses.service'
import { CoverGaps } from 'src/app/@shared/@interfaces/coverGaps'
import { IDialog } from 'src/app/@shared/@interfaces/dialog'
import { CutVariation } from 'src/app/@shared/@models/cut-variation.model'

@Component({
  selector: 'app-cut-variation-dialog',
  templateUrl: './cut-variation-dialog.component.html',
  styleUrls: ['./cut-variation-dialog.component.scss'],
})
export class CutVariationDialogComponent implements IDialog, OnInit {
  //Inputs
  public overlap = 0
  public cutVariation: { id: number; value: number; value2?: number } = {
    id: 1,
    value: 0,
  }

  $response: EventEmitter<Reponse> = new EventEmitter<Reponse>()

  private cutVariationTypes: CutVariationTypes

  public selectedCutName = 'Ninguna'
  public cutVariations: CutVariation[] = []
  public form: FormGroup

  constructor(
    private fb: FormBuilder,
    private modalRef: BsModalRef,
    private profileUsesService: ProfileUsesService,
    private cutVariationService: CutVariationService
  ) {
    this.cutVariationTypes = this.cutVariationService.cutVariationTypes
  }

  ngOnInit(): void {
    this.initComponent()
  }

  private async initComponent(): Promise<void> {
    this.buildForm()
    await this.getCutVariations()
    this.selectCutVariation(this.cutVariation.id)
  }

  confirm(): void {
    this.$response.emit({
      ...this.cutVariation,
      overlap: this.form.get('overlap').value,
    })
    this.modalRef.hide()
  }
  decline(): void {
    this.modalRef.hide()
  }

  public onSubmit(): void {
    const formValue = this.form.value

    this.cutVariation = {
      id: formValue.id ?? 1,
      value: formValue.value ?? 0,
      ...(formValue.value2 ? { value2: formValue.value2 } : {}),
    }

    this.confirm()
  }

  private buildForm(): void {
    this.form = this.fb.group({
      id: [this.cutVariation.id ?? 1, [Validators.required]],
      value: [this.cutVariation.value ?? 0, [Validators.required]],
      value2: [this.cutVariation?.value2 ?? 0],
      overlap: [this.overlap ?? 0, [Validators.required]],
    })
  }

  public onCutVariationChange(cut: CutVariation): void {
    this.selectCutVariation(cut.id)
  }

  public selectCutVariation(id: number) {
    this.selectedCutName = this.getCutVariationName(id)
    this.form.get('id').setValue(id)
  }

  public getCutVariationName(id: number): string {
    return this.cutVariations.find((c) => c.id === id)?.name ?? 'Ninguno'
  }

  private async getCutVariations(): Promise<void> {
    await this.profileUsesService
      .getCutVariations()
      .toPromise()
      .then((cutVariations) => {
        this.cutVariations = cutVariations
      })
  }

  public showValue(): boolean {
    const selection = this.form.get('id').value ?? 1

    const show = !(
      selection === this.cutVariationTypes.nothing ||
      selection === this.cutVariationTypes.value2
    )

    return show
  }

  public showValue2(): boolean {
    const selection = this.form.get('id').value ?? 1

    const show =
      selection === this.cutVariationTypes.value1_2 ||
      selection === this.cutVariationTypes.value2

    return show
  }
}

interface Reponse {
  id: number
  value: number
  value2?: number
  overlap?: number
}
