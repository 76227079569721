import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { debounceTime } from 'rxjs/operators'

import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import { OpeningService } from 'src/app/@services/opening.service'
import {
  CompleteProfile,
  IOpeningWindowDoor,
  OpeningTableItem,
  SuperpositionCutVariation,
} from 'src/app/@shared/@interfaces/openingObject'
import { ProfileCreator } from 'src/app/@shared/@interfaces/profileCreator.model'
import { DoorWindowTipupOpeningElement } from 'src/app/@shared/@models/modelsElements/door-window-tipupOpening-model'

import { SideOptions } from 'src/app/@shared/@types/side.types'

@Component({
  selector: 'app-opening-window-door',
  templateUrl: './opening-window-door.component.html',
  styleUrls: ['./opening-window-door.component.scss'],
})
export class OpeningWindowDoorComponent implements OnInit {
  // Icons
  public faTrashAlt = faTrashAlt

  @Input() public areaWidth = 0
  @Input() public areaheight = 0
  @Input() public serieId = 1

  @Input() public openingSelectedElement: DoorWindowTipupOpeningElement

  @Input()
  public openingLeaftsFromSelected: OpeningTableItem[] = []
  public openingLeafts: OpeningTableItem[] = []
  public openingSelectedId: number

  public openingWindowsDoorImages = []

  // Profiles
  public mainProfiles: {
    [k in SideOptions]?: {
      profile?: ProfileCreator
      superposition?: number
      cutVariation?: { id: number; value: number; value2?: number }
    }
  } = {
    top: {
      superposition: 0,
      cutVariation: { id: 1, value: 0 },
    },
    bottom: {
      superposition: 0,
      cutVariation: { id: 1, value: 0 },
    },
    left: {
      superposition: 0,
      cutVariation: { id: 1, value: 0 },
    },
    right: {
      superposition: 0,
      cutVariation: { id: 1, value: 0 },
    },
  }

  public optionalProfiles: { [k: string]: CompleteProfile } = {
    dripTray: {
      superpositionCutVariation: {
        overlap: 0,
        cutVariation: { id: 1, value: 0 },
      },
    },
    doorStep: {
      superpositionCutVariation: {
        overlap: 0,
        cutVariation: { id: 1, value: 0 },
      },
    },
    inversor: {
      superpositionCutVariation: {
        overlap: 0,
        cutVariation: { id: 1, value: 0 },
      },
    },
    bottom: {
      superpositionCutVariation: {
        overlap: 0,
        cutVariation: { id: 1, value: 0 },
      },
    },
    lateral: {
      superpositionCutVariation: {
        overlap: 0,
        cutVariation: { id: 1, value: 0 },
      },
    },
  }

  public handleDoorForm: FormGroup
  public handleDoorLateralProfile: ProfileCreator

  public hasProfileForm: FormGroup

  @Output() openingObjectEmitter = new EventEmitter<IOpeningWindowDoor[]>()
  @Output() removeOpeningEmitter = new EventEmitter<false>()

  constructor(private fb: FormBuilder, private openingService: OpeningService) {
    this.openingWindowsDoorImages = this.filterOpeningsTypes()
  }

  private filterOpeningsTypes(): Array<{ id: number }> {
    const excludes = [7, 8, 9, 10]

    return this.openingService.openingTypesAbisagradas.filter(
      (o) => !excludes.includes(o.id)
    )
  }

  ngOnInit(): void {
    this.buildForm()
    this.setFromSelect()
  }

  private setFromSelect(): void {
    if (!this.openingSelectedElement) return

    const leaftProfileElement = this.openingSelectedElement.leafts[0]
      .profilesElements.top

    this.setMainProfile(leaftProfileElement.profile)
    this.setMainProfileSuperpositionAndCutVariation({
      overlap: leaftProfileElement.overlap ?? 0,
      cutVariation: leaftProfileElement.cutVariation,
    })

    const doorstepElement = this.openingSelectedElement.doorStepElement

    if (doorstepElement) {
      this.setOptinalProfile('doorStep', doorstepElement.profile, 'hasDoorstep')
      this.setSuperpositionCutVariation('doorStep', {
        overlap: doorstepElement.overlap,
        cutVariation: doorstepElement.cutVariation,
      })
    }

    const dripTray = this.openingSelectedElement.leafts[0].dripTray
    if (dripTray) {
      this.setOptinalProfile('dripTray', dripTray.profile, 'hasDripTray')
      this.setSuperpositionCutVariation('dripTray', {
        cutVariation: dripTray.cutVariation,
        overlap: dripTray.overlap,
      })
    }

    const inversor = this.openingSelectedElement.leafts.find((l) => l.inversor)
      ?.inversor
    if (inversor) {
      this.setOptinalProfile('inversor', inversor.profile, 'hasInversor')
      this.setSuperpositionCutVariation('inversor', {
        overlap: inversor.overlap,
        cutVariation: inversor.cutVariation,
      })
    }

    const lateral = this.openingSelectedElement.leafts
      .find((l) => l.hasHandleDoor)
      ?.getHandleDoorLateral()

    const isLateralDiff =
      lateral?.profile?.id !== leaftProfileElement?.profile?.id
    if (isLateralDiff) {
      this.setOptinalProfile(
        'lateral',
        lateral?.profile,
        'hasHandleDoorProfile'
      )
    }

    const bottomProfile = this.openingSelectedElement.leafts[0].profilesElements
      .bottom
    const isBottomdiff =
      bottomProfile?.profile.id !== leaftProfileElement.profile.id
    if (isBottomdiff) {
      this.setOptinalProfile(
        'bottom',
        bottomProfile.profile,
        'hasBottomProfile'
      )
    }
  }

  private buildForm(): void {
    this.handleDoorForm = this.fb.group({
      height: [this.areaheight / 2, [Validators.required]],
      reference: [],
    })

    this.hasProfileForm = this.fb.group({
      hasBottomProfile: [false],
      hasHandleDoorProfile: [false],
      hasInversor: [false],
      hasDripTray: [false],
      hasDoorstep: [false],
    })

    this.hasProfileForm.valueChanges
      .pipe(debounceTime(300))
      .subscribe(() => this.emit())

    this.handleDoorForm.valueChanges
      .pipe(debounceTime(300))
      .subscribe((values) => {
        this.emit()
      })
  }

  // Setter of profiles
  public setOpeningLeafts(openingLeafts: OpeningTableItem[]) {
    this.openingLeafts = openingLeafts
    this.emit()
  }

  public setMainProfile(profile: ProfileCreator): void {
    Object.keys(this.mainProfiles).forEach((side: SideOptions) => {
      this.mainProfiles[side].profile = profile
      const profileUse = profile?.usesProfile?.find((u) =>
        u.useTypeProfile.name.toLowerCase().includes('hoja')
      )

      if (profileUse) {
        this.mainProfiles[side].cutVariation = {
          id: profileUse.cutVariation.id,
          value: profileUse.value,
        }

        this.mainProfiles[side].superposition = profileUse.overlap
      }
    })
    this.emit()
  }

  public setMainProfileSuperpositionAndCutVariation(
    data: SuperpositionCutVariation
  ): void {
    Object.keys(this.mainProfiles).forEach((side: SideOptions) => {
      this.mainProfiles[side].cutVariation = data.cutVariation
      this.mainProfiles[side].superposition = data.overlap
    })
    this.emit()
  }

  public setOptinalProfile(
    optionalProfileKey: string,
    profile: ProfileCreator,
    formControlName: string
  ) {
    let completeProfile = this.optionalProfiles[optionalProfileKey]
    this.hasProfileForm.get(formControlName).setValue(true)
    this.optionalProfiles[optionalProfileKey] = { ...completeProfile, profile }

    const profileUse =
      profile?.usesProfile.find((u) => {
        return u.useTypeProfile.name.toLowerCase().includes(optionalProfileKey)
      }) ?? profile?.usesProfile[0]

    if (profileUse) {
      this.optionalProfiles[optionalProfileKey] = {
        profile: profile,
        superpositionCutVariation: {
          cutVariation: {
            id: profileUse.cutVariation.id,
            value: profileUse.value,
          },
          overlap: profileUse.overlap,
        },
      }
    }

    this.emit()
  }

  public setSuperpositionCutVariation(
    optionalProfileKey: string,
    data: SuperpositionCutVariation
  ): void {
    const completeProfile = this.optionalProfiles[optionalProfileKey]
    this.optionalProfiles[optionalProfileKey] = {
      ...completeProfile,
      superpositionCutVariation: data,
    }
    this.emit()
  }

  // Opening
  public selectOpeningId(id: number): void {
    this.openingSelectedId = id
  }

  private emit(): void {
    const {
      hasBottomProfile,
      hasHandleDoorProfile,
      hasInversor,
      hasDripTray,
      hasDoorstep,
    } = this.hasProfileForm.value

    const responseObject: IOpeningWindowDoor[] = this.openingLeafts.map((l) => {
      const isOpenToLeft = [2, 4, 6].includes(l.opening.id)
      const hasDifferentProfileForHandleDoor =
        (this.optionalProfiles.lateral?.profile && hasHandleDoorProfile
          ? true
          : false) && l.isMain

      return {
        openingType: { id: l.opening.id },
        openingWidth: l.width,
        doorhandleHeight: this.handleDoorForm.get('height').value,
        isMain: l.isMain,
        ...(hasInversor ? { inversor: this.optionalProfiles.inversor } : {}),
        ...(hasDripTray ? { dryTray: this.optionalProfiles.dripTray } : {}),
        ...(hasDoorstep ? { doorstep: this.optionalProfiles.doorStep } : {}),
        hasChangeLateral: hasDifferentProfileForHandleDoor,
        profiles: {
          ...this.mainProfiles,
          bottom: {
            ...this.mainProfiles.bottom,
            profile: hasBottomProfile
              ? this.optionalProfiles.bottom.profile
              : this.mainProfiles.bottom.profile,
          },
          left: {
            ...this.mainProfiles.left,
            profile: hasDifferentProfileForHandleDoor
              ? isOpenToLeft
                ? this.mainProfiles.left.profile
                : this.optionalProfiles.lateral.profile
              : this.mainProfiles.left.profile,
          },
          right: {
            ...this.mainProfiles.right,
            profile: hasDifferentProfileForHandleDoor
              ? !isOpenToLeft
                ? this.mainProfiles.right.profile
                : this.optionalProfiles.lateral.profile
              : this.mainProfiles.right.profile,
          },
        },
      }
    })

    this.openingObjectEmitter.emit(responseObject)
  }

  public removeOpening() {
    this.removeOpeningEmitter.next(false)
  }
}
