import { Component, OnInit } from '@angular/core'

import { NgxSpinnerService } from 'ngx-spinner'

import { AccesoryService } from 'src/app/@services/accesory.service'
import { Accesory } from 'src/app/@shared/@interfaces/accesory'
import { AccesoryNode } from 'src/app/@components/accesories-tree/accesories-tree.component'

@Component({
  templateUrl: './accesories.component.html',
  styleUrls: ['./accesories.component.scss'],
})
export class AccesoriesComponent implements OnInit {
  public accesories: Accesory[] = []
  public treeNode: AccesoryNode[] = []

  public selectedAccesory: Accesory

  constructor(
    private accesoryService: AccesoryService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.getAccesories()
  }

  public onSelectAccesory(accesory: Accesory): void {
    this.selectedAccesory = accesory
  }

  private getAccesories(): void {
    this.spinner.show()
    this.accesoryService.getAllAccesories().subscribe((accesories) => {
      this.accesories = accesories
      this.buildTree()
      this.spinner.hide()
    })
  }

  private buildTree() {
    this.treeNode =
      this.accesories?.map((a) => ({
        accesory: a,
      })) ?? []
  }

  public onSearch(criteria: string): void {
    if (!criteria) return this.buildTree()
    this.spinner.show()

    criteria = criteria?.toLowerCase() ?? ''

    let auxTreeNode: AccesoryNode[] = []

    this.accesories.forEach((a) => {
      const codeRef = a.codRef.toLowerCase()
      const description = a.denomination.toLowerCase()

      if (codeRef.includes(criteria)) auxTreeNode.push({ accesory: a })
      if (description.includes(criteria)) auxTreeNode.push({ accesory: a })
    })

    this.treeNode = null
    this.treeNode = auxTreeNode

    this.spinner.hide()
  }
}
