// ANGULAR
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
// TOOLS
// Fontawesome regular icons
import {
  faFolderOpen,
  faHandPaper,
  faSave as faSaveRegular,
  faTrashAlt,
} from '@fortawesome/free-regular-svg-icons'
// Fontawesome solid icons
import {
  faFileExport,
  faPlus,
  faRedo,
  faRulerCombined,
  faSave as faSaveSolid,
  faSearchMinus,
  faSearchPlus,
  faTrash,
  faUndo,
} from '@fortawesome/free-solid-svg-icons'
import { ToastrService } from 'ngx-toastr'
import { Subscription } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { KonvaComponent } from 'src/app/@components/konva/konva.component'
import { BarlengthResumeDialogComponent } from 'src/app/@dialogs/barlength-resume-dialog/barlength-resume-dialog.component'
// DIALOGS
import { DeleteItemDialogComponent } from 'src/app/@dialogs/delete-item-dialog/delete-item-dialog.component'
import { GuillotineDialogComponent } from 'src/app/@dialogs/guillotine-dialog/guillotine-dialog.component'
import { NewModelDialogComponent } from 'src/app/@dialogs/new-model-dialog/new-model-dialog.component'
import { SlidingDialogComponent } from 'src/app/@dialogs/sliding-dialog/sliding-dialog.component'
import { SummaryAccessoriesModelDialogComponent } from 'src/app/@dialogs/summary-accessories-model-dialog/summary-accessories-model-dialog.component'
import { asyncForEach } from 'src/app/@helper/asyncForEach'
import { radianToDegree } from 'src/app/@helper/radianToDegree'
import { roundUp } from 'src/app/@helper/roundNumber'
import { AreaContainerService } from 'src/app/@services/area-container.service'
import { AreaService } from 'src/app/@services/area.service'
import { AssociatedFrameProfilesService } from 'src/app/@services/associated-frame-profiles.service'
import { CovergapsService } from 'src/app/@services/covergaps.service'
import { CrossbarService } from 'src/app/@services/crossbar.service'
import { FrameService } from 'src/app/@services/frame.service'
import { Area, KonvaService } from 'src/app/@services/konva.service'
// SERVICES
import { ModalService } from 'src/app/@services/modal.service'
import { ModelService } from 'src/app/@services/model.service'
import { MeasuringRuleService } from 'src/app/@services/modelDrawServices/measuring-rule.service'
import { TipupOpeningService } from 'src/app/@services/tipup-opening.service'
import { AssociatedFrameProfileCreator } from 'src/app/@shared/@interfaces/associatedFrameProfile'
// MODELS
import { DialogInstanceModel } from 'src/app/@shared/@models/dialog-instance-model'
import { KonvaAreaModel } from 'src/app/@shared/@models/konva-area-model'
import { KonvaModelModel } from 'src/app/@shared/@models/konva-model-model'
import { AreaModel } from 'src/app/@shared/@models/modelsElements/area-model'
import { AssociatedProfileFrame } from 'src/app/@shared/@models/modelsElements/associatedProfileFrame-model'
import { CoverGapElement } from 'src/app/@shared/@models/modelsElements/covergap-model'
import { CrossbarElement } from 'src/app/@shared/@models/modelsElements/crossbar-model'
import {
  BarLength,
  Frame,
} from 'src/app/@shared/@models/modelsElements/frame-model'
import { Gap } from 'src/app/@shared/@models/modelsElements/gap-model'
import { NewModelModel } from 'src/app/@shared/@models/new-model-model'
import { SideOptions } from 'src/app/@shared/@types/side.types'
import { EntityInformationService } from 'src/app/@shared/entity-information/entity-information.service'

@Component({
  selector: 'app-model-page',
  templateUrl: './model-page.component.html',
  styleUrls: ['./model-page.component.scss'],
})
export class ModelPageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('modelpagewrapper') wrapperElement: ElementRef<HTMLDivElement>
  @ViewChild('konvaContainer') konvaContainer: ElementRef<HTMLDivElement>
  @ViewChild('konvaComponent') konvaComponent: KonvaComponent

  // Icons
  public faSaveRegular = faSaveRegular
  public faSaveSolid = faSaveSolid
  public faUndo = faUndo
  public faRedo = faRedo
  public faTrashAlt = faTrashAlt
  public faFileExport = faFileExport
  public faFolderOpen = faFolderOpen
  public faHandPaper = faHandPaper
  public faRulerCombined = faRulerCombined
  public faSearchPlus = faSearchPlus
  public faSearchMinus = faSearchMinus
  public faPlus = faPlus
  public faTrash = faTrash

  // New model dialog
  private dialogInstanceObject: DialogInstanceModel

  // SideNav
  public showSideNavRight: boolean

  // Model
  public serieOpeningId: number
  public modelId: number
  public modelObject: NewModelModel
  public konvaModelObject: KonvaModelModel
  // Areas
  public areasArray: Array<AreaModel> = []
  public customClass = 'areasAccordion'
  public isFirstOpen = true
  public konvaAreasArray: Array<KonvaAreaModel>
  public konvaArea: KonvaAreaModel

  // Form
  public dimensionsForm: FormGroup
  public areaDimensionsForm: FormGroup

  // Gap
  public gap: Gap

  // Subscriptions
  private gapSubscription: Subscription
  private areasUpdateSubscription: Subscription
  private removeAreaSubscription: Subscription
  private selectedElementSubscription: Subscription

  // BarLength
  public barlength: BarLength = { ext: 0, int: 0 }
  public hoverProfile = null
  public showLegth = false

  constructor(
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private modelService: ModelService,
    private frameService: FrameService,
    private konvaService: KonvaService,
    private areaService: AreaService,
    private covergapsService: CovergapsService,
    private associatedProfileFrameService: AssociatedFrameProfilesService,
    private entityInfoService: EntityInformationService,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private crossbarService: CrossbarService,
    public measureRuleService: MeasuringRuleService,
    private tipupOpeningService: TipupOpeningService,
    private areaContainerService: AreaContainerService
  ) {
    this.modelObject = new NewModelModel()
  }

  ngOnInit(): void {
    this.openNewModelDialog()
    // Only for dev
    // this.setFrameToSelectedArea()
    this.gapSubscribe()
  }

  ngAfterViewInit() {
    this.renderer.listen(this.wrapperElement.nativeElement, 'click', () => {
      this.entityInfoService.hide()
    })
  }

  async ngOnDestroy(): Promise<void> {
    this.gapSubscription?.unsubscribe()
    this.areasUpdateSubscription?.unsubscribe()
    this.removeAreaSubscription?.unsubscribe()
    this.selectedElementSubscription?.unsubscribe()
    this.gap?.onDestroy()
    this.entityInfoService.hide()
  }

  public toggleRuleActivation(): void {
    this.measureRuleService.toggleRule()
  }

  openNewModelDialog(): void {
    this.dialogInstanceObject = new DialogInstanceModel(
      NewModelDialogComponent,
      { class: 'modal-dialog-centered', ignoreBackdropClick: true }
    )

    this.modalService
      .openModal(this.dialogInstanceObject)
      .subscribe((response: any) => {
        this.createModel(response)
      })
  }

  private createModel(model: any) {
    const newModel = {
      width: Number(model.width),
      height: Number(model.height),
      serie: {
        id: model.serie.id,
      },
      name: model.name,
    }

    this.modelService.createModel(newModel).subscribe(
      (response) => {
        this.konvaService.createGap({
          height: Number(newModel.height),
          id: response.id,
          name: newModel.name,
          serie: response.serie,
          valid: true,
          width: Number(newModel.width),
        })
        this.modelObject = response
        this.modelId = this.modelObject.id
        this.dimensionsFormInstance(newModel.width, newModel.height)
      },
      (err) => {}
    )
  }

  dimensionsFormInstance(gapWidth: number, gapHeight: number): void {
    this.dimensionsForm = this.formBuilder.group({
      height: gapHeight,
      width: gapWidth,
    })
    this.showSideNavRight = true

    this.dimensionsForm.valueChanges
      .pipe(debounceTime(500))
      .subscribe((value) => {
        this.gapDimensionsChange(value.width, value.height)
      })
  }

  getModel(): void {
    this.modelService.getModel(1).subscribe(
      (response: NewModelModel) => {
        this.modelObject = response

        this.konvaService.createGap({
          height: Number(this.modelObject.height),
          id: response.id,
          name: this.modelObject.name,
          serie: response.serie,
          valid: true,
          width: Number(this.modelObject.width),
        })

        this.dimensionsFormInstance(
          this.modelObject.width,
          this.modelObject.height
        )
        this.konvaModelObject = new KonvaModelModel(
          this.konvaService.parseMMtoPX(response.width),
          this.konvaService.parseMMtoPX(response.height),
          'stage'
        )
      },
      (error) => {
        console.dir(error)
        this.toastr.error('Ha ocurrido un error al obtener el modelo')
      }
    )
  }

  getAreas(flag?: boolean) {
    this.areaService.getAreas(this.modelId).subscribe(
      (areas: Array<AreaModel>) => {
        this.areasArray = areas

        this.konvaAreasArray = []
        areas.forEach((area) => {
          this.konvaArea = new KonvaAreaModel(
            area._x,
            area._y,
            this.konvaService.parseMMtoPX(area.width),
            this.konvaService.parseMMtoPX(area.height),
            this.konvaService.getStrokeColor()
          )
          if (flag) {
            this.konvaArea.profile.top = true
            this.konvaArea.profile.bottom = true
            this.konvaArea.profile.left = true
            this.konvaArea.profile.right = true
          }
          this.konvaAreasArray.push(this.konvaArea)
        })
      },
      (error) => {
        console.dir(error)
        this.toastr.error('Ha ocurrido un error al obtener las areas')
      }
    )
  }

  public gapDimensionsChange(width: number, height: number): any {
    const isOnlyOneArea = this.gap.areas.length === 1
    const hasChildren = this.gap.areas[0].children.length > 0

    const canModify = (isOnlyOneArea && !hasChildren) || true
    if (!canModify) {
      const actualGapDimensions = {
        width: this.gap.width,
        height: this.gap.height,
      }

      this.dimensionsForm.patchValue(
        {
          width: actualGapDimensions.width,
          height: actualGapDimensions.height,
        },
        { emitEvent: false }
      )

      return this.toastr.warning('Debe tener solo un area sin elementos')
    }

    const area = this.gap.areas[0]
    this.gap.resize(width, height)
    // area.resize(width, height)
    this.gap.layer.draw()

    return
  }

  addExistingModel(area, $event) {
    $event.stopPropagation()
  }

  openDeleteAreaDialog(area, $event): void {
    this.dialogInstanceObject = new DialogInstanceModel(
      DeleteItemDialogComponent,
      {
        initialState: {
          itemName: 'el área',
        },
        class: 'modal-dialog-centered',
      }
    )
    this.modalService
      .openModal(this.dialogInstanceObject)
      .subscribe((response: string) => {
        if (response) {
          this.deleteArea(area.id)
        }
      })
    $event.stopPropagation()
  }

  deleteArea(areaId) {
    this.areaService.deleteArea(areaId).subscribe(
      (response: any) => {
        this.getAreas()
      },
      (error) => {
        console.dir(error)
        this.toastr.error('El area no ha podido ser eliminada')
      }
    )
  }

  saveChanges(area: AreaModel, $event) {
    const updateArea: Area = {
      width: area.width,
      height: area.height,
      x: area.x,
      y: area.y,
      model: {
        id: area.model.id,
      },
    }

    this.areaService.updateArea(updateArea, area.id).subscribe(
      (response: any) => {},
      (error) => {
        console.dir(error)
        this.toastr.error('El area no ha podido ser actualizada')
      }
    )
    $event.stopPropagation()
  }

  // Resizes Areas
  public areaDimensionsChange(event: any, area: AreaModel): void {}

  public async deleteFrame(frame: Frame): Promise<void> {
    await this.deleteAssociatedFrameProfile(frame)
    await this.deleteCovergaps(frame)
    await this.frameService
      .deleteFrame(frame.id)
      .toPromise()
      .catch((e) => console.log(e))

    frame.setId(null)
  }

  private async deleteCovergaps(frame: Frame): Promise<void> {
    const { external, internal } = frame.covergaps
    if (!external && !internal) return

    const id = external ? external.id : internal.id
    if (!id) return

    await this.covergapsService.deleteCovergap(id).toPromise()
  }

  private async deleteAssociatedFrameProfile(frame: Frame): Promise<void> {
    const associatedSides = frame.associatedProfiles
    let profile: AssociatedProfileFrame[] = []

    Object.keys(associatedSides).forEach((side) => {
      const profiles = associatedSides[side] as AssociatedProfileFrame[]
      profile = [...profile, ...profiles]
    })

    await asyncForEach(profile, async (p: AssociatedProfileFrame) => {
      const { id } = p
      if (!id) return

      await this.associatedProfileFrameService
        .deleteAssociatedFrameProfile(p.id)
        .toPromise()
        .catch((e) => console.log(e))
    })
  }

  private updateAreas(): void {
    this.gap.areas.forEach((a) => {
      if (a.id) {
        const updatedArea: Area = {
          width: a.width,
          height: a.height,
          x: a.x,
          y: a.y,
          model: {
            id: a.model.id,
          },
        }

        this.areaService.updateArea(updatedArea, a.id).subscribe(
          (response) => {},
          (err) => {}
        )
      }
    })
  }

  private async createAreasFromGap(): Promise<void> {
    await asyncForEach<AreaModel>(this.gap.areas, async (a, index) => {
      if (!a.id) {
        const newArea: Area = {
          height: a.height,
          width: a.width,
          x: a.x,
          y: a.y,
          model: {
            id: a.model.id,
          },
        }

        await this.areaService
          .createArea(newArea)
          .toPromise()
          .then(
            (response) => {
              a.id = response.id
            },
            (err) => {}
          )
      }
    })
  }

  private gapSubscribe(): void {
    this.gapSubscription = this.konvaService.gapUpdated$.subscribe((gap) => {
      if (!this.gap && gap) {
        // TODO: undo
        this.areasChangeSubscribe(gap)
        this.removeAreaSubscribe(gap)
      }

      this.gap = gap
      this.areasArray = this.gap.areas
    })
  }

  private areasChangeSubscribe(gap: Gap): void {
    this.areasUpdateSubscription = gap?.areasUpdate$
      .pipe(debounceTime(500))
      .subscribe(async (areas) => {
        this.createAreaContainers()
        await this.createAreasFromGap()
        this.updateAreas()
        this.updateAreasFrames()
        this.updateCrossbar()
        this.updateTipupOpenings()
        this.konvaService.sendImageModelPreview({
          gap: this.gap,
          modelId: this.modelId,
          destroy: false,
        })
        this.updateModelLeafs(this.gap.countLeafs())
        this.updateModelFixedElements()
      })
  }

  private createAreaContainers(): void {
    const container = this.gap.container

    this.areaContainerService.createAreaContainer(container).then((resp) => {})

    this.gap.areas.forEach((a) => {
      const opening = a.doorWindowOpenings
      if (!opening) return
      opening.leafts.forEach((l) => {
        l.drawHinge()
        l.drawHandleDoor()
        l.drawOpening()
      })
    })
    this.gap.layer.draw()
  }

  private updateModelLeafs(leafsCount: number): void {
    this.modelService.setLeafs(this.modelId, leafsCount).subscribe(() => {})
  }

  private updateModelFixedElements(): void {
    const hasFixedElements = this.gap.hasFixedElements()
    this.modelService
      .setFixedElementStatus(this.modelId, hasFixedElements)
      .subscribe(() => {})
  }

  public removeAreaSubscribe(gap: Gap): void {
    this.removeAreaSubscription = gap.removeArea$.subscribe((a) => {
      const id = a?.id

      if (id)
        this.areaService
          .deleteArea(id)
          .subscribe(() => this.toastr.success('Area eliminada'))
    })
  }

  private async updateAreasFrames(): Promise<void> {
    console.log('Entra update frames')
    const updatedFrames: string[] = []

    await asyncForEach<AreaModel>(this.areasArray, async (a) => {
      await asyncForEach<Frame>(a.frames, async (f, index) => {
        if (updatedFrames.includes(f.uuid)) return
        updatedFrames.push(f.uuid)

        if (f?.id) await this.deleteFrame(f)

        const needBeCreated = index === 0 || f?.id === undefined

        if (true) {
          const frameCreator = f.generateFrameRequest(this.modelId)

          const { id } = await this.frameService
            .createFrame(frameCreator)
            .toPromise()

          f.setId(id)
          this.updateCovergaps({
            external: f.covergaps?.external,
            internal: f.covergaps?.internal,
          })

          this.updateProfileFrameAssociated(f.associatedProfiles)
        }
      })
    })
  }

  private async updateCrossbar(): Promise<void> {
    const crossbarsUpdated = []

    await asyncForEach<AreaModel>(this.areasArray, async (a, i) => {
      await asyncForEach<CrossbarElement>(a.crossbars, async (c, i) => {
        if (!c || crossbarsUpdated.includes(c?.uuid)) return

        if (c.id) await this.crossbarService.delete(c.id)

        const id = await this.crossbarService
          .create(c, this.modelId)
          .toPromise()
          .then(({ id }) => id)
        c.setId(id)

        crossbarsUpdated.push(c.uuid)
      })
    })
  }

  private async updateProfileFrameAssociated(
    profiles: { [k in SideOptions]: AssociatedProfileFrame[] }
  ) {
    const modelId = this.modelId

    Object.keys(profiles).forEach((side) => {
      const sideProfiles = profiles[side] as AssociatedProfileFrame[]

      sideProfiles.forEach(async (p) => {
        const { ext, int } = p.calculateLength()
        const { mainAngle, secondAngle } = p.getProfileAngles()

        const request: AssociatedFrameProfileCreator = {
          model: modelId,
          profile: {
            uuid: p.uuid,
            profile: { id: p.profile.id },
            intLong: int,
            extLong: ext,
            superimposition: p.overlap,
            cutVariation: p.cutVariation.value,
            cutVariation2: p.cutVariation.value2,
            typeVariation: { id: p.cutVariation.id },
            ang: roundUp(radianToDegree(mainAngle ?? 0), 2),
            ang2: roundUp(radianToDegree(secondAngle ?? 0), 2),
          },
          side: p.side === 'right' ? 'rigth' : p.side,
          distance: 0,
          frame: { id: p.associatedFrame.id },
        }

        this.associatedProfileFrameService
          .createAssociatedFrameProfile(request)
          .subscribe((response) => {
            p.setId(response.id)
          })
      })
    })
  }

  private async updateCovergaps(covergaps: {
    external?: CoverGapElement
    internal?: CoverGapElement
  }): Promise<void> {
    const { external, internal } = covergaps

    const externalRequestObject = external?.buildRequest()
    const internalRequestObject = internal?.buildRequest()

    const modelId = this.modelId

    const hasCovergaps = external || internal

    if (!hasCovergaps) return

    this.covergapsService
      .createCovergap(externalRequestObject, internalRequestObject, modelId)
      .subscribe(
        (response) => {
          const id = response.id
          external?.setId(id)
          internal?.setId(id)
        },
        (err) => {}
      )
  }

  private async updateTipupOpenings(): Promise<void> {
    const updateds = []

    await asyncForEach<AreaModel>(this.areasArray, async (a, i) => {
      const opening = a.doorWindowOpenings

      const isUpdated = updateds.includes(opening?.uuid)
      if (!opening || isUpdated) return

      if (opening.id) return

      const id = await this.tipupOpeningService
        .createDoorWindowOpening(a.doorWindowOpenings, this.modelId)
        .toPromise()
        .then<number>((r) => r.id)

      opening.setId(id)
      updateds.push(opening.uuid)
    })
  }

  public setSlidingToSelectedArea(): void {
    const dialog = new DialogInstanceModel(SlidingDialogComponent, {
      initialState: {},
      class: 'modal-dialog-centered modal-lg modal-frames',
    })

    this.modalService.openModal(dialog).subscribe((response) => {})
  }

  // Modal bar length resume table
  public openBarLengthResumeModal(): void {
    const barlengthDialog = new DialogInstanceModel(
      BarlengthResumeDialogComponent,
      {
        initialState: {
          modelId: this.modelId,
        },
        class: 'modal-dialog-centered modal-xl',
      }
    )

    this.modalService.openModal(barlengthDialog).subscribe(() => {})
  }

  // Modal summary accessories model table
  public openSummaryAccessoriesModal(): void {
    const summaryAccessoriesDialog = new DialogInstanceModel(
      SummaryAccessoriesModelDialogComponent,
      {
        initialState: {
          modelId: this.modelId,
        },
        class: 'modal-dialog-centered modal-xl',
      }
    )

    this.modalService.openModal(summaryAccessoriesDialog).subscribe(() => {})
  }

  public openGuillotineModal(): void {
    const guillotineModal = new DialogInstanceModel(GuillotineDialogComponent, {
      initialState: {},
      class: 'modal-dialog-centered',
    })

    this.modalService.openModal(guillotineModal).subscribe((response) => {})
  }

  public addAccesory(): void {}
}
