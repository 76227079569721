export const dataUrlToBlob = (dataUri: string): Blob => {
  const array = dataUri.split(',')

  const mime = array[0].match(/:(.*?);/)[1]

  const decodeBase64 = atob(array[1])

  let decodedLength = decodeBase64.length

  const u8arr = new Uint8Array(decodedLength)
  while (decodedLength--) {
    u8arr[decodedLength] = decodeBase64.charCodeAt(decodedLength)
  }

  return new Blob([u8arr], { type: mime })
}
