import { Inject, Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router'
import { environment } from 'src/environments/environment'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = localStorage.getItem('token')
    let req = request

    if (token) {
      req = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      })
    }

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        const budgetorUrl = environment.budgetorUrl

        if (err.status === 401) {
          const redirectUrl = window.location.href
          this.navigateToUrl(`${budgetorUrl}?redirectUrl=${redirectUrl}`)
        }

        return throwError(err)
      })
    )
  }

  // method to navigate to another url
  navigateToUrl(url: string) {
    window.location.href = url
  }
}
