import Konva from 'konva'
import { Injectable } from '@angular/core'
import { ProfileCreator } from 'src/app/@shared/@interfaces/profileCreator.model'
import { reflexRespectHorizontalLine } from 'src/app/@helper/reflexRespectHorizontalLine'
import { reflexRespectVerticalLine } from 'src/app/@helper/reflexRespectVerticalLine'
import { UnionService } from '../union.service'
import { UnionTypes } from 'src/app/@shared/@interfaces/unionTypes'
import { Vector2 } from 'three'

const PROFILE_COLOR = '#b2adad'

@Injectable({
  providedIn: 'root',
})
export class ProfileDrawerCreatorService {
  private unionTypes: UnionTypes

  constructor(private unionService: UnionService) {
    this.unionTypes = this.unionService.unionsIds
  }

  public profileByPoints(points: number[]): Konva.Line {
    console.log('points', points)
    return new Konva.Line({
      points: points,
      closed: true,
      fill: PROFILE_COLOR,
      stroke: 'black',
      strokeWidth: 1,
    })
  }

  /**
   * @param startPointExt exterior start point
   * @param profileLength real profile length (not scaled)
   * @param profileWidth thickness to draw (generally internal camera)
   * @returns profiles as Konva Line
   */
  public createHorizontalProfileFromStartExt(
    profileData: IProfileDrawerStartExtCreator
  ) {
    let {
      startPointExt,
      profileWidth,
      scaleFactor,
      profileLength,
    } = profileData

    console.log('profileData', profileData)

    if (profileWidth === 0) profileWidth = 1

    const startExt = new Vector2(startPointExt.x, startPointExt.y)

    const startInt = new Vector2(
      startExt.x,
      startExt.y + profileWidth * scaleFactor
    )

    const endExt = new Vector2(
      startExt.x + profileLength * scaleFactor,
      startExt.y
    )
    const endInt = new Vector2(endExt.x, startInt.y)

    const profilePolygonPoints = [
      startExt.toArray(),
      startInt.toArray(),
      endInt.toArray(),
      endExt.toArray(),
      startExt.toArray(),
    ].flat()

    const profile = this.profileByPoints(profilePolygonPoints)

    return profile
  }

  /**
   * @param startPointExt exterior start point
   * @param profileLength real profile length (not scaled)
   * @param profileWidth thickness to draw (generally internal camera)
   * @returns profiles as Konva Line
   */
  public createVerticalProfileFromStartExt(
    profileData: IProfileDrawerStartExtCreator
  ) {
    let {
      profile,
      profileLength,
      profileWidth,
      startPointExt,
      scaleFactor,
    } = profileData
    console.log('profileData', profileData)

    profileWidth = profileWidth === 0 ? 1 : profileWidth

    const startExt = new Vector2(startPointExt.x, startPointExt.y)

    const startInt = new Vector2(
      startExt.x + profileWidth * scaleFactor,
      startExt.y
    )

    const endExt = new Vector2(
      startExt.x,
      startExt.y + profileLength * scaleFactor
    )
    const endInt = new Vector2(startInt.x, endExt.y)

    const verticalProfilePoints = [
      startExt.toArray(),
      startInt.toArray(),
      endInt.toArray(),
      endExt.toArray(),
      startExt.toArray(),
    ].flat()

    const profileDraw = this.profileByPoints(verticalProfilePoints)

    return profileDraw
  }

  // Horizontal
  /**
   * @param startPointInt interior start point
   * @param profileLength real profile length (not scaled)
   * @param profileWidth thickness to draw (generally internal camera)
   * @returns profile as Konva Line
   */
  public createHorizontalProfileFromStartInt(
    profileData: IProfileDrawerStartIntCreator
  ) {
    const {
      startPointInt,
      profileWidth,
      scaleFactor,
      profileLength,
    } = profileData

    console.log('profileData', profileData)

    const startInt = new Vector2(startPointInt.x, startPointInt.y)

    const startExt = new Vector2(
      startInt.x,
      startInt.y - profileWidth * scaleFactor
    )

    const endInt = new Vector2(
      startInt.x + profileLength * scaleFactor,
      startPointInt.y
    )

    const endExt = new Vector2(endInt.x, startExt.y)

    const profilePolygonPoints = [
      startExt.toArray(),
      startInt.toArray(),
      endInt.toArray(),
      endExt.toArray(),
      startExt.toArray(),
    ].flat()

    const profile = this.profileByPoints(profilePolygonPoints)

    return profile
  }

  public reflexHorizontalProfile(
    yLine: number,
    profile: Konva.Line
  ): Konva.Line {
    const points = profile.points()
    const reflexedPoints = reflexRespectHorizontalLine(points, yLine)

    profile.points(reflexedPoints)

    return profile
  }

  public setHorizontalSuperpositions(
    unionLeft: number,
    unionRight: number,
    scaledSuperposition: number,
    profile: Konva.Line,
    leftProfile: Konva.Line,
    rightProfile: Konva.Line,
    isTop = true
  ) {
    const side = { ...this.getPointsAsVectors(profile.points()) }
    const left = { ...this.getPointsAsVectors(leftProfile.points()) }
    const right = { ...this.getPointsAsVectors(rightProfile.points()) }

    if (unionLeft === this.unionTypes.cut45) {
      profile.move({
        x: profile.x(),
        y: profile.y() - scaledSuperposition,
      })

      this.resizeVertical(leftProfile, scaledSuperposition, 'top')
      this.resizeVertical(rightProfile, scaledSuperposition, 'down')
    }

    return profile
  }

  private resizeVertical(
    profile: Konva.Line,
    diff: number,
    move: 'top' | 'down'
  ): Konva.Line {
    const { startExt, startInt, endExt, endInt } = this.getPointsAsVectors(
      profile.points()
    )

    startExt.setY(startExt.y - diff)
    startInt.setY(startInt.y - diff)

    const profilePoints = [
      startExt.toArray(),
      startInt.toArray(),
      endInt.toArray(),
      endExt.toArray(),
      startExt.toArray(),
    ].flat()
    profile.points(profilePoints)

    return profile
  }

  // End Horizontal

  // Vertical
  /**
   * @param startPointInt interior start point
   * @param profileLength real profile length (not scaled)
   * @returns profiles as Konva Line
   */
  public createVerticalProfileFromStartInt(
    profileData: IProfileDrawerStartIntCreator
  ) {
    const { scaleFactor } = profileData

    console.log('profileData', profileData)

    const startInt = new Vector2(
      profileData.startPointInt.x,
      profileData.startPointInt.y
    )

    const startExt = new Vector2(
      startInt.x - profileData.profileWidth * scaleFactor,
      startInt.y
    )

    const endInt = new Vector2(
      startInt.x,
      startInt.y + profileData.profileLength * scaleFactor
    )

    const endExt = new Vector2(startExt.x, endInt.y)

    const verticalProfilePoints = [
      startExt.toArray(),
      startInt.toArray(),
      endInt.toArray(),
      endExt.toArray(),
      startExt.toArray(),
    ].flat()

    const profile = this.profileByPoints(verticalProfilePoints)

    return profile
  }

  public reflexVerticalProfile(xLine: number, profile: Konva.Line): Konva.Line {
    const points = profile.points()

    const reflexedPoints = reflexRespectVerticalLine(points, xLine)

    profile.points(reflexedPoints)

    return profile
  }

  // End Vertical

  // Set Unions
  public setUnionsForHorizontalIntToExt(
    unionLeft: number,
    unionRight: number,
    profile: Konva.Line,
    scaledProfileThickness: number,
    scaledLeftThickness: number,
    scaledRightThickness: number
  ): Konva.Line {
    const { startExt, startInt, endExt, endInt } = this.getPointsAsVectors(
      profile.points()
    )

    const isBiggerLeftInt =
      unionLeft === this.unionTypes.cut90Horizontal ||
      unionLeft === this.unionTypes.cut90HorizontalwithVertical
    const isBiggerRightInt =
      unionRight === this.unionTypes.cut90Horizontal ||
      unionRight === this.unionTypes.cut90HorizontalwithVertical

    const isBiggerLeftExt = isBiggerLeftInt
    const isBiggerRightExt = isBiggerRightInt

    const isLeft45 = unionLeft === this.unionTypes.cut45
    const isRight45 = unionRight === this.unionTypes.cut45

    if (isBiggerLeftExt) {
      startExt.setX(startExt.x - scaledLeftThickness)
    }

    if (isBiggerRightExt) {
      endExt.setX(endExt.x + scaledRightThickness)
    }

    if (isBiggerLeftInt) {
      startInt.setX(startInt.x - scaledLeftThickness)
    }

    if (isBiggerRightInt) {
      endInt.setX(endInt.x + scaledRightThickness)
    }

    if (isLeft45) {
      startExt.setX(startExt.x - scaledLeftThickness)
      startInt.setX(startExt.x + scaledProfileThickness)
    }

    if (isRight45) {
      endExt.setX(endExt.x + scaledRightThickness)
      endInt.setX(endExt.x - scaledProfileThickness)
    }

    const newPoints = [
      startExt.toArray(),
      startInt.toArray(),
      endInt.toArray(),
      endExt.toArray(),
      startExt.toArray(),
    ].flat()

    profile.points(newPoints)
    return profile
  }

  public setUnionsForVerticalIntToExt(
    unionTop: number,
    unionBottom: number,
    profile: Konva.Line,
    scaledProfileThickness: number,
    scaledTopThickness: number,
    scaledBottomThickness: number
  ): Konva.Line {
    const { startExt, startInt, endExt, endInt } = this.getPointsAsVectors(
      profile.points()
    )

    const isBiggerInStartInt =
      unionTop === this.unionTypes.cut90Vertical ||
      unionTop === this.unionTypes.cut90VerticalwithHorizontal

    const isBiggerInendInt =
      unionBottom === this.unionTypes.cut90Vertical ||
      unionBottom === this.unionTypes.cut90VerticalwithHorizontal

    const isbiggerInStartExt = isBiggerInStartInt
    const isbiggerInEndExt = isBiggerInendInt

    const isTop45 = unionTop === this.unionTypes.cut45
    const isBottom45 = unionBottom === this.unionTypes.cut45

    if (isbiggerInStartExt) {
      startExt.setY(startExt.y - scaledTopThickness)
    }

    if (isbiggerInEndExt) {
      endExt.setY(endExt.y + scaledBottomThickness)
    }

    if (isBiggerInStartInt) {
      startInt.setY(startInt.y - scaledTopThickness)
    }

    if (isBiggerInendInt) {
      endInt.setY(endInt.y + scaledBottomThickness)
    }

    if (isTop45) {
      startExt.setY(startExt.y - scaledTopThickness)
      startInt.setY(startExt.y + scaledProfileThickness)
    }

    if (isBottom45) {
      endExt.setY(endExt.y + scaledBottomThickness)
      endInt.setY(endExt.y - scaledProfileThickness)
    }

    const newPoints = [
      startExt.toArray(),
      startInt.toArray(),
      endInt.toArray(),
      endExt.toArray(),
      startExt.toArray(),
    ].flat()

    profile.points(newPoints)

    return profile
  }

  public setUnionsForVerticalExtToInt(
    unionTop: number,
    unionBottom: number,
    profile: Konva.Line,
    scaledProfileThickness: number,
    offsetTop: number,
    offsetBottom: number
  ): Konva.Line {
    const { startExt, startInt, endExt, endInt } = this.getPointsAsVectors(
      profile.points()
    )

    const isBiggerTopInt =
      unionTop === this.unionTypes.cut90Vertical ||
      unionTop === this.unionTypes.cut90VerticalwithHorizontal
    const isBiggerBottomInt =
      unionBottom === this.unionTypes.cut90Vertical ||
      unionBottom === this.unionTypes.cut90VerticalwithHorizontal

    const isBiggerTopExt = isBiggerTopInt
    const isBiggerBottomExt = isBiggerBottomInt

    const isTop45 = unionTop === this.unionTypes.cut45
    const isBottom45 = unionBottom === this.unionTypes.cut45

    if (isBiggerTopExt) {
      startExt.setY(startExt.y - offsetTop)
    }

    if (isBiggerBottomExt) {
      endExt.setY(endExt.y + offsetBottom)
    }

    if (isBiggerTopInt) {
      startInt.setY(startInt.y - offsetTop)
    }

    if (isBiggerBottomInt) {
      endInt.setY(endInt.y + offsetBottom)
    }

    if (isTop45) {
      startInt.setY(startInt.y + scaledProfileThickness)
    }

    if (isBottom45) {
      endInt.setY(endInt.y - scaledProfileThickness)
    }

    const newPoints = [
      startExt.toArray(),
      startInt.toArray(),
      endInt.toArray(),
      endExt.toArray(),
      startExt.toArray(),
    ].flat()

    profile.points(newPoints)
    return profile
  }
  public setUnionsForHorizontalExtToInt(
    unionLeft: number,
    unionRight: number,
    profile: Konva.Line,
    scaledProfileThickness: number,
    offsetLeft: number,
    offsetRight: number
  ): Konva.Line {
    const { startExt, startInt, endExt, endInt } = this.getPointsAsVectors(
      profile.points()
    )

    const isBiggerLeftInt =
      unionLeft === this.unionTypes.cut90Horizontal ||
      unionLeft === this.unionTypes.cut90HorizontalwithVertical
    const isBiggerRightInt =
      unionRight === this.unionTypes.cut90Horizontal ||
      unionRight === this.unionTypes.cut90HorizontalwithVertical

    const isBiggerLeftExt = isBiggerLeftInt
    const isBiggerRightExt = isBiggerRightInt

    const isLeft45 = unionLeft === this.unionTypes.cut45
    const isRight45 = unionRight === this.unionTypes.cut45

    if (isBiggerLeftExt) {
      startExt.setX(startExt.x - offsetLeft)
    }

    if (isBiggerLeftInt) {
      startInt.setX(startInt.x - offsetLeft)
    }

    if (isBiggerRightExt) {
      endExt.setX(endExt.x + offsetRight)
    }

    if (isBiggerRightInt) {
      endInt.setX(endInt.x + offsetRight)
    }

    if (isLeft45) {
      startInt.setX(startInt.x + scaledProfileThickness)
    }

    if (isRight45) {
      endInt.setX(endInt.x - scaledProfileThickness)
    }

    const newPoints = [
      startExt.toArray(),
      startInt.toArray(),
      endInt.toArray(),
      endExt.toArray(),
      startExt.toArray(),
    ].flat()

    profile.points(newPoints)
    return profile
  }

  public getPointsAsVectors(profilePoints: number[]) {
    const startExt = new Vector2(profilePoints[0], profilePoints[1])
    const startInt = new Vector2(profilePoints[2], profilePoints[3])
    const endInt = new Vector2(profilePoints[4], profilePoints[5])
    const endExt = new Vector2(profilePoints[6], profilePoints[7])

    return {
      startExt,
      startInt,
      endExt,
      endInt,
    }
  }

  public setPointsFromVectors(
    startExt: Vector2,
    startInt: Vector2,
    endExt: Vector2,
    endInt: Vector2,
    profile: Konva.Line
  ): Konva.Line {
    const newPoints = [
      startExt.toArray(),
      startInt.toArray(),
      endInt.toArray(),
      endExt.toArray(),
      startExt.toArray(),
    ].flat()

    profile.points(newPoints)

    return profile
  }

  // Draw profiles fin
}

interface IProfileDrawerStartIntCreator {
  startPointInt: Vector2
  profileLength: number
  scaleFactor: number
  profile: ProfileCreator
  profileWidth: number
}

interface IProfileDrawerStartExtCreator {
  startPointExt: Vector2
  profileLength: number
  scaleFactor: number
  profile: ProfileCreator
  profileWidth: number
}
