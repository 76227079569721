import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { UnionTypes } from '../@shared/@interfaces/unionTypes'
import { UnionModel } from '../@shared/@models/union-model'

export const UNIONS_TYPES = {
  cut45: 1,
  cut90Horizontal: 2,
  cut90Vertical: 3,
  cut90NotPreferencees: 4,
  cut90VerticalwithHorizontal: 5,
  cut90HorizontalwithVertical: 6,
  customUnions: 7,
}

@Injectable({
  providedIn: 'root',
})
export class UnionService {
  private apiUrl = environment.apiUrl

  public unionsIds: UnionTypes = UNIONS_TYPES

  constructor(private http: HttpClient) {}

  public getUnions(): Observable<UnionModel[]> {
    return this.http.get<UnionModel[]>(`${this.apiUrl}unions`).pipe(
      map((unions) =>
        unions
          // .filter((u) => u.id !== 7)
          .map((union) => {
            union.image = '../../assets/icons/unions/' + union.id + '.svg'
            return union
          })
      )
    )
  }
}
