import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'

import * as uuidGenerator from 'uuid'

// FontAwesome
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { debounceTime } from 'rxjs/operators'

@Component({
  selector: 'app-search-inline',
  templateUrl: './search-inline.component.html',
  styleUrls: ['./search-inline.component.scss'],
})
export class SearchInlineComponent implements OnInit {
  @Output() private searchEmitter = new EventEmitter<string>()

  public readonly labelUuid = uuidGenerator.v4()

  // Icons
  public faSearch = faSearch

  public searchForm: FormGroup

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.buildForm()
  }

  private buildForm(): void {
    this.searchForm = this.fb.group({
      isActive: [false],
      search: [''],
    })

    this.searchForm
      .get('search')
      .valueChanges.pipe(debounceTime(500))
      .subscribe((value) => {
        value

        this.emit(value)
      })
  }

  public emit(search: string): void {
    this.searchEmitter.emit(search)
  }
}
