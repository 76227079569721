import { FlatTreeControl } from '@angular/cdk/tree'
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

import { faTimes, faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons'
import { DeleteItemDialogComponent } from 'src/app/@dialogs/delete-item-dialog/delete-item-dialog.component'

import { ColorService } from 'src/app/@services/color.service'
import { ModalService } from 'src/app/@services/modal.service'
import { Color } from 'src/app/@shared/@interfaces/color.model'
import { DialogInstanceModel } from 'src/app/@shared/@models/dialog-instance-model'

interface ColorNode {
  color: Color
  children?: ColorNode[]
}

interface FlatNode {
  expandable: boolean
  name: string
  level: number
}

@Component({
  templateUrl: './colors.component.html',
  styleUrls: ['./colors.component.scss'],
})
export class ColorsComponent implements OnInit {
  public treeNode: ColorNode[] = []

  public treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable
  )

  // Icons
  public faTimes = faTimes
  public faTrashAlt = faTrashAlt
  public faEdit = faEdit

  public isEditing = false
  public isNew = false

  public form: FormGroup

  constructor(
    private colorSerivce: ColorService,
    private fb: FormBuilder,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.buildForm()
    this.getColors()
  }

  private buildForm(color?: Color): void {
    this.form = this.fb.group({
      code: [color?.code || '', [Validators.required]],
      description: [color?.description || '', [Validators.required]],
      code400: [color?.code400 || '', [Validators.required]],
      ...(color ? { id: [color.id] } : {}),
    })
  }

  private getColors(): void {
    this.colorSerivce.getAll().then((colors) => {
      this.treeNode = colors.map((c) => ({ color: c }))
    })
  }

  private createColor(color: Color): void {
    this.colorSerivce.createColor(color).then((c) => {
      const treeNodeAux = [...this.treeNode, { color: c }]
      this.treeNode = treeNodeAux
    })
  }

  private updateColor(color: Color): void {
    this.colorSerivce.updateColor(color).then((c) => {
      const treeNodeAux = this.treeNode.map((node) => {
        if (node.color.id === color.id) {
          return { color: color }
        }
        return node
      })
      this.treeNode = treeNodeAux
    })
  }

  public deleteColor(color: Color): void {
    const deleteDialog = new DialogInstanceModel(DeleteItemDialogComponent, {
      initialState: {
        itemName: `el color ${color.description}`,
      },
      class: 'modal-dialog-centered',
    })

    this.modalService.openModal(deleteDialog).subscribe((result) => {
      if (!result) return

      this.colorSerivce.deleteColor(color.id).then(() => {
        const treeNodeAux = this.treeNode.filter(
          (node) => node.color.id !== color.id
        )
        this.treeNode = treeNodeAux
      })
    })
  }

  public newColor(): void {
    this.buildForm()
    this.isNew = true
    this.isEditing = true
  }

  public editColor(color: Color): void {
    this.buildForm(color)
    this.isNew = false
    this.isEditing = true
  }

  public closeContent(): void {
    this.isEditing = false
  }

  public onSubmit(): void {
    const color = new Color(this.form.value)

    if (this.isNew) this.createColor(color)
    if (!this.isNew) this.updateColor(color)

    this.closeContent()
  }
}
