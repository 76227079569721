<div class="content">
  <div class="content-header">
    <h2>Optimización de barras</h2>

    <fa-icon
      [icon]="faTimes"
      class="content-header__icon"
      (click)="decline()"
    ></fa-icon>
  </div>

  <div class="table-optimization">
    <app-barlength-resume-table
      [modelId]="modelId"
    ></app-barlength-resume-table>
  </div>
</div>
