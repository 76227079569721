<div class="modal-header">
  <h5 class="modal-title pull-left">Fijo</h5>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="modalRef.hide()"
  >
    <img src="../../../assets/img/icons/close.svg" alt="Close modal icon" />
  </button>
</div>

<div class="modal-body content-flex">
  <!-- grid columns -->
  <div class="columns">
    <div class="columns1">
      <label for="huey">Junquillo</label>
      <app-profile-select
        typeProfile="Junquillo"
        width="50"
        [serieId]="serieId || 1"
        [onlyOneProfile]="true"
        (selectedProfileEmitter)="setProfile($event)"
        [selectedProfile]="profile"
      >
      </app-profile-select>
      <app-overlaps
        (overlapsEmitter)="setSuperpositionAndCutVariation($event)"
      ></app-overlaps>
    </div>

    <div class="trash-icon" *ngIf="jonquillo">
      <fa-icon [icon]="faTrash" class="icon trash" (click)="remove()"></fa-icon>
    </div>

    <!-- TODO: Descomentar futuro y implementar el resto -->
    <!-- <div class="columns2">
    <label for="huey">Cristal</label>
    <app-profile-select></app-profile-select>
    <input class="columns_input" name="espesor-marco" type="text" />
    </div>

    <div class="columns3">
    <label for="huey">Panel</label>
    <app-profile-select></app-profile-select>
    <input class="columns_input" name="espesor-marco" type="text" />
    </div>

    <div class="columns4">
    <label for="huey">Goma interna</label>
    <app-profile-select></app-profile-select>
    <input class="columns_input" name="espesor-marco" type="text" />
    </div>

    <div class="columns5">
    <label for="huey">Goma externa</label>
    <app-profile-select></app-profile-select>
    <input class="columns_input" name="espesor-marco" type="text" />
    </div>

    <div class="columns6">
      <label class="columns6_grid" for="huey">Total</label>
      <input class="columns_input" name="espesor-marco" type="text" />
      </div>

      <div class="columns6">
        <label for="huey">Junquillo 2</label>
        <app-profile-select></app-profile-select>
        <input class="columns_input" name="espesor-marco" type="text" />
        </div> -->
  </div>

  <!-- grid columns -->

  <!-- div gordo -->
  <!-- <div class="second-column"> -->
  <!-- div gordo -->

  <!-- superposition -->
  <!-- <div class="superposition">
  <h5>Superposición</h5>
  <div class="superposition-square">

    <div class="superposition_title">
      <p>Lado</p>
      <p>Superposición</p>
    </div>

    <div class="superposition_grid">
      <label for="huey">1</label>
      <label for="huey">0</label>
      <label for="huey">2</label>
      <label for="huey">0</label>
      <label for="huey">3</label>
      <label for="huey">0</label>
      <label for="huey">4</label>
      <label for="huey">0</label>

    </div>

  </div>
  </div> -->

  <!-- superposition -->

  <!-- union -->
  <!-- <div class="union">
      <h5>Uniones junquillos</h5>
      <div class="union-square">
        <div class="union_grid">
          <input type="radio" id="huey" name="drone" value="huey" unchecked />
          <img src="assets/img/icons/junquillo_union1.svg" alt="boton" />
          <input type="radio" id="huey" name="drone" value="huey" unchecked />
          <img src="assets/img/icons/junquillo_union2.svg" alt="boton" />
          <input type="radio" id="huey" name="drone" value="huey" unchecked />
          <img src="assets/img/icons/junquillo_union3.svg" alt="boton" />
          <input type="radio" id="huey" name="drone" value="huey" unchecked />
          <img src="assets/img/icons/junquillo_union4.svg" alt="boton" />

        </div>

      </div>
      </div> -->
  <!-- union -->

  <!-- input -->
  <!-- <div class="form_reference">
      <label for="huey">Lado</label>
      <select class="form_space" name="OS">
        <option value="1">Inferior</option>
        <option value="2">Superior</option>
      </select>
    </div> -->
  <!-- input -->

  <!-- </div> -->
</div>

<!-- Button -->
<div class="modal-footer justify-content-center">
  <button class="btn btn-primary" (click)="save()">Aceptar</button>
</div>
