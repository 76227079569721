import { Injectable } from '@angular/core'
import { v4 } from 'uuid'

import { CoverGaps } from 'src/app/@shared/@interfaces/coverGaps'
import { ProfileCreator } from 'src/app/@shared/@interfaces/profileCreator.model'
import { UnionTypes } from 'src/app/@shared/@interfaces/unionTypes'
import { CovergapSideElement } from 'src/app/@shared/@models/modelsElements/covergapSide-model'
import { Frame } from 'src/app/@shared/@models/modelsElements/frame-model'
import { Gap } from 'src/app/@shared/@models/modelsElements/gap-model'
import { SideOptions } from 'src/app/@shared/@types/side.types'
import { UnionPositions } from 'src/app/@shared/@types/unionPositions.type'
import { UnionService } from '../union.service'
import { CutVariationsDrawerService } from './cut-variations-drawer.service'
import {
  IPseudoFrameCreatorStartExt,
  PseudoFrameDrawerService,
  SidePseudoFrameCreatorStartExt,
} from './pseudo-frame-drawer.service'
import { SuperpositionDrawerService } from './superposition-drawer.service'
import Konva from 'konva'
import { CoverGapElement } from 'src/app/@shared/@models/modelsElements/covergap-model'
import { ProfileDrawerCreatorService } from './profile-drawer-creator.service'
import { Vector2 } from 'three'

@Injectable({
  providedIn: 'root',
})
export class CovergapDrawService {
  private unionTypes: UnionTypes

  constructor(
    private unionService: UnionService,
    private pseudoFrameDrawer: PseudoFrameDrawerService,
    private superpositionsDrawer: SuperpositionDrawerService,
    private cutVariationDrawer: CutVariationsDrawerService,
    private profileDrawer: ProfileDrawerCreatorService
  ) {
    this.unionTypes = this.unionService.unionsIds
  }

  public createCovergaps(
    frame: Frame,
    covergapData: CoverGaps
  ): { external: CoverGapElement; internal: CoverGapElement } {
    if (!frame) return

    const gap = frame.associatedAreas[0].gap
    const { unions } = covergapData

    if (!unions) return

    const profilesCreator = {
      external: {
        top: covergapData?.top?.external,
        bottom: covergapData?.bottom?.external,
        left: covergapData?.left?.external,
        right: covergapData?.right?.external,
      },
      internal: {
        top: covergapData?.top?.internal,
        bottom: covergapData?.bottom?.internal,
        left: covergapData?.left?.internal,
        right: covergapData?.right?.internal,
      },
    }

    const superpositions = {
      top: covergapData.top?.overlap ?? 0,
      bottom: covergapData.bottom?.overlap ?? 0,
      left: covergapData.left?.overlap ?? 0,
      right: covergapData.right?.overlap ?? 0,
    }

    const cutVariations = {
      top: covergapData.top?.cutVariation ?? { id: 1, value: 0 },
      bottom: covergapData.bottom?.cutVariation ?? { id: 1, value: 0 },
      left: covergapData.left?.cutVariation ?? { id: 1, value: 0 },
      right: covergapData.right?.cutVariation ?? { id: 1, value: 0 },
    }

    const clips = {
      top: covergapData?.top?.clip ?? 0,
      bottom: covergapData?.bottom?.clip ?? 0,
      left: covergapData?.left?.clip ?? 0,
      right: covergapData?.right?.clip ?? 0,
    }

    const externalProfileLines = this.createExternals(
      gap,
      frame,
      covergapData,
      unions,
      superpositions,
      cutVariations
    )

    const internalProfileLines = this.createInternals(
      gap,
      frame,
      covergapData,
      unions,
      superpositions,
      cutVariations
    )

    const { offsets: externalOverlapOffset } = externalProfileLines
    const { offsets: internalOverlapOffset } = externalProfileLines

    const externalsElements = this.createCovergapSides(
      gap,
      frame,
      clips,
      superpositions,
      unions,
      cutVariations,
      profilesCreator.external,
      externalProfileLines
    )
    const internalsElements = this.createCovergapSides(
      gap,
      frame,
      clips,
      superpositions,
      unions,
      cutVariations,
      profilesCreator.internal,
      internalProfileLines
    )

    const externalCovergap = this.createCovergapElement(
      frame,
      clips,
      superpositions,
      unions,
      cutVariations,
      externalsElements
    )
    const internalCovergap = this.createCovergapElement(
      frame,
      clips,
      superpositions,
      unions,
      cutVariations,
      internalsElements
    )

    externalCovergap.setOverlapsApplied({
      top: externalOverlapOffset.top.y,
      bottom: externalOverlapOffset.bottom.y,
      left: externalOverlapOffset.left.x,
      right: externalOverlapOffset.right.x,
    })

    internalCovergap.setOverlapsApplied({
      top: internalOverlapOffset.top.y,
      bottom: internalOverlapOffset.bottom.y,
      left: internalOverlapOffset.left.x,
      right: internalOverlapOffset.right.x,
    })

    externalCovergap?.sides?.forEach((s) => s.group.setZIndex(0))
    internalCovergap?.sides?.forEach((s) => s.group.setZIndex(99))

    gap.layer.draw()

    return {
      external: externalCovergap,
      internal: internalCovergap,
    }
  }

  private createCovergapElement(
    frame: Frame,
    clips: { [k in SideOptions]: number },
    overlaps: { [k in SideOptions]: number },
    unions: { [k in UnionPositions]: number },
    cutVariations: {
      [k in SideOptions]: { id: number; value: number; value2?: number }
    },
    elements: { [k in SideOptions]?: CovergapSideElement }
  ): CoverGapElement {
    const covergap = new CoverGapElement({
      uuid: v4(),
      clips,
      cutVariations,
      frame,
      overlaps,
      unions,
    })

    if (elements.top) covergap.setSide('top', elements.top)
    if (elements.bottom) covergap.setSide('bottom', elements.bottom)
    if (elements.left) covergap.setSide('left', elements.left)
    if (elements.right) covergap.setSide('right', elements.right)

    return covergap
  }

  private createCovergapSides(
    gap: Gap,
    frame: Frame,
    clips: { [k in SideOptions]: number },
    overlaps: { [k in SideOptions]: number },
    unions: { [k in UnionPositions]: number },
    cutVariations: {
      [k in SideOptions]: { id: number; value: number; value2?: number }
    },
    profiles: { [k in SideOptions]: ProfileCreator },
    profileLines: { [k in SideOptions]: Konva.Line }
  ): { [k in SideOptions]?: CovergapSideElement } {
    let top = null
    let bottom = null
    let left = null
    let right = null

    if (profileLines.top)
      top = new CovergapSideElement({
        gap,
        frame,
        clip: clips.top ?? 0,
        cutVariation: cutVariations.top,
        overlap: overlaps.top,
        profile: profiles.top,
        profileDraw: profileLines.top,
        scaleFactor: gap.scaleWidth,
        side: 'top',
        thickness: profiles.top.externalFin,
        unions: {
          'left-top': unions['left-top'],
          'right-top': unions['right-top'],
        },
        uuid: v4(),
        x: profileLines.top.points()[0],
        y: profileLines.top.points()[1],
        axis: 'Horizontal',
      })

    if (profileLines.bottom)
      bottom = new CovergapSideElement({
        gap,
        frame,
        clip: clips.bottom ?? 0,
        cutVariation: cutVariations.bottom,
        overlap: overlaps.bottom,
        profile: profiles.bottom,
        profileDraw: profileLines.bottom,
        scaleFactor: gap.scaleWidth,
        side: 'bottom',
        thickness: profiles.bottom.externalFin,
        unions: {
          'left-bottom': unions['left-bottom'],
          'right-bottom': unions['right-bottom'],
        },
        uuid: v4(),
        x: profileLines.bottom.points()[0],
        y: profileLines.bottom.points()[1],
        axis: 'Horizontal',
      })

    if (profileLines.left)
      left = new CovergapSideElement({
        gap,
        frame,
        clip: clips.left ?? 0,
        cutVariation: cutVariations.left,
        overlap: overlaps.left,
        profile: profiles.left,
        profileDraw: profileLines.left,
        scaleFactor: gap.scaleWidth,
        side: 'left',
        thickness: profiles.left.externalFin,
        unions: {
          'left-top': unions['left-top'],
          'left-bottom': unions['left-bottom'],
        },
        uuid: v4(),
        x: profileLines.left.points()[0],
        y: profileLines.left.points()[1],
        axis: 'Vertical',
      })

    if (profileLines.right)
      right = new CovergapSideElement({
        gap,
        frame,
        clip: clips.right ?? 0,
        cutVariation: cutVariations.right,
        overlap: overlaps.right,
        profile: profiles.right,
        profileDraw: profileLines.right,
        scaleFactor: gap.scaleWidth,
        side: 'right',
        thickness: profiles.right.externalFin,
        unions: {
          'right-top': unions['right-top'],
          'right-bottom': unions['right-bottom'],
        },
        uuid: v4(),
        x: profileLines.right.points()[0],
        y: profileLines.right.points()[1],
        axis: 'Vertical',
      })

    return { top, bottom, left, right }
  }

  // TODO: Apply refactor
  private createExternals(
    gap: Gap,
    frame: Frame,
    covergapData: CoverGaps,
    unions: { [k in UnionPositions]: number },
    superpositions: { [k in SideOptions]: number },
    cutVariations: {
      [k in SideOptions]: { id: number; value: number; value2?: number }
    }
  ) {
    let topData: any = {
      width: 0,
    }
    let bottomData: any = {
      width: 0,
    }
    let leftData: any = {
      width: 0,
    }
    let rightData: any = {
      width: 0,
    }

    if (covergapData?.top?.external) {
      topData = this.createTop(gap, frame, covergapData.top.external) ?? {
        width: 0,
      }
    }

    if (covergapData?.bottom?.external) {
      bottomData = this.createBottom(
        gap,
        frame,
        covergapData.bottom.external
      ) ?? { width: 0 }
    }
    if (covergapData?.left?.external) {
      leftData = this.createLeft(gap, frame, covergapData.left.external) ?? {
        width: 0,
      }
    }
    if (covergapData?.right?.external) {
      rightData = this.craeteRight(gap, frame, covergapData.right.external) ?? {
        width: 0,
      }
    }

    const hasFrameSides = {
      top: topData ? true : false,
      bottom: bottomData ? true : false,
      left: leftData ? true : false,
      right: rightData ? true : false,
    }

    const request: IPseudoFrameCreatorStartExt = {
      scaleFactor: gap.scaleWidth,
      has: {
        top: covergapData?.top?.hasExternal && hasFrameSides?.top,
        bottom: covergapData?.bottom?.hasExternal && hasFrameSides?.bottom,
        left: covergapData?.left?.hasExternal && hasFrameSides?.left,
        right: covergapData?.right?.hasExternal && hasFrameSides?.right,
      },
      sides: {
        top: topData,
        bottom: bottomData,
        left: leftData,
        right: rightData,
      },
      unions: unions,
      superpositions: {
        top: covergapData?.top?.overlap,
        bottom: covergapData?.bottom?.overlap,
        left: covergapData?.left?.overlap,
        right: covergapData?.right?.overlap,
      },
      cutVariations: {
        top: covergapData?.top?.cutVariation,
        bottom: covergapData?.bottom?.cutVariation,
        left: covergapData?.left?.cutVariation,
        right: covergapData?.right?.cutVariation,
      },
    }

    const profiles = this.pseudoFrameDrawer.createPseudoFrameFromStartExt(
      request
    )

    // Fix to different unions in frame
    this.offsetsByFrameUnions(frame, profiles, gap.scaleWidth)

    const overlapsOffsetsApplied = this.superpositionsDrawer.applySuperposition(
      unions,
      profiles,
      superpositions,
      gap.scaleWidth
    )

    this.cutVariationDrawer.applyCutVariations(
      cutVariations,
      {
        top: {
          profile: covergapData?.top?.external,
          profileDraw: profiles.top,
        },
        bottom: {
          profile: covergapData?.bottom?.external,
          profileDraw: profiles.bottom,
        },
        left: {
          profile: covergapData?.left?.external,
          profileDraw: profiles.left,
        },
        right: {
          profile: covergapData?.right?.external,
          profileDraw: profiles.right,
        },
      },
      {
        top: covergapData?.top?.external?.externalFin,
        bottom: covergapData?.bottom?.external?.externalFin,
        left: covergapData?.left?.external?.externalFin,
        right: covergapData?.right?.external?.externalFin,
      },
      'Covergap',
      gap.scaleWidth
    )

    return {
      top: profiles?.top ?? null,
      bottom: profiles?.bottom ?? null,
      left: profiles?.left ?? null,
      right: profiles?.right ?? null,
      offsets: overlapsOffsetsApplied,
    }
  }

  private createInternals(
    gap: Gap,
    frame: Frame,
    covergapData: CoverGaps,
    unions: { [k in UnionPositions]: number },
    superpositions: { [k in SideOptions]: number },
    cutVariations: {
      [k in SideOptions]: { id: number; value: number; value2?: number }
    }
  ) {
    let topData: any = { width: 0 }
    let bottomData: any = { width: 0 }
    let leftData: any = { width: 0 }
    let rightData: any = { width: 0 }

    if (covergapData?.top?.internal) {
      topData = this.createTop(gap, frame, covergapData.top.internal) ?? {
        width: 0,
      }
    }
    if (covergapData?.bottom?.internal) {
      bottomData = this.createBottom(
        gap,
        frame,
        covergapData.bottom.internal
      ) ?? { width: 0 }
    }
    if (covergapData?.left?.internal) {
      leftData = this.createLeft(gap, frame, covergapData.left.internal) ?? {
        width: 0,
      }
    }
    if (covergapData?.right?.internal) {
      rightData = this.craeteRight(gap, frame, covergapData.right.internal) ?? {
        width: 0,
      }
    }

    const hasFrameSides = {
      top: topData ? true : false,
      bottom: bottomData ? true : false,
      left: leftData ? true : false,
      right: rightData ? true : false,
    }

    const request: IPseudoFrameCreatorStartExt = {
      scaleFactor: gap.scaleWidth,
      has: {
        top: covergapData?.top?.hasInternal && hasFrameSides?.top,
        bottom: covergapData?.bottom?.hasInternal && hasFrameSides?.bottom,
        left: covergapData?.left?.hasInternal && hasFrameSides?.left,
        right: covergapData?.right?.hasInternal && hasFrameSides?.right,
      },
      sides: {
        top: topData,
        bottom: bottomData,
        left: leftData,
        right: rightData,
      },
      unions: unions,
      superpositions: {
        top: covergapData?.top?.overlap,
        bottom: covergapData?.bottom?.overlap,
        left: covergapData?.left?.overlap,
        right: covergapData?.right?.overlap,
      },
      cutVariations: {
        top: covergapData?.top?.cutVariation,
        bottom: covergapData?.bottom?.cutVariation,
        left: covergapData?.left?.cutVariation,
        right: covergapData?.right?.cutVariation,
      },
    }

    const profiles = this.pseudoFrameDrawer.createPseudoFrameFromStartExt(
      request
    )

    // Fix to different unions in frame
    this.offsetsByFrameUnions(frame, profiles, gap.scaleWidth)

    const overlapsOffsetApplied = this.superpositionsDrawer.applySuperposition(
      unions,
      profiles,
      superpositions,
      gap.scaleWidth
    )

    this.cutVariationDrawer.applyCutVariations(
      cutVariations,
      {
        top: {
          profile: covergapData?.top?.internal,
          profileDraw: profiles?.top,
        },
        bottom: {
          profile: covergapData?.bottom?.internal,
          profileDraw: profiles?.bottom,
        },
        left: {
          profile: covergapData?.left?.internal,
          profileDraw: profiles?.left,
        },
        right: {
          profile: covergapData?.right?.internal,
          profileDraw: profiles?.right,
        },
      },
      {
        top: covergapData?.top?.internal?.externalFin,
        bottom: covergapData?.bottom?.internal?.externalFin,
        left: covergapData?.left?.internal?.externalFin,
        right: covergapData?.right?.internal?.externalFin,
      },
      'Covergap',
      gap.scaleWidth
    )

    return {
      top: profiles?.top ?? null,
      bottom: profiles?.bottom ?? null,
      left: profiles?.left ?? null,
      right: profiles?.right ?? null,
      offsets: overlapsOffsetApplied,
    }
  }

  private createTop(
    gap: Gap,
    frame: Frame,
    profile: ProfileCreator
  ): SidePseudoFrameCreatorStartExt {
    const { frameSideTop } = frame

    if (!frameSideTop || !profile) return
    const { startExt, endExt } = frameSideTop.pointsAsVector()
    const scaleFactor = frameSideTop.scaleFactor

    const startPointExt = startExt
    const topLength = Math.abs(startExt.x - endExt.x) / scaleFactor
    const topWidth = profile?.internalCamera ?? 0

    return {
      length: topLength,
      startPointExt: startPointExt,
      width: topWidth,
      profile,
    }
  }

  private createBottom(
    gap: Gap,
    frame: Frame,
    profile: ProfileCreator
  ): SidePseudoFrameCreatorStartExt {
    const { frameSideBottom } = frame

    if (!frameSideBottom || !profile) return

    const { startExt, endExt } = frameSideBottom.pointsAsVector()
    const scaleFactor = frameSideBottom.scaleFactor

    const startPointExt = startExt

    const bottomLength = Math.abs(startExt.x - endExt.x) / scaleFactor
    const bottomWidth = profile.internalCamera

    return {
      length: bottomLength,
      startPointExt: startPointExt,
      width: bottomWidth,
      profile,
    }
  }

  private createLeft(
    gap: Gap,
    frame: Frame,
    profile: ProfileCreator
  ): SidePseudoFrameCreatorStartExt {
    const { frameSideLeft } = frame

    if (!frameSideLeft || !profile) return

    const { startExt, endExt } = frameSideLeft.pointsAsVector()
    const scaleFactor = frameSideLeft.scaleFactor

    const startPointExt = startExt

    const leftLength = Math.abs(startExt.y - endExt.y) / scaleFactor
    const leftWidth = profile.internalCamera

    return {
      length: leftLength,
      startPointExt: startPointExt,
      width: leftWidth,
      profile,
    }
  }

  private craeteRight(
    gap: Gap,
    frame: Frame,
    profile: ProfileCreator
  ): SidePseudoFrameCreatorStartExt {
    const { frameSideRight } = frame

    if (!frameSideRight || !profile) return

    const scaleFactor = frameSideRight.scaleFactor
    const { startExt, endExt } = frameSideRight.pointsAsVector()

    const rightWidt = profile.internalCamera
    const rightLength = Math.abs(startExt.y - endExt.y) / scaleFactor

    const startPointExt = startExt

    return {
      length: rightLength,
      startPointExt: startPointExt,
      width: rightWidt,
      profile,
    }
  }

  private offsetsByFrameUnions(
    frame: Frame,
    profiles: { [k in SideOptions]?: Konva.Line },
    scaleFactor: number
  ) {
    const { unions } = frame

    const {
      frameSideBottom,
      frameSideTop,
      frameSideLeft,
      frameSideRight,
    } = frame

    const isBiggerFromTopLeftHorizontal =
      unions['left-top'] === this.unionTypes.cut90Horizontal ||
      unions['left-top'] === this.unionTypes.cut90HorizontalwithVertical

    const isBiggerFromBottomLeftHorizontal =
      unions['left-bottom'] === this.unionTypes.cut90Horizontal ||
      unions['left-bottom'] === this.unionTypes.cut90HorizontalwithVertical

    const isBiggerTopRightHorizontal =
      unions['right-top'] === this.unionTypes.cut90Horizontal ||
      unions['right-top'] === this.unionTypes.cut90HorizontalwithVertical

    const isBiggerBottomRightHorizontal =
      unions['right-bottom'] === this.unionTypes.cut90Horizontal ||
      unions['right-bottom'] === this.unionTypes.cut90HorizontalwithVertical

    if (isBiggerFromTopLeftHorizontal) {
      this.addOffsetToVertical(
        profiles.left,
        (frameSideTop.profile.internalCamera ?? 0) * scaleFactor,
        0
      )
    }
    if (isBiggerFromBottomLeftHorizontal) {
      this.addOffsetToVertical(
        profiles.left,
        0,
        (frameSideBottom.profile.internalCamera ?? 0) * scaleFactor
      )
    }
    if (isBiggerTopRightHorizontal) {
      this.addOffsetToVertical(
        profiles.right,
        (frameSideTop.profile.internalCamera ?? 0) * scaleFactor,
        0
      )
    }
    if (isBiggerBottomRightHorizontal) {
      this.addOffsetToVertical(
        profiles.right,
        0,
        (frameSideBottom.profile.internalCamera ?? 0) * scaleFactor
      )
    }

    const isBiggerFromTopLeftVertical =
      unions['left-top'] === this.unionTypes.cut90Vertical ||
      unions['left-top'] === this.unionTypes.cut90VerticalwithHorizontal

    const isBiggerFromBottomLeftVertical =
      unions['left-bottom'] === this.unionTypes.cut90Vertical ||
      unions['left-bottom'] === this.unionTypes.cut90VerticalwithHorizontal

    const isBiggerTopRightVertical =
      unions['right-top'] === this.unionTypes.cut90Vertical ||
      unions['right-top'] === this.unionTypes.cut90VerticalwithHorizontal

    const isBiggerBottomRightVertical =
      unions['right-bottom'] === this.unionTypes.cut90Vertical ||
      unions['right-bottom'] === this.unionTypes.cut90VerticalwithHorizontal

    if (isBiggerFromTopLeftVertical) {
      this.addOffsetToHorizontal(
        profiles.top,
        (frameSideLeft?.profile?.internalCamera ?? 0) * scaleFactor,
        0
      )
    }
    if (isBiggerTopRightVertical) {
      this.addOffsetToHorizontal(
        profiles.top,
        0,
        (frameSideRight?.profile?.internalCamera ?? 0) * scaleFactor
      )
    }

    if (isBiggerFromBottomLeftVertical) {
      this.addOffsetToHorizontal(
        profiles.bottom,
        (frameSideLeft?.profile?.internalCamera ?? 0) * scaleFactor,
        0
      )
    }
    if (isBiggerBottomRightVertical) {
      this.addOffsetToHorizontal(
        profiles.bottom,
        0,
        (frameSideRight?.profile?.internalCamera ?? 0) * scaleFactor
      )
    }
  }

  addOffsetToHorizontal(
    profile: Konva.Line,
    offsetLeft: number,
    offsetRight: number
  ) {
    if (!profile) return

    const {
      startExt,
      startInt,
      endExt,
      endInt,
    } = this.profileDrawer.getPointsAsVectors(profile.points())

    startExt.add(new Vector2(-offsetLeft, 0))
    startInt.add(new Vector2(-offsetLeft, 0))
    endExt.add(new Vector2(offsetRight, 0))
    endInt.add(new Vector2(offsetRight, 0))

    const newPoints = [
      startExt.toArray(),
      startInt.toArray(),
      endInt.toArray(),
      endExt.toArray(),
    ].flat()

    profile.points(newPoints)
  }

  addOffsetToVertical(
    profile: Konva.Line,
    offsetTop: number,
    offsetBottom: number
  ) {
    if (!profile) return

    const {
      startExt,
      startInt,
      endExt,
      endInt,
    } = this.profileDrawer.getPointsAsVectors(profile.points())

    startExt.add(new Vector2(0, -offsetTop))
    startInt.add(new Vector2(0, -offsetTop))
    endExt.add(new Vector2(0, offsetBottom))
    endInt.add(new Vector2(0, offsetBottom))

    const newPoints = [
      startExt.toArray(),
      startInt.toArray(),
      endInt.toArray(),
      endExt.toArray(),
    ].flat()

    profile.points(newPoints)
  }
}
