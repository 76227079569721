<div #profiledialog>
  <div class="modal-header">
    <h5 class="modal-title pull-left">{{ sideName }}</h5>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <img src="../../../assets/img/icons/close.svg" alt="Close modal icon" />
    </button>
  </div>
  <div class="modal-body">
    <div class="profile-toolbar">
      <form [formGroup]="toolbarForm">
        <ul class="nav">
          <!-- IMPORT -->
          <li class="nav-item">
            <a class="nav-link">
              <img src="../../../assets/icons/import.svg" alt="import-icon"
            /></a>
          </li>
          <!-- PROFILE -->
          <li class="nav-item">
            <a class="nav-link">
              <img
                class="profile-icon"
                src="../../../assets/icons/profile.svg"
                alt="profile-icon"
              />
            </a>
          </li>
          <!-- SORT -->
          <li class="nav-item">
            <a class="nav-link"
              ><img src="../../../assets/icons/sort.svg" alt="sort-icon"
            /></a>
          </li>
          <!-- SERIE -->
          <li class="nav-item">
            <label for="serie">Serie</label>
            <select id="serie" class="form-control" formControlName="serie">
              <option *ngFor="let serie of series" [value]="serie.id">
                {{ serie.name }}
              </option>
            </select>
          </li>
          <!-- SUPERPOSITION -->

          <!-- CUT VARIATION -->

          <!-- FILTAER -->
          <li class="nav-item">
            <a class="nav-link" (click)="openFloatinFilter($event)">
              <img
                [src]="
                  filterFlag
                    ? '../../../assets/icons/filter.svg'
                    : '../../../assets/icons/nofilter.svg'
                "
                alt="filtro"
                class="nav-item__img"
              />
            </a>
          </li>
        </ul>
      </form>
    </div>
    <div class="row no-gutters">
      <div
        class="table-container"
        [ngClass]="{ 'col-8': showProfileFlag, 'col-12': !showProfileFlag }"
      >
        <table class="table">
          <thead>
            <tr>
              <th>
                <div class="table-description">
                  <span>Código</span>
                  <app-search-inline
                    (searchEmitter)="searchByCode($event)"
                  ></app-search-inline>
                </div>
              </th>
              <th>
                <div class="table-description">
                  <span>Descripción</span>
                  <app-search-inline
                    (searchEmitter)="searchByDescription($event)"
                  ></app-search-inline>
                </div>
              </th>
              <th>Uso</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let profile of filteredProfileList"
              (click)="toggleProfile(profile)"
              [ngClass]="{ selected: selectedProfile?.id === profile.id }"
            >
              <td>{{ profile.code }}</td>
              <td>{{ profile.description }}</td>
              <td>{{ profile.usesProfile[0]?.useTypeProfile?.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        *ngIf="showProfileFlag"
        class="col-4 image-profile-container"
        [ngClass]="{ 'col-4': showProfileFlag }"
      >
        <img [src]="profileImage" alt="profile-image" />
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-center">
    <button
      class="btn btn-primary"
      (click)="save()"
      [disabled]="!selectedProfile"
    >
      Aceptar
    </button>
    <button
      class="btn btn-primary"
      (click)="save(true)"
      [disabled]="!selectedProfile"
      *ngIf="!onlyOne"
    >
      Aplicar todos
    </button>
  </div>
</div>

<div class="floating-filter fade-in" #floatingselect>
  <form class="floating-filter-form" [formGroup]="filterForm">
    <div class="floating-filter-form__item">
      <input
        type="radio"
        [value]="1"
        name="filter"
        id="allserie"
        formControlName="filter"
      />
      <label for="allserie">Todos los de la serie</label>
    </div>

    <div class="floating-filter-form__item">
      <input
        type="radio"
        [value]="3"
        name="filter"
        id="only-frames"
        formControlName="filter"
      />
      <label for="only-frames">Sólo {{ usePreferred }}</label>
    </div>
  </form>
</div>
