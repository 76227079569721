import { Injectable } from '@angular/core'
import { ToastrService } from 'ngx-toastr'
import { Vector2 } from 'three'
import * as uuidGenerator from 'uuid'

import {
  Area,
  AreaModel,
} from '../../@shared/@models/modelsElements/area-model'
import { ModalService } from '../modal.service'

@Injectable({
  providedIn: 'root',
})
export class AreaDrawService {
  constructor(
    private toastr: ToastrService,
    private modalService: ModalService
  ) {}

  public splitAreaHorizontally(
    leftAreaWidth: number,
    area: AreaModel,
    offset = 0,
    parentContainer: Area
  ): AreaModel {
    const scaleFactor = area.gap.scaleWidth

    const spaceDimensions = {
      width: area.width,
      height: area.height,
    }

    area.resize(leftAreaWidth - offset / 2, area.height)

    const areaContainer1 = AreaDrawService.createAreaContainer(
      parentContainer,
      area.width,
      area.height,
      area.x,
      area.y
    )

    const newAreaPosition = new Vector2(
      area.x + leftAreaWidth + offset / 2,
      area.y
    ).multiplyScalar(scaleFactor)

    const newAreaDimensions = {
      width: spaceDimensions.width - leftAreaWidth - offset / 2,
      height: spaceDimensions.height,
    }

    const newArea = new AreaModel(
      uuidGenerator.v4(),
      newAreaPosition.x,
      newAreaPosition.y,
      newAreaDimensions.width,
      newAreaDimensions.height,
      `A${area.gap.areas.length + 1}`,
      area.layer,
      area.model.id,
      area.gapPosition,
      scaleFactor,
      scaleFactor,
      area.gap
    )

    const areaContainer2 = AreaDrawService.createAreaContainer(
      parentContainer,
      newArea.width,
      newArea.height,
      newArea.x,
      newArea.y
    )

    area.container = areaContainer1
    area.container.setVinculatedArea(area)
    newArea.container = areaContainer2
    newArea.container.setVinculatedArea(newArea)

    parentContainer.setHorizontalContain([areaContainer1, areaContainer2])
    parentContainer.setVinculatedArea(null)

    area.gap.addArea(newArea)
    area.gap.setGapDownZIndex()
    area.cloneAssociatedElements(newArea)

    return newArea
  }

  public splitAreaVertically(
    topAreaWidth: number,
    area: AreaModel,
    offset = 0,
    parentContainer: Area
  ): AreaModel {
    const scaleFactor = area.gap.scaleWidth

    const spaceDimensions = {
      width: area.width,
      height: area.height,
    }

    area.resize(area.width, topAreaWidth - offset / 2)
    const areaContainer1 = AreaDrawService.createAreaContainer(
      parentContainer,
      area.width,
      area.height,
      area.x,
      area.y
    )

    const newAreaPosition = new Vector2(
      area.x,
      area.y + topAreaWidth + offset / 2
    ).multiplyScalar(scaleFactor)

    const newAreaDimensions = {
      width: spaceDimensions.width,
      height: spaceDimensions.height - topAreaWidth - offset / 2,
    }

    const newArea = new AreaModel(
      uuidGenerator.v4(),
      newAreaPosition.x,
      newAreaPosition.y,
      newAreaDimensions.width,
      newAreaDimensions.height,
      `A${area.gap.areas.length + 1}`,
      area.layer,
      area.model.id,
      area.gapPosition,
      scaleFactor,
      scaleFactor,
      area.gap
    )

    const areaContainer2 = AreaDrawService.createAreaContainer(
      parentContainer,
      newArea.width,
      newArea.height,
      newArea.x,
      newArea.y
    )

    area.container = areaContainer1
    area.container.setVinculatedArea(area)
    newArea.container = areaContainer2
    newArea.container.setVinculatedArea(newArea)

    parentContainer.setVerticalContain([areaContainer1, areaContainer2])
    parentContainer.setVinculatedArea(null)

    area.gap.addArea(newArea)
    area.gap.setGapDownZIndex()
    area.cloneAssociatedElements(newArea)

    return newArea
  }

  private static createAreaContainer(
    parent: Area,
    width: number,
    height: number,
    x: number,
    y: number
  ) {
    const area = new Area({
      parent,
      width,
      height,
      x,
      y,
      modelId: parent.modelId,
    })

    return area
  }
}
