<div class="wrapper">
  <div class="tree">
    <div class="header-container">
      <div>
        <h3 class="title">
          <a class="btn" routerLink="/files">
            <mat-icon>arrow_back</mat-icon>
          </a>
          <strong>Colores</strong>
        </h3>
      </div>
      <div class="icons">
        <app-search-inline></app-search-inline>
        <a class="icons-add icon" (click)="newColor()">
          <mat-icon>add</mat-icon>
        </a>
      </div>
    </div>

    <mat-tree
      [dataSource]="treeNode"
      [treeControl]="treeControl"
      *ngIf="treeNode.length > 0"
    >
      <mat-tree-node
        *matTreeNodeDef="let node"
        matTreeNodePadding
        class="mat-tree-node"
      >
        <div class="node-content">
          <div
            class="tree-item-color"
            [ngStyle]="{ backgroundColor: node.color.code }"
          ></div>
          <span class="tree-item__description">
            {{ node.color.description }}
          </span>
        </div>
        <div class="node-icons">
          <fa-icon
            [icon]="faEdit"
            class="icon edit"
            (click)="editColor(node.color)"
          ></fa-icon>
          <fa-icon
            [icon]="faTrashAlt"
            class="icon trash"
            (click)="deleteColor(node.color)"
          ></fa-icon>
        </div>
      </mat-tree-node>
    </mat-tree>
  </div>

  <div class="content" *ngIf="isEditing">
    <div class="content-header">
      <h1 class="title">Datos Color</h1>
      <fa-icon
        [icon]="faTimes"
        class="icon close"
        (click)="closeContent()"
      ></fa-icon>
    </div>

    <form class="content-form" [formGroup]="form">
      <app-input label="Código as400" width="50%" justifyContent="flex-start">
        <input
          type="text"
          class="content-form__input"
          formControlName="code400"
        />
      </app-input>
      <app-input label="Descripción" width="50%" justifyContent="flex-end">
        <input
          type="text"
          class="content-form__input"
          formControlName="description"
        />
      </app-input>
      <app-input
        label="Código Color Hex."
        width="50%"
        justifyContent="flex-end"
      >
        <input
          type="color"
          class="content-form__input"
          formControlName="code"
        />
      </app-input>
    </form>

    <div class="buttons">
      <button class="btn btn-primary btn-block" (click)="onSubmit()">
        {{ isNew ? 'Crear color' : 'Actualizar color' }}
      </button>
    </div>
  </div>
</div>
