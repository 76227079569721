<form class="side-form" [formGroup]="form">
  <div class="side-form-item">
    <span>Tapajuntas externo</span>
    <app-profile-select
      (selectedProfileEmitter)="setExternalProfile($event)"
      [selectedProfile]="externalProfile"
      [serieId]="serieId"
      [typeProfile]="typeProfile"
    ></app-profile-select>
    <input type="checkbox" name="ext" id="external" formControlName="ext" />
  </div>

  <div class="side-form-item">
    <span>Tapajuntas interno</span>
    <app-profile-select
      [selectedProfile]="internalProfile"
      (selectedProfileEmitter)="setInternalProfile($event)"
      [serieId]="serieId"
      [typeProfile]="typeProfile"
    ></app-profile-select>
    <input type="checkbox" name="int" id="internal" formControlName="int" />
  </div>

  <div class="side-form-item">
    <span>Recorte superior</span>
    <input type="number" class="side-form-item__input" formControlName="clip" />
  </div>

  <div class="side-form-item center">
    <app-overlaps
      [overlap]="overlap"
      [cutVariation]="cutVariation"
      (overlapsEmitter)="setOverlaps($event)"
    ></app-overlaps>
  </div>
</form>
