<div class="content-header modal-header">
  <h2>Guillotina</h2>

  <fa-icon
    [icon]="faTimes"
    class="content-header__icon"
    (click)="decline()"
  ></fa-icon>
</div>

<div class="modal-body">


<!-- menu  -->

  <tabset>
    <tab>
      <ng-template tabHeading>Datos primarios</ng-template>
      <div class="content">
      <div [ngTemplateOutlet]="content1"></div>
      </div>
    </tab>

    <tab>
      <ng-template tabHeading>Construcción avanzada</ng-template>
      <div class="content">
        <div [ngTemplateOutlet]="content2"></div>
      </div>
    </tab>


  </tabset>

<!-- menu  -->

<!-- primera pantalla -->
<ng-template #content1>

  <div class="columns">

  <div class="first-column">

    <div class="first-column_box1">
    <input type="radio" id="union1" name="drone" value="huey" unchecked />
    <img src="assets/img/icons/junquillo_union1.svg" alt="boton" />
    <input type="radio" id="union2" name="drone" value="huey" unchecked />
    <img src="assets/img/icons/junquillo_union2.svg" alt="boton" />
    <input type="radio" id="union3" name="drone" value="huey" unchecked />
    <img src="assets/img/icons/junquillo_union3.svg" alt="boton" />
    </div>

    <div class="first-column_box2">
      <input  class="checkbox-size" type="checkbox" id="cbox1" value="first_checkbox">
      <label for="marco-sup">Marco superior</label>
      <app-profile-select></app-profile-select>
      <input class="checkbox-size" type="checkbox" id="cbox2" value="first_checkbox">
      <label for="marco-der">Marco derecho</label>
      <app-profile-select></app-profile-select>
      <input class="checkbox-size" type="checkbox" id="cbox3" value="first_checkbox">
      <label for="marco-inf">Marco inferior</label>
      <app-profile-select></app-profile-select>
      <input class="checkbox-size" type="checkbox" id="cbox4" value="first_checkbox">
      <label for="marco-izq">Marco izquierdo</label>
      <app-profile-select></app-profile-select>
      <input class="checkbox-size" type="checkbox" id="cbox5" value="first_checkbox">
      <label for="perfil">Perfil igual</label>
   </div>
    
   <div class="first-column_box3">
     <div class="first-column_box3_grid">   
      <label for="perfil">Var. superposición nodo central</label>
      <input class="box_space" name="alturamanilla" type="text" />
    </div>
    <div class="first-column_box3_img">
    <img src="../../../assets/img/barras.svg" alt="Close modal icon" />
  </div>
   </div>

</div>

   <div class="second-column">

   <div class="second-column_box1">
    <input class="checkbox-size" type="checkbox" id="cbox6" value="first_checkbox">
    <label for="perfil">Canaleta</label>
    <input class="box_space" name="alturamanilla" type="text" />
    <input class="checkbox-size" type="checkbox" id="cbox7" value="first_checkbox">
    <label for="perfil">Recolector condensación</label>
    <input class="box_space" name="alturamanilla" type="text" />
    </div>

<div class="second-column_box2">
    <h5>Datos hojas</h5>

  <div class="second-column_box3">
    <input class="checkbox-size" type="checkbox" id="cbox4" value="first_checkbox">
      <label for="marco-izq">Hojas</label>
      <app-profile-select></app-profile-select>
    <input class="checkbox-size" type="checkbox" id="cbox6" value="first_checkbox">
    <label for="perfil">Perfil enganche hojas</label>
    <input class="box_space" name="alturamanilla" type="text" />
  






 </div>

</div>

</div>        


  


</div>
</ng-template>

<!-- primera pantalla -->

<!-- segunda pantalla -->
<ng-template #content2>
  <div class="flex">
    <div class="first">
    
      <div class="lado-superior">
      <div>
        <label for="huey">Lado superior</label>
        <br />
        <app-overlaps></app-overlaps>
      </div>
      <div class="space">
      <app-profile-select></app-profile-select></div>
    </div>
    
    <div class="lado-izquierdo">
      <div>
        <label for="huey">Lado izquierdo</label>
        <br />
        <app-overlaps></app-overlaps>
      </div>
      <div class="space">
      <app-profile-select></app-profile-select></div>
    </div>
    
    <div class="square"></div>
    
    <div class="lado-derecho">
      <div>
        <label for="huey">Lado derecho</label>
        <br />
        <app-overlaps></app-overlaps>
      </div>
      <div class="space">
      <app-profile-select></app-profile-select></div>
    </div>
    
    <div class="lado-inferior">
      <div>
        <label for="huey">Lado inferior</label>
        <br />
        <app-overlaps></app-overlaps>
      </div>
      <div class="space">
      <app-profile-select></app-profile-select></div>
    </div>
    </div>
    <div class="second">
      <div class="box-10">
        <input class="checkbox-size" type="checkbox" id="cbox1" value="first_checkbox">
      <div class="separation"><label for="huey">Perfiles asociados hojas</label>
      <input class="box_space" name="alturamanilla" type="text" />
      </div> </div>
    
       <div class="box-11">
        <img src="../../../assets/img/barras.svg" alt="Close modal icon" />
        <input class="box_space3" name="alturamanilla" type="text" />
       </div>
    
    </div>
    
    </div>
    

</ng-template>

<!-- segunda pantalla -->


</div>




<div class="modal-footer">
  <button class="btn btn-primary" (click)="confirm()">Aceptar</button>
</div>
