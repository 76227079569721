<div class="accesories">
  <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="medium"
    color="#fff"
    type="square-jelly-box"
    [fullScreen]="true"
    ><p style="color: white">Cargando...</p></ngx-spinner
  >

  <div class="tree">
    <app-accesories-tree
      [treeNode]="treeNode"
      [selectedAccesory]="selectedAccesory"
      (selectAccesoryEmitter)="onSelectAccesory($event)"
      (searchEmitter)="onSearch($event)"
    ></app-accesories-tree>
  </div>

  <div class="content" *ngIf="selectedAccesory">
    <h1 class="title">Datos Técnicos</h1>

    <div class="accesory-information">
      <div class="accesory-information__item">
        <app-input label="Descripción">
          <p class="item__value">{{ selectedAccesory.denomination }}</p>
        </app-input>
      </div>

      <div class="accesory-information__item">
        <app-input label="Código">
          <p class="item__value">{{ selectedAccesory.codRef }}</p>
        </app-input>
      </div>

      <div class="accesory-information__item">
        <app-input label="Categoría">
          <p class="item__value">{{ selectedAccesory.company }}</p>
        </app-input>
      </div>

      <div class="accesory-information__item">
        <app-input label="Peso">
          <p class="item__value">{{ selectedAccesory.weight }}</p>
        </app-input>
      </div>

      <div class="accesory-information__item">
        <app-input label="Unidades por caja">
          <p class="item__value">{{ selectedAccesory.undBox }}</p>
        </app-input>
      </div>
    </div>
  </div>
</div>
