<div class="fade-in">
  <table class="table">
    <thead>
      <tr>
        <th scope="col">Código</th>
        <th scope="col">Descripción</th>
        <th scope="col">Función</th>
        <th scope="col">
          <div class="icons">
            <fa-icon
              [icon]="faPlus"
              class="icon add"
              (click)="addAccessoriy()"
            ></fa-icon>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let acc of filteredAccesories">
        <td>{{ acc?.accessory?.codRef }}</td>
        <td>{{ acc?.accessory?.denomination }}</td>
        <td>{{ acc?.formula }}</td>
        <td>
          <div class="icons">
            <fa-icon
              [icon]="faEdit"
              class="icon edit"
              (click)="editAccesory(acc)"
            ></fa-icon>
            <fa-icon
              [icon]="faTrashAlt"
              class="icon delete"
              (click)="removeAccessory(acc)"
            ></fa-icon>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
