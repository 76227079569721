import { Coordinates } from 'src/app/@shared/@interfaces/coordinates'
import { Entity } from 'src/app/@shared/@interfaces/dxf-content'
import { DXFAction } from './DXFAction'

export class DXFRotateAction extends DXFAction {
  constructor(private angle: number) {
    super()
  }

  public apply(entities: Entity[]): Entity[] {
    const newEntitiesVertices: any = []

    const entityType: { [k: string]: (entity: Entity) => void } = {
      LINE: (entity: Entity) => {
        newEntitiesVertices.push(this.rotateVertex(this.angle, entity))
      },
      LWPOLYLINE: (entity: Entity) => {
        newEntitiesVertices.push(this.rotateVertex(this.angle, entity))
      },
      POLYLINE: (entity: Entity) => {
        newEntitiesVertices.push(this.rotateVertex(this.angle, entity))
      },
      ARC: (entity: Entity) => {
        newEntitiesVertices.push(this.rotateArc(this.angle, entity))
      },
      CIRCLE: (entity: Entity) => {
        newEntitiesVertices.push(this.rotateCenter(this.angle, entity))
      },
    }

    entities.forEach((entity) => {
      const type: any = entity.type
      if (!type) return

      const action: (entity: Entity) => void = entityType[type]

      action(entity)
    })

    return newEntitiesVertices
  }

  public rotateVertex(angle: number, entity: Entity): Entity {
    const newEntity = { ...entity }
    const vertices: Coordinates[] = []

    entity.vertices?.forEach((vertex, i) => {
      vertices.push(this.rotateVector(angle, vertex))
    })
    newEntity.vertices = vertices
    return newEntity
  }

  public rotateArc(angle: number, entity: Entity): Entity {
    const newEntity = { ...this.rotateCenter(angle, entity) }
    const rotationAngle = angle
    newEntity.startAngle = newEntity.startAngle
      ? newEntity.startAngle + rotationAngle
      : rotationAngle
    newEntity.endAngle = newEntity.endAngle
      ? newEntity.endAngle + rotationAngle
      : rotationAngle
    return newEntity
  }

  public rotateCenter(angle: number, entity: Entity): Entity {
    const newEntity = { ...entity }
    const center: Coordinates = this.rotateVector(
      angle,
      entity.center ?? { x: 0, y: 0 }
    )
    newEntity.center = center
    return newEntity
  }

  public rotateVector(angle: number, vertex: Coordinates): Coordinates {
    const rotationAngle = angle
    const newX =
      vertex.x * Math.cos(rotationAngle) - vertex.y * Math.sin(rotationAngle)
    const newY =
      vertex.x * Math.sin(rotationAngle) + vertex.y * Math.cos(rotationAngle)
    if (vertex.bulge) {
      return {
        x: newX,
        y: newY,
        z: 0,
        bulge: vertex.bulge,
      }
    }

    return {
      x: newX,
      y: newY,
      z: 0,
    }
  }
}
