<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white">Cargando...</p>
</ngx-spinner>
<div id="pdfPage" *ngIf="model">
  <div class="logo-img-container mx-auto">
    <img src="../../../assets/img/galisur_logo.png" alt="logo" />
  </div>
  <hr class="line-blue" />
  <div class="descriptions col-12">
    <div class="descriptions col-6">
      <p>Estimados Galisur 9</p>
      <p>Polígono Industrial Las Capellanías</p>
      <p>Calle Hojalateros 15 (Parcela 121)</p>
      <p>1005 Cáceres</p>
    </div>

    <div class="descriptions col-6 end">
      <p>ALUMINIOS GALISUR</p>
      <p>P.I. Fridex C/ Siete, Parc. 19</p>
      <p>41500 Alcalá de Guadaira</p>
      <p>Sevilla, España</p>
      <p>955 63 01 38 / 955 63 00 69</p>
      <p>www.galisur.es</p>
    </div>
  </div>
  <!--Model-->
  <div class="descriptions col-12 border">
    <div class="dimensions col-6">
      <h4 class="model-name">Modelo {{model?.name}}:</h4>
      <img class="card-img-top" [src]="url+model?.id+'/preview'" [alt]="model?.name"
        onerror="this.src='../../../assets/img/galisur_logo.png';" />
    </div>
    <div class="dimensions col-6">
      <p>SERIE: {{model?.serie?.name}}</p>
      <p>DIMENSIONES: {{model?.width}} x {{model?.height}}</p>
      <p>COLOR: SIN ANONIZAR</p>
    </div>
  </div>
  <!--Areas-->
  <div *ngFor="let area of model?.areas; let i = index">
    <div class="descriptions col-12 border grey">
      <div class="dimensions col-6">
        <h4 class="model-name">AREA: {{i + 1}}</h4>
      </div>
      <div class="dimensions col-6">
        <p>DIMENSIONES: {{area?.width}} x {{area?.height}}</p>
      </div>
    </div>
    <!--Marcos-->
    <div *ngFor="let frame of area?.frames; let i2 = index">
      <div class="descriptions col-12 border" (click)="data(frame)">
        <div class="dimensions col-6">
          <h4 class="model-name">Marco Izquierdo</h4>
          <img class="card-img-top" [src]="url2+frame.frame?.leftSide?.profile?.id+'/preview'"
            [alt]="area?.frames[0]?.frame?.name" onerror="this.src='../../../assets/img/galisur_logo.png';" />
        </div>
        <div class="dimensions col-6">
          <p>SERIE: {{ frame.frame?.leftSide?.profile?.serie?.name }}</p>
          <p>DENOMINACIÓN: {{ frame.frame?.leftSide?.profile?.description }}</p>
          <p>CÓDIGO: {{ frame.frame?.leftSide?.profile?.code }}</p>
        </div>
      </div>
      <div class="descriptions col-12 border" (click)="data(frame)">
        <div class="dimensions col-6">
          <h4 class="model-name">Marco Derecho</h4>
          <img class="card-img-top" [src]="url2+frame.frame?.rigthSide?.profile?.id+'/preview'"
            [alt]="area?.frames[0]?.frame?.name" onerror="this.src='../../../assets/img/galisur_logo.png';" />
        </div>
        <div class="dimensions col-6">
          <p>SERIE: {{ frame.frame?.rigthSide?.profile?.serie?.name }}</p>
          <p>DENOMINACIÓN: {{ frame.frame?.rigthSide?.profile?.description }}</p>
          <p>CÓDIGO: {{ frame.frame?.rigthSide?.profile?.code }}</p>
        </div>
      </div>
      <div class="descriptions col-12 border" (click)="data(frame)">
        <div class="dimensions col-6">
          <h4 class="model-name">Marco Arriba</h4>
          <img class="card-img-top" [src]="url2+frame.frame?.topSide?.profile?.id+'/preview'"
            [alt]="area?.frames[0]?.frame?.name" onerror="this.src='../../../assets/img/galisur_logo.png';" />
        </div>
        <div class="dimensions col-6">
          <p>SERIE: {{ frame.frame?.topSide?.profile?.serie?.name }}</p>
          <p>DENOMINACIÓN: {{ frame.frame?.topSide?.profile?.description }}</p>
          <p>CÓDIGO: {{ frame.frame?.topSide?.profile?.code }}</p>
        </div>
      </div>
      <div class="descriptions col-12 border" (click)="data(frame)">
        <div class="dimensions col-6">
          <h4 class="model-name">Marco Abajo</h4>
          <img class="card-img-top" [src]="url2+frame.frame?.bottomSide?.profile?.id+'/preview'"
            [alt]="area?.frames[0]?.frame?.name" onerror="this.src='../../../assets/img/galisur_logo.png';" />
        </div>
        <div class="dimensions col-6">
          <p>SERIE: {{ frame.frame?.bottomSide?.profile?.serie?.name }}</p>
          <p>DENOMINACIÓN: {{ frame.frame?.bottomSide?.profile?.description }}</p>
          <p>CÓDIGO: {{ frame.frame?.bottomSide?.profile?.code }}</p>
        </div>
      </div>
    </div>
  </div>
</div>