<div class="cover-gaps-content">
  <div class="union-corner left top">
    <img
      [src]="unionImage('left-top')"
      alt=""
      (click)="openUnionSelector($event, 'left-top')"
    />
  </div>

  <app-cover-gap-side-form
    (coverGapFormEmitter)="setCoverGap($event, 'top')"
    [externalProfile]="coverGaps?.top?.external"
    [internalProfile]="coverGaps?.top?.internal"
    [overlap]="overlaps.top"
    [cutVariation]="cutVariations.top"
    class="cover-gap-side top"
    [serieId]="serieId"
    side="top"
  ></app-cover-gap-side-form>

  <div class="union-corner right top">
    <img
      [src]="unionImage('right-top')"
      alt=""
      (click)="openUnionSelector($event, 'right-top')"
    />
  </div>

  <app-cover-gap-side-form
    (coverGapFormEmitter)="setCoverGap($event, 'right')"
    [externalProfile]="coverGaps?.right?.external"
    [internalProfile]="coverGaps?.right?.internal"
    [overlap]="overlaps.right"
    [cutVariation]="cutVariations.right"
    class="cover-gap-side right"
    [serieId]="serieId"
    side="right"
  ></app-cover-gap-side-form>

  <div class="central-rect">
    <app-scheme-side-rect
      [sides]="isSidesSelected('external')"
      [internalSides]="isSidesSelected('internal')"
      [hasInternal]="true"
    ></app-scheme-side-rect>
  </div>

  <app-cover-gap-side-form
    (coverGapFormEmitter)="setCoverGap($event, 'left')"
    [externalProfile]="coverGaps?.left?.external"
    [internalProfile]="coverGaps?.left?.internal"
    [overlap]="overlaps.left"
    [cutVariation]="cutVariations.left"
    class="cover-gap-side left"
    [serieId]="serieId"
    side="left"
  ></app-cover-gap-side-form>

  <div class="union-corner left bottom">
    <img
      [src]="unionImage('left-bottom')"
      alt=""
      (click)="openUnionSelector($event, 'left-bottom')"
    />
  </div>

  <app-cover-gap-side-form
    (coverGapFormEmitter)="setCoverGap($event, 'bottom')"
    [externalProfile]="coverGaps?.bottom?.external"
    [internalProfile]="coverGaps?.bottom?.internal"
    [overlap]="overlaps.bottom"
    [cutVariation]="cutVariations.bottom"
    side="bottom"
    [serieId]="serieId"
    class="cover-gap-side bottom"
  ></app-cover-gap-side-form>

  <div class="union-corner right bottom">
    <img
      [src]="unionImage('right-bottom')"
      alt=""
      (click)="openUnionSelector($event, 'right-bottom')"
    />
  </div>
</div>

<div class="union-selector-container fade-in" #unionSelector>
  <app-unions-column
    [withCustom]="false"
    [side]="selectedSide"
    (unionSelectEmitter)="chooseUnion($event)"
  ></app-unions-column>
</div>
