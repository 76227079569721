import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { Panel, PanelCreator } from '../@shared/@interfaces/panel'

@Injectable({
  providedIn: 'root',
})
export class PanelService {
  private apiUrl = environment.apiUrl

  constructor(private http: HttpClient) { }

  public createPanel(
    panel: PanelCreator
  ): Observable<number> {
    return this.http
      .post<number>(`${this.apiUrl}panels`, panel)
  }

  public getPanels(): Observable<Panel[]> {
    return this.http.get<Panel[]>(`${this.apiUrl}panels`)
  }

  public updatePanel(
    panel: PanelCreator,
    id: number
  ): Observable<any> {
    return this.http.put(`${this.apiUrl}panels/${id}`, panel)
  }

  public deletePanel(panel: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}panels/${panel}`)
  }
}
