import { Component, OnDestroy, OnInit } from '@angular/core'
import { faFile } from '@fortawesome/free-regular-svg-icons'
import { Observable, Subscription } from 'rxjs'
import { AuthStatusService } from 'src/app/@services/auth-status.service'
import { AuthService } from 'src/app/@services/auth.service'
import { User } from 'src/app/core/models/User.model'
import { Nullable } from 'src/app/core/types/Nullable.type'
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  public faFile = faFile

  public isAuthenticated$: Observable<boolean>

  private userSubscription: Subscription
  public user: Nullable<User>

  constructor(
    private authStatus: AuthStatusService,
    private authService: AuthService
  ) {
    this.isAuthenticated$ = this.authStatus.isAuthenticated$
  }

  ngOnInit(): void {
    this.subscribeToUser()
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe()
  }

  public onLogout(): void {
    this.authService.logout()
  }

  private subscribeToUser(): void {
    this.userSubscription = this.authStatus.user$.subscribe({
      next: (u) => {
        this.user = u
      },
    })
  }
}
