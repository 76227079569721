// ANGULAR
import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
// TOOLS
import { from, Observable, of } from 'rxjs'
import { environment } from '../../environments/environment'
// INTERFACES
import { ProfileCreator } from '../@shared/@interfaces/profileCreator.model'
import { UseProfile } from '../@shared/@interfaces/useProfile'
import { CutOptimizationCreator } from '../@shared/@interfaces/cutOptimization'
import { AccessoryCreator } from '../@shared/@interfaces/accesory'
import { DxfContent } from '../@shared/@interfaces/dxf-content'
import { DXF } from '../presentation/shared/models/DXF.model'
import { map, mergeMap, toArray } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private apiUrl = environment.apiUrl

  constructor(private http: HttpClient) { }

  getProfiles(requestObject: RequestGetProfiles): Observable<any> {
    const requestKeys = Object.keys(requestObject)

    let params = ''
    requestKeys.forEach((key) => (params += `${key}=${requestObject[key]}&`))

    return this.http.get(`${this.apiUrl}profiles?${params}`)
  }

  public getProfileByID(id: number): Observable<any> {
    return this.http.get(`${this.apiUrl}profiles/${id}`)
  }

  public getProfileDxf(id: number): Observable<Blob> {
    return this.http.get(`${this.apiUrl}dxf/profile/${id}`, {
      responseType: 'blob',
    })
  }

  public getProfileParsedDxf(id: number): Observable<DxfContent> {
    return this.http.get<DxfContent>(`${this.apiUrl}profiles/${id}/dxf-parse`)
  }

  public getProfilePng(id: number): Observable<Blob> {
    return this.http.get(`${this.apiUrl}profiles/${id}/preview`, {
      responseType: 'blob',
    })
  }

  public createProfile(
    profile: ProfileCreator,
    uses: Array<UseProfile>,
    accesories: Array<AccessoryCreator>,
    cutOptimization: CutOptimizationCreator
  ): Observable<any> {
    const requestObject = {
      ...profile,
      useProfile: uses,
      profileAccessories: accesories,
      cutsProfile: cutOptimization,
    }
    return this.http.post(`${this.apiUrl}profiles`, requestObject)
  }

  public createProfileParsedDxf(
    id: number,
    dxfFileObject: any
  ): Observable<DxfContent> {
    return this.http.post<DxfContent>(
      `${this.apiUrl}profiles/${id}/dxf-parse`,
      dxfFileObject
    )
  }

  public persistRelationsFor(
    id: number,
    dxfsList: DXF[]
  ): Observable<any> {
    return from(dxfsList).pipe(
      mergeMap(dxf => this.http.post<any>(
        `${this.apiUrl}profiles/disasembled`,
        {
          profileID: id,
          disasembledProfileID: dxf.uuid.toString(),
          offset: dxf.offset,
        }
      )),
      toArray()
    );
  }

  public getDisasembledProfilesForProfile(id: number) {
    return this.http.get(`${this.apiUrl}profiles/disasembled/${id}`)
  }

  public createProfilePng(id: number, dxfBlob: any): Observable<any> {
    const formData = new FormData()
    formData.append('file', dxfBlob, `${id}.png`)
    return this.http.post(`${this.apiUrl}profiles/${id}/preview`, formData)
  }

  public updateProfile(id: number, profile: ProfileCreator): Observable<any> {
    return this.http.put(`${this.apiUrl}profiles/${id}`, profile)
  }

  public deleteProfile(id: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}profiles/${id}`)
  }

  public getColors(): Observable<any> {
    return this.http.get(`${this.apiUrl}colors`)
  }

  public getAssemblies(): Observable<any> {
    return this.http.get(`${this.apiUrl}assemblies`)
  }

  public getMaterials(): Observable<any> {
    return this.http.get(`${this.apiUrl}materials`)
  }
}

export interface RequestGetProfiles {
  serie?: number
  use?: string
  overlap?: number
  cutVariation?: number
  opening?: number
}
