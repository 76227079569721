import { FilesMenuOptionsComponent } from './@components/files-menu-options/files-menu-options.component'
import { ProfileManagementComponent } from './@components/profile-management/profile-management.component'
// ANGULAR
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
// COMPONENTS
import { AccesoriesGroupsComponent } from './@pages/accesories-groups/accesories-groups.component'
import { AccesoriesComponent } from './@pages/accesories/accesories.component'
import { ColorsComponent } from './@pages/colors/colors.component'
import { CrystalsComponent } from './@pages/crystals/crystals.component'
import { FormulasComponent } from './@pages/formulas/formulas.component'
import { HomePageComponent } from './@pages/home-page/home-page.component'
import { ModelPageComponent } from './@pages/model-page/model-page.component'
import { PanelsComponent } from './@pages/panels/panels.component'
import { PdfsComponent } from './@pages/pdfs/pdfs.component'

const routes: Routes = [
  {
    path: 'home',
    component: HomePageComponent,
  },
  {
    path: 'files',
    component: FilesMenuOptionsComponent,
  },
  {
    path: 'model',
    component: ModelPageComponent,
  },
  {
    path: 'profiles',
    component: ProfileManagementComponent,
  },
  {
    path: 'crystals',
    component: CrystalsComponent,
  },
  {
    path: 'panels',
    component: PanelsComponent,
  },
  {
    path: 'formulas',
    component: FormulasComponent,
  },
  {
    path: 'accesories',
    component: AccesoriesComponent,
  },
  {
    path: 'accesories-groups',
    component: AccesoriesGroupsComponent,
  },
  {
    path: 'colors',
    component: ColorsComponent,
  },
  {
    path: 'pdf/:id',
    component: PdfsComponent,
  },

  // TODO: refactor to modularize app
  // {
  //   path: 'v2',
  //   children: [
  //     {
  //       path: 'model',
  //       loadChildren: () =>
  //         import('./presentation/model/model.module').then(
  //           (m) => m.ModelModule
  //         ),
  //     },
  //     {
  //       path: 'files',
  //       loadChildren: () =>
  //         import('./presentation/files/files.module').then(
  //           (m) => m.FilesModule
  //         ),
  //     },
  //     {
  //       path: '**',
  //       pathMatch: 'full',
  //       redirectTo: 'files',
  //     },
  //   ],
  // },

  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full',
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
