<div class="dxf-editor-container">
  <div class="editor-icons">
    <input
      type="file"
      class="editor-icons__folder"
      id="0"
      #dxfSelector
      (change)="selectDxf($event)"
    />
    <button type="button" class="tool-btn" (click)="dxfSelector.click()">
      <fa-icon
        [icon]="faFolder"
        class="editor-icons__icon"
        tooltip="cargar archivo"
        placement="left"
      ></fa-icon>
    </button>
    <button
      *ngFor="let button of toolButtons; index as i"
      type="button"
      class="tool-btn"
      (click)="button.toolClick(button)"
      [disabled]="noDxf"
    >
      <fa-icon
        [icon]="button.icon"
        class="editor-icons__icon"
        tooltip="{{ button.label }}"
        placement="left"
      ></fa-icon>
    </button>
  </div>

  <div class="editor-content">
    <span class="editor-content-direction exterior">Exterior</span>
    <span class="editor-content-direction inside">Interior</span>
    <span class="editor-content-direction vertical reverse">Cristal</span>
    <span class="editor-content-direction vertical">Pared</span>
    <div #dxfViewer>
      <ng-content></ng-content>
    </div>
  </div>
</div>
