import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { NgxSpinnerService } from 'ngx-spinner'
import { ToastrService } from 'ngx-toastr'
import { ModelService } from 'src/app/@services/model.service'
import { NewModelModel } from 'src/app/@shared/@models/new-model-model'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-pdfs',
  templateUrl: './pdfs.component.html',
  styleUrls: ['./pdfs.component.scss'],
})
export class PdfsComponent implements OnInit {
  public model: NewModelModel
  public url = `${environment.apiUrl}models/`
  public url2 = `${environment.apiUrl}profiles/`
  constructor(
    private modelService: ModelService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.spinner.show()
    this.getModel()
  }

  private getModel() {
    const id = Number(this.router.url.split('pdf/')[1])
    this.modelService.getModel(id).subscribe(
      (model) => {
        this.model = model
        this.spinner.hide()
      },
      (err) => {
        this.toastr.error(
          'Ha habido un error en la generación pdf, por favor vuelva a intentarlo en unos minutos.'
        )
        this.spinner.hide()
        this.router.navigateByUrl('/home')
      }
    )
  }

  public data(da: any) {}
}
