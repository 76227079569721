import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { debounceTime } from 'rxjs/operators'
import { OpeningTableItem } from 'src/app/@shared/@interfaces/openingObject'
import { TipupLeaftOpening } from 'src/app/@shared/@models/modelsElements/door-window-leaft-model'

@Component({
  selector: 'app-opening-table-leaft',
  templateUrl: './opening-table.component.html',
  styleUrls: ['./opening-table.component.scss'],
})
export class OpeningTableLeaftComponent implements OnInit, OnChanges {
  @Input() public areaWidth = 0

  @Input() public openingFromSeleted = []

  @Input()
  public openingsLeafts: Array<{ name?: string; id: number }> = []

  @Input() public selectedOpeningId: number

  @Output() private openingLeaftsEmitter = new EventEmitter<
    OpeningTableItem[]
  >()

  @Output() private selectedOpeningIdEmitter = new EventEmitter<number | null>()

  public formArray: FormArray

  public selectedOpeningIndex: number

  constructor(private fb: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    const hasChangeOpening =
      changes.selectedOpeningId?.previousValue !==
      changes.selectedOpeningId?.currentValue

    if (hasChangeOpening) this.changeOpening(this.selectedOpeningId)
  }

  ngOnInit(): void {
    this.buildForm()
    this.buildFromSelected()
  }

  // Forms
  private buildForm(): void {
    this.formArray = this.fb.array([])

    this.formArray.valueChanges.pipe(debounceTime(300)).subscribe((value) => {
      this.emit()
    })
  }
  private addGrupToFormArray(width?: number, isMain = false): void {
    this.updateLeaftWidthEqually()

    width = width ?? this.areaWidth / (this.formArray.length + 1)

    const newGroup = this.fb.group({
      width: [width, [Validators.required]],
      isLock: [false, [Validators.required]],
      isMain: [isMain, [Validators.required]],
    })

    const index = this.formArray.length
    newGroup.controls.width.valueChanges
      .pipe(debounceTime(400))
      .subscribe((width) => {
        this.updateLeaftsWidth(index)
      })

    this.formArray.push(newGroup)
  }
  private removeGroupToFormArray(index: number): void {
    this.formArray.removeAt(index)

    const width = this.areaWidth / this.formArray.length

    if (this.formArray.length > 0) {
      this.formArray.controls.forEach((g) => {
        g.get('width').setValue(width)
      })
    }
  }

  private updateLeaftsWidth(avoidIndex: number) {
    const groups = this.formArray.controls

    if (avoidIndex === this.formArray.length - 1) {
      const avoidIndexWidth = groups[avoidIndex].get('width').value
      const rest = this.areaWidth - avoidIndexWidth
      const restPartial = rest / (this.formArray.length - 1)

      groups.forEach((c, i) => {
        if (i === avoidIndex) return
        c.get('width').setValue(restPartial, { emitEvent: false })
      })

      return
    }

    if (this.formArray.length === 1) {
      this.formArray.at(avoidIndex).get('width').setValue(this.areaWidth, {
        emitEvent: false,
      })
      return
    }

    let restAreaWidth = this.areaWidth

    this.formArray.controls.forEach((c, index) => {
      if (index <= avoidIndex) {
        restAreaWidth -= c.value.width
        return
      }

      const restLeaftWidth =
        restAreaWidth / (this.formArray.length - avoidIndex - 1)

      c.get('width').setValue(restLeaftWidth, { emitEvent: false })
    })
  }

  private updateLeaftWidthEqually(): void {
    const width = this.areaWidth / (this.formArray.length + 1)

    if (this.formArray.length > 0) {
      this.formArray.controls.forEach((g) => {
        g.get('width').setValue(width)
      })
    }
  }

  public getFormGruop(index: number): FormGroup {
    return this.formArray.at(index) as FormGroup
  }

  // Leaft
  public addLeaft(id = 1, width?: number, isMain = false) {
    this.openingsLeafts.push({
      id,
    })

    this.addGrupToFormArray(width, isMain)
  }

  public removeLeaft(): void {
    if (this.openingsLeafts.length <= 1) return
    this.openingsLeafts.pop()
    this.removeGroupToFormArray(this.openingsLeafts.length)
  }

  // Opening
  public selectOpening(index: number): void {
    const isSame = this.selectedOpeningIndex === index
    this.selectedOpeningIndex = isSame ? null : index
    this.selectedOpeningId = isSame ? null : this.openingsLeafts[index].id

    this.emitOpeningSelectedId()
  }
  public changeOpening(id: number): void {
    if (this.openingsLeafts[this.selectedOpeningIndex]) {
      this.openingsLeafts[this.selectedOpeningIndex].id = id
      this.emit()
    }
  }

  // Emitters
  private emit(): void {
    this.openingLeaftsEmitter.emit(this.buildObjectResponse())
  }

  private emitOpeningSelectedId(): void {
    this.selectedOpeningIdEmitter.emit(this.selectedOpeningId)
  }

  //Builders
  private buildObjectResponse(): OpeningTableItem[] {
    return this.openingsLeafts.map((o, i) => {
      const { width, isLock, isMain } = this.formArray.at(i).value

      return {
        opening: {
          id: o.id,
          name: o.name,
        },
        width,
        isLock,
        isMain,
      }
    })
  }

  private buildFromSelected(): void {
    if (this.openingFromSeleted.length > 0) {
      this.openingFromSeleted.forEach((l: OpeningTableItem) =>
        this.addLeaft(l?.opening.id ?? 1, l.width, l.isMain)
      )
    } else {
      this.addLeaft()
    }
  }
}
