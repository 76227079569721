import Konva from 'konva'
import { Observable, Subject, Subscription } from 'rxjs'
import { Vector2 } from 'three/src/math/Vector2'
import { ModelElement } from '../../@interfaces/modelElement'
import { Side, SideOptions } from '../../@types/side.types'
import { Area, AreaModel } from './area-model'
import { TipupLeaftOpening } from './door-window-leaft-model'
import { ElementSelectable } from './elementSelectable'
import { ProfileElement } from './Profile-model'
import { PseudoFrame } from './pseudoframe-model'
import { PROFILE_KONVA_NAMES } from './types-elements-names'

export class JonquilloElement
  extends ElementSelectable
  implements ModelElement {
  public readonly uuid: string
  public sidesElement: { [k in SideOptions]: ProfileElement }
  public id: number

  private _associatedArea: AreaModel
  private _associatedPseudoFrame: PseudoFrame

  public isClipped = false

  private subscriptions: Subscription[] = []

  public container: Area

  constructor(
    uuid: string,
    profileElements: { [k in SideOptions]: ProfileElement }
  ) {
    super()

    this.group = new Konva.Group().addName('jonquillo')
    this.uuid = uuid
    this.setProfileElements(profileElements)
  }

  get associatedArea(): AreaModel {
    return this._associatedArea
  }
  get associatedPseudoFrame(): PseudoFrame {
    return this._associatedPseudoFrame
  }

  get sideElements(): ProfileElement[] {
    return Object.values(this.sidesElement).map((side) => side)
  }

  public setAssociatedArea(area: AreaModel): void {
    this._associatedArea = area
  }

  public setAssociatedPseudoFrame(pseudoframe: PseudoFrame): void {
    this._associatedPseudoFrame = pseudoframe
  }

  private setProfileElements(profileElements: Side<ProfileElement>): void {
    this.sidesElement = profileElements

    Object.keys(this.sidesElement).forEach((side: SideOptions) => {
      this.group.add(this.sidesElement[side].group)
    })
  }

  public addSide(profileElement: ProfileElement, side: SideOptions) {
    this.sidesElement[side] = profileElement
  }

  public destroy(): void {
    this.group.destroy()
    this.subscriptions.forEach((s) => s?.unsubscribe())
  }

  public addSubscriptions(subs: Subscription[]): void {
    this.subscriptions = [...this.subscriptions, ...subs]
  }

  public getTotalWidthInternalCamera(): number {
    return 0
  }

  public getTotalHeightInternalCamera(): number {
    return
  }

  public getPosition(): Vector2 {
    return null
  }
}
