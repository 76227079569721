<div class="wrapper">
  <div class="header">
    <h1>Junquillo</h1>
  </div>

  <div class="area">
    <button class="btn btn-primary btn-block" (click)="onAddAccessory()">
      Añadir sobre área contenedora
    </button>
  </div>

  <h2>Perfiles</h2>
  <div class="profiles" *ngIf="profiles.length > 0">
    <div
      class="profile"
      *ngFor="let profile of profiles"
      (click)="onAddAccessory(profile)"
    >
      <div class="profile-info">
        <span>{{ profile.code }}</span>
        <span>{{ profile.description }}</span>
        <span>{{ profile.ext }}mm</span>
      </div>
      <div class="profile-side">
        <span>{{ profile.side }}</span>
      </div>
    </div>
  </div>
</div>
