import { FormBuilder, FormGroup } from '@angular/forms'
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core'

// fontawesome
import {
  faPlus,
  faSearch,
  faEdit,
  faTrash,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons'

// Toastr
import { ToastrService } from 'ngx-toastr'

// Modals
import { NewAccesoriesCombinationDialogComponent } from 'src/app/@dialogs/new-accesories-combination/new-accesories-combination.component'
import { DialogInstanceModel } from 'src/app/@shared/@models/dialog-instance-model'
import { DeleteItemDialogComponent } from 'src/app/@dialogs/delete-item-dialog/delete-item-dialog.component'
import { ModalService } from 'src/app/@services/modal.service'

import { Profile } from '../../profile-management-editor.component'
import { ProfileCreator } from 'src/app/@shared/@interfaces/profileCreator.model'
import { ProfileAccesoriesCombinationService } from 'src/app/@services/profile-accesories-combination.service'
import { NewAccesoriesFormulaComponent } from 'src/app/@dialogs/new-accesories-formula/new-accesories-formula.component'
import {
  Accesory,
  AccesoryWithFormula,
  AccessoryCreator,
} from 'src/app/@shared/@interfaces/accesory'

interface IAccesory {
  index?: number
  id?: number
  accesory: Accesory
  formula: { id?: number; function?: string; prettyFunction: string }
}

@Component({
  selector: 'app-profile-accesories',
  templateUrl: './profile-accesories.component.html',
  styleUrls: ['./profile-accesories.component.scss'],
})
export class ProfileAccesoriesComponent implements OnInit, OnChanges {
  @Input() private profile: Profile
  @Input() private profileObject: ProfileCreator

  public formSearch: FormGroup

  // Icons
  public faPlus = faPlus
  public faSearch = faSearch
  public faEdit = faEdit
  public faTrash = faTrash
  public faCheckCircle = faCheckCircle

  public selectedAccesory: Accesory
  public filteredAccesories: Array<IAccesory> = []
  public accesories: Array<IAccesory> = []
  public dissasembledAccesories: Array<IAccesory> = []
  public filteredAccesoriesDisasembled: Array<IAccesory> = []

  private accesoriesToSubmit: Array<{
    index: number
    accessory: AccessoryCreator
  }> = []

  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private accesoriesService: ProfileAccesoriesCombinationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.profileObject) {
      this.accesories = []
      this.getAccesories()
      this.getDissasembledAccesories()
      this.searchAccesories()
    }
  }

  ngOnInit(): void {
    this.buildSearchForm()
  }

  private buildSearchForm(): void {
    this.formSearch = this.fb.group({
      search: [''],
      show: [false],
    })
  }

  public buildProfileAccesories(): Array<AccessoryCreator> {
    const accessories: AccessoryCreator[] = []
    this.accesoriesToSubmit.forEach((value) =>
      accessories.push(value.accessory)
    )

    return accessories
  }

  public addNew(): void {
    const newDialog = new DialogInstanceModel(
      NewAccesoriesCombinationDialogComponent,
      {
        initialState: {
          accesory: 'Test',
        },
        // class: 'modal-dialog-centered',
      }
    )

    this.modalService.openModal(newDialog).subscribe(
      async (accesory: Accesory) => {
        const acc = await this.openFormulaModal(accesory)

        if (acc) this.createAccesorie(acc)
      },
      (err) => {}
    )
  }
  private createAccesorie(accessory: any): any {
    const newAccessory: AccessoryCreator = {
      codRef: accessory.accesory.codRef,
      family: accessory.accesory.family,
      formula: accessory.formula.function,
      undBox: accessory.accesory.undBox,
      type: accessory.accesory.type,
    }

    if (this.profile) {
      return this.accesoriesService
        .createAccessory(this.profile.id, newAccessory)
        .subscribe(
          (response) => {
            this.accesories.push({
              index: this.accesories.length,
              id: response.id,
              ...accessory,
            })
            this.toastr.success('Accesorio creado')
            this.searchAccesories()
          },
          (err) => {
            this.toastr.error('No se ha podido crear el accesorio')
          }
        )
    }

    this.accesoriesToSubmit.push({
      index: this.accesories.length,
      accessory: newAccessory,
    })

    this.accesories.push({
      index: this.accesories.length,
      ...accessory,
    })

    this.searchAccesories()
  }

  public deleteAccesory(accesory: IAccesory, index: number): void {
    const deleteDialog = new DialogInstanceModel(DeleteItemDialogComponent, {
      initialState: {
        itemName: 'el accesorio',
      },
      class: 'modal-dialog-centered',
    })

    this.modalService.openModal(deleteDialog).subscribe(
      async (data) => {
        if (this.profile) {
          const isDeleted = await this.deleteRemote(accesory.id)
          if (!isDeleted)
            return this.toastr.error('No se ha podido eliminar el accesorio')

          this.deleteFromArrays(index)

          this.toastr.success('Accesorio eliminado')
        }

        if (!this.profile) {
          this.deleteFromArrays(index)
          this.accesoriesToSubmit = this.accesoriesToSubmit.filter(
            (value) => value.index !== index
          )
        }

        this.searchAccesories()
      },
      (err) => {}
    )
  }
  private deleteRemote(accessoryId: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.accesoriesService
        .deleteAccesorie(this.profile.id, accessoryId)
        .subscribe(
          (response) => {
            resolve(true)
          },
          (err) => {
            resolve(false)
          }
        )
    })
  }
  private deleteFromArrays(index: number): void {
    this.accesories = this.accesories.filter((value) => value.index !== index)
    this.filteredAccesories = this.filteredAccesories.filter(
      (value) => value.index !== index
    )
  }

  public async updateAccesory(index: number): Promise<void> {
    const accesoryToUpdate = this.accesories[index]

    this.openFormulaModal(accesoryToUpdate.accesory)
      .then(async (acc) => {
        if (this.profile) {
          const accessoryUpdated: IAccesory | any = {
            id: accesoryToUpdate.id,
            index: accesoryToUpdate.index,
            ...acc,
          }

          await this.updateRemote(accessoryUpdated)
        }

        if (!this.profile) {
          const indexToSubmit = this.accesoriesToSubmit.findIndex(
            (value) => value.index === index
          )
          this.accesoriesToSubmit[indexToSubmit] = {
            index: accesoryToUpdate.index,
            accessory: {
              codRef: acc.accesory.codRef,
              family: acc.accesory.family,
              formula: acc.formula.function,
              undBox: acc.accesory.undBox,
              type: acc.accesory.type,
            },
          }
          this.accesories[index] = {
            id: accesoryToUpdate.id,
            accesory: accesoryToUpdate.accesory,
            formula: acc.formula as any,
            index: index,
          }
        }

        this.searchAccesories()
      })
      .catch((err) => {})
  }
  public updateRemote(accessory: IAccesory): Promise<void> {
    const indexAccesorie = this.accesories.findIndex(
      (value) => value.index === accessory.index
    )

    const accessoryCreator: AccessoryCreator = {
      codRef: accessory.accesory.codRef,
      family: accessory.accesory.family,
      formula: accessory.formula.function,
      undBox: accessory.accesory.undBox,
      type: accessory.accesory.type,
    }

    return new Promise((resolve, reject) =>
      this.accesoriesService
        .updateAccessory(this.profile.id, accessory.id, accessoryCreator)
        .subscribe(
          (response) => {
            this.accesories[indexAccesorie] = accessory
            this.toastr.success('Accesorio actualizado')
            resolve()
          },
          (err) => {
            this.toastr.error('No se ha podido actualizar el accesorio')
          }
        )
    )
  }

  public openFormulaModal(
    selectedAccesory: Accesory
  ): Promise<AccesoryWithFormula> {
    const formulaDialog = new DialogInstanceModel(
      NewAccesoriesFormulaComponent,
      {
        initialState: {
          accesory: selectedAccesory,
          type: 'Profile',
        },
      }
    )

    return new Promise((resolve, reject) => {
      this.modalService.openModal(formulaDialog).subscribe((acc) => {
        resolve(acc)
      })
    })
  }

  private getAccesories(): void {
    this.profileObject.profileAccessories?.forEach(async (acc) => {
      const newAccesoty: IAccesory = {
        index: this.accesories.length,
        accesory: acc.accessory,
        id: acc.id,
        formula: {
          prettyFunction: acc.formula,
        },
      }

      this.accesories.push(newAccesoty)
    })

    this.searchAccesories()
  }

  private getDissasembledAccesories(): void {
    this.profileObject.disasembledProfiles.forEach((profile) => 
      profile.profileAccessories?.forEach(async (acc) => {
        const newAccesoty: IAccesory = {
          index: this.accesories.length,
          accesory: acc.accessory,
          id: acc.id,
          formula: {
            prettyFunction: acc.formula,
          },
        }
  
        this.dissasembledAccesories.push(newAccesoty)
      })
    )
    this.searchDissasembledAccesories()
  }

  public searchAccesories(criteria?: string): void {
    criteria = criteria ?? this.formSearch.get('search').value
    if (!criteria) this.filteredAccesories = this.accesories

    this.filteredAccesories = this.accesories.filter((value) => {
      const code = value.accesory.codRef.toLowerCase()
      const description = value.accesory.denomination.toLowerCase()

      return code.includes(criteria) || description.includes(criteria)
    })

    this.searchDissasembledAccesories(criteria);
  }

  public searchDissasembledAccesories(criteria?: string): void {
    criteria = criteria ?? this.formSearch.get('search').value
    if (!criteria) this.filteredAccesoriesDisasembled = this.dissasembledAccesories

    this.filteredAccesoriesDisasembled = this.dissasembledAccesories.filter((value) => {
      const code = value.accesory.codRef.toLowerCase()
      const description = value.accesory.denomination.toLowerCase()

      return code.includes(criteria) || description.includes(criteria)
    })
  }
}
