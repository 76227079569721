import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthStatusService } from './@services/auth-status.service'
import { AuthService } from './@services/auth.service'
import { Nullable } from './core/types/Nullable.type'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'galisur-configurador-angular'

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getQueryParams().then((token) => {
      console.log(token)
    })
    await this.loginToken()
  }

  private getQueryParams(): Promise<Nullable<string>> {
    return new Promise((resolve, reject) => {
      this.route.queryParams.subscribe((params) => {
        const { token } = params
        if (token) {
          localStorage.setItem('token', token)
          this.cleanUrl()
        }
        resolve(token)
      })
    })
  }

  // method to clean url query params
  private cleanUrl(): void {
    this.router.navigate([], {
      queryParams: { token: null },
      queryParamsHandling: 'merge',
    })
  }

  private async loginToken(): Promise<void> {
    await this.authService.tokenLogin()
  }
}
