import Konva from 'konva'
import { Observable, Subject } from 'rxjs'
import { Area } from './area-model'
import { PROFILE_KONVA_NAMES } from './types-elements-names'

export abstract class ElementSelectable {
  public abstract readonly uuid: string

  public group: Konva.Group
  private isSelected = false

  private selectedSubject: Subject<any>
  public selected$: Observable<any>

  public container: Area

  constructor() {
    this.selectedSubject = new Subject()
    this.selected$ = this.selectedSubject.asObservable()
  }

  public addListener<T>(): Observable<T> {
    this.group.removeEventListener('click')
    this.group.addEventListener('click', () => {
      this.isSelected = !this.isSelected

      if (this.isSelected) this.select()
      if (!this.isSelected) this.unSelect()
      this.group.getLayer()?.draw()
    })

    return this.selected$
  }

  public select() {
    this.setStrokeColor(this.group, 'red')
    this.selectedSubject.next(this)
    this.isSelected = true
  }

  public unSelect(options?: { emit: boolean }) {
    options = options ?? { emit: true }
    const { emit } = options

    this.setStrokeColor(this.group, 'black')
    this.isSelected = false
    if (emit) this.selectedSubject.next(null)
  }

  private setStrokeColor(group: Konva.Group, color = 'black') {
    group.children.toArray().forEach((e: Konva.Line | Konva.Group) => {
      if (e instanceof Konva.Line) {
        const hasStroke = e.name() === PROFILE_KONVA_NAMES.stroke
        if (hasStroke) e.stroke(color)
      }

      if (e instanceof Konva.Group) {
        this.setStrokeColor(e, color)
      }
    })
  }
}
