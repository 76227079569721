import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { ToastrService } from 'ngx-toastr'
import { AddAccessoriesGroupDialogComponent } from 'src/app/@dialogs/add-accessories-group-dialog/add-accessories-group-dialog.component'
import { NewAccesoriesCombinationDialogComponent } from 'src/app/@dialogs/new-accesories-combination/new-accesories-combination.component'
import { NewAccesoriesFormulaComponent } from 'src/app/@dialogs/new-accesories-formula/new-accesories-formula.component'
import { asyncForEach } from 'src/app/@helper/asyncForEach'
import { cleanArray } from 'src/app/@helper/cleanArray'
import { AccesoryService } from 'src/app/@services/accesory.service'
import { ModalService } from 'src/app/@services/modal.service'
import {
  Accesory,
  AccesoryWithFormula,
} from 'src/app/@shared/@interfaces/accesory'
import { IDialog } from 'src/app/@shared/@interfaces/dialog'
import { DialogInstanceModel } from 'src/app/@shared/@models/dialog-instance-model'
import { CoverGapElement } from 'src/app/@shared/@models/modelsElements/covergap-model'
import { Frame } from 'src/app/@shared/@models/modelsElements/frame-model'
import { Gap } from 'src/app/@shared/@models/modelsElements/gap-model'
import { ProfileElement } from 'src/app/@shared/@models/modelsElements/Profile-model'

import {
  AccessoriesModelService,
  CreateAccessoryModelRequest,
} from '../../../@services/accessories-model.service'

@Component({
  selector: 'app-add-accessory-frame',
  templateUrl: './add-accessory-frame.component.html',
  styleUrls: ['./add-accessory-frame.component.scss'],
})
export class AddAccessoryFrameComponent implements OnInit, IDialog {
  public frame: Frame
  public gap: Gap
  public isAccessoryGroup = false

  public frameProfilesData: ProfileData[] = []
  public externalCovergapsProfilesData: ProfileData[] = []
  public internalCovergapsProfilesData: ProfileData[] = []
  public associatedProfilesData: ProfileData[] = []

  @Output()
  $response: EventEmitter<any> = new EventEmitter()

  constructor(
    private modalRef: BsModalRef,
    private modalService: ModalService,
    private toastr: ToastrService,
    private accessoryService: AccessoriesModelService
  ) {}

  ngOnInit(): void {
    this.buildFrameProfiles()
    this.buildCovergapsProfiles()
    this.buildAssociatedProfiles()
  }

  confirm(): void {
    const areaContainer = this.frame.container

    const request: CreateAccessoryModelRequest = {
      entities: [
        {
          type: 'Area',
          entity: areaContainer.uuid,
        },
        {
          type: 'Frame',
          entity: this.frame.id,
        },
      ],
      accessory: { codeRef: 'AE90', family: 'AS' },
      formula:
        'if(_ancho_area_aleta_externa_ > 1 and _ancho_area_aleta_externa_ <= 2000, 7)',
      model: this.gap.modelId,
      type: 'Frame',
      typeId: this.frame.id,
    }

    this.$response.emit(request)
  }
  decline(): void {
    throw new Error('Method not implemented.')
  }

  public translateSide(side: string): string {
    switch (side) {
      case 'top':
        return 'Superior'
      case 'bottom':
        return 'Inferior'
      case 'left':
        return 'Izquierda'
      case 'right':
        return 'Derecha'
      default:
        return 'No definido'
    }
  }

  public async selecteAccessory(profile?: ProfileData): Promise<void> {
    let entities: { type: string; entity: string }[] = [
      {
        type: 'Area',
        entity: this.frame.container.uuid,
      },
    ]

    let typeInfo: { type: 'Area' | 'Profile'; entity: string } = {
      type: 'Area',
      entity: this.frame.container.uuid,
    }

    if (profile) {
      entities.push({
        type: 'Profile',
        entity: profile.uuid,
      })

      typeInfo = {
        type: 'Profile',
        entity: profile.uuid,
      }
    }

    if (this.isAccessoryGroup) {
      this.onAccesoryGroup(entities, typeInfo)
      return
    }

    const acc = await this.openAccessoryDialog()
    const { accesory, formula } = await this.openFormulaDialog(
      acc,
      typeInfo.type
    )

    const request: CreateAccessoryModelRequest = {
      entities,
      accessory: {
        codeRef: accesory.codRef,
        family: accesory.family,
      },
      formula: formula.function,
      model: this.gap.modelId,
      type: typeInfo.type,
      typeId: typeInfo.entity,
    }

    this.accessoryService.setAccessoryModelToEntity(request).subscribe({
      next: (response) => {
        this.toastr.success(`Accesorio agregado correctamente`)
        this.modalRef.hide()
      },
    })
  }

  private buildFrameProfiles(): void {
    const sides: ProfileElement[] = cleanArray(
      Object.values(this.frame.profilesElements).map((p: ProfileElement) => {
        return p
      })
    )

    this.frameProfilesData = this.buildPorfilesData(sides)
  }

  private buildCovergapsProfiles(): void {
    const { external, internal } = this.frame.covergaps

    this.externalCovergapsProfilesData = this.buildCovergapsProfilesAux(
      external
    )
    this.internalCovergapsProfilesData = this.buildCovergapsProfilesAux(
      internal
    )
  }

  private buildCovergapsProfilesAux(covergaps: CoverGapElement): ProfileData[] {
    const sides: ProfileElement[] = cleanArray(covergaps?.sides ?? [])

    return this.buildPorfilesData(sides)
  }

  private buildAssociatedProfiles(): void {
    const { associatedProfiles } = this.frame

    const profilesElements = Object.values(associatedProfiles)
      .map((p) => {
        return p
      })
      .flat()

    this.associatedProfilesData = this.buildPorfilesData(profilesElements)
  }

  private buildPorfilesData(elements: ProfileElement[]): ProfileData[] {
    return elements.map((p) => {
      const { ext, int } = p.calculateLength()

      const { code, description } = p.profile

      return {
        uuid: p.uuid,
        code,
        description,
        ext,
        int,
        side: this.translateSide(p.side),
      }
    })
  }

  private openAccessoryDialog(): Promise<Accesory> {
    const newDialog = new DialogInstanceModel(
      NewAccesoriesCombinationDialogComponent,
      {
        initialState: {
          accesory: 'Test',
        },
        // class: 'modal-dialog-centered',
      }
    )

    return new Promise((resolve, reject) => {
      this.modalService.openModal(newDialog).subscribe(
        async (accesory: Accesory) => {
          resolve(accesory)
        },
        (err) => {}
      )
    })
  }

  private openFormulaDialog(
    accesory: Accesory,
    type: 'Area' | 'Profile'
  ): Promise<AccesoryWithFormula> {
    const formulaDialog = new DialogInstanceModel(
      NewAccesoriesFormulaComponent,
      {
        initialState: {
          accesory: accesory,
          type: type,
        },
      }
    )

    return new Promise((resolve, reject) => {
      this.modalService
        .openModal(formulaDialog)
        .subscribe((acc: AccesoryWithFormula) => {
          resolve(acc)
        })
    })
  }

  public async onAccesoryGroup(
    entities: { type: string; entity: string }[],
    typeInfo: { type: 'Area' | 'Profile'; entity: string }
  ): Promise<void> {
    const dialog = new DialogInstanceModel(
      AddAccessoriesGroupDialogComponent,
      {}
    )

    this.modalService.openModal(dialog).subscribe({
      next: async (acc: any) => {
        const { accessories } = acc
        if (!accessories) return this.modalRef.hide()

        await asyncForEach(accessories, async (acc: any) => {
          const request: CreateAccessoryModelRequest = {
            entities,
            accessory: {
              codeRef: acc.codRef,
              family: acc.family,
            },
            formula: acc.formula,
            model: this.gap.modelId,
            type: typeInfo.type,
            typeId: typeInfo.entity,
          }

          await this.accessoryService
            .setAccessoryModelToEntity(request)
            .toPromise()
        }).then(() => {
          this.toastr.success(`Accesorios agregados correctamente`)
          this.modalRef.hide()
        })
      },
      error: (err) => {
        console.log(err)
      },
    })
  }
}

interface ProfileData {
  code: string
  description: string
  side: string
  ext: number
  int: number
  uuid: string
}
