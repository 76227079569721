<div class="container-fluid">
  <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="medium"
    color="#fff"
    type="square-jelly-box"
    [fullScreen]="true"
    ><p style="color: white">Cargando...</p></ngx-spinner
  >
  <div class="row">
    <div class="col-4 tree">
      <div class="header-container">
        <div>
          <h3 class="title">
            <button class="btn">
              <mat-icon>arrow_back</mat-icon>
            </button>
            <strong>Perfiles</strong>
          </h3>
        </div>
        <div>
          <button class="newSerieButton btn btn-primary" (click)="addNew()">
            <mat-icon>add</mat-icon>
            Nueva serie
          </button>
        </div>
      </div>

      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
          <li class="mat-tree-node mainTreeNode">
            <div class="tree-node">
              <div>
                <button class="btn" disabled>
                  <mat-icon></mat-icon>
                </button>
                <span>{{ node.name }}</span>
                <span class="description" *ngIf="node.description">{{
                  node.description
                }}</span>
              </div>
              <div *ngIf="node.canEdit">
                <button (click)="editNode(node)" class="btn">
                  <mat-icon>edit</mat-icon>
                </button>
                <button (click)="deleteNode(node)" class="btn">
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </div>
            </div>
          </li>
        </mat-tree-node>
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild; let i">
          <li>
            <div class="tree-node">
              <div>
                <button
                  class="btn"
                  mat-icon-button
                  matTreeNodeToggle
                  [attr.aria-label]="'Toggle ' + node.name"
                  (click)="getDataIfExist(node)"
                >
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{
                      treeControl.isExpanded(node)
                        ? 'expand_more'
                        : 'chevron_right'
                    }}
                  </mat-icon>
                </button>
                {{ node.name }}
              </div>
              <div *ngIf="node.canEdit">
                <button (click)="editNode(node)" class="btn">
                  <mat-icon>edit</mat-icon>
                </button>
                <button (click)="deleteNode(node)" class="btn">
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </div>

              <div *ngIf="!node.canEdit">
                <div class="search-container">
                  <form>
                    <input
                      class="search expandright"
                      [id]="'searchright' + node.parentId + node.name"
                      type="search"
                      name="q"
                      placeholder="Nombre/Cod serie o Perfil"
                      (click)="saveFilterList(node.parentId)"
                      [(ngModel)]="filterBy"
                    />
                    <label
                      class="button searchbutton"
                      [for]="'searchright' + node.parentId + node.name"
                      ><mat-icon>search</mat-icon>
                    </label>
                  </form>
                </div>
                <button (click)="addNew(node)" class="btn">
                  <mat-icon>add</mat-icon>
                </button>
              </div>
            </div>
            <ul [class.tree-invisible]="!treeControl.isExpanded(node)">
              <ng-container matTreeNodeOutlet></ng-container>
            </ul>
          </li>
        </mat-nested-tree-node>
      </mat-tree>
    </div>

    <div class="col-8 content no-admin" *ngIf="!(user$ | async)?.isAdmin()">
      <h2>Galisur</h2>
    </div>

    <div class="col-8 content" *ngIf="(user$ | async)?.isAdmin()">
      <app-profile-management-editor
        [$serie]="selectedSerie"
        [$profile]="selectedProfile"
        (closeProfileEditorEmitter)="hideProfileEditor()"
        (addProfileEmitter)="addProfile($event)"
        (updateProfileEmitter)="updateProfile($event)"
        *ngIf="showProfileEditorFlag"
      ></app-profile-management-editor>

      <app-serie-form
        [serie]="selectedSerieForEdit"
        (hideSerieEditorEmitter)="hideSerieEditor()"
        (addSerieEmitter)="addSerie($event)"
        (updateSerieEmitter)="updateSerie($event)"
        *ngIf="showSerieEditorFlag"
      ></app-serie-form>
    </div>
  </div>
</div>
