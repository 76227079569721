import { FormGroup } from '@angular/forms'

export function invalidInputMessages(
  form: FormGroup,
  controlName: string,
  requiredMessage?: string,
  patternMessage?: string
): string[] {
  if (!isInvalidInput(form, controlName)) return []

  const requiredMessageCopy = requiredMessage ?? 'El campo es requerido'
  const patternMessageCst = patternMessage ?? 'Formato no valido'

  const messages: string[] = []

  const formControl = form.get(controlName)
  const errors = Object.keys(formControl.errors ?? {})

  errors.forEach((errKey) => {
    const errorObject = formControl.errors[errKey]
    messages.push(
      getErrorMessage(
        errKey,
        errorObject,
        requiredMessageCopy,
        patternMessageCst
      )
    )
  })

  return messages
}

export function isInvalidInput(formGroup: FormGroup, control: string): boolean {
  const controlForm = formGroup.get(control)
  return controlForm?.invalid && (controlForm?.touched || controlForm?.dirty)
}

function getErrorMessage(
  errorKey: string,
  errorObject: any,
  requiredMessage: string,
  patternMessageCst: string
): string {
  switch (errorKey) {
    case 'required':
      return requiredMessage
    case 'minlength':
      return `La longitud debe ser mayor a ${errorObject.requiredLength}`
    case 'maxlength':
      return `La longitud debe ser menor a ${errorObject.requiredLength}`
    case 'min':
      return `Debe ser mayor o igual a ${errorObject.min}`
    case 'max':
      return `Debe ser menor o igual a ${errorObject.max}`
    case 'pattern':
      return patternMessageCst
    default:
      return errorObject
  }
}
