import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'

import { ThreeDxfModule } from 'src/app/presentation/files/pages/profiles/pages/profile/components/three-dxf/three-dxf.module'
import { ProfileRoutingModule } from './profile-routing.module'

import { ProfileComponent } from './profile.component'

@NgModule({
  declarations: [ProfileComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ProfileRoutingModule,
    FontAwesomeModule,
    ThreeDxfModule,
  ],
  exports: [ThreeDxfModule],
})
export class ProfileModule {}
