<div class="modal-header">
  <h5 class="modal-title pull-left">Selección perfiles abatible</h5>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="decline()"
  >
    <img src="../../../assets/img/icons/close.svg" alt="Close modal icon" />
  </button>
</div>

<!-- Body -->
<div class="modal-body box-position">
  <tabset>
    <tab>
      <ng-template tabHeading>
        <img
          src="assets/img/icons/abertura1.svg"
          alt="abertura1"
          class="abertura1"
        />
      </ng-template>
      <div class="content">
        <app-opening-window-door
          (openingObjectEmitter)="setOpeningCreatorData($event)"
          (removeOpeningEmitter)="deleteOpening()"
          [areaWidth]="width"
          [areaheight]="height"
          [serieId]="serieId"
          [openingLeaftsFromSelected]="openingTableItem"
          [openingSelectedElement]="selectedOpening"
        ></app-opening-window-door>
      </div>
    </tab>

    <tab>
      <ng-template tabHeading>
        <img
          src="assets/img/icons/abertura2.svg"
          alt="abertura2"
          class="abertura2"
        />
      </ng-template>
      <div class="content">
        <app-opening-door></app-opening-door>
      </div>
    </tab>

    <tab>
      <ng-template tabHeading>
        <img
          src="assets/img/icons/abertura3.svg"
          alt="abertura3"
          class="abertura3"
        />
      </ng-template>
      <div class="content">
        <app-opening-projecting [images]="images(3)"></app-opening-projecting>
      </div>
    </tab>

    <tab>
      <ng-template tabHeading>
        <img
          src="assets/img/icons/abertura4.svg"
          alt="abertura4"
          class="abertura4"
        />
      </ng-template>
      <div class="content">
        <app-opening-projecting [images]="images(4)"></app-opening-projecting>
      </div>
    </tab>
  </tabset>
</div>
<!-- Body -->
<div class="modal-footer justify-content-center">
  <button class="btn btn-primary" (click)="confirm()">Aceptar</button>
</div>
