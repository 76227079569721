<div class="content">
  <div class="content-header">
    <h2>Agregar Grupo de Accesorios</h2>
    <fa-icon
      [icon]="faTimes"
      class="content-header__icon"
      (click)="decline()"
    ></fa-icon>
  </div>
  <hr />
  <div autocomplete="off">
    <div class="form-accessory-data">
      <div
        class="input-group mb-4"
        [ngClass]="{ disabled: accList.length > 0 }"
      >
        <app-input label="Serie" width="max-content">
          <app-dropdown-input
            [items]="series"
            [title]="getSerie()"
            (selectItemEmitter)="selecPositionSeries($event)"
          >
          </app-dropdown-input>
        </app-input>
      </div>
      <div
        class="input-group mb-4"
        [ngClass]="{ disabled: accList.length > 0 }"
      >
        <app-input label="Grupos" width="max-content">
          <app-dropdown-input
            [items]="groups"
            [title]="getGroup()"
            (selectItemEmitter)="selecPositionGroups($event)"
          >
          </app-dropdown-input>
        </app-input>
      </div>
    </div>
    <div class="fade-in">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Código</th>
            <th scope="col">Familia</th>
            <th scope="col">Descripción</th>
            <th scope="col">Medida</th>
            <!-- <th scope="col">Cantidad</th> -->
            <th scope="col">Color</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let formGroup of formArra.controls"
            [formGroup]="formGroup"
          >
            <td>{{ getControlValue(formGroup, 'codRef') }}</td>
            <td>{{ getControlValue(formGroup, 'family') }}</td>
            <td>{{ getControlValue(formGroup, 'denomination') }}</td>
            <td>{{ getControlValue(formGroup, 'um') }}</td>
            <!-- <td>
              <input type="number" class="form-item__input quantity" name="quantity" value="1"
                formControlName="quantity" />
            </td> -->
            <td
              (click)="
                getColors(
                  getControlValue(formGroup, 'family'),
                  getControlValue(formGroup, 'codRef')
                )
              "
            >
              <app-dropdown-input
                [items]="colors"
                [title]="formGroup.get('colorName').value"
                (selectItemEmitter)="selecPositionColor(formGroup, $event)"
              >
              </app-dropdown-input>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-center submit-button">
        <button class="btn btn-primary" (click)="onSubmit()">Aceptar</button>
      </div>
    </div>
  </div>
</div>
