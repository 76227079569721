import { Component, EventEmitter, OnInit } from '@angular/core'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { IDialog } from 'src/app/@shared/@interfaces/dialog'

import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FormBuilder, FormControl, Validators } from '@angular/forms'

@Component({
  selector: 'app-add-name-dialog',
  templateUrl: './add-name-dialog.component.html',
  styleUrls: ['./add-name-dialog.component.scss'],
})
export class AddNameDialogComponent implements OnInit, IDialog {
  $response = new EventEmitter<string>()
  // Icons
  public faTimes = faTimes

  // Input
  public title = 'Añadir nombre'

  // Form
  public formControl: FormControl

  constructor(private modalRef: BsModalRef, private fb: FormBuilder) {
    this.formControl = this.fb.control('', [Validators.required])
  }

  public isInvalidName(): boolean {
    return this.formControl.invalid && this.formControl.touched
  }

  confirm(): void {
    this.formControl.markAsTouched()
    if (!this.formControl.valid) return

    this.$response.emit(this.formControl.value)
    this.modalRef.hide()
  }
  decline(): void {
    this.modalRef.hide()
  }

  ngOnInit(): void {}
}
