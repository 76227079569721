import { Component, EventEmitter, OnInit } from '@angular/core'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { ToastrService } from 'ngx-toastr'
import { AddAccessoriesGroupDialogComponent } from 'src/app/@dialogs/add-accessories-group-dialog/add-accessories-group-dialog.component'
import { NewAccesoriesCombinationDialogComponent } from 'src/app/@dialogs/new-accesories-combination/new-accesories-combination.component'
import { NewAccesoriesFormulaComponent } from 'src/app/@dialogs/new-accesories-formula/new-accesories-formula.component'
import { asyncForEach } from 'src/app/@helper/asyncForEach'
import {
  AccessoriesModelService,
  CreateAccessoryModelRequest,
} from 'src/app/@services/accessories-model.service'
import { ModalService } from 'src/app/@services/modal.service'
import {
  Accesory,
  AccesoryWithFormula,
} from 'src/app/@shared/@interfaces/accesory'
import { IDialog } from 'src/app/@shared/@interfaces/dialog'
import { DialogInstanceModel } from 'src/app/@shared/@models/dialog-instance-model'
import { CrossbarElement } from 'src/app/@shared/@models/modelsElements/crossbar-model'
import { Gap } from 'src/app/@shared/@models/modelsElements/gap-model'

@Component({
  selector: 'app-add-accessory-crossbar',
  templateUrl: './add-accessory-crossbar.component.html',
  styleUrls: ['./add-accessory-crossbar.component.scss'],
})
export class AddAccessoryCrossbarComponent implements OnInit, IDialog {
  public crossbar: CrossbarElement
  public gap: Gap
  public isAccessoryGroup = false

  $response: EventEmitter<any> = new EventEmitter()

  constructor(
    private modalRef: BsModalRef,
    private modalService: ModalService,
    private accessoryService: AccessoriesModelService,
    private toastr: ToastrService
  ) {}

  confirm(): void {}

  decline(): void {}

  ngOnInit(): void {
    if (this.isAccessoryGroup) {
      return this.onAddAccessoryGroup()
    }
    this.onAddAccessory()
  }

  public async onAddAccessory(): Promise<void> {
    const acc = await this.openAccessoryDialog()
    const { accesory, formula } = await this.openFormulaDialog(acc, 'Profile')

    this.saveAccessory({
      codRef: accesory.codRef,
      family: accesory.family,
      formula: formula.function,
    }).then(() => {
      this.toastr.success('Accesorio añadido')
      this.modalRef.hide()
    })
  }

  public onAddAccessoryGroup(): void {
    const dialog = new DialogInstanceModel(
      AddAccessoriesGroupDialogComponent,
      {}
    )

    this.modalService.openModal(dialog).subscribe({
      next: async (res: {
        accessories: Array<{
          codRef: string
          family: string
          formula: string
          group: number
        }>
      }) => {
        const { accessories } = res

        await asyncForEach(accessories, async (acc: any) => {
          await this.saveAccessory({
            codRef: acc.codRef,
            family: acc.family,
            formula: acc.formula,
            group: acc.group,
          })
        }).then(() => {
          this.toastr.success('Accesorios añadidos')
          this.modalRef.hide()
        })
      },
    })
  }

  private openAccessoryDialog(): Promise<Accesory> {
    const newDialog = new DialogInstanceModel(
      NewAccesoriesCombinationDialogComponent,
      {
        initialState: {
          accesory: 'Test',
        },
        // class: 'modal-dialog-centered',
      }
    )

    return new Promise((resolve, reject) => {
      this.modalService.openModal(newDialog).subscribe(
        async (accesory: Accesory) => {
          resolve(accesory)
        },
        (err) => {}
      )
    })
  }

  private openFormulaDialog(
    accesory: Accesory,
    type: 'Profile'
  ): Promise<AccesoryWithFormula> {
    const formulaDialog = new DialogInstanceModel(
      NewAccesoriesFormulaComponent,
      {
        initialState: {
          accesory: accesory,
          type,
        },
      }
    )

    return new Promise((resolve, reject) => {
      this.modalService
        .openModal(formulaDialog)
        .subscribe((acc: AccesoryWithFormula) => {
          resolve(acc)
        })
    })
  }

  private async saveAccessory(acc: {
    codRef: string
    family: string
    formula: string
    group?: number
  }): Promise<void> {
    const request: CreateAccessoryModelRequest = {
      entities: [
        {
          type: 'Profile',
          entity: this.crossbar.uuid,
        },
      ],
      accessory: {
        codeRef: acc.codRef,
        family: acc.family,
      },
      formula: acc.formula,
      model: this.gap.modelId,
      type: 'Profile',
      typeId: this.crossbar.uuid,
      ...(acc.group ? { accessorySerieGroupId: acc.group } : {}),
    }

    return new Promise((resolve, reject) => {
      this.accessoryService.setAccessoryModelToEntity(request).subscribe({
        next: (res) => {
          resolve()
        },
        error: (err) => {
          reject(err)
        },
      })
    })
  }
}
