import { Injectable } from '@angular/core'
import Konva from 'konva'
import * as uuidGenerator from 'uuid'

import {
  CompleteProfile,
  IOpeningWindowDoor,
  SuperpositionCutVariation,
} from 'src/app/@shared/@interfaces/openingObject'
import { AreaModel } from 'src/app/@shared/@models/modelsElements/area-model'
import {
  ProfileElement,
  ProfileElementCreator,
} from 'src/app/@shared/@models/modelsElements/Profile-model'
import { TipupLeaftOpening } from 'src/app/@shared/@models/modelsElements/door-window-leaft-model'
import { SideOptions } from 'src/app/@shared/@types/side.types'
import { Vector2 } from 'three'

import { CutVariationsDrawerService } from './cut-variations-drawer.service'
import {
  IPseudoFrameCreatorStartInt,
  PseudoFrameDrawerService,
  SidePseudoFrameCreatorStartInt,
} from './pseudo-frame-drawer.service'
import { SuperpositionDrawerService } from './superposition-drawer.service'
import { OpeningService } from '../opening.service'
import { ProfileDrawerCreatorService } from './profile-drawer-creator.service'
import { ProfileCreator } from 'src/app/@shared/@interfaces/profileCreator.model'
import { reflexRespectHorizontalLine } from 'src/app/@helper/reflexRespectHorizontalLine'

import { Frame } from 'src/app/@shared/@models/modelsElements/frame-model'
import { UnionPositions } from 'src/app/@shared/@types/unionPositions.type'
import { UnionService } from '../union.service'
import {
  DoorWindowLeaftSideElement,
  DoorWindowLeaftSideElementCreator,
} from 'src/app/@shared/@models/modelsElements/doorwindow-leaft-side.model'
import { InversorElement } from 'src/app/@shared/@models/modelsElements/InversorElement.model'
import { DoorStepElement } from 'src/app/@shared/@models/modelsElements/doorstepElement.model'
import { DriptrayElement } from 'src/app/@shared/@models/modelsElements/driptrayElement.model'

@Injectable({
  providedIn: 'root',
})
export class TipupOpeningDrawService {
  constructor(
    private pseudoFrameService: PseudoFrameDrawerService,
    private superpositionDrawer: SuperpositionDrawerService,
    private cutVariationDrawer: CutVariationsDrawerService,
    private profileDrawer: ProfileDrawerCreatorService,
    private openingService: OpeningService,
    private unionService: UnionService
  ) {}

  public windowDoorDrawer(
    data: IOpeningWindowDoor,
    area: AreaModel,
    offset = 0,
    isFirstLeaft = true,
    isLastLeaft = true
  ): TipupLeaftOpening {
    const requestPseudoFrameCreator: IPseudoFrameCreatorStartInt = {
      has: {
        top: true,
        bottom: true,
        left: true,
        right: true,
      },
      scaleFactor: area.gap.scaleWidth,
      sides: this.pseudoFrameCreatorObjects(data, area),
      superpositions: {
        top: data.profiles.top.superposition,
        bottom: data.profiles.bottom.superposition,
        left: data.profiles.left.superposition,
        right: data.profiles.right.superposition,
      },
      cutVariations: {
        top: data.profiles.top.cutVariation,
        bottom: data.profiles.bottom.cutVariation,
        left: data.profiles.left.cutVariation,
        right: data.profiles.right.cutVariation,
      },
      unions: this.setUnions(data),
    }

    // Create pseudoframe
    const profiles = this.pseudoFrameService.createPseudoFrameFromStartInt(
      requestPseudoFrameCreator
    )

    // Apply superpositions
    this.superpositionDrawer.applySuperposition(
      {
        'left-bottom': 1,
        'left-top': 1,
        'right-bottom': 1,
        'right-top': 1,
      },
      profiles,
      {
        top: data.profiles.top.superposition,
        bottom: data.profiles.bottom.superposition,
        left: isFirstLeaft ? data.profiles.left.superposition : 0,
        right: isLastLeaft ? data.profiles.right.superposition : 0,
      },
      area.gap.scaleWidth
    )

    // Apply cut variations
    this.cutVariationDrawer.applyCutVariations(
      {
        top: data.profiles.top.cutVariation,
        bottom: data.profiles.bottom.cutVariation,
        left: data.profiles.left.cutVariation,
        right: data.profiles.right.cutVariation,
      },
      {
        top: {
          profile: data.profiles.top.profile,
          profileDraw: profiles.top,
        },
        bottom: {
          profile: data.profiles.bottom.profile,
          profileDraw: profiles.bottom,
        },
        left: {
          profile: data.profiles.left.profile,
          profileDraw: profiles.left,
        },
        right: {
          profile: data.profiles.right.profile,
          profileDraw: profiles.right,
        },
      },
      {
        top: data.profiles.top.profile.internalCamera,
        bottom: data.profiles.bottom.profile.internalCamera,
        left: data.profiles.left.profile.internalCamera,
        right: data.profiles.right.profile.internalCamera,
      },
      'Frame',
      area.gap.scaleWidth
    )

    const profileElements = this.createProfileElements(data, area, profiles, {
      top: requestPseudoFrameCreator.sides.top.startPointInt,
      bottom: requestPseudoFrameCreator.sides.bottom.startPointInt,
      left: requestPseudoFrameCreator.sides.left.startPointInt,
      right: requestPseudoFrameCreator.sides.right.startPointInt,
    })

    const leaftElement = this.generateLeaftElement(
      data,
      area,
      profileElements as any
    )

    this.setLengthOnLateralHandleDoor(data, leaftElement)

    if (!data.isMain && data.inversor?.profile) {
      const isOpeningLeft = leaftElement.isOpenLeft()
      const inversorProfile = data.inversor.profile

      const profileInversor = this.createInversor(
        isOpeningLeft ? profileElements.right : profileElements.left,
        inversorProfile,
        offset,
        data.inversor.superpositionCutVariation,
        data.profiles.top?.profile,
        data.profiles.bottom?.profile
      )

      leaftElement.addInversor(profileInversor)
    }

    if (data?.dryTray?.profile) {
      const drytray: ProfileElement = this.createDriptray(
        profileElements.bottom,
        data.dryTray
      )

      leaftElement.addDripTray(drytray)
    }

    area.layer.add(leaftElement.group)

    leaftElement.drawOpening()
    leaftElement.drawHandleDoor()
    leaftElement.drawHinge()

    return leaftElement
  }

  private pseudoFrameCreatorObjects(
    opening: IOpeningWindowDoor,
    area: AreaModel
  ): { [k in SideOptions]: SidePseudoFrameCreatorStartInt } {
    const scaleFactor = area.gap.scaleWidth
    const gapPosition = new Vector2(area.gap.xScaled, area.gap.yScaled)
    const areaScaledPosition = new Vector2(
      area.x * scaleFactor,
      area.y * scaleFactor
    )

    // Calculate lengths
    const lengths = {
      top: area.width,
      bottom: area.width,
      left: area.height,
      right: area.height,
    }

    // Calculate profileThickness or width
    const widths = {
      top: opening.profiles.top?.profile.internalCamera,
      bottom: opening.profiles.bottom?.profile.internalCamera,
      left: opening.profiles.left?.profile.internalCamera,
      right: opening.profiles.right?.profile.internalCamera,
    }

    // Profiles
    const profiles = {
      top: opening.profiles.top?.profile,
      bottom: opening.profiles.bottom?.profile,
      left: opening.profiles.left?.profile,
      right: opening.profiles.right?.profile,
    }

    // Calculate startPointInt
    const startPointsInt = {
      top: new Vector2(areaScaledPosition.x, areaScaledPosition.y).add(
        gapPosition
      ),
      bottom: new Vector2(
        areaScaledPosition.x,
        areaScaledPosition.y + area.height * scaleFactor
      ).add(gapPosition),
      left: new Vector2(areaScaledPosition.x, areaScaledPosition.y).add(
        gapPosition
      ),
      right: new Vector2(
        areaScaledPosition.x + area.width * scaleFactor,
        areaScaledPosition.y
      ).add(gapPosition),
    }

    return {
      top: {
        length: lengths.top,
        profile: profiles.top,
        startPointInt: startPointsInt.top,
        width: widths.top,
      },
      bottom: {
        length: lengths.bottom,
        profile: profiles.bottom,
        startPointInt: startPointsInt.bottom,
        width: widths.bottom,
      },
      left: {
        length: lengths.left,
        profile: profiles.left,
        startPointInt: startPointsInt.left,
        width: widths.left,
      },
      right: {
        length: lengths.right,
        profile: profiles.right,
        startPointInt: startPointsInt.right,
        width: widths.right,
      },
    }
  }

  private generateLeaftElement(
    data: IOpeningWindowDoor,
    area: AreaModel,
    profilesElements: { [k in SideOptions]: ProfileElement }
  ): TipupLeaftOpening {
    return new TipupLeaftOpening(
      uuidGenerator.v4(),
      data.openingType.id,
      data.doorhandleHeight,
      area,
      profilesElements,
      this.openingService.openingTypesAbisagradas,
      data.isMain
    )
  }

  private createProfileElements(
    data: IOpeningWindowDoor,
    area: AreaModel,
    lines: { [k in SideOptions]: Konva.Line },
    startPositions: { [k in SideOptions]: Vector2 }
  ): { [k in SideOptions]?: ProfileElement } {
    const { gap } = area
    const { scaleWidth: scaleFactor } = gap

    let profilesElements: { [k in SideOptions]?: ProfileElement } = {}

    Object.keys(data.profiles).forEach((side: SideOptions) => {
      const profileCreator = data.profiles[side].profile

      const profileElementCreator: DoorWindowLeaftSideElementCreator = {
        gap,
        profile: data.profiles[side].profile,
        profileDraw: lines[side],
        scaleFactor,
        side: side,
        thickness: profileCreator.internalCamera,
        unions: {
          'left-bottom': 1,
          'left-top': 1,
          'right-bottom': 1,
          'right-top': 1,
        },
        uuid: uuidGenerator.v4(),
        axis: side === 'bottom' || side === 'top' ? 'Horizontal' : 'Vertical',
        x: startPositions[side].x,
        y: startPositions[side].y,
        cutVariation: data.profiles[side].cutVariation,
        overlap: data.profiles[side].superposition,
      }

      profilesElements[side] = new DoorWindowLeaftSideElement(
        profileElementCreator
      )
    })

    return profilesElements
  }

  private createDriptray(
    bottomLeaftFrame: ProfileElement,
    profile?: CompleteProfile
  ): DriptrayElement {
    if (!profile.profile) return
    const { startInt } = bottomLeaftFrame.pointsAsVector()
    const {
      startExt: externalFinStartExt,
      endExt: externalFinEndExt,
    } = bottomLeaftFrame.pointsAsVector(
      bottomLeaftFrame.externalFinDraw.points()
    )

    const scaleFactor = bottomLeaftFrame.scaleFactor
    console.log('scaleFactor', scaleFactor)
    const length =
      Math.abs(externalFinStartExt.x - externalFinEndExt.x) / scaleFactor

    const profileDraw = this.profileDrawer.createHorizontalProfileFromStartInt({
      profile: bottomLeaftFrame.profile,
      profileLength: length,
      profileWidth: bottomLeaftFrame.profile.internalCamera,
      scaleFactor,
      startPointInt: new Vector2(externalFinStartExt.x, startInt.y).add(
        new Vector2(0, profile?.superpositionCutVariation?.overlap ?? 0)
      ),
    })

    const newPoints = reflexRespectHorizontalLine(
      profileDraw.points(),
      startInt.y
    )

    profileDraw.points(newPoints)

    this.cutVariationDrawer.applyCutVariations(
      {
        bottom: profile?.superpositionCutVariation?.cutVariation ?? {
          id: 1,
          value: 0,
        },
      },
      {
        bottom: {
          profile: profile.profile,
          profileDraw,
        },
      },
      {
        bottom: profile.profile.internalCamera,
      },
      'Covergap',
      scaleFactor
    )

    const profileElement = new DriptrayElement({
      profile: profile.profile,
      gap: bottomLeaftFrame.gap,
      profileDraw: profileDraw,
      scaleFactor: scaleFactor,
      side: 'bottom',
      thickness: profile.profile.internalCamera,
      unions: {},
      uuid: uuidGenerator.v4(),
      x: startInt.x,
      y: startInt.y,
      axis: 'Horizontal',
      overlap: profile?.superpositionCutVariation?.overlap ?? 0,
      cutVariation: profile?.superpositionCutVariation?.cutVariation ?? {
        id: 1,
        value: 0,
      },
    })

    return profileElement
  }

  private createInversor(
    lateralProfile: ProfileElement,
    profile: ProfileCreator,
    openingOffset: number,
    superposition: SuperpositionCutVariation,
    topProfileData: ProfileCreator,
    bottomProfileData: ProfileCreator
  ) {
    const side = lateralProfile.side
    const scaleFactor = lateralProfile.scaleFactor

    const { profile: lateralProfileData } = lateralProfile

    const {
      startInt: externalPointStart,
      endInt: externalPointEnd,
    } = lateralProfile.pointsAsVector(lateralProfile.externalFinDraw.points())

    const scaledExternalLength =
      Math.abs(externalPointStart.y - externalPointEnd.y) / scaleFactor -
      (topProfileData?.externalFin + bottomProfileData?.externalFin)
    const profileThickness = profile.thicknessTotal

    let profileStartPoint = externalPointStart
      .clone()
      .add(
        new Vector2(0, Math.abs(externalPointStart.y - externalPointEnd.y) / 2)
      )
      .add(new Vector2(0, -(scaledExternalLength / 2) * scaleFactor))

    if (side === 'right') {
      profileStartPoint.add(
        new Vector2((openingOffset + profileThickness) / 2, 0).multiplyScalar(
          scaleFactor
        )
      )
    }

    if (side === 'left') {
      profileStartPoint.add(
        new Vector2((profileThickness - openingOffset) / 2, 0).multiplyScalar(
          scaleFactor
        )
      )
    }

    const profileDraw = this.profileDrawer.createVerticalProfileFromStartInt({
      scaleFactor,
      profile: profile,
      profileLength: scaledExternalLength,
      profileWidth: profile.internalCamera,
      startPointInt: profileStartPoint,
    })

    this.cutVariationDrawer.applyCutVariations(
      {
        ...(side === 'right'
          ? { right: superposition.cutVariation }
          : { left: superposition.cutVariation }),
      },
      {
        ...(side === 'right'
          ? { right: { profile, profileDraw } }
          : { left: { profile, profileDraw } }),
      },
      {
        ...(side === 'right'
          ? { right: profile.internalCamera }
          : { left: profile.internalCamera }),
      },
      'Covergap',
      scaleFactor
    )

    const profileElement = new InversorElement({
      gap: lateralProfile.gap,
      profile: profile,
      profileDraw,
      scaleFactor: lateralProfile.scaleFactor,
      side: 'left',
      thickness: profile.internalCamera,
      unions: {},
      uuid: uuidGenerator.v4(),
      x: profileStartPoint.x,
      y: profileStartPoint.y,
      axis: 'Vertical',
      overlap: superposition?.overlap ?? 0,
      cutVariation: superposition?.cutVariation ?? { id: 1, value: 0 },
    })

    return profileElement
  }

  private setUnions(
    data: IOpeningWindowDoor
  ): { [k in UnionPositions]: number } {
    const unionTypes = this.unionService.unionsIds

    let unions: { [k in UnionPositions] } = {
      'left-top': unionTypes.cut45,
      'left-bottom': unionTypes.cut45,
      'right-top': unionTypes.cut45,
      'right-bottom': unionTypes.cut45,
    }

    const hasLateralChange = data.hasChangeLateral
    const isOpenLeft = this.isOpenLeft(data.openingType.id)

    if (!hasLateralChange) return unions

    if (isOpenLeft) {
      unions['right-bottom'] = unionTypes.cut90Vertical
      unions['right-top'] = unionTypes.cut90Vertical
    }

    if (!isOpenLeft) {
      unions['left-top'] = unionTypes.cut90Vertical
      unions['left-bottom'] = unionTypes.cut90Vertical
    }

    return unions
  }

  private setLengthOnLateralHandleDoor(
    data: IOpeningWindowDoor,
    tipupOpening: TipupLeaftOpening
  ) {
    if (!data.hasChangeLateral) return

    const lateralProfileElement = tipupOpening.getHandleDoorLateral()
    if (!lateralProfileElement) return

    const scaleFactor = lateralProfileElement.scaleFactor
    const {
      startExt,
      startInt,
      endExt,
      endInt,
    } = lateralProfileElement.pointsAsVector()

    const newStartExt = startExt
      .clone()
      .add(new Vector2(0, -data.profiles.top.profile.externalFin * scaleFactor))

    const newStartInt = startInt
      .clone()
      .add(new Vector2(0, -data.profiles.top.profile.externalFin * scaleFactor))

    const newEndExt = endExt
      .clone()
      .add(
        new Vector2(0, data.profiles.bottom.profile.externalFin * scaleFactor)
      )

    const newEndInt = endInt
      .clone()
      .add(
        new Vector2(0, data.profiles.bottom.profile.externalFin * scaleFactor)
      )

    const startLengthDiff = startExt.y - newStartExt.y
    const endLengthDiff = -(endExt.y - newEndExt.y)

    const lengthDiff = startLengthDiff + endLengthDiff

    lateralProfileElement.move(newStartExt.x, newStartExt.y)
    lateralProfileElement.resize(
      lateralProfileElement.scaledLenght + lengthDiff
    )
  }

  public createDoorStep(
    doorstep: CompleteProfile,
    area: AreaModel
  ): DoorStepElement {
    const gap = area.gap
    const scaleFactor = gap.scaleWidth
    const length = area.width
    console.log('area.gap', area.gap)

    const startPoint = new Vector2(
      gap.xScaled + area._x,
      gap.yScaled + area._y + area.height * scaleFactor
    ).add(
      new Vector2(
        0,
        doorstep?.superpositionCutVariation?.overlap ?? 0
      ).multiplyScalar(-1)
    )

    const profileDraw = this.profileDrawer.createHorizontalProfileFromStartInt({
      profile: doorstep.profile,
      profileLength: length,
      profileWidth: doorstep.profile.internalCamera,
      scaleFactor,
      startPointInt: startPoint,
    })

    this.profileDrawer.reflexHorizontalProfile(
      startPoint.y - (doorstep.profile.internalCamera / 2) * scaleFactor,
      profileDraw
    )

    this.cutVariationDrawer.applyCutVariations(
      {
        bottom: doorstep?.superpositionCutVariation?.cutVariation ?? {
          id: 1,
          value: 0,
        },
      },
      {
        bottom: {
          profile: doorstep.profile,
          profileDraw,
        },
      },
      {
        bottom: doorstep.profile.internalCamera,
      },
      'Crossbar',
      scaleFactor
    )

    const profileElement = new DoorStepElement({
      gap,
      profile: doorstep.profile,
      profileDraw,
      scaleFactor,
      side: 'bottom',
      thickness: doorstep.profile.internalCamera,
      unions: {},
      uuid: uuidGenerator.v4(),
      x: startPoint.x,
      y: startPoint.y,
      axis: 'Horizontal',
      cutVariation: doorstep?.superpositionCutVariation?.cutVariation ?? {
        id: 1,
        value: 1,
      },
      overlap: doorstep.superpositionCutVariation?.overlap ?? 0,
    })

    return profileElement
  }

  public isOpenLeft(openingType: number): boolean {
    const isOpenLeft = [2, 4, 6].includes(openingType)
    return isOpenLeft
  }
}
