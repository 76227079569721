<div class="wrapper">
  <div class="section">
    <h4>Junquillo</h4>
    <div class="header">
      <span>{{ selected.side }}</span>

      <button
        class="leafts-title-carousel-icon previus"
        (click)="prev(selected.selectedIndex)"
      >
        <fa-icon [icon]="faChevronLeft"></fa-icon>
      </button>
      <button
        class="leafts-title-carousel-icon next"
        (click)="next(selected.selectedIndex)"
      >
        <fa-icon [icon]="faChevronRight"></fa-icon>
      </button>
    </div>

    <div class="table-container">
      <table class="table-long">
        <thead>
          <tr>
            <th>Exterior</th>
            <th>Interior</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ selected.profileElement.calculateLength().ext }}</td>
            <td>{{ selected.profileElement.calculateLength().int }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
