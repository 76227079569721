import { Component, Input, OnInit } from '@angular/core'
import { AccesoriesGruopsService } from 'src/app/@services/accesories-gruops.service'
import {
  AccesorieGroupItem,
  AccesoryGroupCreator,
} from 'src/app/@shared/@interfaces/accesoryGroup'

import { faPlus, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { DialogInstanceModel } from 'src/app/@shared/@models/dialog-instance-model'
import { NewAccesoriesCombinationDialogComponent } from 'src/app/@dialogs/new-accesories-combination/new-accesories-combination.component'
import { ModalService } from 'src/app/@services/modal.service'
import {
  Accesory,
  AccesoryWithFormula,
} from 'src/app/@shared/@interfaces/accesory'
import { NewAccesoriesFormulaComponent } from 'src/app/@dialogs/new-accesories-formula/new-accesories-formula.component'
import { ToastrService } from 'ngx-toastr'
import { DeleteItemDialogComponent } from 'src/app/@dialogs/delete-item-dialog/delete-item-dialog.component'

@Component({
  selector: 'app-accessorie-group-table',
  templateUrl: './accessorie-group-table.component.html',
  styleUrls: ['./accessorie-group-table.component.scss'],
})
export class AccessorieGroupTableComponent implements OnInit {
  @Input() public groupId: number

  public accesories: AccesorieGroupItem[] = []
  public filteredAccesories: AccesorieGroupItem[] = []

  // Icons
  public faPlus = faPlus
  public faEdit = faEdit
  public faTrashAlt = faTrashAlt

  constructor(
    private accesorieGroupService: AccesoriesGruopsService,
    private modalService: ModalService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.getAccesories()
  }

  private getAccesories(): void {
    this.accesorieGroupService
      .getAccesoriesByGroup(this.groupId)
      .subscribe((accessories) => {
        this.accesories = accessories
        this.search()
      })
  }

  public async addAccessoriy(): Promise<void> {
    const selectedAccesory = await this.openSelectAccessoryModal()
    const accesoryWithFormula = await this.openSelectFormulaModal(
      selectedAccesory
    )

    const newGroupItem = {
      accessory: accesoryWithFormula.accesory,
      accessorySerieGroup: { id: this.groupId },
      choose: 1,
      formula: accesoryWithFormula.formula.function,
      id: null,
    }

    const id = await this.createAccesory(newGroupItem)
    newGroupItem.id = id

    this.accesories.push(newGroupItem)
    this.search()
  }

  public async editAccesory(accesory: AccesorieGroupItem): Promise<void> {
    const accesoryWithFormula = await this.openSelectFormulaModal({
      ...accesory.accessory,
    })

    const newAccesory: AccesorieGroupItem = {
      accessory: accesoryWithFormula.accesory,
      accessorySerieGroup: accesory.accessorySerieGroup,
      choose: accesory.choose,
      formula: accesoryWithFormula.formula.function,
      id: accesory.id,
    }

    this.updateAccesory(newAccesory)
  }

  public removeAccessory(accessory: AccesorieGroupItem): void {
    const deleteDialog = new DialogInstanceModel(DeleteItemDialogComponent, {
      initialState: {
        itemName: 'el accesorio',
      },
      class: 'modal-dialog-centered',
    })

    this.modalService.openModal(deleteDialog).subscribe((response) => {
      if (response) this.deleteAccessory(accessory.id)
    })
  }

  private openSelectAccessoryModal(): Promise<Accesory> {
    const selectAccesoryModal = new DialogInstanceModel(
      NewAccesoriesCombinationDialogComponent,
      {
        initialState: {
          accesory: 'Test',
        },
        // class: 'modal-dialog-centered',
      }
    )

    return new Promise((resolve, reject) => {
      this.modalService.openModal(selectAccesoryModal).subscribe(
        (accesory) => {
          resolve(accesory)
        },
        (err) => reject()
      )
    })
  }

  private openSelectFormulaModal(
    accesory: Accesory
  ): Promise<AccesoryWithFormula> {
    const formulaModal = new DialogInstanceModel(
      NewAccesoriesFormulaComponent,
      {
        initialState: {
          accesory: accesory,
          type: 'Profile',
        },
      }
    )

    return new Promise((resolve, reject) => {
      this.modalService
        .openModal(formulaModal)
        .subscribe((acc: AccesoryWithFormula) => {
          resolve(acc)
        })
    })
  }

  /**
   *
   * @param accesory new Accessory
   * @returns id of new Accessory
   */
  private createAccesory(accesory: AccesorieGroupItem): Promise<number> {
    const accessoryCreator: AccesoryGroupCreator = {
      accessorySerieGroup: accesory.accessorySerieGroup,
      choose: accesory.choose,
      formula: accesory.formula,
      codRef: accesory.accessory.codRef,
      family: accesory.accessory.family,
      type: accesory.accessory.type,
    }

    return this.accesorieGroupService
      .createAccesoryInGroup(accessoryCreator)
      .toPromise()
  }

  private deleteAccessory(accesoryId: number): void {
    if (!accesoryId) return

    this.accesorieGroupService.deleteAccesoryInGroup(accesoryId).subscribe(
      (id) => {
        this.toastr.success('Accesorio eliminado')
        this.accesories = this.accesories.filter((a) => a.id !== accesoryId)
        this.search()
      },
      (err) => {
        this.toastr.error('No se ha podido eliminar el accesorio')
      }
    )
  }

  private updateAccesory(newAccesory: AccesorieGroupItem): any {
    const newAccesoryCreator = {
      accessorySerieGroup: newAccesory.accessorySerieGroup,
      choose: newAccesory.choose,
      codRef: newAccesory.accessory.codRef,
      family: newAccesory.accessory.family,
      formula: newAccesory.formula,
      type: newAccesory.accessory.type,
    }

    this.accesorieGroupService
      .updateAccessory(newAccesoryCreator, newAccesory.id)
      .subscribe(
        (response) => {
          this.toastr.success('Accesorio actualizado')
          this.accesories = this.accesories.map((a) => {
            if (a.id === newAccesory.id) return newAccesory

            return a
          })
          this.search()
        },
        (err) => {
          this.toastr.success('No se ha podido actualizar el accesorio')
        }
      )
  }

  public search(criteria?: string): any {
    if (!criteria) return (this.filteredAccesories = this.accesories)
  }
}
