import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormArray, FormBuilder, Validators } from '@angular/forms'

// Modal
import { BsModalRef } from 'ngx-bootstrap/modal'

// Toastr
import { ToastrService } from 'ngx-toastr'

// Icons
import { faTimes } from '@fortawesome/free-solid-svg-icons'

// Interfaces
import {
  Accesory,
  AccesoryWithFormula,
} from 'src/app/@shared/@interfaces/accesory'
import { IDialog } from 'src/app/@shared/@interfaces/dialog'

// Service
import { ProfileAccesoriesCombinationService } from 'src/app/@services/profile-accesories-combination.service'

// Helper
import { replaceAll } from 'src/app/@helper/replaceAll'

@Component({
  selector: 'app-new-accesories-formula',
  templateUrl: './new-accesories-formula.component.html',
  styleUrls: ['./new-accesories-formula.component.scss'],
})
export class NewAccesoriesFormulaComponent implements IDialog, OnInit {
  @Output()
  $response: EventEmitter<AccesoryWithFormula> = new EventEmitter<AccesoryWithFormula>()

  public formulas: Formula[] = []
  public selectedFormula: Formula
  public formulaTemlplateBuilt: string

  // Inputs
  public accesory: Accesory
  public type: 'Profile' | 'Area' | 'Aperture' | 'Leaf'

  // Icons
  public faTimes = faTimes

  public form: FormArray

  constructor(
    private accesoriesService: ProfileAccesoriesCombinationService,
    public modalRef: BsModalRef,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form = this.fb.array([])
    this.getFormulas()
  }

  confirm(): void {
    if (this.isValidFormula()) {
      const accesoryWithFormula = {
        accesory: this.accesory,
        formula: {
          id: this.selectedFormula.id,
          function: this.formulaTemlplateBuilt,
          description: this.selectedFormula.description,
          prettyFunction: this.prettierOnlyInternal(),
        },
      }

      this.$response.emit(accesoryWithFormula)
      this.modalRef.hide()
      return
    }

    this.toastr.error('Debe establecer una fórmula válida')
  }
  decline(): void {
    this.modalRef.hide()
  }

  private isValidFormula(): boolean {
    if (!this.selectedFormula) return false
    if (this.formulaTemlplateBuilt) return true

    const hasUserVariables =
      this.selectedFormula?.formulaUserVariables.length > 0 ? true : false

    if (hasUserVariables) return false

    this.formulaTemlplateBuilt = this.selectedFormula?.template
    return true
  }

  private getFormulas(): void {
    this.accesoriesService.getFormulasByType(this.type).subscribe(
      (formulas) => {
        this.formulas = formulas
      },
      (err) => {}
    )
  }

  public createVariables(
    formula: Formula
  ): { userVariables: Variable[]; internalVariables: Variable[] } {
    const userVariables: Variable[] = []
    const internalVariables: Variable[] = []

    formula.formulaUserVariables.forEach((variable) => {
      userVariables.push({
        name: variable.name,
        key: variable.key,
        type: variable.type ?? 'number',
      })
    })

    formula.formulaInternalVariables.forEach((variable) => {
      internalVariables.push({
        name: variable.name,
        key: variable.key,
      })
    })

    return {
      userVariables,
      internalVariables,
    }
  }

  public createForm(formula: Formula): void {
    this.selectedFormula = formula

    this.formulaTemlplateBuilt = null
    this.resetFormVariables()

    const variables = this.createVariables(formula)

    this.addGroupToForm(variables.userVariables)
  }

  private addGroupToForm(variables: Variable[]): void {
    variables.forEach((variable) => {
      const newGroup = this.fb.group({
        name: [variable.name],
        key: [variable.key],
        type: [variable.type ?? 'number'],
        value: [null, [Validators.required]],
      })

      this.form.push(newGroup)
    })
  }

  private resetFormVariables(): void {
    this.form = this.fb.array([])
  }

  public prettierFormulaTemplate(formula: Formula): string {
    if (!formula) return null

    const variables = this.createVariables(formula)
    const totalVariables = [
      ...variables.userVariables,
      ...variables.internalVariables,
    ]

    let prettyTemplate = formula.template

    totalVariables.forEach((variable) => {
      prettyTemplate = replaceAll(prettyTemplate, variable.key, variable.name)
    })

    return prettyTemplate
  }

  public prettierOnlyInternal(): string {
    if (!this.selectedFormula) return null
    if (!this.formulaTemlplateBuilt)
      return this.prettierFormulaTemplate(this.selectedFormula)

    const variables = this.createVariables(this.selectedFormula)

    let templateCopy = this.formulaTemlplateBuilt ?? ''

    variables.internalVariables.forEach((variable) => {
      templateCopy = replaceAll(templateCopy, variable.key, variable.name)
    })

    return templateCopy
  }

  public buildFormula(): void {
    this.formulaTemlplateBuilt = this.selectedFormula.template

    this.form.controls.forEach((variableGroup) => {
      const key = variableGroup.get('key').value
      const value = variableGroup.get('value').value

      this.formulaTemlplateBuilt = replaceAll(
        this.formulaTemlplateBuilt,
        key,
        value
      )
    })
  }
}

interface Variable {
  name: string
  key: string
  type?: string
}

export interface Formula {
  id: number
  template: string
  description: string
  formulaUserVariables: Array<Variable>
  formulaInternalVariables: Array<Variable>
}
