// ANGULAR
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core'
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms'
import { DomSanitizer } from '@angular/platform-browser'
// TOOLS
import { BsModalRef } from 'ngx-bootstrap/modal'
// Fontawesome regular icons
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

// MODELS
import { ProfileFull } from 'src/app/@shared/@models/profile-full'
// SERVICES
import { SeriesService } from 'src/app/@services/series.service'
import {
  ProfileService,
  RequestGetProfiles,
} from './../../@services/profile.service'
import { ProfileUsesService } from 'src/app/@services/profile-uses.service'
import { CutVariation } from 'src/app/@shared/@models/cut-variation.model'
import { Serie } from 'src/app/@components/profile-management-editor/profile-management-editor.component'
import { ProfileCreator } from 'src/app/@shared/@interfaces/profileCreator.model'
import { debounceTime } from 'rxjs/operators'

@Component({
  selector: 'app-profile-dialog',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.scss'],
})
export class ProfileDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('profiledialog')
  private profileDialogDiv: ElementRef<HTMLDivElement>
  @ViewChild('floatingselect')
  private floatingFilterDiv: ElementRef<HTMLDivElement>

  // Inputs
  public sideName: string
  public serieId: number
  public onlyOne = false
  public usePreferred = 'marco'

  // Icons
  public faFolderOpen = faFolderOpen
  public faSearch = faSearch

  // Entities
  public filteredProfileList: Array<ProfileCreator> = []
  public profileList: Array<ProfileCreator> = []
  public serieObject: any
  public requestUseProfile: RequestGetProfiles
  public showProfileFlag: boolean
  public selectedProfile: any
  public profileImage: any
  public fullProfile: ProfileFull
  public cutVariations: CutVariation[] = []
  public series: Serie[] = []

  // Form
  public toolbarForm: FormGroup
  public filterForm: FormGroup
  public filterFlag: boolean
  public isFloatingModalInView = false

  // Search Criterias
  private searchDescription = ''
  private searchCode = ''

  @Output()
  $response: EventEmitter<any> = new EventEmitter<any>()

  constructor(
    public modalRef: BsModalRef,
    private seriesService: SeriesService,
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private profileUsesService: ProfileUsesService,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer
  ) {
    this.serieObject = {}
    this.filterFlag = true
    this.fullProfile = new ProfileFull()
  }
  ngAfterViewInit(): void {
    this.listenOnModalClick()
  }

  ngOnInit(): void {
    this.initComponent()
  }

  private async initComponent(): Promise<void> {
    this.getCutVariations()
    this.getSeries()
    this.getSerie()
    this.buildForms()
  }

  private listenOnModalClick(): void {
    this.renderer.listen(this.profileDialogDiv?.nativeElement, 'click', () => {
      this.closeFloatingFilter()
    })
  }

  public getSerie(): void {
    this.seriesService.getSerieById(this.serieId).subscribe(
      (response: any) => {
        this.serieObject = response
        this.getToolFormControl('serie').setValue(this.serieObject.id)
        this.getProfiles()
      },
      (error) => {
        console.dir(error)
      }
    )
  }

  private buildForms(): void {
    this.toolbarForm = this.formBuilder.group({
      serie: [this.serieId],
      superposition: [0],
      cutVariation: [1],
    })

    this.filterForm = this.formBuilder.group({
      filter: [3],
    })

    this.toolbarForm.valueChanges.subscribe((value) => {
      const isAllOfSerie = this.filterForm.get('filter').value === 1
      if (isAllOfSerie)
        return this.getProfiles({
          serie: this.getToolFormControl('serie').value,
        })
      this.getProfiles()
    })

    this.filterForm.get('filter').valueChanges.subscribe((value) => {
      this.filter(value)
      this.closeFloatingFilter()
    })
  }

  public getToolFormControl(controlName: string): AbstractControl {
    return this.toolbarForm.get(controlName)
  }

  public getProfiles(request?: RequestGetProfiles): void {
    request = request ?? {
      serie: this.getToolFormControl('serie').value,
      ...(this.filterFlag
        ? { use: this.usePreferred }
        : {
            cutVariation: this.getToolFormControl('cutVariation').value,
            overlap: this.getToolFormControl('superposition').value,
          }),
    }

    this.profileService.getProfiles(request).subscribe(
      (response: any) => {
        this.profileList = response
        this.filteredProfileList = this.profileList
        this.searchByDescription()
      },
      (error) => {
        console.dir(error)
      }
    )
  }

  public filter(value: number): void {
    if (value === 3) {
      this.filterFlag = true
      this.getProfiles()
      return
    }

    if (value === 1) {
      this.filterFlag = false
      this.toolbarForm.disable()
      this.getToolFormControl('serie').enable()
      this.getProfiles({ serie: this.getToolFormControl('serie').value })
    }
  }

  public openFloatinFilter(event: MouseEvent): void {
    if (this.isFloatingModalInView) return this.closeFloatingFilter()

    setTimeout(() => {
      this.renderer.setStyle(
        this.floatingFilterDiv.nativeElement,
        'display',
        'flex'
      )
      this.renderer.setStyle(
        this.floatingFilterDiv.nativeElement,
        'top',
        event.y + 16 + 'px'
      )
      this.renderer.setStyle(
        this.floatingFilterDiv.nativeElement,
        'left',
        event.x - 20 + 'px'
      )
      this.isFloatingModalInView = true
    }, 0)
  }

  public closeFloatingFilter(): void {
    this.floatingFilterDiv.nativeElement.style.display = 'none'
    this.isFloatingModalInView = false
  }

  public toggleProfile(profile: any): void {
    if (this.selectedProfile === profile) {
      this.showProfileFlag = false
      this.selectedProfile = null
    } else {
      this.selectedProfile = profile
      this.getProfilePng(profile.id)
    }
  }

  public getProfilePng(profileId: number): void {
    this.profileService.getProfilePng(profileId).subscribe(
      (blob: any) => {
        let objectURL = URL.createObjectURL(blob)
        this.profileImage = this.sanitizer.bypassSecurityTrustUrl(objectURL)
        this.showProfileFlag = true
        this.fullProfile.profile = this.selectedProfile
        this.fullProfile.profilePng = this.profileImage
      },
      (error) => {
        this.fullProfile.profile = this.selectedProfile
        console.dir(error)
      }
    )
  }

  public save(all?: boolean): void {
    if (all) {
      this.fullProfile.profile.all = all
    }

    this.$response.emit(this.fullProfile)
    this.modalRef.hide()
  }

  private async getCutVariations(): Promise<void> {
    await this.profileUsesService
      .getCutVariations()
      .toPromise()
      .then((cuts: CutVariation[]) => {
        this.cutVariations = cuts
      })
  }

  private getSeries(): void {
    this.seriesService.getSeries().subscribe((series: Serie[]) => {
      this.series = series
    })
  }

  // Search
  public searchByDescription(criteria?: string): void {
    criteria = criteria ?? this.searchDescription
    this.searchDescription = criteria

    if (!criteria) {
      this.filteredProfileList = [...this.profileList]
      if (this.searchCode) this.searchByCode()
      return
    }

    criteria = criteria.toLowerCase()

    this.filteredProfileList = this.filteredProfileList.filter((p) => {
      const description = p.description.toLowerCase()
      return description.includes(criteria)
    })
  }

  public searchByCode(code?: string): void {
    code = code ?? this.searchCode
    this.searchCode = code

    if (!code) {
      this.filteredProfileList = [...this.profileList]
      if (this.searchByDescription) this.searchByDescription()
      return
    }

    code = code.toLowerCase()

    this.filteredProfileList = this.filteredProfileList.filter((p) => {
      const profileCode = p.code.toLowerCase()
      return profileCode.includes(code)
    })
  }
}
