import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { EntityInformationComponent } from './entity-information.component'
import { EntityInformationViewComponentDirective } from './entity-information-view-component.directive'

@NgModule({
  declarations: [
    EntityInformationComponent,
    EntityInformationViewComponentDirective,
  ],
  exports: [EntityInformationComponent],
  providers: [EntityInformationComponent],
  imports: [CommonModule],
})
export class EntityInformationModule {}
