// ANGULAR
import { Injectable } from '@angular/core'

// MODELS
import { Coordinates } from '../@shared/@interfaces/coordinates'
import { Entity } from '../@shared/@interfaces/dxf-content'

@Injectable({
  providedIn: 'root',
})
export class DxfTransformationService {
  constructor() {}

  public degreeToRadian = (degree: number): number => {
    const radians = (degree * Math.PI) / 180
    return radians
  }

  public rotateVector(
    degrees: number,
    vertex: any
  ): { x: number; y: number; z?: number; bulge?: number } {
    const rotationAngle = this.degreeToRadian(degrees)
    const newX =
      vertex.x * Math.cos(rotationAngle) - vertex.y * Math.sin(rotationAngle)
    const newY =
      vertex.x * Math.sin(rotationAngle) + vertex.y * Math.cos(rotationAngle)
    if (vertex.bulge) {
      return {
        x: newX,
        y: newY,
        z: 0,
        bulge: vertex.bulge,
      }
    }

    return {
      x: newX,
      y: newY,
      z: 0,
    }
  }

  public rotateVertex(degrees, entity: Entity): Entity {
    const newEntity = { ...entity }
    const vertices = []

    entity.vertices.forEach((vertex, i) => {
      vertices.push(this.rotateVector(degrees, vertex))
    })
    newEntity.vertices = vertices
    return newEntity
  }

  public rotateCenter(degrees, entity: any): any {
    const newEntity = { ...entity }
    const center: Coordinates = this.rotateVector(degrees, entity.center)
    newEntity.center = center
    return newEntity
  }

  public rotateArc(degrees: any, entity: any): any {
    const newEntity = { ...this.rotateCenter(degrees, entity) }
    const rotationAngle = this.degreeToRadian(degrees)
    newEntity.startAngle += rotationAngle
    newEntity.endAngle += rotationAngle
    return newEntity
  }

  public rotatePosition(degrees, entity: any): void {
    const newEntity = { ...entity }
    const position: Coordinates = this.rotateVector(degrees, entity.position)
    newEntity.position = position
    return newEntity
  }

  public reflectVertex(entity: any): void {
    const newEntity = { ...entity }
    newEntity.vertices.forEach((vertices, index) => {
      if (vertices.bulge) {
        vertices.bulge = -vertices.bulge
      }
      vertices.x = -vertices.x
    })
    return newEntity
  }

  public reflectCenter(entity: any): void {
    const newEntity = { ...entity }
    newEntity.center.x = -newEntity.center.x
    return newEntity
  }

  public reflectPosition(entity: any) {
    const newEntity = { ...entity }
    newEntity.position.x = -newEntity.position.x
    return newEntity
  }

  public reflectArc(entity: any): void {
    const newEntity = { ...entity }
    const angleBetween = newEntity.angleLength
    const startAngleWithRespectYAxis = newEntity.startAngle - Math.PI / 2
    const endAngleWithRespectYAxis = newEntity.endAngle - Math.PI / 2

    const start =
      newEntity.startAngle - 2 * startAngleWithRespectYAxis - angleBetween
    const end = newEntity.endAngle - 2 * endAngleWithRespectYAxis + angleBetween

    newEntity.startAngle = start
    newEntity.endAngle = end

    return newEntity
  }
}
