import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-dropdown-input',
  templateUrl: './dropdown-input.component.html',
  styleUrls: ['./dropdown-input.component.scss'],
})
export class DropdownInputComponent implements OnInit {
  @Input() public title: string
  @Input() public items: Array<any>
  @Input() public buttonWidth = 120

  @Output()
  public selectItemEmitter: EventEmitter<any> = new EventEmitter<any>()

  public selectedItem: any

  constructor() {}

  ngOnInit(): void {}

  public onSelectItem(item: any): void {
    this.selectedItem = item
    this.selectItemEmitter.emit(item)
  }

  public isSelected(item: any): boolean {
    if (this.items.length === 0 || this.selectedItem === undefined) return false
    return item.id === this.selectedItem.id
  }
}
