import { Directive } from '@angular/core';

@Directive({
  selector: '[appViewComponentDirective]'
})
export class ViewComponentDirectiveDirective {

  constructor() { }

}
