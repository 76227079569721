export const accesoryTypeToDescription = (type: string) => {
  switch (type) {
    case 'P':
      return 'Pieza'
    case 'B':
      return 'Barra'
    case 'M':
      return 'Metros'

    default:
      return 'Pieza'
  }
}
