import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { radianToDegree } from '../@helper/radianToDegree'
import { roundUp } from '../@helper/roundNumber'
import { CrossbarElement } from '../@shared/@models/modelsElements/crossbar-model'

@Injectable({
  providedIn: 'root',
})
export class CrossbarService {
  private apiUrl = environment.apiUrl

  constructor(private http: HttpClient) {}

  public create(
    crossbar: CrossbarElement,
    model: number
  ): Observable<CrossbarCreator> {
    const { ext, int } = crossbar.calculateLength()
    let cutVariation = crossbar.cutVariation ?? { id: 1, value: 0, value2: 0 }

    const { mainAngle, secondAngle } = crossbar.getProfileAngles()

    const body: CrossbarCreator = {
      model,
      leftAreas: crossbar.leftArea.map((a) => a.id),
      rightAreas: crossbar.rightArea.map((a) => a.id),
      topAreas: crossbar.topArea.map((a) => a.id),
      bottomAreas: crossbar.bottomArea.map((a) => a.id),
      profile: {
        uuid: crossbar.uuid,
        typeVariation: { id: cutVariation?.id },
        cutVariation: cutVariation.value,
        cutVariation2: cutVariation.value2,
        extLong: ext,
        intLong: int,
        profile: { id: crossbar.profile.id },
        superimposition: crossbar.overlap,
        ang: roundUp(radianToDegree(mainAngle ?? 0), 2),
        ang2: roundUp(radianToDegree(secondAngle ?? 0), 2),
      },
    }

    return this.http.post<CrossbarCreator>(
      `${this.apiUrl}crossbars-profile`,
      body
    )
  }

  public delete(id: number): Promise<void> {
    return this.http
      .delete<void>(`${this.apiUrl}crossbars-profile/${id}`)
      .toPromise()
  }
}

interface CrossbarCreator {
  model: number
  id?: number
  leftAreas: number[]
  rightAreas: number[]
  topAreas: number[]
  bottomAreas: number[]
  profile: {
    uuid: string
    profile: {
      id: number
    }
    intLong: number
    extLong: number
    superimposition: number
    cutVariation: number
    cutVariation2: number
    typeVariation: {
      id: number
    }
    ang: number
    ang2: number
  }
}
