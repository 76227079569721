<div class="modal-header">
  <h4 *ngIf="!headerContent" class="modal-title pull-left">
    Eliminar {{ itemName }}
  </h4>
  <h4
    *ngIf="headerContent"
    class="modal-title pull-left"
    [innerHTML]="headerContent"
  ></h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="modalRef.hide()"
  >
    <img src="../../../assets/img/icons/close.svg" alt="Close modal icon" />
  </button>
</div>
<div class="modal-body">
  <h5 *ngIf="!bodyContent" class="body-title">
    ¿Estás seguro de querer borrar {{ itemName }}?
  </h5>
  <div *ngIf="bodyContent" class="body-content" [innerHTML]="bodyContent"></div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger mr-3" (click)="confirm()">
    Si
  </button>
  <button type="button" class="btn btn-success" (click)="decline()">No</button>
</div>
