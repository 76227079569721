<div class="btn-group dropdown-group" dropdown>
  <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle dropdown-button"
    aria-controls="dropdown-basic" [ngStyle]="{ 'max-width': buttonWidth + 'px' }">
    <div class="button-title">
      <span>{{ title }}</span>
    </div>
    <span class="caret"></span>
  </button>
  <ul *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
    <li role="menuitem" *ngFor="let item of items" (click)="onSelectItem(item)"
      [ngClass]="{ selected: isSelected(item) }" class="dropdown-item__li">
      <a class="dropdown-item">{{ item?.name || item?.description }}</a>
    </li>
  </ul>
</div>