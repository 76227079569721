<div class="table-responsive-sm content fade-in">
  <table class="table">
    <thead>
      <tr>
        <th scope="col">Código</th>
        <th scope="col">Descripción</th>
        <th scope="col">Opcional</th>
        <th scope="col">Uso</th>
        <th scope="col">Fórmula</th>
        <th scope="col">
          <form class="table-head-icons" [formGroup]="formSearch">
            <fa-icon
              [icon]="faPlus"
              class="table-head-icons__icon"
              (click)="addNew()"
            ></fa-icon>

            <input
              type="checkbox"
              formControlName="show"
              id="show-accesories"
              style="display: none"
            />
            <label for="show-accesories" class="table-head-icons__label">
              <fa-icon
                [icon]="faSearch"
                class="table-head-icons__icon"
                [ngClass]="{
                  dirty: formSearch.get('search').value ? true : false
                }"
              ></fa-icon>
            </label>
            <input
              type="text"
              formControlName="search"
              (keyup)="searchAccesories()"
              class="table-head-icons__input-search"
              [ngClass]="{
                'animated-grow': formSearch.get('show').value,
                hide: !formSearch.get('show').value
              }"
            />
          </form>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        class="table-row fade-in"
        *ngFor="let accesory of filteredAccesories; index as i"
      >
        <td class="dropdown">
          {{ accesory.accesory?.family }} - {{ accesory.accesory?.codRef }}
        </td>
        <td class="centered">{{ accesory.accesory?.denomination }}</td>
        <td>Sí</td>
        <td></td>
        <td class="centered">{{ accesory.formula.prettyFunction }}</td>
        <td>
          <div class="table-head-icons">
            <fa-icon
              [icon]="faEdit"
              class="table-head-icons__icon edit"
              (click)="updateAccesory(accesory.index)"
            ></fa-icon>
            <fa-icon
              [icon]="faTrash"
              class="table-head-icons__icon delete"
              (click)="deleteAccesory(accesory, accesory.index)"
            ></fa-icon>
          </div>
        </td>
      </tr>

      <tr
        class="table-row fade-in"
        *ngFor="let accesory of filteredAccesoriesDisasembled; index as i"
      >
        <td class="dropdown">{{ accesory.accesory.codRef }}</td>
        <td class="centered">{{ accesory.accesory.denomination }}</td>
        <td>Sí</td>
        <td></td>
        <td class="centered">{{ accesory.formula.prettyFunction }}</td>
        <td>
        </td>
      </tr>
    </tbody>
  </table>
</div>
