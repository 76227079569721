import { pointsToVectors2d } from './pointsTo2dVector'

export const reflexRespectVerticalLine = (
  points: number[],
  xLine: number
): number[] => {
  const vectors = pointsToVectors2d(points)

  const translatedVectors = vectors.map((v) => {
    return {
      x: v.x - xLine,
      y: v.y,
    }
  })

  const reflexVector = translatedVectors.map((v) => {
    return rotateRecpectYAxis(Math.PI, {
      x: v.x,
      y: v.y,
      z: 0,
    })
  })

  const newPoints = reflexVector.flatMap((v) => {
    return [v.x + xLine, v.y]
  })

  return newPoints
}

const rotateRecpectYAxis = (
  radians: number,
  vector: { x: number; y: number; z: number }
): { x: number; y: number; z: number } => {
  return {
    x: vector.x * Math.cos(radians) + vector.z * Math.sin(radians),
    y: vector.y,
    z: -vector.x * Math.sin(radians) + vector.z * Math.cos(radians),
  }
}
