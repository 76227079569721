<div class="frame-info-wrapper">
  <div class="marco-sides section">
    <h4>Marco</h4>
    <a>añadir accesorio</a>
    <div class="header">
      <span>{{ selected.selectedFrame.type }}</span>

      <button
        class="leafts-title-carousel-icon previus"
        (click)="previus(frameObjects, 'selectedFrameIndex', 'selectedFrame')"
      >
        <fa-icon [icon]="faChevronLeft"></fa-icon>
      </button>
      <button
        class="leafts-title-carousel-icon next"
        (click)="next(frameObjects, 'selectedFrameIndex', 'selectedFrame')"
      >
        <fa-icon [icon]="faChevronRight"></fa-icon>
      </button>
    </div>

    <div class="table-container">
      <table class="table-long">
        <thead>
          <tr>
            <th>Exterior</th>
            <th>Interior</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ selected.selectedFrame.profile.calculateLength().ext }}</td>
            <td>{{ selected.selectedFrame.profile.calculateLength().int }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="covergaps section" *ngIf="internalCovergapObjects.length > 0">
    <h4>Tapajuntas internos</h4>

    <div class="header">
      <span>{{ selected.selectedinternalCovergap.type }}</span>

      <button
        class="leafts-title-carousel-icon previus"
        (click)="
          previus(
            internalCovergapObjects,
            'selectedinternalCovergapIndex',
            'selectedinternalCovergap'
          )
        "
      >
        <fa-icon [icon]="faChevronLeft"></fa-icon>
      </button>
      <button
        class="leafts-title-carousel-icon next"
        (click)="
          next(
            internalCovergapObjects,
            'selectedinternalCovergapIndex',
            'selectedinternalCovergap'
          )
        "
      >
        <fa-icon [icon]="faChevronRight"></fa-icon>
      </button>
    </div>

    <div class="table-container">
      <table class="table-long">
        <thead>
          <tr>
            <th>Exterior</th>
            <th>Interior</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {{
                selected.selectedinternalCovergap.profile.calculateLength().ext
              }}
            </td>
            <td>
              {{
                selected.selectedinternalCovergap.profile.calculateLength().int
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="covergaps section" *ngIf="externalCovergapsObjects.length > 0">
    <h4>Tapajuntas externos</h4>

    <div class="header">
      <span>{{ selected.selectedexternalCovergap.type }}</span>

      <button
        class="leafts-title-carousel-icon previus"
        (click)="
          previus(
            externalCovergapsObjects,
            'selectedexternalCovergapIndex',
            'selectedexternalCovergap'
          )
        "
      >
        <fa-icon [icon]="faChevronLeft"></fa-icon>
      </button>
      <button
        class="leafts-title-carousel-icon next"
        (click)="
          next(
            externalCovergapsObjects,
            'selectedexternalCovergapIndex',
            'selectedexternalCovergap'
          )
        "
      >
        <fa-icon [icon]="faChevronRight"></fa-icon>
      </button>
    </div>

    <div class="table-container">
      <table class="table-long">
        <thead>
          <tr>
            <th>Exterior</th>
            <th>Interior</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {{
                selected.selectedexternalCovergap.profile.calculateLength().ext
              }}
            </td>
            <td>
              {{
                selected.selectedexternalCovergap.profile.calculateLength().int
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="associtated section" *ngIf="associatedObjects.length > 0">
    <h4>Asociados</h4>

    <div class="header">
      <span>{{ selected.selectedAssociated.type }}</span>

      <button
        class="leafts-title-carousel-icon previus"
        (click)="
          previus(
            associatedObjects,
            'selectedAssociatedIndex',
            'selectedAssociated'
          )
        "
      >
        <fa-icon [icon]="faChevronLeft"></fa-icon>
      </button>
      <button
        class="leafts-title-carousel-icon next"
        (click)="
          next(
            associatedObjects,
            'selectedAssociatedIndex',
            'selectedAssociated'
          )
        "
      >
        <fa-icon [icon]="faChevronRight"></fa-icon>
      </button>
    </div>

    <div class="table-container">
      <table class="table-long">
        <thead>
          <tr>
            <th>Exterior</th>
            <th>Interior</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {{ selected.selectedAssociated.profile.calculateLength().ext }}
            </td>
            <td>
              {{ selected.selectedAssociated.profile.calculateLength().int }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
