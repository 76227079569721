<div class="door-window-info-wrapper">
  <div class="title">
    <h5>Apertura puerta-ventana</h5>
  </div>

  <div class="leafts-wrapper">
    <div class="leafts">
      <div class="leafts-title-carousel" *ngIf="leaftSelected">
        <h5>Hoja {{ leaftSelected.index }}</h5>

        <button
          class="leafts-title-carousel-icon previus"
          (click)="previusLeaft()"
        >
          <fa-icon [icon]="faChevronLeft"></fa-icon>
        </button>
        <button class="leafts-title-carousel-icon next" (click)="nextLeaft()">
          <fa-icon [icon]="faChevronRight"></fa-icon>
        </button>
      </div>

      <div class="leaft-info">
        <span>manilla: {{ leaftSelected.isMain ? 'Sí' : 'No' }}</span>
      </div>
    </div>

    <div class="profiles-carousel fade-in" *ngIf="profileSelected">
      <div class="leafts-title-carousel">
        <h5>Perfil {{ profileSelected.type }}</h5>
        <button
          class="leafts-title-carousel-icon previus"
          (click)="previustProfile()"
        >
          <fa-icon [icon]="faChevronLeft"></fa-icon>
        </button>
        <button class="leafts-title-carousel-icon next" (click)="nextProfile()">
          <fa-icon [icon]="faChevronRight"></fa-icon>
        </button>
      </div>

      <div class="table-container">
        <span>Longitudes</span>
        <table class="table-long">
          <thead class="thead-dark">
            <tr>
              <th>Exterior</th>
              <th>Interior</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {{ this.profileSelected.profile.calculateLength().ext }} mm
              </td>
              <td>
                {{ this.profileSelected.profile.calculateLength().int }} mm
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
