import { Injectable } from '@angular/core'
import Konva from 'konva'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { Size } from 'src/app/@shared/@interfaces/size'
import { dataUrlToBlob } from '../@helper/dataUrlToBlob'
import { Gap } from '../@shared/@models/modelsElements/gap-model'
import { NewModelModel } from '../@shared/@models/new-model-model'
import { ModelService } from './model.service'

@Injectable({
  providedIn: 'root',
})
export class KonvaService {
  private scale: number
  private px: number
  private mm: number
  private strokeColor: string
  private strokePx = 1
  public readonly profileThickness = 20
  private canvaSize = new BehaviorSubject<Size>({ height: 0, width: 0 })
  public readonly canvaSize$ = this.canvaSize.asObservable()

  // Observables
  private createGapSubject: Subject<NewModelModel>
  public craeteGap$: Observable<NewModelModel>

  private addAreaSubject: Subject<Area | null>
  public addArea$: Observable<Area | null>

  private gapUpdatedSubject: Subject<Gap>
  public gapUpdated$: Observable<Gap>

  constructor(private modelService: ModelService) {
    this.scale = 12
    this.px = 3.7795275591
    this.mm = 0.2645833333
    this.strokeColor = '#24E'

    this.buildObservables()
  }

  get stroke(): number {
    return this.strokePx
  }

  parseMMtoPX(mm: number): any {
    return (mm / this.scale) * this.px
  }

  parsePXtoMM(px: number): any {
    return px * this.mm * this.scale
  }

  getStrokeColor(): any {
    return this.strokeColor
  }

  updateCanvaSize(size: Size) {
    this.canvaSize.next(size)
  }

  private buildObservables(): void {
    this.createGapSubject = new Subject<NewModelModel>()
    this.craeteGap$ = this.createGapSubject.asObservable()

    this.addAreaSubject = new Subject<Area>()
    this.addArea$ = this.addAreaSubject.asObservable()

    this.gapUpdatedSubject = new Subject<Gap>()
    this.gapUpdated$ = this.gapUpdatedSubject.asObservable()
  }

  // Interact with konva stage
  public createGap(model: NewModelModel): void {
    this.createGapSubject.next(model)
  }

  public addArea(area: Area | null): void {
    this.addAreaSubject.next(area)
  }

  public gapUpdated(gap: Gap): void {
    this.gapUpdatedSubject.next(gap)
  }

  public sendImageModelPreview(imageModelCreator: ImageModelCreatorDTO): void {
    const { gap, modelId, destroy: normalize } = imageModelCreator
    const destroy = normalize ? normalize : false

    if (!(gap || modelId)) return

    const { layer, stage } = gap
    if (!layer) return

    const copyStage: Konva.Stage = stage.clone({})
    copyStage.scale({ x: 1, y: 1 })

    const offset = 20

    const gapDimensions = {
      width: gap.width * gap.scaleWidth,
      height: gap.height * gap.scaleWidth,
    }

    const originalPositions = {
      x: -gap.xScaled + offset / 2,
      y: -gap.yScaled + offset / 2,
    }

    copyStage.position(originalPositions)

    const dimension =
      gapDimensions.width > gapDimensions.height
        ? gapDimensions.width
        : gapDimensions.height

    copyStage.width(dimension + offset)
    copyStage.height(dimension + offset)

    const fixedPosition = {
      x:
        originalPositions.x +
        (gapDimensions.height > gapDimensions.width
          ? copyStage.width() / 2 - gapDimensions.width / 2
          : 0),
      y:
        originalPositions.y +
        (gapDimensions.width > gapDimensions.height
          ? copyStage.height() / 2 - gapDimensions.height / 2
          : 0),
    }

    copyStage.position(fixedPosition)

    const blob = dataUrlToBlob(copyStage.toDataURL({ pixelRatio: 1 }))

    if (destroy) gap?.selectedArea?.unSelectArea()

    this.modelService
      .createPreviewImage(blob, modelId)
      .subscribe((response) => {})
  }
}

export interface Area {
  x: number
  y: number
  width: number
  height: number
  model?: {
    id: number
  }
}

interface ImageModelCreatorDTO {
  gap: Gap
  modelId: number
  destroy: boolean
}
