import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

// FontAwesome
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'

import { SideOptions } from 'src/app/@shared/@types/side.types'
import { AssociatedProfile } from '../associated-profiles-selector/associated-profiles-selector.component'
import { ProfileCreator } from 'src/app/@shared/@interfaces/profileCreator.model'
import { Overlaps } from '../overlaps/overlaps.component'

@Component({
  selector: 'app-associated-profiles-table',
  templateUrl: './associated-profiles-table.component.html',
  styleUrls: ['./associated-profiles-table.component.scss'],
})
export class AssociatedProfilesTableComponent implements OnInit {
  @Input() public associatedProfilesSide: AssociatedProfile[] = []
  @Input() public side: SideOptions

  @Output() public associatedProfilesSideEmitter = new EventEmitter<
    AssociatedProfile[]
  >()

  // Icons
  public faPlus = faPlus
  public faTrash = faTrash

  constructor() {}

  ngOnInit(): void {}

  public imageUnionUrl(id: number) {
    return `../../../assets/icons/unions/${id}.svg`
  }

  public addNewProfile(): void {
    this.associatedProfilesSide.push({
      profile: null,
      cutVariation: { id: 1, value: 0 },
      overlap: 0,
      union: 1,
    })
  }

  public removeProfile(index: number): void {
    this.associatedProfilesSide = this.associatedProfilesSide.filter(
      (p, i) => index !== i
    )
    this.emit()
  }

  public setProfile(index: number, profile: ProfileCreator): void {
    this.associatedProfilesSide[index].profile = profile
    this.emit()
  }

  public setCutVariationAndOverlap(index: number, data: Overlaps) {
    const profile = this.associatedProfilesSide[index]

    profile.overlap = data.overlap
    profile.cutVariation = data.cutVariation

    this.emit()
  }

  private emit(): void {
    this.associatedProfilesSideEmitter.emit(this.associatedProfilesSide)
  }
}
