import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-opening-images-grid',
  templateUrl: './opening-images-grid.component.html',
  styleUrls: ['./opening-images-grid.component.scss'],
})
export class OpeningImagesGridComponent implements OnInit {
  @Input() $images: { name?: string; id: number }[]
  @Input() openingSelectedId: number

  @Output() openingSelectedEmitter = new EventEmitter<number>()

  constructor() {}

  ngOnInit(): void {}

  public selectOpening(id: number): void {
    if (this.openingSelectedId === null) return

    this.openingSelectedId = id
    this.emit()
  }

  private emit(): void {
    this.openingSelectedEmitter.emit(this.openingSelectedId)
  }
}
