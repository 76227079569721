import Konva from 'konva'
import { Observable, Subject } from 'rxjs'
import { degreeToRadians } from 'src/app/@helper/degreeToRadians'
import { reflexRespectHorizontalLine } from 'src/app/@helper/reflexRespectHorizontalLine'
import { reflexRespectVerticalLine } from 'src/app/@helper/reflexRespectVerticalLine'
import { roundUp } from 'src/app/@helper/roundNumber'
import { Vector2 } from 'three'
import { ModelElement } from '../../@interfaces/modelElement'
import { ProfileCreator } from '../../@interfaces/profileCreator.model'
import { SideOptions } from '../../@types/side.types'
import { UnionPositions } from '../../@types/unionPositions.type'
import { Area } from './area-model'
import { ElementSelectable } from './elementSelectable'

import { Gap } from './gap-model'
import {
  FRAME_COLOR,
  FRAME_STROKE_COLOR,
  PROFILE_KONVA_NAMES,
} from './types-elements-names'

export class ProfileElement extends ElementSelectable implements ModelElement {
  protected readonly profileNames = PROFILE_KONVA_NAMES

  protected _id: number = null
  public readonly uuid: string

  public readonly scaleFactor: number

  protected _x: number
  protected _y: number

  protected _width: number
  protected _height: number

  protected _thickness: number

  public readonly profile: ProfileCreator

  public readonly profileDraw: Konva.Line

  public readonly unions: { [k in UnionPositions]?: number }

  public readonly gap: Gap

  protected _cutVariation: { id: number; value: number; value2?: number }
  protected _overlap = 0

  public readonly side: SideOptions
  public readonly axis: 'Vertical' | 'Horizontal'

  public group = new Konva.Group().addName('profile')

  public externalFinDraw: Konva.Line
  public internalFinDraw: Konva.Line

  public hoverProfile$: Observable<ProfileElement>

  public container: Area

  constructor(
    profileElement: ProfileElementCreator,
    addToLayer: boolean = true
  ) {
    super()
    this._id = profileElement.id
    this.uuid = profileElement.uuid

    this.scaleFactor = profileElement.scaleFactor ?? 1

    this._x = profileElement.x * this.scaleFactor
    this._y = profileElement.y * this.scaleFactor

    this._width = profileElement.width * this.scaleFactor
    this._height = profileElement.height * this.scaleFactor

    this._thickness = profileElement.thickness * this.scaleFactor

    this._cutVariation = profileElement.cutVariation
    this._overlap = profileElement.overlap

    this.profile = profileElement.profile
    this.profileDraw = profileElement.profileDraw
    this.unions = profileElement.unions
    this.gap = profileElement.gap
    this.side = profileElement.side
    this.axis = profileElement.axis

    this.drawExternalFin()
    this.drawInternalFin()
    this.profileDraw.name(this.profileNames.profile)
    this.group.add(this.profileDraw)

    this.hide()
    this.drawStokes()

    if (addToLayer) {
      this.gap.layer.add(this.group)
    }
  }

  get id() {
    return this._id
  }

  get x() {
    return this._x / this.scaleFactor
  }
  get y() {
    return this._y / this.scaleFactor
  }
  get height() {
    return this._height / this.scaleFactor
  }
  get width() {
    return this._width / this.scaleFactor
  }
  get thickness() {
    return this._thickness / this.scaleFactor
  }

  get lenght() {
    return this.length()
  }

  get scaledLenght() {
    return this.lenght * this.scaleFactor
  }

  get cutVariation() {
    return this._cutVariation
  }
  get overlap() {
    return this._overlap
  }

  public setId(id: number): void {
    this._id = id
  }

  /**
   * Clone profile element but not add to layer
   * @param uuid new uuid
   * @returns new ProfileElement which is not added to layer
   */
  public clone(uuid: string): ProfileElement {
    return new ProfileElement(
      {
        id: this.id,
        gap: this.gap,
        height: this.height,
        profile: this.profile,
        profileDraw: this.profileDraw.clone(),
        scaleFactor: this.scaleFactor,
        side: this.side,
        thickness: this.thickness,
        unions: this.unions,
        uuid: uuid,
        x: this.x,
        y: this.y,
        width: this.width,
        axis: this.axis,
        cutVariation: this.cutVariation,
        overlap: this.overlap,
      },
      false
    )
  }

  public isHorizontal(): boolean {
    return this.axis === 'Horizontal'
  }

  public isVertical(): boolean {
    return this.axis === 'Vertical'
  }

  protected length(): number {
    const isHorizonta = this.axis === 'Horizontal'
    const { startInt, endInt } = this.pointsAsVector()

    const length = isHorizonta ? startInt.x - endInt.x : startInt.y - endInt.y

    return Math.abs(length) / this.scaleFactor
  }

  /**
   * Move startExt Point element (fins and profile) to given (x, y) scaled position
   * @param x Scaled x position
   * @param y Scaled y position
   */
  public move(x: number, y: number): void {
    this._x = x
    this._y = y

    const { startExt } = this.pointsAsVector()

    const diff = {
      x: startExt.x - x,
      y: startExt.y - y,
    }

    this.moveProfile(this.profileDraw, diff)
    this.moveProfile(this.externalFinDraw, diff)
    this.moveProfile(this.internalFinDraw, diff)
    this.updateStrokes()
  }

  private moveProfile(profileDraw: Konva.Line, diff: { x: number; y: number }) {
    const { startExt, startInt, endExt, endInt } = this.pointsAsVector(
      profileDraw.points()
    )

    const xDiff = diff.x
    const yDiff = diff.y

    const start = {
      ext: startExt.add(new Vector2(xDiff, yDiff).multiplyScalar(-1)),
      int: startInt.add(new Vector2(xDiff, yDiff).multiplyScalar(-1)),
    }

    const end = {
      ext: endExt.add(new Vector2(xDiff, yDiff).multiplyScalar(-1)),
      int: endInt.add(new Vector2(xDiff, yDiff).multiplyScalar(-1)),
    }

    const newPoints = [
      start.ext.toArray(),
      start.int.toArray(),
      end.int.toArray(),
      end.ext.toArray(),
    ].flat()

    profileDraw.points(newPoints)
    return profileDraw
  }

  public pointsAsVector(
    points?: number[]
  ): {
    startExt: Vector2
    startInt: Vector2
    endExt: Vector2
    endInt: Vector2
  } {
    if (!this.profileDraw) {
      return {
        startExt: new Vector2(0, 0),
        startInt: new Vector2(0, 0),
        endInt: new Vector2(0, 0),
        endExt: new Vector2(0, 0),
      }
    }

    const profilePoints = points ?? this.profileDraw?.points() ?? []
    const startExt = new Vector2(profilePoints[0], profilePoints[1])
    const startInt = new Vector2(profilePoints[2], profilePoints[3])
    const endInt = new Vector2(profilePoints[4], profilePoints[5])
    const endExt = new Vector2(profilePoints[6], profilePoints[7])

    return {
      startExt,
      startInt,
      endExt,
      endInt,
    }
  }

  public draw(): void {
    this.profileDraw.setZIndex(6)
    this.gap.layer.draw()
  }

  private drawExternalFin() {
    const { startExt, endExt } = this.pointsAsVector()

    const { mainAngle, secondAngle } = this.getProfileAngles()

    const { externalFin } = this.profile

    let points = []

    if (this.axis === 'Horizontal') {
      const start = startExt
        .clone()
        .add(
          new Vector2(
            -externalFin / Math.tan(mainAngle),
            -externalFin
          ).multiplyScalar(this.scaleFactor)
        )
      const end = endExt
        .clone()
        .add(
          new Vector2(
            externalFin / Math.tan(secondAngle),
            -externalFin
          ).multiplyScalar(this.scaleFactor)
        )

      points = [
        startExt.toArray(),
        start.toArray(),
        end.toArray(),
        endExt.toArray(),
      ].flat()

      if (this.side === 'bottom') {
        points = reflexRespectHorizontalLine(points, startExt.y)
      }
    }

    if (this.axis === 'Vertical') {
      const start = startExt
        .clone()
        .add(
          new Vector2(
            -externalFin,
            -externalFin / Math.tan(mainAngle)
          ).multiplyScalar(this.scaleFactor)
        )

      const end = endExt
        .clone()
        .add(
          new Vector2(
            -externalFin,
            externalFin / Math.tan(secondAngle)
          ).multiplyScalar(this.scaleFactor)
        )

      points = [
        startExt.toArray(),
        start.toArray(),
        end.toArray(),
        endExt.toArray(),
      ].flat()

      if (this.side === 'right') {
        points = reflexRespectVerticalLine(points, startExt.x)
      }
    }

    const profile = new Konva.Line({
      points,
      fill: FRAME_COLOR,
      stroke: FRAME_STROKE_COLOR,
      strokeWidth: 0.5,
      name: this.profileNames.externalFin,
      closed: true,
    })

    this.externalFinDraw = profile

    this.group.add(profile)
  }

  private drawInternalFin() {
    const { startInt, endInt } = this.pointsAsVector()
    const { mainAngle, secondAngle } = this.getProfileAngles()
    const { internalFin } = this.profile

    let points = []

    if (this.axis === 'Horizontal') {
      const start = startInt
        .clone()
        .add(
          new Vector2(
            internalFin / Math.tan(mainAngle),
            internalFin
          ).multiplyScalar(this.scaleFactor)
        )
      const end = endInt
        .clone()
        .add(
          new Vector2(
            -internalFin / Math.tan(secondAngle),
            internalFin
          ).multiplyScalar(this.scaleFactor)
        )

      points = [
        startInt.toArray(),
        start.toArray(),
        end.toArray(),
        endInt.toArray(),
      ].flat()

      if (this.side === 'bottom') {
        points = reflexRespectHorizontalLine(points, startInt.y)
      }
    }

    if (this.axis == 'Vertical') {
      const start = startInt
        .clone()
        .add(
          new Vector2(
            internalFin,
            internalFin / Math.tan(mainAngle)
          ).multiplyScalar(this.scaleFactor)
        )

      const end = endInt
        .clone()
        .add(
          new Vector2(
            internalFin,
            -internalFin / Math.tan(secondAngle)
          ).multiplyScalar(this.scaleFactor)
        )

      points = [
        startInt.toArray(),
        start.toArray(),
        end.toArray(),
        endInt.toArray(),
      ].flat()

      if (this.side === 'right') {
        points = reflexRespectVerticalLine(points, startInt.x)
      }
    }

    const profile = new Konva.Line({
      points,
      fill: FRAME_COLOR,
      stroke: FRAME_STROKE_COLOR,
      strokeWidth: 0.5,
      name: this.profileNames.internalFin,
      closed: true,
    })

    this.internalFinDraw = profile
    this.group.add(profile)
  }

  protected drawStokes(): void {
    const {
      startInt: externalStart,
      endInt: externalEnd,
    } = this.pointsAsVector(this.externalFinDraw.points())

    const {
      startInt: internalStart,
      endInt: internalEnd,
    } = this.pointsAsVector(this.internalFinDraw.points())

    const points = [
      externalStart.toArray(),
      internalStart.toArray(),
      internalEnd.toArray(),
      externalEnd.toArray(),
    ].flat()

    const stroke = this.drawStroke(points)

    this.group.add(stroke)
    stroke.setZIndex(this.group.children.length - 1)
  }

  protected drawStroke(points: number[]): Konva.Line {
    return new Konva.Line({
      points,
      stroke: FRAME_STROKE_COLOR,
      strokeWidth: 1,
      closed: true,
      name: PROFILE_KONVA_NAMES.stroke,
    })
  }

  private updateStrokes(): void {
    const stroke = this.group.children
      .toArray()
      .filter((c) => c.attrs.name === this.profileNames.stroke)

    stroke.forEach((shape) => {
      if (!shape) return

      shape.remove()
      shape.destroy()
    })

    this.drawStokes()
  }

  protected hide(): void {
    this.profileDraw?.stroke(FRAME_COLOR)
    this.externalFinDraw?.strokeEnabled(false)
    this.internalFinDraw?.strokeEnabled(false)

    this.profileDraw?.setZIndex(0)
  }

  /**
   * resize the profile (don´t move. Use move method)
   * @param scaledLenght new scaled Length of profile
   */
  public resize(scaledLenght: number): void {
    const actualLength = this.scaledLenght
    const lengthDiff = scaledLenght - actualLength

    this.resizeProfile(this.profileDraw, lengthDiff)
    this.resizeProfile(this.externalFinDraw, lengthDiff)
    this.resizeProfile(this.internalFinDraw, lengthDiff)
    this.updateStrokes()
  }

  private resizeProfile(profileDraw: Konva.Line, lengthDiff: number) {
    const { startExt, startInt, endExt, endInt } = this.pointsAsVector(
      profileDraw.points()
    )

    let newEndExt = endExt.clone()
    let newEndInt = endInt.clone()

    let vectorDiff = new Vector2(0, 0)

    if (this.axis === 'Vertical') {
      vectorDiff = new Vector2(0, lengthDiff)
    }

    if (this.axis === 'Horizontal') {
      vectorDiff = new Vector2(lengthDiff, 0)
    }

    newEndExt = newEndExt.add(vectorDiff)
    newEndInt = newEndInt.add(vectorDiff)

    const newPoints = [
      startExt.toArray(),
      startInt.toArray(),
      newEndInt.toArray(),
      newEndExt.toArray(),
    ].flat()

    profileDraw.points(newPoints)
  }

  public destroy(): void {
    this.group.removeEventListener('click')
    this.group.destroyChildren()
    this.group.destroy()
    this.gap.layer.draw()
  }

  public profilePoints(): number[] {
    return this.profileDraw?.points() ?? []
  }

  public getProfileAngles() {
    const { startExt, startInt, endExt, endInt } = this.pointsAsVector()

    let xMain = Math.abs(startExt.x - startInt.x)
    let yMain = Math.abs(startExt.y - startInt.y)
    let xSecond = Math.abs(endExt.x - endInt.x)
    let ySecond = Math.abs(endExt.y - endInt.y)

    // Avoid tan(0) and diff(0)
    xMain = xMain === 0 ? xMain + 0.001 : xMain
    yMain = yMain === 0 ? yMain + 0.001 : yMain
    xSecond = xSecond === 0 ? xSecond + 0.001 : xSecond
    ySecond = ySecond === 0 ? ySecond + 0.001 : ySecond

    let mainAngle = Math.atan(yMain / xMain)
    let secondAngle = Math.atan(ySecond / xSecond)

    if (this.axis === 'Vertical') {
      mainAngle = Math.PI / 2 - mainAngle
      secondAngle = Math.PI / 2 - secondAngle
    }

    return { mainAngle, secondAngle }
  }

  private getByExternalFin() {
    const {
      startInt: startExt,
      startExt: startInt,
      endExt: endInt,
      endInt: endExt,
    } = this.pointsAsVector(this.externalFinDraw.points())

    const xMain = Math.abs(startExt.x - startInt.x)
    const yMain = Math.abs(startExt.y - startInt.y)
    let mainAngle = Math.atan(yMain / xMain)

    const xSecond = Math.abs(endExt.x - endInt.x)
    const ySecond = Math.abs(endExt.y - endInt.y)
    let secondAngle = Math.atan(ySecond / xSecond)

    if (this.axis === 'Vertical') {
      mainAngle = Math.PI / 2 - mainAngle
      secondAngle = Math.PI / 2 - secondAngle
    }

    if (isNaN(mainAngle)) mainAngle = degreeToRadians(45)
    if (isNaN(secondAngle)) secondAngle = degreeToRadians(45)

    return {
      mainAngle,
      secondAngle,
    }
  }

  public calculateLength() {
    const { startInt: startExt, endInt: endExt } = this.pointsAsVector(
      this.externalFinDraw.points()
    )
    const { startInt: startInt, endInt: endInt } = this.pointsAsVector(
      this.internalFinDraw.points()
    )

    const extX = roundUp(Math.abs(startExt.x - endExt.x) / this.scaleFactor, 2)
    const extY = roundUp(Math.abs(startExt.y - endExt.y) / this.scaleFactor, 2)
    const intX = roundUp(Math.abs(startInt.x - endInt.x) / this.scaleFactor, 2)
    const intY = roundUp(Math.abs(startInt.y - endInt.y) / this.scaleFactor, 2)

    if (this.axis === 'Horizontal') {
      return {
        ext: extX,
        int: intX,
      }
    }

    if (this.axis === 'Vertical') {
      return {
        ext: extY,
        int: intY,
      }
    }
  }
}

export interface ProfileElementCreator {
  id?: number
  uuid: string
  x: number
  y: number
  height?: number
  width?: number
  thickness: number
  scaleFactor: number
  side: SideOptions
  profile: ProfileCreator
  profileDraw: Konva.Line
  unions: { [k in UnionPositions]?: number }
  gap: Gap
  axis?: 'Vertical' | 'Horizontal'
  cutVariation?: { id: number; value: number; value2?: number }
  overlap?: number
}
