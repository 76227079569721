import { Injectable } from '@angular/core'

import * as THREE from 'three'
import { DragControls } from 'three/examples/jsm/controls/DragControls'

import { Color, Object3D, Vector, Vector2, Vector3 } from 'three'
import { DXFAction } from '../models/DXFAction'
import { Entity } from 'src/app/@shared/@interfaces/dxf-content'
import { DXFTranslateAction } from '../models/DXFTranslateAction'
import { Coordinates } from 'src/app/@shared/@interfaces/coordinates'
import { cleanArray } from 'src/app/@helper/cleanArray'
import { DxfDimensions } from 'src/app/@services/cotas-service.service'
import { DXF } from '../models/DXF.model'

@Injectable({
  providedIn: 'root',
})
export class DxfDrawerService {
  public drawDxfFromEntities(
    entities: any[]
  ): {
    action: DXFAction
    object: THREE.Object3D
    entities: Entity[]
  } {
    const dxfGroup = new THREE.Group()
    const object3D = new THREE.Object3D()

    const { entites: newEntities, action } = this.positionedToOrigin(entities)

    newEntities.forEach((entity) => {
      if (entity.type === 'LINE') {
        const line = this.drawLine(entity)
        dxfGroup.add(line)
        object3D.add(line)
      }

      if (entity.type === 'CIRCLE') {
        const circle = this.drawArc(entity)
        dxfGroup.add(circle)
        object3D.add(circle)
      }

      if (entity.type === 'ARC') {
        const arc = this.drawArc(entity)
        dxfGroup.add(arc)
        object3D.add(arc)
      }

      if (entity.type === 'POLYLINE') {
        const polyline = this.drawLine(entity)
        dxfGroup.add(polyline)
        object3D.add(polyline)
      }

      if (entity.type === 'LWPOLYLINE') {
        const polyline = this.drawLine(entity)
        dxfGroup.add(polyline)
        object3D.add(polyline)
      }
    })

    return {
      action,
      object: object3D,
      entities: newEntities,
    }
  }

  private drawLine(entity: Entity): THREE.Line {
    const geometry = new THREE.BufferGeometry()
    const geometryVertices: Vector3[] = []

    if (!entity.vertices) {
      throw new Error('missing vertices')
    }

    const vertices = entity.vertices ?? []

    vertices.forEach((v, i) => {
      const hasBulge = v.bulge ? true : false

      if (hasBulge) {
        const bulge = v.bulge ?? 0
        const startPoint = new Vector2(v.x, v.y)
        const endPoint =
          i + 1 < vertices.length ? vertices[i + 1] : geometryVertices[0]

        const bulgeGeometry = new BulgeGeometry(
          startPoint,
          new Vector2(endPoint.x, endPoint.y),
          bulge
        )

        geometryVertices.push.apply(geometryVertices, bulgeGeometry.vertices)
      } else {
        geometryVertices.push(new THREE.Vector3(v.x, v.y, 0))
      }
    })

    geometry.setFromPoints(geometryVertices)
    const line = new THREE.Line(
      geometry,
      new THREE.LineBasicMaterial({
        color: 0x000000,
      })
    )

    return line
  }

  private drawArc(entity: Entity): any {
    if (!entity.center) return

    // not filled arc
    const center = new THREE.Vector3(
      entity.center.x,
      entity.center.y,
      entity.center.z
    )

    const radius = entity.radius ?? 0
    const startAngle = entity.startAngle ?? 0
    const endAngle = entity.endAngle ?? 0
    const segments = 36

    const curve = new THREE.ArcCurve(0, 0, radius, startAngle, endAngle, false)
    // get center of arc
    const centerArc = curve.getPoint(0.5)

    const points = curve.getPoints(segments)
    const geometry = new THREE.BufferGeometry().setFromPoints(points)
    const material = new THREE.LineBasicMaterial({
      color: 0x000000,
    })
    const line = new THREE.Line(geometry, material)
    line.position.copy(center)
    line.name = 'arc'

    return line
  }

  private positionedToOrigin(
    entities: Entity[]
  ): {
    action: DXFAction
    entites: Entity[]
  } {
    const vertexs = this.getVertexs(entities)
    const dimensions = this.getDxfDimensions(vertexs)

    const newEntities = this.translateEntities(entities, dimensions.start)
    const translateAction = new DXFTranslateAction(
      dimensions.start.x,
      dimensions.start.y
    )

    return {
      action: translateAction,
      entites: newEntities,
    }
  }

  private translateEntities(entities: Entity[], sumVector: Vector2): Entity[] {
    const restVector = sumVector.clone().multiplyScalar(-1)

    const newEntities: Entity[][] = []
    const entityType: { [k: string]: (entity: Entity) => void } = {
      LINE: (entity: Entity) => {
        const newEntity: Entity = {
          ...entity,
          vertices: this.translateVertexs(entity.vertices ?? [], restVector),
        }
        newEntities.push([newEntity])
      },
      LWPOLYLINE: (entity: Entity) => {
        const newEntity: Entity = {
          ...entity,
          vertices: this.translateVertexs(entity.vertices ?? [], restVector),
        }
        newEntities.push([newEntity])
      },
      POLYLINE: (entity: Entity) => {
        const newEntity: Entity = {
          ...entity,
          vertices: this.translateVertexs(entity.vertices ?? [], restVector),
        }
        newEntities.push([newEntity])
      },
      ARC: (entity: Entity) => {
        const newEntity = {
          ...entity,
          center: {
            ...entity.center,
            ...this.translateCenter(entity.center, restVector),
          },
        }
        newEntities.push([newEntity])
      },
      CIRCLE: (entity: Entity) => {
        const newEntity = {
          ...entity,
          ...this.translateCenter(
            {
              x: entity?.position?.x ?? 0,
              y: entity?.position?.y ?? 0,
            },
            restVector
          ),
        }
        newEntities.push([newEntity])
      },
      INSERT: (entity: Entity) => {
        // this.reflectPosition(entity)
      },
      VOID: (entity: Entity) => {
        // this.reflectPosition(entity)
      },
    }

    entities.forEach((entity) => {
      const type = entity?.type ?? 'VOID'
      if (!type) return

      const parseFunction = entityType[type]
      if (!parseFunction) return

      parseFunction(entity)
    })

    newEntities.flat().forEach((entity) => {
      if (!entity) return
      const isArc = entity.type === 'ARC'
      if (isArc) {
        entity.color = 16711680
      }

      entity.color = 16711680
    })

    return newEntities.flat()
  }

  private translateVertexs(
    vertexs: Coordinates[],
    sumVector: Vector2
  ): Coordinates[] {
    return vertexs.map((v) => {
      return {
        x: v.x + sumVector.x,
        y: v.y + sumVector.y,
      }
    })
  }

  private translateCenter(
    center?: Coordinates,
    sumVector?: Vector2
  ): Coordinates {
    if (!center) return { x: 0, y: 0 }
    if (!sumVector) return center

    return {
      x: center.x + sumVector.x,
      y: center.y + sumVector.y,
    }
  }

  private getVertexs(entities: Entity[]): Coordinates[] {
    return cleanArray(
      entities.flatMap((e) => {
        if (!e) return null

        const isArc = e.type === 'ARC'
        const isLineType = e?.type === 'LINE' || e?.type === 'LWPOLYLINE'
        const hasVertices = e?.vertices ? true : false

        if (isLineType && !hasVertices) {
          return null
        }

        if (isArc) {
          return [e.center]
        }

        return e?.vertices
      })
    )
  }

  private getDxfDimensions(vertexs: Coordinates[]): DxfDimensions {
    let dimensions: DxfDimensions = {
      start: new Vector2(Infinity, Infinity),
      end: new Vector2(-Infinity, -Infinity),
    }

    vertexs.forEach((v) => {
      if (v.x < dimensions.start.x) dimensions.start.setX(v.x)
      if (v.x > dimensions.end.x) dimensions.end.setX(v.x)
      if (v.y < dimensions.start.y) dimensions.start.setY(v.y)
      if (v.y > dimensions.end.y) dimensions.end.setY(v.y)
    })

    return dimensions
  }

  public addDragProperty(
    dxf: DXF,
    scene: THREE.Scene,
    camera: THREE.Camera,
    renderer: THREE.Renderer
  ): void {
    const dxfGroup = dxf.getGroup()
    if (!dxfGroup) return

    const dragControl = new DragControls(
      [dxfGroup],
      camera,
      renderer.domElement
    )

    const initPosition = new THREE.Vector3(0, 0, 0)
    const endPosition = new THREE.Vector3(0, 0, 0)
    const ceroPosition = new THREE.Vector3(0, 0, 0)
    let draggingElement: THREE.Object3D | null = null

    dragControl.addEventListener('dragstart', (event) => {
      draggingElement = event['object']

      if (!draggingElement) return

      // get position of arc
      const position = draggingElement.position
      initPosition.set(position.x, position.y, position.z)
    })

    dragControl.addEventListener('drag', (event) => {
      renderer.render(scene, camera)
    })

    dragControl.addEventListener('dragend', (event) => {
      const arc = draggingElement
      if (!arc) return
      // get position of arc
      const position = arc.position
      endPosition.set(position.x, position.y, position.z)

      // calculate offset
      const ceroOffset = endPosition.sub(ceroPosition)
      dxf.offset = ceroOffset.toArray();

      const offset = endPosition.sub(initPosition)
      // Este es el vector de movimiento (offset) que genera la posición del elemento
      // Es necesario extraer este calculo durante el drag and drop para poder guardarlo
      // Lo suyo seria separar la ultima funcion que mueve el objeto a fuera o añadir un disparador de evento para la store

      // move all children of group execpt selected one
      const children = dxfGroup.children.filter((c) => c !== arc)
      this.moveGroup(dxf, children, offset, renderer, scene, camera)
    })
  }

  public moveGroup(
    dxf: DXF,
    children: Object3D[],
    offset: THREE.Vector3,
    renderer: THREE.Renderer,
    scene: THREE.Scene,
    camera: THREE.Camera
  ) {
    children.forEach((child) => {
      child.position.add(offset)
    })

    dxf.addAction(new DXFTranslateAction(offset.x, offset.y))

    renderer.render(scene, camera)
  }
}

class BulgeGeometry extends THREE.BufferGeometry {
  private angle = 0
  private radius = 0
  private currentCenter = new Vector2(0, 0)

  public vertices: Vector3[] = []

  constructor(
    private startPoint: THREE.Vector2,
    private endPoint: THREE.Vector2,
    private bulge: number,
    private segments?: number
  ) {
    super()

    this.startPoint = this.startPoint
      ? new THREE.Vector2(startPoint.x, startPoint.y)
      : new THREE.Vector2(0, 0)

    this.endPoint = endPoint
      ? new THREE.Vector2(endPoint.x, endPoint.y)
      : new THREE.Vector2(0, 0)

    this.bulge = bulge ?? 1

    this.angle = 4 * Math.atan(bulge)

    this.radius = startPoint.distanceTo(endPoint) / 2 / Math.sin(this.angle / 2)

    this.currentCenter = this.toPolar(
      startPoint,
      this.radius,
      this.angleBetween(startPoint, endPoint) + (Math.PI / 2 - this.angle / 2)
    )

    this.segments =
      segments || Math.max(Math.abs(Math.ceil(this.angle / (Math.PI / 18))), 6)

    const startAngle = this.angleBetween(this.currentCenter, startPoint)
    const thetaAngle = this.angle / this.segments

    this.vertices.push(new THREE.Vector3(startPoint.x, startPoint.y, 0))

    for (let i = 1; i <= this.segments - 1; i++) {
      const vertex = this.toPolar(
        this.currentCenter,
        Math.abs(this.radius),
        startAngle + thetaAngle * i
      )

      this.vertices.push(new THREE.Vector3(vertex.x, vertex.y, 0))
    }
  }

  private toPolar(point: Vector2, distance: number, angle: number): Vector2 {
    const x = point.x + distance * Math.cos(angle)
    const y = point.y + distance * Math.sin(angle)
    return new Vector2(x, y)
  }

  private angleBetween(p1: Vector2, p2: Vector2): number {
    var v1 = new THREE.Vector2(p1.x, p1.y)
    var v2 = new THREE.Vector2(p2.x, p2.y)
    v2.sub(v1) // sets v2 to be our chord
    v2.normalize()
    if (v2.y < 0) return -Math.acos(v2.x)
    return Math.acos(v2.x)
  }
}
