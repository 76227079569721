import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Formula } from 'src/app/@shared/@interfaces/formula';

export interface FormulaNode {
  formula: Formula
  children?: FormulaNode[]
}

interface FlatNode {
  expandable: boolean
  name: string
  level: number
}

@Component({
  selector: 'app-formulas-tree',
  templateUrl: './formulas-tree.component.html',
  styleUrls: ['./formulas-tree.component.scss']
})
export class FormulasTreeComponent implements OnInit {
  @Input() public treeNode: FormulaNode[] = []
  @Input() public selectedFormula: Formula

  @Output() public selectFormulaEmitter = new EventEmitter<Formula>()
  @Output() public searchEmitter = new EventEmitter<string>()

  public treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable
  )

  constructor(private router: Router) { }

  ngOnInit(): void { }

  public onSelectFormula(formula: Formula): void {
    this.selectFormulaEmitter.emit(formula)
  }

  public back(): void {
    this.router.navigateByUrl('/files')
  }

  public onSearch(criteria: string): void {
    this.searchEmitter.emit(criteria)
  }
}
