import { SerieCreator } from '../@interfaces/serieCreator'
export class NewModelModel {
  id: number
  serie: SerieCreator
  name: string
  height: number
  width: number
  valid: boolean

  constructor() {
    this.serie = new SerieCreator()
  }
}
