import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { BsModalRef } from 'ngx-bootstrap/modal'

import { IDialog } from 'src/app/@shared/@interfaces/dialog'

import {
  faTimes,
  faExternalLinkAlt,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'

import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ToastrService } from 'ngx-toastr'
import { DialogInstanceModel } from 'src/app/@shared/@models/dialog-instance-model'
import { ModalService } from 'src/app/@services/modal.service'
import { ProfileCreator } from 'src/app/@shared/@interfaces/profileCreator.model'
import { ProfileDialogComponent } from '../profile-dialog/profile-dialog.component'
import { roundUp } from 'src/app/@helper/roundNumber'
import { AreaModel } from 'src/app/@shared/@models/modelsElements/area-model'
import { CrossbarElement } from 'src/app/@shared/@models/modelsElements/crossbar-model'
import { CutVariation } from 'src/app/@shared/@models/cut-variation.model'
import {
  CutVariationTypes,
  CUT_VARIATIONS,
} from 'src/app/@services/cut-variation.service'
import { Overlaps } from 'src/app/@components/overlaps/overlaps.component'
import { Serie } from 'src/app/@components/profile-management-editor/profile-management-editor.component'
import { SerieCreator } from 'src/app/@shared/@interfaces/serieCreator'

type Axis = 'Y' | 'X'
type FromAxis = 'top' | 'bottom' | 'left' | 'right'
@Component({
  selector: 'app-new-area-dialog',
  templateUrl: './new-area-dialog.component.html',
  styleUrls: ['./new-area-dialog.component.scss'],
})
export class NewAreaDialogComponent implements IDialog, OnInit {
  @Output()
  $response: EventEmitter<CreateCrossbar> = new EventEmitter<CreateCrossbar>()

  // Input
  public selectedCrossbar: CrossbarElement
  public selectedArea: AreaModel
  public serieId: number

  public axisSelected: Axis = 'X'
  public axisFrom: Axis = 'Y'

  public form: FormGroup

  // Icons
  public faTimes = faTimes
  public faExternalLinkAlt = faExternalLinkAlt
  public faTrashAlt = faTrashAlt

  public selectedProfile: ProfileCreator
  public overlaps: Overlaps = {
    overlap: 0,
    cutVariation: {
      id: 1,
      value: 0,
    },
  }

  constructor(
    private toastr: ToastrService,
    public modalRef: BsModalRef,
    private modalService: ModalService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.buildForm()
  }

  private buildForm(amount?: number, array?: FormArray): void {
    this.form = this.fb.group({
      amount: [amount ?? 0, [Validators.required, Validators.min(0)]],
      array: array ?? this.fb.array([]),
      from: ['top', [Validators.required]],
    })
  }

  public associateUnionProfile(): void {
    const frameDialog = new DialogInstanceModel(ProfileDialogComponent, {
      initialState: {
        serieId: this.serieId,
        sideName: 'Seleccione el perfile de unión',
        onlyOne: true,
        usePreferred: 'INVERSOR',
      },
      class: 'modal-dialog-centered',
    })

    this.modalService.openModal(frameDialog).subscribe((profile: any) => {
      if (!profile) return
      this.selectedProfile = profile.profile as ProfileCreator
      const serie = this.selectedProfile.serie as SerieCreator

      this.overlaps.cutVariation = {
        id: CUT_VARIATIONS.retested,
        value: serie.millVariation,
      }
    })
  }

  public areasFormArray(): FormArray {
    return this.form.get('array') as FormArray
  }

  public createFormArray(): void {
    const amount = this.form.get('amount').value as number
    const array = this.fb.array([])

    const areaLength =
      this.axisSelected === 'X'
        ? this.selectedArea.width
        : this.selectedArea.width
    const profileInternalCamera = this.selectedProfile?.internalCamera ?? 0
    const fixedAreaLength = areaLength - amount * profileInternalCamera
    const eachAreaLength = fixedAreaLength / (amount + 1)

    for (let i = 1; i < amount + 1; i++) {
      const px = i * eachAreaLength + (2 * i - 1) * (profileInternalCamera / 2)
      array.push(this.fb.control(px, [Validators.required, Validators.min(0)]))
    }

    this.buildForm(amount, array)
  }

  public selectAxis(axis: Axis): void {
    this.axisSelected = axis

    if (axis === 'X') {
      this.form.get('from').setValue('top')
      this.axisFrom = 'Y'
    }
    if (axis === 'Y') {
      this.form.get('from').setValue('left')
      this.axisFrom = 'X'
    }
    this.createFormArray()
  }

  public onSubmit(): any {
    const arrayOffsets = this.deleteDuplicate(
      this.form.get('array').value as Array<number>
    )
    arrayOffsets.sort((a, b) => a - b)

    const response: CreateCrossbar = {
      axisSelected: this.axisSelected,
      areasAmount: this.form.get('amount').value,
      from: this.form.get('from').value,
      crossbarsPositionsMM: arrayOffsets,
      selectedProfile: this.selectedProfile,
      overlaps: this.overlaps,
    }

    if (!this.isAllWithinArea(response))
      return this.toastr.warning('Debe seleccionar un offset dentro del área')

    if (this.form.valid) {
      this.$response.emit(response)
      this.modalRef.hide()
    }
  }

  private deleteDuplicate(array: number[]): number[] {
    const auxArray: number[] = []

    array.forEach((nu) => {
      if (!auxArray.includes(nu)) auxArray.push(nu)
    })

    return auxArray
  }

  private isAllWithinArea(crossbars: CreateCrossbar): boolean {
    let isWithin = true
    const dimension =
      crossbars.axisSelected === 'X'
        ? this.selectedArea.height
        : this.selectedArea.width

    crossbars.crossbarsPositionsMM.forEach((offset) => {
      if (offset >= dimension) isWithin = false
    })

    return isWithin
  }

  public removeCrossbar(): void {
    this.$response.emit(null)
    this.modalRef.hide()
  }

  public setOverlaps(overlaps: Overlaps): void {
    this.overlaps = overlaps
  }

  confirm(): void {
    this.modalRef.hide()
  }
  decline(): void {
    this.modalRef.hide()
  }
}

export interface CreateCrossbar {
  axisSelected: Axis
  areasAmount: number
  from: FromAxis
  crossbarsPositionsMM: number[]
  selectedProfile?: ProfileCreator
  overlaps: Overlaps
}
