import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

import { ProfileCreator } from 'src/app/@shared/@interfaces/profileCreator.model'
import { CoverGapForm } from 'src/app/@shared/@interfaces/coverGapForm'
import { SideOptions } from 'src/app/@shared/@types/side.types'
import { debounceTime } from 'rxjs/operators'
import { Overlaps } from '../overlaps/overlaps.component'

@Component({
  selector: 'app-cover-gap-side-form',
  templateUrl: './cover-gap-side-form.component.html',
  styleUrls: ['./cover-gap-side-form.component.scss'],
})
export class CoverGapSideFormComponent implements OnInit, OnChanges {
  @Input() public side: SideOptions
  @Input() public serieId = 1

  @Input() public overlap = 0
  @Input() public cutVariation: {
    id: number
    value: number
    value2?: number
  } = {
    id: 1,
    value: 0,
  }

  @Input() public has: {
    external: boolean
    internal: boolean
  } = {
    external: false,
    internal: false,
  }

  @Input()
  public externalProfile: ProfileCreator
  @Input()
  public internalProfile: ProfileCreator
  @Input()
  public clip = 0

  @Output() private coverGapFormEmitter = new EventEmitter<CoverGapForm>()

  public typeProfile = 'tapajuntas'

  public form: FormGroup

  constructor(private fb: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    const hasExternal = this.form && this.externalProfile
    const hasInternal = this.form && this.internalProfile

    if (hasExternal) this.setExternalProfile(this.externalProfile)
    if (hasInternal) this.setInternalProfile(this.internalProfile)
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      clip: [this.clip ?? 0, [Validators.required]],
      ext: [this.externalProfile ? true : false],
      int: [this.internalProfile ? true : false],
    })

    this.form.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      this.clip = value.clip
      this.emit()
    })
  }

  public setExternalProfile(profile: ProfileCreator): void {
    this.externalProfile = profile
    this.form.get('ext').setValue(true)
    this.emit()
  }
  public setInternalProfile(profile: ProfileCreator): void {
    this.internalProfile = profile
    this.form.get('int').setValue(true)

    this.emit()
  }

  public setOverlaps(overlaps: Overlaps): void {
    this.overlap = overlaps.overlap
    this.cutVariation = overlaps.cutVariation

    this.emit()
  }

  private emit(): void {
    this.coverGapFormEmitter.emit({
      external: this.externalProfile,
      hasExternal: this.form.get('ext').value ?? true,
      internal: this.internalProfile,
      hasInternal: this.form.get('int').value ?? true,
      clip: this.clip ?? 0,
      side: this.side,
      overlap: this.overlap,
      cutVariation: this.cutVariation,
    })
  }
}
