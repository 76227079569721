// ANGULAR
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { FormGroup, FormBuilder } from '@angular/forms'
// MODELS

import { AreaChanges } from 'src/app/@shared/@interfaces/area-changes'
import { debounceTime } from 'rxjs/operators'
import { AreaModel } from 'src/app/@shared/@models/modelsElements/area-model'

@Component({
  selector: 'app-area-form',
  templateUrl: './area-form.component.html',
  styleUrls: ['./area-form.component.scss'],
})
export class AreaFormComponent implements OnInit, OnChanges {
  @Input()
  set $area($area: AreaModel) {
    this.area = $area
  }

  get $area(): AreaModel {
    return this.area
  }
  public area: AreaModel

  @Input() areaWidth: number
  @Input() areaHeight: number
  @Input() areaId: number

  // Form
  public areaDimensionsForm: FormGroup

  @Output()
  areaDimensionsChangeEmitter: EventEmitter<AreaChanges> = new EventEmitter<AreaChanges>()

  constructor(private formBuilder: FormBuilder) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.dimensionsFormInstance()
    this.onChangeSubscriber()
  }

  ngOnInit(): void {}

  dimensionsFormInstance(): void {
    this.areaDimensionsForm = this.formBuilder.group({
      id: [{ value: this.area.id, disabled: true }],
      height: [{ value: this.areaHeight, disabled: true }],
      width: [{ value: this.areaWidth, disabled: true }],
    })
  }
  public onChangeSubscriber(): void {
    this.areaDimensionsForm.valueChanges
      .pipe(debounceTime(800))
      .subscribe((formValue) => {
        this.areaDimensionsChangeEmitter.emit(formValue)
      })
  }
}
