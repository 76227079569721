import { Injectable } from '@angular/core'
import * as uuidGenerator from 'uuid'

import {
  AssociatedProfile,
  AssociatedProfiles,
} from 'src/app/@components/associated-profiles-selector/associated-profiles-selector.component'
import { ProfileCreator } from 'src/app/@shared/@interfaces/profileCreator.model'
import { AssociatedProfileFrame } from 'src/app/@shared/@models/modelsElements/associatedProfileFrame-model'
import { Frame } from 'src/app/@shared/@models/modelsElements/frame-model'

import { UnionPositions } from 'src/app/@shared/@types/unionPositions.type'
import { Vector2 } from 'three'
import { UnionService, UNIONS_TYPES } from '../union.service'
import { CutVariationsDrawerService } from './cut-variations-drawer.service'
import { ProfileDrawerCreatorService } from './profile-drawer-creator.service'
import { PseudoFrameDrawerService } from './pseudo-frame-drawer.service'
import { SuperpositionDrawerService } from './superposition-drawer.service'

@Injectable({
  providedIn: 'root',
})
export class AssociatedProfileFrameDrawerService {
  constructor(
    private unionsService: UnionService,
    private profileDrawer: ProfileDrawerCreatorService,
    private pseudoFrameDraweService: PseudoFrameDrawerService,
    private superpositionDrawer: SuperpositionDrawerService,
    private cutVariationDrawer: CutVariationsDrawerService
  ) {}

  public createAssociatedProfiles(
    associatedProfiles: AssociatedProfiles,
    frame: Frame
  ) {
    const scaleFactor = frame.associatedAreas[0].gap.scaleWidth
    const layer = frame.associatedAreas[0].gap.layer

    const topProfilesLines = associatedProfiles.top.map((ap, index) => {
      const { frameSideTop } = frame
      const { startInt } = frameSideTop.pointsAsVector()

      const profileLine = this.createHorizontal(
        frameSideTop.profile.internalCamera,
        frameSideTop.originalLength,
        ap.profile,
        associatedProfiles.left[index]?.profile,
        associatedProfiles.right[index]?.profile,
        scaleFactor,
        startInt,
        frame.unions['left-top'],
        frame.unions['right-top'],
        frame.unions,
        ap.overlap,
        ap.cutVariation
      )

      const associatedProfileElement = new AssociatedProfileFrame({
        gap: frame.associatedAreas[0].gap,
        profile: ap.profile,
        profileDraw: profileLine,
        scaleFactor,
        side: 'top',
        thickness: ap.profile.internalCamera,
        unions: frame.unions,
        uuid: uuidGenerator.v4(),
        x: startInt.x,
        y: startInt.y,
        axis: 'Horizontal',
        cutVariation: ap.cutVariation,
        overlap: ap.overlap,
        frame,
      })

      frame.addAssociatedProfile('top', associatedProfileElement)

      return profileLine
    })

    const bottomProfilesLines = associatedProfiles.bottom.map((ap, index) => {
      const { frameSideBottom } = frame
      const { startInt } = frameSideBottom.pointsAsVector()

      const profileLine = this.createHorizontal(
        frameSideBottom.profile.internalCamera,
        frameSideBottom.originalLength,
        ap.profile,
        associatedProfiles.left[index]?.profile,
        associatedProfiles.right[index]?.profile,
        scaleFactor,
        startInt,
        frame.unions['left-bottom'],
        frame.unions['right-bottom'],
        frame.unions,
        -ap.overlap,
        ap.cutVariation
      )
      this.profileDrawer.reflexHorizontalProfile(startInt.y, profileLine)

      const associatedProfileElement = new AssociatedProfileFrame({
        gap: frame.associatedAreas[0].gap,
        profile: ap.profile,
        profileDraw: profileLine,
        scaleFactor,
        side: 'bottom',
        thickness: ap.profile.internalCamera,
        unions: frame.unions,
        uuid: uuidGenerator.v4(),
        x: startInt.x,
        y: startInt.y,
        axis: 'Horizontal',
        cutVariation: ap.cutVariation,
        overlap: ap.overlap,
        frame,
      })

      frame.addAssociatedProfile('bottom', associatedProfileElement)

      return profileLine
    })

    const leftProfilesLines = associatedProfiles.left.map((ap, index) => {
      const { frameSideLeft } = frame
      const { startInt } = frameSideLeft.pointsAsVector()

      const profileLine = this.createVertical(
        frameSideLeft.profile.internalCamera,
        frameSideLeft.originalLength,
        ap.profile,
        associatedProfiles.top[index]?.profile,
        associatedProfiles.bottom[index]?.profile,
        scaleFactor,
        startInt,
        frame.unions['left-top'],
        frame.unions['left-bottom'],
        frame.unions,
        ap.overlap,
        ap.cutVariation
      )

      const associatedProfileElement = new AssociatedProfileFrame({
        gap: frame.associatedAreas[0].gap,
        profile: ap.profile,
        profileDraw: profileLine,
        scaleFactor,
        side: 'left',
        thickness: ap.profile.internalCamera,
        unions: frame.unions,
        uuid: uuidGenerator.v4(),
        x: startInt.x,
        y: startInt.y,
        axis: 'Vertical',
        cutVariation: ap.cutVariation,
        overlap: ap.overlap,
        frame,
      })

      frame.addAssociatedProfile('left', associatedProfileElement)

      return profileLine
    })

    const rightProfilesLines = associatedProfiles.right.map((ap, index) => {
      const { frameSideRight } = frame
      const { startInt } = frameSideRight.pointsAsVector()

      const profileLine = this.createVertical(
        frameSideRight.profile.internalCamera,
        frameSideRight.originalLength,
        ap.profile,
        associatedProfiles.top[index]?.profile,
        associatedProfiles.bottom[index]?.profile,
        scaleFactor,
        startInt,
        frame.unions['right-top'],
        frame.unions['right-bottom'],
        frame.unions,
        -ap.overlap,
        ap.cutVariation
      )
      this.profileDrawer.reflexVerticalProfile(startInt.x, profileLine)

      const associatedProfileElement = new AssociatedProfileFrame({
        gap: frame.associatedAreas[0].gap,
        profile: ap.profile,
        profileDraw: profileLine,
        scaleFactor,
        side: 'right',
        thickness: ap.profile.internalCamera,
        unions: frame.unions,
        uuid: uuidGenerator.v4(),
        x: startInt.x,
        y: startInt.y,
        axis: 'Vertical',
        cutVariation: ap.cutVariation,
        overlap: ap.overlap,
        frame,
      })

      frame.addAssociatedProfile('right', associatedProfileElement)

      return profileLine
    })
  }

  private createHorizontal(
    frameSideThickness: number,
    frameSideLength: number,
    profile: ProfileCreator,
    leftProfile: ProfileCreator,
    rightProfile: ProfileCreator,
    scaleFactor: number,
    startPointInt: Vector2,
    unionLeft: number,
    unionRight: number,
    unions: { [k in UnionPositions]: number },
    overlap: number,
    cutVariation: { id: number; value: number; value2?: number }
  ) {
    const profileLine = this.profileDrawer.createHorizontalProfileFromStartInt({
      profileLength: frameSideLength,
      profile,
      profileWidth: profile.internalCamera,
      scaleFactor,
      startPointInt,
    })

    const leftThickness =
      unionLeft === UNIONS_TYPES.cut45 ? profile.internalCamera : 0

    const rightThickness =
      unionRight === UNIONS_TYPES.cut45
        ? profile.internalCamera
        : 2 * frameSideThickness

    this.profileDrawer.setUnionsForHorizontalIntToExt(
      unionLeft,
      unionRight,
      profileLine,
      profile.internalCamera * scaleFactor,
      leftThickness * scaleFactor,
      rightThickness * scaleFactor
    )

    this.superpositionDrawer.applySuperposition(
      unions,
      {
        top: profileLine,
        bottom: null,
        left: null,
        right: null,
      },
      {
        top: overlap,
        bottom: null,
        left: null,
        right: null,
      },
      scaleFactor
    )

    this.cutVariationDrawer.applyCutVariations(
      {
        top: cutVariation,
        bottom: null,
        left: null,
        right: null,
      },
      {
        top: {
          profile: profile,
          profileDraw: profileLine,
        },
        bottom: null,
        left: null,
        right: null,
      },
      {
        top: profile.internalCamera,
        bottom: null,
        left: null,
        right: null,
      },
      'Frame',
      scaleFactor
    )

    return profileLine
  }

  private createVertical(
    frameSideThickness: number,
    frameSideLength: number,
    profile: ProfileCreator,
    topProfile: ProfileCreator,
    bottomProfile: ProfileCreator,
    scaleFactor: number,
    startPointInt: Vector2,
    unionTop: number,
    unionBottom: number,
    unions: { [k in UnionPositions]: number },
    overlap: number,
    cutVariation: { id: number; value: number; value2?: number }
  ) {
    const profileLine = this.profileDrawer.createVerticalProfileFromStartInt({
      profileLength: frameSideLength,
      profile,
      profileWidth: profile.internalCamera,
      scaleFactor,
      startPointInt,
    })

    const topThickness =
      unionTop === UNIONS_TYPES.cut45 ? profile.internalCamera : 0

    const bottomThickness =
      unionBottom === UNIONS_TYPES.cut45
        ? profile.internalCamera
        : 2 * frameSideThickness

    this.profileDrawer.setUnionsForVerticalIntToExt(
      unionTop,
      unionBottom,
      profileLine,
      profile.internalCamera * scaleFactor,
      topThickness * scaleFactor,
      bottomThickness * scaleFactor
    )

    this.superpositionDrawer.applySuperposition(
      unions,
      {
        top: null,
        bottom: null,
        left: profileLine,
        right: null,
      },
      {
        top: null,
        bottom: null,
        left: overlap,
        right: null,
      },
      scaleFactor
    )

    this.cutVariationDrawer.applyCutVariations(
      {
        top: null,
        bottom: null,
        left: cutVariation,
        right: null,
      },
      {
        top: null,
        bottom: null,
        left: {
          profile: profile,
          profileDraw: profileLine,
        },
        right: null,
      },
      {
        top: null,
        bottom: null,
        left: profile.internalCamera,
        right: null,
      },
      'Frame',
      scaleFactor
    )

    return profileLine
  }
}
