<div class="wrapper-list">
  <div class="group" *ngFor="let group of filteredGroups">
    <div class="group-title" (click)="toggleSelectGroup(group.id)">
      <div class="group-title-label">
        <fa-icon
          class="group-title__icon"
          [icon]="faSortUp"
          *ngIf="selectedGroupId === group.id"
        ></fa-icon>
        <fa-icon
          class="group-title__icon"
          [icon]="faSortDown"
          *ngIf="selectedGroupId !== group.id"
        ></fa-icon>
        <span class="group-title__name">{{ group.name }}</span>
      </div>
      <fa-icon
        [icon]="faTrashAlt"
        class="icon delete"
        (click)="deleteGroup(group)"
      ></fa-icon>
    </div>
    <app-accessorie-group-table
      *ngIf="selectedGroupId === group.id"
      [groupId]="group.id"
    ></app-accessorie-group-table>
  </div>
</div>
