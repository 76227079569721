import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  @Input() public label: string
  @Input() public id: string
  @Input() public width = '100%'
  @Input() public disabled = false
  @Input() public justifyContent = 'flex-end'

  public randomID = 'random'

  constructor() {}

  ngOnInit(): void {}
}
