export const customTextToNumberInput = (event: any) => {
  const re = /,/gi;
  const value = event.target.value;
  let isDecimal =
    value.search(re) === -1 ?
      false :
      true

  if (event.keyCode === 44) {
    if (isDecimal || !value) {
      event.preventDefault();
    } else {
      event.target.value += ',';
    }
  }

  if (event.keyCode === 46) {
    if (isDecimal || !value) {
      event.preventDefault();
    } else {
      event.target.value += ',';
    }
  }

  if (event.keyCode < 48 || event.keyCode > 57) {
    event.preventDefault();
  }
}
