<table class="bar-table grid" *ngFor="let profile of data; let i = index">
  <thead class="bar-table-head grid">
    <th>Código</th>
    <th>Descripción</th>
    <th>L. barra</th>
    <th>Cantidad</th>
    <th>Descarte/Barra</th>
    <th>Descarte total</th>
    <th>Tipo de optimización</th>
  </thead>
  <tbody
    class="bar-table-body grid"
    *ngFor="let bar of profile.barDetails; let i2 = index"
  >
    <tr class="bar-table-body-row grid fade-in" (click)="selectBar(i)">
      <td>
        {{ profile.profile.code }}
      </td>
      <td>{{ profile.profile.description }}</td>
      <td>{{ bar.long }} mm</td>
      <td>{{ bar.quantity }} unid.</td>
      <td>{{ bar.restTotal }}</td>
      <td>{{ profile.restTotal }}</td>
      <td>{{ profile.cutOptimization?.name || 'Estándar' }}</td>
    </tr>

    <tr *ngIf="isSelectedBar(i)" class="bar-table-body-row grid fade-in">
      <td class="breakdown">
        <table class="bar-table grid">
          <thead class="bar-table-head grid">
            <th></th>
            <th></th>
            <th>L.ext</th>
            <th>Descarte</th>
            <th></th>
            <th></th>
            <th></th>
          </thead>
          <tbody
            class="bar-table-body grid"
            *ngFor="let bars of bar.bars.data; let indexBar = index"
          >
            <tr class="bar-table-body-row grid fade-in">
              <td class="breakdown">
                <thead class="bar-table-head grid">
                  <th></th>
                  <th>{{ bars.count }}x{{ bar.long }}</th>
                  <th></th>
                  <th>{{ bars.array.rest }}</th>
                  <th></th>
                  <th></th>
                  <th></th>
                </thead>
                <tbody class="bar-table-body grid">
                  <tr
                    class="bar-table-body-row grid fade-in"
                    *ngFor="let long of bar.bars.value[indexBar]"
                  >
                    <td></td>
                    <td>{{ long.count }}x</td>
                    <td>{{ long.value }}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>

<div class="no-model-message" *ngIf="!hasModel()">
  <h3>Ningún modelo proporcionado.</h3>
</div>
