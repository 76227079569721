import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { IDialog } from 'src/app/@shared/@interfaces/dialog'
import {
  IOpeningWindowDoor,
  OpeningTableItem,
} from 'src/app/@shared/@interfaces/openingObject'
import { AreaModel } from 'src/app/@shared/@models/modelsElements/area-model'
import { DoorWindowTipupOpeningElement } from 'src/app/@shared/@models/modelsElements/door-window-tipupOpening-model'

@Component({
  selector: 'app-opening-dialog',
  templateUrl: './opening-dialog.component.html',
  styleUrls: ['./opening-dialog.component.scss'],
})
export class OpeningDialogComponent implements OnInit, AfterViewInit, IDialog {
  // Inputs
  public selectedOpening: DoorWindowTipupOpeningElement
  public area: AreaModel
  public serieId = 1

  $response: EventEmitter<IOpeningWindowDoor[] | false> = new EventEmitter()

  public width = 0
  public height = 0
  public openingTableItem: OpeningTableItem[] = []

  private objectCreator: IOpeningWindowDoor[]

  constructor(private modalRef: BsModalRef) {}

  ngAfterViewInit(): void {}

  confirm(): void {
    this.$response.next(this.objectCreator)
    this.modalRef.hide()
  }
  decline(): void {
    this.modalRef.hide()
  }

  public deleteOpening() {
    this.$response.next(false)
    this.modalRef.hide()
  }

  public setOpeningCreatorData(data: IOpeningWindowDoor[]): void {
    this.objectCreator = data
  }

  ngOnInit(): void {
    this.width = this.area?.width ?? 0
    this.height = this.area?.height ?? 0

    if (this.selectedOpening) {
      this.width =
        this.selectedOpening.getTotalWidth() -
        2 * this.selectedOpening.mainOverlap
      this.height =
        this.selectedOpening.getTotalHeight() -
        2 * this.selectedOpening.mainOverlap
    }

    this.buildLeafts()
  }

  images(num: Number) {
    switch (num) {
      case 1:
        return [
          'Internaderecha.svg',
          'Internaizquierda.svg',
          'Externadercha.svg',
          'Externazquierda.svg',
          'Voltearderecha.svg',
          'Voltearizquierda.svg',
        ]
      case 2:
        return [
          'Internaderecha.svg',
          'Internaizquierda.svg',
          'Externadercha.svg',
          'Externazquierda.svg',
        ]
      case 3:
        return ['arriba.svg']
      case 4:
        return ['abajo.svg']
    }
  }

  private buildLeafts(): void {
    if (!this.selectedOpening) return

    this.openingTableItem = this.selectedOpening.leafts.map((l) => ({
      isLock: false,
      isMain: l.hasHandleDoor,
      width: l.widthInternalCamera(),
      opening: {
        id: l.openingType,
      },
    }))
  }
}
