import { Injectable } from '@angular/core'
import Konva from 'konva'
import { CoverGaps } from 'src/app/@shared/@interfaces/coverGaps'

import { FrameCreatorDraw } from 'src/app/@shared/@interfaces/frameCreatorDraw'
import { ProfileCreator } from 'src/app/@shared/@interfaces/profileCreator.model'
import { UnionTypes } from 'src/app/@shared/@interfaces/unionTypes'
import { AreaModel } from 'src/app/@shared/@models/modelsElements/area-model'
import { Frame } from 'src/app/@shared/@models/modelsElements/frame-model'
import { FrameSide } from 'src/app/@shared/@models/modelsElements/frameSide-model'
import { Gap } from 'src/app/@shared/@models/modelsElements/gap-model'
import { SideOptions } from 'src/app/@shared/@types/side.types'
import { UnionPositions } from 'src/app/@shared/@types/unionPositions.type'
import { Vector2 } from 'three'

import * as uuidGenerator from 'uuid'
import { UnionService } from '../union.service'
import { CovergapDrawService } from './covergap-draw.service'
import { CutVariationsDrawerService } from './cut-variations-drawer.service'
import { PseudoFrameDrawerService } from './pseudo-frame-drawer.service'
import { SuperpositionDrawerService } from './superposition-drawer.service'

@Injectable({
  providedIn: 'root',
})
export class FrameDrawService {
  private unionTypes: UnionTypes

  constructor(
    private unionsService: UnionService,
    private pseudoFrameDraweService: PseudoFrameDrawerService,
    private superpositionDrawer: SuperpositionDrawerService,
    private cutVariationDrawer: CutVariationsDrawerService
  ) {
    this.unionTypes = this.unionsService.unionsIds
  }

  /**
   *
   * @param framesData data from form
   * @param area Area to associted
   * @param subPsudoframesthickness frames of areas which are below this frame
   * @returns frame element and superposition offset to move/resize area
   */
  public createFrame(
    framesData: FrameCreatorDraw,
    area: AreaModel,
    subPsudoframesthickness: { [k in SideOptions]: number }
  ): {
    frame: Frame
    superpositionOffsets: { [k in SideOptions]: { x: number; y: number } }
  } {
    const gap = area.gap
    let scaleWidth = gap.scaleWidth
    let scaleHeight = gap.scaleHeight
    if (scaleHeight != scaleWidth) {
      scaleHeight = scaleWidth
    }
    localStorage.setItem('scaleHeight', scaleHeight.toString())
    localStorage.setItem('scaleWidth', scaleWidth.toString())

    const scaledProfilesThickness = {
      top: (framesData.top?.internalCamera ?? 0) * scaleWidth,
      bottom: (framesData.bottom?.internalCamera ?? 0) * scaleWidth,
      left: (framesData.left?.internalCamera ?? 0) * scaleHeight,
      right: (framesData.right?.internalCamera ?? 0) * scaleHeight,
    }

    const scaledAreaPosition = new Vector2(
      gap.xScaled + area.x * scaleWidth,
      gap.yScaled + area.y * scaleHeight
    )
    const scaledAreaWidth = area.width
    const scaledAreaHeight = area.height
    const scaledAreaDimensions = {
      width: scaledAreaWidth * scaleWidth,
      height: scaledAreaHeight * scaleHeight,
    }

    // Calculate frame positions
    const scaledProfileStartIntPoints = this.profilesStartPoints(
      scaledAreaPosition,
      scaledAreaDimensions,
      scaledProfilesThickness
    )

    // Calculate profiles lengths
    const scaledProfilesLengths = this.profilesLenghts(
      scaledAreaDimensions,
      scaledProfilesThickness,
      scaleWidth,
      scaleHeight
    )

    const request = {
      has: {
        top: framesData?.top ? true : false,
        bottom: framesData?.bottom ? true : false,
        left: framesData?.left ? true : false,
        right: framesData?.right ? true : false,
      },
      cutVariations: framesData?.cutVariations,
      scaleFactorWidth: scaleWidth,
      scaleFactorHeight: scaleHeight,
      sides: {
        top: {
          length: scaledProfilesLengths.top,
          profile: framesData?.top,
          startPointInt: scaledProfileStartIntPoints.top,
          width: framesData?.top?.internalCamera ?? 0,
        },
        bottom: {
          length: scaledProfilesLengths.bottom,
          profile: framesData?.bottom,
          startPointInt: scaledProfileStartIntPoints.bottom,
          width: framesData?.bottom?.internalCamera ?? 0,
        },
        left: {
          length: scaledProfilesLengths.left,
          profile: framesData?.left,
          startPointInt: scaledProfileStartIntPoints.left,
          width: framesData?.left?.internalCamera ?? 0,
        },
        right: {
          length: scaledProfilesLengths.right,
          profile: framesData?.right,
          startPointInt: scaledProfileStartIntPoints.right,
          width: framesData?.right?.internalCamera ?? 0,
        },
      },
      superpositions: framesData?.overlaps,
      unions: framesData?.unions,
    }

    // Create pseudoframe (konva.lines)
    const pseudoFrame = this.pseudoFrameDraweService.createPseudoFrameFromStartInt(
      request
    )

    // Create superpositions
    const superpositionOffsets = this.superpositionDrawer.applySuperposition(
      framesData.unions,
      pseudoFrame,
      framesData.overlaps,
      scaleWidth
    )

    // Create cutVariation
    this.cutVariationDrawer.applyCutVariations(
      framesData.cutVariations,
      {
        top: {
          profile: framesData.top,
          profileDraw: pseudoFrame.top,
        },
        bottom: {
          profile: framesData.bottom,
          profileDraw: pseudoFrame.bottom,
        },
        left: {
          profile: framesData.left,
          profileDraw: pseudoFrame.left,
        },
        right: {
          profile: framesData.right,
          profileDraw: pseudoFrame.right,
        },
      },
      {
        top: framesData?.top?.internalCamera ?? 0,
        bottom: framesData?.bottom?.internalCamera ?? 0,
        left: framesData?.left?.internalCamera ?? 0,
        right: framesData?.right?.internalCamera ?? 0,
      },
      'Frame',
      scaleWidth
    )

    // Create frame element
    const frame = this.createFrameElement(
      framesData.union,
      framesData.unions,
      framesData.overlaps,
      framesData.cutVariations,
      {
        top: framesData.top,
        bottom: framesData.bottom,
        left: framesData.left,
        right: framesData.right,
      },
      pseudoFrame,
      scaledProfileStartIntPoints,
      scaledProfilesLengths,
      scaleWidth,
      scaleHeight,
      gap,
      area
    )

    frame.setOverlapsApplied({
      top: superpositionOffsets.top.y,
      bottom: superpositionOffsets.bottom.y,
      left: superpositionOffsets.left.x,
      right: superpositionOffsets.right.x,
    })

    gap.layer.add(frame.group).draw()

    return { frame, superpositionOffsets }
  }

  private createFrameElement(
    unionId: number,
    unions: { [k in UnionPositions]: number },
    overlaps: { [k in SideOptions]: number },
    cutVariations: { [k in SideOptions]: { id: number; value: number } },
    profilesCreator: { [k in SideOptions]?: ProfileCreator },
    profiles: { [k in SideOptions]?: Konva.Line },
    startPoints: { [k in SideOptions]?: Vector2 },
    scaledLength: { [k in SideOptions]?: number },
    scaleWidth: number,
    scaleHeight: number,
    gap: Gap,
    area: AreaModel
  ) {
    const frame = new Frame(
      uuidGenerator.v4(),
      unionId,
      unions,
      overlaps,
      cutVariations
    )

    let topSide = null
    let bottomSide = null
    let leftSide = null
    let rightSide = null

    if (profilesCreator.top) {
      startPoints.top.set(
        (startPoints.top.x - gap.xScaled) / scaleWidth,
        (startPoints.top.y - gap.yScaled) / scaleWidth
      )

      topSide = this.createFrameSide(
        profilesCreator.top,
        profiles.top,
        startPoints.top,
        scaledLength.top,
        scaleWidth,
        {
          'left-top': unions['left-top'],
          'right-top': unions['right-top'],
        },
        'top',
        'Horizontal',
        gap,
        area,
        frame
      )
      frame.addTop(topSide)
    }

    if (profilesCreator.bottom) {
      startPoints.bottom.set(
        (startPoints.bottom.x - gap.xScaled) / scaleWidth,
        (startPoints.bottom.y - gap.yScaled) / scaleWidth
      )

      bottomSide = this.createFrameSide(
        profilesCreator.bottom,
        profiles.bottom,
        startPoints.bottom,
        scaledLength.bottom,
        scaleWidth,
        {
          'left-bottom': unions['left-bottom'],
          'right-bottom': unions['right-bottom'],
        },
        'bottom',
        'Horizontal',
        gap,
        area,
        frame
      )
      frame.addBottom(bottomSide)
    }

    if (profilesCreator.left) {
      startPoints.left.set(
        (startPoints.left.x - gap.xScaled) / scaleHeight,
        (startPoints.left.y - gap.yScaled) / scaleHeight
      )

      leftSide = this.createFrameSide(
        profilesCreator.left,
        profiles.left,
        startPoints.left,
        scaledLength.left,
        scaleHeight,
        {
          'left-top': unions['left-top'],
          'left-bottom': unions['left-bottom'],
        },
        'left',
        'Vertical',
        gap,
        area,
        frame
      )
      frame.addLeft(leftSide)
    }

    if (profilesCreator.right) {
      startPoints.right.set(
        (startPoints.right.x - gap.xScaled) / scaleHeight,
        (startPoints.right.y - gap.yScaled) / scaleHeight
      )
      rightSide = this.createFrameSide(
        profilesCreator.right,
        profiles.right,
        startPoints.right,
        scaledLength.right,
        scaleHeight,
        {
          'right-top': unions['right-top'],
          'right-bottom': unions['right-bottom'],
        },
        'right',
        'Vertical',
        gap,
        area,
        frame
      )
      frame.addRight(rightSide)
    }

    return frame
  }

  private createFrameSide(
    profileCreator: ProfileCreator,
    profileDraw: Konva.Line,
    startIntPoint: Vector2,
    scaledOriginalLengtth: number,
    scaleFactor: number,
    unions: { [k in UnionPositions]?: number },
    side: SideOptions,
    axis: 'Horizontal' | 'Vertical',
    gap: Gap,
    area: AreaModel,
    frame: Frame
  ) {
    return new FrameSide(
      uuidGenerator.v4(),
      profileCreator,
      startIntPoint.x,
      startIntPoint.y,
      scaledOriginalLengtth,
      profileCreator.id,
      area.id,
      area.uuid,
      profileDraw,
      area.layer,
      scaleFactor,
      gap,
      frame,
      unions,
      side,
      axis
    )
  }

  private profilesStartPoints(
    scaledAreaPosition: Vector2,
    scaledAreaDimensions: { width: number; height: number },
    scaledProfilesThickness: { [k in SideOptions]?: number }
  ): { [k in SideOptions]: Vector2 } {
    const { top, bottom, left, right } = scaledProfilesThickness

    const topSide = new Vector2(
      scaledAreaPosition.x + left,
      scaledAreaPosition.y + top
    )

    const bottomSide = new Vector2(
      scaledAreaPosition.x + left,
      scaledAreaPosition.y + scaledAreaDimensions.height - bottom
    )

    const leftSide = new Vector2(
      scaledAreaPosition.x + left,
      scaledAreaPosition.y + top
    )

    const rightSide = new Vector2(
      scaledAreaPosition.x + scaledAreaDimensions.width - right,
      scaledAreaPosition.y + top
    )

    return {
      top: topSide,
      bottom: bottomSide,
      left: leftSide,
      right: rightSide,
    }
  }

  private profilesLenghts(
    scaledAreaDimensions: { width: number; height: number },
    scaledProfileThickness: { [k in SideOptions]: number },
    scaleFactorWidth: number,
    scaleFactorHeight: number
  ): { [k in SideOptions]: number } {
    const { top, bottom, left, right } = scaledProfileThickness

    const topSide = scaledAreaDimensions.width - left - right
    const leftSide = scaledAreaDimensions.height - top - bottom
    const bottomSide = topSide
    const rightSide = leftSide

    const res = {
      top: topSide / scaleFactorWidth,
      bottom: bottomSide / scaleFactorWidth,
      left: leftSide / scaleFactorHeight,
      right: rightSide / scaleFactorHeight,
    }
    console.log('res :', res)

    return res
  }
}
