import { Component, OnInit } from '@angular/core'
import { IMenu } from 'src/app/@shared/@interfaces/menuShared'

@Component({
  selector: 'app-files-menu-options',
  templateUrl: './files-menu-options.component.html',
  styleUrls: ['./files-menu-options.component.scss'],
})
export class FilesMenuOptionsComponent implements OnInit {
  public listItemsMenuProfilesMachines: IMenu = {
    menuTitle: 'Perfiles / Máquinas',
    menuItems: [
      {
        menuItemId: 'profiles',
        menuItemName: 'Perfiles',
        menuItemAction: '/profiles',
      },
      {
        menuItemId: 'crystals',
        menuItemName: 'Cristales',
        menuItemAction: '/crystals',
      },
      {
        menuItemId: 'panels',
        menuItemName: 'Paneles',
        menuItemAction: '/panels',
      },
      {
        menuItemId: 'colors',
        menuItemName: 'Acabados',
        menuItemAction: '/colors',
      },
    ],
  }

  public listItemsMenuAccessoriesProdcuts: IMenu = {
    menuTitle: 'Accesorios / Productos',
    menuItems: [
      {
        menuItemId: 'accesories',
        menuItemName: 'Accesorios',
        menuItemAction: '/accesories',
      },
      {
        menuItemId: 'AccesoriesGroup',
        menuItemName: 'Grupo de accesorios',
        menuItemAction: '/accesories-groups',
      },

      {
        menuItemId: 'Formulas',
        menuItemName: 'Fórmulas',
        menuItemAction: '/formulas',
      },

      // {
      //   menuItemId: 'ArchivesProductions',
      //   menuItemName: 'Archivos Producciones',
      //   menuItemAction: '/Archives Productions',
      // },
      // {
      //   menuItemId: 'Categories',
      //   menuItemName: 'Categorias',
      //   menuItemAction: '/Categories',
      // },
    ],
  }

  constructor() {}

  ngOnInit(): void {}
}
