import { Component, OnInit } from '@angular/core'
import { Frame } from 'src/app/@shared/@models/modelsElements/frame-model'
import { ProfileElement } from 'src/app/@shared/@models/modelsElements/Profile-model'

import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { SideOptions } from 'src/app/@shared/@types/side.types'

@Component({
  selector: 'app-frame-info',
  templateUrl: './frame-info.component.html',
  styleUrls: ['./frame-info.component.scss'],
})
export class FrameInfoComponent implements OnInit {
  public initialState: { frame: Frame }
  public frame: Frame

  public frameObjects: ProfileObject[] = []
  public internalCovergapObjects: ProfileObject[] = []
  public externalCovergapsObjects: ProfileObject[] = []
  public associatedObjects: ProfileObject[] = []

  // Selected
  public selected: SelectedObject = {
    selectedFrame: null,
    selectedinternalCovergap: null,
    selectedexternalCovergap: null,
    selectedAssociated: null,
  }

  public selectedIndex = {
    selectedFrameIndex: 0,
    selectedinternalCovergapIndex: 0,
    selectedexternalCovergapIndex: 0,
    selectedAssociatedIndex: 0,
  }

  // Icons
  public faChevronLeft = faChevronLeft
  public faChevronRight = faChevronRight

  constructor() {}

  ngOnInit(): void {
    this.frame = this.initialState.frame
    this.buildObjects()
  }

  private buildObjects(): void {
    this.frameObjects = this.frame.frames.map((f) => ({
      type: this.translateSide(f.side),
      profile: f,
    }))

    this.selected.selectedFrame = this.frameObjects[
      this.selectedIndex.selectedFrameIndex
    ]

    this.internalCovergapObjects =
      this.frame.covergaps?.internal?.sides.map((c) => ({
        type: this.translateSide(c.side),
        profile: c,
      })) ?? []

    this.selected.selectedinternalCovergap = this.internalCovergapObjects[
      this.selectedIndex.selectedinternalCovergapIndex
    ]

    this.externalCovergapsObjects =
      this.frame.covergaps?.external?.sides.map((c) => ({
        type: this.translateSide(c.side),
        profile: c,
      })) ?? []

    this.selected.selectedexternalCovergap = this.externalCovergapsObjects[
      this.selectedIndex.selectedexternalCovergapIndex
    ]

    this.associatedObjects =
      [
        ...this.frame.associatedProfiles?.top?.map((a) => ({
          type: this.translateSide(a.side),
          profile: a,
        })),
        ...this.frame.associatedProfiles?.bottom?.map((a) => ({
          type: this.translateSide(a.side),
          profile: a,
        })),
        ...this.frame.associatedProfiles?.left?.map((a) => ({
          type: this.translateSide(a.side),
          profile: a,
        })),
        ...this.frame.associatedProfiles?.right?.map((a) => ({
          type: this.translateSide(a.side),
          profile: a,
        })),
      ] ?? []

    this.selected.selectedAssociated = this.associatedObjects[
      this.selectedIndex.selectedAssociatedIndex
    ]
  }

  private translateSide(side: SideOptions): string {
    switch (side) {
      case 'top':
        return 'Superior'
      case 'bottom':
        return 'Inferior'
      case 'left':
        return 'Izquierdo'
      case 'right':
        return 'Derecho'

      default:
        break
    }
  }

  public next(
    array: ProfileObject[],
    actualIndexKey: string,
    selectedkey: string
  ): void {
    const isBiggerThanArray =
      this.selectedIndex[actualIndexKey] >= array.length - 1
    if (isBiggerThanArray) this.selectedIndex[actualIndexKey] = 0
    if (!isBiggerThanArray)
      this.selectedIndex[actualIndexKey] =
        this.selectedIndex[actualIndexKey] + 1

    this.selected[selectedkey] = array[this.selectedIndex[actualIndexKey]]
  }

  public previus(
    array: ProfileObject[],
    actualIndexKey: string,
    selectedKey: string
  ): void {
    const isCero = this.selectedIndex[actualIndexKey] <= 0

    if (isCero) this.selectedIndex[actualIndexKey] = array.length - 1
    if (!isCero)
      this.selectedIndex[actualIndexKey] =
        this.selectedIndex[actualIndexKey] - 1

    this.selected[selectedKey] = array[this.selectedIndex[actualIndexKey]]
  }
}

interface SelectedObject {
  selectedFrame: ProfileObject
  selectedinternalCovergap: ProfileObject
  selectedexternalCovergap: ProfileObject
  selectedAssociated: ProfileObject
}
interface ProfileObject {
  type: string
  profile: ProfileElement
}
