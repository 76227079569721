// ANGULAR
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
// TOOLS
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { AreaModel } from '../@shared/@models/modelsElements/area-model'
import { FrameCreator } from '../@shared/@interfaces/frameCreator'

@Injectable({
  providedIn: 'root',
})
export class FrameService {
  private apiUrl = environment.apiUrl

  constructor(private http: HttpClient) {}

  getUnions(): Observable<any> {
    return this.http.get<any>(environment.apiUrl + 'unions')
  }

  public createFrame(frame: FrameCreator): Observable<any> {
    return this.http.post(`${this.apiUrl}frames`, frame)
  }

  public deleteFrame(frameId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}frames/${frameId}`)
  }
}
