// ANGULAR
import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
// TOOLS
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
// MODELS
import { NewModelModel } from '../@shared/@models/new-model-model'
import { ListModel } from '../@shared/@models/list-model.model'
import { SafeUrl } from '@angular/platform-browser'
import { map } from 'rxjs/operators'
import { blobToDataUrl } from '../@helper/blobToDataUrl'
import { ModelQuery } from '../core/interfaces/ModelQuery.interface'

@Injectable({
  providedIn: 'root',
})
export class ModelService {
  private apiUrl = environment.apiUrl

  constructor(private http: HttpClient) {}

  createModel(model: any): Observable<NewModelModel> {
    return this.http.post<NewModelModel>(environment.apiUrl + 'models', model)
  }

  getModel(modelId: number): Observable<any> {
    return this.http.get<any>(environment.apiUrl + 'models/' + modelId)
  }

  getListModels(
    query: ModelQuery,
    page = 1
  ): Observable<{ models: any[]; totalPages: number }> {
    const params = new HttpParams().set('page', page.toString())

    return this.http.post<{ models: any[]; totalPages: number }>(
      environment.apiUrl + 'models/serie/uuid',
      query,
      { params }
    )
  }

  public createPreviewImage(image: Blob, id: number): Observable<any> {
    const formData = new FormData()
    formData.append('file', image, `${id}.png`)
    return this.http.post(`${this.apiUrl}models/${id}/preview`, formData)
  }

  public deleteModel(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}models/${id}`)
  }

  public getImageDataUrl(modelId: number): Promise<Promise<string>> {
    return this.http
      .get(`${this.apiUrl}models/${modelId}/preview`, {
        responseType: 'blob',
      })
      .pipe(
        map(async (i) => {
          return await blobToDataUrl(i)
        })
      )
      .toPromise()
  }

  public setLeafs(modelId: number, leafs: number): Observable<any> {
    return this.http.post(`${this.apiUrl}models/${modelId}/leafs`, { leafs })
  }

  /**
   * setFixedElementStatus
   */
  public setFixedElementStatus(
    modelId: number,
    hasFixed: boolean
  ): Observable<any> {
    return this.http.post(`${this.apiUrl}models/${modelId}/fixed-element`, {
      hasFixed,
    })
  }

  public async getModelBars(modelId: number): Promise<GroupedElement[]> {
    const params = new HttpParams().set('model', modelId.toString())
    const response: ElementResponse[] = await this.http
      .get<ElementDTO[]>(`${this.apiUrl}area-container/elements`, { params })
      .toPromise()
      .then((array) =>
        array.map((e) => ({
          extLength: e._extLength,
          intLength: e._intLength,
          uuid: e.uuid,
          name: e.profileLongitude?.profile?.description,
          code: e.profileLongitude?.profile?.code,
        }))
      )

    return this.groupElements(response)
  }

  private groupElements(elements: ElementResponse[]): GroupedElement[] {
    const groups: GroupedElement[] = []

    elements.forEach((element) => {
      const group = groups.find((g) => {
        const sameCode = g.element.code === element.code
        const sameExtLength =
          Math.abs(g.element.extLength - element.extLength) < 0.02
        const sameIntLength =
          Math.abs(g.element.intLength - element.intLength) < 0.02
        return sameCode && sameExtLength && sameIntLength
      })

      if (group) group.count += 1

      if (!group)
        groups.push({
          element,
          count: 1,
        })
    })

    return groups
  }
}

interface ElementDTO {
  uuid: string
  _extLength: number
  _intLength: number
  profileLongitude?: {
    uuid: string
    profile: {
      code: string
      description: string
    }
  }
}

export interface ElementResponse {
  uuid: string
  extLength: number
  intLength: number
  code: string
  name: string
}

export interface GroupedElement {
  count: number
  element: ElementResponse
}
