import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { EntityInformationViewComponentDirective } from './entity-information-view-component.directive'

@Component({
  selector: 'app-entity-information',
  templateUrl: './entity-information.component.html',
  styleUrls: ['./entity-information.component.scss'],
})
export class EntityInformationComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('cardContainer') cardContainer: ElementRef<HTMLDivElement>

  @ViewChild(EntityInformationViewComponentDirective)
  container: EntityInformationViewComponentDirective

  private componentRef: ComponentRef<any>

  private onViewInitSubject: Subject<boolean>
  public onViewInit$: Observable<boolean>

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private renderer: Renderer2
  ) {
    this.onViewInitSubject = new Subject()
    this.onViewInit$ = this.onViewInitSubject.asObservable()
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.onViewInitSubject.next(true)
  }

  ngOnDestroy(): void {
    this.componentRef?.destroy()
  }

  public createContent(component: any, initialState: any): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory<any>(
      component
    )
    const viewContainer = this.container.viewContainerRef
    viewContainer.clear()
    this.componentRef = viewContainer.createComponent<any>(componentFactory)
    this.componentRef.instance.initialState = initialState
  }

  public setPosition(x: number, y: number): void {
    this.renderer.setStyle(this.cardContainer.nativeElement, 'top', y + 'px')
    this.renderer.setStyle(this.cardContainer.nativeElement, 'left', x + 'px')
  }
}
