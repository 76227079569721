import Konva from 'konva'
import { degreeToRadians } from 'src/app/@helper/degreeToRadians'
import { radianToDegree } from 'src/app/@helper/radianToDegree'
import { roundUp } from 'src/app/@helper/roundNumber'
import { CUT_VARIATIONS } from 'src/app/@services/cut-variation.service'
import { UNIONS_TYPES } from 'src/app/@services/union.service'
import { CovergapRequest } from '../../@interfaces/covergapRequest'
import { ModelElement } from '../../@interfaces/modelElement'
import { SideOptions } from '../../@types/side.types'
import { UnionPositions } from '../../@types/unionPositions.type'
import { CovergapSideElement } from './covergapSide-model'
import { Frame } from './frame-model'
import { PseudoFrame } from './pseudoframe-model'

export class CoverGapElement extends PseudoFrame implements ModelElement {
  public readonly uuid: string
  private _id: number

  public readonly frame: Frame

  private _covergapSides: { [k in SideOptions]?: CovergapSideElement } = {}

  private _unions: { [k in UnionPositions]: number }
  private _overlaps: { [k in SideOptions]: number }
  private _clips: { [k in SideOptions]: number }
  private _cutVariations: {
    [k in SideOptions]?: { id: number; value: number; value2?: number }
  }

  public readonly group: Konva.Group

  constructor(covergap: ICoverGapElement) {
    super(covergap.uuid)
    this.uuid = covergap.uuid
    this.frame = covergap.frame

    this._unions = covergap.unions
    this._overlaps = covergap.overlaps
    this._clips = covergap.clips
    this._cutVariations = covergap.cutVariations

    this.group = new Konva.Group().addName('cover gap')
  }

  //Getters
  get unions() {
    return this._unions
  }
  get overlaps() {
    return this._overlaps
  }
  get clips() {
    return this._clips
  }
  get cutVariations() {
    return this._cutVariations
  }
  get covergapsSides() {
    return this._covergapSides
  }
  get covergapsSideArray(): CovergapSideElement[] {
    return Object.keys(this._covergapSides).map(
      (key) => this._covergapSides[key]
    )
  }

  get sidesCovergaps() {
    return this._covergapSides
  }

  get id() {
    return this._id
  }

  public setId(id: number): void {
    this._id = id
  }

  public destroy() {
    this.covergapsSideArray.forEach((c) => {
      c?.destroy()
    })
  }

  public setSide(
    side: SideOptions,
    covergapSideElement: CovergapSideElement
  ): void {
    this._covergapSides[side] = covergapSideElement
    this.sides.push(covergapSideElement)
    this.group.add(covergapSideElement.group)
  }

  public calculateBarLength(): {
    [k in SideOptions]?: { ext: number; int: number }
  } {
    const lengths = {}
    this.sides.forEach((c) => {
      const { ext, int } = c.calculateLength()

      lengths[c.side] = {
        ext,
        int,
      }
    })

    return lengths
  }

  public buildRequest(): CovergapRequest {
    const lengths = this.calculateBarLength()
    const { top, bottom, left, right } = this.sidesCovergaps

    const topAngles = top?.getProfileAngles()
    const bottomAngles = bottom?.getProfileAngles()
    const leftAngles = left?.getProfileAngles()
    const rightAngles = right?.getProfileAngles()

    return {
      top: {
        uuid: this.sidesCovergaps.top?.uuid,
        extLong: lengths?.top?.ext,
        intLong: lengths?.top?.int,
        cutVariation: this._cutVariations?.top?.value,
        cutVariation2: this._cutVariations?.top?.value2,
        profile: { id: this._covergapSides?.top?.profile?.id },
        superimposition: this._overlaps?.top,
        typeCutVariation: { id: this._cutVariations?.top?.id },
        ang: roundUp(radianToDegree(topAngles?.mainAngle ?? 0), 2),
        ang2: roundUp(radianToDegree(topAngles?.secondAngle ?? 0), 2),
      },
      bottom: {
        uuid: this.sidesCovergaps.bottom?.uuid,
        extLong: lengths?.bottom?.ext,
        intLong: lengths?.bottom?.int,
        cutVariation: this._cutVariations?.bottom?.value,
        cutVariation2: this._cutVariations?.bottom?.value2,
        profile: { id: this._covergapSides?.bottom?.profile?.id },
        superimposition: this._overlaps?.bottom,
        typeCutVariation: { id: this._cutVariations?.bottom?.id },
        ang: roundUp(radianToDegree(bottomAngles?.mainAngle ?? 0), 2),
        ang2: roundUp(radianToDegree(bottomAngles?.secondAngle ?? 0), 2),
      },
      left: {
        uuid: this.sidesCovergaps.left?.uuid,
        extLong: lengths?.left?.ext,
        intLong: lengths?.left?.int,
        cutVariation: this._cutVariations?.left?.value,
        cutVariation2: this._cutVariations?.left?.value2,
        profile: { id: this._covergapSides?.left?.profile?.id },
        superimposition: this._overlaps?.left,
        typeCutVariation: { id: this._cutVariations?.left?.id },
        ang: roundUp(radianToDegree(leftAngles?.mainAngle ?? 0), 2),
        ang2: roundUp(radianToDegree(leftAngles?.secondAngle ?? 0), 2),
      },
      right: {
        uuid: this.sidesCovergaps.right?.uuid,
        extLong: lengths?.right?.ext,
        intLong: lengths?.right?.int,
        cutVariation: this._cutVariations?.right?.value,
        cutVariation2: this._cutVariations?.right?.value2,
        profile: { id: this._covergapSides?.right?.profile?.id },
        superimposition: this._overlaps?.right,
        typeCutVariation: { id: this._cutVariations?.right?.id },
        ang: roundUp(radianToDegree(rightAngles?.mainAngle ?? 0), 2),
        ang2: roundUp(radianToDegree(rightAngles?.secondAngle ?? 0), 2),
      },
      frame: { id: this.frame?.id },
      clip: this._clips,
    }
  }
}

interface ICoverGapElement {
  uuid: string
  frame: Frame
  unions: { [k in UnionPositions]: number }
  overlaps: { [k in SideOptions]: number }
  clips: { [k in SideOptions]: number }
  cutVariations: {
    [k in SideOptions]?: { id: number; value: number; value2?: number }
  }
}
