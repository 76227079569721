import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { radianToDegree } from '../@helper/radianToDegree'
import { roundUp } from '../@helper/roundNumber'
import { DoorWindowTipupOpeningElement } from '../@shared/@models/modelsElements/door-window-tipupOpening-model'
import { ProfileElement } from '../@shared/@models/modelsElements/Profile-model'

@Injectable({
  providedIn: 'root',
})
export class TipupOpeningService {
  private apiUrl = environment.apiUrl

  constructor(private http: HttpClient) {}

  public createDoorWindowOpening(
    opening: DoorWindowTipupOpeningElement,
    model: number
  ): Observable<any> {
    const body: DoorWindowBodyRequest = {
      model: model,
      leafList: opening.leafts.map((l) => {
        const { top, bottom, left, right } = l.profilesElements

        return {
          uuid: l.uuid,
          principalTop: this.generateProfileRequest(top),
          principalBottom: this.generateProfileRequest(bottom),
          principalLeft: this.generateProfileRequest(left),
          principalRight: this.generateProfileRequest(right),
          isMain: l.hasHandleDoor,
          area: l.associatedArea.id,
          moveLeaf: { id: l.openingType },
          ...(l.inversor
            ? {
                investor: this.generateProfileRequest(l.inversor),
              }
            : {}),
          ...(l.dripTray
            ? {
                conduit: this.generateProfileRequest(l.dripTray),
              }
            : {}),
        }
      }),
      handleHeight: opening.leafts[0].handledoorHeight,
      heightReference: { id: 1 },
      ...(opening.doorStepElement
        ? { threshold: this.generateProfileRequest(opening.doorStepElement) }
        : {}),
      typeAperture: { id: 1 },
      area: { id: opening.leafts[0].associatedArea.id },
    }

    return this.http.post(`${this.apiUrl}apertures`, body)
  }

  public delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}apertures/${id}`)
  }

  private generateProfileRequest(profile: ProfileElement): LeafProfileRequest {
    const { mainAngle, secondAngle } = profile.getProfileAngles()

    return {
      uuid: profile.uuid,
      profile: { id: profile.profile.id },
      intLong: profile.calculateLength().int,
      extLong: profile.calculateLength().ext,
      typeVariation: { id: profile.cutVariation.id },
      cutVariation: profile.cutVariation.value,
      cutVariation2: profile.cutVariation.value2 ?? 0,
      superimposition: profile.overlap,
      ang: roundUp(radianToDegree(mainAngle), 2),
      ang2: roundUp(radianToDegree(secondAngle), 2),
    }
  }
}

interface LeafProfileRequest {
  uuid: string
  profile: {
    id: number
  }
  intLong: number
  extLong: number
  superimposition: number
  cutVariation: number
  cutVariation2: number
  typeVariation: {
    id: number
  }
  ang: number
  ang2: number
}

interface LeaftRequest {
  uuid: string
  aperture?: {
    id: number
  }
  moveLeaf: {
    id: number
  }
  principalLeft: LeafProfileRequest
  principalRight: LeafProfileRequest
  principalTop: LeafProfileRequest
  principalBottom: LeafProfileRequest
  investor?: LeafProfileRequest
  conduit?: LeafProfileRequest //Tapajuntas
  isMain: boolean
}

interface DoorWindowBodyRequest {
  model: number
  leafList: LeaftRequest[]
  handleHeight: number
  heightReference: {
    id: number
  }
  typeAperture: {
    id: number
  }
  area: {
    id: number
  }
  threshold?: LeafProfileRequest
}
