import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core'
import { ToastrService } from 'ngx-toastr'
import { fromEvent, Subscription } from 'rxjs'
import { debounceTime, map } from 'rxjs/operators'
import { OpeningDialogComponent } from 'src/app/@dialogs/opening-dialog/opening-dialog.component'
import { ModalService } from 'src/app/@services/modal.service'
import { AreaDrawService } from 'src/app/@services/modelDrawServices/area-draw.service'
import { TipupOpeningDrawService } from 'src/app/@services/modelDrawServices/tipup-opening-draw.service'
import { TipupOpeningService } from 'src/app/@services/tipup-opening.service'
import { IOpeningWindowDoor } from 'src/app/@shared/@interfaces/openingObject'
import { DialogInstanceModel } from 'src/app/@shared/@models/dialog-instance-model'
import {
  Area,
  AreaModel,
} from 'src/app/@shared/@models/modelsElements/area-model'
import { DoorWindowTipupOpeningElement } from 'src/app/@shared/@models/modelsElements/door-window-tipupOpening-model'
import { DoorStepElement } from 'src/app/@shared/@models/modelsElements/doorstepElement.model'
import { Gap } from 'src/app/@shared/@models/modelsElements/gap-model'
import { Side } from 'src/app/@shared/@types/side.types'
import { EntityInformationService } from 'src/app/@shared/entity-information/entity-information.service'
import { Vector2 } from 'three'
import * as uuidGenerator from 'uuid'
import { DooeWindowInfoComponent } from '../../entity-info/dooe-window-info/dooe-window-info.component'
import { IconSidebarItem } from '../icon-sidebar-item/icon-sidebar.interface'

@Component({
  selector: 'app-add-tipup-opening-icon-item',
  templateUrl: './add-tipup-opening-icon-item.component.html',
  styleUrls: ['./add-tipup-opening-icon-item.component.scss'],
})
export class AddTipupOpeningIconItemComponent
  implements OnInit, IconSidebarItem {
  @Input() modelObject
  @Input() gap: Gap

  public canUpdate = false

  public gapSubscription: Subscription

  constructor(
    private toastr: ToastrService,
    private modalService: ModalService,
    private tipupOpeningDrawer: TipupOpeningDrawService,
    private tipupOpeningService: TipupOpeningService,
    private areaDrawService: AreaDrawService,
    private entityInfoService: EntityInformationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.gap) this.gapSubscribe()
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.gapSubscription?.unsubscribe()
  }

  public gapSubscribe(): void {
    if (this.gapSubscription) return
    this.gapSubscription = this.gap.gapUpdated$.subscribe(() => {
      this.canUpdate = this.canActivateBotton()
    })
  }

  public canActivateBotton(): boolean {
    const hasSelected = this.gap?.getSelectedElement<DoorWindowTipupOpeningElement>(
      DoorWindowTipupOpeningElement
    )
      ? true
      : false

    const hasSelectedAreaAOpening = this.gap?.selectedArea?.doorWindowOpenings
      ? true
      : false

    const hasSelectedArea = this.gap?.selectedArea ? true : false

    return hasSelected || (!hasSelectedAreaAOpening && hasSelectedArea)
  }

  public openOpeningsDialog(): any {
    const selectedArea = this.gap?.selectedArea
    const selectedOpening = this.gap?.getSelectedElement<DoorWindowTipupOpeningElement>(
      DoorWindowTipupOpeningElement
    )

    const hasSelected = selectedOpening ? true : false

    if (!selectedArea && !hasSelected)
      return this.toastr.warning('Seleccione un area o apertura')

    const dialog = new DialogInstanceModel(OpeningDialogComponent, {
      initialState: {
        area: selectedArea,
        serieId: this.modelObject.serie.id,
        selectedOpening,
      },
      class: 'modal-dialog-centered modal-lg modal-openings',
    })

    const tipupOpeningElement = new DoorWindowTipupOpeningElement(
      uuidGenerator.v4()
    )

    this.modalService
      .openModal(dialog)
      .subscribe((response: IOpeningWindowDoor[]) => {
        if (!response) {
          this.removeOpening(selectedOpening)
          return
        }

        if (!response[0].profiles.top.profile)
          return this.toastr.warning('Seleccione un perfil principal')

        if (selectedOpening) {
          this.updateOpening(
            selectedOpening,
            response,
            selectedOpening,
            tipupOpeningElement
          )
        }

        if (!selectedOpening)
          this.newDoorWindowOpening(selectedArea, response, tipupOpeningElement)

        const openingSubscriptions = this.createSubscription(
          tipupOpeningElement
        )
        tipupOpeningElement.addSubscriptions(openingSubscriptions)

        this.gap.layer.draw()
      })
  }

  public async removeOpening(
    oldOpening: DoorWindowTipupOpeningElement
  ): Promise<AreaModel> {
    const oldArea = oldOpening.leafts[0].associatedArea
    const scaleFactor = oldArea.gap.scaleWidth
    const start = oldOpening
      .getPosition()
      .add(
        new Vector2(
          oldOpening.mainOverlap,
          oldOpening.mainOverlap
        ).multiplyScalar(scaleFactor)
      )

    const oldAreaDimension = {
      width: oldOpening.getTotalWidth() - 2 * oldOpening.mainOverlap,
      height: oldOpening.getTotalHeight() - 2 * oldOpening.mainOverlap,
    }

    oldArea.resize(oldAreaDimension.width, oldAreaDimension.height)
    oldArea.moveScaled(start.x, start.y)

    oldArea.gap.setGapDownZIndex()
    oldArea.removeDoorOpening(oldOpening.uuid)
    oldArea.removeLeaft()

    oldOpening.destroy()
    await this.tipupOpeningService.delete(oldOpening.id).toPromise()

    oldArea.container = oldOpening.container
    oldArea.container.setVinculatedArea(oldArea)

    oldArea.container.removeElements()
    oldArea.container.setVerticalContain([])
    oldArea.container.setHorizontalContain([])
    oldArea.layer.draw()
    oldArea.gap.notifyAreasUpdate()

    return oldArea
  }

  private async updateOpening(
    oldOpening: DoorWindowTipupOpeningElement,
    response: IOpeningWindowDoor[],
    tipupOpeningElement: DoorWindowTipupOpeningElement,
    newDoorWindowOpening: DoorWindowTipupOpeningElement
  ) {
    const oldArea = await this.removeOpening(oldOpening)
    this.newDoorWindowOpening(oldArea, response, newDoorWindowOpening)
  }

  private newDoorWindowOpening(
    selectedArea: AreaModel,
    response: IOpeningWindowDoor[],
    tipupOpeningElement: DoorWindowTipupOpeningElement
  ) {
    const parentContainer = selectedArea.container

    const areas = [selectedArea]
    const hasDoorstep = response[0].doorstep?.profile ? true : false

    let doorStepProfileElement: DoorStepElement
    if (hasDoorstep) {
      doorStepProfileElement = this.tipupOpeningDrawer.createDoorStep(
        response[0].doorstep,
        selectedArea
      )
    }

    parentContainer.addElements([doorStepProfileElement])

    response.forEach((data, index) => {
      const area = areas[index]
      const isLastOpening = index === response.length - 1

      if (!isLastOpening) {
        areas.push(
          this.areaDrawService.splitAreaHorizontally(
            data.openingWidth,
            area,
            this.modelObject.serie.openingOffset,
            parentContainer
          )
        )
      }

      const areaParentContainer = area.container

      const newAreaContainer = new Area({
        width: areaParentContainer.width,
        height: areaParentContainer.height,
        parent: areaParentContainer,
        x: area.x,
        y: area.y,
        modelId: areaParentContainer.modelId,
      })

      areaParentContainer.horizontalContain.push(newAreaContainer)
      area.container = newAreaContainer
      area.container.setVinculatedArea(area)
      areaParentContainer.setVinculatedArea(null)

      const isFirstOpening = index === 0

      const { left, right, top, bottom } = data.profiles

      const leftDiff = left.profile.internalCamera + left.profile.externalFin
      const rightDiff = right.profile.internalCamera + right.profile.externalFin

      const firstLeaft = leftDiff + rightDiff

      const middleLeaft =
        left.profile.internalCamera +
        right.profile.internalCamera +
        left.profile.externalFin +
        right.profile.externalFin
      const lastLeaft =
        left.profile.internalCamera + rightDiff + left.profile.externalFin

      const newAreaWidth =
        area.width -
        (isFirstOpening ? firstLeaft : isLastOpening ? lastLeaft : middleLeaft)

      const newAreaHeight =
        area.height -
        (top.profile.internalCamera + top.profile.externalFin) -
        (bottom.profile.internalCamera + bottom.profile.externalFin)

      area.resize(newAreaWidth, newAreaHeight)
      area.move(
        area.x + left.profile.internalCamera + left.profile.externalFin,
        area.y + (top.profile.internalCamera + top.profile.externalFin)
      )

      const leaft = this.tipupOpeningDrawer.windowDoorDrawer(
        data,
        area,
        this.modelObject.serie.openingOffset,
        isFirstOpening,
        isLastOpening
      )

      const superpositions: Side<number> = {
        top: data.profiles.top.superposition ?? 0,
        bottom: data.profiles.bottom.superposition ?? 0,
        left: isFirstOpening ? data.profiles.left.superposition ?? 0 : 0,
        right: isLastOpening ? data.profiles.right.superposition ?? 0 : 0,
      }

      area.resize(
        area.width + superpositions.left + superpositions.right,
        area.height + superpositions.top + superpositions.bottom
      )
      area.move(area.x - superpositions.left, area.y - superpositions.top)

      area.container.updateWidth(area.width)
      area.container.updateHeight(area.height)
      area.container.updatePosition(area.x, area.y)

      area.addLeaft(leaft)
      tipupOpeningElement.addLeaft(leaft)

      areaParentContainer.addElements([
        leaft.profilesElements.left,
        leaft.profilesElements.right,
        leaft.profilesElements.top,
        leaft.profilesElements.bottom,
        leaft.inversor,
        leaft.dripTray,
      ])

      leaft.container = area.container

      area.layer.add(tipupOpeningElement.group)
      area.setZIndexTop()
      area.addDoorWindowOpening(tipupOpeningElement)
    })

    if (hasDoorstep) {
      doorStepProfileElement.group.setZIndex(99)
      tipupOpeningElement.addDoorStep(doorStepProfileElement)
    }

    tipupOpeningElement.setMainOverlap(response[0].profiles.top.superposition)
    tipupOpeningElement.container = parentContainer

    this.gap.subscribeToDoorWindowOpenings()
    tipupOpeningElement.select()
  }

  private createSubscription(
    opening: DoorWindowTipupOpeningElement
  ): Subscription[] {
    const oversub = fromEvent(opening.group, 'contextmenu')
      .pipe(
        map((e) => {
          e.preventDefault()
          e.stopPropagation()
          return e
        }),
        debounceTime(200)
      )
      .subscribe((e: MouseEvent) => {
        this.entityInfoService.hide()
        this.entityInfoService.showCard(DooeWindowInfoComponent, e, { opening })
      })

    return [oversub]
  }
}
