import { DOCUMENT } from '@angular/common'
import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Inject,
  Injectable,
  Injector,
  Renderer2,
  RendererFactory2,
  Type,
  ViewContainerRef,
} from '@angular/core'
import { EntityInformationComponent } from './entity-information.component'

@Injectable({
  providedIn: 'root',
})
export class EntityInformationService {
  private renderer: Renderer2

  private containerCardRef: ComponentRef<EntityInformationComponent>

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private rendererFactory: RendererFactory2,
    private appRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null)
  }

  public showCard(
    component: Type<any>,
    e: MouseEvent,
    initialState: any
  ): void {
    const cardContainer = this.createCardContainer()
    setTimeout(() => {
      this.containerCardRef.instance.createContent(component, initialState)
    }, 0)
    this.fixCardPosition(e, cardContainer)
  }

  public hide(): void {
    if (!this.containerCardRef) return
    this.deleteCardContaienr()
  }

  private createCardContainer(): HTMLElement {
    this.containerCardRef = this.componentFactoryResolver
      .resolveComponentFactory<EntityInformationComponent>(
        EntityInformationComponent as any
      )
      .create(this.injector)

    this.appRef.attachView(this.containerCardRef.hostView)

    const element = (this.containerCardRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement

    this.renderer.appendChild(this.document.body, element)
    return element
  }

  private fixCardPosition(e: MouseEvent, element: HTMLElement): void {
    const position = {
      x: e.x + 16,
      y: e.y + 16,
    }

    const instance = this.containerCardRef?.instance

    if (!instance) return

    const sub = instance.onViewInit$.subscribe((isViewInit) => {
      if (!isViewInit) return
      instance.setPosition(position.x, position.y)
      sub.unsubscribe()
    })
  }

  private deleteCardContaienr(): void {
    this.appRef.detachView(this.containerCardRef.hostView)
    this.containerCardRef.destroy()
  }
}
