<div class="wrapper-door">
  <!-- imagenes aberturas -->
  <app-opening-images-grid
    [$images]="openingDoorImages"
  ></app-opening-images-grid>

  <!-- caja formulario -->
  <div class="form">
    <!-- primer parrafo altura manilla y desplegable   -->
    <div class="form_first-pair">
      <div class="form_height">
        <label>Altura manilla</label>
        <input class="form_space" name="alturamanilla" type="text" />
      </div>
      <div class="form_reference">
        <label>Referencia</label>
        <select class="form_space" name="OS">
          <option value="1">Del piso</option>
          <option value="2">Del suelo</option>
        </select>
      </div>
    </div>
    <!-- primer parrafo altura manilla y desplegable   -->

    <!-- añadir hojas -->
    <div class="sheet-total">
      <div class="add-sheet">
        <app-opening-table-leaft></app-opening-table-leaft>
      </div>
    </div>
    <!-- añadir hojas -->

    <!-- pefiles -->
    <div class="columns">
      <!-- primera columna  -->

      <div class="columns_profiles">
        <div>
          <input type="checkbox" value="false" />
        </div>
        <div>
          <label>Perfil principal</label>
          <br />
          <app-overlaps></app-overlaps>
        </div>
        <app-profile-select></app-profile-select>

        <div>
          <input type="checkbox" />
        </div>
        <div>
          <label>Perfil inferior</label>
          <br />
          <app-overlaps></app-overlaps>
        </div>
        <app-profile-select></app-profile-select>

        <span class="columns_profiles-handle">
          Perfil lateral de la manilla
        </span>
        <app-profile-select></app-profile-select>

        <span class="columns_profiles-handle"> Canaleta </span>

        <app-profile-select></app-profile-select>

        <div class="columns_profiles-handle">
          <label>Umbral</label>
          <br />
          <app-overlaps></app-overlaps>
        </div>
        <app-profile-select></app-profile-select>

        <span class="columns_profiles-handle"> Base</span>
        <app-profile-select></app-profile-select>

        <span class="columns_profiles-handle"> Base superpuesta</span>
        <app-profile-select></app-profile-select>
      </div>

      <!-- segunda columna  -->

      <div class="columns_profiles2">
        <span class="columns_profiles-handle"> Parte inferior base </span>
        <app-profile-select></app-profile-select>

        <span class="columns_profiles-handle">
          Variación saliente superior
        </span>
        <app-profile-select></app-profile-select>

        <input type="checkbox" />
        <label>Unión central suplementaria</label>
        <app-profile-select></app-profile-select>

        <span class="columns_profiles-handle"> Varilla </span>
        <app-profile-select></app-profile-select>

        <span class="columns_profiles-handle"> Inversión del tope </span>
        <app-profile-select></app-profile-select>

        <span class="handle2"> Distancia agujeros tirador </span>
        <app-profile-select></app-profile-select>
      </div>
    </div>
    <!-- pefiles -->
  </div>
</div>
