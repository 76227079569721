import { Component, Input, OnInit } from '@angular/core'
import { IMenuItems } from 'src/app/@shared/@interfaces/menuItems'
import { IMenu } from 'src/app/@shared/@interfaces/menuShared'

@Component({
  selector: 'app-files-menu',
  templateUrl: './files-menu.component.html',
  styleUrls: ['./files-menu.component.scss'],
})
export class FilesMenuComponent implements OnInit {
  @Input() menuListItems: IMenu

  constructor() {}

  ngOnInit(): void {}

  onClickItem(itemMenu: IMenuItems) {}
}
