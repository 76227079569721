import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'

import { environment } from 'src/environments/environment'
import { AccessoryCreator } from '../@shared/@interfaces/accesory'

@Injectable({
  providedIn: 'root',
})
export class ProfileAccesoriesCombinationService {
  private apiUrl = environment.apiUrl
  private urlPrefix = this.apiUrl + 'profile-Accesories'

  constructor(private http: HttpClient) {}

  // get accesories
  public getAllAccesories(): Observable<any> {
    return this.http.get(`${this.apiUrl}accessories/db2`)
  }

  public getFormulas(): Observable<any> {
    return this.http.get(`${this.apiUrl}formula-templates`)
  }

  public getFormulasByType(type: string): Observable<any> {
    const params = new HttpParams().set('type', type)
    return this.http.get(`${this.apiUrl}formula-templates/type`, { params })
  }

  public getAccesoriesByProfileId(profileId: number): Observable<any> {
    const params = new HttpParams().set('profile', profileId.toString())
    return this.http.get(`${this.urlPrefix}`, { params })
  }

  public createAccessory(
    profileId: number,
    accessory: AccessoryCreator
  ): Observable<any> {
    return this.http.post(
      `${this.apiUrl}profiles/${profileId}/accessories`,
      accessory
    )
  }

  public updateAccessory(
    profileId: number,
    id: number,
    accessory: AccessoryCreator
  ): Observable<any> {
    return this.http.put(
      `${this.apiUrl}profiles/${profileId}/accessories/${id}`,
      accessory
    )
  }

  // Delete accesorie
  public deleteAccesorie(profileId: number, id: number): Observable<any> {
    return this.http.delete(
      `${this.apiUrl}profiles/${profileId}/accessories/${id}`
    )
  }
}
