import { Component } from '@angular/core'

export class DialogInstanceModel {
  component: any
  config: Object

  constructor(component: any, config: Object) {
    this.component = component
    this.config = config
  }
}
