import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { Accesory } from '../@shared/@interfaces/accesory'

@Injectable({
  providedIn: 'root',
})
export class AccesoryService {
  private apiUrl = environment.apiUrl + 'accessories'

  constructor(private http: HttpClient) {}

  public getAllAccesories(): Observable<Accesory[]> {
    return this.http.get<Accesory[]>(`${this.apiUrl}/db2`)
  }
}
