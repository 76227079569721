import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { Color } from 'three'
import { AccessoriesModelCreator } from '../@shared/@interfaces/accessoriesModelCreator'
import { AccessoriesModelUpdate } from '../@shared/@interfaces/accessoriesModelUpdate'
import { AccessoryModel } from '../@shared/@interfaces/accessory-model.model'

@Injectable({
  providedIn: 'root',
})
export class AccessoriesModelService {
  private apiUrl = environment.apiUrl

  constructor(private http: HttpClient) {}

  public getAccessoriesModel(modelId: number): Observable<AccessoryModel[]> {
    const params = new HttpParams().set('model', modelId.toString())

    return this.http.get<AccessoryModel[]>(`${this.apiUrl}accessories-models`, {
      params,
    })
  }
  public getColorsAccessory(family: string, codRef: string): Observable<any[]> {
    return this.http.get<Color[]>(
      `${this.apiUrl}accessories/colors/${family}/${codRef}`
    )
  }

  createAccessoriesModel(
    accessoriesModelCreator: AccessoriesModelCreator
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}accessories-models`,
      accessoriesModelCreator
    )
  }

  public deleteAccessoriesModel(id: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}accessories-models/${id}`)
  }

  public deleteAccessoriesModelGroup(id: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}accessories-models/group/${id}`)
  }

  public updateAccessoriesModel(
    accessoryModel: AccessoriesModelUpdate,
    accessoryModelId: number
  ): Observable<AccessoryModel> {
    return this.http.put<AccessoryModel>(
      `${this.apiUrl}accessories-models/${accessoryModelId}`,
      accessoryModel
    )
  }

  // Create throught formulation
  public setAccessoryModelToEntity(
    request: CreateAccessoryModelRequest
  ): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}accessories-models/extractor`,
      request
    )
  }
}

export interface CreateAccessoryModelRequest {
  entities: Array<{ type: string; entity: string }>
  accessory: { codeRef: string; family: string }
  formula: string
  model: number
  type: string
  typeId: any
  accessorySerieGroupId?: number
}
