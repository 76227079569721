import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'

// Fontawesome
import {
  faPlus,
  faSearch,
  faEdit,
  faTrash,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons'

// toastr
import { ToastrService } from 'ngx-toastr'
import { DeleteItemDialogComponent } from 'src/app/@dialogs/delete-item-dialog/delete-item-dialog.component'
import { ModalService } from 'src/app/@services/modal.service'

import { ProfileUsesService } from 'src/app/@services/profile-uses.service'
import { UseProfile } from 'src/app/@shared/@interfaces/useProfile'
import { CutVariation } from 'src/app/@shared/@models/cut-variation.model'
import { DialogInstanceModel } from 'src/app/@shared/@models/dialog-instance-model'
import { ProfileUse } from 'src/app/@shared/@models/profile-use.model'
import { ProfileUseType } from 'src/app/@shared/@models/profileUseType.model'
import { Profile } from '../../profile-management-editor.component'

interface NewUse {
  use: UseProfile
  index: number
}

@Component({
  selector: 'app-profile-uses',
  templateUrl: './profile-uses.component.html',
  styleUrls: ['./profile-uses.component.scss'],
})
export class ProfileUsesComponent implements OnInit, OnChanges {
  @Input() public profile: Profile

  // Icons
  public faPlus = faPlus
  public faSearch = faSearch
  public faEdit = faEdit
  public faTrash = faTrash
  public faCheckCircle = faCheckCircle

  // Dependencies arrays
  public useTypes: ProfileUseType[]
  public cutVariations: CutVariation[]
  private hasDependencies = false

  // form
  public form: FormArray
  public filteredForm: FormArray
  private useControlModel: any
  public searchForm: FormGroup

  //ProfileUses
  private newUses: NewUse[] = []

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private modalService: ModalService,
    private profileUsesService: ProfileUsesService
  ) {
    this.useTypes = []
    this.cutVariations = []
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const hasProfileChanged = this.hasProfileChanged(changes)
    if (!this.hasDependencies) {
      await this.getDependencies()
      this.hasDependencies = true
    }

    if (this.hasProfileChanged(changes)) {
      this.form = this.fb.array([])
      this.newUses = []
    }

    if (this.profile && this.hasDependencies && hasProfileChanged)
      this.getProfileUses()
  }

  ngOnInit(): void {
    this.buildForm()
  }

  private hasProfileChanged(changes: SimpleChanges): boolean {
    const currentProfile = changes.profile.currentValue
    const previusProfile = changes.profile.previousValue

    return !(currentProfile?.id === previusProfile?.id)
  }

  // Form control
  private buildForm(): void {
    this.useControlModel = {
      useType: [1, [Validators.required]],
      cutVariation: [1, [Validators.required]],
      overlap: [0, [Validators.required]],
      value: [0, [Validators.required]],
      updating: [true],
      isNewRemote: [true],
      isNew: [true],
      id: [''],
    }

    this.form = this.fb.array([])
    this.filteredForm = this.form
    this.searchForm = this.fb.group({
      search: [''],
      show: [false],
    })
  }
  public getUseTypeName(id?: number): string {
    if (!id) return 'Tipo de uso'

    const find = this.useTypes.find((value) => value.id === id)
    return find ? find.name : 'Tipo de corte'
  }
  public getCutVariationName(id?: number): string {
    if (!id) return 'Tipo de corte'

    const cut = this.cutVariations.find((value) => value.id === id)
    return cut ? cut.name : 'Tipo de corte'
  }

  // On change tab or submit
  public buildProfileUses(): Array<UseProfile> {
    const useToPost: UseProfile[] = []
    this.newUses.forEach((use) => useToPost.push(use.use))

    return useToPost
  }

  // Form events
  public isUpdating(formGroupItem: FormGroup): boolean {
    return formGroupItem.get('updating').value
  }

  public addUse(use?: ProfileUse, id?: number): any {
    if (use)
      return this.form.push(
        this.fb.group({
          index: [this.form.controls.length],
          useType: [use.useType, [Validators.required]],
          cutVariation: [use.cutVariation, [Validators.required]],
          overlap: [use.overlap, [Validators.required]],
          value: [use.value, [Validators.required]],
          updating: [false],
          isNewRemote: [false],
          isNew: [false],
          id: [id],
        })
      )

    this.form.push(
      this.fb.group({ index: [this.form.length], ...this.useControlModel })
    )

    this.searchUses()
  }
  public editUse(useFormGroup: FormGroup): void {
    useFormGroup.get('updating').setValue(true)
  }
  public updateUse(useFormGroup: FormGroup, index: number): void {
    const isNewRemote = useFormGroup.get('isNewRemote').value
    const isNew = useFormGroup.get('isNew').value

    // Local
    if (isNewRemote && isNew) this.createUse(useFormGroup, index)
    if (isNewRemote && !isNew) this.updateLocal(useFormGroup, index)
    if (!isNewRemote) this.updateRemote(useFormGroup)
    useFormGroup.get('updating').setValue(false)

    this.searchUses()
  }
  public updateRemote(useFormGroup: FormGroup): void {
    const useValue = useFormGroup.value
    const id = useValue.id

    const use: UseProfile = {
      cutVariation: {
        id: useValue.cutVariation,
      },
      useTypeProfile: {
        id: useValue.useType,
      },
      overlap: useValue.overlap,
      value: useValue.value,
    }

    this.profileUsesService.updateUse(id, use).subscribe(
      (response) => {
        this.toastr.success('uso actualizado')
      },
      (err) => {
        this.toastr.error('No se ha podido actualizar')
      }
    )
  }
  public updateLocal(useFormGroup: FormGroup, index: number): void {
    const useFormValue = useFormGroup.value
    const use = this.newUses.find((value) => value.index === index)

    if (use)
      use.use = {
        useTypeProfile: {
          id: useFormValue.useType,
        },
        cutVariation: {
          id: useFormValue.cutVariation,
        },
        overlap: useFormValue.overlap,
        value: useFormValue.value,
      }
  }
  public createUse(useFormGroup: FormGroup, index: number): void {
    const formValue = useFormGroup.value

    const newUse: UseProfile = {
      cutVariation: {
        id: formValue.cutVariation,
      },
      overlap: formValue.overlap,
      useTypeProfile: {
        id: formValue.useType,
      },
      value: formValue.value,
    }

    this.newUses.push({
      use: newUse,
      index,
    })

    if (this.profile)
      this.profileUsesService.createUse(this.profile.id, newUse).subscribe(
        (response) => {
          useFormGroup.get('isNewRemote').setValue(false)
          useFormGroup.get('id').setValue(response.id)
          this.toastr.success('Se ha creado el uso')
        },
        (err) => {
          this.toastr.error('No se ha podido crear el uso')
          this.form.removeAt(index)
        }
      )

    useFormGroup.get('isNew').setValue(false)
  }
  public deleteUse(useFormGroup: FormGroup, index: number): void {
    const useFormValue = useFormGroup.value

    const dialogModel = new DialogInstanceModel(DeleteItemDialogComponent, {
      initialState: {
        itemName: 'el uso',
      },
      class: 'modal-dialog-centered',
    })

    this.modalService.openModal(dialogModel).subscribe(
      (response: boolean) => {
        if (response) {
          if (useFormValue.isNewRemote) this.deleteLocal(index)
          if (!useFormValue.isNewRemote) this.deleteRemote(index)

          this.searchUses()
        }
      },
      (err) => {}
    )
  }
  public deleteLocal(index: number): void {
    const newUsesAux: NewUse[] = []

    this.newUses.forEach((use) => {
      if (use.index !== index) newUsesAux.push(use)
    })

    this.form.removeAt(index)
    this.newUses = newUsesAux
    this.searchUses()
  }
  public deleteRemote(index: number): void {
    const id = this.form.value[index].id

    this.profileUsesService.deleteUse(id).subscribe(
      (response) => {
        this.form.removeAt(index)
        this.toastr.success('El uso a sido eliminado')
        this.searchUses()
      },
      (err) => {
        this.toastr.error('No se ha podido eliminar el uso')
      }
    )
  }

  // onSelect
  public selectUseType(control: FormGroup, type: ProfileUseType): void {
    const useTypeControl = control.get('useType')
    useTypeControl.setValue(type.id)
  }
  public selectCutVariation(control: FormGroup, cut: CutVariation): void {
    control.get('cutVariation').setValue(cut.id)
  }

  private getProfileUses(): void {
    this.profileUsesService.getUsesByProfileId(this.profile.id).subscribe(
      (usesData) => {
        const uses = usesData as Array<any>

        uses.forEach((use) => {
          const newUse: ProfileUse = new ProfileUse(
            use.useTypeProfile.id,
            use.cutVariation.id,
            use.overlap,
            use.value
          )

          this.addUse(newUse, use.id)
        })

        this.searchUses()
      },
      (err) => {}
    )
  }

  // Dependencies
  private async getDependencies(): Promise<void> {
    await this.getProfileUseTypes()
    await this.getCutVariations()
  }
  public async getProfileUseTypes(): Promise<void> {
    await this.profileUsesService
      .getProfileUseTypes()
      .toPromise()
      .then((types) => (this.useTypes = types))
      .catch((error) => {})
  }
  public async getCutVariations(): Promise<void> {
    await this.profileUsesService
      .getCutVariations()
      .toPromise()
      .then((variations) => (this.cutVariations = variations))
      .catch((err) => {})
  }

  // Search
  public searchUses(criteria?: string): any {
    criteria = criteria ?? this.searchForm.get('search').value

    // Set indexs
    this.form.controls.forEach((control, index) => {
      control.get('index').setValue(index)
    })

    if (!criteria) {
      this.filteredForm.controls = this.form.controls
      return
    }

    criteria = criteria.toLowerCase()

    this.filteredForm.controls = this.form.controls.filter((control) => {
      const cutVariationId: number = control.get('cutVariation').value
      const useTypeId: number = control.get('useType').value

      const cutVariationName = this.getCutVariationName(
        cutVariationId
      ).toLowerCase()
      const useTypeName = this.getUseTypeName(useTypeId).toLowerCase()

      return (
        cutVariationName.includes(criteria) || useTypeName.includes(criteria)
      )
    })
  }
}
