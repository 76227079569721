// ANGULAR
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  Renderer2,
  ElementRef,
} from '@angular/core'
import {
  FormGroup,
  FormBuilder,
  AbstractControl,
  Validators,
} from '@angular/forms'
// TOOLS
import { BsModalRef } from 'ngx-bootstrap/modal'
// MODELS
import { UnionModel } from 'src/app/@shared/@models/union-model'
import { DialogInstanceModel } from 'src/app/@shared/@models/dialog-instance-model'
import { ProfileFull } from 'src/app/@shared/@models/profile-full'

import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

// SERVICES
import { FrameService } from 'src/app/@services/frame.service'
import { ModalService } from 'src/app/@services/modal.service'
// DIALOGS
import { ProfileDialogComponent } from 'src/app/@dialogs/profile-dialog/profile-dialog.component'
import { ProfileService } from 'src/app/@services/profile.service'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { ProfileCreator } from 'src/app/@shared/@interfaces/profileCreator.model'
import { asyncForEach } from 'src/app/@helper/asyncForEach'
import { UnionSelect } from 'src/app/@components/unions-column/unions-column.component'
import { UnionPositions } from 'src/app/@shared/@types/unionPositions.type'
import { SideOptions } from 'src/app/@shared/@types/side.types'
import { CutVariationDialogComponent } from '../cut-variation-dialog/cut-variation-dialog.component'
import { CoverGaps } from 'src/app/@shared/@interfaces/coverGaps'
import { AssociatedProfiles } from 'src/app/@components/associated-profiles-selector/associated-profiles-selector.component'

@Component({
  selector: 'app-frame-profiles-dialog',
  templateUrl: './frame-profiles-dialog.component.html',
  styleUrls: ['./frame-profiles-dialog.component.scss'],
})
export class FrameProfilesDialogComponent implements OnInit {
  @ViewChild('customUnionSelect')
  private customUnionSelectDiv: ElementRef<HTMLDivElement>

  // Inputs
  public serieId: number
  public serieOpeningId: number
  public framesProfiles: {
    [k in SideOptions]?: { id: number }
  }
  public frameUnions: { [k in UnionPositions]: number }
  public overlaps: { [k in SideOptions]: number } = null
  public union: number
  public cutVariations: {
    [k in SideOptions]: { id: number; value: number }
  } = {
    bottom: { id: 1, value: 0 },
    top: { id: 1, value: 0 },
    left: { id: 1, value: 0 },
    right: { id: 1, value: 0 },
  }
  public associatedProfiles: AssociatedProfiles = {
    top: [],
    bottom: [],
    left: [],
    right: [],
  }

  public coverGaps: CoverGaps

  // form
  public frameForm: FormGroup
  public customUnionsForm: FormGroup
  public overlapForm: FormGroup

  public customUnionSide: UnionPositions = 'left-top'
  public customActualSelectUnionId = null
  public isCustomSelectOpen = false
  public isCustomUnions = false

  public images: {
    top: SafeUrl
    right: SafeUrl
    left: SafeUrl
    bottom: SafeUrl
  }

  // Profile unions
  public profileUnions: Array<UnionModel>
  // Profile dialog
  private dialogInstanceObject: DialogInstanceModel
  // Outputs
  @Output()
  $response: EventEmitter<any> = new EventEmitter<any>()

  public hasFrame = false

  // Icons
  public faTrashAlt = faTrashAlt

  constructor(
    private renderer: Renderer2,
    public modalRef: BsModalRef,
    private frameService: FrameService,
    private profileService: ProfileService,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.getUnions()
    this.buildForms()
    this.frameFormValueChange()
    this.getActualProfiles()
  }

  public deleteFrame(): void {
    this.$response.emit(null)
    this.modalRef.hide()
  }

  getUnions(): void {
    this.frameService.getUnions().subscribe(
      (response: Array<any>) => {
        this.profileUnions = response.filter((u) => u.id !== 7)
        this.profileUnions.map((union) => {
          union.image = '../../../assets/icons/unions/' + union.id + '.svg'
          return union
        })
      },
      (error) => {
        console.dir(error)
      }
    )
  }

  private buildForms(): void {
    this.frameForm = this.formBuilder.group({
      top: ['', Validators.required],
      left: ['', Validators.required],
      right: ['', Validators.required],
      bottom: ['', Validators.required],
      union: [1, Validators.required],
    })

    this.customUnionsForm = this.formBuilder.group({
      'left-top': [1],
      'right-top': [1],
      'left-bottom': [1],
      'right-bottom': [1],
    })

    this.overlapForm = this.formBuilder.group({
      top: [0],
      bottom: [0],
      left: [0],
      right: [0],
    })
  }

  private frameFormValueChange(): void {
    this.frameForm.get('union').valueChanges.subscribe((value) => {
      if (value !== 7 && value !== '7') {
        this.isCustomUnions = false
        return this.customUnionFormKeys.forEach((key) =>
          this.customUnionsForm.get(key).setValue(value)
        )
      }

      this.isCustomUnions = true
    })
  }

  get customUnionFormKeys(): string[] {
    return Object.keys(this.customUnionsForm.controls)
  }

  getFormControl(controlName: string): AbstractControl {
    return this.frameForm.get(controlName)
  }

  openProfileDialog(side: string): void {
    this.dialogInstanceObject = new DialogInstanceModel(
      ProfileDialogComponent,
      {
        initialState: {
          sideName: side,
          serieId: this.serieId,
        },
        class: 'modal-dialog-centered',
      }
    )
    // TODO: REFACTOR
    this.modalService
      .openModal(this.dialogInstanceObject)
      .subscribe((response: ProfileFull) => {
        if (side === 'Lado superior') {
          this.getFormControl('top').setValue(response.profile)
          this.images = { ...this.images, top: response.profilePng }
        }
        if (side === 'Lado derecho') {
          this.getFormControl('right').setValue(response.profile)
          this.images = { ...this.images, right: response.profilePng }
        }
        if (side === 'Lado izquierdo') {
          this.getFormControl('left').setValue(response.profile)
          this.images = { ...this.images, left: response.profilePng }
        }
        if (side === 'Lado inferior') {
          this.getFormControl('bottom').setValue(response.profile)
          this.images = { ...this.images, bottom: response.profilePng }
        }
        if (response.profile.all) {
          this.getFormControl('top').setValue(response.profile)
          this.images = { ...this.images, top: response.profilePng }
          this.getFormControl('right').setValue(response.profile)
          this.images = { ...this.images, right: response.profilePng }
          this.getFormControl('left').setValue(response.profile)
          this.images = { ...this.images, left: response.profilePng }
          this.getFormControl('bottom').setValue(response.profile)
          this.images = { ...this.images, bottom: response.profilePng }
        }

        if (response.profile) this.hasFrame = true
      })
  }

  public openCutVariationSelection(side: SideOptions): void {
    const cutVariationDialog = new DialogInstanceModel(
      CutVariationDialogComponent,
      {
        initialState: {
          cutVariation: this.cutVariations[side],
        },
        class: 'modal-dialog-centered',
      }
    )

    this.modalService.openModal(cutVariationDialog).subscribe((data) => {
      this.cutVariations[side] = data
    })
  }

  public openCustomUnionSelect(event: MouseEvent, side: UnionPositions): void {
    if (this.isCustomSelectOpen) return this.hideCustomUnionSelector()
    this.isCustomSelectOpen = true

    this.customUnionSide = side
    this.customActualSelectUnionId = this.customUnionsForm?.get(side).value
    this.showCustomUnionSelector(event, side)
  }

  public selectCustomUnion(unionSelect: UnionSelect): void {
    this.customUnionsForm.get(unionSelect.side)?.setValue(unionSelect.union)
    this.hideCustomUnionSelector()
  }

  public getUnionImage(unionId: number): string {
    return this.profileUnions?.find((u) => u.id === unionId).image ?? ''
  }

  public hasImage(side: UnionPositions): boolean {
    return this.customUnionsForm?.get(side).value ? true : false
  }

  private showCustomUnionSelector(
    event: MouseEvent,
    side: UnionPositions
  ): void {
    const position = {
      x: event.x + 24 + 'px',
      y: side.includes('bottom') ? event.y - 280 + 'px' : event.y + 16 + 'px',
    }

    this.renderer.setStyle(
      this.customUnionSelectDiv.nativeElement,
      'display',
      'flex'
    )
    this.renderer.setStyle(
      this.customUnionSelectDiv.nativeElement,
      'left',
      position.x
    )
    this.renderer.setStyle(
      this.customUnionSelectDiv.nativeElement,
      'top',
      position.y
    )
  }

  private hideCustomUnionSelector(): void {
    this.isCustomSelectOpen = false
    this.renderer.setStyle(
      this.customUnionSelectDiv.nativeElement,
      'display',
      'none'
    )
  }

  private async getActualProfiles(): Promise<void> {
    if (!this.framesProfiles) return
    const keys = Object.keys(this.framesProfiles)

    let frames = {}

    await asyncForEach<string>(keys, async (k) => {
      const profile = await this.getProfile(this.framesProfiles[k].id)
      const profileUrl = await this.getProfileImg(this.framesProfiles[k].id)
      frames = {
        ...frames,
        [k]: {
          profile,
          profileUrl,
        },
      }
    })

    this.setData(frames)
    this.setOverlaps()
    this.hasFrame = true

    if (Number(this.union) === 7) this.setCustomUnions()
  }

  private setCustomUnions(): void {
    Object.keys(this.frameUnions).forEach((k) => {
      this.customUnionsForm?.get(k).setValue(this.frameUnions[k])
    })
  }

  private setOverlaps(): void {
    if (!this.overlaps) return
    Object.keys(this.overlaps).forEach((k) => {
      this.overlapForm?.get(k).setValue(this.overlaps[k])
    })
  }

  private async getProfileImg(profileId: number): Promise<SafeUrl | null> {
    if (!profileId) return

    return await this.profileService
      .getProfilePng(profileId)
      .toPromise()
      .then((blob) => {
        let objectURL = URL.createObjectURL(blob)
        return this.sanitizer.bypassSecurityTrustUrl(objectURL)
      })
      .catch((err) => {
        return null
      })
  }

  private async getProfile(id: number): Promise<ProfileCreator> {
    if (!id) return

    return await this.profileService
      .getProfileByID(id)
      .toPromise()
      .catch((err) => {})
  }

  private setData(profiles: any): void {
    const keys = Object.keys(profiles)

    keys.forEach((k) => {
      this.frameForm.get(k).setValue(profiles[k].profile)
      this.images = { ...this.images, [k]: profiles[k].profileUrl }
    })
    this.frameForm.get('union').setValue(this.union)
  }

  private buildSubmit(): {
    unions: { [k in UnionPositions]: number }
    left: ProfileCreator
    right: ProfileCreator
    top: ProfileCreator
    bottom: ProfileCreator
    union: number
    coverGaps: CoverGaps
  } {
    return {
      associatedProfiles: this.associatedProfiles,
      unions: { ...this.customUnionsForm.value },
      overlaps: { ...this.overlapForm.value },
      cutVariations: this.cutVariations,
      coverGaps: this.coverGaps,
      ...this.frameForm.value,
    }
  }

  public setCoverGaps(coverGaps: CoverGaps): void {
    this.coverGaps = coverGaps
  }

  save(): void {
    this.$response.emit(this.buildSubmit())
    this.modalRef.hide()
  }

  // AssociatedProfiles
  public setAssociatedProfiles(associatedProfiles: AssociatedProfiles): void {
    this.associatedProfiles = associatedProfiles
  }
}
