import { Component, EventEmitter, OnInit } from '@angular/core'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { ToastrService } from 'ngx-toastr'
import { AddAccessoriesGroupDialogComponent } from 'src/app/@dialogs/add-accessories-group-dialog/add-accessories-group-dialog.component'
import { NewAccesoriesCombinationDialogComponent } from 'src/app/@dialogs/new-accesories-combination/new-accesories-combination.component'
import { NewAccesoriesFormulaComponent } from 'src/app/@dialogs/new-accesories-formula/new-accesories-formula.component'
import { asyncForEach } from 'src/app/@helper/asyncForEach'
import {
  AccessoriesModelService,
  CreateAccessoryModelRequest,
} from 'src/app/@services/accessories-model.service'
import { ModalService } from 'src/app/@services/modal.service'
import {
  Accesory,
  AccesoryWithFormula,
} from 'src/app/@shared/@interfaces/accesory'
import { IDialog } from 'src/app/@shared/@interfaces/dialog'
import { DialogInstanceModel } from 'src/app/@shared/@models/dialog-instance-model'
import { AreaModel } from 'src/app/@shared/@models/modelsElements/area-model'
import { Gap } from 'src/app/@shared/@models/modelsElements/gap-model'

@Component({
  selector: 'app-add-accessory-area',
  templateUrl: './add-accessory-area.component.html',
  styleUrls: ['./add-accessory-area.component.scss'],
})
export class AddAccessoryAreaComponent implements OnInit, IDialog {
  public area: AreaModel
  public gap: Gap
  public isAccessoryGroup = false

  $response: EventEmitter<any> = new EventEmitter()

  constructor(
    private modalRef: BsModalRef,
    private modalService: ModalService,
    private accessoryService: AccessoriesModelService,
    private toastr: ToastrService
  ) {}

  confirm(): void {}

  decline(): void {}

  ngOnInit(): void {
    if (this.isAccessoryGroup) {
      this.onAddAccesoryGroup()
      return
    }

    this.onAddAccessory()
  }

  public async onAddAccesoryGroup(): Promise<void> {
    const dialog = new DialogInstanceModel(
      AddAccessoriesGroupDialogComponent,
      {}
    )

    this.modalService.openModal(dialog).subscribe({
      next: async (res: {
        accessories: Array<{
          codRef: string
          family: string
          formula: string
          group: number
        }>
      }) => {
        const { accessories } = res

        await asyncForEach(accessories, async (acc: any) => {
          await this.saveAccessory({
            codRef: acc.codRef,
            family: acc.family,
            formula: acc.formula,
            group: acc.group,
          })
        }).then(() => {
          this.toastr.success('Grupo de accesorios añadido')
          this.modalRef.hide()
        })
      },
    })
  }

  public async onAddAccessory(): Promise<void> {
    const acc = await this.openAccessoryDialog()
    const { accesory, formula } = await this.openFormulaDialog(acc)

    this.saveAccessory({
      codRef: accesory.codRef,
      family: accesory.family,
      formula: formula.function,
    }).then(() => {
      this.toastr.success('Accesorio añadido')
      this.modalRef.hide()
    })
  }

  private openAccessoryDialog(): Promise<Accesory> {
    const newDialog = new DialogInstanceModel(
      NewAccesoriesCombinationDialogComponent,
      {
        initialState: {
          accesory: 'Test',
        },
        // class: 'modal-dialog-centered',
      }
    )

    return new Promise((resolve, reject) => {
      this.modalService.openModal(newDialog).subscribe({
        next: (acc: Accesory) => {
          if (!acc) return this.modalRef.hide()
          resolve(acc)
        },
        error: (err) => {},
        complete: () => {},
      })
    })
  }

  private openFormulaDialog(accesory: Accesory): Promise<AccesoryWithFormula> {
    const formulaDialog = new DialogInstanceModel(
      NewAccesoriesFormulaComponent,
      {
        initialState: {
          accesory: accesory,
          type: 'Area',
        },
      }
    )

    return new Promise((resolve, reject) => {
      this.modalService
        .openModal(formulaDialog)
        .subscribe((acc: AccesoryWithFormula) => {
          if (!acc) return this.modalRef.hide()
          resolve(acc)
        })
    })
  }

  private async saveAccessory(acc: {
    codRef: string
    family: string
    formula: string
    group?: number
  }): Promise<void> {
    const area = this.area.container

    const request: CreateAccessoryModelRequest = {
      entities: [
        {
          type: 'Area',
          entity: area.uuid,
        },
      ],
      accessory: {
        codeRef: acc.codRef,
        family: acc.family,
      },
      formula: acc.formula,
      model: this.gap.modelId,
      type: 'Area',
      typeId: area.uuid,
      ...(acc.group ? { accessorySerieGroupId: acc.group } : {}),
    }

    return new Promise((resolve, reject) => {
      this.accessoryService.setAccessoryModelToEntity(request).subscribe({
        next: (res) => {
          resolve()
        },
        error: (err) => {
          reject(err)
        },
      })
    })
  }
}
