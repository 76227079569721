import { ModelElement } from '../../@interfaces/modelElement'
import { Side, SideOptions } from '../../@types/side.types'
import { Area, AreaModel } from './area-model'
import { ProfileElement, ProfileElementCreator } from './Profile-model'

export class CrossbarElement extends ProfileElement implements ModelElement {
  private _leftArea: AreaModel[] = []
  private _rightArea: AreaModel[] = []
  private _topArea: AreaModel[] = []
  private _bottomArea: AreaModel[] = []

  private _areasRelation: AreaModel[] = []

  public container: Area

  constructor(crossbar: CrossBarCreatorObject) {
    super(crossbar)

    this._leftArea = crossbar?.leftArea ?? []
    this._rightArea = crossbar?.rightArea ?? []
    this._topArea = crossbar?.topArea ?? []
    this._bottomArea = crossbar?.bottomArea ?? []

    this._areasRelation = [
      ...this._leftArea,
      ...this._rightArea,
      ...this._topArea,
      ...this._bottomArea,
    ]

    this.group.name('crossbar')
  }

  get x(): number {
    return (this._x - this.gap.xScaled) / this.scaleFactor
  }
  get y(): number {
    return (this._y - this.gap.yScaled) / this.scaleFactor
  }

  get leftArea(): AreaModel[] {
    return this._leftArea
  }
  get rightArea(): AreaModel[] {
    return this._rightArea
  }
  get topArea(): AreaModel[] {
    return this._topArea
  }
  get bottomArea(): AreaModel[] {
    return this._bottomArea
  }

  public addArea(area: AreaModel, side: SideOptions): void {
    const relations: Side<AreaModel[]> = {
      top: this._topArea,
      bottom: this._bottomArea,
      left: this._leftArea,
      right: this._rightArea,
    }

    relations[side].push(area)
  }

  public replaceArea(
    previusAreaUuid: string,
    newArea: AreaModel,
    side: SideOptions
  ): void {
    let auxArray = []
    let areaArray = this.getAreasBySide(side)

    auxArray = areaArray.filter((a) => a.uuid !== previusAreaUuid)
    auxArray.push(newArea)

    this.replaceArray(auxArray, side)
  }

  public replaceArray(array: AreaModel[], side: SideOptions): void {
    if (side === 'top') this._topArea = array
    if (side === 'bottom') this._bottomArea = array
    if (side === 'left') this._leftArea = array
    if (side === 'right') this._rightArea = array
  }

  private getAreasBySide(side: SideOptions): AreaModel[] {
    if (side === 'top') return this._topArea
    if (side === 'bottom') return this._bottomArea
    if (side === 'left') return this._leftArea
    if (side === 'right') return this._rightArea
  }

  public removeArea(uuid: string, side: SideOptions): CrossbarElement {
    const area = this.getAreasBySide(side)
    area.forEach((a) => {
      if (a.uuid !== uuid) return

      a.removeCrossbar(this.uuid, this.setAgainstSide(side))
    })

    return this
  }

  private setAgainstSide(side: SideOptions): SideOptions {
    switch (side) {
      case 'top':
        return 'bottom'
      case 'bottom':
        return 'top'
      case 'left':
        return 'right'
      case 'right':
        return 'left'

      default:
        break
    }
  }

  public cleanAreas(): CrossbarElement {
    this._topArea.forEach((a) => this.removeArea(a.uuid, 'top'))
    this._bottomArea.forEach((a) => this.removeArea(a.uuid, 'bottom'))
    this._leftArea.forEach((a) => this.removeArea(a.uuid, 'left'))
    this._rightArea.forEach((a) => this.removeArea(a.uuid, 'right'))
    this._areasRelation = []

    this._topArea = []
    this._bottomArea = []
    this._leftArea = []
    this._rightArea = []

    return this
  }
}

export interface CrossBarCreatorObject extends ProfileElementCreator {
  leftArea?: AreaModel[]
  rightArea?: AreaModel[]
  topArea?: AreaModel[]
  bottomArea?: AreaModel[]
}
