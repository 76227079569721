<div class="container fade-in">
  <div class="header">
    <h1 class="header__title">Gestión de perfiles</h1>
    <fa-icon
      [icon]="faTimes"
      class="header__icon"
      (click)="closeProfileEditor()"
    ></fa-icon>
  </div>

  <form class="form-inline" [formGroup]="profileDataForm">
    <div class="input-item">
      <div
        class="input-group form-inline-item"
        [ngClass]="{ disabled: !isNewSerie }"
      >
        <label for="serie" class="form-inline-item__label">Serie</label>
        <div class="form-inline-item__separator"></div>
        <input
          type="text"
          autocomplete="off"
          class="form-inline-item__input"
          id="serie"
          placeholder="Serie"
          formControlName="serie"
        />
      </div>
      <app-input-alert
        *ngFor="
          let errorMessage of isInvalidControl(
            'serie',
            'Debe indicar una serie'
          )
        "
        [title]="errorMessage"
      ></app-input-alert>
    </div>

    <div class="input-item">
      <div
        class="input-group form-inline-item name"
        [ngClass]="{ disabled: !isNewProfile }"
      >
        <label for="name" class="form-inline-item__label">Código perfil</label>
        <div class="form-inline-item__separator"></div>
        <input
          type="text"
          autocomplete="off"
          class="form-inline-item__input"
          id="name"
          placeholder="Código"
          formControlName="name"
        />
      </div>
      <app-input-alert
        *ngFor="
          let errorMessage of isInvalidControl('name', 'Debe indicar el nombre')
        "
        [title]="errorMessage"
      ></app-input-alert>
    </div>

    <div class="input-item">
      <div class="input-group form-inline-item description">
        <label for="description" class="form-inline-item__label"
          >Denominación</label
        >
        <div class="form-inline-item__separator"></div>
        <input
          type="text"
          autocomplete="off"
          class="form-inline-item__input description"
          id="description"
          placeholder="Descripción"
          formControlName="description"
        />
      </div>
      <app-input-alert
        *ngFor="
          let errorMessage of isInvalidControl(
            'description',
            'Debe indicar la descripción'
          )
        "
        [title]="errorMessage"
      ></app-input-alert>
    </div>
  </form>

  <div class="content">
    <tabset id="profileCreationTabset" class="custom-tabset">
      <!--Technical tab-->
      <tab customClass="tabHeader ">
        <ng-template tabHeading>
          <div class="tab-title" (click)="getFormsProperties()">
            <span class="tab-title__text">Datos técnicos</span>
            <div class="tab__separator"></div>
          </div>
        </ng-template>

        <app-technical-data
          #technicaldata
          [$isNewProfile]="isNewProfile"
          [$profile]="profileForm"
          [$serieID]="serie?.id"
          [$profileObject]="profileObjectFromDB"
          [$profileParsedDxf]="profileParsedDxf"
          (profileCreatorEmitter)="profileCreatorSubmit()"
          (isDisassembledEmitter)="setIsDisassembled($event)"
        ></app-technical-data>
      </tab>

      <!--Uses tab-->
      <tab customClass="tabHeader">
        <ng-template tabHeading>
          <div class="tab-title" (click)="getFormsProperties()">
            <span class="tab-title__text">Uso</span>
            <div class="tab__separator"></div>
          </div>
        </ng-template>
        <app-profile-uses #profileuses [profile]="profile"></app-profile-uses>
      </tab>

      <!--Accesories tab-->
      <tab customClass="tabHeader">
        <ng-template tabHeading>
          <div class="tab-title" (click)="getFormsProperties()">
            <span class="tab-title__text">Combinación de accesorios</span>
            <div class="tab__separator"></div>
          </div>
        </ng-template>
        <app-profile-accesories
          #profileAccesories
          [profile]="profile"
          [profileObject]="profileObjectFromDB"
        ></app-profile-accesories>
      </tab>

      <!-- Cutting and optimization -->
      <tab customClass="tabHeader">
        <ng-template tabHeading>
          <div class="tab-title" (click)="getFormsProperties()">
            <span class="tab-title__text">Corte y optimización</span>
            <div class="tab__separator"></div>
          </div>
        </ng-template>
        <app-profile-cutting-optimization
          #profileCutting
          [profile]="profile"
          (submitEmitter)="profileCreatorSubmit()"
        ></app-profile-cutting-optimization>
      </tab>

      <!-- Disassembly tab -->
      <tab customClass="tabHeader " *ngIf="isDisassembled">
        <ng-template tabHeading>
          <div class="tab-title" (click)="showDisassemblyTab()">
            <span class="tab-title__text">Perfiles desensamblados</span>
            <div class="tab__separator last"></div>
          </div>
        </ng-template>

        <app-dxf-disassembly-confection *ngIf="showDisassemblyTabFlag" [profile]="profile" [preloadProfile]="preloadDisassemblyProfile"></app-dxf-disassembly-confection>
      </tab>
    </tabset>
  </div>
</div>
