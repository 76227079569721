import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ProfileDialogComponent } from 'src/app/@dialogs/profile-dialog/profile-dialog.component'
import { ModalService } from 'src/app/@services/modal.service'
import { ProfileCreator } from 'src/app/@shared/@interfaces/profileCreator.model'
import { DialogInstanceModel } from 'src/app/@shared/@models/dialog-instance-model'
import { SideOptions } from 'src/app/@shared/@types/side.types'

@Component({
  selector: 'app-profile-select',
  templateUrl: './profile-select.component.html',
  styleUrls: ['./profile-select.component.scss'],
})
export class ProfileSelectComponent implements OnInit {
  @Input()
  public selectedProfile: ProfileCreator = null

  @Input() public serieId = 1
  @Input() public width = 100
  @Input() public onlyOneProfile = false
  @Input() public typeProfile = 'marco'

  @Output() private selectedProfileEmitter = new EventEmitter<ProfileCreator>()

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {}

  public openProfileSelectorModal(): void {
    const profileSelectorDialog = new DialogInstanceModel(
      ProfileDialogComponent,
      {
        initialState: {
          onlyOne: this.onlyOneProfile,
          serieId: this.serieId,
          usePreferred: this.typeProfile,
        },
        class: 'modal-dialog-centered ',
      }
    )

    this.modalService.openModal(profileSelectorDialog).subscribe((data) => {
      const profile: ProfileCreator = data.profile
      this.selectedProfile = profile
      this.selectedProfileEmitter.emit(profile)
    })
  }
}
