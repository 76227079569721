import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'

import {
  faTrashAlt,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons'
import { ToastrService } from 'ngx-toastr'
import { DeleteItemDialogComponent } from 'src/app/@dialogs/delete-item-dialog/delete-item-dialog.component'

import { AccesoriesGruopsService } from 'src/app/@services/accesories-gruops.service'
import { ModalService } from 'src/app/@services/modal.service'
import { SerieCreator } from 'src/app/@shared/@interfaces/serieCreator'
import { DialogInstanceModel } from 'src/app/@shared/@models/dialog-instance-model'
import { IdAndNameModel } from 'src/app/@shared/@models/idAndName.model'

@Component({
  selector: 'app-accessorie-group-list',
  templateUrl: './accessorie-group-list.component.html',
  styleUrls: ['./accessorie-group-list.component.scss'],
})
export class AccessorieGroupListComponent implements OnInit, OnChanges {
  @Input() public selectedSerie: SerieCreator

  public groups: IdAndNameModel[] = []
  public filteredGroups: IdAndNameModel[] = []
  public selectedGroupId: number | null

  // Icons
  public faTrashAlt = faTrashAlt
  public faSortUp = faSortUp
  public faSortDown = faSortDown

  constructor(
    private accesoriesGroupsService: AccesoriesGruopsService,
    private modalService: ModalService,
    private toastr: ToastrService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    const { previousValue, currentValue } = changes.selectedSerie
    const hasSerieChange = previousValue?.id !== currentValue?.id

    if (hasSerieChange) this.getAllGroups()
  }

  ngOnInit(): void { }

  public toggleSelectGroup(id: number): void {
    if (this.selectedGroupId === id) return (this.selectedGroupId = null)
    this.selectedGroupId = id
  }

  private getAllGroups(): void {
    this.accesoriesGroupsService
      .getAccesorieGroupsBySerie(this.selectedSerie?.id)
      .subscribe((groups) => {
        this.groups = groups
        this.search()
      })
  }

  public addGroup(name: string): void {
    const { id } = this.selectedSerie

    this.accesoriesGroupsService
      .createGroup({ serie: { id }, name })
      .subscribe((groupId) => {
        this.groups.push({
          id: groupId,
          name,
        })
        this.search()
      })
  }

  public deleteGroup(group: IdAndNameModel) {
    const deleteDialog = new DialogInstanceModel(DeleteItemDialogComponent, {
      initialState: {
        itemName: 'el grupo',
      },
      class: 'modal-dialog-centered',
    })

    this.modalService.openModal(deleteDialog).subscribe((response) => {
      if (response) this.deleteRemote(group.id)
    })
  }

  private deleteRemote(groupId: number): void {
    this.accesoriesGroupsService.deleteGroup(groupId).subscribe(
      (response) => {
        this.toastr.success('Grupo eliminado')
        this.groups = this.groups.filter((g) => g.id !== groupId)
        this.search()
      },
      (err) => {
        this.toastr.error('El grupo no ha podido ser eliminado')
      }
    )
  }

  public search(criteria?: string): void {
    this.filteredGroups = this.groups
    if (!criteria) return

    criteria = criteria.toLowerCase()

    this.filteredGroups = this.groups.filter((g) => {
      const name = g.name.toLowerCase()
      return name.includes(criteria)
    })
  }
}
