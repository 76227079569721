import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core'
import { computePosition, flip, offset, shift } from '@floating-ui/dom'

import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { Subscription } from 'rxjs'
import { AccessoriesModelService } from 'src/app/@services/accessories-model.service'
import { KonvaService } from 'src/app/@services/konva.service'
import { ModalService } from 'src/app/@services/modal.service'
import { DialogInstanceModel } from 'src/app/@shared/@models/dialog-instance-model'
import { AreaModel } from 'src/app/@shared/@models/modelsElements/area-model'
import { CrossbarElement } from 'src/app/@shared/@models/modelsElements/crossbar-model'
import { DoorWindowTipupOpeningElement } from 'src/app/@shared/@models/modelsElements/door-window-tipupOpening-model'
import { Frame } from 'src/app/@shared/@models/modelsElements/frame-model'
import { Gap } from 'src/app/@shared/@models/modelsElements/gap-model'
import { JonquilloElement } from 'src/app/@shared/@models/modelsElements/jonquillo-model'
import { AddAccessoryAreaComponent } from '../addAccesory/add-accessory-area/add-accessory-area.component'
import { AddAccessoryCrossbarComponent } from '../addAccesory/add-accessory-crossbar/add-accessory-crossbar.component'
import { AddAccessoryFrameComponent } from '../addAccesory/add-accessory-frame/add-accessory-frame.component'
import { AddAccessoryJunquilloComponent } from '../addAccesory/add-accessory-junquillo/add-accessory-junquillo.component'
import { AddAccessoryTipupopeningComponent } from '../addAccesory/add-accessory-tipupopening/add-accessory-tipupopening.component'

const selectableElements = {
  Frame: {
    type: Frame,
    string: 'Frame',
  },
  Crossbar: {
    type: CrossbarElement,
    string: 'Crossbar',
  },
  DoorWindowTipupOpening: {
    type: DoorWindowTipupOpeningElement,
    string: 'DoorWindowTipupOpening',
  },
  Jonquillo: {
    type: JonquilloElement,
    string: 'Jonquillo',
  },
  AreaModel: {
    type: AreaModel,
    string: 'AreaModel',
  },
}

@Component({
  selector: 'app-model-option-button',
  templateUrl: './model-option-button.component.html',
  styleUrls: ['./model-option-button.component.scss'],
})
export class ModelOptionButtonComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('popover', { static: true }) popover!: ElementRef<HTMLDivElement>
  @ViewChild('button', { static: true }) button!: ElementRef<HTMLButtonElement>

  // Icons
  public faEllipsisV = faEllipsisV

  public isOpenPopover = false

  private gap!: Gap
  private gapSubscription!: Subscription

  private selectedType!: string

  constructor(
    private renderer: Renderer2,
    private konvaService: KonvaService,
    private modalService: ModalService,
    private accessoryModalService: AccessoriesModelService
  ) {}

  ngOnInit(): void {
    this.gapSubscribe()
  }

  ngAfterViewInit(): void {
    this.setPopoverPosition()
  }

  ngOnDestroy(): void {
    this.gapSubscription?.unsubscribe()
  }

  public togglePopover(): void {
    this.isOpenPopover = !this.isOpenPopover
    this.setPopoverPosition()

    this.renderer.setStyle(
      this.popover.nativeElement,
      'display',
      this.isOpenPopover ? 'block' : 'none'
    )
  }

  public onAddAccessory(isGroup = false): void {
    if (!this.selectedType) return

    const mapper = {
      [selectableElements.Frame.string]: () => {
        const selected = this.gap.getSelectedElement<Frame>(Frame)

        const dialog = new DialogInstanceModel(AddAccessoryFrameComponent, {
          initialState: {
            frame: selected,
            gap: this.gap,
            isAccessoryGroup: isGroup,
          },
        })

        this.modalService.openModal(dialog).subscribe({
          next: (value) => {
            this.accessoryModalService
              .setAccessoryModelToEntity(value)
              .subscribe({
                next: () => {},
              })
          },
        })
      },
      [selectableElements.Crossbar.string]: () => {
        const selected = this.gap.getSelectedElement<CrossbarElement>(
          CrossbarElement
        )

        const dialog = new DialogInstanceModel(AddAccessoryCrossbarComponent, {
          initialState: {
            crossbar: selected,
            gap: this.gap,
            isAccessoryGroup: isGroup,
          },
        })

        this.modalService.openModal(dialog).subscribe({
          next: (value) => {},
        })
      },
      [selectableElements.DoorWindowTipupOpening.string]: () => {
        const selected = this.gap.getSelectedElement<DoorWindowTipupOpeningElement>(
          DoorWindowTipupOpeningElement
        )

        const dialog = new DialogInstanceModel(
          AddAccessoryTipupopeningComponent,
          {
            initialState: {
              opening: selected,
              gap: this.gap,
              isAccessoryGroup: isGroup,
            },
          }
        )

        this.modalService.openModal(dialog).subscribe({
          next: (value) => {},
        })
      },
      [selectableElements.Jonquillo.string]: () => {
        const selected = this.gap.getSelectedElement<JonquilloElement>(
          JonquilloElement
        )

        const dialog = new DialogInstanceModel(AddAccessoryJunquilloComponent, {
          initialState: {
            junquillo: selected,
            gap: this.gap,
            isAccessoryGroup: isGroup,
          },
        })

        this.modalService.openModal(dialog).subscribe({
          next: (value) => {},
        })
      },
      [selectableElements.AreaModel.string]: () => {
        const selected = this.gap.selectedArea

        const dialog = new DialogInstanceModel(AddAccessoryAreaComponent, {
          initialState: {
            area: selected,
            gap: this.gap,
            isAccessoryGroup: isGroup,
          },
        })

        this.modalService.openModal(dialog).subscribe({
          next: (value) => {},
        })
      },
    }

    const selectedFunction = mapper[this.selectedType]

    selectedFunction()
  }

  private setPopoverPosition(): void {
    computePosition(this.button.nativeElement, this.popover.nativeElement, {
      placement: 'bottom-end',
      middleware: [offset(5), flip(), shift({ padding: 10 })],
    }).then((value) => {
      const { x, y } = value

      this.renderer.setStyle(this.popover.nativeElement, 'left', `${x}px`)
      this.renderer.setStyle(this.popover.nativeElement, 'top', `${y}px`)
    })
  }

  private gapSubscribe(): void {
    this.gapSubscription = this.konvaService.gapUpdated$.subscribe((gap) => {
      this.gap = gap

      const selected = this.gap.selectedElement
      const areaSelected = this.gap.selectedArea

      if (areaSelected) {
        this.selectedType = this.extractElementType(areaSelected)
        return
      }

      if (!selected) return

      const elementType = this.extractElementType(selected)
      this.selectedType = elementType
    })
  }

  private extractElementType(element: any): string {
    const elementType = Object.keys(selectableElements).find(
      (key) => selectableElements[key].type === element.constructor
    )

    return elementType
  }
}
