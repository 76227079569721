<div class="container fade-in">
  <div class="header">
    <h1 class="header__title">
      Datos de la serie {{ form.get('name').value }}
    </h1>
    <fa-icon
      [icon]="faTimes"
      class="header__icon"
      (click)="hideSerieEditor()"
    ></fa-icon>
  </div>
  <form [formGroup]="form" autocomplete="off">
    <div class="form-serie-data">
      <div class="input-group mb-4">
        <app-input label="Nombre" id="description">
          <input
            type="text"
            class="form-item__input description"
            id="name"
            name="name"
            placeholder="Nombre"
            formControlName="name"
          />
        </app-input>
        <app-input-alert
          *ngFor="
            let errorMessage of inputErrorMessages(
              'name',
              'Debe indicar un nombre'
            )
          "
          [title]="errorMessage"
        ></app-input-alert>
      </div>

      <div class="input-group mb-4">
        <app-input label="Descripción" id="description">
          <input
            type="text"
            class="form-item__input description"
            id="description"
            name="description"
            placeholder="Descripción"
            formControlName="description"
          />
        </app-input>
        <app-input-alert
          *ngFor="
            let errorMessage of inputErrorMessages(
              'description',
              'Debe indicar una descripción'
            )
          "
          [title]="errorMessage"
        ></app-input-alert>
      </div>
    </div>

    <div>
      <tabset class="custom-tabset">
        <tab>
          <ng-template tabHeading>
            <div class="tab-title">
              <span class="tab-title__text">Datos técnicos</span>
              <div class="tab__separator"></div>
            </div>
          </ng-template>

          <div *ngTemplateOutlet="serieTechnicalData"></div>
        </tab>

        <tab>
          <ng-template tabHeading>
            <div class="tab-title">
              <span class="tab-title__text">Colores</span>
              <div class="tab__separator last"></div>
            </div>
          </ng-template>

          <div *ngTemplateOutlet="serieColorsTable"></div>
        </tab>
      </tabset>
    </div>

    <ng-template #serieTechnicalData>
      <div class="row mb-3 mt-5 fade-in">
        <div class="col-5">
          <div class="input-group mb-3">
            <app-input label="Longitud de barra" id="long">
              <input
                type="number"
                step="any"
                min="0"
                class="form-item__input"
                formControlName="barLength"
                id="long"
              />
            </app-input>
            <app-input-alert
              *ngFor="
                let errorMessage of inputErrorMessages(
                  'barLength',
                  'Debe indicar una longitud (mm)'
                )
              "
              [title]="errorMessage"
            ></app-input-alert>
          </div>
          <div class="input-group mb-3">
            <app-input label="Offset abertura">
              <input
                type="number"
                step="any"
                min="0"
                class="form-item__input"
                formControlName="openingOffset"
              />
            </app-input>
            <app-input-alert
              *ngFor="
                let errorMessage of inputErrorMessages(
                  'openingOffset',
                  'Debe indicar una distancia (mm)'
                )
              "
              [title]="errorMessage"
            ></app-input-alert>
          </div>
          <div class="input-group mb-3">
            <app-input label="Offset abertura - puertas">
              <input
                type="number"
                step="any"
                min="0"
                class="form-item__input"
                formControlName="openingOffsetDoor"
              />
            </app-input>
            <app-input-alert
              *ngFor="
                let errorMessage of inputErrorMessages(
                  'openingOffsetDoor',
                  'Debe indicar una distancia (mm)'
                )
              "
              [title]="errorMessage"
            ></app-input-alert>
          </div>
          <div class="input-group mb-3">
            <app-input label="Distancia hoja - piso">
              <input
                type="number"
                step="any"
                min="0"
                class="form-item__input"
                formControlName="floorPaneDistance"
              />
            </app-input>
            <app-input-alert
              *ngFor="
                let errorMessage of inputErrorMessages(
                  'floorPaneDistance',
                  'Debe indicar una distancia (mm)'
                )
              "
              [title]="errorMessage"
            ></app-input-alert>
          </div>
          <div class="input-group mb-3">
            <app-input label="Superposición de apertura">
              <input
                type="number"
                step="any"
                min="0"
                class="form-item__input"
                formControlName="openingOverlap"
              />
            </app-input>
            <app-input-alert
              *ngFor="
                let errorMessage of inputErrorMessages(
                  'openingOverlap',
                  'Debe indicar una superposición (mm)'
                )
              "
              [title]="errorMessage"
            ></app-input-alert>
          </div>
          <div class="input-group mb-3">
            <app-input label="Tipo retestado">
              <app-dropdown-input
                [items]="millTypes"
                [title]="millTypeSelected?.name"
                (selectItemEmitter)="onSelectMillType($event)"
              ></app-dropdown-input>
            </app-input>
          </div>
          <div class="input-group mb-3">
            <app-input label="Variación retestado">
              <input
                type="number"
                step="any"
                min="0"
                class="form-item__input"
                formControlName="millVariation"
              />
            </app-input>
            <app-input-alert
              *ngFor="
                let errorMessage of inputErrorMessages(
                  'millVariation',
                  'Debe indicar una variación (mm)'
                )
              "
              [title]="errorMessage"
            ></app-input-alert>
          </div>
          <div class="input-group mb-3">
            <app-input label="Tipo de apertura">
              <app-dropdown-input
                [items]="openings"
                [title]="openingsSelected?.name"
                (selectItemEmitter)="onSelectOpening($event)"
              ></app-dropdown-input>
            </app-input>
          </div>
        </div>

        <!--Superposition-->
        <div class="col-7">
          <div class="row mb-3">
            <div class="col-12">
              <app-form-group title="Superposición vidrio">
                <div class="row justify-content-around">
                  <div class="col-6">
                    <div class="serie-radio">
                      <button
                        type="button"
                        class="btn superposition-btn"
                        [ngClass]="{ active: showSerieRadios }"
                        (click)="selectSerieRadios()"
                      >
                        Serie
                      </button>
                      <div *ngIf="showSerieRadios" class="serie-radio-items">
                        <div
                          class="serie-radio-item"
                          *ngFor="let serieOverlap of serieOverlaps"
                        >
                          <input
                            type="radio"
                            name="serieOverlap"
                            class="serie-radio-item__input"
                            formControlName="serieOverlap"
                            [id]="serieOverlap.id + serieOverlap.name"
                            [value]="serieOverlap.id"
                          />
                          <label
                            [for]="serieOverlap.id + serieOverlap.name"
                            class="serie-radio-item__label"
                            >{{ serieOverlap.name }}</label
                          >
                        </div>

                        <app-input-alert
                          *ngFor="
                            let errorMessage of inputErrorMessages(
                              'serieOverlap',
                              'Debe seleccionar una superposición'
                            )
                          "
                          [title]="errorMessage"
                        ></app-input-alert>
                        <!--SerieOverlap value-->
                        <div class="value">
                          <label for="value" class="value__label">Valor</label>
                          <input
                            type="number"
                            step="any"
                            name="value"
                            id="value"
                            formControlName="overlapAmount"
                            class="value__input"
                          />
                          <app-input-alert
                            *ngIf="isInvalidControl('overlapAmount')"
                          ></app-input-alert>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <!--Crystal Overlap-->
                    <div class="serie-radio">
                      <button
                        type="button"
                        class="btn superposition-btn"
                        [ngClass]="{ active: showCristalRadios }"
                        (click)="selectCristalRadios()"
                      >
                        Cristal
                      </button>
                      <div *ngIf="showCristalRadios" class="serie-radio-items">
                        <div
                          class="serie-radio-item"
                          *ngFor="let crystalOverlap of crystalOverlaps"
                        >
                          <input
                            type="radio"
                            name="crystalOverlap"
                            class="serie-radio-item__input"
                            formControlName="crystalOverlap"
                            [id]="crystalOverlap.id + crystalOverlap.name"
                            [value]="crystalOverlap.id"
                          />
                          <label
                            [for]="crystalOverlap.id + crystalOverlap.name"
                            class="serie-radio-item__label"
                            >{{ crystalOverlap.name }}</label
                          >
                        </div>
                        <app-input-alert
                          *ngFor="
                            let errorMessage of inputErrorMessages(
                              'crystalOverlap',
                              'Debe seleccionar una superposición'
                            )
                          "
                          [title]="errorMessage"
                        ></app-input-alert>
                      </div>
                    </div>
                  </div>
                </div>
              </app-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-8">
              <!-- Height references -->
              <app-form-group
                title="Referencia de altura de manija"
                class="prueba"
              >
                <app-input label="Altura área <=1800">
                  <app-dropdown-input
                    [items]="heightReferences"
                    [title]="heightMinus1800Selected?.name"
                    (selectItemEmitter)="onSelectHeightMinus1800($event)"
                  ></app-dropdown-input>
                </app-input>
                <app-input
                  label="Altura pred. accionamiento apertura"
                  *ngIf="!isHeightReferenceeCenter(heightMinus1800Selected)"
                >
                  <input
                    type="number"
                    step="any"
                    class="form-item__input"
                    formControlName="heightLow"
                  />
                </app-input>
                <app-input-alert
                  *ngFor="
                    let errorMessage of inputErrorMessages(
                      'heightLow',
                      'Debe indicar una altura (mm)'
                    )
                  "
                  [title]="errorMessage"
                ></app-input-alert>

                <app-input label="Altura área >=1800">
                  <app-dropdown-input
                    [items]="heightReferences"
                    [title]="heightMore1800Selected?.name"
                    (selectItemEmitter)="onSelectHeightMore1800($event)"
                  ></app-dropdown-input>
                </app-input>
                <app-input
                  label="Altura pred. accionamiento apertura"
                  *ngIf="!isHeightReferenceeCenter(heightMore1800Selected)"
                >
                  <input
                    type="number"
                    step="any"
                    class="form-item__input"
                    formControlName="heightHigh"
                  />
                </app-input>
                <app-input-alert
                  *ngFor="
                    let errorMessage of inputErrorMessages(
                      'heightHigh',
                      'Debe indicar una altura (mm)'
                    )
                  "
                  [title]="errorMessage"
                ></app-input-alert>
              </app-form-group>
            </div>

            <!-- Bead joins radio select -->
            <div class="col-4 col-joinbeads">
              <app-form-group title="Uniones junquillos">
                <div class="serie-radio">
                  <div
                    class="serie-radio-item serie-radio-item-center"
                    *ngFor="let beadjoin of unionJonquillos"
                  >
                    <input
                      type="radio"
                      name="unionJonquillo"
                      class="serie-radio-item__input"
                      formControlName="unionJonquillo"
                      [id]="beadjoin.id + beadjoin.name"
                      [value]="beadjoin.id"
                    />
                    <label
                      [for]="beadjoin.id + beadjoin.name"
                      class="serie-radio-item__label"
                    >
                      <img
                        [src]="beadjoin.image"
                        [alt]="beadjoin.name"
                        class="serie-radio-item__label__icon"
                      />
                    </label>
                  </div>
                  <app-input-alert
                    *ngFor="
                      let errorMessage of inputErrorMessages(
                        'unionJonquillo',
                        'Debe elegir una unión'
                      )
                    "
                    [title]="errorMessage"
                  ></app-input-alert>
                </div>
              </app-form-group>
            </div>
          </div>
          <div class="row roture-checkbox">
            <input type="checkbox" id="hasRoture" formControlName="hasRoture" />
            <label for="hasRoture">Con rotura</label>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #serieColorsTable>
      <div class="mb-5 mt-5 fade-in">
        <app-color-table-selector
          [aviableColors]="aviableColors"
          [serieColors]="serieColors"
          (serieColorsEmitter)="setSerieColors($event)"
        ></app-color-table-selector>
      </div>
    </ng-template>

    <div class="d-flex justify-content-center submit-button">
      <button class="btn btn-primary" (click)="onSubmit()">Aceptar</button>
    </div>
  </form>
</div>
