import { Component, EventEmitter, OnInit } from '@angular/core'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { IDialog } from 'src/app/@shared/@interfaces/dialog'

@Component({
  selector: 'app-sliding-dialog',
  templateUrl: './sliding-dialog.component.html',
  styleUrls: ['./sliding-dialog.component.scss'],
})
export class SlidingDialogComponent implements OnInit, IDialog {
  $response = new EventEmitter<any>()
  constructor(public modalRef: BsModalRef) {}
  confirm(): void {
    this.modalRef.hide()
  }
  decline(): void {
    this.modalRef.hide()
  }

  ngOnInit(): void {}

  save(): void {}
}
