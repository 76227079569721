// ANGULAR
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
// TOOLS
import Konva from 'konva'
import { Layer } from 'konva/types/Layer'
import { Stage } from 'konva/types/Stage'
import { fromEvent, Observable, Subject, Subscription } from 'rxjs'
import { roundUp } from 'src/app/@helper/roundNumber'
import { KonvaService } from 'src/app/@services/konva.service'
import { ModelService } from 'src/app/@services/model.service'
import { MeasuringRuleService } from 'src/app/@services/modelDrawServices/measuring-rule.service'
import { KonvaAreaModel } from 'src/app/@shared/@models/konva-area-model'
// MODELS
import { KonvaModelModel } from 'src/app/@shared/@models/konva-model-model'
import { Gap } from 'src/app/@shared/@models/modelsElements/gap-model'
import { NewModelModel } from 'src/app/@shared/@models/new-model-model'
import { Vector2 } from 'three'
import * as uuidGenerator from 'uuid'

@Component({
  selector: 'app-konva',
  templateUrl: './konva.component.html',
  styleUrls: ['./konva.component.scss'],
})
export class KonvaComponent implements OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('stage') stageRef: ElementRef<HTMLDivElement>

  @Input()
  set konvaModel(konvaModel: KonvaModelModel) {
    this.konvaModelObject = konvaModel
  }
  get konvaModel(): KonvaModelModel {
    return this.konvaModelObject
  }
  public konvaModelObject: KonvaModelModel

  @Input()
  set konvaAreas(konvaAreas: KonvaAreaModel[]) {
    this.konvaAreasArray = konvaAreas
  }

  get konvaAreas(): KonvaAreaModel[] {
    return this.konvaAreasArray
  }
  public konvaAreasArray: Array<KonvaAreaModel>

  public isDrawing = false
  public xDrawing = 0
  public yDrawing = 0

  public gapAspectRadio = 1
  public scaleDimensions = {
    width: 1,
    height: 1,
  }

  // Konva types
  public stage: Stage
  private stageMeasurement: { height: number; width: number }

  public layer: Layer

  private center: { x: number; y: number }
  public gap: Gap
  public gapAbsoluePosition: Vector2

  private createGapSubscription: Subscription
  private model: NewModelModel

  private measureRuleSubscription: Subscription
  private measureSubscription: Subscription
  private measureElements: Array<Konva.Line | Konva.Text> = []

  constructor(
    private konvaService: KonvaService,
    private modelService: ModelService,
    private measureRuleService: MeasuringRuleService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {}

  ngAfterViewInit() {
    this.createGapSubscription = this.konvaService.craeteGap$.subscribe(
      (model) => {
        this.model = model
        this.setupStage()
        this.measure()
      }
    )

    this.measureRuleSubscription = this.measureRuleService.isMeasuringRuleActive$.subscribe(
      (isRuleActive) => {
        this.measure(isRuleActive)
        if (!isRuleActive) this.measureSubscription?.unsubscribe()
      }
    )
  }

  ngOnDestroy() {
    // TODO: create img from stage

    if (this.stage) {
      this.stage?.scale({
        x: 1,
        y: 1,
      })

      const stagePosition = this.stage.position()

      const gapPosition = {
        x: this.gap.xScaled,
        y: this.gap.yScaled,
      }

      this.stage.setPosition(gapPosition)

      this.gap?.areas.forEach((a) => a.unSelectArea())
      this.gap?.unSelectAll()

      this.konvaService.sendImageModelPreview({
        gap: this.gap,
        modelId: this.model?.id,
        destroy: true,
      })
    }

    this.createGapSubscription?.unsubscribe()
    this.measureRuleSubscription?.unsubscribe()
    this.measureSubscription?.unsubscribe()
    this.gap?.onDestroy()
  }

  private setupStage(): void {
    const { clientWidth, clientHeight } = this.stageRef.nativeElement

    this.stageMeasurement = {
      width: clientWidth - 320,
      height: clientHeight,
    }

    this.stage = new Konva.Stage({
      container: this.stageRef.nativeElement,
      x: 0,
      y: 0,
      width: this.stageMeasurement.width,
      height: this.stageMeasurement.height,
    })

    this.layer = new Konva.Layer()
    this.stage.add(this.layer)

    this.center = {
      x: this.stage.width() / 2,
      y: this.stage.height() / 2,
    }

    this.createGap()

    // TODO: charge model from json
    // const stageFromJson = Konva.Node.create(jsonStage, 'stage')
    // this.stage.add(stageFromJson).draw()

    this.scaleSubscriber()
  }

  private createGap(): void {
    const { clientWidth, clientHeight } = this.stageRef.nativeElement

    this.gapAspectRadio = this.model.width / this.model.height

    const isOutHeight = this.model.height > clientHeight - 50
    const isOutWidth = this.model.width > clientWidth - 320

    const gapOriginalMeasures = {
      width: this.model.width,
      height: this.model.height,
    }

    const gapMeasures = {
      width: gapOriginalMeasures.width,
      height: gapOriginalMeasures.height,
    }

    // TODO: Validate dimensions when overflows
    if (isOutWidth) {
      gapMeasures.width = clientWidth - 320 - 100
      gapMeasures.height = gapOriginalMeasures.width / this.gapAspectRadio
    }

    if (isOutHeight) {
      gapMeasures.height = clientHeight - 100
      gapMeasures.width = gapMeasures.height * this.gapAspectRadio
    }
    this.scaleDimensions = {
      width: gapMeasures.width / gapOriginalMeasures.width,
      //height: gapMeasures.height / gapOriginalMeasures.height,
      height: gapMeasures.width / gapOriginalMeasures.width,
    }
    this.gapAbsoluePosition = new Vector2(
      this.center.x - gapMeasures.width / 2,
      this.center.y - gapMeasures.height / 2
    )

    this.gap = new Gap(
      uuidGenerator.v4(),
      this.gapAbsoluePosition.x,
      this.gapAbsoluePosition.y,
      gapOriginalMeasures.width,
      gapOriginalMeasures.height,
      this.layer,
      this.model.id,
      this.scaleDimensions.width,
      this.scaleDimensions.height,
      this.stage
    )

    this.gap.gapUpdated$.subscribe((gap) => {
      this.notifyGapUpdate(gap)
    })

    this.gap.setGapDownZIndex()
    this.gap.layer.draw()

    this.gap.notifyUpdate()
    this.gap.notifyAreasUpdate()
  }

  private notifyGapUpdate(gap: Gap): void {
    this.konvaService.gapUpdated(gap)
  }

  private scaleSubscriber(): void {
    const scaleBy = 1.1
    this.stage?.on('wheel', (e) => {
      e.evt.preventDefault()

      const oldScale = this.stage.scaleX()

      const pointer = this.stage.getPointerPosition()

      const mousePointTo = {
        x: (pointer.x - this.stage.x()) / oldScale,
        y: (pointer.y - this.stage.y()) / oldScale,
      }

      const newScale =
        e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy

      this.stage.scale({ x: newScale, y: newScale })

      const newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      }
      this.stage.position(newPos)
      this.stage.draw()
    })
  }

  private measure(ruleActive = false): void {
    let isMeasuring = false

    if (!ruleActive) {
      this.destroyMeasureElements()
      this.stage?.removeEventListener('mousemove')

      return
    }

    this.measureSubscription = fromEvent(this.stage, 'click').subscribe(
      (e: MouseEvent) => {
        this.stage.removeEventListener('mousemove')

        if (!isMeasuring) {
          const clickPoint = this.getPointerPosition()

          this.stage.addEventListener('mousemove', () => {
            const actualPoint = this.getPointerPosition()

            this.destroyMeasureElements()

            const line = new Konva.Line({
              points: [clickPoint.toArray(), actualPoint.toArray()].flat(),
              stroke: 'red',
              strokeWidth: 1,
            })

            const lengthVector = roundUp(
              clickPoint.clone().add(actualPoint.multiplyScalar(-1)).length() /
                this.gap.scaleWidth,
              2
            )

            const text = new Konva.Text({
              text: `${lengthVector} mm`,
              x: -actualPoint.x - 10,
              y: -actualPoint.y - 15,
            })

            this.measureElements = [...this.measureElements, line, text]
            this.layer.add(line, text).draw()
          })
        }

        isMeasuring = !isMeasuring
      }
    )
  }

  private destroyMeasureElements() {
    this.measureElements.forEach((e) => e.destroy())
    this.measureElements = []
    this.gap?.layer.draw()
  }

  private measureMove(pointA: Vector2): Observable<Konva.Line> {
    const subject = new Subject<Konva.Line>()

    let line: Konva.Line
    const subMove = fromEvent(this.stage, 'mousemove').subscribe(
      (e: MouseEvent) => {
        const oldScale = this.stage.scaleX()

        const pointer = this.stage.getPointerPosition()

        const mousePointTo = {
          x: (pointer.x - this.stage.x()) / oldScale,
          y: (pointer.y - this.stage.y()) / oldScale,
        }

        const actualPoint = new Vector2(mousePointTo.x, mousePointTo.y)

        line?.destroy()
        line = new Konva.Line({
          points: [pointA.toArray(), actualPoint.toArray()].flat(),
          stroke: 'red',
          strokeWidth: 1,
        })

        this.layer.add(line).draw()
      }
    )

    return subject.asObservable()
  }

  private getPointerPosition(): Vector2 {
    const oldScale = this.stage.scaleX()

    const pointer = this.stage.getPointerPosition()

    const mousePointTo = {
      x: (pointer.x - this.stage.x()) / oldScale,
      y: (pointer.y - this.stage.y()) / oldScale,
    }

    const actualPoint = new Vector2(mousePointTo.x, mousePointTo.y)

    return actualPoint
  }
}

const jsonStage = {
  attrs: {},
  className: 'Layer',
  children: [
    {
      attrs: {
        x: 156,
        y: 50,
        width: 751,
        height: 751,
        stroke: 'red',
        strokeWidth: 1,
        name: 'GAP',
      },
      className: 'Rect',
    },
    {
      attrs: { name: 'area' },
      className: 'Group',
      children: [
        {
          attrs: {
            x: 193.55751,
            y: 87.55751000000001,
            width: 675.88498,
            height: 675.88498,
            stroke: 'blue',
            strokeWidth: 1,
            dash: [2, 5],
            fill: 'transparent',
          },
          className: 'Rect',
        },
        {
          attrs: {
            text: 'A1',
            align: 'center',
            fill: 'black',
            x: 515.5,
            y: 425.5,
          },
          className: 'Text',
        },
      ],
    },
    {
      attrs: { name: 'frame' },
      className: 'Group',
      children: [
        {
          attrs: { name: 'profile' },
          className: 'Group',
          children: [
            {
              attrs: {
                points: [
                  156,
                  50.00000000000001,
                  193.55751,
                  87.55751000000001,
                  869.44249,
                  87.55751000000001,
                  907,
                  50.00000000000001,
                  156,
                  50.00000000000001,
                ],
                closed: true,
                fill: '#b2adad',
                stroke: '#b2adad',
                strokeWidth: 1,
                name: 'profile',
              },
              className: 'Line',
            },
            {
              attrs: {
                points: [
                  156,
                  50.00000000000001,
                  156,
                  50.00000000000001,
                  907,
                  50.00000000000001,
                  907,
                  50.00000000000001,
                ],
                fill: '#b2adad',
                stroke: 'black',
                strokeWidth: 0.5,
                name: 'externalFin',
                closed: true,
                strokeEnabled: false,
              },
              className: 'Line',
            },
            {
              attrs: {
                points: [
                  193.55751,
                  87.55751000000001,
                  208.57751000000002,
                  102.57751,
                  854.42249,
                  102.57751,
                  869.44249,
                  87.55751000000001,
                ],
                fill: '#b2adad',
                stroke: 'black',
                strokeWidth: 0.5,
                name: 'internalFin',
                closed: true,
                strokeEnabled: false,
              },
              className: 'Line',
            },
            {
              attrs: {
                points: [
                  156,
                  50.00000000000001,
                  208.57751000000002,
                  102.57751,
                  854.42249,
                  102.57751,
                  907,
                  50.00000000000001,
                ],
                stroke: 'red',
                strokeWidth: 1,
                closed: true,
                name: 'stroke',
              },
              className: 'Line',
            },
          ],
        },
        {
          attrs: { name: 'profile' },
          className: 'Group',
          children: [
            {
              attrs: {
                points: [
                  156,
                  801,
                  193.55751,
                  763.44249,
                  869.44249,
                  763.44249,
                  907,
                  801,
                  156,
                  801,
                ],
                closed: true,
                fill: '#b2adad',
                stroke: '#b2adad',
                strokeWidth: 1,
                name: 'profile',
              },
              className: 'Line',
            },
            {
              attrs: {
                points: [156, 801, 156, 801, 907, 801, 907, 801],
                fill: '#b2adad',
                stroke: 'black',
                strokeWidth: 0.5,
                name: 'externalFin',
                closed: true,
                strokeEnabled: false,
              },
              className: 'Line',
            },
            {
              attrs: {
                points: [
                  193.55751,
                  763.44249,
                  208.57751000000002,
                  748.42249,
                  854.42249,
                  748.42249,
                  869.44249,
                  763.44249,
                ],
                fill: '#b2adad',
                stroke: 'black',
                strokeWidth: 0.5,
                name: 'internalFin',
                closed: true,
                strokeEnabled: false,
              },
              className: 'Line',
            },
            {
              attrs: {
                points: [
                  156,
                  801,
                  208.57751000000002,
                  748.42249,
                  854.42249,
                  748.42249,
                  907,
                  801,
                ],
                stroke: 'red',
                strokeWidth: 1,
                closed: true,
                name: 'stroke',
              },
              className: 'Line',
            },
          ],
        },
        {
          attrs: { name: 'profile' },
          className: 'Group',
          children: [
            {
              attrs: {
                points: [
                  156,
                  50.00000000000001,
                  193.55751,
                  87.55751000000001,
                  193.55751,
                  763.44249,
                  156,
                  801,
                  156,
                  50.00000000000001,
                ],
                closed: true,
                fill: '#b2adad',
                stroke: '#b2adad',
                strokeWidth: 1,
                name: 'profile',
              },
              className: 'Line',
            },
            {
              attrs: {
                points: [
                  156,
                  50.00000000000001,
                  156,
                  50.00000000000001,
                  156,
                  801,
                  156,
                  801,
                ],
                fill: '#b2adad',
                stroke: 'black',
                strokeWidth: 0.5,
                name: 'externalFin',
                closed: true,
                strokeEnabled: false,
              },
              className: 'Line',
            },
            {
              attrs: {
                points: [
                  193.55751,
                  87.55751000000001,
                  208.57751000000002,
                  102.57751,
                  208.57751000000002,
                  748.42249,
                  193.55751,
                  763.44249,
                ],
                fill: '#b2adad',
                stroke: 'black',
                strokeWidth: 0.5,
                name: 'internalFin',
                closed: true,
                strokeEnabled: false,
              },
              className: 'Line',
            },
            {
              attrs: {
                points: [
                  156,
                  50.00000000000001,
                  208.57751000000002,
                  102.57751,
                  208.57751000000002,
                  748.42249,
                  156,
                  801,
                ],
                stroke: 'red',
                strokeWidth: 1,
                closed: true,
                name: 'stroke',
              },
              className: 'Line',
            },
          ],
        },
        {
          attrs: { name: 'profile' },
          className: 'Group',
          children: [
            {
              attrs: {
                points: [
                  907,
                  50.00000000000001,
                  869.44249,
                  87.55751000000001,
                  869.44249,
                  763.44249,
                  907,
                  801,
                  907,
                  50.00000000000001,
                ],
                closed: true,
                fill: '#b2adad',
                stroke: '#b2adad',
                strokeWidth: 1,
                name: 'profile',
              },
              className: 'Line',
            },
            {
              attrs: {
                points: [
                  907,
                  50.00000000000001,
                  907,
                  50.00000000000001,
                  907,
                  801,
                  907,
                  801,
                ],
                fill: '#b2adad',
                stroke: 'black',
                strokeWidth: 0.5,
                name: 'externalFin',
                closed: true,
                strokeEnabled: false,
              },
              className: 'Line',
            },
            {
              attrs: {
                points: [
                  869.44249,
                  87.55751000000001,
                  854.42249,
                  102.57751000000002,
                  854.42249,
                  748.42249,
                  869.44249,
                  763.44249,
                ],
                fill: '#b2adad',
                stroke: 'black',
                strokeWidth: 0.5,
                name: 'internalFin',
                closed: true,
                strokeEnabled: false,
              },
              className: 'Line',
            },
            {
              attrs: {
                points: [
                  907,
                  50.00000000000001,
                  854.42249,
                  102.57751000000002,
                  854.42249,
                  748.42249,
                  907,
                  801,
                ],
                stroke: 'red',
                strokeWidth: 1,
                closed: true,
                name: 'stroke',
              },
              className: 'Line',
            },
          ],
        },
      ],
    },
  ],
}
