<div class="rect">
  <div
    class="scheme-rect"
    [ngClass]="{
      top: sides.top,
      bottom: sides.bottom,
      left: sides.left,
      right: sides.right
    }"
  >
    <div
      class="scheme-rect internal"
      *ngIf="hasInternal"
      [ngClass]="{
        top: internalSides.top,
        bottom: internalSides.bottom,
        left: internalSides.left,
        right: internalSides.right
      }"
    ></div>
  </div>
</div>
