<div class="modal-header">
  <h5 class="modal-title pull-left">Nuevo modelo</h5>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="modalRef.hide()"
  >
    <img src="../../../assets/img/icons/close.svg" alt="Close modal icon" />
  </button>
</div>
<div class="modal-body">
  <div class="row no-gutters">
    <div class="col-8 row no-gutters align-items-center">
      <div class="col-12">
        <form [formGroup]="dimensionsForm">
          <div class="name-container">
            <div class="form-row">
              <div class="col-4 name-label">
                <label for="name" class="header-label">Nombre</label>
              </div>
              <div class="col-8 name-input">
                <input
                  id="name"
                  type="text"
                  class="form-control"
                  placeholder="nombre"
                  formControlName="name"
                />
              </div>
            </div>
          </div>
          <div class="dimensions-container">
            <div class="card">
              <div class="card-header">
                <div class="header-label">Dimensiones lados</div>
              </div>
              <div class="card-body">
                <div class="form-row">
                  <div class="col-2 width-label">
                    <label for="width">Ancho</label>
                  </div>
                  <div class="col-4">
                    <input
                      id="width"
                      type="text"
                      class="form-control"
                      placeholder="mm"
                      formControlName="width"
                    />
                  </div>
                  <div class="col-1 height-label">
                    <label for="height">Alto</label>
                  </div>
                  <div class="col-4 height-input">
                    <input
                      id="height"
                      type="text"
                      class="form-control"
                      placeholder="mm"
                      formControlName="height"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col-4 system-container">
      <div class="system-container-header">
        <div class="system-container-header-label">Serie</div>
        <div class="system-container-header-icon">
          <img src="../../../assets/icons/search.svg" alt="Close modal icon" />
        </div>
      </div>
      <div class="system-container-body">
        <ul class="list-group">
          <button
            *ngFor="let serie of series"
            type="button"
            class="list-group-item list-group-item-action"
            [ngClass]="{ 'list-group-item-action-selected': serie.selected }"
            (click)="setSerie(serie)"
          >
            {{ serie.name }}
          </button>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer justify-content-center">
  <!-- [disabled]="dimensionsForm.invalid" -->
  <button
    class="btn btn-primary"
    (click)="save()"
    [disabled]="dimensionsForm.invalid"
  >
    Aceptar
  </button>
</div>
