<div class="openings-images">
  <h5>Aberturas</h5>
  <div class="grid">
    <div *ngFor="let ima of $images; let i = index">
      <img
        src="../../../../assets/img/openings/{{ ima.id }}.svg"
        alt="abertura"
        (click)="selectOpening(ima.id)"
        [ngClass]="{
          active: openingSelectedId === ima.id
        }"
      />
    </div>
  </div>
</div>
