import { Component, EventEmitter, OnInit, Output } from '@angular/core'

import { BsModalRef } from 'ngx-bootstrap/modal'

import {
  faTimes,
  faPlus,
  faSearch,
  faWrench,
} from '@fortawesome/free-solid-svg-icons'

import { NgxSpinnerService } from 'ngx-spinner'

import { ProfileAccesoriesCombinationService } from 'src/app/@services/profile-accesories-combination.service'
import { Accesory } from 'src/app/@shared/@interfaces/accesory'
import { FormBuilder, FormGroup } from '@angular/forms'
import { debounceTime } from 'rxjs/operators'

@Component({
  selector: 'app-new-accesories-combination',
  templateUrl: './new-accesories-combination.component.html',
  styleUrls: ['./new-accesories-combination.component.scss'],
})
export class NewAccesoriesCombinationDialogComponent implements OnInit {
  @Output()
  $response: EventEmitter<any> = new EventEmitter<Accesory>()

  // Form
  public form: FormGroup

  // Icons
  public faTimes = faTimes
  public faPlus = faPlus
  public faSearch = faSearch
  public faWrench = faWrench

  public accesories: Accesory[] = []
  public filteredAccesories: Accesory[] = []

  public selectedAccesory: Accesory

  constructor(
    public modalRef: BsModalRef,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private accesoriesService: ProfileAccesoriesCombinationService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      search: [''],
      show: [false],
    })

    this.getAllAccesories()
    this.searchSubscriber()
  }

  // Modal control
  confirm(): void {
    this.$response.emit(this.selectedAccesory)
    this.modalRef.hide()
  }

  decline(): void {
    this.modalRef.hide()
  }

  private searchSubscriber(): void {
    this.form
      .get('search')
      .valueChanges.pipe(debounceTime(800))
      .subscribe((criteria) => {
        this.searchAccesories(criteria)
      })
  }

  public searchAccesories(criteria?: string): void {
    criteria = criteria ?? this.form.get('search').value
    if (!criteria) this.filteredAccesories = this.accesories

    this.filteredAccesories = this.accesories.filter((value) => {
      const code = value.codRef.toLowerCase().replace(' ', '')
      const description = value.denomination.toLowerCase().replace(' ', '')
      const family = value.family.toLowerCase().replace(' ', '')
      const familyWithLocale = `ES ${family}`.toLowerCase().replace(' ', '')
      const criteriaToLower = criteria.toLowerCase().replace(' ', '')

      return (
        code.includes(criteriaToLower) ||
        description.includes(criteriaToLower) ||
        family.includes(criteriaToLower) ||
        familyWithLocale.includes(criteriaToLower)
      )
    })
  }

  public selectAccesory(accesory: Accesory): void {
    this.selectedAccesory = accesory
    this.confirm()
  }

  private getAllAccesories(): void {
    this.spinner.show()

    this.accesoriesService.getAllAccesories().subscribe(
      (accesories: Accesory[]) => {
        this.accesories = accesories
        this.filteredAccesories = this.accesories
        this.spinner.hide()
      },
      (err) => {}
    )
  }
}
