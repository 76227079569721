<div class="wrapper-opening-table">
  <!-- Buttons -->
  <div class="buttons">
    <img
      src="assets/img/icons/addhoja.svg"
      alt="boton"
      class="add"
      (click)="addLeaft()"
    />
    <img
      src="assets/img/icons/quitarhoja.svg"
      alt="boton"
      class="remove"
      (click)="removeLeaft()"
    />
  </div>
  <!-- End Buttons -->

  <table class="table-opening">
    <!-- Headers -->
    <div class="table-column" style="min-width: 100px">
      <div class="first-row"></div>
      <div class="header">
        <span>Hoja apertura</span>
      </div>
      <div class="header">
        <span>Ancho</span>
      </div>
      <div class="header">
        <span>Tamaño fijo</span>
      </div>
      <div class="header">
        <span>Principal</span>
      </div>
    </div>
    <!-- End Headers -->

    <div
      class="table-column fade-in"
      *ngFor="let openingLeaft of openingsLeafts; let i = index"
      style="max-width: 100px"
    >
      <div class="first-row row-centered">
        <span>Hoja {{ i + 1 }}</span>
      </div>
      <div class="row-centered">
        <img
          src="../../../../assets/img/openings/{{ openingLeaft.id }}.svg"
          [alt]="openingLeaft.name || 'abertura'"
          style="width: 30px; cursor: pointer"
          (click)="selectOpening(i)"
          [ngClass]="{
            active: selectedOpeningIndex === i
          }"
        />
      </div>
      <form class="row-centered" [formGroup]="getFormGruop(i)">
        <input type="number" style="width: 50px" formControlName="width" />
      </form>
      <form class="row-centered" [formGroup]="getFormGruop(i)">
        <span><input type="checkbox" formControlName="isLock" /></span>
      </form>
      <form class="row-centered" [formGroup]="getFormGruop(i)">
        <span><input type="checkbox" formControlName="isMain" /></span>
      </form>
    </div>
  </table>
</div>
