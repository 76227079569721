import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { pluck } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { Accesory } from '../@shared/@interfaces/accesory'
import {
  AccesorieGroupItem,
  AccesoryGroupCreator,
} from '../@shared/@interfaces/accesoryGroup'
import { IdAndNameModel } from '../@shared/@models/idAndName.model'

@Injectable({
  providedIn: 'root',
})
export class AccesoriesGruopsService {
  private apiUrl = environment.apiUrl

  constructor(private http: HttpClient) { }

  public getAccesorieGroupsBySerie(
    serieId?: number
  ): Observable<IdAndNameModel[]> {
    let params = null;
    if (serieId) {
      params = new HttpParams().set('serie', serieId.toString())
    }

    return this.http.get<IdAndNameModel[]>(`${this.apiUrl}accessories-groups`, {
      params,
    })
  }

  /**
   *
   * @param group new Group
   * @return group id
   */
  public createGroup(group: {
    serie: { id: number }
    name: string
  }): Observable<number> {
    return this.http
      .post<number>(`${this.apiUrl}accessories-groups`, group)
      .pipe(pluck('id'))
  }

  public deleteGroup(groupId: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}accessories-groups/${groupId}`)
  }

  // Accesories
  public getAccesoriesByGroup(
    groupId: number
  ): Observable<AccesorieGroupItem[]> {
    const params = new HttpParams().set(
      'accessorySerieGroup',
      groupId.toString()
    )

    return this.http.get<AccesorieGroupItem[]>(
      `${this.apiUrl}accessories-series`,
      { params }
    )
  }

  public createAccesoryInGroup(
    accessory: AccesoryGroupCreator
  ): Observable<number> {
    return this.http
      .post<number>(`${this.apiUrl}accessories-series`, accessory)
      .pipe(pluck('id'))
  }

  public deleteAccesoryInGroup(accesoryId: number): Observable<void> {
    return this.http.delete<void>(
      `${this.apiUrl}accessories-series/${accesoryId}`
    )
  }

  public updateAccessory(
    accesory: AccesoryGroupCreator,
    id: number
  ): Observable<any> {
    return this.http.put(`${this.apiUrl}accessories-series/${id}`, accesory)
  }
}
