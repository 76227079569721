export class Color {
  id: number
  code400: string
  code: string
  description: string

  constructor(data: Partial<Color>) {
    Object.assign(this, data)
  }
}

export interface SerieColor {
  id?: number
  color: Color
}
