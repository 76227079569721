<div class="modal-header">
  <h5 class="modal-title pull-left">Selección perfiles marco</h5>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="modalRef.hide()"
  >
    <img src="../../../assets/img/icons/close.svg" alt="Close modal icon" />
  </button>
</div>
<div class="modal-body">
  <tabset class="custom-tabset">
    <tab>
      <ng-template tabHeading>
        <div class="tab-title">
          <span class="tab-title__text">Datos principales</span>
          <div class="tab__separator"></div>
        </div>
      </ng-template>

      <div *ngTemplateOutlet="frames"></div>
    </tab>

    <tab *ngIf="hasFrame">
      <ng-template tabHeading>
        <div class="tab-title">
          <span class="tab-title__text">Tapajuntas</span>
          <div class="tab__separator"></div>
        </div>
      </ng-template>

      <div *ngTemplateOutlet="coverGapsElementHTML"></div>
    </tab>

    <tab *ngIf="hasFrame">
      <ng-template tabHeading>
        <div class="tab-title">
          <span class="tab-title__text">Perfiles Asociados</span>
          <div class="tab__separator last"></div>
        </div>
      </ng-template>

      <div *ngTemplateOutlet="associatedProfilesTemplate"></div>
    </tab>
  </tabset>

  <div class="trash-icon" *ngIf="framesProfiles">
    <fa-icon [icon]="faTrashAlt" class="icon" (click)="deleteFrame()"></fa-icon>
  </div>

  <!-- Frame Selection tab -->
  <ng-template #frames>
    <div class="row no-gutters">
      <!-- PROFILES SELECTOR -->

      <div class="col-8 profiles-container">
        <div class="row" style="position: relative">
          <!-- Selects: custom union -->
          <div
            *ngIf="isCustomUnions"
            class="select-union left-top"
            (click)="openCustomUnionSelect($event, 'left-top')"
          >
            <img
              [src]="getUnionImage(customUnionsForm.get('left-top').value)"
              class="select-union__image"
              *ngIf="hasImage('left-top')"
              alt="union"
            />
          </div>
          <div
            *ngIf="isCustomUnions"
            class="select-union right-top"
            (click)="openCustomUnionSelect($event, 'right-top')"
          >
            <img
              [src]="getUnionImage(customUnionsForm.get('right-top').value)"
              class="select-union__image"
              *ngIf="hasImage('right-top')"
              alt="union"
            />
          </div>
          <div
            *ngIf="isCustomUnions"
            class="select-union left-bottom"
            (click)="openCustomUnionSelect($event, 'left-bottom')"
          >
            <img
              [src]="getUnionImage(customUnionsForm.get('left-bottom').value)"
              class="select-union__image"
              *ngIf="hasImage('left-bottom')"
              alt="union"
            />
          </div>
          <div
            *ngIf="isCustomUnions"
            class="select-union right-bottom"
            (click)="openCustomUnionSelect($event, 'right-bottom')"
          >
            <img
              [src]="getUnionImage(customUnionsForm.get('right-bottom').value)"
              class="select-union__image"
              *ngIf="hasImage('right-bottom')"
              alt="union"
            />
          </div>

          <!-- TOP -->
          <form class="col-12 my-3 text-center" [formGroup]="overlapForm">
            <div class="profile-container">
              <div class="profile-label">Lado superior</div>
              <div class="overlap">
                <img
                  src="../../../assets/icons/overlap.svg"
                  alt="overlap-icon"
                  tooltip="superposición"
                  class="overlap__icon top"
                />
                <input
                  type="number"
                  class="overlap__input"
                  formControlName="top"
                />
                <div class="cut-variation">
                  <div
                    class="cut-variation__button"
                    tooltip="variación de corte"
                    (click)="openCutVariationSelection('top')"
                  >
                    <img
                      src="../../../assets/icons/cutting.svg"
                      alt="variación de corte"
                      class="cut-variation__button__icon"
                    />
                  </div>
                  <span class="cut-variation__value">{{
                    cutVariations.top.value
                  }}</span>
                </div>
              </div>
              <div
                class="profile-img-container mx-auto"
                (click)="openProfileDialog('Lado superior')"
              >
                <img
                  *ngIf="images?.top"
                  [src]="images?.top"
                  alt="profile-image"
                />
              </div>
            </div>
            <!-- SIDES-->
            <div class="col-12 my-3 text-center">
              <div class="row no-gutters align-items-center">
                <!-- LEFT SIDE -->
                <div class="col-4">
                  <div class="profile-container">
                    <div class="profile-label">Lado izquierdo</div>
                    <div class="overlap">
                      <img
                        src="../../../assets/icons/overlap.svg"
                        alt="overlap-icon"
                        tooltip="superposición"
                        class="overlap__icon left"
                      />
                      <input
                        type="number"
                        class="overlap__input"
                        formControlName="left"
                      />
                      <div class="cut-variation">
                        <div
                          class="cut-variation__button"
                          tooltip="variación de corte"
                          (click)="openCutVariationSelection('left')"
                        >
                          <img
                            src="../../../assets/icons/cutting.svg"
                            alt="variación de corte"
                            class="cut-variation__button__icon"
                          />
                        </div>
                        <span class="cut-variation__value">{{
                          cutVariations.left.value
                        }}</span>
                      </div>
                    </div>
                    <div
                      class="profile-img-container mx-auto"
                      (click)="openProfileDialog('Lado izquierdo')"
                    >
                      <img
                        *ngIf="images?.left"
                        [src]="images.left"
                        alt="profile-image"
                      />
                    </div>
                  </div>
                </div>
                <!-- SKETCH -->
                <app-scheme-side-rect
                  [sides]="{
                    top: images?.top,
                    bottom: images?.bottom,
                    left: images?.left,
                    right: images?.right
                  }"
                ></app-scheme-side-rect>

                <!-- RIGHT SIDE -->
                <div class="col-4">
                  <div class="profile-container">
                    <div class="profile-label">Lado derecho</div>
                    <div class="overlap">
                      <input
                        type="number"
                        class="overlap__input"
                        formControlName="right"
                      />
                      <img
                        src="../../../assets/icons/overlap.svg"
                        alt="overlap-icon"
                        tooltip="superposición"
                        class="overlap__icon right"
                      />
                      <div class="cut-variation">
                        <div
                          class="cut-variation__button"
                          tooltip="variación de corte"
                          (click)="openCutVariationSelection('right')"
                        >
                          <img
                            src="../../../assets/icons/cutting.svg"
                            alt="variación de corte"
                            class="cut-variation__button__icon"
                          />
                        </div>
                        <span class="cut-variation__value">{{
                          cutVariations.right.value
                        }}</span>
                      </div>
                    </div>
                    <div
                      class="profile-img-container mx-auto"
                      (click)="openProfileDialog('Lado derecho')"
                    >
                      <img
                        *ngIf="images?.right"
                        [src]="images.right"
                        alt="profile-image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- LADO INFERIOR -->
            <div class="col-12 my-3 text-center">
              <div class="profile-container">
                <div class="profile-label">Lado inferior</div>
                <div class="overlap">
                  <img
                    src="../../../assets/icons/overlap.svg"
                    alt="overlap-icon"
                    tooltip="superposición"
                    class="overlap__icon bottom"
                  />
                  <input
                    type="number"
                    class="overlap__input"
                    formControlName="bottom"
                  />
                  <div class="cut-variation">
                    <div
                      class="cut-variation__button"
                      tooltip="variación de corte"
                      (click)="openCutVariationSelection('bottom')"
                    >
                      <img
                        src="../../../assets/icons/cutting.svg"
                        alt="variación de corte"
                        class="cut-variation__button__icon"
                      />
                    </div>
                    <span class="cut-variation__value">{{
                      cutVariations.bottom.value
                    }}</span>
                  </div>
                </div>
                <div
                  class="profile-img-container mx-auto"
                  (click)="openProfileDialog('Lado inferior')"
                >
                  <img
                    *ngIf="images?.bottom"
                    [src]="images.bottom"
                    alt="profile-image"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <!-- Unions -->
      <div class="col-3">
        <div class="unions-container">
          <div class="unions-title">Uniones</div>
          <div class="unions-box">
            <app-unions-column [formGroup]="frameForm"></app-unions-column>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #coverGapsElementHTML>
    <app-cover-gap
      [coverGaps]="coverGaps"
      [serieId]="serieId"
      (coverGapsEmitter)="setCoverGaps($event)"
    ></app-cover-gap>
  </ng-template>

  <ng-template #associatedProfilesTemplate>
    <app-associated-profiles-selector
      (associatedProfilesEmitter)="setAssociatedProfiles($event)"
      [associatedProfiles]="associatedProfiles"
    ></app-associated-profiles-selector>
  </ng-template>
</div>

<!-- Button -->
<div class="modal-footer justify-content-center">
  <button class="btn btn-primary" (click)="save()">Aceptar</button>
</div>

<div class="custom-union-select fade-in" #customUnionSelect>
  <app-unions-column
    [side]="customUnionSide"
    [withCustom]="false"
    [actualUnion]="customActualSelectUnionId"
    (unionSelectEmitter)="selectCustomUnion($event)"
  ></app-unions-column>
</div>
