import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { IDialog } from 'src/app/@shared/@interfaces/dialog'

import { faTimes } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-barlength-resume-dialog',
  templateUrl: './barlength-resume-dialog.component.html',
  styleUrls: ['./barlength-resume-dialog.component.scss'],
})
export class BarlengthResumeDialogComponent implements OnInit, IDialog {
  // Inputs
  public modelId: number

  @Output()
  $response = new EventEmitter<any>()

  // Icons
  public faTimes = faTimes

  constructor(private modalRef: BsModalRef) {}

  confirm(): void {
    this.modalRef.hide()
  }
  decline(): void {
    this.modalRef.hide()
  }

  ngOnInit(): void {}
}
