import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { SerieCreator } from '../@shared/@interfaces/serieCreator'
import { Color, SerieColor } from '../@shared/@interfaces/color.model'
import { map, pluck } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class SeriesService {
  private apiUrl = environment.apiUrl

  constructor(private http: HttpClient) {}

  public createNewSerie(serie: SerieCreator): Observable<any> {
    return this.http.post(`${this.apiUrl}series`, serie)
  }

  public updateSerie(id: number, serie: SerieCreator): Observable<any> {
    return this.http.put(`${this.apiUrl}series/${id}`, serie)
  }

  public deleteSerie(id: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}series/${id}`)
  }

  // deprecated
  getEnsemble(): Observable<any> {
    return this.http.get(environment.apiUrl + 'ensemble')
  }

  getSeries(): Observable<any> {
    return this.http.get(environment.apiUrl + 'series')
  }

  getSeriesFilteredByProfile(serieId: number): Observable<any> {
    return this.http.get(environment.apiUrl + 'profiles?serie=' + serieId)
  }

  getSerieById(serieId: number): Observable<any> {
    return this.http.get(environment.apiUrl + 'series/' + serieId)
  }

  // dependencies
  public getMillTypes(): Observable<any> {
    return this.http.get(`${this.apiUrl}mill-types`)
  }
  public getOpenings(): Observable<any> {
    return this.http.get(`${this.apiUrl}openings`)
  }
  public getBeadJoins(): Observable<any> {
    return this.http.get(`${this.apiUrl}union-jonquillos`)
  }
  public getSerieOverLaps(): Observable<any> {
    return this.http.get(`${this.apiUrl}serie-overlaps`)
  }
  public getCrystalOverLaps(): Observable<any> {
    return this.http.get(`${this.apiUrl}crystal-overlaps`)
  }
  public getHeightReferences(): Observable<any> {
    return this.http.get(`${this.apiUrl}height-references`)
  }
}
