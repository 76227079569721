// ANGULAR
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  Renderer2,
  ViewChild,
} from '@angular/core'
// TOOLS
import Konva from 'konva'
import { fromEvent, Observable, Subscription } from 'rxjs'
import { withLatestFrom } from 'rxjs/operators'
// MODELS
import { Size } from 'src/app/@shared/@interfaces/size'
import { Vertex } from 'src/app/@shared/@interfaces/vertex'
import { Vector2 } from 'three'
// SERVICES
import { DxfEditorService } from 'src/app/@services/dxf-editor.service'
import {
  CotasDimmesion,
  CotasServiceService,
  DxfDimensions,
} from 'src/app/@services/cotas-service.service'

import { ToastrService } from 'ngx-toastr'
import { DOCUMENT } from '@angular/common'

const verticalLineYOffset = {
  E: 48,
  A: 16,
  B: 16,
  C: 16,
  D: 16,
}

@Component({
  selector: 'app-level-editor',
  templateUrl: './level-editor.component.html',
  styleUrls: ['./level-editor.component.scss'],
})
export class LevelEditorComponent implements AfterViewInit, OnDestroy {
  @ViewChild('stage') stageDiv: ElementRef<HTMLDivElement>
  @ViewChild('profileContainer') profileContainerDiv: ElementRef<HTMLDivElement>

  @Input() closestVertice$: Observable<Vertex>

  // Size of the dxf canvas
  public canvasSize: Size

  // Konva object declarations
  private stage: Konva.Stage
  private layer: Konva.Layer
  private marker: Konva.Rect

  public maxWidthMM = 90

  private cotaE: Cota
  private cotaA: Cota
  private cotaB: Cota
  private cotaC: Cota
  private cotaD: Cota
  private cotaF: Cota
  private cotaG: Cota

  private cotaColors = {
    E: {
      color: 'red',
    },
    A: {
      color: 'blue',
    },
    B: {
      color: 'green',
    },
    C: {
      color: 'cyan',
    },
    D: {
      color: 'orange',
    },
    F: {
      color: 'red',
    },
    G: {
      color: 'red',
    },
  }

  private dxfDimensions: DxfDimensions

  // Subscriptions
  private dxfDimensionsSubscription: Subscription
  private cotaEDataFromFormSubscription: Subscription
  private cotaADataFromFormSubscription: Subscription
  private cotaBDataFromFormSubscription: Subscription
  private cotaCDataFromFormSubscription: Subscription
  private cotaDDataFromFormSubscription: Subscription

  private hideShowVerticalSubscription: Subscription
  private hideShowHorizontalSubscription: Subscription

  private cleanCotasSubscription: Subscription
  private generalSubscriptions: Subscription[] = []

  constructor(
    private dxfService: DxfEditorService,
    private cotasService: CotasServiceService,
    private toastr: ToastrService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.canvasSize = this.dxfService.getCanvasSize
  }

  ngAfterViewInit(): void {
    this.setupStage()
    this.listenerHandler()
    this.hideShowListeners()
  }

  ngOnDestroy(): void {
    this.stage.destroyChildren()
    this.stage.destroy()
    this.unsubscribe()
    this.dxfService?.onDestroy()
    this.cotasService?.onDestroy()
  }

  private unsubscribe(): void {
    this.dxfDimensionsSubscription.unsubscribe()
    this.cotaEDataFromFormSubscription.unsubscribe()
    this.cotaADataFromFormSubscription.unsubscribe()
    this.cotaBDataFromFormSubscription.unsubscribe()
    this.cotaCDataFromFormSubscription.unsubscribe()
    this.cotaDDataFromFormSubscription.unsubscribe()
    this.hideShowVerticalSubscription.unsubscribe()
    this.hideShowHorizontalSubscription.unsubscribe()
    this.cleanCotasSubscription.unsubscribe()

    this.generalSubscriptions.forEach((s) => s?.unsubscribe())
  }

  private hideShowListeners(): void {
    this.hideShowHorizontalSubscription = this.cotasService.hideShowHorizontal$.subscribe(
      (show) => {
        this.hideShowAllHorizontalCotas(show)
        this.layer.draw()
      }
    )

    this.hideShowVerticalSubscription = this.cotasService.hideShowVertical$.subscribe(
      (show) => {
        this.hideShowAllVerticalCotas(show)
        this.layer.draw()
      }
    )
  }

  public listenerHandler(): void {
    this.dxfDimensionsSubscription = this.cotasService.dxfAbsoluteDimensions$.subscribe(
      (dimensions) => {
        this.dxfDimensions = dimensions
        this.layer.removeChildren()
        this.cleanAllCotas()
      }
    )

    this.cotaADataFromFormSubscription = this.cotasService.createCotaA$?.subscribe(
      (widthMM) => {
        if (!this.canDraw(widthMM, this.cotaA)) return

        this.cleanCota(this.cotaA)

        if (widthMM !== null && widthMM !== undefined) {
          const widthPx = this.MMtoPx(widthMM)
          this.drawCotaA(this.dxfDimensions?.start.x + widthPx)
          this.createCotaE(this.dxfDimensions)
          return
        }
        this.createSectionA(this.dxfDimensions)
      }
    )

    this.cotaBDataFromFormSubscription = this.cotasService.createCotaB$.subscribe(
      (widthMM) => {
        if (!this.canDraw(widthMM, this.cotaB)) return

        this.cleanCota(this.cotaB)

        if (widthMM !== null && widthMM !== undefined) {
          const widthPx = this.MMtoPx(widthMM)

          const endCotaA = this.cotaA?.rightVerticalLine?.points()[0] ?? 0
          const endSectionB = endCotaA + widthPx

          this.drawSectionB(endSectionB)

          this.createCotaE(this.dxfDimensions)
          return
        }
        this.createSectionB(this.dxfDimensions)
      }
    )

    this.cotaCDataFromFormSubscription = this.cotasService.createCotaC$.subscribe(
      (widthMM) => {
        if (!this.canDraw(widthMM, this.cotaC)) return

        this.cleanCota(this.cotaC)
        if (widthMM !== null && widthMM !== undefined) {
          const widthPx = this.MMtoPx(widthMM)
          this.drawSectionC(this.dxfDimensions?.end.x - widthPx)

          this.createCotaE(this.dxfDimensions)
          return
        }
        this.createSectionC(this.dxfDimensions)
      }
    )

    this.cotaDDataFromFormSubscription = this.cotasService.createCotaD$.subscribe(
      (widthMM) => {
        if (!this.canDraw(widthMM, this.cotaD)) return
        this.cleanCota(this.cotaD)

        if (widthMM !== null && widthMM !== undefined) {
          const widthPx = this.MMtoPx(widthMM)
          const cotaCWidth = this.cotaC?.widthPx ?? 0
          const initCotaD = this.dxfDimensions?.end.x - widthPx - cotaCWidth

          this.drawSectionD(initCotaD)
          this.createCotaE(this.dxfDimensions)
          return
        }
        this.createSectionD(this.dxfDimensions)
      }
    )

    this.cotaEDataFromFormSubscription = this.cotasService.createCotaE$.subscribe(
      (widthMM) => {
        const canCreate = (widthMM || this.maxWidthMM) && this.dxfDimensions

        if (canCreate) {
          if (widthMM) this.maxWidthMM = widthMM

          this.cleanCota(this.cotaE)
          this.createCotaE(this.dxfDimensions)
        }
      }
    )

    this.cleanCotasSubscription = this.cotasService.cleanCotas$.subscribe(
      () => {
        this.cleanAllCotas()
      }
    )
  }

  private canDraw(widthMM: number | null, cota: Cota): boolean {
    if (!this.dxfDimensions) return false
    if (this.MMtoPx(widthMM) === cota?.widthPx) return false

    return true
  }

  public setWidthToCota(cota: Cota, forcedWidth?: number): Cota {
    const start = cota?.leftVerticalLine?.points()[0] ?? 0
    const end = cota?.rightVerticalLine?.points()[0] ?? 0

    let width = Math.abs(end - start)

    if (forcedWidth !== null && forcedWidth !== undefined) {
      width = forcedWidth
    }

    if (cota) cota.widthPx = width

    return cota
  }

  private drawHorizontalLineWithLetter(
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    letter: string,
    color: string
  ): Partial<Cota> {
    const letterSpace = 16
    const letterCount = letter.length

    const endLine1X = x2 / 2 + x1 / 2
    const startLine2X = endLine1X + letterCount * letterSpace

    const completeLine = new Konva.Line({
      points: [x1, y1, x2, y2],
      stroke: color,
    })

    const line1 = new Konva.Line({
      points: [x1, y1, endLine1X, y2],
      stroke: color,
    })

    const line2 = new Konva.Line({
      points: [startLine2X, y1, x2, y2],
      stroke: color,
    })

    const text = new Konva.Text({
      text: letter,
      fontSize: letterSpace,
      x: endLine1X,
      y: y1 - letterSpace / 3,
      fill: color,
      align: 'center',
      width: letterSpace * letterCount,
    })

    this.layer.add(line1, line2, text).draw()

    return {
      text: text,
      horizontalLines: [line1, line2],
    }
  }

  private recalculateVerticalLine(
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    color: string,
    line?: Konva.Line
  ): Konva.Line {
    if (line) line.remove()

    const newLine = new Konva.Line({
      points: [x1, y1, x2, y2],
      stroke: color,
    })

    this.layer.add(newLine).draw()
    return newLine
  }

  // Konva object intance
  // See Konva documentations
  private setupStage(): void {
    this.stage = new Konva.Stage({
      container: this.stageDiv.nativeElement,
      width: 500,
      height: 500,
    })

    this.layer = new Konva.Layer()
    this.stage.add(this.layer)
    this.marker = new Konva.Rect({
      width: 10,
      height: 10,
      stroke: '#08f',
      strokeWidth: 2,
      x: 10,
      y: 10,
      visible: false,
    })
    this.layer.add(this.marker)
    this.stage.draw()
  }

  private drawHorizontalLine(
    cota: Cota,
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    letter: string,
    color: string
  ): Cota {
    const textAndHorizontalLines = this.drawHorizontalLineWithLetter(
      x1,
      y1,
      x2,
      y2,
      letter,
      color
    )

    cota = {
      text: textAndHorizontalLines.text,
      horizontalLines: textAndHorizontalLines.horizontalLines,
    }

    const horizontalLineDistance = Math.abs(x2 - x1)

    /**
     * It´s set to true so that it does not show the dimension partition.
     * If "horizontalLineDistance" <(some distance) is set, the broken line will appear with the letter in the center
     */

    if (true) {
      const xLeft = cota.horizontalLines[0].points()[0]
      const xRight = cota.horizontalLines[1].points()[2]
      const YCoordenate = cota.horizontalLines[0].points()[1]

      cota.horizontalLines[0].points([xLeft, YCoordenate, xRight, YCoordenate])
      cota.horizontalLines[1].visible(false)

      cota.text.x(cota.text.x() - 8)
      cota.text.y(cota.text.y() + 8)

      this.layer.draw()
    }

    return cota
  }

  private cleanCota(cota: Cota): void {
    cota?.text?.destroy()
    cota?.leftVerticalLine?.destroy()
    cota?.rightVerticalLine?.destroy()
    cota?.horizontalLines?.forEach((h) => h?.destroy())
    this.layer?.draw()
  }
  private cleanAllCotas(): void {
    if (this.cotaA) {
      this.drawCotaA(this.dxfDimensions.start.x)
      this.cotaA.widthPx = 0
    }
    if (this.cotaB) {
      this.drawSectionB(this.dxfDimensions.start.x)
      this.cotaB.widthPx = 0
    }
    if (this.cotaC) {
      this.drawSectionC(this.dxfDimensions.end.x)
      this.cotaC.widthPx = 0
    }
    if (this.cotaD) {
      this.drawSectionD(this.dxfDimensions.end.x)
      this.cotaD.widthPx = 0
    }
    if (this.cotaE) {
      this.cotaE.widthPx = 0
    }

    this.cleanCota(this.cotaA)
    this.cleanCota(this.cotaB)
    this.cleanCota(this.cotaC)
    this.cleanCota(this.cotaD)
    this.cleanCota(this.cotaE)
  }

  private createCotaE(dimensions?: DxfDimensions): void {
    this.cleanCota(this.cotaE)

    if (!this.dxfDimensions) return

    const leftVerticalLineCotaB = this.cotaB?.leftVerticalLine?.points()[0]
    const rightVerticalLineCotaA = this.cotaA?.rightVerticalLine?.points()[0]
    const xVerticalLeftCoordinate =
      leftVerticalLineCotaB ??
      rightVerticalLineCotaA ??
      this.dxfDimensions.start.x

    const rightVerticalLineCotaD = this.cotaD?.rightVerticalLine?.points()[0]
    const leftVerticalLineCotaC = this.cotaC?.leftVerticalLine?.points()[0]
    const xVerticalRightCoordinate =
      rightVerticalLineCotaD ??
      leftVerticalLineCotaC ??
      this.dxfDimensions?.end.x

    const vertialLeftLinePoints = [
      xVerticalLeftCoordinate,
      this.dxfDimensions.start.y,
      xVerticalLeftCoordinate,
      this.dxfDimensions.end.y + verticalLineYOffset.E,
    ]

    const vertialRightLinePoints = [
      xVerticalRightCoordinate,
      this.dxfDimensions.start.y,
      xVerticalRightCoordinate,
      this.dxfDimensions.end.y + verticalLineYOffset.E,
    ]

    const vertialLeftLine = new Konva.Line({
      points: vertialLeftLinePoints,
      stroke: this.cotaColors.E.color,
    })

    const vertialRightLine = new Konva.Line({
      points: vertialRightLinePoints,
      stroke: this.cotaColors.E.color,
    })

    this.cotaE = this.drawHorizontalLine(
      this.cotaE,
      xVerticalLeftCoordinate,
      this.dxfDimensions.end.y + verticalLineYOffset.E,
      xVerticalRightCoordinate,
      this.dxfDimensions.end.y + verticalLineYOffset.E,
      'E',
      this.cotaColors.E.color
    )

    let cotaEWidth = xVerticalRightCoordinate - xVerticalLeftCoordinate

    this.cotaE = {
      ...this.cotaE,
      leftVerticalLine: vertialLeftLine,
      rightVerticalLine: vertialRightLine,
      widthPx: cotaEWidth,
    }

    this.layer.add(vertialLeftLine, vertialRightLine).draw()

    if (cotaEWidth < 0) {
      this.cleanAllCotas()
      this.toastr.info('La Cámara Interna no puede ser negativa')
    }

    this.emitaData()
  }

  public createSectionA(dimensions: DxfDimensions): void {
    const LineACoordinates: DxfDimensions = {
      start: new Vector2(dimensions.start.x, dimensions.start.y),
      end: new Vector2(dimensions.start.x, dimensions.start.y),
    }

    const lineAAux = this.createArrow(
      [
        LineACoordinates.start.x,
        LineACoordinates.start.y,
        LineACoordinates.end.x,
        LineACoordinates.end.y,
      ],
      this.cotaColors.A.color
    )

    this.layer.add(lineAAux)

    const mouseMoveSubscription = fromEvent(
      this.stageDiv.nativeElement,
      'mousemove'
    )
      .pipe(withLatestFrom(this.closestVertice$))
      .subscribe(([, v]) => {
        //
        this.marker.visible(true)
        this.marker.x(v.absolute.x - this.marker.width() / 2)
        this.marker.y(v.absolute.y - this.marker.height() / 2)

        LineACoordinates.end.setX(v.absolute.x)
        LineACoordinates.end.setY(v.absolute.y)

        lineAAux.points([
          LineACoordinates.start.x,
          LineACoordinates.start.y,
          LineACoordinates.end.x,
          LineACoordinates.end.y,
        ])

        this.layer.draw()
      })

    const clickSubscription = fromEvent(this.stageDiv.nativeElement, 'click')
      .pipe(withLatestFrom(this.closestVertice$))
      .subscribe(([, v]) => {
        mouseMoveSubscription.unsubscribe()

        LineACoordinates.end.setX(v.absolute.x)
        LineACoordinates.end.setY(v.absolute.y)

        lineAAux.remove()
        clickSubscription.unsubscribe()

        this.drawCotaA(LineACoordinates.end.x)
      })

    this.generalSubscriptions.push(clickSubscription, mouseMoveSubscription)

    this.abortActionListeners(
      mouseMoveSubscription,
      clickSubscription,
      lineAAux
    )
  }

  private drawCotaA(x2: number): any {
    this.cleanCota(this.cotaA)

    const xStartCotaA = this.dxfDimensions?.start.x
    const xEndCotaA = x2

    if (xEndCotaA > this.dxfDimensions?.end.x) {
      this.cotaA = this.setWidthToCota(this.cotaA, 0)
      this.createCotaE()
      return this.toastr.info('La Aleta externa execede el ancho')
    }

    if (xEndCotaA - xStartCotaA < 0) {
      this.cotaA = this.setWidthToCota(this.cotaA, 0)
      this.createCotaE()
      return this.toastr.info('La Aleta externa no puede ser negativa')
    }

    this.cotaA = this.drawHorizontalLine(
      this.cotaA,
      xStartCotaA,
      this.dxfDimensions?.end.y + verticalLineYOffset.A,
      xEndCotaA,
      this.dxfDimensions?.end.y + verticalLineYOffset.A,
      'A',
      this.cotaColors.A.color
    )

    this.cotaA.leftVerticalLine = this.recalculateVerticalLine(
      xStartCotaA,
      this.dxfDimensions?.start.y,
      xStartCotaA,
      this.dxfDimensions?.end.y + verticalLineYOffset.A,
      this.cotaColors.A.color
    )

    this.cotaA.rightVerticalLine = this.recalculateVerticalLine(
      xEndCotaA,
      this.dxfDimensions?.start.y,
      xEndCotaA,
      this.dxfDimensions?.end.y + verticalLineYOffset.A,
      this.cotaColors.A.color
    )

    this.cotaA.rightVerticalLine.visible(false)

    if (this.calculateWidthPx(this.cotaA) === 0) {
      this.cotaA?.leftVerticalLine?.visible(false)
    }
    this.cotaA = this.setWidthToCota(this.cotaA)

    const endHorizontalLineCotaB = this.cotaB?.rightVerticalLine?.points()[0]

    if (endHorizontalLineCotaB) this.drawSectionB(endHorizontalLineCotaB)
    this.createCotaE(this.dxfDimensions)
  }

  public createSectionB(dimensions: DxfDimensions): void {
    const pointCotaE = this.cotaE.leftVerticalLine.points()

    const LineACoordinates: DxfDimensions = {
      start: new Vector2(pointCotaE[0], pointCotaE[1]),
      end: new Vector2(pointCotaE[0], pointCotaE[1]),
    }

    const lineBAux = this.createArrow(
      [
        LineACoordinates.start.x,
        LineACoordinates.start.y,
        LineACoordinates.end.x,
        LineACoordinates.end.y,
      ],
      this.cotaColors.B.color
    )

    this.layer.add(lineBAux).draw()

    const mouseMoveSubscription = fromEvent(
      this.stageDiv.nativeElement,
      'mousemove'
    )
      .pipe(withLatestFrom(this.closestVertice$))
      .subscribe(([, v]) => {
        //
        this.marker.visible(true)
        this.marker.x(v.absolute.x - this.marker.width() / 2)
        this.marker.y(v.absolute.y - this.marker.height() / 2)

        LineACoordinates.end.setX(v.absolute.x)
        LineACoordinates.end.setY(v.absolute.y)

        lineBAux.points([
          LineACoordinates.start.x,
          LineACoordinates.start.y,
          LineACoordinates.end.x,
          LineACoordinates.end.y,
        ])

        this.layer.draw()
      })

    const clickSubscription = fromEvent(this.stageDiv.nativeElement, 'click')
      .pipe(withLatestFrom(this.closestVertice$))
      .subscribe(([, v]) => {
        mouseMoveSubscription.unsubscribe()

        LineACoordinates.end.setX(v.absolute.x)
        LineACoordinates.end.setY(v.absolute.y)

        lineBAux.remove()
        clickSubscription.unsubscribe()

        this.drawSectionB(LineACoordinates.end.x)
        this.createCotaE(this.dxfDimensions)
      })

    this.generalSubscriptions.push(clickSubscription, mouseMoveSubscription)

    this.abortActionListeners(
      mouseMoveSubscription,
      clickSubscription,
      lineBAux
    )
  }

  private drawSectionB(x2: number): any {
    this.cleanCota(this.cotaB)

    const endHorizontalLineCotaA =
      this.cotaA?.rightVerticalLine?.points()[0] ?? this.dxfDimensions?.start.x

    if (
      endHorizontalLineCotaA < this.dxfDimensions?.start.x ||
      x2 > this.dxfDimensions?.end.x
    ) {
      this.cotaB = this.setWidthToCota(this.cotaB, 0)
      return this.toastr.info('El diente externo debe estar dentro del ancho')
    }

    if (x2 - endHorizontalLineCotaA < 0) {
      this.cotaB = this.setWidthToCota(this.cotaB, 0)
      x2 = endHorizontalLineCotaA
      this.toastr.info('El diente externo no puede ser negativo')
    }

    this.cotaB = this.drawHorizontalLine(
      this.cotaB,
      endHorizontalLineCotaA,
      this.dxfDimensions?.end.y + verticalLineYOffset.B,
      x2,
      this.dxfDimensions?.end.y + verticalLineYOffset.B,
      'B',
      this.cotaColors.B.color
    )

    this.cotaB.leftVerticalLine = this.recalculateVerticalLine(
      endHorizontalLineCotaA,
      this.dxfDimensions?.start.y,
      endHorizontalLineCotaA,
      this.dxfDimensions?.end.y + verticalLineYOffset.B,
      this.cotaColors.B.color
    )

    this.cotaB.rightVerticalLine = this.recalculateVerticalLine(
      x2,
      this.dxfDimensions?.start.y,
      x2,
      this.dxfDimensions?.end.y + verticalLineYOffset.B,
      this.cotaColors.B.color
    )

    this.cotaB = this.setWidthToCota(this.cotaB)
  }

  public createSectionC(dimensions: DxfDimensions): void {
    const LineAuxCoordinates: DxfDimensions = {
      start: new Vector2(dimensions.end.x, dimensions.start.y),
      end: new Vector2(dimensions.end.x, dimensions.start.y),
    }

    const lineCAux = this.createArrow(
      [
        LineAuxCoordinates.start.x,
        LineAuxCoordinates.start.y,
        LineAuxCoordinates.end.x,
        LineAuxCoordinates.end.y,
      ],
      this.cotaColors.C.color
    )

    this.layer.add(lineCAux).draw()

    const mouseMoveSubscription = fromEvent(
      this.stageDiv.nativeElement,
      'mousemove'
    )
      .pipe(withLatestFrom(this.closestVertice$))
      .subscribe(([, v]) => {
        //
        this.marker.visible(true)
        this.marker.x(v.absolute.x - this.marker.width() / 2)
        this.marker.y(v.absolute.y - this.marker.height() / 2)

        LineAuxCoordinates.end.setX(v.absolute.x)
        LineAuxCoordinates.end.setY(v.absolute.y)

        lineCAux.points([
          LineAuxCoordinates.start.x,
          LineAuxCoordinates.start.y,
          LineAuxCoordinates.end.x,
          LineAuxCoordinates.end.y,
        ])

        this.layer.draw()
      })

    const clickSubscription = fromEvent(this.stageDiv.nativeElement, 'click')
      .pipe(withLatestFrom(this.closestVertice$))
      .subscribe(([, v]) => {
        mouseMoveSubscription.unsubscribe()

        LineAuxCoordinates.end.setX(v.absolute.x)
        LineAuxCoordinates.end.setY(v.absolute.y)

        lineCAux.remove()
        clickSubscription.unsubscribe()

        this.drawSectionC(LineAuxCoordinates.end.x)
      })

    this.generalSubscriptions.push(clickSubscription, mouseMoveSubscription)

    this.abortActionListeners(
      mouseMoveSubscription,
      clickSubscription,
      lineCAux
    )
  }

  private drawSectionC(x1: number): any {
    this.cleanCota(this.cotaC)

    if (x1 < this.dxfDimensions?.start.x) {
      this.cotaC = this.setWidthToCota(this.cotaC, 0)
      return this.toastr.info('La aleta interna excede el ancho')
    }

    if (this.dxfDimensions?.end.x - x1 < 0) {
      this.cotaC = this.setWidthToCota(this.cotaC, 0)
      return this.toastr.info('La aleta interna no puede ser negativa')
    }

    this.cotaC = this.drawHorizontalLine(
      this.cotaC,
      x1,
      this.dxfDimensions?.end.y + verticalLineYOffset.C,
      this.dxfDimensions?.end.x,
      this.dxfDimensions?.end.y + verticalLineYOffset.C,
      'C',
      this.cotaColors.C.color
    )

    this.cotaC.rightVerticalLine = this.recalculateVerticalLine(
      this.dxfDimensions?.end.x,
      this.dxfDimensions?.start.y,
      this.dxfDimensions?.end.x,
      this.dxfDimensions?.end.y + verticalLineYOffset.C,
      this.cotaColors.C.color
    )

    this.cotaC.leftVerticalLine = this.recalculateVerticalLine(
      x1,
      this.dxfDimensions?.start.y,
      x1,
      this.dxfDimensions?.end.y + verticalLineYOffset.C,
      this.cotaColors.C.color
    )
    this.cotaC.leftVerticalLine.visible(false)

    this.cotaC = this.setWidthToCota(this.cotaC)

    const initHorizontalCotaD = this.cotaD?.leftVerticalLine?.points()[0]
    if (initHorizontalCotaD) this.drawSectionD(initHorizontalCotaD)
    this.createCotaE(this.dxfDimensions)
  }

  private createSectionD(dimensions: DxfDimensions): void {
    const pointCotaE = this.cotaE.rightVerticalLine.points()

    const LineAuxCoordinates: DxfDimensions = {
      start: new Vector2(pointCotaE[0], pointCotaE[1]),
      end: new Vector2(pointCotaE[0], pointCotaE[1]),
    }

    const lineAux = this.createArrow(
      [
        LineAuxCoordinates.start.x,
        LineAuxCoordinates.start.y,
        LineAuxCoordinates.end.x,
        LineAuxCoordinates.end.y,
      ],
      this.cotaColors.D.color
    )

    this.layer.add(lineAux).draw()

    const mouseMoveSubscription = fromEvent(
      this.stageDiv.nativeElement,
      'mousemove'
    )
      .pipe(withLatestFrom(this.closestVertice$))
      .subscribe(([, v]) => {
        //
        this.marker.visible(true)
        this.marker.x(v.absolute.x - this.marker.width() / 2)
        this.marker.y(v.absolute.y - this.marker.height() / 2)

        LineAuxCoordinates.end.setX(v.absolute.x)
        LineAuxCoordinates.end.setY(v.absolute.y)

        lineAux.points([
          LineAuxCoordinates.start.x,
          LineAuxCoordinates.start.y,
          LineAuxCoordinates.end.x,
          LineAuxCoordinates.end.y,
        ])

        this.layer.draw()
      })

    const clickSubscription = fromEvent(this.stageDiv.nativeElement, 'click')
      .pipe(withLatestFrom(this.closestVertice$))
      .subscribe(([, v]) => {
        mouseMoveSubscription.unsubscribe()

        LineAuxCoordinates.end.setX(v.absolute.x)
        LineAuxCoordinates.end.setY(v.absolute.y)

        lineAux.remove()
        clickSubscription.unsubscribe()

        this.drawSectionD(LineAuxCoordinates.end.x)
        this.createCotaE(this.dxfDimensions)
      })

    this.generalSubscriptions.push(clickSubscription, mouseMoveSubscription)

    this.abortActionListeners(mouseMoveSubscription, clickSubscription, lineAux)
  }
  private drawSectionD(x1: number): any {
    this.cleanCota(this.cotaD)

    const xEndSectionD =
      this.cotaC?.leftVerticalLine?.points()[0] ?? this.dxfDimensions?.end.x

    const isInViewPort =
      xEndSectionD > this.dxfDimensions?.end.x ||
      x1 < this.dxfDimensions?.start.x

    if (isInViewPort) {
      this.cotaD = this.setWidthToCota(this.cotaD, 0)
      x1 = xEndSectionD
      this.toastr.info('El diente interno debe estar dentro del ancho ')
    }

    if (xEndSectionD - x1 < 0) {
      this.cotaD = this.setWidthToCota(this.cotaD, 0)
      x1 = xEndSectionD
      this.toastr.info('El diente interno no puede ser negativo')
    }

    this.cotaD = this.drawHorizontalLine(
      this.cotaD,
      x1,
      this.dxfDimensions?.end.y + verticalLineYOffset.D,
      xEndSectionD,
      this.dxfDimensions?.end.y + verticalLineYOffset.D,
      'D',
      this.cotaColors.D.color
    )

    this.cotaD.leftVerticalLine = this.recalculateVerticalLine(
      x1,
      this.dxfDimensions?.start.y,
      x1,
      this.dxfDimensions?.end.y + verticalLineYOffset.D,
      this.cotaColors.D.color
    )

    this.cotaD.rightVerticalLine = this.recalculateVerticalLine(
      xEndSectionD,
      this.dxfDimensions?.start.y,
      xEndSectionD,
      this.dxfDimensions?.end.y + verticalLineYOffset.D,
      this.cotaColors.D.color
    )

    this.cotaD = this.setWidthToCota(this.cotaD)
  }

  private abortActionListeners(
    mouseMoveSubscription: Subscription,
    clickSubscription: Subscription,
    lineAux: Konva.Arrow
  ): void {
    let keyupListener = this.renderer.listen(
      this.document,
      'keyup',
      (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          mouseMoveSubscription.unsubscribe()
          clickSubscription.unsubscribe()

          lineAux.remove()
          this.layer.draw()

          keyupListener()
          keyupListener = null

          contextMenuClickListener()
          contextMenuClickListener = null
        }
      }
    )

    let contextMenuClickListener = this.renderer.listen(
      this.document,
      'contextmenu',
      (event: MouseEvent) => {
        mouseMoveSubscription.unsubscribe()
        clickSubscription.unsubscribe()

        lineAux.remove()
        this.layer.draw()

        keyupListener()
        keyupListener = null

        contextMenuClickListener()
        contextMenuClickListener = null
      }
    )
  }

  private hideShowAllcotas(show: boolean): void {
    this.hideShowAllHorizontalCotas(show)
    this.hideShowAllVerticalCotas(show)
  }

  private hideShowAllVerticalCotas(show: boolean): void {
    this.cotaA?.leftVerticalLine?.visible(show)
    this.cotaA?.rightVerticalLine?.visible(show)

    this.cotaB?.leftVerticalLine?.visible(show)
    this.cotaB?.rightVerticalLine?.visible(show)

    this.cotaC?.leftVerticalLine?.visible(show)
    this.cotaC?.rightVerticalLine?.visible(show)

    this.cotaD?.leftVerticalLine?.visible(show)
    this.cotaD?.rightVerticalLine?.visible(show)

    this.cotaE?.leftVerticalLine?.visible(show)
    this.cotaE?.rightVerticalLine?.visible(show)
  }

  private hideShowAllHorizontalCotas(show: boolean): void {
    this.cotaA?.horizontalLines?.forEach((h, index) => {
      const width = this.cotaA?.widthPx ?? 0
      if (width < 24 && index == 1) return h?.visible(false)
      h?.visible(show)
    })
    this.cotaB?.horizontalLines?.forEach((h, index) => {
      const width = this.cotaB?.widthPx ?? 0
      if (width < 24 && index == 1) return h?.visible(false)
      h?.visible(show)
    })
    this.cotaC?.horizontalLines?.forEach((h, index) => {
      const width = this.cotaC?.widthPx ?? 0
      if (width < 24 && index == 1) return h?.visible(false)
      h?.visible(show)
    })
    this.cotaD?.horizontalLines?.forEach((h, index) => {
      const width = this.cotaD?.widthPx ?? 0
      if (width < 24 && index == 1) return h?.visible(false)
      h?.visible(show)
    })
    this.cotaE?.horizontalLines?.forEach((h, index) => {
      const width = this.cotaE?.widthPx ?? 0
      if (width < 32 && index == 1) return
      h?.visible(show)
    })

    this.cotaA?.text?.visible(show)
    this.cotaB?.text?.visible(show)
    this.cotaC?.text?.visible(show)
    this.cotaD?.text?.visible(show)
    this.cotaE?.text?.visible(show)
  }

  private createArrow(points: number[], color: string): Konva.Arrow {
    return new Konva.Arrow({
      points,
      stroke: color,
      fill: color,
      pointerWidth: 8,
      pointerLength: 8,
    })
  }

  public calculateWidthPx(cota: Cota): number {
    const XStart: number = cota.horizontalLines[0].points()[0]
    const XEnd: number = cota.horizontalLines[1].points()[2]

    return Math.abs(XEnd - XStart)
  }

  public calculateWidthMM(cota: Cota): number {
    const widthPx = Math.abs(
      this.dxfDimensions?.end.x - this.dxfDimensions?.start.x
    )

    return (this.maxWidthMM * this.calculateWidthPx(cota)) / widthPx
  }

  private pxToMM(px: number): number {
    if (!px) return 0

    const widthPx = Math.abs(
      this.dxfDimensions?.end.x - this.dxfDimensions?.start.x
    )

    return (this.maxWidthMM * px) / widthPx
  }

  private MMtoPx(mm: number): number {
    const widthPx = Math.abs(
      this.dxfDimensions?.end.x - this.dxfDimensions?.start.x
    )

    return (mm * widthPx) / this.maxWidthMM
  }

  private emitaData(): void {
    const dimensions: CotasDimmesion = {
      A: Number(this.pxToMM(this.cotaA?.widthPx).toFixed(2)),
      B: Number(this.pxToMM(this.cotaB?.widthPx).toFixed(2)),
      C: Number(this.pxToMM(this.cotaC?.widthPx).toFixed(2)),
      D: Number(this.pxToMM(this.cotaD?.widthPx).toFixed(2)),
      E: Number(this.pxToMM(this.cotaE?.widthPx).toFixed(2)),
    }

    this.cotasService.emitCotasDimension(dimensions)
  }
}

interface Cota {
  text: Konva.Text
  horizontalLines: Konva.Line[]
  leftVerticalLine?: Konva.Line
  rightVerticalLine?: Konva.Line
  widthPx?: number
}
