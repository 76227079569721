import Konva from 'konva'

import { ProfileElement, ProfileElementCreator } from './Profile-model'

export class DoorWindowLeaftSideElement extends ProfileElement {
  constructor(leaftProfile: DoorWindowLeaftSideElementCreator) {
    super(leaftProfile)
  }

  public resize(scaledLenght: number): void {
    super.resize(scaledLenght)
  }
}

export interface DoorWindowLeaftSideElementCreator
  extends ProfileElementCreator {}
