import Konva from 'konva'
import * as uuidGenerator from 'uuid'

import { Observable, Subject } from 'rxjs'
import { roundUp } from 'src/app/@helper/roundNumber'
import { Vector2 } from 'three'
import { ModelElement } from '../../@interfaces/modelElement'
import { Side, SideOptions } from '../../@types/side.types'
import { IdModel } from '../id-model'
import { CrossbarElement } from './crossbar-model'
import { TipupLeaftOpening } from './door-window-leaft-model'
import { DoorWindowTipupOpeningElement } from './door-window-tipupOpening-model'
import { Frame } from './frame-model'
import { FrameSide } from './frameSide-model'
import { Gap } from './gap-model'
import { JonquilloElement } from './jonquillo-model'
import { ProfileElement } from './Profile-model'
import { cleanArray } from 'src/app/@helper/cleanArray'

const fillAreaColor = '#c9ebf4bb'
const PROFILE_COLOR = '#b2adad'
const STROKE_WIDTH = 1
const HALF_STROKE_WIDTH = STROKE_WIDTH / 2

export class AreaModel implements ModelElement {
  public readonly uuid: string
  public model: IdModel
  id: number | null = null
  _x: number
  _y: number

  private _width: number
  private _height: number

  public leftCrossbar: CrossbarElement
  public rightCrossbar: CrossbarElement
  public topCrossbar: CrossbarElement
  public bottomCrossbar: CrossbarElement

  public leftFrame: FrameSide[] = []
  public rightFrame: FrameSide[] = []
  public topFrame: FrameSide[] = []
  public bottomFrame: FrameSide[] = []

  public frames: Frame[] = []

  public doorWindowOpenings: DoorWindowTipupOpeningElement
  public leaft: TipupLeaftOpening
  public jonquillo: JonquilloElement

  cuts: Konva.Line[] = []
  area: Konva.Rect
  text: Konva.Text
  layer: Konva.Layer
  public readonly group: Konva.Group

  public gap: Gap
  public gapPosition: Vector2
  public abosulePosition: Vector2
  private isSelected = false

  public center: Vector2

  public scaleWidth = 1
  private scaleHeight = 1

  private selectedSubject: Subject<AreaModel | null>

  private _children: ProfileElement[] = []

  public container: Area

  constructor(
    uuid: string,
    x: number,
    y: number,
    width: number,
    height: number,
    text: string,
    layer: Konva.Layer,
    modelId: number,
    gapPosition: Vector2,
    scaleWidth: number,
    scaleHeight: number,
    gap: Gap
  ) {
    this.uuid = uuid
    this.layer = layer

    this.gapPosition = gapPosition
    this.gap = gap

    this._x = x
    this._y = y

    this.scaleWidth = scaleWidth
    this.scaleHeight = scaleHeight

    this._width = width * scaleWidth
    this._height = height * scaleHeight
    this.avoidNegativeDimensions()

    this.abosulePosition = new Vector2(
      this._x + this.gapPosition.x,
      this._y + this.gapPosition.y
    )

    this.group = new Konva.Group().addName('area')

    this.setCenter()
    this.createArea()
    this.createText(text)

    this.model = new IdModel(modelId)
  }

  get children(): ModelElement[] | ProfileElement[] {
    return this._children
  }

  get crossbars(): CrossbarElement[] {
    return [
      this.leftCrossbar,
      this.rightCrossbar,
      this.topCrossbar,
      this.bottomCrossbar,
    ].filter((c) => (c ? true : false))
  }

  get width(): number {
    return Number(roundUp(this._width / this.scaleWidth, 4).toFixed(2))
  }
  get height(): number {
    return Number(roundUp(this._height / this.scaleHeight, 4).toFixed(2))
  }

  get x(): number {
    return this._x / this.scaleWidth
  }

  get y(): number {
    return this._y / this.scaleHeight
  }

  public getNonScaledStartPosition(): Vector2 {
    const startX = this.gap.xScaled + this._x
    const startY = this.gap.yScaled + this._y

    return new Vector2(startX, startY)
  }

  public destroy(): void {
    // this.leftCrossbar?.destroy()
    // this.rightCrossbar?.destroy()
    // this.topCrossbar?.destroy()
    // this.bottomCrossbar?.destroy()

    // this.frames.forEach((f) => f.destroy())
    this.cuts.forEach((c) => c.destroy())
    this.area.destroy()
    this.text.destroy()
    this.group.destroy()
  }

  /**
   * hasFixedElements
   */
  public hasFixedElements(): boolean {
    const hasFixed = this.doorWindowOpenings ? false : true
    const hasChildren = this.hasElementNotCrossbar()

    return hasFixed && hasChildren
  }

  public hasElementNotCrossbar(): boolean {
    return this._children.some((c: ProfileElement) => {
      const isUndefined = c === undefined || c === null
      if (isUndefined) return false

      return c instanceof CrossbarElement ? false : true
    })
  }

  public addJonquillo(jonquillo: JonquilloElement): void {
    this.jonquillo = jonquillo
    this._children.push(jonquillo as any)
  }

  public removeJonquillo(uuid: string): void {
    this.jonquillo = null
    this._children = this._children.filter((c) => c?.uuid !== uuid)
  }

  public addLeaft(leaft: TipupLeaftOpening): void {
    this.leaft = leaft
    this._children.push(leaft as any)
  }

  public removeLeaft(): void {
    this._children = this._children.filter((c) => c?.uuid !== this.leaft?.uuid)
    this.leaft = null
  }

  public setZIndexTop() {
    this.group.moveToTop()
  }

  public hasFrame(): boolean {
    if (this.frames.length === 0) return false
    return true
  }

  private avoidNegativeDimensions(): void {
    if (this._width < 0) {
      this._x = this._x + this._width
      this._width = Math.abs(this._width)
    }

    if (this._height < 0) {
      this._y = this._y + this._height
      this._height = Math.abs(this._height)
    }
  }

  private createArea(): void {
    this.area = new Konva.Rect({
      x: this.abosulePosition.x,
      y: this.abosulePosition.y,
      width: this._width,
      height: this._height,
      stroke: 'blue',
      strokeWidth: 1,
      dash: [2, 5],
    })
    this.area.setZIndex(0)
    this.group.add(this.area)
  }

  private createText(text: string): void {
    this.text = new Konva.Text({
      text,
      align: 'center',
    })

    this.group.add(this.text)
    this.setTextPosition()
  }

  private setCenter(): void {
    this.center = new Vector2(
      this.abosulePosition.x + this._width / 2,
      this.abosulePosition.y + this._height / 2
    )
  }

  private setTextPosition() {
    this.text.setPosition({
      x: this.center.x - this.text.text.length - 16,
      y: this.center.y,
    })
  }

  /**
   *
   * @param width non scaled width
   * @param height non scaled height
   */
  public resize(width: number, height: number): void {
    this._width = width * this.scaleWidth
    this._height = height * this.scaleHeight

    this.area.width(this._width)
    this.area.height(this._height)

    this.setCenter()
    this.setTextPosition()
    this.gap.notifyAreasUpdate()
  }

  // Frames
  public addFrame(frame: Frame): void {
    this.frames.push(frame)
    frame.associateArea(this)
    this._children.push(frame as any)
  }

  public removeFrame(uuid: string): void {
    const frame = this.frames.find((f) => f?.uuid === uuid)
    if (!frame) return

    this.leftFrame = this.leftFrame.filter(
      (f) => f.uuid !== frame?.frameSideLeft?.uuid
    )
    this.rightFrame = this.rightFrame.filter(
      (f) => f.uuid !== frame?.frameSideRight?.uuid
    )
    this.topFrame = this.topFrame.filter(
      (f) => f.uuid !== frame?.frameSideTop?.uuid
    )
    this.bottomFrame = this.bottomFrame.filter(
      (f) => f.uuid !== frame?.frameSideBottom?.uuid
    )

    this.frames = this.frames.filter((f) => f.uuid !== frame.uuid)
    this._children = this._children.filter((f) => f.uuid !== frame.uuid)
    frame.destroy()
  }

  // Frame
  public extractframesBelow(frame: Frame) {
    const frameVectorsTop = frame.frameSideTop.pointsAsVector()
    const frameVectorsLeft = frame.frameSideLeft.pointsAsVector()

    return this.frames.filter((f) => {
      const top = f.frameSideTop.pointsAsVector()
      const left = f.frameSideLeft.pointsAsVector()

      const isTopBelow = top.startInt.y > frameVectorsTop.startInt.y
      const isLeftBelow = left.startInt.x > frameVectorsLeft.startInt.x

      return isTopBelow && isLeftBelow
    })
  }
  public areaSubframesOffsets(frame: Frame) {
    const framesBelow = this.extractframesBelow(frame)

    let offsets = {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    }

    framesBelow.forEach((f) => {
      offsets.top += f?.frameSideTop?.profile?.internalCamera
      offsets.bottom += f?.frameSideBottom?.profile?.internalCamera
      offsets.left += f?.frameSideLeft?.profile?.internalCamera
      offsets.right += f?.frameSideRight?.profile?.internalCamera
    })

    return offsets
  }

  public addDoorWindowOpening(opening: DoorWindowTipupOpeningElement): void {
    this.doorWindowOpenings = opening
    this._children.push(opening as any)
  }

  public removeDoorOpening(uuid: string): void {
    this._children = this._children.filter((o) => o?.uuid !== uuid)
    this.doorWindowOpenings = null
  }

  // Crossbar
  public addCrossBar(crossbar: CrossbarElement, side: SideOptions): void {
    if (side === 'left') {
      this.leftCrossbar = crossbar
      this._children.push(crossbar)
    }
    if (side === 'right') {
      this.rightCrossbar = crossbar
      this._children.push(crossbar)
    }
    if (side === 'top') {
      this.topCrossbar = crossbar
      this._children.push(crossbar)
    }
    if (side === 'bottom') {
      this.bottomCrossbar = crossbar
      this._children.push(crossbar)
    }
  }

  public removeCrossbar(uuid: string, side: SideOptions): void {
    if (side === 'top') {
      this.topCrossbar = null
      this._children = this._children.filter((c: any) => c.uuid !== uuid)
    }

    if (side === 'bottom') {
      this.bottomCrossbar = null
      this._children = this._children.filter((c: any) => c.uuid !== uuid)
    }
    if (side === 'left') {
      this.leftCrossbar = null
      this._children = this._children.filter((c: any) => c.uuid !== uuid)
    }
    if (side === 'right') {
      this.rightCrossbar = null
      this._children = this._children.filter((c: any) => c.uuid !== uuid)
    }
  }

  public move(x: number, y: number): void {
    this._x = x * this.scaleWidth
    this._y = y * this.scaleHeight

    this.abosulePosition = new Vector2(
      this._x + this.gapPosition.x,
      this._y + this.gapPosition.y
    )

    this.area.setPosition({
      x: this.abosulePosition.x,
      y: this.abosulePosition.y,
    })

    this.container?.updatePosition(this.x, this.y)

    this.setCenter()
    this.setTextPosition()

    this.updateArea()
  }

  public moveScaled(xScaled: number, yScaled: number): void {
    this._x = xScaled - this.gap.xScaled
    this._y = yScaled - this.gap.yScaled

    this.area.setPosition({
      x: xScaled,
      y: yScaled,
    })

    this.setCenter()
    this.setTextPosition()

    this.updateArea()
  }

  public displacement(x: number, y: number): void {
    this.move(this.x + x, this.y + y)
  }

  public cloneAssociatedElements(area: AreaModel): void {
    // Add frames
    this.frames.forEach((f) => area.addFrame(f))
  }

  public setClickListener(): Observable<AreaModel | null> {
    this.selectedSubject = new Subject<AreaModel>()

    this.area.addEventListener('click', () => {
      this.selectArea()
    })

    return this.selectedSubject.asObservable()
  }

  public selectArea(): void {
    if (this.area && this.selectedSubject) {
      this.isSelected = !this.isSelected

      if (!this.isSelected) {
        this.area.fill('transparent')
        this.group.setZIndex(1)
      }
      if (this.isSelected) {
        this.area.fill(fillAreaColor)
        this.group.zIndex(this.layer.children.length - 1)
      }

      this.layer?.draw()
      if (this.isSelected) this.selectedSubject.next(this)
      if (!this.isSelected) this.selectedSubject.next(null)
    }
  }

  public unSelectArea(): void {
    this.area?.fill('transparent')
    this.isSelected = false
    this.group.setZIndex(1)
    this.layer.draw()
  }

  public updateArea(): void {
    this.gap.notifyAreasUpdate()
  }
}

export class Area {
  public readonly uuid = uuidGenerator.v4()
  public readonly modelId: number
  public parent: Area | null = null
  public elements: ProfileElement[] = []
  private _width: number = 0
  private _height: number = 0
  private _x: number
  private _y: number

  public verticalContain: Area[] = []
  public horizontalContain: Area[] = []

  public vinculatedAreaModel: AreaModel | null = null

  constructor(params: AreaParams) {
    const {
      parent,
      verticalContain,
      horizontalContain,
      element,
      width,
      height,
      x,
      y,
      modelId,
    } = params

    this.horizontalContain = horizontalContain ?? []
    this.verticalContain = verticalContain ?? []
    this._width = width
    this._height = height
    this._x = x
    this._y = y
    this.modelId = modelId

    this.parent = parent
    this.elements = element ?? []
  }

  get width(): number {
    return this._width
  }

  get height(): number {
    return this._height
  }

  get x(): number {
    return this._x
  }

  get y(): number {
    return this._y
  }

  public horizontalElements(): ProfileElement[] {
    if (!this.elements) return []

    const horizontals = this.elements.filter((e) => {
      return e.isHorizontal()
    })

    return horizontals
  }

  public verticalElements(): ProfileElement[] {
    if (!this.elements) return []
    const verticals = this.elements.filter((e) => {
      return e.isVertical()
    })

    return verticals
  }

  public destroy(): void {
    this.verticalContain.forEach((c) => c.destroy())
    this.horizontalContain.forEach((c) => c.destroy())
    this.parent?.removeFromContain(this)
  }

  public resize(
    width: number,
    height: number,
    moveArea: { x: number; y: number }
  ): void {
    const widthDiff = width - this.width
    const heightDiff = height - this.height

    this._width = width
    this._height = height

    const horizontalLenght = this.horizontalContain.length
    const verticalLenght = this.verticalContain.length

    const partWidth =
      widthDiff / (horizontalLenght === 0 ? 1 : horizontalLenght)
    const partHeight = heightDiff / (verticalLenght === 0 ? 1 : verticalLenght)

    this.horizontalContain.forEach((c, i) => {
      const isFirst = i === 0
      const moveAreaPosition = {
        x: isFirst ? moveArea.x : partWidth * i,
        y: 0,
      }

      const newAreaDimensions = {
        width: c.width + partWidth,
        height: c.height + partHeight,
      }
      c.resize(
        newAreaDimensions.width,
        newAreaDimensions.height,
        moveAreaPosition
      )
    })

    this.verticalContain.forEach((c, i) => {
      const isFirst = i === 0
      const moveAreaPosition = {
        x: 0,
        y: isFirst ? 0 : partHeight * i,
      }
      c.resize(c.width + partWidth, c.height + partHeight, moveAreaPosition)
    })

    this.horizontalElements().forEach((e) => {
      if (!e) return
      const scaleFactor = e.scaleFactor
      const newWidth = e.scaledLenght + widthDiff * scaleFactor
      e.resize(newWidth)

      const { startExt } = e.pointsAsVector()
      const newPoint = {
        x: startExt.x + moveArea.x * scaleFactor,
        y: startExt.y + moveArea.y * scaleFactor,
      }

      e.move(newPoint.x, newPoint.y)

      const isBottom = e.side === 'bottom'

      if (isBottom) {
        e.move(newPoint.x, newPoint.y + heightDiff * scaleFactor)
      }

      const isCrossbar = e instanceof CrossbarElement

      if (isCrossbar) {
        e.move(newPoint.x, newPoint.y + partHeight * scaleFactor)
      }
    })

    this.verticalElements().forEach((e) => {
      if (!e) return
      const scaleFactor = e.scaleFactor
      const newheigt = e.scaledLenght + heightDiff * scaleFactor
      const { startExt } = e.pointsAsVector()

      const newPoin = {
        x: startExt.x + moveArea.x * scaleFactor,
        y: startExt.y + moveArea.y * scaleFactor,
      }
      e.resize(newheigt)
      e.move(newPoin.x, newPoin.y)

      const isRight = e.side === 'right'

      if (isRight) {
        e.move(newPoin.x + widthDiff * scaleFactor, newPoin.y)
      }

      const isCrossbar = e instanceof CrossbarElement

      if (isCrossbar) {
        e.move(newPoin.x + partWidth * scaleFactor, newPoin.y)
      }
    })

    if (!this.vinculatedAreaModel) return

    this.vinculatedAreaModel.resize(width, height)

    this.vinculatedAreaModel.displacement(moveArea.x, moveArea.y)
  }

  public setVerticalContain(contain: Area[]): void {
    this.verticalContain = contain
  }

  public setHorizontalContain(contain: Area[]): void {
    this.horizontalContain = contain
  }

  public addVerticalContain(areas: Area[]): void {
    this.verticalContain = [...this.verticalContain, ...areas]
  }

  public addHorizontalContain(areas: Area[]): void {
    this.horizontalContain = [...this.horizontalContain, ...areas]
  }

  public removeFromContain(area: Area): void {
    this.verticalContain = this.verticalContain.filter(
      (a) => a.uuid !== area.uuid
    )
    this.horizontalContain = this.horizontalContain.filter(
      (a) => a.uuid !== area.uuid
    )
  }

  public removeElements(): void {
    this.elements = []
  }

  public clone(): Area {
    const area = new Area({
      parent: this.parent,
      verticalContain: this.verticalContain,
      horizontalContain: this.horizontalContain,
      element: this.elements,
      width: this.width,
      height: this.height,
      x: this.x,
      y: this.y,
      modelId: this.modelId,
    })

    return area
  }

  public updateWidth(width: number): void {
    this._width = width
  }

  public updateHeight(height: number): void {
    this._height = height
  }

  public updatePosition(x: number, y: number): void {
    this._x = x
    this._y = y
  }

  public addElements(elements: ProfileElement[]): void {
    if (!this.elements) this.elements = []
    this.elements = cleanArray([...this.elements, ...elements])
  }

  public setVinculatedArea(area: AreaModel | null): void {
    this.vinculatedAreaModel = area
  }
}

export interface AreaParams {
  x: number
  y: number
  width: number
  height: number
  verticalContain?: Area[]
  horizontalContain?: Area[]
  element?: ProfileElement[]
  parent?: Area | null
  modelId: number
}
