import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { Color } from '../@shared/@interfaces/color.model'

@Injectable({
  providedIn: 'root',
})
export class ColorService {
  private apiUrl = environment.apiUrl + 'colors'

  constructor(private http: HttpClient) {}

  public async getAll(): Promise<Color[]> {
    return this.http.get<Color[]>(this.apiUrl).toPromise()
  }

  public async createColor(color: Color): Promise<Color> {
    return this.http.post<Color>(this.apiUrl, color).toPromise()
  }

  public async updateColor(color: Color): Promise<Color> {
    return this.http.put<Color>(`${this.apiUrl}/${color.id}`, color).toPromise()
  }

  public async deleteColor(id: number): Promise<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`).toPromise()
  }
}
