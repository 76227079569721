export class User {
  id: number
  name: string
  email: string
  company: string
  createdAt: Date
  updatedAt: Date
  deletedAt: Date
  subcription: Date
  endSubscription: Date
  Cif: string
  credit: number
  role: { id: number; name?: string }
  galisurId: number
  isActive: boolean

  constructor(user: UserDTO) {
    this.id = user.id
    this.name = user.name
    this.email = user.email
    this.subcription = user.subcription
    this.endSubscription = user.endSubcription
    this.Cif = user.Cif
    this.credit = user.credit
    this.role = user.role
    this.galisurId = user.galisurId
    this.isActive = user.isActive
    this.company = user.company
  }

  public isAdmin(): boolean {
    return this.role.id === 1
  }

  public isSubscriptionActive(): boolean {
    //check endSubscription is after today
    return this.endSubscription > new Date()
  }

  public toDto(): UserDTO {
    return {
      id: this.id,
      name: this.name,
      email: this.email,
      company: this.company,
      subcription: this.subcription,
      endSubcription: this.endSubscription,
      Cif: this.Cif,
      credit: this.credit,
      role: this.role,
      galisurId: this.galisurId,
      isActive: this.isActive,
    }
  }
}

export interface UserDTO {
  id: number
  name: string
  email: string
  company: string
  subcription: Date
  endSubcription: Date
  Cif: string
  credit: number
  galisurId: number
  isActive: boolean
  role: { id: number; name?: string }
}
