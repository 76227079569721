export class SerieCreator {
  id?: number
  name: string
  description: string
  barLength: number
  openingOffset: number
  openingOffsetDoor: number
  floorPaneDistance: number
  openingOverlap: number
  millType: {
    id: number
  }
  millVariation: number
  opening: {
    id: number
  }
  unionJonquillo: {
    id: number
  }
  serieOverlap: {
    id: number
  }
  overlapAmount: number
  crystalOverlap: {
    id: number
  }
  heightReferenceLow: {
    id: number
  }
  heightLow: number
  heightReferenceHigh: {
    id: number
  }
  heightHigh: number
  hasRoture: boolean
}
