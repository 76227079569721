import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { Color, SerieColor } from '../@shared/@interfaces/color.model'

@Injectable({
  providedIn: 'root',
})
export class SerieColorService {
  private apiUrl = environment.apiUrl

  constructor(private http: HttpClient) {}

  public getSerieColor(serieId: number): Observable<SerieColor[]> {
    const params = new HttpParams().set('serie', serieId.toString())
    return this.http.get<SerieColor[]>(`${this.apiUrl}serie-colors`, { params })
  }

  public deleteAllSerieColors(serieId: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}serie-colors/${serieId}`)
  }

  public createSerieColors(serieId: number, colors: Color[]): Observable<any> {
    const colorsMapped = {
      colors: colors.map((c) => ({
        color: {
          id: c.id,
        },
      })),
    }

    const params = new HttpParams().set('serie', serieId.toString())

    return this.http.post(`${this.apiUrl}serie-colors/${serieId}`, colorsMapped)
  }

  public serieAviableColors(): Observable<Color[]> {
    return this.http.get<Color[]>(`${this.apiUrl}colors`).pipe(
      map((colors: any[]) => {
        return colors.map((c) => c)
      })
    )
  }
}
