<table class="table table-responsive">
  <thead>
    <tr>
      <th scope="col">Código</th>
      <th scope="col" class="table-header-icons">
        <img
          src="../../../assets/icons/superposition.svg"
          alt="Superposición"
          class="icon"
        />
        <img
          src="../../../assets/icons/cut-variation.svg"
          alt="Variación"
          class="icon"
        />
      </th>

      <th scope="col">Unión</th>
      <th scope="col">
        <fa-icon
          [icon]="faPlus"
          class="icon add"
          (click)="addNewProfile()"
        ></fa-icon>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let profile of associatedProfilesSide; let i = index">
      <th scope="row" style="max-width: 30px">
        <app-profile-select
          width="50"
          [onlyOneProfile]="true"
          [selectedProfile]="profile.profile"
          (selectedProfileEmitter)="setProfile(i, $event)"
        ></app-profile-select>
      </th>

      <td>
        <app-overlaps
          [overlap]="profile.overlap"
          [cutVariation]="profile.cutVariation"
          (overlapsEmitter)="setCutVariationAndOverlap(i, $event)"
        ></app-overlaps>
      </td>
      <td>
        <img [src]="imageUnionUrl(profile.union)" alt="Unión" />
      </td>
      <td>
        <fa-icon
          [icon]="faTrash"
          class="icon trash"
          (click)="removeProfile(i)"
        ></fa-icon>
      </td>
    </tr>
  </tbody>
</table>
