// ANGULAR
import { Injectable } from '@angular/core'

// TOOLS
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal'
import { Observable } from 'rxjs'

// MODELS
import { DialogInstanceModel } from '../@shared/@models/dialog-instance-model'

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modalRef: BsModalRef

  constructor(private modalService: BsModalService) {}

  openModal(dialogInstanceObject: DialogInstanceModel): Observable<any> {
    this.modalRef = this.modalService.show(
      dialogInstanceObject.component,
      dialogInstanceObject.config
    )
    return this.modalRef.content.$response
  }
}
