import { TuiRootModule } from '@taiga-ui/core'
// ANGULAR
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'

import { CommonModule } from '@angular/common'

// APP
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'

// TOOLS
import { NgxSpinnerModule } from 'ngx-spinner'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'

// toastr
import { ToastrModule } from 'ngx-toastr'

// ngx-bootstrap
import { ModalModule } from 'ngx-bootstrap/modal'
import { TabsModule } from 'ngx-bootstrap/tabs'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { TooltipModule } from 'ngx-bootstrap/tooltip'
import { AccordionModule } from 'ngx-bootstrap/accordion'
import { CarouselModule } from 'ngx-bootstrap/carousel'

// COMPONENTS
import { KonvaComponent } from './@components/konva/konva.component'
import { FilesMenuComponent } from './@components/files-menu/files-menu.component'
import { LevelEditorComponent } from './@components/profile-management-editor/level-editor/level-editor.component'
// Layout
import { NavbarComponent } from './@layout/navbar/navbar.component'
// Pages
import { HomePageComponent } from './@pages/home-page/home-page.component'
import { ModelPageComponent } from './@pages/model-page/model-page.component'
// Dialogs
import { NewModelDialogComponent } from './@dialogs/new-model-dialog/new-model-dialog.component'
import { DeleteItemDialogComponent } from './@dialogs/delete-item-dialog/delete-item-dialog.component'
import { ProfileDialogComponent } from './@dialogs/profile-dialog/profile-dialog.component'
import { FrameProfilesDialogComponent } from './@dialogs/frame-profiles-dialog/frame-profiles-dialog.component'
import { FilesMenuOptionsComponent } from './@components/files-menu-options/files-menu-options.component'
import { ProfileManagementComponent } from './@components/profile-management/profile-management.component'

// Material
import { MatTreeModule } from '@angular/material/tree'
import { MatIconModule } from '@angular/material/icon'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { SerieFormComponent } from './@components/serie-form/serie-form.component'
import { FormGroupComponent } from './@shared/@components/form-group/form-group.component'

import { ProfileManagementEditorComponent } from './@components/profile-management-editor/profile-management-editor.component'
import { TechnicalDataComponent } from './@components/profile-management-editor/tabs/technical-data/technical-data.component'
import { ProfileUsesComponent } from './@components/profile-management-editor/tabs/profile-uses/profile-uses.component'
import { ProfileAccesoriesComponent } from './@components/profile-management-editor/tabs/profile-accesories/profile-accesories.component'
import { DxfEditorComponent } from './@components/profile-management-editor/dxf-editor/dxf-editor.component'
import { InputComponent } from './@shared/@components/input/input.component'
import { DropdownInputComponent } from './@shared/@components/dropdown-input/dropdown-input.component'
import { InputAlertComponent } from './@shared/@components/input-alert/input-alert.component'
import { NewAccesoriesCombinationDialogComponent } from './@dialogs/new-accesories-combination/new-accesories-combination.component'
import { NewAccesoriesFormulaComponent } from './@dialogs/new-accesories-formula/new-accesories-formula.component'
import { ProfileCuttingOptimizationComponent } from './@components/profile-management-editor/tabs/profile-cutting-optimization/profile-cutting-optimization.component'
import { AreaFormComponent } from './@components/area-form/area-form.component'
import { NewAreaDialogComponent } from './@dialogs/new-area-dialog/new-area-dialog.component'
import { UnionsColumnComponent } from './@components/unions-column/unions-column.component'
import { CutVariationDialogComponent } from './@dialogs/cut-variation-dialog/cut-variation-dialog.component'
import { CoverGapComponent } from './@components/cover-gap/cover-gap.component'
import { AssociatedProfilesSelectorComponent } from './@components/associated-profiles-selector/associated-profiles-selector.component'
import { CoverGapSideFormComponent } from './@components/cover-gap-side-form/cover-gap-side-form.component'
import { AssociatedProfilesTableComponent } from './@components/associated-profiles-table/associated-profiles-table.component'
import { SchemeSideRectComponent } from './@components/scheme-side-rect/scheme-side-rect.component'
import { ProfileSelectComponent } from './@components/profile-select/profile-select.component'
import { OverlapsComponent } from './@components/overlaps/overlaps.component'
import { ColorTableSelectorComponent } from './@components/color-table-selector/color-table-selector.component'
import { SearchInlineComponent } from './@components/search-inline/search-inline.component'
import { BarlengthResumeTableComponent } from './@components/barlength-resume-table/barlength-resume-table.component'
import { BarlengthResumeDialogComponent } from './@dialogs/barlength-resume-dialog/barlength-resume-dialog.component'
import { OpeningDialogComponent } from './@dialogs/opening-dialog/opening-dialog.component'
import { OpeningImagesGridComponent } from './@components/openings/opening-images-grid/opening-images-grid.component'
import { AccesoriesComponent } from './@pages/accesories/accesories.component'
import { AccesoriesTreeComponent } from './@components/accesories-tree/accesories-tree.component'
import { AccesoriesGroupsComponent } from './@pages/accesories-groups/accesories-groups.component'
import { SeriesTreeComponent } from './@components/series-tree/series-tree.component'
import { AccessorieGroupListComponent } from './@components/accessorie-group-list/accessorie-group-list.component'
import { AccessorieGroupTableComponent } from './@components/accessorie-group-table/accessorie-group-table.component'
import { AddNameDialogComponent } from './@dialogs/add-name-dialog/add-name-dialog.component'
import { JonquilloDialogComponent } from './@dialogs/jonquillo-dialog/jonquillo-dialog.component'
import { SlidingDialogComponent } from './@dialogs/sliding-dialog/sliding-dialog.component'
import { GuillotineDialogComponent } from './@dialogs/guillotine-dialog/guillotine-dialog.component'
import { OpeningTableLeaftComponent } from './@components/openings/opening-table-leaft/opening-table.component'
import { OpeningWindowDoorComponent } from './@components/openings/tabs/opening-window-door/opening-window-door.component'
import { OpeningDoorComponent } from './@components/openings/tabs/opening-door/opening-door.component'
import { OpeningProjectingComponent } from './@components/openings/tabs/opening-projecting/opening-projecting.component'

import { SummaryAccessoriesModelDialogComponent } from './@dialogs/summary-accessories-model-dialog/summary-accessories-model-dialog.component'
import { AddAccessoriesDialogComponent } from './@dialogs/add-accessories-dialog/add-accessories-dialog.component'
import { AddFrameIconItemComponent } from './@components/model-page-icons-sidebar/add-frame-icon-item/add-frame-icon-item.component'
import { AddTipupOpeningIconItemComponent } from './@components/model-page-icons-sidebar/add-tipup-opening-icon-item/add-tipup-opening-icon-item.component'
import { AddCrossbarIconItemComponent } from './@components/model-page-icons-sidebar/add-crossbar-icon-item/add-crossbar-icon-item.component'
import { ViewComponentDirectiveDirective } from './@directives/view-component-directive.directive'

// Modules
import { EntityInformationModule } from './@shared/entity-information/entity-information.module'
import { EntityInformationService } from './@shared/entity-information/entity-information.service'
import { DooeWindowInfoComponent } from './@components/entity-info/dooe-window-info/dooe-window-info.component'
import { FrameInfoComponent } from './@components/entity-info/frame-info/frame-info.component'
import { AddAccessoriesGroupDialogComponent } from './@dialogs/add-accessories-group-dialog/add-accessories-group-dialog.component'
import { CrystalsTreeComponent } from './@components/crystals-tree/crystals-tree.component'
import { CrystalsComponent } from './@pages/crystals/crystals.component'
import { PanelsTreeComponent } from './@components/panels-tree/panels-tree.component'
import { PanelsComponent } from './@pages/panels/panels.component'
import { FormulasTreeComponent } from './@components/formulas-tree/formulas-tree.component'
import { FormulasComponent } from './@pages/formulas/formulas.component'
import { NgxPaginationModule } from 'ngx-pagination'
import { AddJonquilloIconItemComponent } from './@components/model-page-icons-sidebar/add-jonquillo-icon-item/add-jonquillo-icon-item.component'
import { PdfsComponent } from './@pages/pdfs/pdfs.component'
import { JonquilloInfoComponent } from './@components/entity-info/jonquillo-info/jonquillo-info.component'
import { TokenInterceptor } from './@interceptor/token.interceptor'
import { LoadingComponent } from './@components/loading/loading.component'
import { ColorsComponent } from './@pages/colors/colors.component'
import { ModelOptionButtonComponent } from './@components/model-option-button/model-option-button.component'
import { AddAccessoryFrameComponent } from './@components/addAccesory/add-accessory-frame/add-accessory-frame.component'
import { AddAccessoryAreaComponent } from './@components/addAccesory/add-accessory-area/add-accessory-area.component'
import { AddAccessoryTipupopeningComponent } from './@components/addAccesory/add-accessory-tipupopening/add-accessory-tipupopening.component'
import { AddAccessoryCrossbarComponent } from './@components/addAccesory/add-accessory-crossbar/add-accessory-crossbar.component'
import { AddAccessoryJunquilloComponent } from './@components/addAccesory/add-accessory-junquillo/add-accessory-junquillo.component'
import { TuiPaginationModule, TuiTabsModule } from '@taiga-ui/kit'
import { TooltipModule as CustomTooltipModule } from './presentation/shared/tooltip/tooltip.module'
import { ThreeDxfModule } from './presentation/files/pages/profiles/pages/profile/components/three-dxf/three-dxf.module'
import { ProfileModule } from './presentation/files/pages/profiles/pages/profile/profile.module'

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomePageComponent,
    ModelPageComponent,
    NewModelDialogComponent,
    DeleteItemDialogComponent,
    KonvaComponent,
    FrameProfilesDialogComponent,
    FilesMenuComponent,
    FilesMenuOptionsComponent,
    ProfileDialogComponent,
    ProfileManagementComponent,
    ProfileManagementEditorComponent,
    TechnicalDataComponent,
    ProfileUsesComponent,
    ProfileAccesoriesComponent,
    DxfEditorComponent,
    SerieFormComponent,
    FormGroupComponent,
    InputComponent,
    DropdownInputComponent,
    InputAlertComponent,
    NewAccesoriesCombinationDialogComponent,
    NewAccesoriesFormulaComponent,
    ProfileCuttingOptimizationComponent,
    LevelEditorComponent,
    AreaFormComponent,
    NewAreaDialogComponent,
    UnionsColumnComponent,
    CutVariationDialogComponent,
    CoverGapComponent,
    AssociatedProfilesSelectorComponent,
    CoverGapSideFormComponent,
    AssociatedProfilesTableComponent,
    SchemeSideRectComponent,
    ProfileSelectComponent,
    OverlapsComponent,
    ColorTableSelectorComponent,
    SearchInlineComponent,
    BarlengthResumeTableComponent,
    BarlengthResumeDialogComponent,
    OpeningDialogComponent,
    GuillotineDialogComponent,
    OpeningImagesGridComponent,
    AccesoriesComponent,
    AccesoriesTreeComponent,
    AccesoriesGroupsComponent,
    SeriesTreeComponent,
    AccessorieGroupListComponent,
    AccessorieGroupTableComponent,
    AddNameDialogComponent,
    JonquilloDialogComponent,
    SlidingDialogComponent,
    OpeningTableLeaftComponent,
    OpeningWindowDoorComponent,
    OpeningDoorComponent,
    OpeningProjectingComponent,
    SummaryAccessoriesModelDialogComponent,
    AddAccessoriesDialogComponent,
    AddAccessoriesGroupDialogComponent,
    AddFrameIconItemComponent,
    AddTipupOpeningIconItemComponent,
    AddCrossbarIconItemComponent,
    ViewComponentDirectiveDirective,
    DooeWindowInfoComponent,
    FrameInfoComponent,
    CrystalsTreeComponent,
    CrystalsComponent,
    PanelsTreeComponent,
    PanelsComponent,
    FormulasTreeComponent,
    FormulasComponent,
    AddJonquilloIconItemComponent,
    PdfsComponent,
    JonquilloInfoComponent,
    LoadingComponent,
    ColorsComponent,
    ModelOptionButtonComponent,
    AddAccessoryFrameComponent,
    AddAccessoryAreaComponent,
    AddAccessoryTipupopeningComponent,
    AddAccessoryCrossbarComponent,
    AddAccessoryJunquilloComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    FontAwesomeModule,
    FormsModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    ReactiveFormsModule,
    MatTreeModule,
    MatIconModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    BsDropdownModule.forRoot(),
    ToastrModule.forRoot(),
    TooltipModule.forRoot(),
    AccordionModule.forRoot(),
    CarouselModule.forRoot(),
    EntityInformationModule,
    TuiRootModule,
    TuiTabsModule,
    TuiPaginationModule,
    CustomTooltipModule,
    ProfileModule,
  ],
  exports: [ProfileModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
