// ANGULAR
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
// TOOLS
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
// MODELS
import { AreaModel } from '../@shared/@models/modelsElements/area-model'
import { Area } from './konva.service'

@Injectable({
  providedIn: 'root',
})
export class AreaService {
  private apiUrl = environment.apiUrl

  constructor(private http: HttpClient) { }

  getAreas(modelId: number): Observable<Array<AreaModel>> {
    return this.http.get<Array<AreaModel>>(
      environment.apiUrl + 'areas?model=' + modelId
    )
  }
  createArea(model: Area): Observable<any> {
    return this.http.post<any>(environment.apiUrl + 'areas', model)
  }

  updateArea(area: Area, areaId: number): Observable<AreaModel> {
    return this.http.put<AreaModel>(`${this.apiUrl}areas/${areaId}`, area)
  }

  deleteArea(areaId: number): Observable<AreaModel> {
    return this.http.delete<AreaModel>(`${this.apiUrl}areas/${areaId}`)
  }
}
