import Konva from 'konva'
import { Vector2 } from 'three'

import { Frame } from './frame-model'
import { ProfileElement, ProfileElementCreator } from './Profile-model'

export class CovergapSideElement extends ProfileElement {
  private _clip = 0

  public frame: Frame

  public layer: Konva.Layer

  constructor(covergap: ICovergapSide) {
    super(covergap)

    this._overlap = covergap.overlap
    this._clip = covergap.clip
    this._cutVariation = covergap.cutVariation

    this.frame = covergap.frame
    this.layer = covergap.layer
  }

  get overlap() {
    return this._overlap
  }

  get clip() {
    return this._clip
  }

  get cutVariation() {
    return this._cutVariation
  }
}

interface ICovergapSide extends ProfileElementCreator {
  overlap: number
  cutVariation: { id: number; value: number; value2?: number }
  clip: number
  frame: Frame
  layer?: Konva.Layer
}
