import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PanelNode } from 'src/app/@components/panels-tree/panels-tree.component';
import { invalidInputMessages } from 'src/app/@helper/invalidInputShowMessage';
import { PanelService } from 'src/app/@services/panel.service';
import { Panel, PanelCreator } from 'src/app/@shared/@interfaces/panel';

@Component({
  templateUrl: './panels.component.html',
  styleUrls: ['./panels.component.scss']
})
export class PanelsComponent implements OnInit {

  public treeNode: PanelNode[] = []
  public selectedPanel: Panel
  public panels: Panel[] = []

  public form: FormGroup

  public new = false

  constructor(
    private panelService: PanelService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.getPanels()
  }

  private buildForm(): void {
    this.form = this.fb.group({
      name: ['1', [Validators.required]],
      thickness: ['1', [Validators.required]],
    })
  }
  private setForm() {
    this.form.get('name').setValue(this.selectedPanel.name);
    this.form.get('thickness').setValue(this.selectedPanel.thickness);
  }

  private getPanels(): void {
    this.spinner.show()
    this.panelService.getPanels().subscribe((panels) => {
      this.panels = panels
      this.buildTree()
      this.spinner.hide()
    })
  }

  public onSelectPanel(panel: Panel): void {
    this.selectedPanel = panel
    this.setForm();
  }

  private buildTree() {
    this.treeNode =
      this.panels?.map((c) => ({
        panel: c,
      })) ?? []
  }
  public onSearch(criteria: string): void {
    if (!criteria) return this.buildTree()
    this.spinner.show()

    criteria = criteria?.toLowerCase() ?? ''

    let auxTreeNode: PanelNode[] = []

    this.panels.forEach((c) => {
      const name = c.name

      if (name.includes(criteria)) auxTreeNode.push({ panel: c })
    })

    this.treeNode = null
    this.treeNode = auxTreeNode

    this.spinner.hide()
  }

  public addNew() {
    this.form.get('name').setValue('');
    this.form.get('thickness').setValue(1);
    this.new = true;
    this.selectedPanel = null;
  }

  public inputErrorMessages(
    control: string,
    requiredMessage: string
  ): string[] {
    return invalidInputMessages(this.form, control, requiredMessage);
  }

  public onSubmit() {
    const panel = {
      name: this.form.get('name').value,
      thickness: this.form.get('thickness').value,
    };
    if (this.selectedPanel) {
      this.editPanel(panel);
    } else {
      this.newPanel(panel);
    }
  }

  public editPanel(panel: PanelCreator) {
    this.panelService.updatePanel(panel, this.selectedPanel.id)
      .subscribe(
        (response) => {
          this.toastr.success('Se ha actualizado el panel')
          this.getPanels();
          this.selectedPanel = null;
        },
        (err) => {
          this.toastr.error('No ha sido posible actualizar el panel')
        }
      )
  }

  public newPanel(panel) {
    this.panelService.createPanel(panel)
      .subscribe(
        (response) => {
          this.toastr.success('Se ha creado el panel')
          this.getPanels();
          this.new = false;
        },
        (err) => {
          this.toastr.error('No ha sido posible crear el panel')
        }
      )
  }
}
