<div class="wrapper">
  <div class="content">
    <div class="canvas-container">
      <app-disassembly-dxf-editor class="canvas-editor"></app-disassembly-dxf-editor>
    </div>

    <div class="dxf-table-container">
      <table class="table">
        <thead>
          <tr>
            <th colspan="2">Perfiles</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let dxf of dxfsList">
            <td>{{ dxf.profile.description }}</td>
            <td>
              <fa-icon [icon]="faTrash" class="text-red" (click)="onRemoveProfile(dxf)"></fa-icon>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="dxf-table-actions">
        <button class="btn btn-primary" (click)="onAddProfile()">
          Añadir perfil
        </button>
        <button class="btn btn-primary confirm" (click)="confirm()">
          Confirmar
        </button>
      </div>
    </div>
  </div>
</div>
