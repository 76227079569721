<div id="nav-container" class="navbar">
  <div class="navbar-left">
    <ul class="nav nav-tabs nav-OptionHeader" id="myTab" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link active"
          id="home-tab"
          data-toggle="tab"
          role="tab"
          aria-controls="home"
          aria-selected="true"
          [routerLink]="['/home']"
        >
          <img src="/../../../assets/img/icons/HomeIcon.png" />
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="newModel-tab"
          data-toggle="tab"
          href="#newModel"
          role="tab"
          aria-controls="newModel"
          aria-selected="true"
        >
          <img src="./../../../assets/img/icons/FileIcon.png" />
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="editModel-tab"
          data-toggle="tab"
          href="#editModel"
          role="tab"
          aria-controls="editModel"
          aria-selected="true"
        >
          <img src="./../../../assets/img/icons/FolderOpenIcon.png" />
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="models-tab"
          data-toggle="tab"
          href="#models"
          role="tab"
          aria-controls="models"
          aria-selected="false"
          >Modelo</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="files-tab"
          data-toggle="tab"
          role="tab"
          aria-controls="files"
          aria-selected="false"
          [routerLink]="['/files']"
        >
          Archivos
        </a>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent"></div>

    <div
      class="tab-pane fade"
      id="newModel"
      role="tabpanel"
      aria-labelledby="newModel-tab"
    >
      <!-- <h1>Component for Create new Model</h1> -->
    </div>
    <div
      class="tab-pane fade"
      id="editModel"
      role="tabpanel"
      aria-labelledby="editModel-tab"
    >
      <!-- <h1>Component for edits Model</h1> -->
    </div>
    <div
      class="tab-pane fade"
      id="models"
      role="models"
      aria-labelledby="models-tab"
    >
      <!-- <h1>Component for view Models</h1> -->
    </div>
  </div>

  <div class="navbar-right" *ngIf="user">
    <div class="user">
      <div class="user-name">
        <span>{{ user?.name }}</span>
      </div>
      <div class="logout">
        <button class="btn btn-danger" (click)="onLogout()">
          Cerrar sesión
        </button>
      </div>
    </div>
  </div>
</div>
