import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { CutVariationDialogComponent } from 'src/app/@dialogs/cut-variation-dialog/cut-variation-dialog.component'
import { ModalService } from 'src/app/@services/modal.service'
import { DialogInstanceModel } from 'src/app/@shared/@models/dialog-instance-model'

@Component({
  selector: 'app-overlaps',
  templateUrl: './overlaps.component.html',
  styleUrls: ['./overlaps.component.scss'],
})
export class OverlapsComponent implements OnInit {
  @Input() public overlap = 0
  @Input() public cutVariation = {
    id: 1,
    value: 0,
    value2: null,
  }

  @Output() private overlapsEmitter = new EventEmitter<Overlaps>()

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {}

  public openOverlapDialog(): void {
    const dialog = new DialogInstanceModel(CutVariationDialogComponent, {
      initialState: {
        overlap: this.overlap,
        cutVariation: this.cutVariation,
      },
      class: 'modal-dialog-centered modal-lg',
    })

    this.modalService.openModal(dialog).subscribe((response) => {
      this.overlap = response.overlap ?? 0
      this.cutVariation = {
        id: response.id,
        value: response.value,
        value2: response.value2 ?? null,
      }

      this.emit()
    })
  }

  private emit(): void {
    this.overlapsEmitter.emit({
      cutVariation: this.cutVariation,
      overlap: this.overlap,
    })
  }
}

export interface Overlaps {
  overlap: number
  cutVariation: {
    id: number
    value: number
    value2?: number
  }
}
