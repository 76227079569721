import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { UseProfile } from '../@shared/@interfaces/useProfile'

@Injectable({
  providedIn: 'root',
})
export class ProfileUsesService {
  private apitUrl = environment.apiUrl

  constructor(private http: HttpClient) {}

  public getUsesByProfileId(profileId: number): Observable<any> {
    const params = new HttpParams().set('idProfile', profileId.toString())
    return this.http.get(`${this.apitUrl}uses-profiles`, { params })
  }

  public createUse(profileId: number, use: UseProfile): Observable<any> {
    return this.http.post(`${this.apitUrl}uses-profiles/${profileId}`, use)
  }

  public updateUse(id: number, use: UseProfile): Observable<any> {
    return this.http.put(`${this.apitUrl}uses-profiles/${id}`, use)
  }

  public deleteUse(id: number): Observable<any> {
    return this.http.delete(`${this.apitUrl}uses-profiles/${id}`)
  }

  public getProfileUseTypes(): Observable<any> {
    return this.http.get(`${this.apitUrl}uses-types-profile`)
  }

  // TODO: shared service
  public getCutVariations(): Observable<any> {
    return this.http.get(`${this.apitUrl}cut-variations`)
  }
}
