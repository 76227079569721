import { roundUp } from 'src/app/@helper/roundNumber'
import { Vector2 } from 'three/src/math/Vector2'
import { ModelElement } from '../../@interfaces/modelElement'
import { Side, SideOptions } from '../../@types/side.types'
import { Area } from './area-model'
import { ElementSelectable } from './elementSelectable'
import { ProfileElement } from './Profile-model'

export class PseudoFrame extends ElementSelectable implements ModelElement {
  public profilesElements: Side<ProfileElement>

  public sides: ProfileElement[] = []
  public overlapsApplied: Side<number>

  public container: Area

  constructor(public uuid: string) {
    super()
    this.uuid = uuid

    this.profilesElements = {
      top: null,
      bottom: null,
      left: null,
      right: null,
    }
  }

  public setProfile(side: SideOptions, profile: ProfileElement) {
    this.profilesElements[side] = profile
    this.sides.push(profile)
  }

  public setOverlapsApplied(overlaps: Side<number>) {
    this.overlapsApplied = overlaps
  }

  public calculateBarLength(): {
    [k in SideOptions]?: { ext: number; int: number }
  } {
    const angles = this.getCutAngle()

    const lengths = {}
    this.sides.forEach((c) => {
      const scaleFactor = c.scaleFactor
      const length = c?.lenght
      const scaledLength = length * scaleFactor
      const side = c?.side
      if (!length || !side) return

      const scaledTotalThickness = c.profile.thicknessTotal * scaleFactor
      const scaledInternalCamera = c.profile.internalCamera * scaleFactor
      const scaledExternalFin = c.profile.externalFin * scaleFactor
      const scaledInternalFin = c.profile.internalFin * scaleFactor

      const deltaMainInternalCamera =
        scaledInternalCamera * Math.tan(angles[side].mainAngle)
      const deltaSecondInternalCamera =
        scaledInternalCamera * Math.tan(angles[side].secondAngle)

      // Aleta external
      const deltaMainExternalFin =
        scaledExternalFin * Math.tan(angles[side].mainAngle)
      const deltaSecondExternalFin =
        scaledExternalFin * Math.tan(angles[side].secondAngle)

      // Aleta internal
      const deltMainInternalFin =
        scaledInternalFin * Math.tan(angles[side].mainAngle)
      const deltSecondInternalFin =
        scaledInternalFin * Math.tan(angles[side].secondAngle)

      const externalLength =
        (scaledLength +
          deltaMainInternalCamera +
          deltaSecondInternalCamera +
          deltaMainExternalFin +
          deltaSecondExternalFin) /
        scaleFactor

      const internalLength =
        (scaledLength - deltMainInternalFin - deltSecondInternalFin) /
        scaleFactor

      lengths[side] = {
        ext: roundUp(externalLength, 2),
        int: roundUp(internalLength, 2),
      }
    })

    return lengths
  }

  public getCutAngle(): {
    [k in SideOptions]?: { mainAngle: number; secondAngle: number }
  } {
    const angles = {}

    this.sides?.forEach((c) => {
      const side = c?.side
      if (!side) return
      const { startExt, startInt, endInt, endExt } = c.pointsAsVector()

      angles[side] = this.cutAngleOfProfile(
        startExt,
        startInt,
        endInt,
        endExt,
        c.axis
      )
    })

    return angles
  }

  private cutAngleOfProfile(
    startExt: Vector2,
    startInt: Vector2,
    endInt: Vector2,
    endExt: Vector2,
    axis: 'Horizontal' | 'Vertical'
  ) {
    const xMain = Math.abs(startExt.x - startInt.x)
    const yMain = Math.abs(startExt.y - startInt.y)
    let mainAngle = Math.atan(yMain / xMain)

    const xSecond = Math.abs(endExt.x - endInt.x)
    const ySecond = Math.abs(endExt.y - endInt.y)
    let secondAngle = Math.atan(ySecond / xSecond)

    if (axis === 'Vertical') {
      mainAngle = Math.PI / 2 - mainAngle
      secondAngle = Math.PI / 2 - secondAngle
    }

    return {
      mainAngle,
      secondAngle,
    }
  }
}
