import { Component, OnInit } from '@angular/core'

import { JonquilloElement } from 'src/app/@shared/@models/modelsElements/jonquillo-model'

import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { ProfileElement } from 'src/app/@shared/@models/modelsElements/Profile-model'
import { SideOptions } from 'src/app/@shared/@types/side.types'

@Component({
  selector: 'app-jonquillo-info',
  templateUrl: './jonquillo-info.component.html',
  styleUrls: ['./jonquillo-info.component.scss'],
})
export class JonquilloInfoComponent implements OnInit {
  // Inputs
  public initialState: { jonquillo: JonquilloElement }

  // Icons
  public faChevronLeft = faChevronLeft
  public faChevronRight = faChevronRight

  private sides: JonquilloSide[] = []
  public selected: JonquilloSide

  constructor() {}

  ngOnInit(): void {
    const { sidesElement } = this.initialState.jonquillo

    Object.keys(sidesElement).forEach((side: SideOptions, i) => {
      const sideName = this.getSide(side)

      this.sides.push({
        side: sideName,
        profileElement: sidesElement[side],
        selectedIndex: i,
      })
    })

    this.selected = this.sides[0]
  }

  private getSide(side: SideOptions): Sidename {
    switch (side) {
      case 'top':
        return 'Superior'
      case 'bottom':
        return 'Inferior'
      case 'left':
        return 'Izquierdo'
      case 'right':
        return 'Derecho'

      default:
        break
    }
  }

  public next(index: number): void {
    const newIndex = index >= this.sides.length - 1 ? 0 : index + 1
    this.selected = this.sides[newIndex]
  }

  public prev(index: number): void {
    const newIndex = index <= 0 ? this.sides.length - 1 : index - 1
    this.selected = this.sides[newIndex]
  }
}

type Sidename = 'Superior' | 'Inferior' | 'Izquierdo' | 'Derecho'
interface JonquilloSide {
  side: Sidename
  profileElement: ProfileElement
  selectedIndex: number
}
