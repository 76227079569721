import { AssociatedProfile } from 'src/app/@components/associated-profiles-selector/associated-profiles-selector.component'
import { roundUp } from 'src/app/@helper/roundNumber'
import { Frame } from './frame-model'
import { ProfileElement, ProfileElementCreator } from './Profile-model'

export class AssociatedProfileFrame extends ProfileElement {
  public readonly associatedFrame: Frame

  constructor(associatedProfile: AssociatedProfileCreator) {
    super({
      ...associatedProfile,
    })

    this.associatedFrame = associatedProfile.frame
    this.group.addName('associated frame profile')

    this.hide()
  }

  public toObject(): AssociatedProfile {
    return {
      cutVariation: this.cutVariation,
      overlap: this.overlap,
      profile: this.profile,
      union: 1,
    }
  }
}

interface AssociatedProfileCreator extends ProfileElementCreator {
  frame: Frame
}
