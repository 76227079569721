<form class="cutting fade-in" *ngIf="!loading">
  <div class="machine">
    <span class="title">Máquinas</span>
    <div class="machine-content">
      <div class="table-machine">
        <table class="table table-responsive-sm table-hover">
          <thead>
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Posición</th>
            </tr>
          </thead>
          <tbody>
            <tr class="table-row fade-in">
              <td style="padding: 18px">
                <span>Estándar</span>
              </td>
              <td>
                <app-dropdown-input
                  [items]="machinePositions"
                  [title]="getPosition()"
                  (selectItemEmitter)="selecPosition($event)"
                ></app-dropdown-input>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <form class="data" [formGroup]="form">
    <div class="cutting-data">
      <span class="title">Datos de corte</span>
      <app-input label="Tipo de corte" width="max-content">
        <app-dropdown-input
          [items]="cutTypes"
          [title]="getCutType()"
          (selectItemEmitter)="selecCutType($event)"
        ></app-dropdown-input>
      </app-input>

      <app-input label="Redondeo" width="max-content">
        <input
          type="number"
          class="cutting-data-option__input"
          min="0"
          formControlName="rounding"
        />
      </app-input>
      <app-input-alert
        *ngFor="let errorMessage of inputErrorMessages('rounding')"
        [title]="errorMessage"
      ></app-input-alert>

      <app-input label="Barras agrupadas" width="max-content">
        <input
          type="number"
          class="cutting-data-option__input"
          min="0"
          formControlName="groupedBars"
        />
      </app-input>
      <app-input-alert
        *ngFor="let errorMessage of inputErrorMessages('groupedBars')"
        [title]="errorMessage"
      ></app-input-alert>

      <div class="cutting-data-option">
        <div class="cutting-data-option-checkbox">
          <input
            type="checkbox"
            id="open"
            class="cutting-data-option__checkbox"
            formControlName="openPackage"
          />
          <label for="open" class="cutting-data-option__label"
            >Abrir paquete</label
          >
        </div>
      </div>

      <div class="cutting-data-option">
        <div class="cutting-data-option-checkbox">
          <input
            type="checkbox"
            id="discard-between"
            class="cutting-data-option__checkbox"
            formControlName="discardBetweenPieces"
          />
          <label for="discard-between" class="cutting-data-option__label"
            >Descarte en mm entre dos piezas</label
          >
        </div>
        <app-input label="Valor" justifyContent="center">
          <input
            type="number"
            class="cutting-data-option__input"
            min="0"
            formControlName="discardBetweenPiecesValue"
          />
        </app-input>
      </div>
      <app-input-alert
        *ngFor="
          let errorMessage of inputErrorMessages('discardBetweenPiecesValue')
        "
        [title]="errorMessage"
      ></app-input-alert>

      <div class="cutting-data-option">
        <div class="cutting-data-option-checkbox">
          <input
            type="checkbox"
            id="discard-start"
            class="cutting-data-option__checkbox"
            formControlName="discardStartEndBar"
          />
          <label for="discard-start" class="cutting-data-option__label"
            >Descarte inicio-fin barra</label
          >
        </div>
        <app-input label="Valor" justifyContent="center">
          <input
            type="number"
            class="cutting-data-option__input"
            formControlName="discardStartEndBarValue"
          />
        </app-input>
      </div>
      <app-input-alert
        *ngFor="
          let errorMessage of inputErrorMessages('discardStartEndBarValue')
        "
        [title]="errorMessage"
      ></app-input-alert>
    </div>

    <div class="optimization">
      <!-- <span class="title">Parámetros de optimización</span> -->

      <!-- <div class="radio-options">
        <div class="radio-option" *ngFor="let param of optimizationParams">
          <input
            type="radio"
            [id]="param.id"
            name="cutOptimization"
            formControlName="cutOptimization"
            [value]="param.id"
          />
          <label [for]="param.id" class="radio-option__label">{{
            param.name
          }}</label>
        </div>
      </div> -->

      <div class="params">
        <div id="optimus-selection" class="fade-in" *ngIf="isOptimusBar()">
          <!-- Min -->
          <div class="param">
            <app-input label="Min." justifyContent="center">
              <input
                type="number"
                min="0"
                class="param__input"
                formControlName="min"
              />
            </app-input>
            <app-input-alert
              *ngFor="let errorMessage of inputErrorMessages('min')"
              [title]="errorMessage"
            ></app-input-alert>
          </div>

          <!-- Max -->
          <div class="param">
            <app-input label="Max." justifyContent="center">
              <input
                type="number"
                min="0"
                class="param__input"
                formControlName="max"
              />
            </app-input>
            <app-input-alert
              *ngFor="let errorMessage of inputErrorMessages('max')"
              [title]="errorMessage"
            ></app-input-alert>
          </div>

          <!-- Paso -->
          <div class="param">
            <app-input label="Paso" justifyContent="center">
              <input
                type="number"
                min="0"
                class="param__input"
                formControlName="passed"
              />
            </app-input>
            <app-input-alert
              *ngFor="let errorMessage of inputErrorMessages('passed')"
              [title]="errorMessage"
            ></app-input-alert>
          </div>
        </div>

        <div id="multiple-selection" class="fade-in" *ngIf="isMultiple()">
          <!-- Longitud2 -->
          <div class="param">
            <app-input label="Longitud 2" justifyContent="center">
              <input
                type="number"
                min="0"
                class="param__input"
                formControlName="length2"
              />
            </app-input>
            <app-input-alert
              *ngFor="let errorMessage of inputErrorMessages('length2')"
              [title]="errorMessage"
            ></app-input-alert>
          </div>

          <!-- Longitud 3 -->
          <div class="param">
            <app-input label="Longitud 3" justifyContent="center">
              <input
                type="number"
                min="0"
                class="param__input"
                formControlName="length3"
              />
            </app-input>
            <app-input-alert
              *ngFor="let errorMessage of inputErrorMessages('length3')"
              [title]="errorMessage"
            ></app-input-alert>
          </div>

          <!-- Max. Cant. -->
          <div class="param extend">
            <app-input
              label="Máx. cantidad de medidas para la barra"
              justifyContent="center"
            >
              <input
                type="number"
                min="0"
                class="param__input"
                formControlName="maximumNumberMeasures"
              />
            </app-input>
            <app-input-alert
              *ngFor="
                let errorMessage of inputErrorMessages('maximumNumberMeasures')
              "
              [title]="errorMessage"
            ></app-input-alert>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="button">
    <button class="btn btn-primary" (click)="onSubmit()">Aceptar</button>
  </div>
</form>
