import { Component, Input, OnInit } from '@angular/core'
import { SideOptions } from 'src/app/@shared/@types/side.types'

@Component({
  selector: 'app-scheme-side-rect',
  templateUrl: './scheme-side-rect.component.html',
  styleUrls: ['./scheme-side-rect.component.scss'],
})
export class SchemeSideRectComponent implements OnInit {
  @Input() public sides: { [k in SideOptions]: boolean } = {
    top: false,
    bottom: false,
    left: false,
    right: false,
  }

  @Input() public internalSides: { [k in SideOptions]: boolean } = {
    top: false,
    bottom: false,
    left: false,
    right: false,
  }

  @Input() public hasInternal = false

  constructor() {}

  ngOnInit(): void {}
}
