import { IdAndNameModel } from './idAndName.model'

export class ProfileUse {
  public readonly useType: number
  public readonly cutVariation: { id: number }
  public readonly overlap: number
  public readonly value: number
  public readonly useTypeProfile: IdAndNameModel

  constructor(
    useType?: number,
    cutVariation?: { id: number },
    overlap?: number,
    value?: number,
    useTypeProfile?: {
      id: number
      name: string
    }
  ) {
    this.useType = useType ?? 1
    this.cutVariation = cutVariation ?? { id: 1 }
    this.overlap = overlap ?? 0
    this.value = value ?? 0
    this.useTypeProfile = useTypeProfile
  }

  toObjectFormSubmit() {
    return {
      useTypeProfile: {
        id: this.useType,
      },
      cutVariation: {
        id: this.cutVariation,
      },
      overlap: this.overlap,
      value: this.value,
    }
  }
}
