import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PanelService } from 'src/app/@services/panel.service';
import { Panel } from 'src/app/@shared/@interfaces/panel';

export interface PanelNode {
  panel: Panel
  children?: PanelNode[]
}

interface FlatNode {
  expandable: boolean
  name: string
  level: number
}

@Component({
  selector: 'app-panels-tree',
  templateUrl: './panels-tree.component.html',
  styleUrls: ['./panels-tree.component.scss']
})
export class PanelsTreeComponent implements OnInit {

  @Input() public treeNode: PanelNode[] = []
  @Input() public selectedPanel: Panel

  @Output() public searchEmitter = new EventEmitter<string>()
  @Output() public addNewEmitter = new EventEmitter<void>()
  @Output() public selectPanelEmitter = new EventEmitter<Panel>()

  public treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable
  )

  constructor(private router: Router,
    private panelService: PanelService
  ) { }

  ngOnInit(): void { }

  public editNode(panel: Panel): void {
    this.selectPanelEmitter.emit(panel)
  }

  public deleteNode(panel: Panel) {
    this.panelService.deletePanel(panel.id).subscribe((panels) => {
      this.treeNode = this.treeNode.filter((x) => x.panel.id != panel.id);
    })
  }

  public addNew(): void {
    this.addNewEmitter.emit()
  }

  public onSearch(criteria: string): void {
    this.searchEmitter.emit(criteria)
  }

  public back(): void {
    this.router.navigateByUrl('/files')
  }
}
