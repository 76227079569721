<table class="color-table grid">
  <thead class="color-table-head grid">
    <th>Color</th>
    <th>Código</th>
    <th>Descripción</th>
    <th>
      <div class="head-icons button-dropdown">
        <!-- Dropdown -->

        <!-- Dropdown button -->
        <button class="btn btn-primary-outline" (click)="hideShowColors()">
          Añadir Color <fa-icon [icon]="faPlus"></fa-icon>
        </button>

        <!-- Dropdown content -->
        <div class="dropdown-content fade-in" #dropdownContent>
          <div
            class="dropdown-item-color"
            *ngFor="let color of aviableColors"
            (click)="addColor(color)"
          >
            <div
              class="dropdown-item-color__color"
              [ngStyle]="{ backgroundColor: color.code }"
            ></div>
            <span>{{ color.description }}</span>
          </div>
        </div>
      </div>
    </th>
  </thead>
  <tbody class="color-table-body grid">
    <tr
      class="color-table-body-row grid fade-in"
      *ngFor="let color of serieColors"
    >
      <td>
        <div
          class="table-color"
          [ngStyle]="{ backgroundColor: color.code }"
        ></div>
      </td>
      <td>{{ color.code400 }}</td>
      <td>{{ color.description }}</td>
      <td class="head-icons">
        <fa-icon
          [icon]="faTrash"
          class="head-icons__icon delete"
          (click)="removeColor(color)"
        ></fa-icon>
      </td>
    </tr>
  </tbody>
</table>
