<form [formGroup]="searchForm" class="form">
  <label [for]="labelUuid" class="search__label">
    <fa-icon
      [icon]="faSearch"
      class="search__icon"
      [ngClass]="{
        'search-active': searchForm.get('search').value ? true : false
      }"
    ></fa-icon>
  </label>
  <input
    type="checkbox"
    [id]="labelUuid"
    style="display: none"
    formControlName="isActive"
  />
  <input
    type="text"
    [ngClass]="{
      hide: !searchForm.get('isActive').value,
      'input-grow': searchForm.get('isActive').value
    }"
    class="search__input"
    formControlName="search"
  />
</form>
