<div class="content">
  <div class="modal-header">
    <h5 class="modal-title pull-left">
      Selección variación de corte y superposición
    </h5>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="decline()"
    >
      <img src="../../../assets/img/icons/close.svg" alt="Close modal icon" />
    </button>
  </div>

  <div class="modal-body">
    <form class="modal-body-form" [formGroup]="form">
      <div class="modal-item">
        <img
          src="../../../assets/icons/cut-variation.svg"
          alt="variación de corte"
          class="modal-body-form__icon"
        />

        <div class="modal-body-form-inputs">
          <app-input label="Variación" justifyContent="flex-start">
            <app-dropdown-input
              [items]="cutVariations"
              [title]="selectedCutName"
              [buttonWidth]="500"
              (selectItemEmitter)="onCutVariationChange($event)"
            ></app-dropdown-input>
          </app-input>
          <app-input
            label="Valor"
            justifyContent="flex-start"
            *ngIf="showValue()"
          >
            <div class="modal-body-form-inputs__input">
              <input type="number" formControlName="value" />
            </div>
          </app-input>
          <app-input
            label="Valor2"
            justifyContent="flex-start"
            *ngIf="showValue2()"
          >
            <div class="modal-body-form-inputs__input">
              <input type="number" formControlName="value2" />
            </div>
          </app-input>
        </div>
      </div>

      <div class="modal-item">
        <div class="superposition">
          <img
            src="../../../assets/icons/superposition.svg"
            alt="superposición"
            class="modal-body-form__icon icon___superposition"
          />
          <app-input label="Superposición" justifyContent="flex-start">
            <div class="modal-body-form-inputs__input">
              <input type="number" formControlName="overlap" />
            </div>
          </app-input>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="onSubmit()">Aceptar</button>
  </div>
</div>
