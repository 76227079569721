<div class="content">
  <div class="header">
    <h1>Marco</h1>
  </div>

  <button class="btn btn-primary btn-block" (click)="selecteAccessory()">
    Añadir sobre área contenedora
  </button>

  <div class="profiles">
    <h2>Perfiles</h2>

    <div class="profiles" *ngIf="frameProfilesData.length > 0">
      <h3>Marco</h3>
      <div
        class="profile"
        *ngFor="let profile of frameProfilesData"
        (click)="selecteAccessory(profile)"
      >
        <div class="profile-info">
          <span>{{ profile.code }}</span>
          <span>{{ profile.description }}</span>
          <span>{{ profile.ext }}mm</span>
        </div>
        <div class="profile-side">
          <span>{{ profile.side }}</span>
        </div>
      </div>
    </div>

    <div class="profiles" *ngIf="externalCovergapsProfilesData.length > 0">
      <h3>Tapajuntas Exterior</h3>
      <div
        class="profile"
        *ngFor="let profile of externalCovergapsProfilesData"
        (click)="selecteAccessory(profile)"
      >
        <div class="profile-info">
          <span>{{ profile.code }}</span>
          <span>{{ profile.description }}</span>
          <span>{{ profile.ext }}mm</span>
        </div>
        <div class="profile-side">
          <span>{{ profile.side }}</span>
        </div>
      </div>
    </div>

    <div class="profiles" *ngIf="internalCovergapsProfilesData.length > 0">
      <h3>Tapajuntas Interior</h3>
      <div
        class="profile"
        *ngFor="let profile of internalCovergapsProfilesData"
        (click)="selecteAccessory(profile)"
      >
        <div class="profile-info">
          <span>{{ profile.code }}</span>
          <span>{{ profile.description }}</span>
          <span>{{ profile.ext }}mm</span>
        </div>
        <div class="profile-side">
          <span>{{ profile.side }}</span>
        </div>
      </div>
    </div>

    <div class="profiles" *ngIf="associatedProfilesData.length > 0">
      <h3>Perfiles Asociados</h3>
      <div
        class="profile"
        *ngFor="let profile of associatedProfilesData"
        (click)="selecteAccessory(profile)"
      >
        <div class="profile-info">
          <span>{{ profile.code }}</span>
          <span>{{ profile.description }}</span>
          <span>{{ profile.ext }}mm</span>
        </div>
        <div class="profile-side">
          <span>{{ profile.side }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #area></ng-template>

<ng-template #profiles></ng-template>
