import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core'
import { faEdit, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import { ToastrService } from 'ngx-toastr'
import { BsModalRef } from 'ngx-bootstrap/modal'

import { AccessoriesModelService } from 'src/app/@services/accessories-model.service'
import { ModalService } from 'src/app/@services/modal.service'

import { AccessoryModel } from 'src/app/@shared/@interfaces/accessory-model.model'
import { IDialog } from 'src/app/@shared/@interfaces/dialog'
import { DialogInstanceModel } from 'src/app/@shared/@models/dialog-instance-model'

import { AddAccessoriesDialogComponent } from '../add-accessories-dialog/add-accessories-dialog.component'
import { AddAccessoriesGroupDialogComponent } from '../add-accessories-group-dialog/add-accessories-group-dialog.component'
import { accesoryTypeToDescription } from 'src/app/@helper/accesoryTipeToDescription'

@Component({
  selector: 'app-summary-accessories-model-dialog',
  templateUrl: './summary-accessories-model-dialog.component.html',
  styleUrls: ['./summary-accessories-model-dialog.component.scss'],
})
export class SummaryAccessoriesModelDialogComponent implements OnInit, IDialog {
  @Input() private modelId: number
  @Output()
  $response = new EventEmitter<any>()

  public faTimes = faTimes
  public faEdit = faEdit
  public faTrash = faTrash

  public accessoriesModel: AccessoryModel[] = []

  constructor(
    private accessoriesModelService: AccessoriesModelService,
    private modalService: ModalService,
    private modalRef: BsModalRef,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.getAccessoriesModel()
  }

  public getUnit(type: string): string {
    return accesoryTypeToDescription(type ?? 'P')
  }

  private getAccessoriesModel(): void {
    if (this.modelId) {
      this.accessoriesModelService
        .getAccessoriesModel(this.modelId)
        .subscribe((accessoriesModel) => {
          this.accessoriesModel = accessoriesModel
        })
    }
  }

  public openAccesory(): void {
    const accessoriesModelDialog = new DialogInstanceModel(
      AddAccessoriesDialogComponent,
      {
        initialState: {
          modelId: this.modelId,
        },
        class: 'modal-dialog-centered',
      }
    )
    this.modalService.openModal(accessoriesModelDialog).subscribe(() => {
      this.getAccessoriesModel()
    })
  }

  public openGroupAccesory(): void {
    const accessoriesModelDialog = new DialogInstanceModel(
      AddAccessoriesGroupDialogComponent,
      {
        initialState: {
          modelId: this.modelId,
        },
        class: 'modal-dialog-centered',
      }
    )
    this.modalService.openModal(accessoriesModelDialog).subscribe(() => {
      this.getAccessoriesModel()
    })
  }

  public editAccessory(acc: AccessoryModel) {
    if (!acc.accessorySerieGroup) {
      const accessoriesModelDialog = new DialogInstanceModel(
        AddAccessoriesDialogComponent,
        {
          initialState: {
            modelId: this.modelId,
            acc: acc,
            quantity: acc.type ? true : false,
          },
          class: 'modal-dialog-centered',
        }
      )
      this.modalService
        .openModal(accessoriesModelDialog)
        .subscribe((accessory: AccessoryModel) => {
          this.getAccessoriesModel()
        })
    } else {
      const accessoriesModelList = this.accessoriesModel.filter(
        (a) => a?.accessorySerieGroup?.id == acc?.accessorySerieGroup?.id
      )
      const accessoriesModelDialog = new DialogInstanceModel(
        AddAccessoriesGroupDialogComponent,
        {
          initialState: {
            modelId: this.modelId,
            accList: accessoriesModelList,
          },
          class: 'modal-dialog-centered modal-xl',
        }
      )
      this.modalService
        .openModal(accessoriesModelDialog)
        .subscribe((accessory: AccessoryModel) => {
          this.getAccessoriesModel()
        })
    }
  }

  public deleteAccessory(acc: AccessoryModel): void {
    if (acc.accessorySerieGroup) {
      this.accessoriesModelService
        .deleteAccessoriesModelGroup(acc.accessorySerieGroup.id)
        .subscribe(() => {
          this.toastr.success('Se ha borrado el grupo de accesorios')
        })
    } else {
      this.accessoriesModelService
        .deleteAccessoriesModel(acc.id)
        .subscribe(() => {
          this.toastr.success('Se ha borrado el accesorio')
        })
    }
    this.getAccessoriesModel()
  }

  public confirm(): void {
    this.$response.emit()
    this.modalRef.hide()
  }

  public decline(): void {
    this.modalRef.hide()
  }
}
