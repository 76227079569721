import { ProfileElement, ProfileElementCreator } from './Profile-model'

export class DriptrayElement extends ProfileElement {
  constructor(driptray: DriptrayElementCreator) {
    super(driptray)

    this.hide()
  }
}

export interface DriptrayElementCreator extends ProfileElementCreator {}
