<div class="crystals">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white">Cargando...</p>
  </ngx-spinner>

  <div class="tree">
    <app-crystals-tree [treeNode]="treeNode" [selectedAccesory]="selectedCrystal"
      (selectCrystalEmitter)="onSelectCrystal($event)" (searchEmitter)="onSearch($event)" (addNewEmitter)="addNew()">
    </app-crystals-tree>
  </div>

  <div class="content" *ngIf="new || selectedCrystal">
    <form [formGroup]="form" autocomplete="off">
      <h1 class="title">Datos Técnicos</h1>

      <div class="crystal-information">
        <div class="crystal-information__item">
          <app-input label="Nombre" id="name">
            <input type="text" class="form-item__input name" id="name" name="name" placeholder="Nombre"
              formControlName="name" />
          </app-input>
          <app-input-alert *ngFor="
                              let errorMessage of inputErrorMessages(
                                'name',
                                'Debe indicar un nombre'
                              )
                            " [title]="errorMessage"></app-input-alert>
        </div>

        <div class="crystal-information__item">
          <app-input label="Espesor" id="thickness">
            <input type="number" class="form-item__input thickness" id="thickness" name="thickness"
              placeholder="Espesor" formControlName="thickness" />
          </app-input>
          <app-input-alert *ngFor="
                                        let errorMessage of inputErrorMessages(
                                          'thickness',
                                          'Debe indicar un espesor'
                                        )
                                      " [title]="errorMessage"></app-input-alert>
        </div>

        <div class="crystal-information__item">
          <app-input label="Peso" id="weight">
            <input type="number" class="form-item__input weight" id="weight" name="weight" placeholder="Peso"
              formControlName="weight" />
          </app-input>
          <app-input-alert *ngFor="
                                                  let errorMessage of inputErrorMessages(
                                                    'weight',
                                                    'Debe indicar un peso'
                                                  )
                                                " [title]="errorMessage"></app-input-alert>
        </div>

        <div class="crystal-information__item">
          <app-input label="Tipo" name="type">
            <app-dropdown-input [items]="crystalTypes" [title]="getCrystalsType()"
              (selectItemEmitter)="selecPosition($event)"></app-dropdown-input>
          </app-input>
        </div>

        <div class="crystal-information__item"
          *ngIf="(form.get('crystalType').value == 4 || form.get('crystalType').value == 5)">
          <app-input label="Cámara" id="camera">
            <input type="text" class="form-item__input camera" id="camera" name="camera" placeholder="Cámara"
              formControlName="camera" />
          </app-input>
        </div>

        <div class="crystal-information__item">
          <app-input label="Cámara 2" id="camera2" *ngIf="(form.get('crystalType').value == 5)">
            <input type="text" class="form-item__input camera2" id="camera2" name="camera2" placeholder="Cámara"
              formControlName="camera2" />
          </app-input>
        </div>

      </div>
      <div class="d-flex justify-content-center submit-button">
        <button class="btn btn-primary" (click)="onSubmit()">Aceptar</button>
      </div>
    </form>
  </div>
</div>