import { end, start } from '@popperjs/core'
import Konva from 'konva'
import { reflexRespectVerticalLine } from 'src/app/@helper/reflexRespectVerticalLine'
import { rotatePoints } from 'src/app/@helper/rotatePoints'
import { rotateRespectPoint } from 'src/app/@helper/rotateRespectToPoint'
import { roundUp } from 'src/app/@helper/roundNumber'
import { Vector, Vector2 } from 'three'
import { ModelElement } from '../../@interfaces/modelElement'
import { SideOptions } from '../../@types/side.types'
import { AreaModel } from './area-model'
import { DoorWindowLeaftSideElement } from './doorwindow-leaft-side.model'
import { DriptrayElement } from './driptrayElement.model'
import { InversorElement } from './InversorElement.model'
import { JonquilloElement } from './jonquillo-model'
import { ProfileElement } from './Profile-model'

import { PseudoFrame } from './pseudoframe-model'

export class TipupLeaftOpening extends PseudoFrame implements ModelElement {
  private ELEMENT_NAMES = {
    hinge: 'hinge',
    handleDoor: 'handleDoor',
    opening: 'opening',
    oscilo: 'oscilo',
  }

  public readonly openingType: number
  public readonly handledoorHeight: number

  public readonly associatedArea: AreaModel

  public readonly position: Vector2

  public readonly profilesElements: {
    [k in SideOptions]: DoorWindowLeaftSideElement
  }
  public inversor: InversorElement
  public dripTray: DriptrayElement

  public readonly hasHandleDoor: boolean
  public readonly group: Konva.Group

  public jonquillo: JonquilloElement

  private openingTypesAviables: { id: number }[] = []

  constructor(
    uuid: string,
    openingType: number,
    handledoorHeight: number,
    associatedArea: AreaModel,
    profileElements: { [k in SideOptions]: ProfileElement },
    openingTypesAviables: { id: number }[],
    hasHandleDoor = false
  ) {
    super(uuid)

    this.openingTypesAviables = openingTypesAviables
    this.profilesElements = profileElements
    this.associatedArea = associatedArea
    this.openingType = openingType
    this.handledoorHeight = handledoorHeight
    this.hasHandleDoor = hasHandleDoor

    this.group = new Konva.Group().addName('door-window leaft')
    this.setSides()
  }

  public destroy() {
    this.associatedArea.gap.removeArea(this.associatedArea.uuid)
    this.jonquillo?.destroy()
    this.group.destroy()
  }

  public addJonquillo(jonquillo: JonquilloElement): void {
    this.jonquillo = jonquillo
  }
  public removeJonquillo(): void {
    this.jonquillo = null
  }

  private setSides(): void {
    Object.keys(this.profilesElements).forEach((side: SideOptions) => {
      const element = this.profilesElements[side]
      this.sides.push(element)
      this.group.add(element.group)
    })
  }

  public drawOpening(): void {
    this.destroyOpening()
    if (!this.isVerticalOpening()) this.drawHorizontalOpening()
    if (this.isVerticalOpening()) this.drawVerticalOpening()
  }

  private destroyOpening(): void {
    const openings = this.group.children
      .toArray()
      .filter((c) => c.attrs.name === this.ELEMENT_NAMES.opening)
    openings.forEach((o) => o.destroy())
  }

  private drawHorizontalOpening(): void {
    const { top, bottom, left, right } = this.profilesElements

    const topPoints = top.pointsAsVector(top.internalFinDraw.points())
    const bottomPoints = bottom.pointsAsVector(bottom.internalFinDraw.points())
    const rightPoints = right.pointsAsVector(right.internalFinDraw.points())
    const leftPoints = left.pointsAsVector()

    const start = new Vector2(topPoints.startInt.x, topPoints.startInt.y)

    const middle = new Vector2(
      rightPoints.startInt.x,
      bottomPoints.startInt.y -
        Math.abs(rightPoints.startInt.y - rightPoints.endInt.y) / 2
    )

    const end = new Vector2(bottomPoints.startInt.x, bottomPoints.startInt.y)

    const leftOpeningDraw = new Konva.Line({
      points: [start.toArray(), middle.toArray(), end.toArray()].flat(),
      strokeWidth: 0.5,
      stroke: 'black',
    }).addName(this.ELEMENT_NAMES.opening)

    const topVectors = top.pointsAsVector()
    const bottomVectors = bottom.pointsAsVector()
    const leftVectors = left.pointsAsVector()
    const rightvectors = right.pointsAsVector()

    const center = new Vector2(
      leftVectors.startInt.x +
        roundUp(
          Math.abs(rightvectors.startInt.x - leftVectors.startInt.x) / 2,
          2
        ),
      topVectors.startInt.y +
        roundUp(
          Math.abs(bottomVectors.startInt.y - topVectors.startInt.y) / 2,
          2
        )
    )

    let degreeToRotate = 0

    if (this.isOpenRight()) {
      degreeToRotate = 180
    }

    const newPoints = rotatePoints(
      leftOpeningDraw.points(),
      center,
      degreeToRotate
    )
    leftOpeningDraw.points(newPoints)

    if (this.isOpenToOut()) {
      leftOpeningDraw.dash([10, 5])
    }

    if (this.hasHorizontalOscilo()) this.drawOscilo()

    this.group.add(leftOpeningDraw)
  }

  private drawVerticalOpening(): void {
    const { left, top, right } = this.profilesElements
    const leftPoints = left.pointsAsVector(left.internalFinDraw.points())
    const rightPoints = right.pointsAsVector(right.internalFinDraw.points())

    const startPoint = new Vector2(leftPoints.startInt.x, leftPoints.endInt.y)

    const middlePoint = new Vector2(
      leftPoints.startInt.x +
        Math.abs(rightPoints.startInt.x - leftPoints.startInt.x) / 2,
      leftPoints.startInt.y
    )

    const endPoint = new Vector2(rightPoints.startInt.x, rightPoints.endInt.y)

    const points = [
      startPoint.toArray(),
      middlePoint.toArray(),
      endPoint.toArray(),
    ].flat()

    const line = new Konva.Line({
      points,
      strokeWidth: 0.5,
      stroke: 'black',
    }).addName(this.ELEMENT_NAMES.opening)

    if (this.isOpenBottom()) {
      const center = new Vector2(
        middlePoint.x,
        leftPoints.startInt.y +
          Math.abs(leftPoints.startInt.y - leftPoints.endInt.y) / 2
      )

      const newPoints = rotatePoints(points, center, 180)
      line.points(newPoints)
    }

    if (this.isOpenToOut()) {
      line.dash([10, 5])
    }

    this.group.add(line)
  }

  private drawOscilo(): void {
    this.destroyOscilo()
    if (this.hasHorizontalOscilo()) this.drawHorizontalOscilo()
  }

  private drawHorizontalOscilo(): void {
    const { top, bottom } = this.profilesElements

    const botomPoints = bottom.pointsAsVector(bottom.internalFinDraw.points())
    const topPoints = top.pointsAsVector(top.internalFinDraw.points())

    const start = botomPoints.startInt
    const middle = new Vector2(
      topPoints.startInt.x +
        Math.abs(topPoints.startInt.x - topPoints.endInt.x) / 2,
      topPoints.startInt.y
    )
    const end = botomPoints.endInt

    const osciloLine = new Konva.Line({
      points: [start.toArray(), middle.toArray(), end.toArray()].flat(),
      strokeWidth: 0.5,
      stroke: 'black',
    }).addName(this.ELEMENT_NAMES.oscilo)

    this.group.add(osciloLine)
  }

  private destroyOscilo(): void {
    const oscilos = this.group.children
      .toArray()
      .filter((c) => c.attrs.name === this.ELEMENT_NAMES.oscilo)

    oscilos.forEach((oscilo) => {
      oscilo.destroy()
    })
  }

  public drawHandleDoor(): void {
    if (!this.hasHandleDoor) return

    if (this.isOpenToOut()) return

    this.destroyHandleDoor()
    if (!this.isVerticalOpening()) this.drawHandleDoorHorizontal()
    if (this.isVerticalOpening()) this.drawHandleDoorVertical()
  }

  private destroyHandleDoor(): void {
    const handleDoors = this.group.children
      .toArray()
      .filter((c) => c.attrs.name === this.ELEMENT_NAMES.handleDoor)

    handleDoors.forEach((handleDoor) => {
      handleDoor.destroy()
    })
  }

  private drawHandleDoorVertical(): void {
    const { gap } = this.associatedArea

    const { bottom, right, left, top } = this.profilesElements

    const scaleFactor = gap.scaleWidth
    const isLeftOpen = this.isOpenLeft()

    const rightInternalCamertaPosition = right.pointsAsVector()
    const rightExternalFinPosition = right.pointsAsVector(
      right.externalFinDraw.points()
    )

    const leftInternalCamertaPosition = left.pointsAsVector()
    const leftExternalFinPosition = left.pointsAsVector(
      left.externalFinDraw.points()
    )

    const widthLeft = Math.abs(
      leftExternalFinPosition.startInt.x -
        leftInternalCamertaPosition.startInt.x
    )
    const widthRight = Math.abs(
      rightExternalFinPosition.startInt.x -
        rightInternalCamertaPosition.startInt.x
    )

    const offset = (isLeftOpen ? widthRight : widthLeft) / 5

    const baseYPosition =
      bottom.pointsAsVector().startExt.y - this.handledoorHeight * scaleFactor

    const baseXPosition = isLeftOpen
      ? rightInternalCamertaPosition.startInt.x + offset
      : leftExternalFinPosition.startInt.x + offset

    const baseWith = (isLeftOpen ? widthRight : widthLeft) - 2 * offset

    const baseHeigth = baseWith

    const baseHandledoor = new Konva.Rect({
      x: baseXPosition,
      y: baseYPosition,
      width: baseWith,
      height: baseHeigth,
      fill: 'transparent',
      stroke: 'black',
      strokeWidth: 0.5,
    })
    const handledoor = new Konva.Rect({
      x: baseHandledoor.x() + baseWith / 2 - baseWith / 2 / 2,
      y: baseHandledoor.y() + baseHeigth / 2,
      width: baseWith / 2,
      height: baseHeigth + baseHeigth / 3,
      fill: 'black',
    })

    const topVectors = top.pointsAsVector()
    const botomVectors = bottom.pointsAsVector()
    const positionVector = this.isOpenBottom() ? botomVectors : topVectors

    const moveDiff = new Vector2(
      0,
      this.isOpenBottom()
        ? Math.abs(positionVector.startExt.y - positionVector.startInt.y)
        : 0
    )

    const position = new Vector2(
      positionVector.startInt.x +
        Math.abs(positionVector.startInt.x - positionVector.endInt.x) / 2 -
        baseWith / 2,
      positionVector.startExt.y +
        Math.abs(positionVector.startExt.y - positionVector.startInt.y) / 5
    ).add(moveDiff.multiplyScalar(-1))

    baseHandledoor.position({
      x: position.x,
      y: position.y,
    })

    handledoor.position({
      x: position.x + baseWith / 2 - handledoor.width() / 2,
      y: position.y + baseHeigth / 2,
    })

    const group = new Konva.Group()
      .add(baseHandledoor, handledoor)
      .addName(this.ELEMENT_NAMES.handleDoor)

    this.group.add(group)
  }

  private drawHandleDoorHorizontal(): void {
    const { gap } = this.associatedArea
    const { bottom, right, left } = this.profilesElements

    const scaleFactor = gap.scaleWidth
    const isLeftOpen = this.isOpenLeft()

    const rightInternalCamertaPosition = right.pointsAsVector()
    const rightExternalFinPosition = right.pointsAsVector(
      right.externalFinDraw.points()
    )

    const leftInternalCamertaPosition = left.pointsAsVector()
    const leftExternalFinPosition = left.pointsAsVector(
      left.externalFinDraw.points()
    )

    const widthLeft = Math.abs(
      leftExternalFinPosition.startInt.x -
        leftInternalCamertaPosition.startInt.x
    )
    const widthRight = Math.abs(
      rightExternalFinPosition.startInt.x -
        rightInternalCamertaPosition.startInt.x
    )

    const offset = (isLeftOpen ? widthRight : widthLeft) / 5

    const baseYPosition =
      bottom.pointsAsVector().startExt.y - this.handledoorHeight * scaleFactor

    const baseXPosition = isLeftOpen
      ? rightInternalCamertaPosition.startInt.x + offset
      : leftExternalFinPosition.startInt.x + offset

    const baseWith = (isLeftOpen ? widthRight : widthLeft) - 2 * offset

    const baseHeigth = baseWith

    const baseHandledoor = new Konva.Rect({
      x: baseXPosition,
      y: baseYPosition,
      width: baseWith,
      height: baseHeigth,
      fill: 'transparent',
      stroke: 'black',
      strokeWidth: 0.5,
    })
    const handledoor = new Konva.Rect({
      x: baseHandledoor.x() + baseWith / 2 - baseWith / 2 / 2,
      y: baseHandledoor.y() + baseHeigth / 2,
      width: baseWith / 2,
      height: baseHeigth + baseHeigth / 3,
      fill: 'black',
    })

    const group = new Konva.Group()
      .add(baseHandledoor, handledoor)
      .addName(this.ELEMENT_NAMES.handleDoor)

    this.group.add(group)
  }

  public drawHinge(): void {
    this.destroyHinge()
    if (!this.isVerticalOpening()) this.drawHingeHorizontal()
    if (this.isVerticalOpening()) this.drawHingeVertical()
  }

  private destroyHinge(): void {
    const hinges = this.group.children
      .toArray()
      .filter((c) => c.attrs.name === this.ELEMENT_NAMES.hinge)

    if (hinges.length === 0) return

    hinges.forEach((hingeGroup) => {
      hingeGroup.destroy()
    })
  }

  private drawHingeVertical(): void {
    let profile: ProfileElement

    if (this.isOpenTop()) profile = this.profilesElements.bottom
    if (this.isOpenBottom()) profile = this.profilesElements.top

    const { startInt: startExt, endInt: endExt } = profile.pointsAsVector(
      profile.externalFinDraw.points()
    )
    const scaleFactor = profile.scaleFactor
    const length = profile.lenght

    const hingeWidth = 20
    const hingeHeight = 5

    const diffMove = 0.1 * (length * scaleFactor)

    const diffMoveY = this.isOpenBottom() ? -hingeHeight : 0

    const leftHinge = new Konva.Rect({
      x: startExt.x + diffMove,
      y: startExt.y + diffMoveY,
      width: hingeWidth,
      height: hingeHeight,
      fill: 'black',
    })

    const rightHinge = new Konva.Rect({
      x: endExt.x - diffMove,
      y: endExt.y + diffMoveY,
      width: hingeWidth,
      height: hingeHeight,
      fill: 'black',
    })

    const hingeGroup = new Konva.Group()
      .add(leftHinge, rightHinge)
      .addName(this.ELEMENT_NAMES.hinge)

    this.group.add(hingeGroup)
  }

  private drawHingeHorizontal(): void {
    let profile: ProfileElement

    if (this.isOpenLeft()) profile = this.profilesElements.left
    if (!this.isOpenLeft()) profile = this.profilesElements.right

    const { startInt: startExt, endInt: endExt } = profile.pointsAsVector(
      profile.externalFinDraw.points()
    )
    const scaleFactor = profile.scaleFactor
    const length = profile.lenght

    const hingeWidth = 5
    const hingeHeight = 20

    const topHinge = new Konva.Rect({
      x: startExt.x + (this.isOpenLeft() ? -hingeWidth : 0),
      y: startExt.y + 0.1 * (length * scaleFactor),
      width: hingeWidth,
      height: hingeHeight,
      fill: 'black',
    })

    const bottomHinge = new Konva.Rect({
      x: startExt.x + (this.isOpenLeft() ? -hingeWidth : 0),
      y: endExt.y - 0.1 * (length * scaleFactor),
      width: hingeWidth,
      height: hingeHeight,
      fill: 'black',
    })

    const hingeGroup = new Konva.Group()
      .add(topHinge, bottomHinge)
      .addName(this.ELEMENT_NAMES.hinge)

    this.group.add(hingeGroup)
  }

  public isOpenLeft(): boolean {
    const isOpenLeft = [2, 4, 6].includes(this.openingType)
    return isOpenLeft
  }

  public isOpenRight(): boolean {
    const isRight = [1, 3, 5].includes(this.openingType)
    return isRight
  }

  public isOpenToOut(): boolean {
    return [3, 4, 13, 14].includes(this.openingType)
  }

  public isOpenTop(): boolean {
    const isOpenTop = [11, 13].includes(this.openingType)
    return isOpenTop
  }

  public isOpenBottom(): boolean {
    const isOpenBottom = [12, 14].includes(this.openingType)
    return isOpenBottom
  }

  private isVerticalOpening(): boolean {
    return [11, 12, 13, 14].includes(this.openingType)
  }

  public hasOscilo(): boolean {
    return [5, 6, 11, 12, 13, 14].includes(this.openingType)
  }

  private hasHorizontalOscilo(): boolean {
    return [5, 6].includes(this.openingType)
  }

  private onlyOscilo(): boolean {
    return [11, 12, 13, 14].includes(this.openingType)
  }

  public getHandleDoorLateral(): ProfileElement {
    if (!this.hasHandleDoor) return
    if (this.isOpenLeft()) return this.profilesElements.right

    return this.profilesElements.left
  }

  public addDripTray(dripTray: ProfileElement): void {
    this.dripTray = dripTray
    this.group.add(dripTray.group)
  }

  public addInversor(inversor: ProfileElement): void {
    this.inversor = inversor
    this.group.add(inversor.group)
    inversor.group.zIndex(0)
    this.group.zIndex(0)
  }

  public widthInternalCamera(): number {
    const { startExt, endExt } = this.profilesElements.top.pointsAsVector()
    const { scaleFactor } = this.profilesElements.top

    return Math.abs(endExt.x - startExt.x) / scaleFactor
  }
}
