<div class="col-12 row wrapper-home" id="homePage">
  <div class="col-2 button-option-container column" id="modelButtonContainer">
    <div
      class="btn-group-vertical blocks model-home-options"
      data-toggle="buttons"
    >
      <button
        type="button"
        class="btn-outline-primary btn-sm btn-block mainbtn"
        [routerLink]="['/model']"
      >
        Nuevo Modelo
      </button>
      <button
        type="button"
        class="btn-outline-primary btn-sm btn-block mainbtn"
        *ngIf="false"
      >
        Abrir Modelo
      </button>
    </div>
  </div>

  <div class="col-10" id="modelListContainer">
    <!-- seach bar -->
    <div class="col-12 input-SearchBar">
      <div class="input-group">
        <input
          type="search"
          class="form-control seach-bar-container"
          placeholder="Nombre Modelo"
          aria-label="Search"
          aria-describedby="search-addon"
          [(ngModel)]="filterBy"
        />
        <span class="input-group-text border-0" id="search-addon">
          <fa-icon [icon]="faSearch"> </fa-icon>
        </span>
      </div>
    </div>
    <!-- list of model to choose -->
    <div class="row">
      <div
        class="col-2 mt-4 div-models-card"
        *ngFor="let item of filteredModel"
      >
        <div class="card model-card animate__animated animate__fadeIn">
          <div class="card-img-block imagen-container">
            <img
              class="card-img-top"
              [src]="item?.image"
              [alt]="item.modelName"
            />
          </div>
          <div class="card-body pt-0 data-container">
            <div class="card-body-name" [routerLink]="['/pdf', item.model?.id]">
              <h6 class="card-title model-name">{{ item.name }}</h6>
              <p class="card-text">{{ item.createdAt | date: 'yyyy-MM-dd' }}</p>
            </div>
            <div class="card-body-icon">
              <fa-icon
                [icon]="faInfoCircle"
                class="info-icon"
                (click)="seeModelBars(item)"
              ></fa-icon>
              <fa-icon
                [icon]="faTrashAlt"
                class="trash-icon"
                (click)="deleteModel(item)"
              ></fa-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination-center">
      <tui-pagination
        [length]="totalPages"
        [index]="page - 1"
        (indexChange)="onPageChange($event)"
      ></tui-pagination>
    </div>
  </div>

  <div
    class="lateral-window animate__animated animate__fadeInRight"
    #lateralWindow
  >
    <div
      class="content-wrapper animate__animated animate__fadeIn"
      *ngIf="!isLoadingModelBars && selectedModel"
    >
      <div class="header">
        <div class="close-container">
          <fa-icon
            [icon]="faTimes"
            class="close-icon"
            (click)="hideLateralWindow()"
          ></fa-icon>
        </div>
        <div class="title-container">
          <h2>Barras de Modelo: {{ selectedModel.name }}</h2>
        </div>
      </div>

      <div class="content">
        <table class="table">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Cantidad</th>
              <th scope="col">Código</th>
              <th scope="col">Descripción</th>
              <th scope="col">Ext</th>
              <th scope="col">Int</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let bar of modelBars">
              <td>{{ bar.count }}</td>
              <td>{{ bar.element.code }}</td>
              <td>{{ bar.element.name }}</td>
              <td>{{ bar.element.extLength }} mm</td>
              <td>{{ bar.element.intLength }} mm</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="loading-wrapper" *ngIf="isLoadingModelBars">
      <app-loading></app-loading>
      <span>Cargando...</span>
    </div>
  </div>
</div>
