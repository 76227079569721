<div class="table-responsive-sm content fade-in">
  <table class="table">
    <thead>
      <tr>
        <th scope="col">Descripción</th>
        <th scope="col">Superposición</th>
        <th scope="col">Variación de corte</th>
        <th scope="col">Valor</th>
        <th scope="col">
          <form class="table-head-icons" [formGroup]="searchForm">
            <fa-icon
              [icon]="faPlus"
              class="table-head-icons__icon"
              (click)="addUse()"
            ></fa-icon>

            <input
              type="checkbox"
              formControlName="show"
              id="show-uses"
              style="display: none"
            />
            <label for="show-uses" class="table-head-icons__label">
              <fa-icon
                [icon]="faSearch"
                class="table-head-icons__icon"
                [ngClass]="{
                  dirty: searchForm.get('search').value ? true : false
                }"
              ></fa-icon>
            </label>
            <input
              type="text"
              formControlName="search"
              id="search-uses"
              class="table-head-icons__input-search"
              [ngClass]="{
                'animated-grow': searchForm.get('show').value,
                hide: !searchForm.get('show').value
              }"
              (keyup)="searchUses(searchForm.get('search').value)"
            />
          </form>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let use of filteredForm.controls; index as i"
        [formGroup]="use"
        [ngClass]="{ disabled: !isUpdating(use) }"
        class="table-row fade-in"
      >
        <td class="dropdown">
          <app-dropdown-input
            [items]="useTypes"
            [title]="getUseTypeName(use.value.useType)"
            [buttonWidth]="500"
            (selectItemEmitter)="selectUseType(use, $event)"
          ></app-dropdown-input>
        </td>
        <td class="centered">
          <form></form>
          <input
            type="number"
            min="0"
            class="dropdown-input"
            formControlName="overlap"
          />
        </td>
        <td>
          <app-dropdown-input
            [items]="cutVariations"
            [title]="getCutVariationName(use.value.cutVariation)"
            [buttonWidth]="500"
            (selectItemEmitter)="selectCutVariation(use, $event)"
          ></app-dropdown-input>
        </td>
        <td class="centered">
          <input
            type="number"
            min="0"
            class="dropdown-input"
            formControlName="value"
          />
        </td>
        <td>
          <div class="table-head-icons">
            <fa-icon
              [icon]="faEdit"
              class="table-head-icons__icon edit"
              (click)="editUse(use)"
              *ngIf="!isUpdating(use)"
            ></fa-icon>
            <fa-icon
              [icon]="faCheckCircle"
              class="table-head-icons__icon check"
              (click)="updateUse(use, i)"
              *ngIf="isUpdating(use)"
            ></fa-icon>
            <fa-icon
              [icon]="faTrash"
              class="table-head-icons__icon delete"
              (click)="deleteUse(use, use.get('index').value)"
            ></fa-icon>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
