<div class="header-container">
  <div>
    <h3 class="title">
      <button class="btn" (click)="back()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <strong>Grupos de Accesorios</strong>
    </h3>
  </div>
  <app-search-inline (searchEmitter)="onSearch($event)"></app-search-inline>
</div>

<mat-tree
  [dataSource]="treeNode"
  [treeControl]="treeControl"
  *ngIf="treeNode.length > 0"
>
  <mat-tree-node
    *matTreeNodeDef="let node"
    matTreeNodePadding
    class="mat-tree-node"
  >
    <span class="tree-item__code">{{ node.serie.name }}</span>

    <button class="btn" (click)="onSelectSerie(node.serie)">
      <mat-icon
        class="icon"
        [ngClass]="{
          selected: selectedSerie?.id === node?.serie?.id
        }"
      >
        visibility
      </mat-icon>
    </button>
  </mat-tree-node>
</mat-tree>
