<form [formGroup]="areaDimensionsForm" class="dimensions-form">
  <div class="form-row">
    <div class="form-group col-6">
      <label for="width">Ancho</label>
      <div class="input-group">
        <input
          id="width"
          type="number"
          class="form-control"
          formControlName="width"
        />
        <div class="input-group-append">
          <span class="input-group-text">mm</span>
        </div>
      </div>
    </div>

    <div class="form-group col-6">
      <label for="height">Alto</label>
      <div class="input-group">
        <input
          id="height"
          type="number"
          class="form-control"
          formControlName="height"
        />
        <div class="input-group-append">
          <span class="input-group-text">mm</span>
        </div>
      </div>
    </div>
  </div>
</form>
