import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'

import { environment } from 'src/environments/environment'
import { CutOptimizationCreator } from '../@shared/@interfaces/cutOptimization'

@Injectable({
  providedIn: 'root',
})
export class ProfileCuttingOptimizationService {
  private apiUrl = environment.apiUrl

  constructor(private http: HttpClient) {}

  public getCutByProfile(profileId: number): Observable<any> {
    const params = new HttpParams().set('profile', profileId.toString())
    return this.http.get(`${this.apiUrl}cuts-profile`, { params })
  }

  /**
   * Create a new Cut associated with a perfile
   * @param cut new cut Object. Must to have profile assing
   * @returns response from api
   */
  public createCut(cut: CutOptimizationCreator): Observable<any> {
    return this.http.post(`${this.apiUrl}cuts-profile`, cut)
  }

  /**
   * update a cut of a profile
   * @param id cut id
   * @param cut cut Object. Must to have profile assing
   * @returns reponse form api
   */
  public updateCut(id: number, cut: CutOptimizationCreator): Observable<any> {
    return this.http.put(`${this.apiUrl}cuts-profile/${id}`, cut)
  }

  // Dependencies endpoints
  /**
   * optimization params
   */
  public getOptimizationParams(): Observable<any> {
    return this.http.get(`${this.apiUrl}cuts-optimization`)
  }

  /**
   * Cut types for cut-optimization
   */
  public getCutTypes(): Observable<any> {
    return this.http.get(`${this.apiUrl}types-cut`)
  }

  /**
   * Get all machines
   */
  public getMachines(): Observable<any> {
    return this.http.get(`${this.apiUrl}machines`)
  }

  /**
   * Get machine positions
   */
  public getMachinePositions(): Observable<any> {
    return this.http.get(`${this.apiUrl}positions-machine`)
  }
}
