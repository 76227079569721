<div class="technical-data-content fade-in">
  <form class="form" [formGroup]="form">
    <div class="form-group">
      <div class="form-item">
        <label for="thickness" class="form-item__label">Espesor total</label>
        <hr class="form-item__separator" />
        <input
          autocomplete="off"
          type="text"
          id="thickness"
          class="form-item__input"
          formControlName="thicknessTotal"
          (keypress)="decimalNumberValidation($event)"
          (change)="createCotaE(form.get('thicknessTotal').value)"
        />
      </div>
      <app-input-alert
        *ngFor="let errorMessage of invalidInputMessages('thicknessTotal')"
        [title]="errorMessage"
      ></app-input-alert>

      <div class="form-item">
        <label for="heightTotal" class="form-item__label">Altura total</label>
        <hr class="form-item__separator" />
        <input
          autocomplete="off"
          type="text"
          id="heightTotal"
          class="form-item__input"
          formControlName="heightTotal"
          (keypress)="decimalNumberValidation($event)"
        />
      </div>
      <app-input-alert
        *ngFor="let errorMessage of invalidInputMessages('heightTotal')"
        [title]="errorMessage"
      ></app-input-alert>

      <div class="form-item-with-letter">
        <div class="form-item">
          <label for="externalFin" class="form-item__label">
            Aleta externa
          </label>
          <hr class="form-item__separator" />
          <input
            autocomplete="off"
            type="text"
            id="externalFin"
            class="form-item__input"
            formControlName="externalFin"
            (keypress)="decimalNumberValidation($event)"
            (change)="createCotaA(form.get('externalFin').value)"
          />
        </div>

        <div class="form-item-with-letter__letter" (click)="createCotaA()">
          A
        </div>
      </div>
      <app-input-alert
        *ngFor="let errorMessage of invalidInputMessages('externalFin')"
        [title]="errorMessage"
      ></app-input-alert>

      <div class="form-item-with-letter">
        <div class="form-item">
          <label for="externalTooth" class="form-item__label"
            >Diente externo</label
          >
          <hr class="form-item__separator" />
          <input
            autocomplete="off"
            type="text"
            id="externalTooth"
            class="form-item__input"
            formControlName="externalTooth"
            (keypress)="decimalNumberValidation($event)"
            (change)="createCotaB(form.get('externalTooth').value)"
          />
        </div>

        <div class="form-item-with-letter__letter" (click)="createCotaB()">
          B
        </div>
      </div>
      <app-input-alert
        *ngFor="let errorMessage of invalidInputMessages('externalTooth')"
        [title]="errorMessage"
      ></app-input-alert>

      <div class="form-item-with-letter">
        <div class="form-item">
          <label for="internalFin" class="form-item__label"
            >Aleta interna</label
          >
          <hr class="form-item__separator" />
          <input
            autocomplete="off"
            type="text"
            id="internalFin"
            class="form-item__input"
            formControlName="internalFin"
            (keypress)="decimalNumberValidation($event)"
            (change)="createCotaC(form.get('internalFin').value)"
          />
        </div>

        <div class="form-item-with-letter__letter" (click)="createCotaC()">
          C
        </div>
      </div>
      <app-input-alert
        *ngFor="let errorMessage of invalidInputMessages('internalFin')"
        [title]="errorMessage"
      ></app-input-alert>

      <div class="form-item-with-letter">
        <div class="form-item">
          <label for="internalTooth" class="form-item__label"
            >Diente interno</label
          >
          <hr class="form-item__separator" />
          <input
            autocomplete="off"
            type="text"
            id="internalTooth"
            class="form-item__input"
            formControlName="internalTooth"
            (keypress)="decimalNumberValidation($event)"
            (change)="createCotaD(form.get('internalTooth').value)"
          />
        </div>

        <div class="form-item-with-letter__letter" (click)="createCotaD()">
          D
        </div>
      </div>
      <app-input-alert
        *ngFor="let errorMessage of invalidInputMessages('internalTooth')"
        [title]="errorMessage"
      ></app-input-alert>

      <div class="form-item-with-letter">
        <div class="form-item">
          <label for="internalCamera" class="form-item__label"
            >Cámara interna</label
          >
          <hr class="form-item__separator" />
          <input
            autocomplete="off"
            type="number"
            min="0"
            id="internalCamera"
            class="form-item__input"
            formControlName="internalCamera"
          />
        </div>

        <div class="form-item-with-letter__letter">E</div>
      </div>
      <app-input-alert
        *ngFor="let errorMessage of invalidInputMessages('internalCamera')"
        [title]="errorMessage"
      ></app-input-alert>

      <div class="form-item-with-letter">
        <div class="form-item">
          <label for="correction" class="form-item__label">Corrección</label>
          <hr class="form-item__separator" />
          <input
            autocomplete="off"
            type="number"
            min="0"
            id="correction"
            class="form-item__input"
            formControlName="correction"
          />
        </div>

        <div class="form-item-with-letter__letter">F</div>
      </div>
      <app-input-alert
        *ngFor="let errorMessage of invalidInputMessages('correction')"
        [title]="errorMessage"
      ></app-input-alert>

      <div class="form-item-with-letter">
        <div class="form-item">
          <label for="thicknessCrystal" class="form-item__label"
            >Espesor col. cristal</label
          >
          <hr class="form-item__separator" />
          <input
            autocomplete="off"
            type="number"
            min="0"
            id="thicknessCrystal"
            class="form-item__input"
            formControlName="thicknessCrystal"
          />
        </div>
        <div class="form-item-with-letter__letter">G</div>
      </div>
      <app-input-alert
        *ngFor="let errorMessage of invalidInputMessages('thicknessCrystal')"
        [title]="errorMessage"
      ></app-input-alert>
    </div>

    <div class="form-group">
      <div class="form-item">
        <label for="longitude" class="form-item__label"
          >Longuitud habitual</label
        >
        <hr class="form-item__separator" />
        <input
          autocomplete="off"
          type="number"
          min="0"
          id="longitude"
          class="form-item__input"
          formControlName="longitude"
        />
      </div>
      <app-input-alert
        *ngFor="let errorMessage of invalidInputMessages('longitude')"
        [title]="errorMessage"
      ></app-input-alert>

      <div class="form-item">
        <label for="kg" class="form-item__label">kg/m</label>
        <hr class="form-item__separator" />
        <input
          autocomplete="off"
          type="number"
          min="0"
          id="kg"
          class="form-item__input"
          formControlName="kg"
        />
      </div>

      <app-input-alert
        *ngFor="let errorMessage of invalidInputMessages('kg')"
        [title]="errorMessage"
      ></app-input-alert>
    </div>

    <div class="form-group">
      <div class="form-item">
        <label for="material" class="form-item__label">Ensamblado</label>
        <hr class="form-item__separator" />
        <div class="btn-group dropdown-group" dropdown>
          <button
            dropdownToggle
            type="button"
            class="btn btn-primary dropdown-toggle dropdown-button"
            aria-controls="dropdown-basic"
          >
            <div class="button-title">
              <span>{{ selectedAssembly.name }}</span>
            </div>
            <span class="caret"></span>
          </button>
          <ul
            id="dropdown-basic"
            *dropdownMenu
            class="dropdown-menu"
            role="menu"
            aria-labelledby="button-basic"
          >
            <li
              role="menuitem"
              class="dropdown-item"
              *ngFor="let assembly of assemblies"
              (click)="onSelectAssembly(assembly)"
            >
              <a class="dropdown-item">{{ assembly.name }}</a>
            </li>
          </ul>
        </div>
      </div>
      <app-input-alert
        *ngIf="isInvalidAssembly(selectedAssembly)"
        title="Debe seleccionar un ensamblado"
      ></app-input-alert>

      <div class="form-item">
        <label for="material" class="form-item__label">Material</label>
        <hr class="form-item__separator" />

        <div class="btn-group dropdown-group" dropdown>
          <button
            dropdownToggle
            type="button"
            class="btn btn-primary dropdown-toggle dropdown-button"
            aria-controls="dropdown-basic"
          >
            <div class="button-title">
              <span>{{ selectedMaterial.name }}</span>
            </div>
            <span class="caret"></span>
          </button>
          <ul
            id="dropdown-basic"
            *dropdownMenu
            class="dropdown-menu"
            role="menu"
            aria-labelledby="button-basic"
          >
            <li
              role="menuitem"
              class="dropdown-item"
              *ngFor="let material of materials"
              (click)="onSelectMaterial(material)"
            >
              <a class="dropdown-item">{{ material.name }}</a>
            </li>
          </ul>
        </div>
      </div>
      <app-input-alert
        *ngIf="isInvalidMaterial(selectedMaterial)"
        title="Debe seleccionar un material"
      ></app-input-alert>
    </div>
  </form>

  <div class="rigth-col">
    <div class="sketch-container">
      <app-dxf-editor
        #dxfEditor
        [$profileParsedDxf]="profileParsedDxf"
        (dxfFileEmitter)="dxfFileEmitter($event)"
        (closestVerticeChange)="onClosestVerticeChange($event)"
      >
        <app-level-editor
          [closestVertice$]="closestVertice$"
        ></app-level-editor>
      </app-dxf-editor>
    </div>

    <form [formGroup]="form" class="form-checkbox">
      <div class="form-checkbox-item">
        <input type="checkbox" id="reversible" formControlName="reversible" />
        <label for="reversible">Reversible</label>
      </div>

      <div class="form-checkbox-item">
        <input type="checkbox" id="thermalCut" formControlName="thermalCut" />
        <label for="thermalCut">Corte térmico</label>
      </div>

      <div class="form-checkbox-item">
        <input type="checkbox" id="clipped" formControlName="clipped" />
        <label for="clipped">Clipado</label>
      </div>
    </form>

    <div class="submit-button">
      <button class="btn btn-primary" (click)="onSubmit()">Aceptar</button>
    </div>
  </div>
</div>
