import { Component, EventEmitter, OnInit } from '@angular/core'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { ToastrService } from 'ngx-toastr'
import { AddAccessoriesGroupDialogComponent } from 'src/app/@dialogs/add-accessories-group-dialog/add-accessories-group-dialog.component'
import { NewAccesoriesCombinationDialogComponent } from 'src/app/@dialogs/new-accesories-combination/new-accesories-combination.component'
import { NewAccesoriesFormulaComponent } from 'src/app/@dialogs/new-accesories-formula/new-accesories-formula.component'
import { asyncForEach } from 'src/app/@helper/asyncForEach'
import {
  AccessoriesModelService,
  CreateAccessoryModelRequest,
} from 'src/app/@services/accessories-model.service'
import { ModalService } from 'src/app/@services/modal.service'
import {
  Accesory,
  AccesoryWithFormula,
} from 'src/app/@shared/@interfaces/accesory'
import { IDialog } from 'src/app/@shared/@interfaces/dialog'
import { DialogInstanceModel } from 'src/app/@shared/@models/dialog-instance-model'
import { TipupLeaftOpening } from 'src/app/@shared/@models/modelsElements/door-window-leaft-model'
import { DoorWindowTipupOpeningElement } from 'src/app/@shared/@models/modelsElements/door-window-tipupOpening-model'
import { Gap } from 'src/app/@shared/@models/modelsElements/gap-model'
import { ProfileElement } from 'src/app/@shared/@models/modelsElements/Profile-model'

@Component({
  selector: 'app-add-accessory-tipupopening',
  templateUrl: './add-accessory-tipupopening.component.html',
  styleUrls: ['./add-accessory-tipupopening.component.scss'],
})
export class AddAccessoryTipupopeningComponent implements OnInit, IDialog {
  public opening: DoorWindowTipupOpeningElement
  public gap: Gap
  public isAccessoryGroup = false

  public leafs: TipupLeaftOpening[] = []
  public selectedLeaf: TipupLeaftOpening | null
  public leafProfiles: ProfileData[] = []
  public openingProfiles: ProfileData[] = []

  $response: EventEmitter<any> = new EventEmitter()

  // Tabs
  public activeTabIndex = 0

  constructor(
    private modalRef: BsModalRef,
    private modalService: ModalService,
    private accessoryService: AccessoriesModelService,
    private toastr: ToastrService
  ) {}

  confirm(): void {}

  decline(): void {}

  ngOnInit(): void {
    this.setLeafs()
    this.setOpeningProfiles()
  }

  public async onAddOverOpening(): Promise<void> {
    const area = this.opening.container

    const entities = [
      {
        type: 'Area',
        entity: area.uuid,
      },
      {
        type: 'Aperture',
        entity: this.opening.id,
      },
    ]

    if (this.isAccessoryGroup) {
      return this.onAccesoryGroup(entities, { type: 'Area', entity: area.uuid })
    }
    const acc = await this.openAccessoryDialog()
    const { accesory, formula } = await this.openFormulaDialog(acc, 'Aperture')

    const request: CreateAccessoryModelRequest = {
      entities: entities,
      accessory: {
        codeRef: accesory.codRef,
        family: accesory.family,
      },
      formula: formula.function,
      model: this.gap.modelId,
      type: 'Area',
      typeId: area.uuid,
    }

    this.accessoryService.setAccessoryModelToEntity(request).subscribe({
      next: (res) => {
        this.toastr.success('Accesorio añadido')
        this.modalRef.hide()
      },
    })
  }

  public async onAddOverOpeningContainer(): Promise<void> {
    const area = this.opening.container
    const entities = [
      {
        type: 'Area',
        entity: area.uuid,
      },
    ]

    if (this.isAccessoryGroup) {
      return this.onAccesoryGroup(entities, { type: 'Area', entity: area.uuid })
    }

    const acc = await this.openAccessoryDialog()
    const { accesory, formula } = await this.openFormulaDialog(acc, 'Area')

    const request: CreateAccessoryModelRequest = {
      entities,
      accessory: {
        codeRef: accesory.codRef,
        family: accesory.family,
      },
      formula: formula.function,
      model: this.gap.modelId,
      type: 'Area',
      typeId: area.uuid,
    }

    this.accessoryService.setAccessoryModelToEntity(request).subscribe({
      next: (res) => {
        this.toastr.success('Accesorio añadido')
        this.modalRef.hide()
      },
    })
  }

  public async onAddOverOpeningProfile(profile: ProfileData): Promise<void> {
    const area = this.opening.container

    const entities = [
      {
        type: 'Area',
        entity: area.uuid,
      },
      {
        type: 'Profile',
        entity: profile.uuid,
      },
    ]

    if (this.isAccessoryGroup) {
      return this.onAccesoryGroup(entities, {
        type: 'Profile',
        entity: profile.uuid,
      })
    }

    const acc = await this.openAccessoryDialog()
    const { accesory, formula } = await this.openFormulaDialog(acc, 'Profile')

    const request: CreateAccessoryModelRequest = {
      entities: entities,
      accessory: {
        codeRef: accesory.codRef,
        family: accesory.family,
      },
      formula: formula.function,
      model: this.gap.modelId,
      type: 'Profile',
      typeId: profile.uuid,
    }

    this.accessoryService.setAccessoryModelToEntity(request).subscribe({
      next: (res) => {
        this.toastr.success('Accesorio añadido')
        this.modalRef.hide()
      },
    })
  }

  public async onAddOverLeaf(): Promise<void> {
    const leaf = this.selectedLeaf

    if (!leaf) return

    const area = leaf.container

    const entities = [
      {
        type: 'Area',
        entity: area.uuid,
      },
      {
        type: 'Leaf',
        entity: leaf.uuid,
      },
    ]

    if (this.isAccessoryGroup) {
      return this.onAccesoryGroup(entities, { type: 'Leaf', entity: leaf.uuid })
    }

    const acc = await this.openAccessoryDialog()
    const { accesory, formula } = await this.openFormulaDialog(acc, 'Leaf')

    const request: CreateAccessoryModelRequest = {
      entities: entities,
      accessory: {
        codeRef: accesory.codRef,
        family: accesory.family,
      },
      formula: formula.function,
      model: this.gap.modelId,
      type: 'Leaf',
      typeId: leaf.uuid,
    }

    this.accessoryService.setAccessoryModelToEntity(request).subscribe({
      next: (res) => {
        this.toastr.success('Accesorio añadido')
        this.modalRef.hide()
      },
    })
  }

  public async onAddOverLeafContainer(): Promise<void> {
    const leaf = this.selectedLeaf
    const area = leaf?.container
    if (!area) return

    const entities = [
      {
        type: 'Area',
        entity: area.uuid,
      },
    ]

    if (this.isAccessoryGroup) {
      return this.onAccesoryGroup(entities, { type: 'Area', entity: area.uuid })
    }

    const acc = await this.openAccessoryDialog()
    const { accesory, formula } = await this.openFormulaDialog(acc, 'Area')

    const request: CreateAccessoryModelRequest = {
      entities: entities,
      accessory: {
        codeRef: accesory.codRef,
        family: accesory.family,
      },
      formula: formula.function,
      model: this.gap.modelId,
      type: 'Area',
      typeId: area.uuid,
    }

    this.accessoryService.setAccessoryModelToEntity(request).subscribe({
      next: (res) => {
        this.toastr.success('Accesorio añadido')
        this.modalRef.hide()
      },
    })
  }

  public async onAddOverLeafProfile(profile: ProfileData): Promise<void> {
    const entities = [
      {
        type: 'Profile',
        entity: profile.uuid,
      },
    ]

    if (this.isAccessoryGroup) {
      return this.onAccesoryGroup(entities, {
        type: 'Profile',
        entity: profile.uuid,
      })
    }

    const acc = await this.openAccessoryDialog()
    const { accesory, formula } = await this.openFormulaDialog(acc, 'Profile')

    const request: CreateAccessoryModelRequest = {
      entities: entities,
      accessory: {
        codeRef: accesory.codRef,
        family: accesory.family,
      },
      formula: formula.function,
      model: this.gap.modelId,
      type: 'Profile',
      typeId: profile.uuid,
    }

    this.accessoryService.setAccessoryModelToEntity(request).subscribe({
      next: (res) => {
        this.toastr.success('Accesorio añadido')
        this.modalRef.hide()
      },
    })
  }

  public async onAddAccessory(): Promise<void> {
    const area = this.opening.container
    const entities = [
      {
        type: 'Area',
        entity: area.uuid,
      },
    ]

    if (this.isAccessoryGroup) {
      return this.onAccesoryGroup(entities, { type: 'Area', entity: area.uuid })
    }

    const acc = await this.openAccessoryDialog()
    const { accesory, formula } = await this.openFormulaDialog(acc, 'Area')

    const request: CreateAccessoryModelRequest = {
      entities,
      accessory: {
        codeRef: accesory.codRef,
        family: accesory.family,
      },
      formula: formula.function,
      model: this.gap.modelId,
      type: 'Area',
      typeId: area.uuid,
    }

    this.accessoryService.setAccessoryModelToEntity(request).subscribe({
      next: (res) => {
        this.toastr.success('Accesorio añadido')
        this.modalRef.hide()
      },
    })
  }

  private openAccessoryDialog(): Promise<Accesory> {
    const newDialog = new DialogInstanceModel(
      NewAccesoriesCombinationDialogComponent,
      {
        initialState: {
          accesory: 'Test',
        },
        // class: 'modal-dialog-centered',
      }
    )

    return new Promise((resolve, reject) => {
      this.modalService.openModal(newDialog).subscribe(
        async (accesory: Accesory) => {
          resolve(accesory)
        },
        (err) => {}
      )
    })
  }

  private openFormulaDialog(
    accesory: Accesory,
    type: 'Area' | 'Profile' | 'Leaf' | 'Aperture'
  ): Promise<AccesoryWithFormula> {
    const formulaDialog = new DialogInstanceModel(
      NewAccesoriesFormulaComponent,
      {
        initialState: {
          accesory: accesory,
          type: type,
        },
      }
    )

    return new Promise((resolve, reject) => {
      this.modalService
        .openModal(formulaDialog)
        .subscribe((acc: AccesoryWithFormula) => {
          resolve(acc)
        })
    })
  }

  private setOpeningProfiles(): void {
    const { doorStepElement } = this.opening

    if (!doorStepElement) return

    const { uuid, profile } = doorStepElement
    const { code, description } = profile
    const { ext, int } = doorStepElement.calculateLength()

    this.openingProfiles = [
      {
        uuid,
        code,
        description,
        ext,
        int,
        side: 'Umbral',
      },
    ]
  }

  private setLeafs(): void {
    this.leafs = this.opening.leafts
    this.selectedLeaf = this.leafs[0]
    this.setLeafProfiles()
  }

  public onSelectLeaf(leaf: TipupLeaftOpening): void {
    this.selectedLeaf = leaf
    this.setLeafProfiles()
  }

  public setLeafProfiles(): void {
    const { profilesElements, dripTray, inversor } = this.selectedLeaf

    const profiles: ProfileData[] = Object.keys(profilesElements).map(
      (p: string) => {
        const profile: ProfileElement = profilesElements[p]
        const { uuid, profile: pInfo, side } = profile
        const { code, description } = pInfo
        const { ext, int } = profile.calculateLength()

        return {
          uuid,
          code,
          description,
          ext,
          int,
          side,
        }
      }
    )

    if (dripTray) {
      const { uuid, profile: pInfo, side } = dripTray
      const { code, description } = pInfo
      const { ext, int } = dripTray.calculateLength()

      profiles.push({
        uuid,
        code,
        description,
        ext,
        int,
        side: 'canaleta',
      })
    }

    if (inversor) {
      const { uuid, profile: pInfo, side } = inversor
      const { code, description } = pInfo
      const { ext, int } = inversor.calculateLength()

      profiles.push({
        uuid,
        code,
        description,
        ext,
        int,
        side: 'inversor',
      })
    }

    this.leafProfiles = profiles
  }

  public async onAccesoryGroup(
    entities: { type: string; entity: string }[],
    typeInfo: { type: 'Area' | 'Profile' | 'Leaf'; entity: string }
  ): Promise<void> {
    const dialog = new DialogInstanceModel(
      AddAccessoriesGroupDialogComponent,
      {}
    )

    this.modalService.openModal(dialog).subscribe({
      next: async (acc: any) => {
        const { accessories } = acc
        if (!accessories) return this.modalRef.hide()

        await asyncForEach(accessories, async (acc: any) => {
          const request: CreateAccessoryModelRequest = {
            entities,
            accessory: {
              codeRef: acc.codRef,
              family: acc.family,
            },
            formula: acc.formula,
            model: this.gap.modelId,
            type: typeInfo.type,
            typeId: typeInfo.entity,
          }

          await this.accessoryService
            .setAccessoryModelToEntity(request)
            .toPromise()
        }).then(() => {
          this.toastr.success(`Accesorios agregados correctamente`)
          this.modalRef.hide()
        })
      },
      error: (err) => {
        console.log(err)
      },
    })
  }
}

interface ProfileData {
  code: string
  description: string
  side: string
  ext: number
  int: number
  uuid: string
}
