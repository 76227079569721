// ANGULAR
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms'

// TOOLS
import { BsModalRef } from 'ngx-bootstrap/modal'

// MODELS
import { NewModelModel } from 'src/app/@shared/@models/new-model-model'

// SERVICES
import { SeriesService } from 'src/app/@services/series.service'
import { ModelService } from './../../@services/model.service'
import { AreaService } from 'src/app/@services/area.service'
import { AreaModel } from 'src/app/@shared/@models/modelsElements/area-model'

@Component({
  selector: 'app-new-model-dialog',
  templateUrl: './new-model-dialog.component.html',
  styleUrls: ['./new-model-dialog.component.scss'],
})
export class NewModelDialogComponent implements OnInit {
  public series: Array<any>
  public dimensionsForm: FormGroup
  public newModelObject: NewModelModel
  public areaModelObject: AreaModel

  @Output()
  $response: EventEmitter<any> = new EventEmitter<any>()

  constructor(
    public modalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private seriesService: SeriesService,
    private areaService: AreaService
  ) {
    this.series = new Array<any>()
    this.newModelObject = new NewModelModel()
  }

  ngOnInit(): void {
    this.dimensionsFormInstance()
    this.getSeries()
  }

  dimensionsFormInstance(): void {
    this.dimensionsForm = this.formBuilder.group({
      name: ['', Validators.required],
      height: ['', Validators.required],
      width: ['', Validators.required],
      serie: ['', Validators.required],
    })
  }

  getFormControl(controlName: string): AbstractControl {
    return this.dimensionsForm.get(controlName)
  }

  getSeries(): void {
    this.seriesService.getSeries().subscribe(
      (response: any) => {
        this.series = response
        this.series.map((serie) => {
          serie.selected = false
        })
      },
      (error) => {
        console.dir(error)
      }
    )
  }

  setSerie(serie: any): void {
    this.getFormControl('serie').setValue(serie.id)
    this.newModelObject.serie.id = this.getFormControl('serie').value
    this.fitSeries(serie)
  }

  fitSeries(serie: any): void {
    this.series.map((serie) => {
      serie.selected = false
    })
    serie.selected = true
  }

  save(): void {
    this.newModelObject = Object.assign(
      this.dimensionsForm.value,
      this.newModelObject
    )
    this.$response.emit(this.newModelObject)
    this.modalRef.hide()
  }
}
