import { Component, EventEmitter, OnInit } from '@angular/core'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { ToastrService } from 'ngx-toastr'
import { AddAccessoriesGroupDialogComponent } from 'src/app/@dialogs/add-accessories-group-dialog/add-accessories-group-dialog.component'
import { NewAccesoriesCombinationDialogComponent } from 'src/app/@dialogs/new-accesories-combination/new-accesories-combination.component'
import { NewAccesoriesFormulaComponent } from 'src/app/@dialogs/new-accesories-formula/new-accesories-formula.component'
import { asyncForEach } from 'src/app/@helper/asyncForEach'
import { cleanArray } from 'src/app/@helper/cleanArray'
import {
  AccessoriesModelService,
  CreateAccessoryModelRequest,
} from 'src/app/@services/accessories-model.service'
import { ModalService } from 'src/app/@services/modal.service'
import {
  Accesory,
  AccesoryWithFormula,
} from 'src/app/@shared/@interfaces/accesory'
import { IDialog } from 'src/app/@shared/@interfaces/dialog'
import { DialogInstanceModel } from 'src/app/@shared/@models/dialog-instance-model'
import { Gap } from 'src/app/@shared/@models/modelsElements/gap-model'
import { JonquilloElement } from 'src/app/@shared/@models/modelsElements/jonquillo-model'

@Component({
  selector: 'app-add-accessory-junquillo',
  templateUrl: './add-accessory-junquillo.component.html',
  styleUrls: ['./add-accessory-junquillo.component.scss'],
})
export class AddAccessoryJunquilloComponent implements OnInit, IDialog {
  public junquillo: JonquilloElement
  public gap: Gap
  public isAccessoryGroup = false

  $response: EventEmitter<any> = new EventEmitter()

  public profiles: ProfileData[] = []

  constructor(
    private modalRef: BsModalRef,
    private modalService: ModalService,
    private accessoryService: AccessoriesModelService,
    private toastr: ToastrService
  ) {}

  confirm(): void {}

  decline(): void {}

  ngOnInit(): void {
    this.buildProfileData()
  }

  public async onAddAccessory(profileData?: ProfileData): Promise<void> {
    const area = this.junquillo.container

    let entities = [
      {
        type: 'Area',
        entity: area.uuid,
      },
    ]

    let typeInfo: { type: 'Area' | 'Profile'; entity: string } = {
      type: 'Area',
      entity: area.uuid,
    }

    if (profileData) {
      entities.push({
        type: 'Profile',
        entity: profileData.uuid,
      })

      typeInfo = {
        type: 'Profile',
        entity: profileData.uuid,
      }
    }

    if (this.isAccessoryGroup) {
      this.onAccesoryGroup(entities, typeInfo)
      return
    }

    const acc = await this.openAccessoryDialog()
    const { accesory, formula } = await this.openFormulaDialog(
      acc,
      typeInfo.type
    )

    const request: CreateAccessoryModelRequest = {
      entities,
      accessory: {
        codeRef: accesory.codRef,
        family: accesory.family,
      },
      formula: formula.function,
      model: this.gap.modelId,
      type: typeInfo.type,
      typeId: typeInfo.entity,
    }

    this.accessoryService.setAccessoryModelToEntity(request).subscribe({
      next: (res) => {
        this.toastr.success('Accesorio añadido')
        this.modalRef.hide()
      },
    })
  }

  private openAccessoryDialog(): Promise<Accesory> {
    const newDialog = new DialogInstanceModel(
      NewAccesoriesCombinationDialogComponent,
      {
        initialState: {
          accesory: 'Test',
        },
        // class: 'modal-dialog-centered',
      }
    )

    return new Promise((resolve, reject) => {
      this.modalService.openModal(newDialog).subscribe(
        async (accesory: Accesory) => {
          resolve(accesory)
        },
        (err) => {}
      )
    })
  }

  private openFormulaDialog(
    accesory: Accesory,
    type: 'Area' | 'Profile'
  ): Promise<AccesoryWithFormula> {
    const formulaDialog = new DialogInstanceModel(
      NewAccesoriesFormulaComponent,
      {
        initialState: {
          accesory: accesory,
          type: type,
        },
      }
    )

    return new Promise((resolve, reject) => {
      this.modalService
        .openModal(formulaDialog)
        .subscribe((acc: AccesoryWithFormula) => {
          resolve(acc)
        })
    })
  }
  private buildProfileData(): void {
    const elements = cleanArray(this.junquillo.sideElements)

    this.profiles = elements.map((el) => {
      const { profile, side, uuid } = el
      const { code, description } = profile
      const { ext, int } = el.calculateLength()

      return {
        uuid,
        code,
        description,
        ext,
        int,
        side,
      }
    })
  }

  public async onAccesoryGroup(
    entities: { type: string; entity: string }[],
    typeInfo: { type: 'Area' | 'Profile'; entity: string }
  ): Promise<void> {
    const dialog = new DialogInstanceModel(
      AddAccessoriesGroupDialogComponent,
      {}
    )

    this.modalService.openModal(dialog).subscribe({
      next: async (acc: any) => {
        const { accessories } = acc
        if (!accessories) return this.modalRef.hide()

        await asyncForEach(accessories, async (acc: any) => {
          const request: CreateAccessoryModelRequest = {
            entities,
            accessory: {
              codeRef: acc.codRef,
              family: acc.family,
            },
            formula: acc.formula,
            model: this.gap.modelId,
            type: typeInfo.type,
            typeId: typeInfo.entity,
          }

          await this.accessoryService
            .setAccessoryModelToEntity(request)
            .toPromise()
        }).then(() => {
          this.toastr.success(`Accesorios agregados correctamente`)
          this.modalRef.hide()
        })
      },
      error: (err) => {
        console.log(err)
      },
    })
  }
}

interface ProfileData {
  code: string
  description: string
  side: string
  ext: number
  int: number
  uuid: string
}
