// ANGULAR
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  Output,
  EventEmitter,
  ViewChild,
  SimpleChange,
  SimpleChanges,
  OnDestroy,
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

// TOOLS
import { ToastrService } from 'ngx-toastr'
// Icons
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { invalidInputMessages } from 'src/app/@helper/invalidInputShowMessage'

// MODELS
import { ProfileCreator } from 'src/app/@shared/@interfaces/profileCreator.model'
import { UseProfile } from 'src/app/@shared/@interfaces/useProfile'
import { DxfContent } from 'src/app/@shared/@interfaces/dxf-content'

// SERVICES
import { ProfileService } from 'src/app/@services/profile.service'

// COMPONENTS
import { ProfileAccesoriesComponent } from './tabs/profile-accesories/profile-accesories.component'
import { ProfileUsesComponent } from './tabs/profile-uses/profile-uses.component'
import { TechnicalDataComponent } from './tabs/technical-data/technical-data.component'
import { ProfileCuttingOptimizationComponent } from './tabs/profile-cutting-optimization/profile-cutting-optimization.component'
import { CutOptimizationCreator } from 'src/app/@shared/@interfaces/cutOptimization'
import { AccessoryCreator } from 'src/app/@shared/@interfaces/accesory'
import { DxfEditorService } from 'src/app/@services/dxf-editor.service'
import { AuthStatusService } from 'src/app/@services/auth-status.service'
import { Observable } from 'rxjs'
import { User } from 'src/app/core/models/User.model'
import { map } from 'rxjs/operators'

export interface Profile {
  name: string
  description: string
  id: number
}

export interface Serie {
  name: string
  id: number
}

@Component({
  selector: 'app-profile-management-editor',
  templateUrl: './profile-management-editor.component.html',
  styleUrls: ['./profile-management-editor.component.scss'],
})
export class ProfileManagementEditorComponent
  implements OnInit, OnChanges, OnDestroy {
  // Childs for get forms
  @ViewChild('technicaldata')
  private technicalDataComponent: TechnicalDataComponent
  @ViewChild('profileuses')
  private profileUsesComponent: ProfileUsesComponent
  @ViewChild('profileAccesories')
  private profileAccesoriesComponent: ProfileAccesoriesComponent
  @ViewChild('profileCutting')
  private profileCutting: ProfileCuttingOptimizationComponent

  // Outputs
  @Output()
  public addProfileEmitter: EventEmitter<any> = new EventEmitter<any>()
  @Output()
  public updateProfileEmitter: EventEmitter<any> = new EventEmitter<any>()

  @Input()
  set $serie($serie: Serie) {
    this.serie = $serie
  }

  get $serie(): Serie {
    return this.serie
  }
  public serie: Serie

  @Input()
  set $profile($profile: Profile) {
    this.profile = $profile
  }

  get $profile(): Profile {
    return this.profile
  }
  public profile: Profile

  public profileParsedDxf: DxfContent
  public profileObjectFromDB: ProfileCreator | null

  public isNewSerie = true
  public isNewProfile = true

  public isDisassembled = false

  // Form
  public profileDataForm: FormGroup

  // For Submit
  private profileCreator: ProfileCreator
  private accesoriesProfile: AccessoryCreator[] = []
  private useProfile: UseProfile[] = []
  private cutsOptimization: CutOptimizationCreator
  private dxf: FormData
  private showDisassemblyTabFlag: boolean = false;
  private preloadDisassemblyProfile: boolean = false;

  // Icons
  public faTimes = faTimes

  @Output()
  public closeProfileEditorEmitter: EventEmitter<void> = new EventEmitter()

  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService,
    private dxfService: DxfEditorService,
    private toastr: ToastrService
  ) {}

  get profileForm(): Profile {
    return {
      description: this.profileDataForm.get('description').value,
      name: this.profileDataForm.get('name').value,
      id: this.profile ? this.profile.id : null,
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const previusSerie = changes.$serie?.previousValue
    const currentSerie = changes.$serie?.currentValue

    const hasSerieChanged = previusSerie?.id !== currentSerie?.id
    if (!hasSerieChanged) return
    if (hasSerieChanged && previusSerie) this.closeProfileEditor()

    if (this.serie) this.isNewSerie = false
    if (this.profile) {
      this.isNewProfile = false
      this.getProfileByID()
      this.getProfileParsedDxf()
    }
    if (!this.profile) this.isNewProfile = true
    this.setFormValues()
  }

  ngOnInit(): void {
    this.buildProfileDataForm()
    this.setFormValues()
  }

  ngOnDestroy(): void {
    this.dxfService?.onDestroy()
  }

  // Get forms from tabs
  public getFormsProperties(): void {
    this.profileCreator = this.technicalDataComponent.buildObjectForSubmit()
    this.useProfile = this.profileUsesComponent?.buildProfileUses()
    this.accesoriesProfile = this.profileAccesoriesComponent?.buildProfileAccesories()
    this.cutsOptimization = this.profileCutting.buildCutOptimizationForSubmit()

    this.dxf = this.technicalDataComponent.loadProfileDxf()
  }

  public showDisassemblyTab(): void{
    this.showDisassemblyTabFlag = true;
  }

  // Submit
  public profileCreatorSubmit(): void {
    if (this.profileDataForm.invalid) {
      this.profileDataForm.markAllAsTouched()
      return
    }

    this.getFormsProperties()
    if (this.isNewProfile) this.createNewProfile()
    if (!this.isNewProfile) this.updateProfile()
  }

  public createNewProfile(): void {
    this.getFormsProperties()
    this.profileService
      .createProfile(
        this.profileCreator,
        this.useProfile,
        this.accesoriesProfile,
        this.cutsOptimization
      )
      .subscribe(
        (response) => {
          this.toastr.success('Se ha creado el perfil')
          this.createParsedDxf(response.id)
          this.createDxfPng(response.id)
          this.addProfileEmitter.emit({
            id: response.id,
            code: response.code,
            description: response.description,
          })
          this.closeProfileEditor()
        },
        (error) => {
          if (error.error.statusCode == 405) {
            this.toastr.error('El codigo de perfil ya existe')
          }
          this.toastr.error('No se ha podido crear el perfil')
        }
      )
  }

  public createParsedDxf(profileId: number): void {
    const parsedDxf = this.dxfService.getDxfParserOutput
    this.profileService.createProfileParsedDxf(profileId, parsedDxf).subscribe(
      (response: any) => {},
      (error) => {
        console.dir(error)
        this.toastr.error('No se ha podido guardar el dxf')
      }
    )
  }

  public createDxfPng(profileId: number): void {
    this.profileService
      .createProfilePng(profileId, this.dxfService.getDxfPng)
      .subscribe(
        (response: any) => {},
        (error) => {
          console.dir(error)
          this.toastr.error('No se ha podido guardar lla imagen del perfil')
        }
      )
  }

  public updateProfile(): void {
    this.profileService
      .updateProfile(this.$profile.id, this.profileCreator)
      .subscribe(
        (response) => {
          this.toastr.success('Datos técnicos actualizados')
          this.createParsedDxf(this.profile.id)
          this.createDxfPng(this.profile.id)
          this.updateProfileEmitter.emit({
            id: this.profile.id,
            name: this.profileCreator.code,
            description: this.profileCreator.description,
          })
        },
        (error) => {
          this.toastr.error('No se ha podido actualizar los datos técnicos')
        }
      )
  }

  private getProfileByID(): void {
    this.profileService.getProfileByID(this.profile.id).subscribe(
      async (profile) => {
        profile.disasembledProfiles = await this.profileService.getDisasembledProfilesForProfile(this.profile.id).pipe(
          map(async(disasembledProfiles: []) => {
            let tmp = [];
            for(const disasembledProfile of disasembledProfiles) {
              tmp.push(await this.profileService.getProfileByID((disasembledProfile as any).disasembledProfile.id).toPromise())
            }
            return tmp;
          })
        ).toPromise();
        this.profileObjectFromDB = profile
        this.preloadDisassemblyProfile = (this.profileObjectFromDB.assembly.id === 3)
      },
      (error) => {
        this.toastr.error(error)
      }
    )
  }

  public getProfileParsedDxf(): void {
    this.profileService.getProfileParsedDxf(this.profile.id).subscribe(
      (response: DxfContent) => {
        this.profileParsedDxf = response
        this.dxfService.isDxfFromDBEmitter(true)
      },
      (error) => {
        console.dir(error)
      }
    )
  }

  public getProfilePng(): void {
    this.profileService.getProfilePng(this.profile.id).subscribe(
      (response: any) => {
        // this.profileParsedDxf = new Blob([response], { type: 'image/.png' })
      },
      (error) => {
        console.dir(error)
      }
    )
  }

  private buildProfileDataForm(): void {
    this.profileDataForm = this.fb.group({
      serie: [{ value: '', disabled: false }, [Validators.required]],
      name: ['', [Validators.required]],
      description: ['', [Validators.required, Validators.maxLength(140)]],
    })
  }

  public isInvalidControl(control: string, requiredMessage?: string): string[] {
    return invalidInputMessages(this.profileDataForm, control, requiredMessage)
  }

  public setFormValues(): void {
    if (!this.profileDataForm) return

    const formValues = {
      serie: {
        value: this.serie ? this.serie.name : '',
        disabled: !this.isNewSerie,
      },
      name: {
        value: this.profile ? this.profile.name : '',
        disabled: !this.isNewProfile,
      },
      description: this.profile ? this.profile.description : '',
    }

    this.profileDataForm.reset(formValues)
    this.profileDataForm.updateValueAndValidity()
  }

  public closeProfileEditor(): void {
    this.closeProfileEditorEmitter.emit()
  }

  public setIsDisassembled(is: boolean): void {
    this.isDisassembled = is
  }
}
