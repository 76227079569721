import { Injectable } from '@angular/core'
import Konva from 'konva'
import { pointsToVectors2d } from 'src/app/@helper/pointsTo2dVector'
import { rotateRespectPoint } from 'src/app/@helper/rotateRespectToPoint'
import { rotateVector } from 'src/app/@helper/rotateVector'
import { ProfileCreator } from 'src/app/@shared/@interfaces/profileCreator.model'
import { UnionTypes } from 'src/app/@shared/@interfaces/unionTypes'
import { Vector } from 'src/app/@shared/@interfaces/vector'
import { SideOptions } from 'src/app/@shared/@types/side.types'
import { UnionPositions } from 'src/app/@shared/@types/unionPositions.type'
import { Vector2 } from 'three'
import { UnionService } from '../union.service'
import { ProfileDrawerCreatorService } from './profile-drawer-creator.service'

@Injectable({
  providedIn: 'root',
})
export class PseudoFrameDrawerService {
  private unionTypes: UnionTypes

  constructor(
    private profileDrawer: ProfileDrawerCreatorService,
    private unionService: UnionService
  ) {
    this.unionTypes = this.unionService.unionsIds
  }

  public createPseudoFrameFromStartInt(params: any) {
    const { sides, has, unions } = params
    let { top, bottom, left, right } = sides

    let topProfile = null
    let bottomProfile = null
    let leftProfile = null
    let rightProfile = null

    const scaleFactorHeight = +localStorage.getItem('scaleHeight')
    const scaleFactorWidth = +localStorage.getItem('scaleWidth')

    if (has.top) {
      topProfile = this.profileDrawer.createHorizontalProfileFromStartInt({
        profile: top.profile,
        profileLength: top.length,
        profileWidth: top.width,
        scaleFactor: scaleFactorWidth,
        startPointInt: top.startPointInt,
      })

      topProfile = this.profileDrawer.setUnionsForHorizontalIntToExt(
        unions['left-top'],
        unions['right-top'],
        topProfile,
        top.width * scaleFactorWidth,
        left.width * scaleFactorHeight,
        right.width * scaleFactorHeight
      )
    }

    if (has.bottom) {
      bottomProfile = this.profileDrawer.createHorizontalProfileFromStartInt({
        profile: bottom.profile,
        profileLength: bottom.length,
        profileWidth: bottom.width,
        scaleFactor: scaleFactorWidth,
        startPointInt: bottom.startPointInt,
      })

      bottomProfile = this.profileDrawer.reflexHorizontalProfile(
        bottom.startPointInt.y,
        bottomProfile
      )

      bottomProfile = this.profileDrawer.setUnionsForHorizontalIntToExt(
        unions['left-bottom'],
        unions['right-bottom'],
        bottomProfile,
        bottom.width * scaleFactorWidth,
        left.width * scaleFactorHeight,
        right.width * scaleFactorHeight
      )
    }

    if (has.left) {
      leftProfile = this.profileDrawer.createVerticalProfileFromStartInt({
        profile: left.profile,
        profileLength: left.length,
        profileWidth: left.width,
        scaleFactor: scaleFactorHeight,
        startPointInt: left.startPointInt,
      })

      leftProfile = this.profileDrawer.setUnionsForVerticalIntToExt(
        params.unions['left-top'],
        params.unions['left-bottom'],
        leftProfile,
        (left.width ?? 0) * scaleFactorHeight,
        (top?.width ?? 0) * scaleFactorWidth,
        (bottom?.width ?? 0) * scaleFactorWidth
      )
    }

    if (has.right) {
      rightProfile = this.profileDrawer.createVerticalProfileFromStartInt({
        profile: right.profile,
        profileLength: right.length,
        profileWidth: right.width,
        scaleFactor: scaleFactorHeight,
        startPointInt: right.startPointInt,
      })

      rightProfile = this.profileDrawer.reflexVerticalProfile(
        right.startPointInt.x,
        rightProfile
      )

      rightProfile = this.profileDrawer.setUnionsForVerticalIntToExt(
        params.unions['right-top'],
        params.unions['right-bottom'],
        rightProfile,
        right.width * scaleFactorHeight,
        top.width * scaleFactorWidth,
        bottom.width * scaleFactorWidth
      )
    }

    return {
      top: topProfile as Konva.Line,
      bottom: bottomProfile as Konva.Line,
      left: leftProfile as Konva.Line,
      right: rightProfile as Konva.Line,
    }
  }

  public createPseudoFrameFromStartExt(params: IPseudoFrameCreatorStartExt) {
    const { sides, has, scaleFactor, unions } = params
    const { top, bottom, left, right } = sides

    let topProfile = null
    let bottomProfile = null
    let leftProfile = null
    let rightProfile = null

    const minInternalCamera = 0.001

    top.width = top.width === 0 ? minInternalCamera : top.width
    bottom.width = bottom.width === 0 ? minInternalCamera : bottom.width
    left.width = left.width === 0 ? minInternalCamera : left.width
    right.width = right.width === 0 ? minInternalCamera : right.width

    if (has.top && top.startPointExt) {
      topProfile = this.profileDrawer.createHorizontalProfileFromStartExt({
        profile: top.profile,
        profileLength: top.length,
        profileWidth: top.width,
        scaleFactor: scaleFactor,
        startPointExt: top.startPointExt,
      })

      topProfile = this.profileDrawer.setUnionsForHorizontalExtToInt(
        unions['left-top'],
        unions['right-top'],
        topProfile,
        top.width * scaleFactor,
        (left?.profile?.externalFin ?? 0) * scaleFactor,
        (right?.profile?.externalFin ?? 0) * scaleFactor
      )
    }

    if (has.bottom && bottom.startPointExt) {
      bottomProfile = this.profileDrawer.createHorizontalProfileFromStartExt({
        profile: bottom.profile,
        profileLength: bottom.length,
        profileWidth: bottom.width,
        scaleFactor: scaleFactor,
        startPointExt: bottom.startPointExt,
      })

      bottomProfile = this.profileDrawer.reflexHorizontalProfile(
        bottom.startPointExt.y,
        bottomProfile
      )

      bottomProfile = this.profileDrawer.setUnionsForHorizontalExtToInt(
        unions['left-bottom'],
        unions['right-bottom'],
        bottomProfile,
        bottom.width * scaleFactor,
        (left?.profile?.externalFin ?? 0) * scaleFactor,
        (right?.profile?.externalFin ?? 0) * scaleFactor
      )
    }

    if (has.left && left.startPointExt) {
      leftProfile = this.profileDrawer.createVerticalProfileFromStartExt({
        profile: left.profile,
        profileLength: left.length,
        profileWidth: left.width,
        scaleFactor: scaleFactor,
        startPointExt: left.startPointExt,
      })

      leftProfile = this.profileDrawer.setUnionsForVerticalExtToInt(
        params.unions['left-top'],
        params.unions['left-bottom'],
        leftProfile,
        left.width * scaleFactor,
        (top?.profile?.externalFin ?? 0) * scaleFactor,
        (bottom?.profile?.externalFin ?? 0) * scaleFactor
      )
    }

    if (has.right && right.startPointExt) {
      rightProfile = this.profileDrawer.createVerticalProfileFromStartExt({
        profile: right.profile,
        profileLength: right.length,
        profileWidth: right.width,
        scaleFactor: scaleFactor,
        startPointExt: right.startPointExt,
      })

      rightProfile = this.profileDrawer.reflexVerticalProfile(
        right.startPointExt.x,
        rightProfile
      )

      rightProfile = this.profileDrawer.setUnionsForVerticalExtToInt(
        params.unions['right-top'],
        params.unions['right-bottom'],
        rightProfile,
        right.width * scaleFactor,
        (top?.profile?.externalFin ?? 0) * scaleFactor,
        (bottom?.profile?.externalFin ?? 0) * scaleFactor
      )
    }

    return {
      top: topProfile as Konva.Line,
      bottom: bottomProfile as Konva.Line,
      left: leftProfile as Konva.Line,
      right: rightProfile as Konva.Line,
    }
  }
}

export interface IPseudoFrameCreatorStartInt {
  scaleFactor: number
  sides: { [k in SideOptions]?: SidePseudoFrameCreatorStartInt }
  unions: { [k in UnionPositions]: number }
  superpositions: { [k in SideOptions]?: number }
  cutVariations: {
    [k in SideOptions]?: { id: number; value: number; value2?: number }
  }
  has: { [k in SideOptions]: boolean }
}

export interface IPseudoFrameCreatorStartExt {
  scaleFactor: number
  sides: { [k in SideOptions]?: SidePseudoFrameCreatorStartExt }
  unions: { [k in UnionPositions]: number }
  superpositions: { [k in SideOptions]?: number }
  cutVariations: {
    [k in SideOptions]?: { id: number; value: number; value2?: number }
  }
  has: { [k in SideOptions]: boolean }
}

export interface SidePseudoFrameCreatorStartInt {
  profile: ProfileCreator
  width: number
  length: number
  startPointInt: Vector2
}

export interface SidePseudoFrameCreatorStartExt {
  startPointExt: Vector2
  profile: ProfileCreator
  width: number
  length: number
}
