import { Vector } from '../@shared/@interfaces/vector'
import { rotateVector } from './rotateVector'

export const rotateRespectPoint = (
  vector: Vector,
  point: Vector,
  degree: number
): Vector => {
  const transformatedVector = {
    x: vector.x - point.x,
    y: vector.y - point.y,
  }

  const rotatedVectorTransformated = rotateVector(transformatedVector, degree)

  const rotatedVector = {
    x: rotatedVectorTransformated.x + point.x,
    y: rotatedVectorTransformated.y + point.y,
  }

  return rotatedVector
}
